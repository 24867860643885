import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  denormalisedResponseEntities,
  ensureCurrentUser,
  ensureStripeCustomer,
} from '../../util/data';
import { storableError } from '../../util/errors';
import config from '../../config';
import { TRANSITION_COVID_CONFIRM_PAYMENT, TRANSITION_COVID_REQUEST } from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import { drivelahApiPut } from '../../util/apiHelper';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

const { UUID } = sdkTypes;

export const FETCH_DISINFECTED_BOOKING_REQUEST = 'FETCH_DISINFECTED_BOOKING_REQUEST';
export const FETCH_DISINFECTED_BOOKING_SUCCESS = 'FETCH_DISINFECTED_BOOKING_SUCCESS';
export const FETCH_DISINFECTED_BOOKING_ERROR = 'FETCH_DISINFECTED_BOOKING_ERROR';
export const INITIATE_ORDER_REQUEST = 'app/CovidPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CovidPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CovidPage/INITIATE_ORDER_ERROR';
const LISTING_ONE_BOTTLE = process.env.REACT_APP_COVID_LISTING_ONE_BOTTLE;
const LISTING_THREE_BOTTLES = process.env.REACT_APP_COVID_LISTING_THREE_BOTTLES;
const LISTING_FIVE_BOTTLES = process.env.REACT_APP_COVID_LISTING_FIVE_BOTTLES;

const initialState = {
  listingRefs: [],
  fetchInProgress: false,
  fetchError: null,
  initiateOrderInProgress: false,
  initiateOrderError: null,
  transaction: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DISINFECTED_BOOKING_REQUEST:
      return {
        ...state, fetchInProgress: true
      }
    case FETCH_DISINFECTED_BOOKING_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        listingRefs: payload.data.data.map(({ id, type }) => ({ id, type })),
      }
    case FETCH_DISINFECTED_BOOKING_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        error: payload
      }
    case INITIATE_ORDER_REQUEST:
      return {
        ...state,
        initiateOrderInProgress: true,
        initiateOrderError: null,
      };
    case INITIATE_ORDER_SUCCESS:
      return {
        ...state,
        initiateOrderInProgress: false,
        transaction: payload,
      };

    case INITIATE_ORDER_ERROR:
      return {
        ...state,
        initiateOrderInProgress: false,
        initiateOrderError: payload,
      };
    default:
      return state
    }
}

const fetchDisinfectedBookingRequest = () => ({ type: FETCH_DISINFECTED_BOOKING_REQUEST });
const fetchDisinfectedBookingSuccess = data => ({ type: FETCH_DISINFECTED_BOOKING_SUCCESS, payload: data });
const fetchDisinfectedBookingError = error => ({ type: FETCH_DISINFECTED_BOOKING_ERROR, payload: error });

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(fetchDisinfectedBookingRequest());
  const bounds = {
    _sdkType: "LatLngBounds",
    ne: {
      _sdkType: "LatLng",
      lat: 1.4708809,
      lng: 104.04157989999999,
    },
    sw: {
      _sdkType: "LatLng",
      lat: 1.216611,
      lng: 103.60650989999999
    }
  }

  const center = {
    lat: (bounds.ne.lat + bounds.sw.lat) / 2,
    lng: (bounds.ne.lng + bounds.sw.lng) / 2,
  };

  return sdk.listings.query({
    origin: `${center.lat},${center.lng}`,
    meta_isDisinfected: true,
    page: 1,
    per_page: 4,
    include: ['author', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
    pub_isDeposit: false,
    meta_live: true,
  }).then(res => {
    dispatch(addMarketplaceEntities(res));
    dispatch(fetchDisinfectedBookingSuccess(res));
    return res;
  })
    .catch(e => {
    dispatch(fetchDisinfectedBookingError(storableError(e)));
  })
}

export const confirmDeclaration = (transactionId) => async (dispatch, getState, sdk) => {
  try {
    dispatch(initiateOrderRequest());
    const { currentUser } = getState().user;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
    const updateDisinfectedListingURL = `listings/${currentUserId}/${transactionId}/confirm-declaration-covid`;
    const response = await drivelahApiPut(updateDisinfectedListingURL);
    dispatch(initiateOrderSuccess(response.data && response.data.data));
    return response.data && response.data.data;
  } catch(e) {
    dispatch(initiateOrderError(storableError(e)));
    return null;
  }
}

export const initiateOrder = ({
  card, stripe, paymentMethod, replaceCurrentCard, paramsOrder
  }) => async ( dispatch, getState, sdk) => {
  try {
    const { deliveryAddress, name, numberBottles } = paramsOrder;
    const { currentUser } = getState().user;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    dispatch(initiateOrderRequest());
    const mustSaveCard = card && !paymentMethod && replaceCurrentCard;
    const bodyParams = {
      processAlias: config.bookingProcessAliasCovidProgram,
      transition: TRANSITION_COVID_REQUEST,
      params: {
        listingId: getListingFromNumberBottles(numberBottles),
        quantity: 1,
        protectedData: { deliveryAddress, name, numberBottles }
      }
    };

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    let transactionId = null;
    const transactionResponse = await sdk.transactions.initiate(bodyParams, queryParams);

    dispatch(addMarketplaceEntities(transactionResponse));
    const [transaction] = denormalisedResponseEntities(transactionResponse);
    transactionId = transaction.id;
    const hasPaymentIntents =
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.stripePaymentIntents;

    const updateDisinfectedListingURL = `listings/${transactionId && transactionId.uuid}/purchase-disinfected-covid`;
    const disinfectedData = await drivelahApiPut(updateDisinfectedListingURL);

    if (!hasPaymentIntents) {
      throw new Error(
        `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
      );
    }
    const { stripePaymentIntentClientSecret } = hasPaymentIntents
      ? transaction.attributes.protectedData.stripePaymentIntents.default
      : null;

    const args = paymentMethod
      ? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
      : [stripePaymentIntentClientSecret, card];

    const responseCard = await stripe.handleCardPayment(...args);
    if (responseCard.error) {
      return Promise.reject(responseCard);
    } else {
      const transaction = await handleConfirmPaymentTransition({
        sdk, dispatch, transactionId, mustSaveCard, stripe, card,
        stripeCustomer: ensuredStripeCustomer,
        currentUserId,
      });
      return transaction;
    }
  } catch(e) {
    dispatch(initiateOrderError(storableError(e)));
    return null;
  }
};

const handleConfirmPaymentTransition = async ({ sdk, dispatch, transactionId, mustSaveCard, stripe, card, currentUserId, stripeCustomer }) => {

  const confirmParams = {
    id: transactionId,
    transition: TRANSITION_COVID_CONFIRM_PAYMENT,
    params: {},
  };

  if (!currentUserId) {
    throw new Error( `Missing current user` );
  }

  const response = await sdk.transactions.transition(confirmParams, { include: ['booking', 'provider'], expand: true });
  dispatch(addMarketplaceEntities(response));
  const [transaction] = denormalisedResponseEntities(response);
  dispatch(initiateOrderSuccess(transaction));
  if (!mustSaveCard) return transaction;

  const result = await stripe.createPaymentMethod({ type: 'card', card });
  const payment_method = result.paymentMethod.id;
  dispatch(savePaymentMethod(stripeCustomer, payment_method));
  return transaction;
}

const getListingFromNumberBottles = (numberBottles) => {
  switch (numberBottles) {
    case '1':
      return new UUID(LISTING_ONE_BOTTLE);
    case '3':
      return new UUID(LISTING_THREE_BOTTLES);
    case '5':
      return new UUID(LISTING_FIVE_BOTTLES);
    default:
      return new UUID(LISTING_ONE_BOTTLE);
  }
}

export const fetchTransactionCovid = id => (dispatch, getState, sdk) => {
  dispatch(fetchDisinfectedBookingRequest());

  const queryParams = {
    include: ['customer', 'provider', 'listing'],
    expand: true,
  };
  return sdk.transactions
    .show({ id, ...queryParams})
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      const tx = entities[0];
      dispatch(initiateOrderSuccess(tx));
    })
    .catch(e => {
      return dispatch(initiateOrderError(storableError(e)));
    });
};
