import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import infoCircle from '../../assets/updatelisting/info-circle-solid.svg';
import { Button, FieldTextInput, Form } from '../../components';
import arrayMutators from 'final-form-arrays';

import { composeValidators, required } from '../../util/validators';

import css from './EditListingInstructionsForm.css';

const MAX_LENGTH = 2000;

export const EditListingInstructionsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators, setFieldTouched }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        submitButtonId,
      } = formRenderProps;

      const parkingInstructionsLabel = intl.formatMessage({
        id: 'EditListingInstructionsForm.parkingInstructionsLabel',
      });

      const parkingInstructionsPlaceholder = intl.formatMessage({
        id: 'EditListingInstructionsForm.parkingInstructionsPlaceholder',
      });

      const parkingInstructionsRequiredMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.parkingInstructionsRequiredMessage',
      });
      const parkingInstructionsInfoMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.parkingInstructionsInfoMessage',
      });
      const pickupDropoffInstructionsLabel = intl.formatMessage({
        id: 'EditListingInstructionsForm.pickupDropoffInstructionsLabel',
      });
      const pickupDropoffInstructionsPlaceholder = intl.formatMessage({
        id: 'EditListingInstructionsForm.pickupDropoffInstructionsPlaceholder',
      });
      const pickupDropoffInstructionsRequiredMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.pickupDropoffInstructionsRequiredMessage',
      });
      const pickupDropoffInstructionsInfoMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.pickupDropoffInstructionsInfoMessage',
      });
      const carGuideLabel = intl.formatMessage({
        id: 'EditListingInstructionsForm.carGuideLabel',
      });
      const carGuidePlaceholder = intl.formatMessage({
        id: 'EditListingInstructionsForm.carGuidePlaceholder',
      });
      const carGuideRequiredMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.carGuideRequiredMessage',
      });
      const carGuideInfoMessage = intl.formatMessage({
        id: 'EditListingInstructionsForm.carGuideInfoMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            if (!invalid) handleSubmit(e);
            const formState = form.getState();
            const { errors } = formState || {};
            Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
          }}
        >
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.displayInlineContainer}>
            <div className={classNames(css.column, css.column12, css.specificInstructionOuter)}>
              <div className={css.fieldWrapper}>

                <div className={css.limitCounter}>
                  {(values.parkingInstructions && values.parkingInstructions.length) || 0}/
                  {MAX_LENGTH}
                </div>
                <FieldTextInput
                  id="parkingInstructions"
                  name="parkingInstructions"
                  className={classNames(css.policy, css.newInput, css.specificInstruction)}
                  type="textarea"
                  maxLength={MAX_LENGTH}
                  label={parkingInstructionsLabel}
                  placeholder={parkingInstructionsPlaceholder}
                  validate={composeValidators(required(parkingInstructionsRequiredMessage))}
                  errorClassName={css.customErrorClass}
                  customErrorText={
                    values.parkingInstructions &&
                      values.parkingInstructions.length &&
                      values.parkingInstructions.length >= MAX_LENGTH
                      ? 'You have reached the maximum character limit'
                      : ''
                  }
                />
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  {parkingInstructionsInfoMessage}
                </div>
              </div>
            </div>
            <div className={classNames(css.column, css.column12, css.specificInstructionOuter)}>
              <div className={css.fieldWrapper}>
                <div className={css.limitCounter}>
                  {(values.pickupDropoffInstructions && values.pickupDropoffInstructions.length) || 0}/
                  {MAX_LENGTH}
                </div>
                <FieldTextInput
                  id="pickupDropoffInstructions"
                  name="pickupDropoffInstructions"
                  className={classNames(css.policy, css.newInput, css.specificInstruction)}
                  type="textarea"
                  maxLength={MAX_LENGTH}
                  label={pickupDropoffInstructionsLabel}
                  placeholder={pickupDropoffInstructionsPlaceholder}
                  validate={composeValidators(required(pickupDropoffInstructionsRequiredMessage))}
                  errorClassName={css.customErrorClass}
                  customErrorText={
                    values.pickupDropoffInstructions &&
                      values.pickupDropoffInstructions.length &&
                      values.pickupDropoffInstructions.length >= MAX_LENGTH
                      ? 'You have reached the maximum character limit'
                      : ''
                  }
                />
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  {pickupDropoffInstructionsInfoMessage}
                </div>
              </div>
            </div>
            <div className={classNames(css.column, css.column12, css.specificInstructionOuter)}>
              <div className={css.fieldWrapper}>
                <div className={css.limitCounter}>
                  {(values.carGuide && values.carGuide.length) || 0}/
                  {MAX_LENGTH}
                </div>
                <FieldTextInput
                  id="carGuide"
                  name="carGuide"
                  className={classNames(css.policy, css.newInput, css.specificInstruction)}
                  type="textarea"
                  maxLength={MAX_LENGTH}
                  label={carGuideLabel}
                  placeholder={carGuidePlaceholder}
                  validate={composeValidators(required(carGuideRequiredMessage))}
                  errorClassName={css.customErrorClass}
                  customErrorText={
                    values.carGuide &&
                      values.carGuide.length &&
                      values.carGuide.length >= MAX_LENGTH
                      ? 'You have reached the maximum character limit'
                      : ''
                  }
                />
                <div className={css.infoText}>
                  <span className={css.infoIcon}>
                    <img src={infoCircle} alt="" />
                  </span>
                  {carGuideInfoMessage}
                </div>
              </div>
            </div>
          </div>
          <div className={css.stickyButtons}>
            <div className={css.stickButtonsContainer}>
              <div className={css.stickButtonsDescription}></div>
              <div className={css.stickButton}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingInstructionsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingInstructionsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingInstructionsFormComponent);
