import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { txIsCanceled, txIsDeclined, txIsDelivered } from '../../../util/transaction';

import css from '../BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemLongTermCurrentPayment = props => {
  const { transaction, isProvider, intl } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : <FormattedMessage id="CheckoutBreakdownLongTerm.currentPaymentMade" />;

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;

  const finalTotalPrice = isProvider
    ? new Money(totalPrice.amount, totalPrice.currency)
    : totalPrice;

  const formattedTotalPrice = formatMoney(intl, finalTotalPrice);

  return (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>{totalLabel}</div>
      <div className={css.itemValue}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemLongTermCurrentPayment.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemLongTermCurrentPayment;
