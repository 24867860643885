import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import css from '../BookingBreakdown.css';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemMonthlyLongTerm = ({ transaction, isCustomer, intl }) => {

  let nextPaymentType = get(transaction, 'attributes.metadata.nextTransactionType');
  nextPaymentType = nextPaymentType ? nextPaymentType : get(transaction, 'attributes.protectedData.longTermNextPaymentType');
  const monthlyPrice = get(transaction, 'listing.attributes.publicData.longTermPrice.amount', 99900);
  const payinTotal = get(transaction, 'attributes.payinTotal.amount', 0);
  const longTermTotalTripPrice = get(transaction, 'attributes.protectedData.longTermTotalTripPrice', 0);

  let currentPaid = get(transaction, 'attributes.metadata.longTermPaymentMade');
  if (!currentPaid) {
    currentPaid = get(transaction, 'attributes.payinTotal.amount', 0);
  }
  let remainPayment = longTermTotalTripPrice - currentPaid;
  let nextPaymentPrice = monthlyPrice;
  if (!monthlyPrice) {
    return null;
  }

  if (nextPaymentType === 'LTM') {
    nextPaymentPrice = 0.5*payinTotal;
  } else if (nextPaymentType === 'LTL') {
    nextPaymentPrice = remainPayment;
  } else if (nextPaymentType === 'EMPTY') {
    nextPaymentPrice = 0;
  }

  if (!isCustomer) {
    return null;
  }

  nextPaymentPrice = new Money(nextPaymentPrice, config.currency);
  nextPaymentPrice = nextPaymentPrice ? formatMoney(intl, nextPaymentPrice) : null;

  return nextPaymentPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdownLongTerm.nextMonthlyPayment" />
      </span>
      <span className={css.itemValue}>{nextPaymentPrice}</span>
    </div>
  ) : null;
};

export default LineItemMonthlyLongTerm;
