import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { InlineTextButton } from '../../components';

import css from './ModalMissingInformation.css';
import { format, formatPhoneNumber } from '../FieldPhoneNumberInput/fiFormatter';
import SignupStep3Form from '../../forms/SignupForm/SignupStep3Form';
// import { getPUSHTELLSearchExperimentPage } from '../../util/emailNotify';

const MobileValidate = props => {
  const {
    className,
    user,
    sendMobileCodeInProgress, //State that show code is sending
    sendMobileCodeError, //Send code error flag
    onSendMobileCodeEmail,  //Send mobile code handler
    callMobileCodeInProgress, //State that show code is calling
    callMobileCodeError, //Call error flag
    onCallMobileCodeEmail, //Call handler
    onChangeMobileNumber, // Pres the change button
    checkValidCode,
    phoneNumber,
    verifyPhoneSuccess,
    loadingPhoneValidate,
    resendCodeSuccess,
    resendCodeInprogress,
    resendCodeError
  } = props;

  useEffect(() => {
    if (
      !sendMobileCodeInProgress 
      // &&
      // in case A experiment otp api needs to be called on form load
      // getPUSHTELLSearchExperimentPage() === 'CASE_A'
    ) {
      onSendMobileCodeEmail();
    }
  }, []);

  const sendingCode = <FormattedMessage id="ModalMissingInformation.mobileValidateSendingCode" />;

  const sendCodeAgain = (
    <InlineTextButton className={css.helperMVLink} onClick={onSendMobileCodeEmail}>
      <FormattedMessage id="ModalMissingInformation.mobileValidateSendCodeAgain" />
    </InlineTextButton>
  );

  const changeMyNumber = (
    <InlineTextButton className={css.helperMVLink} onClick={onChangeMobileNumber}>
      <FormattedMessage id="ModalMissingInformation.mobileValidateChangeMyNumber" />
    </InlineTextButton>
  );

  const calling = <FormattedMessage id="ModalMissingInformation.mobileValidateCallingMe" />;

  const callMeInstead = (
    <InlineTextButton className={css.helperMVLink} onClick={onCallMobileCodeEmail}>
      <FormattedMessage id="ModalMissingInformation.mobileValidateCallMeInstead" />
    </InlineTextButton>
  );

  const resendErrorTranslationId = 'ModalMissingInformation.sendCodeError';

  const sendErrorMessage = sendMobileCodeError ? (
    <p className={css.error}>
      <FormattedMessage
        id={resendErrorTranslationId}
        values={{ error: <FormattedMessage id={sendMobileCodeError.message} /> }}
      />
    </p>
  ) : null;

  const resendErrorMessage = resendCodeError ? (
    <p className={css.error}>
      <FormattedMessage
        id={resendErrorTranslationId}
        values={{ error: <FormattedMessage id={'ModalMissingInformation.resendErrorMessage'} /> }}
      />
    </p>
  ) : null;

  const resendSuccessMessage = resendCodeSuccess ? (
    <div className={css.resendSuccessMessage}>
      <FormattedMessage id="ModalMissingInformation.resendSuccessMessage" />
    </div>
  ) : null;

  const getFormattedPhoneNumber = phoneNumber => {
    // const removePhoneCode = phoneNumber.substring(3);
    return formatPhoneNumber(phoneNumber);
  };

  return (
    <div className={className}>
      {verifyPhoneSuccess ? (
        <p className={css.modalTitle}>
          <FormattedMessage id="ModalMissingInformation.mobileValidatedTitle" />
        </p>
      ) : (
        <h1 className={css.modalTitleConfirmMobile}>
          <FormattedMessage id="ModalMissingInformation.mobileValidateTitle" />
        </h1>
      )}

      <p className={css.modalMessage}>
        <FormattedMessage
          id={
            verifyPhoneSuccess
              ? 'ModalMissingInformation.mobileValidatedDescription'
              : 'ModalMissingInformation.mobileValidateDescription'
          }
          values={{ phoneNumber: getFormattedPhoneNumber(phoneNumber) }}
        />
      </p>
      {sendErrorMessage}
      {resendErrorMessage}
      {resendSuccessMessage}

      <SignupStep3Form
        resendCode={onSendMobileCodeEmail}
        onChangeNumber={onChangeMobileNumber}
        submitCode={checkValidCode}
        inProgress={(loadingPhoneValidate && !verifyPhoneSuccess) || sendMobileCodeInProgress}
      />
    </div>
  );
};

export default MobileValidate;
