import React, { useEffect, useRef, useState } from 'react';
import css from './UserPictureIDVerification.css';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { connect } from 'react-redux';
import axios from 'axios';
import { get } from 'lodash';
import { updateUserData } from '../../ducks/user.duck';
import Button from '../Button/Button';
import dynamic from 'next/dynamic';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureCurrentUser } from '../../util/data';
import {
  AddressDetailsForm,
  EmailVerificationFormAccountSettings,
  PersonalDetailsForm,
  PhoneNumberForm,
} from '../../forms';
import { saveContactDetails } from './PhoneNumber.duck';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { saveAddressDetails } from './AddressDetailsPage.duck';
import BackgroundCheckConsent from './BackgroundCheckConsent';

import {
  EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
} from '../../util/conversions/conversionsConstants';
import { savePersonalDetails } from '../../containers/PersonalDetailsPage/PersonalDetailsPage.duck';
import config from '../../config';

const { LatLng } = sdkTypes;


let initializeOnfido = null

const DynamicHeader = dynamic(() => import('./dynamicOnfido').then(comp => {
  initializeOnfido = comp;
}), {
})

const JustToModifyStep = ({ modifyStep, handleSteps, step, title }) => {
  React.useEffect(() => {
    modifyStep && handleSteps(step, title);
  }, []);
  return <></>
};
const JustToModifyStepCard = ({ modifyStep, handleSteps, step, title, setCardVerificationModal }) => {
  React.useEffect(() => {
    modifyStep && handleSteps(step, title);
    setCardVerificationModal(true);
  }, []);
  return <></>
};

const UserPictureIDVerificationComponent = props => {


  const { onComplete, steps, currentUser, onUpdateUserData, saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
    onChange,
    scrollingDisabled,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    intl,
    onSubmitAddressDetailsForm,
    onSubmitPersonalDetailsForm,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    savePersonalDetailsInProgress,
    savePersonalDetailsSuccess,
    savePersonalDetailsError,
    connectCalendarTabVisibility,
    transactionId,
    haveNextTransit,
    nextInteractableTransitions,
    transaction,
    onTransit,
    isHost,
    userLocation,
    phoneNumberVerified,
    isEmailVerified,
    dateOfBirth,
    handleSteps,
    isHostVerified,
    isGuestVerified,
    isSubmittedOnfidoVerification,
    setCardVerificationModal,
    CardVerificationModal,
    isCardVerified,
    isBackgroundCheckConsentAccepted,
    showThankYouPage,
    handleContinueVerification,
    thankYouPage,
    fetchCalendarVisibility, pageName } = props;
  const modalReference = useRef(null);
  const [onfidoInitToken, setOnfidoInitToken] = useState(null);
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState('');

  const user = ensureCurrentUser(currentUser);
  const userId = get(currentUser, 'id.uuid', null);
  const userPhoneNumber = get(currentUser, 'attributes.profile.protectedData.phoneNumber', null);


  useEffect(() => {
    if (config && config.custom && config.custom.phoneCode.length) {
      let country = config.maps &&
        config.maps.search &&
        config.maps.search.countryLimit &&
        config.maps.search.countryLimit.length &&
        config.maps.search.countryLimit[0];
      if (country) {
        config.custom.phoneCode.every(function (element) {
          if (element.cc === country) {
            setSelected(element.key);
            return false;
          } else {
            return true;
          }
        })
      }
    }
  }, []);

  const transactionData = {
    transaction,
    transactionId,
    nextInteractableTransitions,
    haveNextTransit,
    onTransit,
    isHost
  }

  const protectedData = user.attributes.profile.protectedData || {};
  const userEmail = user.attributes.email;
  const currentPhoneNumber = protectedData.phoneObj ? protectedData.phoneObj.rawNumber : '';
  const currentPhoneCode = protectedData.phoneObj ? protectedData.phoneObj.phoneCode : (selected || '');
  const { location, unitNumber, city, state, postalCode, streetNumber, suburb } = protectedData;
  const { selectedPlace } = location || {};
  const { address, origin } = selectedPlace || {};
  const [a, setA] = useState("afsedf")
  const initialValues = {
    location: {
      search: address,
      selectedPlace: {
        address,
        streetNumber,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    unitNumber,
    streetNumber,
    suburb,
    city,
    state,
    postalCode,
  };

  const dobInitialValues = {
    dateOfBirth,
  }


  const [addressVisible, setaddressVisible] = useState(false);
  const [phoneVisible, setphoneVisible] = useState(true);
  const [verifyIdVisible, setverifyIdVisible] = useState(false);
  const [Disable, setDisable] = useState(true);
  const [continueToCredit, setContinueToCredit] = useState(false);

  let EnableIDVerify = true;


  const _createOnfidoCheck = async () => {
    const applicant_id = OnfidoUserID;
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/onfidoCheck/${applicant_id}`)
        .then((response) => {

          if (response.data.status === 'in_progress') {
            const publicData = isHost ? {
              hostIdentityVerificationStatus: 'pending',
              hostVerificationSent: new Date().toISOString(),
              onFidoCheckId: response.data.id
            } : {
              onFidoCheckId: response.data.id
            }
            onUpdateUserData({
              protectedData: {
                onfidoDocIds: response.data.report_ids,
              },
              publicData
            }).then(() => {
              if (isCardVerified) {
                window.location.reload();
              }
            });
            OnfidoUserID = '';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }


  }
  let token;
  let OnfidoUserID;
  const _handleInitiateVerification = async () => {
    const userId = await get(currentUser, 'id.uuid');
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/${userId}`)
        .then(async (response) => {

          if (response && response.data.data.token) {
            setOnfidoInitToken(response.data.data.token);
            token = response.data.data.token;

            OnfidoUserID = response.data.OnfidoUserID
            initializeOnfido.initializeOnfido(token, currentUser, modalReference, _createOnfidoCheck, transactionData, onTransit);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }

  };


  return (
    <>
      <div className={css.root} style={{ zIndex: 999 }}>
        {userId && !phoneNumberVerified && phoneVisible == true ? (
          <PhoneNumberForm
            className={css.form}
            initialValues={{ phoneNumber: currentPhoneNumber, phoneCode: currentPhoneCode }}
            savePhoneNumberError={savePhoneNumberError}
            currentUser={currentUser}
            onSubmit={async values => {
              onSubmitContactDetails({ ...values, currentPhoneNumber, currentPhoneCode });
              // // await setverifyIdVisible(true)
              // await setphoneVisible(false)
              // await setaddressVisible(true)
            }}
            onChange={onChange}
            inProgress={saveContactDetailsInProgress}
            ready={contactDetailsChanged}
            modifyStep={true}
            handleSteps={handleSteps}
            step={1}
          />
        ) : (
          ''
        )}

        {userId && phoneNumberVerified && !isEmailVerified && !isHost ? (
          <EmailVerificationFormAccountSettings
            userEmail={userEmail}
            modifyStep={true}
            handleSteps={handleSteps}
            step={2}
          />
        ) : null}

        {userId && phoneNumberVerified && !userLocation && (!isHost ? isEmailVerified : true) ? (
          <AddressDetailsForm
            className={css.form}
            initialValues={initialValues}
            currentUser={currentUser}
            inProgress={saveAddressDetailsInProgress}
            ready={saveAddressDetailsSuccess}
            saveAddressDetailsError={saveAddressDetailsError}
            onSubmit={values => {
              const { location, unitNumber, city, state, postalCode, streetNumber, suburb } = values;
              const {
                selectedPlace: { address, origin },
              } = location;
              const updatedValues = {
                location: {
                  search: address,
                  selectedPlace: {
                    address,
                    streetNumber,
                    origin: new LatLng(origin.lat, origin.lng),
                  },
                },
                unitNumber,
                streetNumber,
                suburb,
                state,
                city,
                postalCode,
              };
              onSubmitAddressDetailsForm(updatedValues);
              const properties = createRawPropertiesForGTM({
                props,
              });
              createConversionEvents(
                properties,
                EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
                'click'
              );
              EnableIDVerify = false;
            }}
            pageName={pageName}
            isVerification={true}
            modifyStep={true}
            handleSteps={handleSteps}
            step={isHost ? 2 : 3}
          />
        ) : null}

        {userId &&
          !isHost &&
          phoneNumberVerified &&
          userLocation &&
          isEmailVerified &&
          !dateOfBirth ? (
          <PersonalDetailsForm
            className={css.form}
            initialValues={dobInitialValues}
            currentUser={currentUser}
            inProgress={savePersonalDetailsInProgress}
            ready={savePersonalDetailsSuccess}
            saveAddressDetailsError={savePersonalDetailsError}
            onSubmit={values => {
              const { dateOfBirth } = values;
              const updatedValues = {
                dateOfBirth,
              };
              onSubmitPersonalDetailsForm(updatedValues);
            }}
            showGuide={true}
            modifyStep={true}
            handleSteps={handleSteps}
            step={4}
          />
        ) : null}
        <br></br>
        {userId &&
          phoneNumberVerified && !showThankYouPage &&
          userLocation && !continueToCredit &&
          (!isHost ? isEmailVerified && dateOfBirth && !isSubmittedOnfidoVerification : true) ? (
          <>
            <DynamicHeader />
            <JustToModifyStep modifyStep={true} handleSteps={handleSteps} step={isHost ? 3 : 5} title={'Identity Verification'} />
            <div className={css.identityVerificationGuide}>
              <p className={css.identityVerificationGuideHeading}>
                <FormattedMessage id="identityVerification.guideLinet1" /><br></br>
              </p>
              <p className={css.identityVerificationGuideText}>
                <FormattedMessage id="identityVerification.guideLinet2" /><br></br>
                <FormattedMessage id="identityVerification.guideLinet3" /><br></br>
                &nbsp; &#160;<FormattedMessage id="identityVerification.guideLinet4" /><br></br>
                &nbsp; &#160;<FormattedMessage id="identityVerification.guideLinet5" /><br></br>
              </p>
            </div>
            <div className={css.verificationModalButton} onClick={_handleInitiateVerification}>
              <Button>Start identity verification</Button>
            </div>
            <br></br>
            <div ref={modalReference} style={{ width: 200 }}>
              {' '}
            </div>
          </>
        ) : null}

        {userId &&
          phoneNumberVerified &&
          userLocation &&
          dateOfBirth &&
          !isHost && isSubmittedOnfidoVerification &&
          !isBackgroundCheckConsentAccepted ? (
          <div>
            <BackgroundCheckConsent
              modifyStep={true}
              handleSteps={handleSteps} step={6} title={'Background check consent'}
              isBackgroundCheckConsentAccepted={isBackgroundCheckConsentAccepted}
              handleContinueVerification={handleContinueVerification}
            />
          </div>
        ) : null}

        {userId &&
          phoneNumberVerified &&
          userLocation &&
          dateOfBirth &&
          !isHost &&
          isSubmittedOnfidoVerification &&
          isBackgroundCheckConsentAccepted &&
          !isCardVerified ? (
          <div>
            <JustToModifyStepCard
              modifyStep={true}
              handleSteps={handleSteps}
              step={7} title={'Credit Verification'}
              setCardVerificationModal={setCardVerificationModal}
            />
            {/* <CardVerificationPage
              transactInModal={true}
              currentUser={currentUser}
              onUpdateUserData={onUpdateUserData}
            /> */}
          </div>
        ) : null}

      </div>
    </>
  );
};


UserPictureIDVerificationComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,

};

const { bool, func } = PropTypes;

UserPictureIDVerificationComponent
  .propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  onSubmitAddressDetailsForm: func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;


  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  } = state.AddressDetailsPage;

  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    connectCalendarTabVisibility,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserData: data => dispatch(updateUserData(data)),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onSubmitPersonalDetailsForm: values => dispatch(savePersonalDetails(values)),
    onSubmitAddressDetailsForm: address_values => dispatch(saveAddressDetails(address_values)),
  };
};

const UserPictureIDVerification = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(UserPictureIDVerificationComponent);

export default UserPictureIDVerification;
