import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NamedLink } from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import css from './SectionFeaturedFlexCar.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { CitiesModalContentComponent } from '../../containers/LandingPageFlex/CitiesModal';

const SectionFeaturedFlexCar = () => {
  const history = useHistory();
  const featuredFlexCarIds = useSelector(state => state.SearchPage.featuredFlexCarIds);
  const fetchFeaturedFlexCarInProgress = useSelector(
    state => state.SearchPage.fetchFeaturedFlexCarInProgress
  );
  const refs = useMemo(() => featuredFlexCarIds.map(id => ({ type: 'listing', id })), [
    featuredFlexCarIds,
  ]);
  const listings = useSelector(state => getMarketplaceEntities(state, refs));
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const doFindCardsByCity = (city) => {
    const routes = routeConfiguration();
    let query = {};
    if (city) {
      query = { pub_listingTimezone: city };
    }
    const url = createResourceLocatorString(
      'LongTermRentalSearch',
      routes,
      {},
      { ...query },
    );
    history.push(url);
  };

  return (
    <div className={css.root}>
      {/* <div className={css.title}>Featured Cars</div> */}
      <div className={css.cityContainer}>
        <CitiesModalContentComponent onCitySelect={doFindCardsByCity} />
      </div>
      <NamedLink
        name="LongTermRentalSearch"
        to={{
          search: '',
        }}
        className={css.viewAllCar}
      >
        View all cars
      </NamedLink>
    </div>
  );
};

export default SectionFeaturedFlexCar;
