import React, { useState, useEffect, forwardRef, useRef } from 'react';
import QRCode from 'qrcode';
import DMLogo from '../../assets/logos/DriveMateQRLogo.jpg';

// Use forwardRef to allow the parent to access the QR code URL
const QRCodeGenerator = forwardRef(({ url, size = 200, logo = DMLogo }, ref) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const canvasRef = useRef(null);

  useEffect(() => {
    generateQRCodeWithLogo();
  }, [url]);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const generateQRCodeWithLogo = async () => {
    try {
      // Generate QR code as Data URL
      const qrCodeUrl = await QRCode.toDataURL(url, {
        width: size,
        margin: 2,
        errorCorrectionLevel: 'H',
        color: {
          dark: '#0D6786',
          light: '#ffffff',
        },
      });
      setQrCodeUrl(qrCodeUrl);

      // Load the QR code and logo as images
      const qrCodeImage = await loadImage(qrCodeUrl);
      const logoImage = await loadImage(logo);

      // Draw QR code and logo on the canvas
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Set canvas size
      canvas.width = size;
      canvas.height = size;

      // Draw QR code onto the canvas
      ctx.drawImage(qrCodeImage, 0, 0, size, size);

      // Calculate logo placement
      const logoSize = size * 0.3;
      const logoX = (canvas.width - logoSize) / 2;
      const logoY = (canvas.height - logoSize) / 2;

      // Draw logo onto the canvas
      ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

      // If parent ref is passed, expose the canvas to the parent
      if (ref) {
        ref.current = canvas;
      }
    } catch (error) {
      console.error('Error generating QR code with logo:', error);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>

      {/* Render the canvas, which will be used for download */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {/* Render QR code */}
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="Generated QR Code"
          style={{ width: size, height: size, borderRadius: '4px' }} // Optional: Add border radius
        />
      )}

      {/* Optionally render a logo over the QR code */}
      {logo && (
        <img
          src={logo}
          alt="Logo"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: size * 0.3, // Adjust logo size relative to QR code size
            height: size * 0.3,
            borderRadius: '4px', // Rounded corners for the logo
            zIndex: 1, // Ensure the logo is above the QR code
          }}
        />
      )}
    </div>
  );
});

export default QRCodeGenerator;
