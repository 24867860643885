import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { FieldUploadFile, Form } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './DrivingLicenseForm.css';

class DrivingLicenseFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { currentUser } = this.props;
    const initialUrls = {
      drivingLicenseFrontUrl: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.drivingLicenseFront,
      drivingLicenseBackUrl: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.drivingLicenseBack,
      hostHoldingDrivingLicensePhoto: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.hostHoldingDrivingLicensePhoto,
      passportFirstPage: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.passportFirstPage,
      passportLastPage: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.passportLastPage,
      frontalPhoto: !currentUser
        ? null
        : currentUser.attributes.profile.protectedData.frontalPhoto[0],
    };
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            formId,
            intl,
            values,
            isFromTripPage
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(
            rootClassName || css.root,
            className,
            isFromTripPage ? css.tripPageForm : ''
          );

          return (
            <Form className={classes} onSubmit={e => {}}>
              {true ? (
                <div className={css.tripPageFormOuter}>
                  <div className={css.row}>
                    <div className={css.column50}>
                      <div className={css.drivingLicenseUploadButtons}>
                        <FieldUploadFile
                          id="file-upload-front-driving-license"
                          name="file-upload-front-driving-license"
                          typeFile="front-driving-license"
                          label={() => 'Driving Licence Front copy'}
                          twoColumns={false}
                          rootClassName={css.tripPageUploadButtonRoot}
                          className={css.tripPageUploadButton}
                          currentUser={currentUser}
                          initialValues={[initialUrls.drivingLicenseFrontUrl]}
                          previewFileClassName={css.previewFile}
                          hideUpload={true}
                          showPreview={true}
                        />
                      </div>
                    </div>
                    <div className={css.column50}>
                      <div className={css.drivingLicenseUploadButtons}>
                        <FieldUploadFile
                          id="file-upload-front-driving-license"
                          name="file-upload-front-driving-license"
                          typeFile="front-driving-license"
                          label={() => 'Driving Licence Back copy'}
                          twoColumns={false}
                          rootClassName={css.tripPageUploadButtonRoot}
                          className={css.tripPageUploadButton}
                          currentUser={currentUser}
                          initialValues={[initialUrls.drivingLicenseBackUrl]}
                          previewFileClassName={css.previewFile}
                          hideUpload={true}
                          showPreview={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={css.row}>
                    <div className={css.column50}>
                      <div className={css.profileUploadButtons}>
                        <label>Profile Image</label>
                        <div className={css.profileUploadInputOuter}>
                          <FieldUploadFile
                            id="file-upload-front-driving-license"
                            name="file-upload-front-driving-license"
                            typeFile="front-driving-license"
                            label={() => ''}
                            twoColumns={false}
                            rootClassName={css.tripPageUploadButtonRoot}
                            className={classNames(css.tripPageUploadButton, css.profileUploadInput)}
                            currentUser={currentUser}
                            initialValues={[initialUrls.frontalPhoto]}
                            previewFileClassName={css.previewFile}
                            hideUpload={true}
                            showPreview={true}
                          />
                          <p className={css.profileUploadLabel}>
                            A profile picture is mandatory for bookings. Upload a recent clear
                            picture where your face is fully visible (no sunglasses or masks). Click
                            to upload.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={css.column50}>
                      <div className={css.profileUploadButtons}>
                        <label>Selfie with ID</label>
                        <div className={css.profileUploadInputOuter}>
                          <FieldUploadFile
                            id="file-upload-front-driving-license"
                            name="file-upload-front-driving-license"
                            typeFile="front-driving-license"
                            label={() => ''}
                            twoColumns={false}
                            currentUser={currentUser}
                            rootClassName={css.tripPageUploadButtonRoot}
                            className={classNames(css.tripPageUploadButton, css.profileUploadInput)}
                            initialValues={[initialUrls.hostHoldingDrivingLicensePhoto]}
                            previewFileClassName={css.previewFile}
                            hideUpload={true}
                            showPreview={true}
                          />
                          <p className={css.profileUploadLabel}>
                            Add a selfie, holding your driving license / NRIC / FIN next to your face. This will help us verify your profile faster. Click to upload.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={css.sectionTitle}>Passport (optional)</div>
                  <div className={css.row}>
                    <div className={css.column50}>
                      <div className={css.drivingLicenseUploadButtons}>
                        <FieldUploadFile
                          id="file-upload-front-driving-license"
                          name="file-upload-front-driving-license"
                          typeFile="front-driving-license"
                          label={() => 'Passport Front copy'}
                          twoColumns={false}
                          rootClassName={css.tripPageUploadButtonRoot}
                          className={css.tripPageUploadButton}
                          currentUser={currentUser}
                          initialValues={[initialUrls.passportFirstPage]}
                          previewFileClassName={css.previewFile}
                          hideUpload={true}
                          showPreview={true}
                        />
                      </div>
                    </div>
                    <div className={css.column50}>
                      <div className={css.drivingLicenseUploadButtons}>
                        <FieldUploadFile
                          id="file-upload-front-driving-license"
                          name="file-upload-front-driving-license"
                          typeFile="front-driving-license"
                          label={() => 'Passport Back copy'}
                          rootClassName={css.tripPageUploadButtonRoot}
                          className={css.tripPageUploadButton}
                          twoColumns={false}
                          currentUser={currentUser}
                          initialValues={[initialUrls.passportLastPage]}
                          previewFileClassName={css.previewFile}
                          hideUpload={true}
                          showPreview={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <FieldUploadFile
                    id="file-upload-front-driving-license"
                    name="file-upload-front-driving-license"
                    typeFile="front-driving-license"
                    label={() => 'Driving license Front'}
                    twoColumns={false}
                    currentUser={currentUser}
                    initialValues={[initialUrls.drivingLicenseFrontUrl]}
                    previewFileClassName={css.previewFile}
                    hideUpload={true}
                  />
                  <FieldUploadFile
                    id="file-upload-front-driving-license"
                    name="file-upload-front-driving-license"
                    typeFile="front-driving-license"
                    label={() => 'Driving license back'}
                    twoColumns={false}
                    currentUser={currentUser}
                    initialValues={[initialUrls.drivingLicenseBackUrl]}
                    previewFileClassName={css.previewFile}
                    hideUpload={true}
                  />
                  <FieldUploadFile
                    id="file-upload-front-driving-license"
                    name="file-upload-front-driving-license"
                    typeFile="front-driving-license"
                    label={() => 'Selfie With Driving license'}
                    twoColumns={false}
                    currentUser={currentUser}
                    initialValues={[initialUrls.hostHoldingDrivingLicensePhoto]}
                    previewFileClassName={css.previewFile}
                    hideUpload={true}
                  />
                  <FieldUploadFile
                    id="file-upload-front-driving-license"
                    name="file-upload-front-driving-license"
                    typeFile="front-driving-license"
                    label={() => 'Passport First Page (optional)'}
                    twoColumns={false}
                    currentUser={currentUser}
                    initialValues={[initialUrls.passportFirstPage]}
                    previewFileClassName={css.previewFile}
                    hideUpload={true}
                  />
                  <FieldUploadFile
                    id="file-upload-front-driving-license"
                    name="file-upload-front-driving-license"
                    typeFile="front-driving-license"
                    label={() => 'Passport Last Page (optional)'}
                    twoColumns={false}
                    currentUser={currentUser}
                    initialValues={[initialUrls.passportLastPage]}
                    previewFileClassName={css.previewFile}
                    hideUpload={true}
                  />
                </>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

DrivingLicenseFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

DrivingLicenseFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const DrivingLicenseForm = compose(injectIntl)(DrivingLicenseFormComponent);

DrivingLicenseForm.displayName = 'DrivingLicenseForm';

export default DrivingLicenseForm;
