import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './DataDeletion.css';

const DataDeletion = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 15th July 2021</p>
      <div style={{ color: '#00a3ad' }}>
        <h2 className={css.mainTitle}>Data Deletion Instructions</h2>

        <h3>Facebook Data Deletion Instructions URL for Drive mate</h3>
        <p>
          Drive mate is a peer to peer car rental platform and we do not save your personal data in
          our server. But according to the Facebook Instant website Platform rules, we have to
          provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to
          delete your website activities for Researcherstore, you can remove your activities by the
          following instructions.
        </p>
        <ul>
          <li className={css.line}>
            1. &nbsp; Go to Your Facebook Account’s Setting &amp; Privacy. Click 'Setting'.
          </li>
          <li className={css.line}>
            2. &nbsp; Then, go to 'Apps and Websites' and you will see all of your Apps activities.
          </li>
          <li className={css.line}>3. &nbsp; Select the option box of 'Drive mate'.</li>
          <li className={css.line}>4. &nbsp; Click 'Remove' button.</li>
          <li className={css.line}>
            5. &nbsp; Congratulation, you have successfully removed your activities on Drive mate.
          </li>
        </ul>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

DataDeletion.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

DataDeletion.propTypes = {
  rootClassName: string,
  className: string,
};

export default DataDeletion;
