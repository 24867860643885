import React, { memo } from 'react';
import { array, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionRulesMaybe.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import badge from '../../assets/badge.png';
import not_suitable_for_kids from '../../assets/not_suitable_for_kids.png';
import no_smoking from '../../assets/no_smoking.png';
import not_permitted from '../../assets/not_permitted.png';
import pet_friendly from '../../assets/pet_friendly.png';

const keyUrlMapping = rule => {
  let url = badge;
  if (rule.key === 'No Pets') {
    url = pet_friendly;
  }
  if (rule.key === 'Not suitable for kids') {
    url = not_suitable_for_kids;
  }
  if (rule.key === 'No smoking') {
    url = no_smoking;
  }
  if (rule.key === 'Not permitted to drive to Malaysia') {
    url = not_permitted;
  }
  return url;
};

const SectionRulesMaybe = memo(
  props => {
    let index = 0;
    const {
      className,
      rootClassName,
      titleClassName,
      subTitle,
      keyRules,
      title,
      keyRule,
      selectedOptions,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    return keyRules || keyRule ? (
      <div className={classes}>
        <h2 className={classNames(css.title, titleClassName || css.null)}>Key rules</h2>
        {subTitle && (
          <p className={css.subTitle}>
            <FormattedMessage id={subTitle} />
          </p>
        )}
        {!!keyRules && Array.isArray(keyRules) && keyRules.length > 0 ? (
          keyRules.map(rule => {
            if ((selectedOptions || []).filter(select => select === rule.key).length > 0) {
              index = index + 1;
              return (
                <li className={css.item}>
                  <div className={css.itemWrapper}>
                    {/* <span className={css.iconWrapper}>
                  <IconCheck isVisible={isSelected} />
                </span> */}
                    <div className={css.icon}>
                      <img src={keyUrlMapping(rule)} alt="" />
                    </div>
                    <div className={css.labelWrapper}>{rule.label}</div>
                  </div>
                </li>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className={css.noRules}>
            <FormattedMessage id="SectionRulesMaybe.noKeyRules" />
          </div>
        )}
        {/* {!!keyRules &&
          Array.isArray(keyRules) &&
          keyRules.length > 0 &&
          selectedOptions &&
          selectedOptions.length <= 0 && (
          )} */}
        {!!keyRule && typeof keyRule === 'string' && !isNaN(keyRule) && `${keyRule}km`}
        {!!keyRule && typeof keyRule === 'string' && isNaN(keyRule) && `${keyRule}`}
      </div>
    ) : null;
  },
  (prev, next) => {
    return isEqual(
      pick(prev, ['keyRules', 'selectedOptions']),
      pick(next, ['keyRules', 'selectedOptions'])
    );
  }
);

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  // publicData: shape({
  //   rules: string,
  // }).isRequired,
  keyRules: array,
};

export default SectionRulesMaybe;
