import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { StripeConnectAccountForm } from '../../forms';
import { ListingLink, StripeConnectAccountStatusBox } from '../../components';
import config from '../../config';

import css from './EditListingPayoutsPanel.css';
import { connect } from 'react-redux';
import { TRACKING_DEVICE, VERIFICATION } from '../EditListingWizard/EditListingWizardTab';
import { createResourceLocatorString } from '../../util/routes';
import { updateIdentityForPayout } from '../../ducks/user.duck';
import routeConfiguration from '../../routeConfiguration';

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

const EditListingPayoutsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    submitButtonId,
    currentUser,
    updateIdentityForPayout,
    stripeProps,
    intl,
  } = props;

  const {
    getAccountLinkInProgress,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    stripeAccountData,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccountFetched,
    onPayoutDetailsFormChange,
    onPayoutDetailsSubmit,
    onGetStripeConnectAccountLink,
    stripeAccount,
    stripeConnected,
    sParams,
    requirementsMissing,
  } = stripeProps || {};

  // Create return URL for the Stripe onboarding form
  const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
    const path =
      pathParams && pathParams.tab === VERIFICATION
        ? createResourceLocatorString(
            'EditListingStripeOnboardingPage',
            routes,
            { ...pathParams, returnURLType, tab: TRACKING_DEVICE },
            { shouldSubmit: true }
          )
        : createResourceLocatorString(
            'EditListingStripeOnboardingPage',
            routes,
            { ...pathParams, returnURLType },
            { shouldSubmit: true }
          );
    const root = rootURL.replace(/\/$/, '');
    return `${root}${path}`;
  };


  const [userUpdateLoading, setUserUpdateLoading] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const accountId = stripeConnected ? stripeAccount.id : null;

  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const { returnURLType, ...pathParams } = sParams || {};
  const successURL = createReturnURL('success', rootURL, routes, pathParams);
  const failureURL = createReturnURL('failure', rootURL, routes, pathParams);

  const showVerificationError = returnURLType === 'failure';
  const showVerificationNeeded = stripeConnected && requirementsMissing;


  const formDisabled = getAccountLinkInProgress;
  const savedCountry = stripeAccountData ? stripeAccountData.country : null;


  const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
    getLinkFn({ type, ...commonParams })
      .then(url => {
        window.location.href = url;
      })
      .catch(err => console.error(err));
  };

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPayoutsPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPayoutsPanel.createListingTitle" />
  );

  const bankName = !currentUser ? '' : currentUser.attributes.profile.protectedData.bankName;
  const bankBranchCode = !currentUser
    ? ''
    : currentUser.attributes.profile.protectedData.bankBranchCode;
  const bankAccountNumber = !currentUser
    ? ''
    : currentUser.attributes.profile.protectedData.bankAccountNumber;
  const bankCountry = !currentUser
    ? currentListing.attributes.publicData.location.country
    : currentUser.attributes.profile.protectedData.bankCountry;

  const initialValues = {
    bankNameSelection: bankName,
    branchCode: bankBranchCode,
    bankAccountNumber: bankAccountNumber,
    bankCountrySelection: bankCountry,
  };

  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <div className={css.titleSection}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p>
          Add your bank account details so we can make the payments for all the bookings that are
          made.
        </p>
      </div>
      {/* <EditListingPayoutsForm
        className={css.form}
        initialValues={initialValues}
        onSubmit={async values => {
          setUserUpdateLoading(true)
          const { bankNameSelection, branchCode,
            bankAccountNumber, bankCountrySelection } = values;

          const updatedValues = {
              bankName: bankNameSelection,
              bankCountry:bankCountrySelection,
              bankBranchCode: branchCode,
              bankAccountNumber: bankAccountNumber
          };


          await updateIdentityForPayout(updatedValues, "HOST")
          onSubmit(updatedValues);
          setUserUpdateLoading(false)
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
        updateUserLoader={userUpdateLoading}
      /> */}
      <div className={css.form}>
        {/* <h1 className={css.modalTitle}>
          <FormattedMessage id="EditListingPhotosPanel.payoutModalTitleOneMoreThing" />
          <br />
          <FormattedMessage id="EditListingPhotosPanel.payoutModalTitlePayoutPreferences" />
        </h1> */}
        <p className={css.modalMessage}>
          <FormattedMessage id="EditListingPhotosPanel.payoutModalInfo" />
        </p>
        {!currentUserLoaded ? (
          <FormattedMessage id="StripePayoutPage.loadingData" />
        ) : (
          <StripeConnectAccountForm
            disabled={formDisabled}
            inProgress={payoutDetailsSaveInProgress}
            ready={payoutDetailsSaved}
            stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
            savedCountry={savedCountry}
            submitButtonText={intl.formatMessage({
              id: 'StripePayoutPage.submitButtonText',
            })}
            stripeAccountError={
              createStripeAccountError || updateStripeAccountError || fetchStripeAccountError
            }
            stripeAccountFetched={stripeAccountFetched}
            onChange={onPayoutDetailsFormChange}
            onSubmit={onPayoutDetailsSubmit}
            onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
            stripeConnected={stripeConnected}
            customButtonWrapper={(buttonJSX) => {
              return (
                <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton}>
                    {buttonJSX}
                  </div>
                </div>
              </div>
              )
            }}
          >
            {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
              <StripeConnectAccountStatusBox
                type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                inProgress={getAccountLinkInProgress}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                  'custom_account_verification'
                )}
              />
            ) : stripeConnected && savedCountry ? (
              <StripeConnectAccountStatusBox
                type="verificationSuccess"
                inProgress={getAccountLinkInProgress}
                disabled={payoutDetailsSaveInProgress}
                onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                  'custom_account_update'
                )}
              />
            ) : null}
          </StripeConnectAccountForm>
        )}
      </div>
    </div>
  );
};

EditListingPayoutsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingPayoutsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

const mapDispatchToProps = dispatch => ({
  updateIdentityForPayout: (userData, type) => dispatch(updateIdentityForPayout(userData, type)),
});

export default connect(null, mapDispatchToProps)(injectIntl(EditListingPayoutsPanel));
