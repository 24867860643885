import React from 'react';
import { FieldTextInput } from '../../components';

const parse = value => {
  console.log(value, value ? value.replace(/\s/g, '') : '');
  return value ? value.replace(/[^\d]/g, '') : '';
};

const FieldABNInput = props => {
  const inputProps = {
    type: 'text',
    parse: parse,
    maxLength: 11,
    ...props,
  };

  return <FieldTextInput {...inputProps} />;
};

export default FieldABNInput;
