import React from 'react';
import { ExternalLink, PrimaryButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAHWhyDriveLah.css';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const BAHWhyDriveLah = props => {
  const { isAuthenticated } = props;

  return (
    <section className={css.whyDriveLahSection}>
      <div className={css.textBox}>
        <h2 className={css.title}>
          <FormattedMessage id="BecomeAHostPage.whyDriveLahTitle" values={{ newline: <br /> }} />
        </h2>
        <div className={css.description}>
          {/* <FormattedMessage id="BecomeAHostPage.whyDriveLahDesc" /> */}
          <p>
          You'll earn more per trip sharing your car on Drive mate compared to other similar platforms, with less risk. Hosts set the hire rates and availability. With Drive mate’s unmatched comprehensive insurance cover (for damage, theft, 3rd party loss, hail, flood and fire), plus no host deductible, you really can't go wrong.​
          </p>
          <ExternalLink href={`https://help.${DOMAIN_HELP_URL}/kb/en`} className={css.link}>
            <PrimaryButton type="button" className={css.primaryBtn}>
              See how
            </PrimaryButton>
          </ExternalLink>
        </div>
      </div>
    </section>
  );
};

export default BAHWhyDriveLah;
