import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import css from './FieldSelectDay.css';
import config from '../../config';
import { ValidationError } from '../../components';

const Day = props => {
  const { value, onSelect, day, initialsOnly, peakDays } = props;
  const { key, label} = day;
  const labelText = initialsOnly ? label.charAt(0).toUpperCase() : label;
  const ensuredValue = Array.isArray(value) ? value : [];
  let isSelected = ensuredValue.includes(key);
  if(peakDays && !isSelected) {
    isSelected =  !peakDays.includes(key)
  }

  const classes = classNames(css.day, {
    [css.selectedDay]: isSelected
  });

  return (
    <div className={classes} onClick={() => onSelect(key, isSelected)}>
      {labelText}
    </div>
  )
};

const Days = props => {
  const { children, ...rest } = props;
  return (
    <div className={css.days} {...rest}>
      {React.Children.map(children, child => child)}
    </div>
  )
}

const FieldSelectDayComp = props => {
  const { rootClassName, className, input, id, label, customOnChange, initialsOnly, meta, peakDays } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { value, onFocus, onBlur, onChange } = input;

  const handleSelect = (key, isSelected) => {
    if (typeof customOnChange === 'function')
      customOnChange(key, isSelected);
    onFocus();
    let ensuredValue = Array.isArray(value) ? value : [];
    if (isSelected)
      ensuredValue = ensuredValue.filter(day => day !== key);
    else
      ensuredValue.push(key);
    onChange(ensuredValue);
    onBlur();
  }

  return (
    <div className={classes}>
      <label htmlFor={id} className={css.label}>
        {label}
      </label>
      <Days id={id}>
        {
          config.custom.daysOfWeek.map(day => <Day value={value} day={day} peakDays={peakDays} initialsOnly={initialsOnly} onSelect={handleSelect} />)
        }
      </Days>
      <ValidationError fieldMeta={meta} />
    </div>
  )
};

const FieldSelectDay = ({ onUnmount, ...rest }) => {
  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function')
        onUnmount();
    }
  }, []);

  return <Field component={FieldSelectDayComp} {...rest} />;
};

export default FieldSelectDay;