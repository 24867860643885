import config from '../config';

const apiUrl = config.apiUrl;

export const fetchShuLastLocation = (tId, fleetId) => {
  return fetch(apiUrl + '/api/shu-last-location', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tId,
      fleetId,
    }),
  }).then(res => res.json());
};

export const fetchPrimaryGpsLocation = (tId, listingId) => {
  return fetch(apiUrl + '/api/v1/gps/get-device-location', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId: listingId,
      transactionId: tId,
    }),
  }).then(res => res.json());
};

export const checkTripCondition = (tId) => {
  return fetch(apiUrl + `/api/sentrilock/${tId}/check-trip-conditions`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(res => res.json());
};
