import config from '../config';
import { DOB_MOBILE_KEYS, INITIAL_DOB, INPUT_TYPE } from './constants';
import { initiateExperimentEventFromListing } from '../util/gtm/gtmHelpers';
import { EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B } from './gtm/gtmConstants';
import { STATE_WITH_SHORT_NAME } from './constants/general';
import { get } from 'lodash';

export const getCountryCode = () => {
  let code = '';
  if (config && config.custom && config.custom.phoneCode.length) {
    let country = config.maps &&
      config.maps.search &&
      config.maps.search.countryLimit.length ?
      config.maps.search.countryLimit[0] : null;
    if (country) {
      config.custom.phoneCode.every(function (element) {
        if (element.cc === country) {
          code = element.key;
          return false;
        } else {
          return true;
        }
      })
    }
  }
  return code;
};

/**
 * This method is to check if given fuelType is in petrol category
 * @param {string} fuelType
 * @returns {boolean}
 */
export const isFuelTypeIsPetrol = (fuelType) => {
  return fuelType && fuelType === 'petrol' ||
    fuelType === 'premiumUnleaded_95Petrol' ||
    fuelType === 'premiumUnleaded_98Petrol' ||
    fuelType === 'regularUnleadedPetrol' ||
    fuelType === 'E10_UnleadedPetrol';
};

/**
 * Generate random number
 * @returns {string}
 */
export const getRandomNumber = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Convert valid numeric string value to number or null
 * @param {string} str
 * @returns {number|null}
 */
export const toNumber = str => {
  const num = Number.parseInt(str, 10);
  return Number.isNaN(num) ? null : num;
};

/**
 * This method will convert our params to date of birth object
 * @param {Object<DOB_MOBILE_KEYS>} params - Date of birth parameters
 * @returns {{day: number | null, month: number | null, year: number | null}} - Converted date of birth object
 */
export const toDateOfBirth = (params) => {
  let result = { day: null, month: null, year: null };
  Object.keys((params || {})).forEach((key) => {
    switch (key) {
      case DOB_MOBILE_KEYS.day_1:
      case DOB_MOBILE_KEYS.day_2:
        // WARN: This will reset any previously set day value
        if (params[DOB_MOBILE_KEYS.day_1]) {
          result.day = params[DOB_MOBILE_KEYS.day_1];
        }
        // This will append to the first value
        if (params[DOB_MOBILE_KEYS.day_2]) {
          result.day = result.day ? result.day + params[DOB_MOBILE_KEYS.day_2] : params[DOB_MOBILE_KEYS.day_2];
        }
        result.day = toNumber(result.day); // Cast to number
        break;
      case DOB_MOBILE_KEYS.month_1:
      case DOB_MOBILE_KEYS.month_2:
        // WARN: This will reset any previously set month value
        if (params[DOB_MOBILE_KEYS.month_1]) {
          result.month = params[DOB_MOBILE_KEYS.month_1];
        }
        // This will append to the first value
        if (params[DOB_MOBILE_KEYS.month_2]) {
          result.month = result.month ? result.month + params[DOB_MOBILE_KEYS.month_2] : params[DOB_MOBILE_KEYS.month_2];
        }
        result.month = toNumber(result.month); // Cast to number
        break;
      case DOB_MOBILE_KEYS.year_1:
      case DOB_MOBILE_KEYS.year_2:
      case DOB_MOBILE_KEYS.year_3:
      case DOB_MOBILE_KEYS.year_4:
        // WARN: This will reset any previously set year value
        if (params[DOB_MOBILE_KEYS.year_1]) {
          result.year = params[DOB_MOBILE_KEYS.year_1];
        }
        // This will append to the result.year
        if (params[DOB_MOBILE_KEYS.year_2]) {
          result.year = result.year ? result.year + params[DOB_MOBILE_KEYS.year_2] : params[DOB_MOBILE_KEYS.year_2];
        }
        // This will append to the result.year
        if (params[DOB_MOBILE_KEYS.year_3]) {
          result.year = result.year ? result.year + params[DOB_MOBILE_KEYS.year_3] : params[DOB_MOBILE_KEYS.year_3];
        }
        // This will append to the result.year
        if (params[DOB_MOBILE_KEYS.year_4]) {
          result.year = result.year ? result.year + params[DOB_MOBILE_KEYS.year_4] : params[DOB_MOBILE_KEYS.year_4];
        }
        result.year = toNumber(result.year); // Cast to number
        break;
      default:
        break;
    }
  });

  return result;
}

/**
 * This method will convert the number based DOB (day (DD), month (MM), year (YYYY)) to INITIAL_DOB format values
 * @param {{day: number, month: number, year: number}} dob - Date of birth in numbers (DD, MM, YYYY) in its own keys
 * @returns {INITIAL_DOB} - Converted INITIAL_DOB object
 */
export const fromDateOfBirth = (dob) => {
  let result = INITIAL_DOB;

  const doPaddingNeeded = num => {
    if (typeof num === 'number' && num >= 0 && num < 10) {
      return `0${num}`;
    }
    return num.toString();
  };

  Object.keys((dob || {})).forEach((key) => {
    switch (key) {
      case 'day':
        if (dob[key]) {
          let value = doPaddingNeeded(dob[key]);
          let str = value.toString();
          if (str && str.length && str.length >= 1 && str.length <= 2) {
            result[DOB_MOBILE_KEYS.day_1] = str[0] || '';
            result[DOB_MOBILE_KEYS.day_2] = str[1] || '';
          }
        }
        break;
      case 'month':
        if (dob[key]) {
          let value = doPaddingNeeded(dob[key]);
          let str = value.toString();
          if (str && str.length && str.length >= 1 && str.length <= 2) {
            result[DOB_MOBILE_KEYS.month_1] = str[0] || '';
            result[DOB_MOBILE_KEYS.month_2] = str[1] || '';
          }
        }
        break;
      case 'year':
        if (dob[key]) {
          let str = dob[key].toString();
          if (str && str.length && str.length >= 1 && str.length <= 4) {
            result[DOB_MOBILE_KEYS.year_1] = str[0] || '';
            result[DOB_MOBILE_KEYS.year_2] = str[1] || '';
            result[DOB_MOBILE_KEYS.year_3] = str[2] || '';
            result[DOB_MOBILE_KEYS.year_4] = str[3] || '';
          }
        }
        break;
      default:
        break;
    }
  });
  return result;
}

/**
 * Validates the given day, month, and year inputs and returns them as a valid date object if they form a valid date.
 * If the inputs do not represent a valid date, it returns null.
 *
 * @param {{day: string | number, month: string | number, year: string | number}} dateObj - An object containing the day, month, and year inputs.
 * @returns {{year: string | number, month: string | number, day: string | number} | null} - A valid date object if the inputs form a valid date, otherwise null.
 */
export const onValidDateFromSelected = ({ day, month, year }) => {
  /**
   * Checks if the given date is valid.
   * @param {Date} date - The date object to validate.
   * @param {number} year - The year of the date.
   * @param {number} month - The month of the date.
   * @param {number} day - The day of the date.
   * @returns {boolean} - true if the date is valid, otherwise false.
   */
  const isValidDate = (date, year, month, day) => {
    const yearsMatch = date.getFullYear() === year;
    const monthsMatch = date.getMonth() + 1 === month;
    const daysMatch = date.getDate() === day;
    return yearsMatch && monthsMatch && daysMatch;
  };

  const dayNum = toNumber(day);
  const monthNum = toNumber(month);
  const yearNum = toNumber(year);

  // Check if all inputs (day, month, and year) are valid numbers
  // also, added yearNum.toString().length check to avoid validation failure due to auto year calculation through js (ex: new Date(19, 11, 06) , it's a valid date but year is 1919)
  if (dayNum !== null && monthNum !== null && yearNum !== null && yearNum.toString().length === 4) {
    // Create a Date object using the provided year, month, and day
    const d = new Date(yearNum, monthNum - 1, dayNum);

    // Check if the created date is a valid date
    if (isValidDate(d, yearNum, monthNum, dayNum)) {
      // Return the valid date object
      return { year, month, day };
    }
  }

  // Return null if the inputs do not represent a valid date
  return null;
};

/**
 * Function to focus on the next input element when the current input element reaches its maximum length.
 * If the current input element does not have a maxlength attribute, it will focus on the previous input element.
 *
 * @param {HTMLInputElement} x - The input element that triggers the focus change.
 * @returns {void}
 */
export const onFocusNext = (x) => {
  // Get the maximum length (maxlength) of the current input element
  let ml = ~~x.getAttribute('maxlength');

  const findSiblingInput = (el, step) => {
    let sibling = el[step] || el.parentElement[step];
    // Loop to find the next input element of type "text"
    while (sibling) {
      if (sibling.tagName === 'INPUT' && sibling.type === INPUT_TYPE) {
        return sibling;
      }
      // Check for childNodes to iterate through the children
      if (sibling.childNodes && sibling.childNodes.length) {
        if (step === 'previousSibling') {
          // Iterate through childNodes in reverse order if step is 'previousSibling'
          for (let i = sibling.childNodes.length - 1; i >= 0; i--) {
            const childNode = sibling.childNodes[i];
            if (childNode.tagName === 'INPUT' && childNode.type === INPUT_TYPE) {
              return childNode;
            }
          }
        } else {
          // Iterate through childNodes from the beginning if step is 'nextSibling'
          for (let i = 0; i < sibling.childNodes.length; i++) {
            const childNode = sibling.childNodes[i];
            if (childNode.tagName === 'INPUT' && childNode.type === INPUT_TYPE) {
              return childNode;
            }
          }
        }
      }
      sibling = sibling[step];
    }
    return sibling;
  };

  // Check if the maximum length is set and the current input value has reached the maximum length
  if (ml && x.value.length >= ml) {
    // Find the next input element inside the parent
    const nextSiblingInput = findSiblingInput(x, 'nextSibling');
    // If the next input element is found, focus on it
    if (nextSiblingInput) {
      nextSiblingInput.focus();
    }
    //  else {
    //   // Find the parent's next input element inside the parent
    //   const nextParentSiblingInput = findSiblingInput(x, 'nextSibling');
    // }
  } else {
    // Find the previous input element inside the parent
    const prevSiblingInput = findSiblingInput(x, 'previousSibling');
    // If the previous input element is found, focus on it
    if (prevSiblingInput) {
      prevSiblingInput.focus();
    }
  }
};

// export const sortByVToR = (a, b) => {
//   let vToRA = a.attributes.publicData.v_to_r;
//   let vToRB = b.attributes.publicData.v_to_r;

//   // Treat undefined values as infinity
//   if (vToRA === undefined) {
//     vToRA = Infinity;
//   }
//   if (vToRB === undefined) {
//     vToRB = Infinity;
//   }

//   return vToRB - vToRA;
// };


export const sortByVToR = (a, b) => {
  const vToRA = a.attributes.publicData.v_to_r;
  const vToRB = b.attributes.publicData.v_to_r;

  if (vToRA === undefined && vToRB === undefined) {
    return 0; // Both values are undefined, so they are considered equal
  } else if (vToRA === undefined || vToRA === Infinity) {
    return 1; // Place undefined and Infinity values at the end
  } else if (vToRB === undefined || vToRB === Infinity) {
    return -1; // Place undefined and Infinity values at the end
  } else {
    return vToRB - vToRA; // Compare other values normally
  }
};
/**
 * Function to handle the search experiment for autocomplete.
 *
 * @param {Object} params - An object containing the parameters for the search experiment.
 * @param {Function} params.setSearchDateTime - The function to set the search date and time.
 * @param {Object} params.rawInitialValues - The raw initial values for the search.
 * @param {boolean} params.isSearching - A flag indicating if the search is in progress.
 * @param {Function} params.setIsSearching - The function to set the flag for search in progress.
 * @param {Object} params.location - The location object for the search.
 * @returns {void}
 */
export const onHandleAutocompleteExperiment = ({ setSearchDateTime, rawInitialValues, isSearching, setIsSearching, location }) => {
  // Check if localStorage is available in the browser
  // if (typeof localStorage !== 'undefined') {
  //   // Check if the 'searchExperiment' key is not present in localStorage
  //   if (localStorage.getItem('searchExperiment') == null) {
  //     // Define search experiment cases
  //     const items = [EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B];
  //     // Randomly select one experiment variant
  //     const item = items[Math.floor(Math.random() * items.length)];
  //     // Create an experiment object with the selected variant
  //     let expObject = {
  //       name: 'Search Experiment',
  //       variant: item,
  //     };
  //     // Store the experiment object in localStorage
  //     localStorage.setItem('searchExperiment', JSON.stringify(expObject));

  //     // Initiate the experiment event from the listing with the selected variant
  //     initiateExperimentEventFromListing({
  //       props: { setSearchDateTime, rawInitialValues, isSearching, setIsSearching, location },
  //       event: 'Search Experiment',
  //       experimentData: {},
  //     });
  //   } else {
  //     // If 'searchExperiment' key is already present in localStorage,
  //     // initiate the experiment event with the stored variant
  //     initiateExperimentEventFromListing({
  //       props: { setSearchDateTime, rawInitialValues, isSearching, setIsSearching, location },
  //       event: 'Search Experiment',
  //       experimentData: {},
  //     });
  //   }
  // }
};
// export const doSearchExperiment = () => {
//   if (localStorage && localStorage.setItem) {
//     const items = [EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B];
//     // Randomly select one experiment variant
//     const item = items[Math.floor(Math.random() * items.length)];
//     // Create an experiment object with the selected variant
//     let expObject = {
//       name: 'Search Experiment',
//       variant: item,
//     };
//     // Store the experiment object in localStorage
//     localStorage.setItem('searchExperiment', JSON.stringify(expObject));
//   }
// }

/**
 * This method will return the suburb from listing
 * @param {FlexListingObject} l
 * @returns {string}
 */
export const getSuburbForListing = (l) => {
  return get(l, 'attributes.publicData.location.suburb', '') || '';
}

/**
 * This method returns the state in sort form if found in our constant
 * @param {FlexListingObject} l
 * @returns {string}
 */
export const getShortStateForListing = (l) => {
  const state = get(l, 'attributes.publicData.location.state', '') || '';
  const found = STATE_WITH_SHORT_NAME.find((item) => {
    if (item.state.toLowerCase() === state.toLowerCase()) {
      return item;
    }
  });
  return found && found.short ? found.short : state;
}
