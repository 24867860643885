import React from 'react';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import { connect } from 'react-redux';
import classNames from 'classnames';

import css from './AboutPage.css';
import image from './people_hug.jpg';
import logoImage from '../../assets/logos/logo-footer@200.png';
import drivemateLogo from '../../assets/logos/drivemate-logo.svg';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const AboutPage = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;
  const { siteInstagramPage, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <StaticPage
      title="About Us | Drive mate "
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Drivelah',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* <h1 className={css.pageTitle}>Experience the unique Finnish home sauna.</h1> */}
          <div className={css.coverImage} src={image} alt="My first ice cream."></div>

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <img className={css.logo} src={drivemateLogo} alt="My first ice cream." />
              <p className={css.description}>“Sharing is caring!<br />We are passionate
                about our planet and want to take care of it in a more efficient way! ”</p>
            </div>

            <div className={css.contentMain}>
              <h1 className={css.aboutUs}>
                What We’re About
              </h1>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                How we get around is constantly changing.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                We wanted to create a concept fulfilling the demand amidst a growing car sharing industry while providing remarkable service and saving the environment.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Drive mate ticks all these boxes.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                As we progress technologically, the world needs to innovate to help reduce environmental impacts on both national and worldwide spectrums.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Drive mate is the latest, safest and most cost-effective car rental platform in Australia providing a way for you and your neighbours to connect through a shared demand.
              </p>
              {/* <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Our unique car sharing app is stacked with benefits and features you’ll struggle to find using traditional car hire companies. With tonnes of vehicles to pick from, a fully verified guest network and an amazing insurance structure, Drive mate is the ultimate solution to safe and simple car rental in Australia.
              </p> */}
              <h2 className={css.subtitle}>
                Who benefits from Drive mate and how?
              </h2>
              <ul className={css.contentListing}>
                <li>
                  <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                    <strong>People who need cars</strong> – Neighbours wanting to borrow cars don’t have to look far. There are hundreds to choose from and guests can enjoy keyless, in-app access to cars!
                  </p>
                </li>
                <li>
                  <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                    <strong>Car owners</strong> – Car ownership can be expensive. Why not make some extra cash to support it when your car’s free? All vehicles are fully insured* and you’ll be in complete control of your rates, who drives your car and when.
                  </p>
                </li>
                <li>
                  <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                    <strong>Everyone in the long-run!</strong> – With the environment suffering a little more each year, there’s no better time to act than now. We are Drive mate are doing our bit with you achieving a cleaner, greener, more sustainable planet together by reducing active vehicle numbers in our cities.
                  </p>
                </li>
              </ul>

              {/* <h2 className={css.subtitle}>
                Where Drive mate All Started
              </h2>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Drive mate actually originated from Australia (locally known as Drive mate) and has proved to be an incredible success across the country.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                With goals of expanding our concept to Australia, we’ve introduced millions of Aussies to a brand new way to rent cars that’s cheaper, easier and more secure than ever before.
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Ultimately, we’d like to see mass vehicle ownership start to phase out, while convincing our peers that private car sharing is the way to go!
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Reducing the amount of wasted hours where existing cars could be in use is what we’re really striving to accomplish. We believe providing an advanced platform that makes car sharing a breeze encourages more people to onboard our vision while benefiting from an all-round amazing service.
              </p> */}

              {/* <img className={css.infinityImageMobile} src={infinityImage}></img>
              <img className={css.infinityImage} src={infinityImage}></img> */}

              <h2 className={css.subtitle}>
                Talk to us
              </h2>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Ask us anything and we’ll be more than happy to discuss it!
              </p>
              <p className={classNames(css.defaultTextColor, css.aboutUsText)}>
                Shoot through an enquiry email at {SUPPORT_EMAIL} or give us a call on +61341602205 for a helpful one-on-one!
              </p>

              {/* <div className={css.contentSideMobile}>
                <img className={css.logo} src={logoImage} alt="My first ice cream." />
                <p className={css.description}>“Sharing is caring!<br />We are passionate
                about our planet and want to take care of it in a more efficient way! ”</p>
              </div> */}

              {/* <h2 className={css.subtitle}>
                We are founded in Singapore.
              </h2> */}

              {/* <p className={css.defaultTextColor}>
                In Singapore we have 11,520,548 of unused car hours per day. That is a waste. We at Drive mate strive to reduce that number by enabling people to share their car when they don’t need it.
              </p>
              <p className={css.defaultTextColor}>
              We envision a society where sharing vehicles becomes the norm; everyone can access a variety of cars at the click of a button and smart technology makes the experience hassle free.
              </p>
              <p className={css.defaultTextColor}>
              We want to help build that future and do good for the city we love.
              </p> */}

              {/* <h2 className={classNames(css.subtitle, css.getInTouch)}>Get in touch</h2>
              <p className={css.contactText}>
              We are happy to help you in anything you have in your mind.<br/>Best way to reach us is by emailing us at {' '}
                <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</ExternalLink> or {' '}
                call us at <ExternalLink href="tel:+61341602205">+61341602205</ExternalLink>.
              </p>
              <p className={css.contactText}>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink> pages.
              </p> */}
            </div>
          </div>

          <div className={css.background}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h1>
                  Working together creates a more promising outlook for our future. Join Drive mate and share the passion.
                </h1>
                <p>
                  Going green truly is the answer. What we can achieve through sharing is much more powerful than what we’ll change individually.
                </p>
                <p>
                  Reducing our planet’s deterioration starts with minimising activity that pollutes our communities and natural resources. Drive mate offers something better than public transport or costly car ownership.
                </p>
                <p>
                  Whether you just want to pop into the shops, spend a day moving house or take a weekend trip, Drive mate has a car rental solution for you.
                </p>
              </div>
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <div className={css.contentContainer}>
              <h1 className={css.slogan}>Ready to go<br />Drive mate?</h1>
              <div className={css.buttons}>
                {!isAuthenticated &&
                  <NamedLink
                    name="SignupPage"
                    className={classNames(css.secondaryButton, css.joinButton)}
                    onClick={onLoginOrSignupClick}
                  >
                    <FormattedMessage id="AboutPage.join" />
                  </NamedLink>
                }
                {isAuthenticated &&
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: "?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&showAlert=true"
                    }}
                    className={css.secondaryButton}
                  >
                    <FormattedMessage id="AboutPage.findACar" />
                  </NamedLink>
                }
                {isAuthenticated &&
                  <NamedLink
                    name="NewListingPage"
                    className={css.defaultButton}
                  >
                    <FormattedMessage id="AboutPage.listYourCar" />
                  </NamedLink>
                }
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated,
  };
};
const mapDispatchToProps = dispatch => ({
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
