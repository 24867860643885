import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink, Modal } from '../../components';

import css from './InsurancePanelNewLongTerm.css';
import arrowDown from '../../assets/arrow-down-blue.svg';
import insurancePdf from './insurancePdf.pdf';
import { checkIsOldCar } from '../../util/dates';

const INSURANCE_PRIVATE = 'private';
const INSURANCE_COMMERCIAL = 'commercial';

class InsurancePanelNewLongTerm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  openInsurancePolicy = (type, url) => {
    this.setState({
      isModalOpen: true,
    });
  };

  render() {
    const {
      rootClassName,
      className,
      showInsurance,
      insuranceType,
      listing,
      onManageDisableScrolling,
      onReadInsurance,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const isOldCar = checkIsOldCar(listing);

    const panelTitle = <FormattedMessage id="InsurancePanel.InsurancePanelTitle" />;
    const panelParagraph = <FormattedMessage id="InsurancePanel.InsurancePanelPara" />;

    return (
      <div className={classes}>
        <div className={css.insuranceSection}>
          <h5>{panelTitle}</h5>
          <p>{panelParagraph}</p>
          <ExternalLink onClick={this.openInsurancePolicy} className={css.insuranceBottomImgText}>
            Learn more
            <img src={arrowDown} alt="" />
          </ExternalLink>
        </div>
        <div className={css.insuranceSection}>
          <h5>Terms & Conditions</h5>
          <p>Read the detailed terms & conditions in the draft subscription agreement.</p>
          <ExternalLink href="https://help.drivelah.sg/en/articles/6634633-flex-car-subscription-with-benefits" className={css.insuranceBottomImgText}>
            View
            <img src={arrowDown} alt="" />
          </ExternalLink>
        </div>
        <Modal
          id="staticPageModal"
          isOpen={!!this.state.isModalOpen}
          onClose={() => {
            this.setState({
              isModalOpen: false,
            });
          }}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
        >
          <div className={css.insuranceListingWrapper}>
            <h3>Comprehensive insurance cover in SG &amp; MY</h3>
            <ul className={css.insurancePointsList}>
              <li>Unlimited cover for 3rd party liability (bodily injury)</li>
              <li>Up to $5 mn cover for 3rd party liability (property damage)</li>
              <li>Personal accident benefits up to $50,000</li>
              <li>Excess $3000 (for Section I &amp; II each)</li>
              <li>Policy applicable only for the approved driver during the period of rental</li>
            </ul>
            {showInsurance && insuranceType === INSURANCE_PRIVATE && (
              <div className={css.insuranceBottom}>
                <ExternalLink
                  onClick={onReadInsurance}
                  className={css.insuranceBottomImgText}
                  href={insurancePdf}
                >
                  <FormattedMessage id="InsurancePanel.privateInsuranceButton" />
                </ExternalLink>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

InsurancePanelNewLongTerm.defaultProps = {
  rootClassName: null,
  className: null,
};

InsurancePanelNewLongTerm.propTypes = {
  rootClassName: string,
  className: string,
  onReadInsurance: func,
};

export default InsurancePanelNewLongTerm;
