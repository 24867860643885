/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { memo, useState } from 'react';
import { bool, oneOf, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LINE_ITEM_CUSTOM_REFUND,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_UNITS,
  propTypes,
} from '../../util/types';
import css from './BookingBreakdown.css';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUpdateBookingMaybe from './LineItemUpdateBookingMaybe';
import LineItemYoungCustomerCommissionMaybe from './LineItemYoungCustomerCommissionMaybe';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemLongTermDiscountMaybe from './LineItemLongTermDiscountMaybe';
import LineItemCreditsMaybe from './LineItemCreditsMaybe';
import LineItemMasterCardPromoMaybe from './LineItemMastercardPromoMaybe';
import LineItemProcessingFee from './LineItemProcessingFee';
import LineItemProcessingFeeRefundMaybe from './LineItemProcessingFeeRefundMaybe';
import LineItemYoungCustomerCommissionMaybeRefund from './LineItemYoungCustomerCommissionRefundMaybe';
import LineItemUpdateBookingPeriod from './LineItemUpdateBookingPeriod';
import LineItemInitialPaymentItemsMaybe from './LineItemInitialPaymentItemsMaybe';
import LineItemUnknownItemsRefundMaybe from './LineItemUnknownItemsRefundMaybe';
import LineItemNewCarDiscountMaybe from './LineItemNewCarDiscountMaybe';
import LineItemDelivery from './LineItemDelivery';
import LineItemDeliveryRefaundMaybe from './LineItemDeliveryRefaundMaybe';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import LineItemNewExcessReduction from "./LineItemNewExcessReduction";
import LineItemNewExcessReduction2 from "./LineItemNewExcessReduction2";

export const BookingBreakdownComponent = memo(
  props => {
    const {
      rootClassName,
      className,
      intl,
      userRole,
      transaction: rawTransaction,
      currentUser,
      doNotShowTotal,
      unitType,
      isOnListingPage = false,
      timeZone,
      hideBookingPeriod,
      isEditTripPage,
    } = props;
    const [isOpenDetails, setIsOpenDetails] = useState(false);

    const onSetOpenDetails = () => setIsOpenDetails(prev => !prev);

    const isCustomer = userRole === 'customer';
    const isProvider = userRole === 'provider';
    const classes = classNames(rootClassName || css.root, className);
    let transaction = rawTransaction;

    // const LTM_MONTHLY_PRICE = 300000;
    // if (isLongTermRental) {
    //   transaction = changeLTMPrice(transaction, LTM_MONTHLY_PRICE);
    // }
    return (
      <div className={classes}>
        {isOnListingPage || hideBookingPeriod ? null : (
          <LineItemBookingPeriod
            booking={transaction.booking}
            transaction={transaction}
            unitType={unitType}
            timezone={getDefaultTimeZoneOnBrowser(timeZone)}
          />
        )}

        <LineItemInitialPaymentItemsMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isProvider={isProvider}
          onSetOpenDetails={onSetOpenDetails}
          isOpenDetails={isOpenDetails}
        />
        <div
          className={classNames(css.explanationBreakdownContainer, {
            [css.hide]: !isOpenDetails,
            [css.show]: isOpenDetails,
          })}
        >
          <LineItemUpdateBookingPeriod
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isProvider={isProvider}
            isEditTripPage={isEditTripPage}
            isExplanationBreakdown={isEditTripPage}
          />
          <LineItemCustomerCommissionMaybe
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isExplanationBreakdown={isEditTripPage}
            isEditTripPage={isEditTripPage}
          />
          <LineItemNewExcessReduction
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isExplanationBreakdown={isEditTripPage}
            isEditTripPage={isEditTripPage}
          />
          <LineItemNewExcessReduction2
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isExplanationBreakdown={isEditTripPage}
            isEditTripPage={isEditTripPage}
          />
          <LineItemYoungCustomerCommissionMaybe
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isExplanationBreakdown={isEditTripPage}
          />
          <LineItemProcessingFee
            transaction={transaction}
            isCustomer={isCustomer}
            isProvider={isProvider}
            intl={intl}
            isExplanationBreakdown={isEditTripPage}
          />
        </div>

        <LineItemUnknownItemsMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
          isProvider={isProvider}
        />
        <LineItemUnknownItemsRefundMaybe
          transaction={transaction}
          intl={intl}
          isProvider={isProvider}
          isCustomer={isCustomer}
        />

        <LineItemCustomerCommissionMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemCustomerCommissionRefundMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemYoungCustomerCommissionMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemYoungCustomerCommissionMaybeRefund
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemProcessingFee
          transaction={transaction}
          isCustomer={isCustomer}
          isProvider={isProvider}
          intl={intl}
        />
        <LineItemProcessingFeeRefundMaybe
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemDelivery
          transaction={transaction}
          isProvider={isProvider}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemDeliveryRefaundMaybe
          transaction={transaction}
          isProvider={isProvider}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemProviderCommissionMaybe
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
        />
        <LineItemProviderCommissionRefundMaybe
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
        />

        <LineItemUpdateBookingMaybe isProvider={isProvider} transaction={transaction} intl={intl} />

        {doNotShowTotal ? null : (
          <div>
            <hr className={css.totalDivider} />
            <LineItemMasterCardPromoMaybe
              transaction={transaction}
              userRole={userRole}
              intl={intl}
            />
            <LineItemLongTermDiscountMaybe transaction={transaction} intl={intl} />
            <LineItemCreditsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />
            <LineItemNewCarDiscountMaybe
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <LineItemTotalPrice
              currentUser={currentUser}
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
              isEditTripPage={isEditTripPage}
            />
          </div>
        )}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['userRole', 'transaction', 'currentUser']),
      pick(next, ['userRole', 'transaction', 'currentUser'])
    )
);

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  doNotShowTotal: false,
  customRefundType: LINE_ITEM_CUSTOM_REFUND,
  hideUnitPrice: false,
  timeZone: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,
  transactionMode: bool,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  doNotShowTotal: bool,
  hideUnitPrice: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const BookingBreakdown = compose(
  connect(mapStateToProps, () => {}),
  injectIntl
)(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;

const changeLTMPrice = (rawTransaction, LTM_MONTHLY_PRICE) => {
  let transaction = cloneDeep(rawTransaction);
  const lineItems =
    (transaction && transaction.attributes && transaction.attributes.lineItems) || [];
  let longTermRentalPrice, processFees, tripFees;
  let totalPrice = transaction.attributes.payinTotal;
  lineItems.forEach(lineItem => {
    if (lineItem.code === LINE_ITEM_UNITS) {
      longTermRentalPrice = lineItem.lineTotal;
    } else if (lineItem.code === LINE_ITEM_PROCESSING_FEE) {
      processFees = lineItem.lineTotal;
    } else if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION) {
      tripFees = lineItem.lineTotal;
    }
  });
  longTermRentalPrice.amount = LTM_MONTHLY_PRICE;
  processFees.amount = (LTM_MONTHLY_PRICE + tripFees.amount) * 0.04;
  totalPrice.amount = LTM_MONTHLY_PRICE + processFees.amount + tripFees.amount;
  return transaction;
};
