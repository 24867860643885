import React from 'react';
import css from './ListingPageGo.css';
import { PropertyCheckList } from '../../../components';
import { FormattedMessage } from 'react-intl';

const SectionFeaturesMaybe = props => {
  const { listing, keyFeaturesConfig } = props;
  const { keyFeatures } = listing.attributes.publicData || {};

  const filteredKeyFeaturesConfig = keyFeaturesConfig.filter(feature =>
    keyFeatures.includes(feature.key)
  );
  
  return (
    <div className={css.section}>
      <div className={css.sectionTitle}>
        <FormattedMessage id="ListingPageGo.SectionFeatures.title" />
      </div>
      <PropertyCheckList
        id="ahihi"
        options={filteredKeyFeaturesConfig}
        selectedOptions={keyFeatures}
        twoColumns={true}
        className={css.features}
      />
    </div>
  );
};

export default SectionFeaturesMaybe;
