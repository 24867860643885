import React, { useEffect, useState } from 'react';
import css from './SeoLandingPage.css';
import { ListingCardV3, NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import Slider from 'react-slick';
import { compose } from 'redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchTopBrandCar } from '../SearchPage/SearchPage.duck';

const SectionCarsTopBrands = ({
  isMobileLayout,
  brandCategory,
  onFetchTopBrandCar,
  listings,
  fetchFeaturedCarInProgress,
  setSearchFilters,
}) => {
  const [filterText, setFilterText] = useState(null);
  useEffect(() => {
    const lowerTheString = brandCategory.toLowerCase();
    const validString = lowerTheString.split(' ');
    const validStringConjoined = validString.join('_');
    setFilterText(validStringConjoined);
    setSearchFilters(validStringConjoined);
    onFetchTopBrandCar(validStringConjoined);
  }, []);
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionCars}>
      <div className={css.sectionCarsTitleWrapper}>
        <div className={css.sectionTitle}>Choose from the top rented {brandCategory} cars</div>
      </div>
      {!isMobileLayout ? (
        fetchFeaturedCarInProgress ? (
          <div className={css.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={css.listingGrid}>
            {listings.map(l => (
              <div className={css.listingCardWrapper}>
                <ListingCardV3 listing={l} />
              </div>
            ))}
          </div>
        )
      ) : (
        ''
      )}
      {isMobileLayout ? (
        fetchFeaturedCarInProgress ? (
          <div className={css.sectionCarsLoading}>Loading car...</div>
        ) : (
          <div className={css.sliderWrapper}>
            <Slider {...sliderConfig}>
              {listings.map(l => (
                <div className={css.listingCardWrapper}>
                  <ListingCardV3 listing={l} />
                </div>
              ))}
            </Slider>
          </div>
        )
      ) : (
        ''
      )}
      <div className={css.btnWrapper}>
        <NamedLink
          name="SearchPage"
          to={{
            search: `?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999&pub_brandName=${filterText}&sort=-price`,
          }}
          className={css.viewAllCar}
        >
          <FormattedMessage id="LandingPage.viewAllCar" />
        </NamedLink>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { topBrandCarIds } = state.SearchPage;
  const featuredCarRefs = topBrandCarIds.map(id => ({ type: 'listing', id }));
  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    topBrandCarIds,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchTopBrandCar: searchParams => dispatch(fetchTopBrandCar(searchParams)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SectionCarsTopBrands);
