import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBox,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import SectionHero from './SectionHero';
import css from './LandingPageFlex.css';
import SectionWhyRentWithFlex from './SectionWhyRentWithFlex';
import SectionCompare from './SectionCompare';
import SectionHowItWorks from './SectionHowItWorks';
import SectionBecomeFlexHost from './SectionBecomeFlexHost';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionFeaturedFlexCar from './SectionFeaturedFlexCar';
import { fetchFeaturedFlexCar } from '../SearchPage/SearchPage.duck';
import { parse } from '../../util/urlHelpers';
import FlexPlusFooter from './FlexPlusFooter';
import { compose } from 'redux';
import { connect } from 'react-redux';

const questions = [
  {
    question: 'What is Drive mate Flex+ car subscription?',
    answer: `Drive mate Flex+ is a car subscription service that allows you to have access to a Vehicle for a specific period, without the need to buy or lease the car. It offers flexibility and convenience, with the ability to switch cars or end the subscription as needed (just give a 30 days notice). You can Swtich cars depending on your needs. Insurance, maintenance, registration and Roadside assistance are all included in the price.`,
  },
  {
    question: 'How does it work?',
    answer: `You can choose a car from our available fleet and customise your subscription with Insurance and Mileage packages that you might want to add, and pay weekly. The fee covers the cost of the car, insurance, maintenance and roadside assistance; all you have to worry about are fuel and toll costs. You can then use the car until you want to switch cars or end the subscription, and as long as timely payments are made.`,
  },
  {
    question: 'What are the benefits of car subscription on Drive mate?',
    answer: `As with all car subscriptions, we provide the flexibility to switch cars as needed, no long-term commitment, access to a variety of vehicles, convenience of maintenance and insurance being taken care of, and often a lower upfront cost compared to purchasing a car. Our unique proposition is that you can reduce your monthly costs by re-sharing the car on our platform to rent out on days that you don't need it yourself.`,
  },
  {
    question: 'What is the mileage allowance on Drive mate Flex+?',
    answer: `Drive mate Flex+ plans include 500km/week mileage allowance including unused kms roll over. There are options to increase this allowance should you require; pricing is visible on the check-out pages during your booking.`,
  },
  {
    question: 'What is the minimum and maximum duration of a car subscription?',
    answer: `Drive mate requires a minimum of 28 days-notice to cancel a subscription and the minimum commitment is 8 weeks. If after 8 weeks, you decide to return the car; you will be able to do so without any penalties or fees.`,
  },
  {
    question: 'What happens if the car requires maintenance or repairs?',
    answer: `Drive mate will keep track of regular service schedules and will inform you when service is due. For general repairs, depending on the circumstances and requirements, we will be able to assist with arrangements so that you can get back onto the road as soon as possible.`,
  },
  {
    question: 'Can I add additional drivers to my car subscription?',
    answer: `Yes, you are welcome to add additional drivers to your subscription plan as long as they are verified members of the Drive mate platform. Additional drivers are included in the subscription fee.`,
  },
  {
    question: 'Is insurance included in the car subscription?',
    answer: `Comprehensive insurance is included on all Drive mate subscriptions; there are options to increase insurance coverage during the check-out pages when making your booking.`,
  },
  {
    question: 'Do I need to make manual payments for my subscriptions?',
    answer: `No, you just need to ensure there is sufficient balance in your account to pay for a week of subscription. We will auto charge the advance weekly subscription fee from your bank account. In case we are unable to charge and you don't pay on time a late payment fee of $27.50 applies (when an invoice is 7 days in arrears.`,
  },
  {
    question: 'Are there any additional costs or fees I need to be aware of?',
    answer: `In addition to your subscription fee, you are responsible for fuel and toll costs as well as paying all fines, penalties and traffic infringements.If you exceed the free kilometres included in your subscription plan, there is an excess kilometre fee payable. Your included free kilometre allowance will roll over to the next week until you end the contract. This means that if you have a contract for 52 weeks, then total kms included in the basic plan is 52 x 500 = 26000 kms. As long as you stay within the 26000 upon return after 1 year subscription no mileage fee will be charged.If the vehicle sustains any damage or is involved in an accident, a Insurance Excess is payable for each incident. The Insurance Excess may be refundable in full or in part if Drive mate's insurance provider recovers costs from any at fault third party or the total cost of damages is less than the Insurance Excess.`,
  },
  {
    question: 'Will the car be delivered to me?',
    answer: `Yes, as long as it is in the city of Sydney, Melbourne or Brisbane. We will make an appointment with you when it can be delivered.`,
  },
  {
    question: 'How much am I supposed to pay the first time for my subscription?',
    answer: `When subscribing, an upfront payment equivalent to one week's subscription fee is required. Additionally, for the initial payment, a refundable deposit equivalent to four weeks' subscription fee is also collected. Therefore, the first payment amounts to five weeks' subscription fee, with four weeks being refundable at the end of the subscription period`,
  },
];

const LandingPageFlexComponent = ({ params, location, version: pVersion, currentUser }) => {
  const { search } = location;
  const { version = 'v1' } = parse(search);
  const schemaDescription =
    ' Attractive long term car rental services for your transportation needs with Drive lah.No hidden costs & fees. 24/7 support & service. Fast & easy booking.';
  return (
    <Page title="Next level of car ownership" className={css.root} description={schemaDescription}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer transparent />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero version={pVersion || version} currentUser={currentUser} isFromFlexPage={true} />
          {/* <ChannelsListings /> */}
          <div className={css.trustPilotLogo}>
            {/* <div className={css.trustPilotLogoContent}>
              <TrustBox />
            </div> */}
          </div>
          <SectionWhyRentWithFlex />
          <SectionCompare />
          <SectionHowItWorks />
          <SectionFeaturedFlexCar />
          <SectionBecomeFlexHost />
          <SectionHelp data={questions} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FlexPlusFooter />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = () => ({});

const LandingPageFlex = compose(connect(mapStateToProps, mapDispatchToProps))(
  LandingPageFlexComponent
);

LandingPageFlex.loadData = fetchFeaturedFlexCar;

export default LandingPageFlex;
