import React from 'react';
import css from './InstantBookingLandingPage.css';

export const SectionHero = ({ intl }) => {
  return (
    <div className={css.sectionHero} title="Book, Unlock and Go! With Drive Mate car sharing Australia">
      <h1 className={css.sectionHeroTitle}>
        {intl.formatMessage({
          id: 'InstantBookingLandingPage.SectionHero.title',
        })}
      </h1>
      <div className={css.sectionHeroDescription}>
        {intl.formatMessage(
          {
            id: 'InstantBookingLandingPage.SectionHero.description',
          },
          { values: { newLine: <br /> } }
        )}
      </div>
    </div>
  );
};
