import React from 'react';
import { Modal } from '../../components';

import css from './TransactionPanel.css';

export const ShowImgModal = props => {
  const { isOpen, onClose, onManageDisableScrolling, url } = props;

  return (
    <Modal
      id="showImgModal"
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.showImgModalImgWrapper}>
        <img src={url} className={css.showImgModalImg} />
      </div>
    </Modal>
  );
};
