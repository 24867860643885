import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

export const additionalLocations = [
  {
    identifierId: 'woodlands',
    address: 'Woodlands, Singapore',
    predictionPlace: {
      address: 'Woodlands, Singapore',
      description: 'Woodlands, Singapore',
      origin: new LatLng(1.4381922, 103.7889597),
      bounds: new LatLngBounds(
        new LatLng(1.5280237284119522, 103.87881953592863),
        new LatLng(1.3483606715880478, 103.69909986407139)
      ),
    },
  },
  {
    identifierId: 'jurong',
    address: 'Jurong, Singapore',
    predictionPlace: {
      address: 'Jurong, Singapore',
      description: 'Jurong, Singapore',
      origin: new LatLng(1.3367679, 103.6941672),
      bounds: new LatLngBounds(
        new LatLng(1.426599428411952, 103.78402318322867),
        new LatLng(1.2469363715880477, 103.60431121677132)
      ),
    },
  },
  {
    identifierId: 'yishun',
    address: 'Yishun, Singapore',
    predictionPlace: {
      address: 'Yishun, Singapore',
      description: 'Yishun, Singapore',
      origin: new LatLng(1.430368, 103.8353628),
      bounds: new LatLngBounds(
        new LatLng(1.5201995284119523, 103.92522232868376),
        new LatLng(1.340536471588048, 103.74550327131624)
      ),
    },
  },
  {
    identifierId: 'ang-mo-kio',
    address: 'Ang Mo Kio, Singapore',
    predictionPlace: {
      address: 'Ang Mo Kio, Singapore',
      description: 'Ang Mo Kio, Singapore',
      origin: new LatLng(1.3691149, 103.8454342),
      bounds: new LatLngBounds(
        new LatLng(1.4589464284119522, 103.93529138134427),
        new LatLng(1.2792833715880478, 103.75557701865573)
      ),
    },
  },
  {
    identifierId: 'bukit-panjang',
    address: 'Bukit Panjang, Singapore',
    predictionPlace: {
      address: 'Bukit Panjang, Singapore',
      description: 'Bukit Panjang, Singapore',
      origin: new LatLng(1.3774142, 103.7719498),
      bounds: new LatLngBounds(
        new LatLng(1.4672457284119522, 103.8618072933679),
        new LatLng(1.2875826715880478, 103.6820923066321)
      ),
    },
  },
  {
    identifierId: 'sengkang',
    address: 'Sengkang, Singapore',
    predictionPlace: {
      address: 'Sengkang, Singapore',
      description: 'Sengkang, Singapore',
      origin: new LatLng(1.3868121, 103.8914433),
      bounds: new LatLngBounds(
        new LatLng(1.4766436284119522, 103.98130114897378),
        new LatLng(1.2969805715880478, 103.80158545102623)
      ),
    },
  },
  {
    identifierId: 'sembawang',
    address: 'Sembawang, Singapore',
    predictionPlace: {
      address: 'Sengkang, Singapore',
      description: 'Sengkang, Singapore',
      origin: new LatLng(1.4491107, 103.8184954),
      bounds: new LatLngBounds(
        new LatLng(1.5389422284119523, 103.90835566748646),
        new LatLng(1.359279171588048, 103.72863513251355)
      ),
    },
  },
  {
    identifierId: 'hougang',
    address: 'Hougang, Singapore',
    predictionPlace: {
      address: 'Hougang, Singapore',
      description: 'Hougang, Singapore',
      origin: new LatLng(1.3612182, 103.8862529),
      bounds: new LatLngBounds(
        new LatLng(1.4510497284119521, 103.97610978620934),
        new LatLng(1.2713866715880477, 103.79639601379067)
      ),
    },
  },
];
