import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldCheckboxGroup, FieldTextInput, Form } from '../../components';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import css from './EditListingPoliciesForm.css';

const DESCRIPTION_MAX_LENGTH = 500;
export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators, setFieldTouched }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        submitButtonId,
      } = formRenderProps;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const keyRulesLabel = intl.formatMessage({
        id: 'EditListingPoliciesForm.keyRulesLabel',
      });

      const millagePerDayLabel = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayLabel',
      });
      const millagePerDayRequired = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayRequired',
      });
      const millagePerDayPlaceholder = intl.formatMessage({
        id: 'EditListingPoliciesForm.millagePerDayPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            if (!invalid) handleSubmit(e);
            const formState = form.getState();
            const { errors } = formState || {};
            Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
          }}
        >
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.displayInlineContainer}>
            <div className={classNames(css.column, css.column6, css.milageDayOuter)}>
              <FieldCheckboxGroup
                className={classNames(css.policy, css.newInput)}
                id="keyRules"
                name="keyRules"
                label={keyRulesLabel}
                options={config.custom.keyRules}
              />
            </div>
            <div className={classNames(css.column, css.column12, css.keyRulesOuter)}>
              <div className={css.fieldWrapper}>
                  <div className={css.limitCounter}>
                    {(values.rules && values.rules.length) || 0}/{DESCRIPTION_MAX_LENGTH}
                  </div>
                <FieldTextInput
                  id="rules"
                  name="rules"
                  className={classNames(css.policy, css.newInput)}
                  type="textarea"
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  label={rulesLabelMessage}
                  placeholder={rulesPlaceholderMessage}
                />
              </div>
            </div>
          </div>
          {/* <FieldSelect
            className={css.policy}
            type="textarea"
            name="millagePerDay"
            id="millagePerDay"
            label={millagePerDayLabel}
            validate={composeValidators(required(millagePerDayRequired))}
          >
            <option disabled value="">
              {millagePerDayPlaceholder}
            </option>
            {config.custom.millagePerDayOptions.map(op => (
              <option key={op.key} value={op.key}>
                {op.label}
              </option>
            ))}
          </FieldSelect> */}


          <div className={css.stickyButtons}>
            <div className={css.stickButtonsContainer}>
              <div className={css.stickButtonsDescription}></div>
              <div className={css.stickButton}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                  id={submitButtonId}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
