import React, { useState } from 'react';
import { Button } from '../../components';
import css from './VaccinationForm.css';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { uploadFiles } from '../../util/fileUpload';
import { saveVaccineCertificate } from '../../containers/VaccineCredits/VaccineCredits.duck';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const VaccinationForm = props => {
  const { currentUser, onVaccinationCertificateUpload, vaccineCertificateInProgress } = props;
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const dropRef = React.createRef();

  const handleselectedFile = event => {
    event.preventDefault();
    event.stopPropagation();
    if (
      ((event.dataTransfer && !event.dataTransfer.files) ||
        (event.dataTransfer && !event.dataTransfer.files.length) ||
        (event.dataTransfer && !event.dataTransfer.files.length === 0)) &&
      (!event.target.files || !event.target.files.length || event.target.files.length === 0)
    )
      return;
    const files = event.target.files[0] || event.dataTransfer.files[0];
    setUploadedFiles(files);
  };

  const removeItem = () => {
    setUploadedFiles(null);
  };

  const handleUpload = file => {
    const user = currentUser;
    if (!user.id) {
      return;
    }

    if (file) {
      let data = new FormData();
      data.append('file', file, file.name);
      data.append('userId', user.id.uuid);
      data.append('userEmail', user.attributes.email);
      uploadFiles(data)
        .then(response => {
          const code = response.status;
          if (code !== 200) {
            return null;
          }
          return response.json();
        })
        .then(jsonResponse => {
          if (!jsonResponse.url) {
            setUploadError({
              id: 'EditListingInsuranceForm.genericUploadError',
            });
          } else {
            // Submit the form here
            const parameters = {
              vaccineCertificateadded: true,
              vaccineCertificatelink: jsonResponse.url,
              vaccineCertificatetimestamp: moment().unix(),
            };
            onVaccinationCertificateUpload(parameters);
          }
        })
        .catch(e => {
          setUploadError({
            id: 'EditListingInsuranceForm.genericUploadError',
          });
        });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleUpload(uploadedFiles);
  };

  const handleDragOver = e => {
    if ('preventDefault' in e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const uploadErrorMessage = uploadError ? (
    <p className={css.error}>
      <FormattedMessage id={uploadError.id} />
    </p>
  ) : null;

  return (
    <form
      className={classNames(css.root, css.vaccinationSubmitForm)}
      onSubmit={e => handleSubmit(e)}
    >
      <div className={css.wrapper}>
        <div className={css.fields}>
          <div
            className={classNames(css.field, css.customUpload)}
            ref={dropRef}
            onDrop={handleselectedFile}
            onDragOver={handleDragOver}
            onDragLeave={handleDragOver}
          >
            <label htmlFor="file-upload-authorize">
              Drag files here or <span>Browse</span>
            </label>
            <input
              className={css.uploadInput}
              type="file"
              tabIndex="-1"
              name="file-upload-authorize"
              id="file-upload-authorize"
              onChange={e => handleselectedFile(e)}
            />
          </div>
          {uploadedFiles && (
            <div className={css.uploadContentList}>
              <ul className={css.uploadContentListOuter}>
                <li className={css.uploadContentItem}>
                  {uploadedFiles.name}
                  <span className={css.removeItem} onClick={() => removeItem()}>
                    X
                  </span>
                </li>
              </ul>
            </div>
          )}
          {uploadErrorMessage}
        </div>
      </div>
      <div className={css.buttonWrapper}>
        <Button disabled={!uploadedFiles} className={css.findYourCar} type="submit">
          {vaccineCertificateInProgress ? (
            <div className={css.locationLoader}>
              <span></span>
            </div>
          ) : (
            'Submit'
          )}
        </Button>
      </div>
    </form>
  );
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onVaccinationCertificateUpload: data => dispatch(saveVaccineCertificate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationForm);
