import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import modalLeftImage from '../../assets/searchpageContact/contact-modal-image.png';
import css from './SearchContactForm.css';
import { Modal } from '..';
import { SearchContactModalForm } from '../../forms';
import { saveSearchedPlaces } from '../../util/emailNotify';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { encodeLatLngBounds } from '../../util/urlHelpers';
import moment from 'moment';
import { useHistory } from 'react-router';
import { initiateEventFromExpertPage } from '../../util/gtm/gtmHelpers';
import {
  EVENT_SEARCH_EXPERT_HELP_BUTTON_CLICKED,
  EVENT_SEARCH_EXPERT_HELP_SUBMITTED,
  VIEW_EXPERT_BUTTON_ID,
  VIEW_EXPERT_FIND_ME_CAR,
} from '../../util/gtm/gtmConstants';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the final-form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const SearchContactForm = props => {
  const history = useHistory();
  const { onManageDisableScrolling, isAuthenticated } = props;
  const [showFormModal, setShowFormModal] = useState(false);

  const [formStatus, setFormStatus] = useState({ success: false });

  useEffect(() => {
    if (showFormModal) {
      initiateEventFromExpertPage({
        props: props,
        buttonId: VIEW_EXPERT_BUTTON_ID,
        event: EVENT_SEARCH_EXPERT_HELP_BUTTON_CLICKED,
        isHost: false,
      });
    }
  }, [showFormModal]);
  const handleSubmitSearch = value => {
    const { search = {}, pickUp, dropOff, pickupTime, dropOffTime } = value;
    console.log("Search Values", value);
    const routes = routeConfiguration();
    const { selectedPlace } = search;
    if (selectedPlace) {
      saveSearchedPlaces(selectedPlace);
    }
    initiateEventFromExpertPage({
      props: props,
      buttonId: VIEW_EXPERT_FIND_ME_CAR,
      event: EVENT_SEARCH_EXPERT_HELP_SUBMITTED,
      isHost: false,
    });
    const { search: searchLocation } = search;
    console.log("Search Contact form", search);
    history.push(
      createResourceLocatorString('SearchPage', routes, null, {
        bounds: encodeLatLngBounds(selectedPlace ? selectedPlace.bounds : DEFAULT_BOUNDS),
        dates: `${moment(pickUp.date)
          .utc()
          .format('YYYY-MM-DD')},${moment(dropOff.date).format('YYYY-MM-DD')}`,
        hours: `${moment(pickupTime, 'hh:mm a').format('HH:mm')},${moment(
          dropOffTime,
          'hh:mm a'
        ).format('HH:mm')}`,
        locationName: searchLocation,
        timezone:selectedPlace.timezone
      })
    );
  };

  const _handleModalClose = isClosed => {
    setShowFormModal(isClosed);
    setFormStatus({ success: false });
  };

  return (
    <div className={css.searchListContact}>
      {/*<div className={css.searchListContactWrapper}>
        <button className={css.mobileContactButtonOverlay} onClick={() => setShowFormModal(true)}>
          Contact Expert &gt;{' '}
        </button>
        <div className={css.icon}>
          <img src={contactIcon} className={css.iconImage} />
        </div>
        <div className={css.contactDescription}>
          <p>
            Not finding what you are looking for? Get in touch with our experts and they will help
            find you the perfect car right away!
            <button className={css.mobileContactButton} onClick={() => setShowFormModal(true)}>
              Contact Expert &gt;{' '}
            </button>
          </p>
        </div>
        <button onClick={() => setShowFormModal(true)} className={css.contactButton}>
          Contact Expert
        </button>
  </div>*/}
      {showFormModal && (
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={showFormModal}
          onClose={() => _handleModalClose(false)}
          disableCloseBtn={false}
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.searchContactModal}
          scrollLayerClassName={css.searchContactModalSLayer}
          containerClassName={css.searchContactModalContainer}
          contentClassName={css.searchContactModalContent}
          isClosedClassName={css.searchContactModalIsClosed}
          closeButtonClassName={css.searchContactModalCloseButton}
          closeTextClassName={css.searchContactModalCloseText}
          closeIconClassName={css.searchContactModalCloseIcon}
          customContainerClassName={css.searchContactModalCustomContainer}
        >
          <div className={css.searchContactModalInner}>
            <div className={css.searchContactLeftImage}>
              <img src={modalLeftImage} />
            </div>
            <div className={css.searchContactRightContent}>
              <h3>Find the perfect car for you with help from our experts</h3>
              {formStatus && formStatus.success ? (
                <p>{formStatus.message}</p>
              ) : (
                <p>
                  Our team will find the best car that suits your needs and get in touch with you
                  via phone.
                </p>
              )}

              {formStatus && !formStatus.success && (
                <SearchContactModalForm
                  isAuthenticated={isAuthenticated}
                  onSubmit={handleSubmitSearch}
                  className={css.searchForm}
                  formStatus={formStatus}
                  setFormStatus={setFormStatus}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

SearchContactForm.defaultProps = { rootClassName: null, className: null };

const { shape, bool, string } = PropTypes;

SearchContactForm.propTypes = {
  rootClassName: string,
  className: string,
  fieldMeta: shape({
    touched: bool.isRequired,
    error: string,
  }).isRequired,
};

export default SearchContactForm;
