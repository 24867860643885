// ================ Action types ================ //
import { fetchCurrentUser } from '../../ducks/user.duck';

const SAVE_UCS_DECLARATION_REQUEST = 'app/AddressDetailsPage/SAVE_UCS_DECLARATION_REQUEST';
const SAVE_UCS_DECLARATION_SUCCESS = 'app/AddressDetailsPage/SAVE_UCS_DECLARATION_SUCCESS';
const SAVE_UCS_DECLARATION_ERROR = 'app/AddressDetailsPage/SAVE_UCS_DECLARATION_ERROR';

// ================ Reducer ================ //

export const initialState = {
  saveUCSDeclarationSuccess: null,
  saveUCSDeclarationError: null,
  saveUCSDeclarationInProgress: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_UCS_DECLARATION_REQUEST:
      return {
        ...state,
        saveUCSDeclarationSuccess: false,
        saveUCSDeclarationError: false,
        saveUCSDeclarationInProgress: true,
      };
    case SAVE_UCS_DECLARATION_SUCCESS:
      return { ...state, saveUCSDeclarationSuccess: true, saveUCSDeclarationInProgress: false };
    case SAVE_UCS_DECLARATION_ERROR:
      return { ...state, saveUCSDeclarationError: payload, saveUCSDeclarationInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestsaveUCSDeclaration = () => ({
  type: SAVE_UCS_DECLARATION_REQUEST,
});

const saveUCSDeclarationSuccess = () => ({
  type: SAVE_UCS_DECLARATION_SUCCESS,
});

const saveUCSDeclarationError = e => ({
  type: SAVE_UCS_DECLARATION_REQUEST,
  payload: e,
});

export const saveUCSDeclaration = () => (dispatch, getState, sdk) => {
  dispatch(requestsaveUCSDeclaration());
  const protectedData = {};
  protectedData.ucsHostDeclaration = {
    agreedToDeclaration: true,
    signedAt: new Date().toISOString()
  };
  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(response => {
      dispatch(saveUCSDeclarationSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(error => {
      dispatch(saveUCSDeclarationError());
    });
};