import React from 'react';
import classNames from 'classnames';
import css from './BrandingPage.css';
import arrowRight from '../../assets/hostLanding/next.png';
import { HostSignUpPromoForm } from '../../forms';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import { IconSpinner } from '../../components';

const { Money } = types;

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};

const PartnerSectionHero = ({ isScoot, signUpCodeAmount, loading, getToCalculatorSection }) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
            state: {
              email,
              password,
              code,
            },
          }
        : {
            pathname: url,
            state: {
              email,
              password,
            },
          }
    );
  };

  return (
    <div className={css.sectionHero}>
      <div className={css.landingContainer}>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          <div className={css.column6}>
            <div className={css.bannerContent}>
              <h1 className={classNames(css.heroTitle, css.heroTitleDesk)}>
                Let your car work <br />
                for you!
              </h1>
              <p>
                Don’t use your car on certain days of the week? Share it with your neighbours for
                cash!
              </p>
              <p>
                Make car ownership really worth its while earning limitless passive income on the
                Drive mate app – a fully-insured car sharing network of locals{' '}
                <a className={css.link}>renting</a> their neighbour’s vehicles.
              </p>
              <span className={css.calcTargetButton} onClick={getToCalculatorSection}>
                Calculate your potential earnings{' '}
                <span>
                  <img src={arrowRight} alt="" />
                </span>
              </span>
            </div>
          </div>
          <div className={css.column6}>
            <div className={css.landingFormWrapper}>
              {loading ? (
                <div className={css.loadingWrapper}>
                  <IconSpinner className={css.loadingSpinner} />
                </div>
              ) : currentUser && currentUser.id ? (
                <>
                  <div className={css.signUpTitle}>
                    {signUpCodeAmount && signUpCodeAmount > 0 ? (
                      <FormattedMessage
                        id={
                          isScoot
                            ? 'brandingPartnerLandingPage.scootSchemaTitle'
                            : 'brandingPartnerLandingPage.schemaTitle'
                        }
                        values={{
                          amount: signUpCodeAmount
                            ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                            : '$0',
                        }}
                      />
                    ) : (
                      <FormattedMessage id={'brandingPartnerLandingPage.signUpNow'} />
                    )}
                  </div>

                  <p className={css.loginDesc}>
                    This promo is only available to new users. You will have to logout and create a
                    new account.
                  </p>
                </>
              ) : (
                <>
                  <div className={css.signUpTitle}>
                    {signUpCodeAmount && signUpCodeAmount > 0 ? (
                      <FormattedMessage
                        id={
                          isScoot
                            ? 'brandingPartnerLandingPage.scootSchemaTitle'
                            : 'brandingPartnerLandingPage.schemaTitle'
                        }
                        values={{
                          amount: signUpCodeAmount
                            ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                            : '$0',
                        }}
                      />
                    ) : (
                      <FormattedMessage id={'brandingPartnerLandingPage.signUpNow'} />
                    )}
                  </div>
                  <HostSignUpPromoForm onSubmit={handleSubmit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSectionHero;
