import * as Actions from './EditTripPage.actions';

import { initialState } from './EditTripPage.state';
import {
  REQUEST_STATUS__ERROR,
  REQUEST_STATUS__PENDING,
  REQUEST_STATUS__SUCCESS,
} from '../../constants/other';

export default function tripPageDetailsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case Actions.SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case Actions.FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };

    case Actions.FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }

    case Actions.FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case Actions.FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };

    case Actions.FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };

    case Actions.FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case Actions.ADD_CHILD_LONG_TERM_TRANSACTIONS:
      return { ...state, childLongTermTransactions: payload };

    case Actions.ADD_NEXT_CHILD_TRANSACTION:
      return { ...state, nextLongTermTransaction: payload };

    case Actions.ADD_CURRENT_CHILD_TRANSACTION:
      return { ...state, currentChildLongTermTransaction: payload };

    case Actions.TRIP_DATES_SET:
      return { ...state, tripDates: { ...state.tripDates, ...payload } };

    case Actions.UPDATE_BOOKING_DATES_SET:
      return { ...state, updateBookingDates: { ...state.updateBookingDates, ...payload } };

    case Actions.ESTIMATED_TX_REQUEST_PENDING:
      return {
        ...state,
        estimatedTx: null,
        estimatedTxRequestStatus: REQUEST_STATUS__PENDING,
        estimatedTxError: null,
        formError: null,
        updateBookingInitiateError: null,
      };

    case Actions.ESTIMATED_TX_REQUEST_SUCCESS:
      return {
        ...state,
        estimatedTxRequestStatus: REQUEST_STATUS__SUCCESS,
        estimatedTx: payload,
        estimatedTxError: null,
        formError: null,
      };

    case Actions.ESTIMATED_TX_REQUEST_ERROR:
      return {
        ...state,
        estimatedTxRequestStatus: REQUEST_STATUS__ERROR,
        estimatedTxError: payload,
      };

    case Actions.UPDATE_BOOKING_INITIATE_REQUEST:
      return {
        ...state,
        updateBookingInitiateRequestStatus: REQUEST_STATUS__PENDING,
        updateBookingInitiateError: null,
      };

    case Actions.UPDATE_BOOKING_INITIATE_SUCCESS:
      return {
        ...state,
        updateBookingInitiateRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.UPDATE_BOOKING_INITIATE_ERROR:
      return {
        ...state,
        updateBookingInitiateRequestStatus: REQUEST_STATUS__ERROR,
        updateBookingInitiateError: payload,
      };

    case Actions.ACCEPT_UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        acceptUpdateBookingRequestStatus: REQUEST_STATUS__PENDING,
        acceptUpdateBookingError: null,
      };

    case Actions.ACCEPT_UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        acceptUpdateBookingRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.ACCEPT_UPDATE_BOOKING_ERROR:
      return {
        ...state,
        acceptUpdateBookingRequestStatus: REQUEST_STATUS__ERROR,
        acceptUpdateBookingError: payload,
      };

    case Actions.CANCEL_UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        cancelUpdateBookingRequestStatus: REQUEST_STATUS__PENDING,
        cancelUpdateBookingError: null,
      };

    case Actions.CANCEL_UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        cancelUpdateBookingRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.CANCEL_UPDATE_BOOKING_ERROR:
      return {
        ...state,
        cancelUpdateBookingRequestStatus: REQUEST_STATUS__ERROR,
        cancelUpdateBookingError: payload,
      };

    case Actions.FETCH_UPDATE_BOOKING_TXS_REQUEST:
      return {
        ...state,
        fetchUpdateBookingTxsRequestStatus: REQUEST_STATUS__PENDING,
      };

    case Actions.FETCH_UPDATE_BOOKING_TXS_SUCCESS:
      return {
        ...state,
        fetchUpdateBookingTxsRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.FETCH_UPDATE_BOOKING_TXS_ERROR:
      return {
        ...state,
        fetchUpdateBookingTxsRequestStatus: REQUEST_STATUS__ERROR,
      };

    case Actions.CANCEL_ALL_UPDATE_BOOKING_TXS_REQUEST:
      return {
        ...state,
        cancelAllUpdateBookingTxsRequestStatus: REQUEST_STATUS__PENDING,
        cancelUpdateBookingError: null,
      };

    case Actions.CANCEL_ALL_UPDATE_BOOKING_TXS_SUCCESS:
      return {
        ...state,
        cancelAllUpdateBookingTxsRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.CANCEL_ALL_UPDATE_BOOKING_TXS_ERROR:
      return {
        ...state,
        cancelAllUpdateBookingTxsRequestStatus: REQUEST_STATUS__ERROR,
        cancelUpdateBookingError: payload,
      };

    case Actions.FETCH_LAST_UPDATE_BOOKING_TX_REQUEST:
      return {
        ...state,
        fetchLastUpdateBookingTxRequestStatus: REQUEST_STATUS__PENDING,
      };

    case Actions.FETCH_LAST_UPDATE_BOOKING_TX_SUCCESS:
      return {
        ...state,
        fetchLastUpdateBookingTxRequestStatus: REQUEST_STATUS__SUCCESS,
      };

    case Actions.FETCH_LAST_UPDATE_BOOKING_TX_ERROR:
      return {
        ...state,
        fetchLastUpdateBookingTxRequestStatus: REQUEST_STATUS__ERROR,
      };

    default:
      return state;
  }
}
