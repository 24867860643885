import React from 'react';
import { LINE_ITEM_MASTERCARD_PROMO, propTypes } from '../../util/types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import css from './BookingBreakdown.css';

const LineItemMastercardPromoMaybe = props => {
  const { transaction, intl } = props;
  const translationKey = 'BookingBreakdown.firstTimeMastercardPromo';
  const promoUnit = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_MASTERCARD_PROMO && !item.reversal
  );
  if (!promoUnit) return null;
  const formattedUnitPrice = formatMoney(intl, promoUnit.lineTotal, 1);

  return formattedUnitPrice ? (
    <div className={css.creditsLineItem}>
      <span className={css.totalLabel}>
        <FormattedMessage id={translationKey} />
      </span>
      <span className={css.totalPrice}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

LineItemMastercardPromoMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemMastercardPromoMaybe;
