import React, { PureComponent } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import merge from 'lodash/merge';
import { propTypes } from '../../util/types';
import { isMobile } from 'react-device-detect';
import { get } from 'lodash';
import { LongTermSearchResultsPanel } from '../../components';
import { validFilterParams, validURLParamsForExtendedData } from './LongTermRentalSearch.helpers';
import { ensureUser } from '../../util/data';
import css from './LongTermRentalSearch.css';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import noTrips from '../../assets/searchPage/no-trips.svg';
import { parse } from '../../util/urlHelpers';
import LongTermSearchFilters from '../../components/LongTermSearchFilters/LongTermSearchFilters';
import config from '../../config';

const MAX_MOBILE_SCREEN_WIDTH = 768;

class LongTermRentalMainPanel extends PureComponent {
  state = { isSearchFiltersPanelOpen: false };

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      primaryFilters,
      secondaryFilters,
      isMobileLayout,
      visibilityMap,
      onManageVisibilitySearchMap,
      searchDate,
      location,
      sort,
      onSelectCard,
      searchTimes,
      isSearchingLongTerm,
      isAuthenticated,
      currentUser,
      speculatedTripPricesInProgress,
      speculatedTripPrices,
      isFilterModal
    } = this.props;
    const { search } = location;
    const { mapSearch, ...searchInURL } = parse(search);
    const ensuredPrimaryFilters = pickBy(primaryFilters, identity);
    const ensuredSecondaryFilters = pickBy(secondaryFilters, identity);
    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;

    const filters = merge(ensuredPrimaryFilters, ensuredSecondaryFilters);
    const selectedFilters = validFilterParams(urlQueryParams, filters);
    const selectedFiltersCount = Object.keys(selectedFilters).length;
    const isHomeDeliverySelected = 'pub_delivery' in selectedFilters;

    const selectedSecondaryFilters = ensuredSecondaryFilters
      ? validFilterParams(urlQueryParams, ensuredSecondaryFilters)
      : {};
    const searchFiltersPanelSelectedCount = Object.keys(selectedSecondaryFilters).length;

    const searchFiltersPanelProps = !!ensuredSecondaryFilters
      ? {
          isSearchFiltersPanelOpen: this.state.isSearchFiltersPanelOpen,
          toggleSearchFiltersPanel: isOpen => {
            this.setState({ isSearchFiltersPanelOpen: isOpen });
          },
          searchFiltersPanelSelectedCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;
    const validQueryParams = validURLParamsForExtendedData(searchInURL, filters);
    const classes = classNames(
      rootClassName || css.searchResultContainer,
      className,
      (!visibilityMap || validQueryParams.pub_longTermRental) && css.fullScale
    );

    const filterParamNames = Object.values(filters).map(f => f.paramName);
    const secondaryFilterParamNames = ensuredSecondaryFilters
      ? Object.values(ensuredSecondaryFilters).map(f => f.paramName)
      : [];

    const user = ensureUser(currentUser);
    const userTrips = get(
      user,
      'attributes.profile.metadata.intercomUserStat.number_of_trips_success_as_guest'
    );
    const isLearnMoreSectionVisible = !currentUser || userTrips === 0;

    const getCarsText = () => {
      let cityFilter = searchParamsForPagination && searchParamsForPagination.pub_listingTimezone ? searchParamsForPagination.pub_listingTimezone : '';
      const address = searchParamsForPagination && searchParamsForPagination.address ? searchParamsForPagination.address : '';
      const cities = config && config.custom && config.custom.cities.length ? config.custom.cities.filter((city) => city.enabled) : [];
      const selectedCities = [];
      if (cities && cities.length) {
        let cityFilters = cityFilter && cityFilter.split && cityFilter.split(',') && cityFilter.split(',').length ? cityFilter.split(',') : [];
        for (let i = 0; i < cities.length; i++) {
          // Find all city filters applied
          for (let j = 0; j < cityFilters.length; j++) {
            if (cityFilters[j] && cityFilters[j] === cities[i].key) {
              selectedCities.push(cities[i].label);
            }
          }
        }
      }

      const total = pagination && pagination.totalItems ? pagination.totalItems : 0;
      let selection = '';

      if (selectedCities && selectedCities.hasOwnProperty('length') && !selectedCities.length) {
        selection = address || 'Australia';
      } else if (selectedCities && selectedCities.hasOwnProperty('length') && selectedCities.length === 1) {
        selection = selectedCities[0] || address || 'Australia';
      } else if (selectedCities && selectedCities.hasOwnProperty('length') && selectedCities.length >= 2) {
        selection = 'selected cities'
      }
      return searchInProgress ? '' : `${total || 0} cars available for subscription in ${selection}`;
    }

    return (
      <div className={classes}>
        <div className={css.filterSectionTop}>
        <div className={classNames(css.accessibilityBar, css.row)}>
          <div className={classNames(css.column4, css.column)}>
            <div className={css.resultsLocation}>
                {getCarsText()}
            </div>
          </div>
          <div className={classNames(css.column8, css.column)}>
            <LongTermSearchFilters
              className={css.searchFilters}
              urlQueryParams={urlQueryParams}
              sort={sort}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              onManageDisableScrolling={onManageDisableScrolling}
              currentUser={currentUser}
              {...searchFiltersPanelProps}
              {...ensuredPrimaryFilters}
              {...ensuredSecondaryFilters}
              openFilterModal={isFilterModal}
            />
          </div>
        </div>
          {/* <div className={css.filterSectionRight}>
            {(selectedFilters && selectedFilters.pub_longTermRental) ||
            isSearchingLongTerm ? null : (
              <SearchDateTimeFilters
                isMobileLayout={isMobile}
                visibilityMap={visibilityMap}
                onManageVisibilitySearchMap={onManageVisibilitySearchMap}
              />
            )}
          </div> */}
        </div>

        {/* {isMobileLayout && (
          <SearchFiltersMobile
            className={css.searchFiltersMobile}
            urlQueryParams={urlQueryParams}
            sort={sort}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            showAsModalMaxWidth={showAsModalMaxWidth}
            onMapIconClick={onMapIconClick}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            filterParamNames={filterParamNames}
            selectedFiltersCount={selectedFiltersCount}
            {...primaryFilters}
            {...ensuredSecondaryFilters}
          />
        )} */}
        {/* {isMobileLayout && ( */}
        {/* <BSearchFiltersMobile
          className={bLandingCss.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          sort={sort}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          filterParamNames={filterParamNames}
          selectedFiltersCount={selectedFiltersCount}
          currentUser={currentUser}
          {...primaryFilters}
          {...ensuredSecondaryFilters}
        /> */}
        {/* )} */}
        {/* This is search results section */}
        {!isMobile && isSearchFiltersPanelOpen && (
          <div className={classNames(css.searchFiltersPanel)}>
            {/* <SearchFiltersPanel
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              onClosePanel={() => this.setState({ isSearchFiltersPanelOpen: false })}
              filterParamNames={secondaryFilterParamNames}
              {...secondaryFilters}
            /> */}
          </div>
        )}
        {/* {!isSearchFiltersPanelOpen && isLearnMoreSectionVisible && (
          <div className={css.canCancelMessage}>
            <div className={css.canCancelMessageInner}>
              <span className={css.canCancelIcon}>
                <img src={infoIcon} alt="" />
              </span>
              <p>
                Don't worry: you can cancel your first trip anytime; for free.
                <NamedLink name="HowItWorkPage">
                  Learn more about our cancellation policy.
                </NamedLink>
              </p>
              <p>Book without worry, you can cancel your first trip anytime for free.</p>
            </div>
          </div>
        )} */}
        {!isSearchFiltersPanelOpen && (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {listingsAreLoaded && totalItems === 0 || searchListingsError ? (
              <div className={css.notResultFoundView}>
                <div className={css.imageThumb}>
                  <img src={noTrips} alt="" />
                </div>
                <h2 className={css.error}>
                  <FormattedMessage id="SearchPage.searchErrorMessage" />
                </h2>
              </div>
            ) : null}

            {isHomeDeliverySelected ? (
              <p className={css.homeDeliveryText}>
                {' '}
                <FormattedMessage id="SearchPage.deliveryHomeText" />
              </p>
            ) : null}

            <LongTermSearchResultsPanel
              isMobileLayout={isMobile}
              onSelectCard={onSelectCard}
              searchDate={searchDate}
              className={css.searchListingsPanel}
              listings={listings}
              location={location}
              resultsCount={totalItems}
              pagination={listingsAreLoaded ? pagination : null}
              search={searchParamsForPagination}
              setActiveListing={onActivateListing}
              searchTimes={searchTimes}
              isSearchingLongTerm={isSearchingLongTerm}
              visibilityMap={visibilityMap}
              onManageDisableScrolling={onManageDisableScrolling}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              speculatedTripPricesInProgress={speculatedTripPricesInProgress}
              speculatedTripPrices={speculatedTripPrices}
            />
          </div>
        )}
      </div>
    );
  }
}

LongTermRentalMainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
};

LongTermRentalMainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  onSelectCard: func,
};

export default LongTermRentalMainPanel;
