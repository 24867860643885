import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './HelpLineNumber.css';

const HelpLineNumber = props => {
  const {
    className,
    rootClassName,
    isWhiteText,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes} id="helpLineNumberBox">
      <div className={css.questionMark}>
        ?
      </div>
      <div className={css.moreinfoText}>
        <a className={classNames(isWhiteText ? css.telLinkWhite : css.null, css.telLink)} href="tel:+61341602205">
          <FormattedMessage id="HelpLineNumber.callUs" />
        </a>
      </div>
    </div>
  );
}

HelpLineNumber.defaultProps = {
  className: null,
  rootClassName: null,
}

HelpLineNumber.propTypes = {
  className: string,
  rootClassName: string,
}

export default HelpLineNumber;

