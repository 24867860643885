import axios from 'axios';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { v1 as uuid } from 'uuid';

const { UUID, Money, LatLng } = sdkTypes;
const RELEVANCE_EMAIL = config.relevance.relevanceEmail;
const RELEVANCE_PUBLIC_API_KEY = config.relevance.relevanceApiKey;
const BASE_RELEVANCE_URL = 'https://www.tryrelevance.com';
const DEVICE = 'webapp';

// Format response to use types from SDK (e.g., UUID, Money, LatLng)
export const relevanceFormatter = response => {
  response.data.data = response.data.data.map(listing => {
    if (listing.id && listing.id.uuid) {
      listing.id = new UUID(listing.id.uuid);
    }

    if (listing.attributes) {
      const geolocation = listing.attributes.geolocation;
      const price = listing.attributes.price;
      if (geolocation) {
        listing.attributes.geolocation = new LatLng(geolocation.lat, geolocation.lng);
      }
      if (price) {
        listing.attributes.price = new Money(price.amount, price.currency);
      }
    }

    return listing;
  });

  response.data.included = response.data.included.map(includedInfo => {
    if (includedInfo.id && includedInfo.id.uuid) {
      includedInfo.id = new UUID(includedInfo.id.uuid);
    }

    return includedInfo;
  });
  return response;
};

const reportError = e => {
  let url;
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  const relevanceParams = { email: RELEVANCE_EMAIL, message: e, url };
  const relevanceOptions = { headers: { API_KEY: RELEVANCE_PUBLIC_API_KEY } };

  return axios.post(`${BASE_RELEVANCE_URL}/api/errors`, relevanceParams, relevanceOptions);
};

export const relevanceSearch = (dispatch, sdk, params, onSuccess, onError) => {
  const relevanceParams = { email: RELEVANCE_EMAIL, sharetribe: true };
  const relevanceOptions = { headers: { API_KEY: RELEVANCE_PUBLIC_API_KEY } };
  return axios
    .post(`${BASE_RELEVANCE_URL}/api/results`, { ...params, ...relevanceParams }, relevanceOptions)
    .then(response => {
      onSuccess(response);
    })
    .catch(e => {
      reportError(e);
      onError(e);
    });
};

export const triggerRelevanceSync = listingId => {
  const relevanceParams = { email: RELEVANCE_EMAIL };
  const relevanceOptions = { headers: { API_KEY: RELEVANCE_PUBLIC_API_KEY } };
  const url = `${BASE_RELEVANCE_URL}/api/dataset_rows/sync_transactions/${listingId}`;

  axios.patch(url, relevanceParams, relevanceOptions).catch(e => {
    reportError(e);
  });
};

// AB testing

const setCookie = (name, value, days) => {
  if (typeof document !== 'undefined') {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
};

const getCookie = name => {
  if (typeof document !== 'undefined') {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  } else {
    return null;
  }
};

const eraseCookie = name => {
  if (typeof document !== 'undefined') {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};

const setCookieIfNull = (name, value, days) => {
  if (getCookie(name) === null) {
    setCookie(name, value, days);
  }
};

export const assignTestGroup = () => {
  if (!config.relevance.relevanceAbTestEnabled) {
    return null;
  }
  const assignedGroup = Math.random() > 0.5 ? 'relevance' : 'sharetribe';
  let anonymousId = null;
  try {
    anonymousId = uuid.v4();
  } catch {
    return null;
  }

  if (anonymousId) {
    setCookieIfNull('relevance_test_group', assignedGroup, 100);
    setCookieIfNull('relevance_anonymous_id', `rel_id_${anonymousId}`, 100);
    return getCookie('relevance_test_group');
  } else {
    return null;
  }
};

export const trackRelevanceEvent = (name, type) => {
  const source = getCookie('relevance_test_group');
  const anonymousId = getCookie('relevance_anonymous_id');
  const relevanceParams = {
    email: RELEVANCE_EMAIL,
    device: DEVICE,
    anonymous_id: anonymousId,
    event_type: type,
    name,
    source,
  };
  const relevanceOptions = { headers: { API_KEY: RELEVANCE_PUBLIC_API_KEY } };
  const url = `${BASE_RELEVANCE_URL}/api/events`;

  axios.post(url, relevanceParams, relevanceOptions).catch(e => {
    reportError(e);
  });
};
