/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';
import { TOP_CATEGORIES } from './seoCategoriesArrays';

const SeoLandingRentOtherSegments = memo(
  ({ selectedValues, setSelectedValues, brandCategory }) => {
    const history = useHistory();
    useEffect(() => {
      const getDefaultKey =
        TOP_CATEGORIES.values &&
        TOP_CATEGORIES.values.length > 0 &&
        TOP_CATEGORIES.values.filter(item => item.label === brandCategory);
      setSelectedValues(getDefaultKey[0]);
    }, []);
    const redirectToDifferentLocation = (segment, locationName) => {
      setSelectedValues(segment);
      history.push(locationName);
      history.go(0);
    };
    return (
      <div className={css.rentFromOtherBrands}>
        <div className={css.rentFromOtherBrandsContainer}>
          <div className={css.rentFromOtherBrandsTitle}>
            <span>Rent cars from other popular segments</span>
            <p>
              Owing to the fact that all cars on Drive mate are owned by real people, we are able to
              offer a much more diverse brands of cars and that too at highly economical price
              points. Rent cars from popular brands on Drive mate today.
            </p>
          </div>
          <div className={css.rentFromOtherBrandsListing}>
            <ul className={css.listings}>
              {TOP_CATEGORIES.values.map(segment => {
                if (selectedValues && selectedValues.key !== segment.key) {
                  return (
                    <li
                      key={segment.key}
                      className={css.listingsItem}
                      onClick={() =>
                        redirectToDifferentLocation(
                          segment,
                          TOP_CATEGORIES.generateRoute(segment.key)
                        )
                      }
                    >
                      <div className={css.listingsItemContent}>{segment.label}</div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingRentOtherSegments;
