export const ERROR_TYPE__UPDATE_BOOKING__ZERO_DIFF = 'update-booking-zero-diff';
export const ERROR_TYPE__UPDATE_BOOKING__INVALID_DURATION_TIME = 'update-booking-invalid-duration-time';
export const ERROR_TYPE__UPDATE_BOOKING__BLOCKED_TIME = 'update-booking-blocked-time';
export const ERROR_TYPE__HAVE_OVERLAPPED_BOOKING = 'has-overlapped-booking';
export const ERROR_TYPE__UPDATE_BOOKING__SHORT_TIME = 'update-booking-short-time';
export const ERROR_TYPE__UPDATE_BOOKING__SHORT_TIME_BEFORE_PICK_UP = 'update-booking-short-time-before-pick-up';
export const ERROR_TYPE__UPDATE_BOOKING__DROP_OFF_TIME_BEFORE_CURRENT_MOMENT = 'update-booking-drop-off-time-before-current-moment';


export const apiErrorsMap = {
  [ERROR_TYPE__UPDATE_BOOKING__INVALID_DURATION_TIME]: 'Invalid duration time (Equal/longer than 1 hours)',
  [ERROR_TYPE__UPDATE_BOOKING__BLOCKED_TIME]: 'Dates unavailable or booking conflict. Please choose different dates or contact the host',
  [ERROR_TYPE__HAVE_OVERLAPPED_BOOKING]: 'You have an overlapped accepted booking',
  [ERROR_TYPE__UPDATE_BOOKING__ZERO_DIFF]: 'Invalid booking duration',
  [ERROR_TYPE__UPDATE_BOOKING__SHORT_TIME]: 'Trip modification requires longer or equal duration compared to original trip',
  [ERROR_TYPE__UPDATE_BOOKING__SHORT_TIME_BEFORE_PICK_UP]: 'Less then 12 hours before pick up',
  [ERROR_TYPE__UPDATE_BOOKING__DROP_OFF_TIME_BEFORE_CURRENT_MOMENT]: 'The selected time cannot be earlier than the current time'
};
