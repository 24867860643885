import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './RentalAgreement.css';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

const RentalAgreement = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div style={{color: '#00a3ad'}}>
      <h1><strong>RENTAL AGREEMENT</strong></h1>
      <p>This rental agreement is entered into between the Host and the Guest (each, a “<strong>party</strong>”  and collectively, the “<strong>parties</strong>”) for the rental of the Vehicle on the following terms and conditions <strong>(Terms and Conditions)</strong>.</p>
      <br/>
      <div>
          <h2>1. DEFINITIONS AND INTERPRETATION</h2>
      </div>
      <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.1</span>The definitions and rules of interpretation in this clause apply in this agreement.</p>
      <br />
      <div className={css.tableOuter}>
        <table>
            <tbody>
                <tr className={css.row}>
                    <td className={css.left}>
                        <p><strong>Accident</strong></p>
                    </td>
                    <td className={css.right}>
                        <p>an unintended and unforeseen incident, including:</p>
                        <ol className={css.alphabetListing}>
                          <li>
                            a collision between the Vehicle and another vehicle or object, including animals and roadside infrastructure;
                          </li>
                          <li>
                            rollovers; and
                          </li>
                          <li>
                            weather events, including hail Damage,
                          </li>
                        </ol>
                        <p>
                          that results in Damage or Third Party Loss.
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                    <td className={css.left}>
                      <p><strong>Authorised <br /> Driver</strong></p>
                    </td>
                    <td className={css.right}>
                      <p>a person with a valid licence to drive in the jurisdiction the Vehicle is being driven in, who has been verified by Drive mate in accordance with its policies and notified to the Host as a driver of the Vehicle</p>
                    </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                      <p><strong>Damage</strong></p>
                  </td>
                  <td className={css.right}>
                      <p>means:</p>
                      <ol className={css.alphabetListing}>
                        <li>
                          any loss or damage to the Vehicle including its parts, components and accessories, including the GPS unit, that is not fair wear and tear;
                        </li>
                        <li>
                          towing and salvage costs;
                        </li>
                        <li>
                          assessing fees; and
                        </li>
                        <li>
                          Loss of Use,
                        </li>
                      </ol>
                      <p>
                        and for the removal of doubt, any Damage to the windscreen, headlights, lights or tyres that makes the Vehicle unroadworthy is <strong>not</strong> fair wear and tear.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                    <p><strong>Damage Cover</strong></p>
                  </td>
                  <td className={css.right}>
                    <p>the cover the Guest and an Authorised Driver have for Damage, theft, attempted theft and Third Party Loss under clause 5, subject to the Damage Cover Exclusions in clause 7. </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                    <p><strong>Damage Excess</strong></p>
                  </td>
                  <td className={css.right}>
                    <p>the amount, including GST, up to which the Guest <strong>must </strong> pay Us in the event of an Accident or attempted theft that causes Damage or Third Party Loss or the Vehicle has been stolen and not recovered.</p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                    <p><strong>Designated <br /> Location</strong></p>
                  </td>
                  <td className={css.right}>
                    <p>the designated location at which the Vehicle is to be collected or returned to the Host by the Guest, or such other location as parties may agree.</p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Drive mate, We, Us, Our</strong></p></td>
                  <td className={css.right}>
                      <p>Drive mate Australia Pty Ltd ABN 19 640 421 496.</p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Drop-off Time</strong></p></td>
                  <td className={css.right}>
                      <p>the time and date of which the later of the parties have confirmed via the Platform the return of the Vehicle to the Host at the Designated Location.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Guest</strong></p></td>
                  <td className={css.right}>
                      <p>the person, whose particulars are set out in the Platform, who is renting or intending to rent a Vehicle.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Host</strong></p></td>
                  <td className={css.right}>
                      <p>the person, whose particulars are set out in the Platform, who has a Vehicle to rent on the Platform.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Listing</strong></p></td>
                  <td className={css.right}>
                      <p>the Vehicle that is listed on the Platform.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                      <p><strong>Overhead <br /> Damage</strong></p>
                  </td>
                  <td className={css.right}>
                      <ol className={css.alphabetListing}>
                        <li>
                          Damage to the Vehicle; and
                        </li>
                        <li>
                          Third Party Loss,
                        </li>
                      </ol>
                      <p>caused by:</p>
                      <div className={css.padLeft}>
                        <ol className={css.romanListing}>
                          <li>
                            contact between the part of the Vehicle that is at or above the level of the top of the front windscreen with objects overhanging or obstructing its path;
                          </li>
                          <li>
                            objects being placed on the roof of the Vehicle; or
                          </li>
                          <li>
                            the Guest or any person standing or sitting on the roof of the Vehicle.
                          </li>
                        </ol>
                      </div>
                      {/* <p>
                        and for the removal of doubt, any Damage to the windscreen, headlights, lights or tyres that makes the Vehicle unroadworthy is not fair wear and tear.
                      </p> */}
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Personal <br /> Information</strong></p></td>
                  <td className={css.right}>
                      <p>has the meaning given in the Privacy Act 1988 (Cth)
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Pick-up Time</strong></p></td>
                  <td className={css.right}>
                      <p>the time and date of which the later of the parties has confirmed collection via the Platform of the Vehicle by the Guest from the Host at the Designated Location
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Platform</strong></p></td>
                  <td className={css.right}>
                      <p>Drive mate’s website <a href={`https://${DOMAIN_URL}/`} title="Drive mate Australia">(https://{DOMAIN_URL}/)</a> and/or its associated mobile applications.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Policies</strong></p></td>
                  <td className={css.right}>
                      <p>the policies imposed by Drive mate on Guests and Hosts from time to time, as set out on the Platform and in clause 5.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Privacy Policy</strong></p></td>
                  <td className={css.right}>
                      <p>Drive mate's Privacy Policy available at <a href={`https://${DOMAIN_URL}/privacy-policy`} title="Drive mate Australia">https://{DOMAIN_URL}/privacy-policy</a>
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Rental Period</strong></p></td>
                  <td className={css.right}>
                      <p>the period of hire for the Vehicle as agreed between the parties via the Platform, or the date and time the Vehicle is returned to the Host at the Designated Location, whichever is the later.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}>
                      <p><strong>Serious Breach</strong></p>
                  </td>
                  <td className={css.right}>
                      <p>a breach of any of the following:</p>
                      <ol className={css.alphabetListing}>
                        <li>
                        clauses 4.1, 4.2, 4.3 or 5 that causes Damage, theft of the Vehicle or Third Party Loss; or
                        </li>
                        <li>
                        clause 8 (all parts) that prevents Us from properly investigating a claim arising from an Accident or theft or from prosecuting or defending any Accident or theft claim.
                        </li>
                      </ol>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Terms</strong></p></td>
                  <td className={css.right}>
                      <p>these Terms and Conditions, the terms of use of the Platform, as modified or amended by Drive mate from time to time.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Third Party Loss</strong></p></td>
                  <td className={css.right}>
                      <p>loss or damage to third party property, including other motor vehicles and any claim for third party loss of income.
                      </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Trip Price</strong></p></td>
                  <td className={css.right}>
                    <p>the fees payable to Drive mate for the rental of the Vehicle by the Guest, as set out on the Listing, exclusive of any service fees or other fees payable to Drive mate or any other third party.
                    </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Unsealed Road</strong></p></td>
                  <td className={css.right}>
                    <p>a road, other than a road that is undergoing temporary roadworks, that has been formed and constructed but is not sealed with a hard material such as tar, bitumen or concrete.
                    </p>
                  </td>
                </tr>
                <tr className={css.row}>
                  <td className={css.left}><p><strong>Vehicle</strong></p></td>
                  <td className={css.right}>
                      <p>
                        a motor vehicle and registered with the relevant state road authority as being operated by a Host and listed on the Platform by that Host for rent.
                      </p>
                  </td>
                </tr>
                {/* <tr className={css.row}>
                <td className={css.left}><span><strong>Host</strong></span></td>
                <td className={css.right}>
                        <p>the person, whose particulars are set out in the Platform, who has a Vehicle to rent on the Platform;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Guest</strong></span></td>
                <td className={css.right}>
                        <p>the person, whose particulars are set out in the Platform, who is intending to rent a Vehicle;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Vehicle</strong></span></td>
                <td className={css.right}>
                        <p>a vehicle defined under the Road Traffic Act (Cap. 276) as a motor vehicle and registered with the Land Transport Authority as belonging to a Host and listed on the Platform by that Host for rent;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>PDPA</strong></span></td>
                <td className={css.right}>
                        <p>Personal Data Protection Act 2012;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Personal Data</strong></span></td>
                <td className={css.right}>
                        <p>has the meaning given in the PDPA;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Pick-up Time</strong></span></td>
                <td className={css.right}>
                        <p>the time and date of which the later of the parties have confirmed collection via the Platform of the Vehicle by the Guest from the Host at the Designated Location;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Policies</strong></span></td>
                <td className={css.right}>
                        <p>any policies imposed by Drive mate on Guests and/or Hosts from time to time, as set out on the Platform;</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Platform</strong></span></td>
                <td className={css.right}>
                        <p>Drive mate’s website (<a href={`https://${DOMAIN_URL}/`}>https://{DOMAIN_URL}/</a>) and/or its associated mobile applications;</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Rental Period</strong></span></td>
                <td className={css.right}>
                        <p>the period of hire for the Vehicle as agreed between the parties via the Platform; and</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr>
                <tr className={css.row}>
                <td className={css.left}><span><strong>Terms</strong></span></td>
                <td className={css.right}>
                        <p>the terms of use of the Platform, as modified or amended by Drive mate from time to time.</p>
                        <p>
                            <br/>
                        </p>
                    </td>
                </tr> */}
            </tbody>
        </table>
      </div>
      <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.2</span>Clause and paragraph headings shall not affect the interpretation of this agreement.&nbsp;</p>
    {/* <p>
        <br/>
    </p> */}
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.3</span>A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's legal and personal representatives, successors and permitted assigns.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.4</span>A reference to a company shall include any company, corporation or other body corporate, wherever and however incorporated or established.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.5</span>Unless the context otherwise requires, words in the singular shall include the plural and vice versa.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.6</span>Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.7</span>A reference to a statute or statutory provision is a reference to it as it is in force for the time being, taking account of any amendment, extension or re-enactment, and includes any subordinate legislation for the time being in force made under it.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.8</span>A reference to writing or written includes email.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>1.9</span>Any words following the terms including, include, in particular or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.</p>
    <p>
      <br />
    </p>
    <div>
        <h2>2. RENTAL OF VEHICLE&nbsp;</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.1</span>The Host shall hire the Vehicle to the Guest for the Rental Period, subject to the Terms.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.2</span>The parties agree that they shall at all times abide by the Terms and the prevailing Policies.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.3</span>The Vehicle shall at all times remain the property of the Host, and the Guest shall have no right, title or interest in or to the Vehicle (save the right to possession and use of the Vehicle during the Rental Period, subject to the terms and conditions of this agreement).</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.4</span> The risk of loss, theft, damage or destruction of the Vehicle shall pass to the Guest at the Pick-Up Time. The Vehicle shall remain at the sole risk of the Guest during the Rental Period and any further term during which the Vehicle is in the possession, custody or control of the Guest, until the Drop-off Time. </p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.5</span>The parties agree that they shall at all times, comply with any applicable laws or regulations, including any laws or regulations.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>2.6</span>For the avoidance of doubt, the parties acknowledge and agree that Drive mate is not a party to this contract and may only be a party to the extent that Drive mate facilitates transactions between the Host and the Guest. Otherwise, Drive mate does not hold any substantive rights or obligations under these Terms and when performing any action or function under the rental agreement, Drive mate does so as agent of the Host and it has no liability to the Guest or third parties for any of the actions or functions it performs on the Host's behalf.</p>
    <p>
      <br />
    </p>
    <div>
        <h2>3. FEES</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>3.1</span>In consideration of the hire of the Vehicle to the Guest for the Rental Period, the Guest shall pay the Trip Price to Drive mate.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>3.2</span>The parties agree that the Trip Price shall be paid to the Host through the payment services offered via the Platform, in accordance with the Terms and the Policies.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>3.3</span>The parties acknowledge and agree to pay any additional fees set out in the Policies, including any feespayable to the other party for additional mileage, latereturn, car breakdownorany other failure to comply with the Terms and/or the Policies.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>3.4</span>All payments to be made by either party under this agreement shall be made without withholding or set-off on account of disputes, counterclaims or for any other reason whatsoever.</p>
    <p>
      <br />
    </p>
    <div>
        <h2>4. GUEST’S RESPONSIBILITIES&nbsp;</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>4.1</span>The Guest shall during the term of this agreement:</p>
    <div className={css.padLeft}>
    <ol className={css.alphabetListing}>
      <li>
        have a current valid license to drive the Vehicle which has been submitted to Drive mate for verification and which is:
        <ol className={css.romanListing}>
          <li>
            issued in an Australian state or territory or an international license (with a valid International Driving Permit or an approved translation into English if the license is not issued in English);
          </li>
          <li>
            appropriate for the class of the Vehicle; and
          </li>
          <li>
            not subject to any restriction or condition;
          </li>
        </ol>
      </li>
      <li>
        ensure that the Vehicle is used in a proper and skillful manner, and at all times in accordance with the Policies and the Terms, and the Vehicle’s manufacturer requirements, recommendations and instruction manuals;
      </li>
      <li>
        ensure that the Vehicle is operated only by the Authorised Driver;
      </li>
      <li>
        ensure that all applicable rules and regulations, including any road traffic codes or rules of conduct in the relevant state jurisdiction are complied with;
      </li>
      <li>
        maintain at its own expense the Vehicle in the same condition as at the Pick-Up Time (fair wear and tear only excepted, in accordance with the Policies);
      </li>
      <li>
        keep the Host fully informed of all material matters relating to the Vehicle, including complying with any applicable Policies regarding the reporting of any accident, damage or loss to the Vehicle; and
      </li>
      <li>
      comply with all Policies regarding the pick-up and drop-off of the Vehicle from and with the Host;
      </li>
    </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>4.2</span>During the term of this agreement, the Guest must not:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          allow the Vehicle to be driven by any person who is not an Authorised Driver:
        </li>
        <li>
          drive the Vehicle on:
          <ol className={css.romanListing}>
            <li>
              an Unsealed Road;
            </li>
            <li>
              roads that are prone to flooding or are flooded;
            </li>
            <li>
              beaches, sand dunes, streams, rivers, creeks, dams or off-road;
            </li>
            <li>
              any road where the police or an authority has issued a warning;
            </li>
            <li>
              any road that is closed; or
            </li>
            <li>
              any road where it would be unsafe to drive the Vehicle.
            </li>
          </ol>
        </li>
        <li>
          use the Vehicle in any alpine resort during the gazetted snow season or in any other area under conditions where a reasonable person would use snow chains;
        </li>
        <li>
          part with control of (including for the purposes of repair or maintenance), sell or offer for sale, underlet, sublet or lend the Vehicle or allow the creation of any mortgage, charge, lien or register or claim to be entitled to register any interest in the Vehicle under the Personal Property Securities Act 2009 (Cth), without the prior written consent of the Host;
        </li>
        <li>
          drive the Vehicle whilst their driver's license is cancelled or suspended, including as a result of an accumulation of demerit points;
        </li>
        <li>
          use the Vehicle:
          <ol className={css.romanListing}>
            <li>
              for any commercial or non-personal use (including but not limited to using the Vehicle as a private hire vehicle, or carry any passengers for payment of any kind or for rideshare purposes);
            </li>
            <li>
              when it is damaged or unsafe;
            </li>
            <li>
              for any illegal purpose;
            </li>
            <li>
              to move dangerous, hazardous, biohazardous, infectious, inflammable goods or substances that pollute or contaminate, in quantities above that used for domestic purposes;
            </li>
            <li>
              to propel or tow another vehicle or a trailer;
            </li>
            <li>
              to carry or transport illegal drugs or substances; or
            </li>
            <li>
              in connection with the motor trade for experiments, tests, trials or demonstration purposes;
            </li>
          </ol>
        </li>
        <li>
          use the Vehicle to carry:
          <ol className={css.romanListing}>
            <li>
              more than the number of passengers for which the Vehicle is licensed; or
            </li>
            <li>
              any load that exceeds the limits for which the Vehicle was designed, constructed, registered or licensed.
            </li>
          </ol>
        </li>
        <li>
          make any alterations to the Vehicle;
        </li>
        <li>
          affix or install any accessories, equipment or devices on to the Vehicles without the Hosts’ prior written consent;
        </li>
        <li>
          deliver up the Vehicle at the end of the Rental Period at the Designated Location in a tardy or late manner in contravention of the Policies, failing which the Policy on late returns shall apply; and
        </li>
        <li>
          drive the Vehicle:
          <ol className={css.romanListing}>
            <li>
              whilst intoxicated or under the influence of drugs or alcohol or with a blood alcohol content or level of drugs present in blood, urine or oral fluid that exceeds the limit set by law;
            </li>
            <li>
              recklessly or dangerously; or
            </li>
            <li>
              whilst there is Damage to the Vehicle or it is unroadworthy or unsafe.
            </li>
          </ol>
        </li>
        <li>
          fail or refuse to undergo any breath, blood, urine or oral fluid test or drug impairment assessment;
        </li>
        <li>
          use a mobile phone:
          <ol className={css.romanListing}>
            <li>
              to make or receive a phone call, perform any audio function or as a navigational device, <strong>unless</strong> the Vehicle is stationary and the body of the phone is secured in a mounting affixed to the Vehicle and its use does not require manual operation of the phone; or
            </li>
            <li>
              to send a text message, video message, email or similar communication <strong>unless</strong> the Vehicle is parked.
            </li>
          </ol>
        </li>
        <li>
          damage the Vehicle deliberately or recklessly or allow anyone else to do so;
        </li>
        <li>
          modify the Vehicle in any way;
        </li>
        <li>
          sell, rent, lease or dispose of the Vehicle;
        </li>
        <li>
          use the Vehicle to move infectious, biohazardous or biomedical waste, <strong>unless</strong> specifically approved by Us.
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>4.3</span>The Guest shall during the term of this agreement:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          ensure the Vehicle is locked when not in use or unattended and the keys or remote control device <strong>must</strong> be kept in the Guest's possession, or that of any Authorised Driver, at all times and never left in the ignition when the Vehicle is unattended; and
        </li>
        <li>
          take reasonable care of the Vehicle by:
          <ol className={css.romanListing}>
            <li>
              preventing it from being damaged;
            </li>
            <li>
              making sure that it is protected from the weather;
            </li>
            <li>
              maintaining the engine and brake oils and coolant level and tyre pressures;
            </li>
            <li>
              using the correct fuel type; and
            </li>
            <li>
              making sure it is not overloaded.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>4.4</span>Unless specifically approved by the Host, the Guest <strong> must not: </strong></p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          use the Vehicle for transporting any pets or animals, except assistance animals;
        </li>
        <li>
          smoke in the Vehicle and the Guest <strong>must</strong> take reasonable steps to prevent passengers from doing so; or
        </li>
        <li>
          use the Vehicle to move infectious, biohazardous or biomedical waste.
        </li>
      </ol>
      <p>
        Additional cleaning, disinfection and deodorising charges as set on the Platform will apply.
      </p>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>4.5</span>The Guest must pay all tolls, speeding and traffic fines and infringements as well as any fines or charges imposed for parking or using the Vehicle or release of the Vehicle if it has been seized by a regulatory authority. An administrative fee applies if We are required to nominate the Guest as the responsible driver if any toll, fine or infringement is unpaid. </p>
    <p>
      <br />
    </p>
    <div>
        <h2>5. POLICIES</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.1</span><strong>Policies are set out on the Platform </strong></p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
        The Policies that apply to the agreement between the Guest and the Host for the term of the agreement are set out in detail on the Platform and will be strictly enforced for compliance. All the policies can be found at https://help.{DOMAIN_URL}/kb/en
        </li>
        <li>
          The Policies may be amended from time to time and the Guest should refer to the Website for the current version.  If there is any inconsistency between these Terms and Conditions and the Platform, those on the Platform will prevail.
        </li>
        <li>
          A breach of any Policy is a Serious Breach of the agreement.
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.2</span><strong>Vehicle cleanliness Policy </strong></p>
    <div className={css.padLeft}>
      <p>We expect hosts to provide guests with a clean car. In turn, guests must return the car in the same condition in which they received it or as close to clean as possible. If a guest does not want to accept a car because they find it to be unacceptably dirty, they should not begin their trip. Instead, they should contact Drive mate customer support so we can help them address the issue.</p>
      <p>
      If a host receives a dirty car from a guest, they can charge the guest for cleaning fees.
      </p>
      <br/>

      <p style={{display: "flex"}}><strong> HOW DO I DOCUMENT THE CAR’S CONDITION? </strong></p>
      <p>
        We advise hosts and guests to take multiple photos of the car inside and out and upload them directly to the Trip Photos in the trip page. We suggest taking interior photos of the middle console, front seats and floor, and the back seats and floor. We also suggest taking exterior photos of the driver side, passenger side, front, and back of the car as well. The more photos you take, the better. Be sure to capture the same views of the car in both the pre- and post-trip photos so that it’s easy to compare the two.
      </p>

      <br />
      <p>
        The best way to capture and save these photos is to use the trip check-in and checkout feature in the Drive mate app. The app will guide you through each photo you need to take and place a timestamp on the photos. These photos are stored in Trip photos and can be easily accessed by Customer Support.
      </p>

      <p style={{display: "flex"}}><strong>WHY ARE PHOTOS IMPORTANT?</strong></p>

      <p>
        In the event of a dispute, Drive mate will use photos to determine if a host is eligible for reimbursement of cleaning costs and if a guest should be charged a cleaning fee. Hosts and guests who take multiple, clear, consistent view photos of a car’s condition have verifiable proof of the car’s condition at the start and end of a trip.
      </p>

      <p style={{display: "flex"}}><strong>HOW DO HOSTS FILE FOR A CLEANING REIMBURSEMENT? </strong></p>

      <p>To file a cleaning reimbursement, hosts must submit their claim within 48 hours of the trip end with proof of the issue to {SUPPORT_EMAIL}. Drive mate will review the photos and determine the amount to be paid to the host based on the cleaning level, as outlined below. If we determine that the guest is responsible for the car’s dirty condition, we’ll charge them in line with this policy. </p>

      <p style={{display: "flex"}}><strong>CLEANING FEES AND FINES </strong></p>

      <p>The levels of cleaning and the eligible reimbursement amounts are as follows:  </p>


      <p style={{display: "flex"}}><strong> INELIGIBLE FOR REIMBURSEMENT </strong></p>

      <p>Minimal amounts of trash. Small amounts of crumbs, sand, or dirt on floor mats that can be shaken off or vacuumed out.Small marks that can be cleaned off easily by hand.</p>

      <p style={{display: "flex"}}><strong>MODERATE CLEANING - UP TO $60</strong></p>

      <p>
      Car exterior is returned significantly* dirtier than it was received (e.g. dirt on car body, wheels, or windows).Floor mats or upholstery are returned significantly* dirtier than they were received(e.g. requires more than a shake-out to be restored to original condition).Large amounts of food, dirt, or mud that cannot be shaken out and must be heavily vacuumed to remove. Light stains or residue on hard surfaces (e.g. liquid spills or sticky substances).
      </p>
      <p>*Drive mate will use its discretion to determine what is considered significant.</p>

      <p style={{display: "flex"}}><strong>HEAVY CLEANING - $180</strong></p>
      <p>Major stains or residue on seat fabric or other hard-to-clean surfaces. Pet Hair Smoke scent /physical remnants of smoking.</p>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.3</span><strong>Pick up &amp; Drop off policy </strong></p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          <p>Taking pre- and post-trip photos are the key to a successful vehicle pickup and drop off.</p>
          At pick up:
          <ol className={css.romanListing}>
            <li>
              Guest to show the host a valid driving license. If it s a keyless access (Drive mate Go trip), then guest needs to upload a selfie with driving license.
            </li>
            <li>
              Guest to inspect the car to understand how it operates
            </li>
            <li>
              Guest to take pic of the dashboard to capture mileage and fuel level (optional for host)
            </li>
            <li>
              Guest to take photos of the car’s interior and exterior and be sure to capture any pre-existing damage so that they are not held responsible for it. (Optional for host)
            </li>
            <li>
              In case of physical handover, host to confirm pick up via the app. For keyless handover (Drive mate Go), pick up is auto approved. Guest can access the car via the app.
            </li>
          </ol>
        </li>
        <li>
          At drop off:
          <ol className={css.romanListing}>
            <li>
              Guest to take photos of the car's mileage and fuel levels
            </li>
            <li>
              Guest to take photos of the car's interior and exterior to document its condition
            </li>
            <li>Tidy up the car </li>
            <li>In case of physical handover, host to confirm drop off via the app. For keyless handover, drop off is auto approved. Guest needs to take their belongings and lock the car via the app.</li>
            {/* <li>
            The best way to capture and save these photos is to use the trip check-in and checkout
feature in the Drive mate app. The app will guide the Guest through each photo that they
need to take and place a timestamp on the photos. These photos are stored in Trip
photos and can be easily accessed by Customer Support.
            </li>
            <li>
            If the Guest fails to take the photos at pick up or drop off as required by this clause 0,
they may forfeit the right to dispute that Damage to the Vehicle occurred during the
Rental Period.
            </li> */}
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.4</span><strong>Trip Cancellation Policy</strong></p>
    <div className={css.padLeft}>
      <p>
        Drive mate understands that Hosts and Guests can have a change in plan due to unforeseen circumstances. In that case, Drive mate wants to be fair to all parties involved. There are specific rules for cancellation depending upon the type of booking it is (regular or instant) and the time of cancellation (vs scheduled trip time).
      </p>
      <ol className={css.alphabetListing}>
        <li>
          <i>Guest trip cancellation Policy</i>
          <ol className={css.romanListing}>
            <li>
              Guests may cancel their trip through Our site or mobile app, and the cancellation is effective immediately.
            </li>
            <li>
              For all cancellations by Guests, the processing fees are not refundable and the total amount refunded will depend on when the Guest cancels the trip. A breakdown of the refund due is on the Platform.
            </li>
          </ol>
        </li>
        <li>
          <i>Host trip cancellation policy</i>
          <ol className={css.romanListing}>
            <li>
              If the Hosts cancel a booking, Drive mate may charge the Host a fee in order to help compensate the Guest for the inconvenience and for finding alternative transport.  A full refund will be provided to the Guest and processing fees will also be refunded.
            </li>
            <li>
              For physical handover enabled cars only, fees also apply where the Host fails to make the Vehicle or key available for a booking, for example, because they are unreachable at the time of handover.  A 'No Show' is determined if the Host is not contactable either by the Guest or customer service less than one (1) hour before trip start time.  In case of 'No Show' by the Host, a credit will be given to the Guest, <strong>except</strong> if the trip start time is within 24 hours of the booking being accepted.
            </li>
          </ol>
        </li>
        <li>
          <i>Cancellation by Drive mate</i>
          <ol className={css.romanListing}>
            <li>
              In some cases, Drive mate may need to cancel a trip after booking. Drive mate will notify the Guest in the event Drive mate must cancel a trip. Where Drive mate must cancel a trip, the Guest will receive a full refund.
            </li>
            <li>
              Please note that for exceptional circumstances, acting reasonably, Drive mate reserves the right to allow certain cancellations without any penalty to the Guest or Host. These circumstances are purely at the discretion of Drive mate and are for the protection of the interests of Guests and Hosts.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.5</span><strong>Fuel Policy</strong></p>
    <div className={css.padLeft}>
      <p>It is the responsibility of the guest at check-out to return the car’s fuel tank filled back to the check in levels. It is mandatory for the guests to take pictures of the fuel gauge at check in & check out for non Drive mate Go cars. For Drive mate Go cars, fuel is always paid for by the guest basis the distance travelled.</p>
      <ol className={css.alphabetListing}>
        {/* <li>
        If the guest does not bring the car back it's original fuel level, then the guest is charged at
$3 per litre. The host receives 80% of this amount.
        </li> */}
        <p style={{display: "flex"}}><strong>For cars with keyless access:</strong></p>
        <li>
          No reporting required from host. Guests are charged $0.29 / km basis the distance travelled (measured via GPS). Host is paid $0.22/ km out of this charge.
        </li>
        <li>
          If the guest refuels during the trip, they can upload the picture of the fuel receipt upon drop off. Equivalent amount will then be refunded back to the guest.
        </li>
        <p style={{display: "flex"}}><strong>For cars with physical key handover:</strong></p>
        <li>
          Host will need to submit documentation - the pictures of the car’s odometer at pick up and drop off. Hosts can submit the documentation via the online help desk (web/ app) within 1 hr of the trip getting completed.
        </li>
        <li>
          If the guest does not bring the car back it's original fuel level, then the guest is charged at $0.40/km for fuel (calculated basis 10 litres for 100 kms). The host receives 80% of this amount.
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.6</span><strong>Wear &amp; Tear Policy</strong></p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          Normal “wear and tear” that results from the normal use of the Vehicle is an inherent part of sharing or using a car.
        </li>
        <li>
          The Guest is not responsible for wear and tear or mechanical failure resulting from normal usage of the car.
        </li>
        <li>
          However, the Guest is fully responsible for, and Drive mate does not provide any protection to the Guest for:
          <ol className={css.romanListing}>
            <li>
              any Damage related to misuse or arising from a Serious Breach;
            </li>
            <li>
              serious Damage to the interior of the Vehicle, including gouges, tears, and  punctures that is not caused by an accident; and
            </li>
            <li>
              any mechanical Damage resulting from the Guest’s negligence, intentional acts, or improper driving (for example, clutch Damage from rough driving, suspension Damage from running over speed bumps at high speed or into curbs, Underbody and Overhead Damage.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.7</span><strong>Late Return Policy</strong></p>
    <div className={css.padLeft}>
      <p>
        Hosts are counting on guests to return the car at the originally scheduled time. If guests don't, it can put significant inconvenience and stress on the host and possibly future guests.
      </p>
      <p>
        For the guests, we want to ensure that there is sufficient flexibility when it comes to dropping off as there are practical issues sometimes (traffic, getting stuck due to unavoidable reasons). For this, Drive mate provides a grace period of 15 mins to all guests depending upon the duration of the rental. It is always recommenced to opt for trip extension if the guest feels that they are likely to get delayed (trip extensions are subject to approval by the host).
      </p>
      <p>
        <strong>Charges to guests</strong>
      </p>
      <p>
        If the guest is delayed beyond the grace period, then a late return charge + inconvenience fee will apply.
      </p>
      <ol className={css.alphabetListing}>
        <li>
          The late return charge is calculated basis the car's daily/hourly rate depending and Drive mate fees.
        </li>
        <li>
          The inconvenience fee is $30 (if no booking is impacted) and $75 (if next booking is impacted).
        </li>
      </ol>
      <p>
        <strong>Payouts to hosts</strong>
      </p>
      <p>
        The host will be paid their share of the late return charge + inconvenience fee
      </p>
      <ol>
        <li>
          Host's share of the late return charge will be basis the daily/hourly rate set in the system by them
        </li>
        <li>
          Inconvenience fee of $20 (if no booking is impacted) and $50 (if next booking is impacted).
        </li>
      </ol>
      <p>
        In the event of very substantial lateness, additional fines may be assessed at sole discretion of Drive mate. In case it is unclear when the guest will return the car, Drive mate could decide to pick up the car and return the car to the host and charge repossession fees.
      </p>
      <p>
        Drive mate reserves the authority to charge the estimated late return charge to the guest's payment method on file.
      </p>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>5.8</span><strong>Mileage Policy</strong></p>
    <div className={css.padLeft}>
      <p>
        On Drive mate, hosts set a daily price assuming a 100 km mileage limit. If it exceeds the mileage limit, then the host is compensated for the same as below.
      </p>
      <div className={css.padLeft}>
        <p style={{display: "flex"}}><strong>WHAT EXACTLY ARE THE MILEAGE LIMITS?</strong></p>
        <ol>
            <li>
              Hosts set a daily price assuming wear and tear included for 100 km daily mileage.
            </li>
            <li>
              For hourly trips, the pricing is auto calculated by the system basis host's daily price.
            </li>
        </ol>
        <p>
          Up to 4 hrs, 40 kms mileage shall apply.
        </p>
        <p>
          after 4 hrs, mileage limit of 60 kms shall apply.
        </p>
        <p>
          after 6 hrs, mileage limit of 80 kms shall apply.
        </p>
        <p>
          After 8 hrs, daily mileage limit of 100 kms shall apply.
        </p>
        <p>
          Any distance travelled over and above the mileage limit shall classify as excess distance and the cost will be borne by the guest as follows.
        </p>
        <p style={{display: "flex"}}><strong>HOW IS EXCESS DISTANCE CALCULATED?</strong></p>
        <p>
          We charge guests for each additional kilometre they’ve driven over the mileage limit for their trip. The funds collected are added to the host’s account to compensate them for the added wear and tear to their vehicle.
          {/* The charges per additional kilometre to the guest are dependent on the type of car. */}
        </p>
        {/*
          <p>
            For Tier 1 & Tier 2 vehicles:  $0.12 per km
          </p>
          <p>
            For Tier 3 & Tier 4 vehicles: $0.18 per km
          </p>
        */}
        {/* <p>
          To understand which car brands & models fall under which tier, please <a href={`https://help.{DOMAIN_URL}/kb/guide/en/what-are-the-various-tiers-for-excess-mileage-LoTMKdpaIf/Steps/834122`} style={{color: "blue", textDecoration: "underline"}} title="What are the various tiers for excess mileage?">click here.</a>
        </p> */}
        <p>
          We additionally charge <strong>0.35$/km</strong> for all car types on extra mileage
        </p>
        <p>
          The host share of the additional charge is 80%. <strong>Please note that these charges cover only wear and tear. Fuel is treated separately. </strong>
        </p>
        <p style={{display: "flex"}}><strong>HOW IS THE ISSUE REPORTED?</strong></p>
        <p style={{display: "flex"}}><strong>For cars with keyless access:</strong></p>
        <p>No reporting required from hosts. Drive mate system auto calculates the excess mileage and charges the guest. Host is compensated automatically.  Drive mate has two options (depending upon the vehicle) to assess excess distance:</p>
        <ol>
          <li>
            From the Drive mate Go system - cars with OBD 2 port help us extract data from the odometer
          </li>
          <li>
            From the GPS device/ pics of the odometer
          </li>
        </ol>
        <p style={{display: "flex"}}><strong>For cars with physical key handover:</strong></p>
        <p>
          Host will need to submit documentation - the pictures of the car’s odometer at pick up and drop off or basis the car’s GPS system (if available). Hosts can submit the documentation via the online help desk (web/ app) within 24 hrs of the trip getting completed.
        </p>
      </div>

    </div>
    <p>
      <br />
    </p>
    <div>
        <h2>6. DAMAGE COVER&nbsp;</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>6.1</span>Standard Damage Cover is included in the Rental Charges.</p>
    <p><span style={{flex: "0 0 40px", maxWidth: 40}}>6.2&nbsp;&nbsp;&nbsp;&nbsp;</span>Subject to these Terms and Conditions, the Guest will be indemnified for any Damage to the Vehicle, its theft and Third Party Loss but the Guest <strong>must</strong> pay up to the Damage Excess shown on the Rental Agreement for each Accident or theft claim <strong>unless:</strong></p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          the Host and Drive mate agree the Guest was not at fault; and
        </li>
        <li>
          the other party was insured and their insurance company accepts liability.
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>6.3</span>Unless the Guest has expressly authorised a charge to their credit card at an earlier time an amount up to, but not exceeding, the Damage Excess will be charged to their credit card:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          for single vehicle Accidents, after a repairer's estimate or tax invoice verifying the amount charged for Damage has been sent to the Guest;
        </li>
        <li>
          if the Vehicle has been stolen, after the Host and Drive mate have made reasonable enquiries and in their opinion it is unlikely the Vehicle will be recovered; and
        </li>
        <li>
          for Accidents in which there is also Third Party Loss, after the Host and Drive mate have made an estimate of the Guest's total liability. Supporting documents and particulars of the claim for Third Party Loss will be forwarded to the Guest as soon as practicable.
        </li>
      </ol>
    </div>
    <p>
      <br />
    </p>
    <div>
      <h2>7. DAMAGE COVER EXCLUSIONS</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>7.1</span>Even if the Damage Excess has been paid, there is no Damage Cover, and the Guest is liable for:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          Damage or Third Party Loss arising from:
          <ol className={css.romanListing}>
            <li>
              a Serious Breach of the Rental Contract; or
            </li>
            <li>
              the use of the Vehicle by any driver who is not an Authorised Driver or who is less than 21 or more than 75 years of age;
            </li>
          </ol>
        </li>
        <li>
          Overhead Damage;
        </li>
        <li>
          Underbody Damage;
        </li>
        <li>
          Damage caused by:
          <ol className={css.romanListing}>
            <li>
              immersion of the Vehicle in water;
            </li>
            <li>
              use of the incorrect fuel type;
            </li>
          </ol>
        </li>
        <li>
          damage to the tyres of the Vehicle, other than by normal wear and tear
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>7.2</span>There is also no Damage Cover for:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          the full cost of replacing or repairing any accessories supplied by the Host including, but not limited to GPS units, lost keys, keyless start and remote control devices;
        </li>
        <li>
          personal items that are left in or stolen from the Vehicle or for loss or damage to property belonging to or in the custody of:
          <ol className={css.romanListing}>
            <li>
              the Guest;
            </li>
            <li>
              any relative, friend or associate who ordinarily resides with the Guest or with whom the Guest ordinarily resides; or
            </li>
            <li>
              the Guest's employees.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p>
      <br />
    </p>
    <div>
        <h2>8. ACCIDENT AND THEFT REPORTING</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>8.1</span>If the Guest has an Accident or if the Vehicle is stolen, the Accident or theft must be reported to Drive mate as soon as practicable but in no case more than 24 hours of it occurring and fully complete an Accident/Theft report form.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>8.2</span>If the Vehicle is stolen or if the Guest has an Accident where:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          any person is injured;
        </li>
        <li>
          the other party has failed to stop or leaves the scene of the Accident without exchanging names and addresses; or
        </li>
        <li>
          the other party appears to be under the influence of drugs or alcohol,
        </li>
      </ol>
      <p>
      the Guest <strong>must</strong> also report the theft or Accident to the Police.
      </p>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>8.3</span>If the Guest has an Accident they must:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          exchange names and addresses, phone numbers and email addresses with the other driver;
        </li>
        <li>
          take a photo of the other driver's license;
        </li>
        <li>
          take the registration numbers of all vehicles involved;
        </li>
        <li>
          take as many photos as is reasonable showing:
          <ol className={css.romanListing}>
            <li>
              the position of the Vehicles before they are moved for towing or salvage;
            </li>
            <li>
              the Damage to the Vehicle;
            </li>
            <li>
              the damage to any third party vehicle or property; and
            </li>
            <li>
              the general area where the Accident occurred, including any road or traffic signs;
            </li>
          </ol>
        </li>
        <li>
          obtain the names, addresses, phone numbers and email addresses of all witnesses;
        </li>
        <li>
          forward all third party correspondence or court documents to the Host within 7 days of receipt; and
        </li>
        <li>
          co-operate with the Host and Drive mate in the prosecution of any legal proceedings that may be instituted or the defence of any legal proceedings which may be instituted against the Guest as a result of an Accident, including attending:
          <ol className={css.romanListing}>
            <li>
              the office of the lawyer for the Host or Drive mate; or
            </li>
            <li>
              any Court hearing.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>8.4</span>The Guest must not:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          make any admission of fault;
        </li>
        <li>
          promise to pay any claim for Third Party Loss; or
        </li>
        <li>
          release the other party from any liability to pay for Damage as a result of an Accident, theft of attempted theft.
        </li>
      </ol>
    </div>
      <br />
    <div>
        <h2>9. REPRESENTATIONS AND WARRANTIES</h2>
    </div>
    <div className={css.padLeft}>
      <p>
      The Host hereby irrevocably and unconditionally represents and warrants:
      </p>
      <ol className={css.alphabetListing}>
        <li>
          they are the registered owner of the Vehicle and or have been duly authorised by the registered owner of the Vehicle to enter into this agreement;
        </li>
        <li>
          the registered owner of the Vehicle has the right to let the Vehicle for hire in accordance with this agreement;
        </li>
        <li>
          the Vehicle complies with the applicable Terms and Policies;
        </li>
        <li>
        the Vehicle is in safe and roadworthy condition, in good mechanical condition, and in full compliance with all applicable inspection and registration requirements; and
        </li>
        <li>
        any information or specifications set out on the Platform or provided by the Host to the Guest with respect to the Vehicle is complete, accurate and not misleading in any way.
        </li>
      </ol>
    </div>
    <p>
      <br/>
    </p>
    <div>
      <h2>10. LIMITATION OF LIABILITY</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>10.1</span>The Guest has consumer rights conferred by the Australian Consumer Law and neither this clause nor any other provision of the Terms or the Policies excludes, restricts or modifies any implied terms, guarantees or rights the Guest may have under those laws or any other Federal, State or Territory legislation.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>10.2</span>Subject to clauses 10.1 and 10.3, neither party shall be liable, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with this agreement for:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          loss of profits, sales, business, or revenue;
        </li>
        <li>
          business interruption;
        </li>
        <li>
          loss of anticipated savings;
        </li>
        <li>
          loss or corruption of data or information;
        </li>
        <li>
          cost of substitute products or services;
        </li>
        <li>
          loss of business opportunity, goodwill or reputation; or
        </li>
        <li>
          any incidental, special, indirect or consequential loss or damage
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>10.3</span>Nothing in this agreement shall limit or exclude any party’s liability for:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
          death or personal injury resulting from negligence;
        </li>
        <li>
          fraud; and/or
        </li>
        <li>
          any other matter in respect of which any party is prohibited under applicable law from limiting or excluding liability.
        </li>
      </ol>
    </div>
    <p>
      <br/>
    </p>
    <div>
      <h2>11. TERMINATION</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.1</span>This agreement shall commence upon the confirmation of the Guest’s booking of the Vehicle by the Host via the Platform, and shall continue until the Drop-Off Time, unless terminated earlier in accordance with this clause 11.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.2</span>A party may terminate this agreement by cancelling the trip via the Platform in accordance with the applicable Policies. This agreement shall terminate with immediate effect upon such cancellation. A trip cannot be cancelled during the Rental Period.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.3</span>This agreement shall be automatically terminated in the event that the trip for which the Guest has agreed to rent the Vehicle is cancelled by Drive mate.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.4</span>Termination of this agreement shall be without prejudice to the rights and obligations of the parties accrued up to the date of termination.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.5</span>Termination of this agreement shall not affect the continuation in force of clause 11 and any other clause of this agreement which is expressed or by its nature intended to continue in force notwithstanding termination of this agreement.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>11.6</span>Upon termination of this agreement:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
        to the maximum extent permitted by the Australian Consumer Law, the Guest shall not be entitled to any refund for the Trip Price (in whole or in part) unless provided for in the applicable Policies; and
        </li>
        <li>
        where the Guest has collected the Vehicle from the Host, the Guest shall immediately return the Vehicle to the Host at the Designated Location in accordance with the Policies.
        </li>
      </ol>
    </div>
    <p>
      <br/>
    </p>
    <div>
      <h2>12. SERIOUS BREACH</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>12.1</span>If the Guest:</p>
    <div className={css.padLeft}>
      <ol className={css.alphabetListing}>
        <li>
        commits a Serious Breach of these Terms and Conditions in a way that causes Damage, theft of the Vehicle or Third Party Loss; or
        </li>
        <li>
        drives the Vehicle in a reckless manner so that a substantial breach of road safety legislation has occurred,
          <p>the Guest:</p>
          <ol className={css.romanListing}>
            <li>
              has no Damage Cover;
            </li>
            <li>
              is liable for all Damage, theft of the Vehicle and Third Party Loss; and
            </li>
            <li>
              is liable for and <strong>must</strong> pay any additional costs or expenses incurred by the Host in recovering the Vehicle.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>12.2</span>Acting reasonably, the Host may terminate the agreement and take immediate possession of the Vehicle if a breach of any part of sub-clause 12.1 has occurred.</p>
    <p>
      <br/>
    </p>
    <div>
      <h2>13. PERSONAL INFORMATION</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>13.1</span>The Privacy Policy forms part of the rental agreement.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>13.2</span>By entering into the rental agreement the Guest represents to Drive mate that they have read and understood the Privacy Policy.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>13.3</span> Each party acknowledges that as a result of this agreement, they may obtain Personal Information of the other party. Neither party shall, during and after termination of this agreement, without the prior written consent of the other party, use, disclose or retain such Personal Information beyond the purposes contemplated by this agreement or legal purposes.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>13.4</span> Each party shall undertake reasonable security arrangements to prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks with respect to the other party’s Personal Information.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>13.5</span>Each party shall at all times, comply with the provisions of the Privacy Act 1988 (Cth) in respect of the other party’s Personal Information.</p>
    <p>
      <br/>
    </p>
    <div>
      <h2>14. GENERAL</h2>
    </div>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.1</span> Neither party may assign, novate or sub-contract any of its rights or obligations under this agreement.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.2</span>No variation of this agreement shall be effective unless it is agreed between the parties in writing.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.3</span>A waiver of any right under this agreement shall be in writing. Such waiver shall apply only to the person to whom the waiver is addressed and the circumstances for which it is given. Any failure to enforce any provision of this agreement shall not constitute a waiver of such or any other provision. Unless specifically provided otherwise, rights and remedies arising under this agreement are cumulative and do not exclude rights and remedies provided by law.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.4</span>If any provision of this agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision shall be deemed deleted. Any modification to or deletion of a provision under this clause shall not affect the validity and enforceability of the rest of this agreement.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.5</span>This agreement and any documents referred to in it constitutes the whole agreement between the parties and supersedes all previous arrangements, understandings and agreements between the parties relating to its subject matter. This clause is not intended to limit or otherwise affect the enforceability of the Terms of Use.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.6</span> The parties agree that in the event any dispute arises out of or in connection with this agreement, they shall first submit the dispute to Drive mate for resolution, and shall co-operate, in good faith, with Drive mate to resolve the dispute. No party shall commence any proceedings before complying with this clause, other than where a party seeks urgent interlocutory relief.</p>
    <p style={{display: "flex"}}><span style={{flex: "0 0 40px", maxWidth: 40}}>14.7</span>This agreement is governed by and will be interpreted in accordance with the laws of Victoria, Australia. Subject to clause 14.6, each of the parties submits to the non-exclusive jurisdiction of the Victorian Courts as regards any claim or matter arising under this agreement.</p>
      </div>
    </div>
  );
};

RentalAgreement.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

RentalAgreement.propTypes = {
  rootClassName: string,
  className: string,
};

export default memo(RentalAgreement);
