/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  IconArrowDown,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  TabNav,
} from '../../components'; // Import the Dropdown component
import css from './LayoutWrapperSideNav.css';
import IconCheck from '../IconCheck/IconCheck';

const NavMenuItem = props => {
  const { className, id, disabled, text, selected, linkProps, linkClass, selectedClass, customLinkClass, customSelectedLinkClass, isCompleted, setMenuTitle } = props;
  const linkClasses = classNames('', linkClass, customLinkClass, {
    [css.selectedLink]: selected,
    [selectedClass]: selected,
    [customSelectedLinkClass]: selected,
    [css.disabled]: disabled,
  });

  useEffect(() => {
    if (selected && text && setMenuTitle && typeof setMenuTitle === 'function') {
      setMenuTitle(text);
    }
  }, [selected]);

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
      {isCompleted ? <IconCheck /> : null}
    </div>
  );
};

const LayoutWrapperSideNav = props => {
  const [title, setMenuTitle] = useState('Menu');
  const { className, rootClassName, children, tabs, user } = props;
  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    // Ensure that we're in a browser environment before using window
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      // Update windowWidth when the component mounts
      setWindowWidth(window.innerWidth);

      // Update windowWidth when the window is resized
      window.addEventListener('resize', handleResize);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []); // Empty dependency array ensures this effect runs only once

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );


  return (
    <>
      <div>
        {/* Render the tabs as a dropdown in mobile view */}
        {tabs && windowWidth <= 1024 ? (
          <div className={css.menuContainer}>
            <Menu>
              <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
                <div className={css.menuLabelTitleContainer}>
                  <div className={css.menuLabelTitle}>{title}</div>
                  <div className={css.menuLabelIcon}>
                    <IconArrowDown className={css.arrowDown} color='#4A4A4A' />
                  </div>
                </div>
              </MenuLabel>
              <MenuContent className={css.menuContent} contentClassName={css.tabs}>
                {tabs.map((tab, i) => {
                  return (<MenuItem
                    key={`${i}__key`}
                    className={css.tab}
                    name={tab.text}
                  >
                    <NavMenuItem id={`${i}_keys_id`} className={''} linkClass={''} selectedClass={css.selectedClass} {...tab} customLinkClass={''} setMenuTitle={setMenuTitle} />
                  </MenuItem>);
                })}
              </MenuContent>
            </Menu>
            {/* {children} */}
          </div>
        ) : (
          <aside className={classes}>
            {/* Render the tabs as a dropdown in mobile view */}
            {tabs && (
              <>
                <TabNav
                  selectedClass={css.selectedClass}
                  linkClass={css.customLink}
                  rootClassName={css.tabs}
                  tabRootClassName={css.tab}
                  tabs={tabs}
                />
                {user && user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="LayoutWrapperSideNav.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </>
            )}
            {children}
          </aside>
        )}
      </div>
    </>
  );
};

LayoutWrapperSideNav.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  tabs: null,
};

const { node, string, array } = PropTypes;

LayoutWrapperSideNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabs: array,
};

export default LayoutWrapperSideNav;