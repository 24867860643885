import React, { memo } from 'react';
import classNames from 'classnames';
import config from '../../config';
import { CheckoutBreakdownLongTerm } from '../../components';

import css from './TransactionPanel.css';
import { txIsCanceledByHost } from '../../util/transaction';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

// Functional component as a helper to build BookingBreakdown
const BreakdownLongTermMaybe = memo(
  props => {
    const {
      className,
      rootClassName,
      breakdownClassName,
      transaction,
      transactionRole,
      currentUser,
      timeZone,
    } = props;
    const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
    const classes = classNames(rootClassName || css.breakdownMaybe, className);
    const breakdownClasses = classNames(breakdownClassName || css.breakdown);

    return loaded ? (
      <div className={classes}>
        {txIsCanceledByHost(transaction) && (
          <h3 className={css.canceledByHostText}>Trip cancelled by host</h3>
        )}
        {txIsCanceledByHost(transaction) && transactionRole === 'provider' ? null : (
          <div>
            <h3 className={css.bookingBreakdownLongTermTitle}>
              <FormattedMessage id="TransactionPanel.bookingBreakdownTitle" />
            </h3>
            <CheckoutBreakdownLongTerm
              transactionMode={true}
              className={breakdownClasses}
              userRole={transactionRole}
              unitType={config.bookingUnitType}
              transaction={transaction}
              booking={transaction.booking}
              currentUser={currentUser}
              timeZone={timeZone}
              hideTobePaid
              hideCurrentPayment
            />
          </div>
        )}
        {transactionRole === 'provider' && txIsCanceledByHost(transaction) && (
          <h3 className={css.canceledByHostText}>
            Frequent cancellations may lead to removal of your listing from the platform
          </h3>
        )}
        {transactionRole === 'customer' && txIsCanceledByHost(transaction) && (
          <h3 className={css.canceledByHostText}>
            We are really sorry for the inconvenience to you. Full trip amount has been processed
            for refund to you which should reflect in your credit card (time taken will depend upon
            the credit card)
          </h3>
        )}
      </div>
    ) : null;
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['transaction', 'transactionRole', 'currentUser']),
      pick(next, ['transaction', 'transactionRole', 'currentUser'])
    )
);

export default BreakdownLongTermMaybe;
