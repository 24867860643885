import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userDisplayNameAsString } from '../../util/data';
import { txIsDeclined, txIsExpired, txIsRequested } from '../../util/transaction';
import {
  txIsDeclinedLTF,
  txIsExpiredLTF,
  txIsRequestedLTF,
} from '../../util/transactionLongTermFirst';
import Avatar from '../Avatar/Avatar';
import css from './TransactionPanel.css';

const getCustomerPhoneNumber = transaction => {
  return (
    get(transaction, 'attributes.protectedData.guestPhoneNumber', null) ||
    get(transaction, 'attributes.protectedData.customerPhoneNumber', null)
  );
};

const getHostPhoneNumber = transaction => {
  if(transaction && transaction.customer &&
    transaction.customer.attributes  &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.publicData &&
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus === "confirmed" ) {
      return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
    }
    return null;
};

const PartnerInfo = ({ otherUser, currentUserRole, transaction }) => {
  const isShowPartnerInfo =
    !txIsRequested(transaction) &&
    !txIsDeclined(transaction) &&
    !txIsExpired(transaction) &&
    !txIsRequestedLTF(transaction) &&
    !txIsDeclinedLTF(transaction) &&
    !txIsExpiredLTF(transaction);

  const otherPhoneNumber =
    currentUserRole === 'customer'
      ? getHostPhoneNumber(transaction)
      : getCustomerPhoneNumber(transaction);
  const listingRegistrationNumber = get(
    transaction,
    'listing.attributes.publicData.license_plate_number',
    null
  );
  return (
    <div className={css.smallPartnerInfo}>
      <div className={css.avatarinfoContainer}>
        <div className={css.avatarWrapperProviderDesktop}>
          <Avatar renderSizes={'48px'} user={otherUser} className={css.smallAvatarDesktop} />
        </div>
        <div className={css.partnetTextContainer}>
          <p className={css.partnerText}>
            {currentUserRole === 'customer' ? (
              <FormattedMessage id="TransactionPanel.yourHost" />
            ) : (
              <FormattedMessage id="TransactionPanel.whoIsDriving" />
            )}
          </p>
          <div className={css.otherText}>{userDisplayNameAsString(otherUser)}</div>
        </div>
      </div>
      <div className={css.contactContainer}>{otherPhoneNumber && <a href={`tel:${otherPhoneNumber}`}>{otherPhoneNumber}</a>}</div>
      {/*{isShowPartnerInfo && listingRegistrationNumber && (*/}
      {/*  <div className={css.partnerName}>*/}
      {/*    <h3>*/}
      {/*      <FormattedMessage id="TransactionPanel.registrationNumber" />*/}
      {/*    </h3>*/}
      {/*    <div>{listingRegistrationNumber}</div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default PartnerInfo;
