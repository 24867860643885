import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import { FieldBirthdayInput, Form, PrimaryButton } from '../../components';

import css from './SyncCalenderForm.css';

class SyncCalenderFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  render() {
    const { currentUser } = this.props;
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            formId,
            intl,
            inProgress,
            invalid,
            values,
            initialValues,
            saveAddressDetailsError,
            ready,
            shouldHideSubmitButton,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

        const classes = classNames(rootClassName || css.root, className);

        const { dateOfBirth: dob} = initialValues || {};

          const { dateOfBirth: birthDate } = values;
          // const blockNoChanged = initialBlockNo !== blockNo;
          // const streetNameChanged =
          //   initialStreetName && initialStreetName.search !== streetName.search;
          // const buildingChanged = initialBuilding !== building;
          // const floorUnitChanged = initialFloorUnit !== floorUnit;
          const dobChanged = dob !== birthDate;
          // const countryChanged = initialCountry !== country;

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristineSinceLastSubmit ||
            inProgress ||
            !(
              dobChanged
            );

          const submitReady = ready;
          const submitInProgress = inProgress;

          const genericFailure = saveAddressDetailsError ? (
            <span className={css.error}>
              <FormattedMessage id="AddressDetailsForm.genericFailure" />
            </span>
          ) : null;


          const required = validators.required(intl.formatMessage({ id: 'SignupForm.dobRequired' }));
          const minAge = 22;
          const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });
          const minAgeRequired = validators.ageAtLeast(minAgeMessage, minAge);



          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <FieldBirthdayInput
                      className={css.birthDate}
                      initialBirthDate={dob && dob.day && dob.month && dob.year ? dob: null }
                      id={`dateOfBirth`}
                      name="dateOfBirth"
                      disabled={dob && dob.day && dob.month && dob.year ? true: false}
                      label="Date of Birth"
                      format={v => v}
                      externalCursor={dob && dob.day && dob.month && dob.year ? 'not-allowed': null}
                      valueFromForm={values.dateOfBirth}
                      validate={validators.composeValidators(required, minAgeRequired)}
                    />
              {/* </div> */}

              {!shouldHideSubmitButton && !(dob && dob.day && dob.month && dob.year)? (
                <div className={css.bottomWrapper}>
                  {genericFailure}
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    ready={submitReady}
                    disabled={submitDisabled}
                  >
                    <FormattedMessage id="PasswordChangeForm.saveChanges" />
                  </PrimaryButton>
                </div>
              ) : null}
            </Form>
          );
        }}
      />
    );
  }
}

SyncCalenderFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

SyncCalenderFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const SyncCalenderForm = compose(injectIntl)(SyncCalenderFormComponent);

SyncCalenderForm.displayName = 'SyncCalenderForm';

export default SyncCalenderForm;
