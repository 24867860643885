export const format = value => {
  if (!value) {
    return '';
  }
  return value.replace(/[^\d]/g, '');

};

export const floorUnitFormat = value => {
  if (!value) {
    return '';
  }
  let tempVal = '';
  for (let i=0; i < value.length; i++) {
    if(i !== 2 && value[i] === '-') {
      continue;
    }
    tempVal = tempVal + value[i]
  }
  return tempVal.replace(/[^\d|-]/g, '');

};

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => (value ? value.replace(/\s/g, '') : '');
