export function distanceCountHelper(mk1, mk2) {
  const prevLatInRad = (mk1.lat * Math.PI) / 180;
  const prevLongInRad = (mk1.lng * Math.PI) / 180;
  const latInRad = (mk2.lat * Math.PI) / 180;
  const longInRad = (mk2.lng * Math.PI) / 180;

  const d = // Radius of the Earth In kilometers
    6377.830272 *
    Math.acos(
      Math.sin(prevLatInRad) * Math.sin(latInRad) +
        Math.cos(prevLatInRad) * Math.cos(latInRad) * Math.cos(longInRad - prevLongInRad)
    );

  return d.toFixed(2);
}
