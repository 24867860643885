import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.css';

const SIZE_SMALL = 'small';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg id="close-solid" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
           viewBox="0 0 14 14">
        <path id="close-solid-2" data-name="close-solid"
              d="M9.654,87l3.98-3.98a1.251,1.251,0,0,0,0-1.769l-.885-.885a1.251,1.251,0,0,0-1.769,0L7,84.346l-3.98-3.98a1.251,1.251,0,0,0-1.769,0l-.885.885a1.251,1.251,0,0,0,0,1.769L4.346,87,.366,90.98a1.251,1.251,0,0,0,0,1.769l.885.885a1.251,1.251,0,0,0,1.769,0L7,89.654l3.98,3.98a1.251,1.251,0,0,0,1.769,0l.885-.885a1.251,1.251,0,0,0,0-1.769Z"
              transform="translate(0 -80)" fill="#fff" />
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path id="close-solid"
              d="M9.654,87l3.98-3.98a1.251,1.251,0,0,0,0-1.769l-.885-.885a1.251,1.251,0,0,0-1.769,0L7,84.346l-3.98-3.98a1.251,1.251,0,0,0-1.769,0l-.885.885a1.251,1.251,0,0,0,0,1.769L4.346,87,.366,90.98a1.251,1.251,0,0,0,0,1.769l.885.885a1.251,1.251,0,0,0,1.769,0L7,89.654l3.98,3.98a1.251,1.251,0,0,0,1.769,0l.885-.885a1.251,1.251,0,0,0,0-1.769Z"
              transform="translate(0 -80)" />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1 -1)" fillRule="evenodd">
        <rect transform="rotate(45 7 7)" x="-1" y="6" width="16" height="2" rx="1" />
        <rect transform="rotate(-45 7 7)" x="-1" y="6" width="16" height="2" rx="1" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
