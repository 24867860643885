import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';
import insuranceLogo from '../../assets/landingpage/insurance.png';
import ntucLogo from '../../assets/ntuc-logo-nobg.png';
import css from './InsuranceLandingModal.css';

export default function InsuranceLandingModal(props) {
  const { onManageDisableScrolling, isModalOpen, setModalVisibility } = props;
  return (
    <Modal
      id="insuranceLandingModal"
      isOpen={isModalOpen}
      onClose={() => {
        setModalVisibility && setModalVisibility(false);
      }}
      containerClassName={css.modalContainer}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
    >
      <div className={css.insuranceListingWrapper}>
        <h3>Our partners</h3>
        <div className={css.insuranceLogoWrapper}>
          <img src={insuranceLogo} className={css.insuranceLogo} alt="tokio-marine-logo" />
          <img src={ntucLogo} className={css.insuranceLogo} alt="ntuc-income-logo" />
        </div>

        <h3>Comprehensive insurance cover in SG &amp; MY</h3>
        <ul className={css.insurancePointsList}>
          <li>Unlimited cover for 3rd party liability (bodily injury)</li>
          <li>Up to $5 mn cover for 3rd party liability (property damage)</li>
          <li>Personal accident benefits up to $50,000</li>
          <li>Excess $3000 (for Section I &amp; II each)</li>
          <li>Policy applicable only for the approved driver during the period of rental</li>
        </ul>
        {/* {showInsurance && insuranceType === INSURANCE_PRIVATE && (
            <div className={css.insuranceBottom}>
              <ExternalLink
                onClick={onReadInsurance}
                className={css.insuranceBottomImgText}
                href={insurancePdf}
              >
                <FormattedMessage id="InsurancePanel.privateInsuranceButton" />
              </ExternalLink>
            </div>
          )} */}
      </div>
    </Modal>
  );
}
