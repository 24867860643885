import classNames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { object, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { updateIdentity } from '../../ducks/user.duck';
import { TransactionVerificationForm } from '../../forms';
import { ensureCurrentUser, userDisplayNameAsString } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './TransactionIdentityVerification.css';

const { LatLng } = sdkTypes;

class TransactionIdentityVerificationComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
    };
    const { currentUser } = props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const { profile = {} } = ensureCurrentUser.attributes || {};
    const { protectedData = {} } = profile || {};
    const {
      passportFirstPage = null,
      passportLastPage = null,
      frontalPhoto = null,
      hostHoldingDrivingLicensePhoto = null,
      drivingLicenseFront = null,
      drivingLicenseBack = null,
    } = protectedData;
    this.currentInitialValues = {
      photo: frontalPhoto,
      passportFirstPage,
      passportLastPage,
      hostHoldingDrivingLicensePhoto,
      drivingLicenseFront,
      drivingLicenseBack,
    };
  }

  setData = (property, data) => {
    this.currentInitialValues[property] = data;
  };

  render() {
    const { className, rootClassName, currentUser, updateIdentityError } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);

    const isVerified = get(currentUser, 'identityStatus.guestVerified', false);
    const underVerified = get(currentUser, 'identityStatus.guestUnderVerify', false);

    const panelTitle =
      isVerified || underVerified ? (
        <FormattedMessage
          id="TransactionIdentityVerification.createListingTitlePendingVerify"
          values={{ username: currentUser.attributes.profile.displayName }}
        />
      ) : (
        <FormattedMessage
          id="TransactionIdentityVerification.createListingTitleFirstTime"
          values={{ newline: <br /> }}
        />
      );

    const userName = userDisplayNameAsString(
      currentUser.attributes.profile.displayName,
      this.state.title || ''
    );
    const title = this.state.title ? userName : panelTitle;

    const userLocation = !this.props.currentUser
      ? null
      : this.props.currentUser.attributes.profile.protectedData.location;
    const location = userLocation
      ? {
          ...userLocation,
          selectedPlace: {
            ...userLocation.selectedPlace,
            origin: new LatLng(
              userLocation.selectedPlace.origin.lat,
              userLocation.selectedPlace.origin.lng
            ),
          },
        }
      : null;

    return (
      <div className={classes}>
        {/* <h1 className={css.title}>{title}</h1> */}
        <TransactionVerificationForm
          className={css.form}
          initialValues={{
            postalCode: get(currentUser, 'attributes.profile.protectedData.postalCode', ''),
            location: location
              ? {
                  search: location.search,
                  selectedPlace: {
                    address: location.selectedPlace.address,
                    origin: location.selectedPlace.origin,
                  },
                }
              : null,
            unitNumber: get(currentUser, 'attributes.profile.protectedData.unitNumber', ''),
            streetNumber: get(currentUser, 'attributes.profile.protectedData.streetNumber', ''),
            suburb: get(currentUser, 'attributes.profile.protectedData.suburb', ''),
            city: get(currentUser, 'attributes.profile.protectedData.city', ''),
            state: get(currentUser, 'attributes.profile.protectedData.state', ''),
            drivingLicenseFront: get(
              currentUser,
              'attributes.profile.protectedData.drivingLicenseFront'
            ),
            drivingLicenseBack: get(
              currentUser,
              'attributes.profile.protectedData.drivingLicenseBack'
            ),
            hostHoldingDrivingLicensePhoto: get(
              currentUser,
              'attributes.profile.protectedData.hostHoldingDrivingLicensePhoto'
            ),
            passportFirstPage: get(
              currentUser,
              'attributes.profile.protectedData.passportFirstPage'
            ),
            passportLastPage: get(currentUser, 'attributes.profile.protectedData.passportLastPage'),
          }}
          onSubmit={values => {
            const hasProfilePhoto = this.props.currentUser && this.props.currentUser.profileImage;
            const initialPhoto = this.currentInitialValues.photo
              ? this.currentInitialValues.photo
              : hasProfilePhoto
              ? [this.props.currentUser.profileImage.attributes.variants['square-small2x'].url]
              : null;
            this.props
              .updateIdentity(
                {
                  ...pickBy(values, identity),
                  ...pickBy(this.currentInitialValues, identity),
                  location: values.location
                    ? {
                        search: values.location.search,
                        selectedPlace: {
                          address: values.location.selectedPlace.address,
                          origin: values.location.selectedPlace.origin,
                        },
                      }
                    : null,
                  email: this.props.currentUser.attributes.email,
                  photo: initialPhoto,
                },
                'GUEST'
              )
              .then(() => {
                if (this.props.imageUpload) {
                  this.props.onUpdateProfile({ profileImageId: this.props.imageUpload.imageId });
                }
              });
            this.setState({
              title: userName + ',',
            });
          }}
          saveActionMsg={<FormattedMessage id="TransactionIdentityVerification.submitVerify" />}
          currentUser={this.props.currentUser}
          drivingLicenseFrontUrl={
            this.currentInitialValues.drivingLicenseFront ||
            get(currentUser, 'attributes.profile.protectedData.drivingLicenseFront', '')
          }
          drivingLicenseBackUrl={
            this.currentInitialValues.drivingLicenseBack ||
            get(currentUser, 'attributes.profile.protectedData.drivingLicenseBack', '')
          }
          hostHoldingDrivingLicenseUrl={
            this.currentInitialValues.hostHoldingDrivingLicensePhoto ||
            get(currentUser, 'attributes.profile.protectedData.hostHoldingDrivingLicensePhoto', '')
          }
          passportFirstPageUrl={
            this.currentInitialValues.passportFirstPage ||
            get(currentUser, 'attributes.profile.protectedData.passportFirstPage', null)
          }
          passportLastPageUrl={
            this.currentInitialValues.passportLastPage ||
            get(currentUser, 'attributes.profile.protectedData.passportLastPage', null)
          }
          photo={
            this.currentInitialValues.photo ||
            get(currentUser, 'attributes.profile.protectedData.frontalPhoto', '')
          }
          setData={this.setData}
          location={this.props.location}
          updateIdentityError={updateIdentityError}
        />
      </div>
    );
  }
}

TransactionIdentityVerificationComp.defaultProps = {
  className: null,
  rootClassName: null,
};

TransactionIdentityVerificationComp.propTypes = {
  className: string,
  rootClassName: string,
  location: object,
};

const mapStateToProps = state => {
  const { image } = state.ProfileSettingsPage;
  const { updateIdentityError } = state.user;
  return {
    imageUpload: image,
    updateIdentityError,
  };
};

const mapDispatchToProps = dispatch => ({
  updateIdentity: (userData, type) => dispatch(updateIdentity(userData, type)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const TransactionIdentityVerification = compose(connect(mapStateToProps, mapDispatchToProps))(
  TransactionIdentityVerificationComp
);

export default TransactionIdentityVerification;
