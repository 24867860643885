import classNames from 'classnames';
import React, { useState } from 'react';
import videoThumb from '../../assets/b-landing/video-thumb.png';
import flexThumb from '../../assets/b-landing/flexDesk.jpg';
import point1 from '../../assets/b-landing/point-1.svg';
import point2 from '../../assets/b-landing/point-2.svg';
import point3 from '../../assets/b-landing/point-3.svg';
import rentersImage from '../../assets/b-landing/renters-img.jpg';
import flexColor from '../../assets/b-landing/flex-color.svg';
import appStoreBadge from '../../assets/b-landing/appstore-badge.svg';
import googlePlayBadge from '../../assets/b-landing/google-play-badge.png';
import BLandingCss from './BLandingPage.css';
import { FlexLogo, IconLogo, Modal, NamedLink } from '../../components';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import { sendGAEvent } from '../../util/googleAnalytics';

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};
const SectionGrids = ({ onManageDisableScrolling, onPushGTMSeeVideoEvent, onVideoModalOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
    onVideoModalOpen(true);
  };
  return (
    <div className={BLandingCss.sectionGrids}>
      <div className={BLandingCss.landingContainer}>
        <div className={BLandingCss.landingRow}>
          <div className={classNames(BLandingCss.column6, BLandingCss.gridBox)}>
            <div className={BLandingCss.gridBoxInner}>
              <div
                className={BLandingCss.gridThumb}
                onClick={handleOpenVideo}
                style={{ cursor: 'pointer' }}
              >
                <img src={videoThumb} alt="" />
              </div>
              <div className={BLandingCss.gridContent}>
                <span className={BLandingCss.title}>Why rent from Drive mate?</span>
                <p>Renting a car from real people comes with real benefits.</p>
                <ul>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    Always nearby
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    More choices, less hassle
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    Affordable, 30-40% cheaper
                  </li>
                </ul>
                <div className={BLandingCss.btnWrapper}>
                  <NamedLink name="BecomeAGuestPage" className={BLandingCss.btnMore}>
                    Learn more
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(BLandingCss.column6, BLandingCss.gridBox)}>
            <div className={BLandingCss.gridBoxInner}>
              <div className={BLandingCss.gridThumb}>
                <img src={flexThumb} alt="" />
                <div className={BLandingCss.thumbContent}>
                  <FlexLogo />
                </div>
              </div>
              <div className={BLandingCss.gridContent}>
                <span className={BLandingCss.title}>Next level of car ownership</span>
                <p>
                  Flexible monthly car rentals with ZERO financial commitment with{' '}
                  <span>
                    <img src={flexColor} alt="" />
                  </span>
                </p>
                <ul>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    No commitments. Cancel anytime
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    Most Affordable
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <CheckMark />
                    No Deposit
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              BLandingCss.column12,
              BLandingCss.gridBox,
              BLandingCss.gridHorizontal
            )}
          >
            <div className={BLandingCss.gridBoxInner}>
              <div className={BLandingCss.gridThumb}>
                <img src={rentersImage} alt="" />
              </div>
              <div className={BLandingCss.gridContent}>
                <span className={BLandingCss.title}>Make your vehicle work for you!</span>
                <p>
                  Make the most out of your car. Share your car when you don’t need it and earn
                  easily. Fully insured+. No impact on your NCD.
                </p>
                <ul className={BLandingCss.horizontalList}>
                  <li className={BLandingCss.heroContentText}>
                    <div className={BLandingCss.iconThumb}>
                      <img src={point1} alt="" />
                    </div>
                    <div className={BLandingCss.iconText}>Additional income</div>
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <div className={BLandingCss.iconThumb}>
                      <img src={point2} alt="" />
                    </div>
                    <div className={BLandingCss.iconText}>Fully verified guests</div>
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <div className={BLandingCss.iconThumb}>
                      <img src={point3} alt="" />
                    </div>
                    <div className={BLandingCss.iconText}>Comprehensively insured</div>
                  </li>
                </ul>
                <div className={BLandingCss.btnWrapper}>
                  <NamedLink name="BecomeAHostPage" className={BLandingCss.btnMore}>
                    Learn how much you can earn
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              BLandingCss.column12,
              BLandingCss.gridBox,
              BLandingCss.gridHorizontal,
              BLandingCss.gridImageRight
            )}
          >
            <div className={BLandingCss.gridBoxInner}>
              <div className={BLandingCss.gridThumb}>
                <div className={BLandingCss.thumbContent}>
                  <IconLogo />
                </div>
              </div>
              <div className={BLandingCss.gridContent}>
                <span className={BLandingCss.title}>Have you got our apps?</span>
                <p>Available on both iOS and Android, download on your phone now.</p>
                <ul className={BLandingCss.badgeListing}>
                  <li className={BLandingCss.heroContentText}>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/id1500770324"
                      className={BLandingCss.appButton}
                    >
                      <img src={appStoreBadge} alt="" />
                    </a>
                  </li>
                  <li className={BLandingCss.heroContentText}>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.drivelah.drivelah"
                      className={BLandingCss.appButton}
                    >
                      <img src={googlePlayBadge} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="videoLandingpage"
        containerClassName={BLandingCss.modalContainer}
        closeButtonClassName={BLandingCss.modalCloseButton}
        contentClassName={BLandingCss.contentModal}
        isOpen={isOpen}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
          onVideoModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalVideo isOpen={isOpen} />
      </Modal>
    </div>
  );
};

const ModalVideo = ({ isOpen }) => {
  return (
    <div className={BLandingCss.videoContentWrapper}>
      {isOpen && (
        <iframe
          id="videoIframe"
          className={BLandingCss.iframeVideo}
          allowFullScreen={true}
          src="https://www.youtube.com/embed/OaOzjZiVaws"
        ></iframe>
      )}
    </div>
  );
};

export default SectionGrids;
