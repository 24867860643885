// ================ Action types ================ //
const SAVE_VACCINE_CERTIFICATE_REQUEST = 'app/VaccineCertificate/SAVE_VACCINE_CERTIFICATE_REQUEST';
const SAVE_VACCINE_CERTIFICATE_SUCCESS = 'app/VaccineCertificate/SAVE_VACCINE_CERTIFICATE_SUCCESS';
const SAVE_VACCINE_CERTIFICATE_ERROR = 'app/VaccineCertificate/SAVE_VACCINE_CERTIFICATE_ERROR';

// ================ Reducer ================ //

export const initialState = {
  vaccineCertificateSuccess: null,
  vaccineCertificateError: null,
  vaccineCertificateInProgress: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_VACCINE_CERTIFICATE_REQUEST:
      return {
        ...state,
        vaccineCertificateSuccess: false,
        vaccineCertificateError: false,
        vaccineCertificateInProgress: true,
      };
    case SAVE_VACCINE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        vaccineCertificateSuccess: true,
        vaccineCertificateInProgress: false,
      };
    case SAVE_VACCINE_CERTIFICATE_ERROR:
      return {
        ...state,
        vaccineCertificateError: payload,
        vaccineCertificateInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestSaveVaccineCertificate = () => ({
  type: SAVE_VACCINE_CERTIFICATE_REQUEST,
});

const saveVaccineCertificateSuccess = () => ({
  type: SAVE_VACCINE_CERTIFICATE_SUCCESS,
});

const saveVaccineCertificateError = e => ({
  type: SAVE_VACCINE_CERTIFICATE_ERROR,
  payload: e,
});

export const saveVaccineCertificate = params => (dispatch, getState, sdk) => {
  dispatch(requestSaveVaccineCertificate());
  const { vaccineCertificateadded, vaccineCertificatelink, vaccineCertificatetimestamp } = params;
  if (!vaccineCertificateadded && !vaccineCertificatelink && !vaccineCertificatetimestamp) {
    return null;
  }
  const protectedData = {
    vaccineCertificatelink: vaccineCertificatelink,
  };
  const publicData = {
    vaccineCertificateadded: vaccineCertificateadded,
    vaccineCertificatetimestamp: vaccineCertificatetimestamp,
  };
  return sdk.currentUser
    .updateProfile({ protectedData, publicData })
    .then(response => {
      dispatch(saveVaccineCertificateSuccess(response));
    })
    .catch(error => {
      dispatch(saveVaccineCertificateError(error));
    });
};
