import React from 'react';
import css from './GrabRentACarLandingPage.css';
import Slider from 'react-slick';
import storyIcon from '../../assets/landingpage/storyIcon.png';
import userIcon from '../../assets/grabBanner/userIcon.svg';

const datas = [
  {
    content:
      'The best part about Drive mate is that the mileage is included in the rental cost. This makes it so much more affordable and easy to make profits.',
    name: 'Mavis',
    avatar: userIcon,
  },
  {
    content:
      'The customer service is really cooperative and patient. If I face an issue I just call them and they sort the problem immediately. Happy with the service.',
    name: 'Chew',
    avatar: userIcon,
  },
  {
    content:
      'I work on weekdays and drive for Grab on weekends. This option of renting a car from Drive mate whenever I have time helps me make money in my free time.',
    name: 'Shimei He',
    avatar: userIcon,
  },
  {
    content: 'Convenient way to Rent out your vehicle with insurance covered. Hassle free!',
    name: 'Jonathan Lai',
    avatar: userIcon,
  },
  {
    content:
      'Drive mate is a safe, easy and convenient platform for car owners to lease their unused car 24-7 for extra income to offset their car maintenance in Australia. Thanks Drive mate!',
    name: 'Ben Chan',
    avatar: userIcon,
  },
];

const Story = ({ content, name, avatar }) => {
  return (
    <div className={css.story}>
      <div className={css.storyContentWrapper}>
        <img src={storyIcon} height={100} />
        <div className={css.storyContent}>{content}</div>
      </div>
      <div className={css.avatarWrapper}>
        <img src={avatar} className={css.avatar} />
        <div className={css.storyName}>{name}</div>
      </div>
    </div>
  );
};

const SectionStories = () => {
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionStories}>
      <div className={css.sectionStoriesTitleWrapper}>
        <div className={css.sectionTitle}>Happy Customers</div>
        <div className={css.sectionStoriesDescription}>They love sharing cars on Drive mate!</div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider {...sliderConfig}>
          {datas.map(s => (
            <div className={css.storyWrapper}>
              <Story {...s} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionStories;
