import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';

import css from './ModalSharingListing.css';
import FacebookLogo from './facebook-letter-logo.svg';
import TwitterLogo from './twitter-logo-silhouette.svg';
import WhatsappLogo from './whatsapp.svg';
import MessengerLogo from './messenger.svg';
import CopyLogo from './link.svg';
import MailLogo from './close-envelope.svg';

const MOBILE_INPUT = 'MOBILE_INPUT';

class ModalSharingListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    }
  }


  handleChangeMobileNumber() {
    //NOTICE: You can use modalMissingInfomationProps here by
    //call this.prop.modalMissingInfomationProps
    this.setState({
      showMissingInformationReminder: MOBILE_INPUT
    })
  }



  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      shareableLink,
      onShareFacebookTimeline,
      onShareFacebookMessenger,
      showShareModal,
      onClose
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const whatsAppLink = `https://wa.me/?text=Check%20out%20this%20awesome%20car%20for%20sharing%20on%20Drive%20lah%20${shareableLink}`;
    const twitterLink = `https://twitter.com/intent/tweet?text=Check%20out%20this%20awesome%20car%20for%20sharing%20on%20Drive%20lah%20${shareableLink}`;
    const mailLink = `mailto:?subject=Check out this awesome car for sharing on Drive mate&body=I found a really great car to hire, check it out: ${shareableLink}`;
    const content = (
      <div className={css.container}>
        <h1 className={css.header}>Share</h1>
        <h2 className={css.message}>Check out this awesome car for sharing on Drive mate</h2>
        <div className={css.text} onClick={() => onShareFacebookTimeline("Check out this awesome car for sharing on Drive mate")}>
          <img className={css.logo} src={FacebookLogo} alt="facebook-logo"/>
          facebook
        </div>
        <div className={css.text} onClick={() => {
          if (window) {
            window.open(twitterLink, '_blank');
          }
        }}>
          <img className={css.logo} src={TwitterLogo} alt="twitter-logo"/>
          twitter
        </div>
        <div className={css.text} onClick={() => {
          if (window) {
            window.open(whatsAppLink, '_blank');
          }
        }}>
          <img className={css.logo} src={WhatsappLogo} alt="whatsapp-logo"/>
          whatsapp
        </div>
        <div className={css.text} onClick={() => {
          if (window) {
            window.location.href = mailLink;
          }
        }}>
          <img className={css.logo} src={MailLogo} alt="email-logo"/>
          email
        </div>
        <div className={css.text} onClick={() => onShareFacebookMessenger()}>
          <img className={css.logo} src={MessengerLogo} alt="messenger-logo"/>
          messenger
        </div>
        <div className={css.tooltip}
          onClick={() => {
            if (window) {
              if (!navigator.clipboard) {
                let tooltip = document.getElementById("copyTip");
                tooltip.innerHTML = `Can't copy, something went wrong`;
                return;
              }
              navigator.clipboard.writeText(shareableLink).then(function() {
                let tooltip = document.getElementById("copyTip");
                tooltip.innerHTML = `Copied: ${shareableLink}`;
              }, function(err) {
                let tooltip = document.getElementById("copyTip");
                tooltip.innerHTML = `Can't copy, something went wrong`;
              });

            }
          }}
          onMouseOut={() => {
            let tooltip = document.getElementById("copyTip");
            tooltip.innerHTML = "Copy link to clipboard";
          }}>
          <img className={css.logo} src={CopyLogo} alt="messenger-logo"/>
          <span className={css.tooltiptext} id="copyTip">Copy link to clipboard</span>
          copy link
        </div>
      </div>
    );


    const closeButtonMessage = <FormattedMessage id="ModalSharingListing.close" />;

    return (
      <Modal
        id="ModelSharingListing"
        containerClassName={containerClassName}
        isOpen={showShareModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {content}
      </Modal>
    );
  }
}

ModalSharingListing.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  shareLink: null
};

ModalSharingListing.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  shareableLink: string.isRequired,

  onManageDisableScrolling: func.isRequired,
  onShareFacebookTimeline: func.isRequired,
  onShareFacebookMessenger: func.isRequired,
  onClose: func.isRequired
};

ModalSharingListing.displayName = 'ModalSharingListing';

export default ModalSharingListing;
