import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';
import iconChecked from '../../assets/landingPageCovid/iconChecked.svg';
import viroQ from '../../assets/landingPageCovid/QuantumIon.png';
import css from './CovidPage.css';

const SectionHowToEnrolDesktop = () => {
  return (
    <div className={css.sectionHowToEnrolDesktop}>
      <div className={css.sectionHowToEnrol}>
        <div className={css.title}>
          <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.title' />
        </div>
        <div className={css.listFeature}>
          <img src={iconChecked} className={css.listIcon} />
          <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.stepViroQ1' />
        </div>
        <div className={css.listFeature}>
          <img src={iconChecked} className={css.listIcon} />
          <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.stepViroQ2' />
        </div>
        <div className={css.listFeature}>
          <img src={iconChecked} className={css.listIcon} />
          <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.stepViroQ3' />
        </div>
      </div>
      <div>
        <img src={viroQ} className={css.viroQImage} />
      </div>
      <div className={css.why60DaysWrapper}>
        <div className={css.why60Days}>
          <div className={css.why60DaysTitle}>
            <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.why60DaysTitle' />
          </div>
          <div className={css.why60DaysDesciption}>
            <FormattedHTMLMessage id='LandingPageCovid.SectionHowToEnrol.why60DaysDesciption'/>
          </div>
        </div>
        <NamedLink
          className={`${css.enrolButton} ${css.button}`}
          name="CovidCheckoutPage"
        >
          <div className={css.enrolAndBuy}>
            <FormattedMessage id="LandingPageCovid.SectionHero.enrolAndBuy" />
            <br />
          </div>
          <div className={css.price}>
            <FormattedMessage id="LandingPageCovid.SectionHero.price" />
          </div>
        </NamedLink>
        <div className={css.sectionHeroWhyBuy}>
          <FormattedHTMLMessage id='LandingPageCovid.SectionHero.whyBuyIt1' />
          <br />
          <FormattedMessage id='LandingPageCovid.SectionHero.whyBuyIt2' />
        </div>
        <div className={css.viewCertifications}>
          <a target="_blank" href="https://drivelah-public-files.s3-ap-southeast-1.amazonaws.com/Documents/Quantum-Ion-Certficates.pdf">
            <FormattedMessage id='LandingPageCovid.SectionHowToEnrol.viewCertifications' />
          </a>
        </div>
      </div>
    </div>
  )
};

export default SectionHowToEnrolDesktop;