import React from 'react';
import { flatten } from 'lodash';

import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';

import css from './TripPanel.css';

const PickUpSection = props => {
  const {
    shouldShowPickUpSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    photoSectionListCustomerDLGO,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    isDrivelahGo,
    isPickedUp,
    isDropOff,
    isPickupRequested,
    interiorPhotoDlGo,
    odometerPhotoDlGo,
    fuelGaugePhotoDlGo,
  } = props;

  // For Node.js version 11.0.0 and higer
  //
  const flatTripPhotos =  [
    ...allTripPhotos,
    ...interiorPhotoDlGo,
    ...odometerPhotoDlGo,
    ...fuelGaugePhotoDlGo,
  ];

  const filterTripPhotos = flatten(flatTripPhotos).filter(i => i.isPickUp);

  console.log('allTripPhotos')
  console.log(allTripPhotos)

    // const filterTripPhotos = [
    //   ...allTripPhotos,
    //   ...interiorPhotoDlGo,
    //   ...odometerPhotoDlGo,
    //   ...fuelGaugePhotoDlGo,
    // ]
    //   .reduce((acc, val) => {
    //     console.log('acc')
    //     console.log(acc)
    //     return acc.concat(val), []
    //   })
    //   .filter(i => i.isPickUp);
  const photoSectionOnlyViewCustomer = isDrivelahGo ? photoSectionListCustomerDLGO : photoSectionListCustomer;

  console.log('photoSectionList')
  console.log(photoSectionList)
  console.log('filterTripPhotos')
  console.log(filterTripPhotos)

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowPickUpSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isCustomer ? 'Send pickup request' : 'Confirm pickup request'}
        </h2>
      </div>
      {photoSectionList.map(i => {
        const isShowSection =
          (i.data === 'interiorPhotos' || i.data === 'odometerPhotos' || i.data === 'fuelGaugePhotos')
            ? isDrivelahGo
              ? !!isPickupRequested
              : true
            : true;
        return isShowSection ? (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={isPickUp}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            onNoteChange={onNoteChange}
          />
        ) : null;
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionOnlyViewCustomer.map(i => {
          const isShowSection =
            i.data === 'interiorPhotos' ||
            i.data === 'odometerPhotos' ||
            i.data === 'fuelGaugePhotos'
              ? isDrivelahGo
                ? (i.data === 'interiorPhotos' && interiorPhotoDlGo && interiorPhotoDlGo.length > 0) ||
                  (i.data === 'odometerPhotos' && odometerPhotoDlGo && odometerPhotoDlGo.length > 0) ||
                  (i.data === 'fuelGaugePhotos' && fuelGaugePhotoDlGo && fuelGaugePhotoDlGo.length > 0)
                : true
              : true;
          return isShowSection ? (
            <SectionCarPhotosOnlyView
              key={i.id}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={true}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          ) : null;
        })}
    </div>
  );
};

export default PickUpSection;
