import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CUSTOMER_PROMO, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import { ensureTransaction } from '../../util/data';

const LineItemCreditsMaybe = props => {
  const { transaction, isProvider, intl } = props;

  if (isProvider) return null;

  const creditsItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_PROMO
  );

  if (!creditsItem) return null;

  const { protectedData } = ensureTransaction(transaction).attributes;

  const { code } = protectedData || {};

  const label = !code ? (
    <FormattedMessage id="BookingBreakdown.credits" />
  ) : (
    <FormattedMessage id="BookingBreakdown.promos" />
  );
  const credits = creditsItem.lineTotal;
  const formattedPrice = formatMoney(intl, credits, 1);

  return (
    <div className={css.creditsLineItem}>
      <div className={css.totalLabel}>{label}</div>
      <div className={css.totalPrice}>{formattedPrice}</div>
    </div>
  );
};

LineItemCreditsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCreditsMaybe;
