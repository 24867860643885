import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './BAGReadyToRent.css';

const BAGReadyToRent = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;

  return (
    <section className={css.root}>
      <div className={css.readyToShareSection}>
        <div className={css.textBox}>
          <h5 className={css.title}>
            <FormattedMessage
              id="BecomeAGuestPage.readyToShareTitle"
              values={{ newlineDesktop: <span className={css.newlineDesktop}><br/></span>, newlineMobile: <span className={css.newlineMobile}><br /></span> }}
            />
          </h5>
          {isAuthenticated &&
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  'bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&showAlert=true',
              }}
              className={classNames(css.listYourCar)}
            >
              <FormattedMessage id="BecomeAGuestPage.searchForCars" />
            </NamedLink>
          }
          {!isAuthenticated &&
            <NamedLink
              name="SignupPage"
              className={classNames(css.listYourCar)}
              onClick={onLoginOrSignupClick}
            >
              <FormattedMessage id="BecomeAGuestPage.joinUsNowButton" />
            </NamedLink>
          }
        </div>
      </div>
    </section>
  );
};

export default BAGReadyToRent;
