export const EVENT_CONVERSION_HOST_CONFIRMED_DROPOFF = {
  eventName: 'Car Host - Confirmed Drop-Off',
  eventID: 'sKIZCLmwxoADENHNtPAC',
};
export const EVENT_CONVERSION_DRIVER_CONFIRMED_DROPOFF = {
  eventName: 'Car Driver - Drop-Off Confirmed',
  eventID: 'NaCkCK2gn4ADENHNtPAC',
};
export const EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS = {
  eventName: 'Create an account with driver credentials',
  eventID: 'mg39CLuGzIADENHNtPAC',
};
export const EVENT_CONVERSION_MADE_BOOKING_REQUEST = {
  eventName: 'Made a booking request',
  eventID: '6bjUCOONzIADENHNtPAC',
};
export const EVENT_CONVERSION_STARTED_LISTING = {
  eventName: 'Made a booking request',
  eventID: 'wRA9CLWyxoADENHNtPAC',
};
export const EVENT_CONVERSION_COMPLETED_TRIP = {
  eventName: 'Completed a trip',
  eventID: 'ZcKDCKe-n4ADENHNtPAC',
};
export const EVENT_CONVERSION_SUBMIT_EMAIL_ID = {
  eventName: 'Submit email id',
  eventID: 'Dw43CKOGzIADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_REQUEST_WITHDRAWN = {
  eventName: 'Booking request withdrawn',
  eventID: 'N7fHCNu3xoADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_DECLINED = {
  eventName: 'Booking request declined',
  eventID: 'SEuqCLixn4ADENHNtPAC',
};
export const EVENT_CONVERSION_PERFORMED_SEARCH = {
  eventName: 'Performed a search - New',
  eventID: '0Ij3CPq9n4ADENHNtPAC',
};
export const EVENT_CONVERSION_CREATE_VERIFIED_ACCOUNT = {
  eventName: 'Create a verified account',
  eventID: 'eS90CIiyxoADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED = {
  eventName: 'Booking request accepted',
  eventID: 'k7nNCL3DxoADENHNtPAC',
};
export const EVENT_CONVERSION_BOOKING_CANCELLED = {
  eventName: 'Booking cancelled',
  eventID: 'XggcCOaWzIADENHNtPAC',
};
export const EVENT_CONVERSION_PUBLISHED_LISTING = {
  eventName: 'Published a listing',
  eventID: 'rBV-CKWRzIADENHNtPAC',
};
