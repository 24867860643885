import React, { memo } from 'react';
import BLandingCss from './BLandingPage.css';
import vulcanPost from '../../assets/hostLanding/vulcan.png';
import theStraitsTime from '../../assets/hostLanding/straits.png';
import cna from '../../assets/hostLanding/cna.png';
import zaobao from '../../assets/hostLanding/zao.png';
import insurance from '../../assets/landingpage/insurance.png';
import landTransport from '../../assets/landingpage/landTransport.jpeg';

export const Channels = () => (
  <div className={BLandingCss.channelOuter}>
    <div className={BLandingCss.channelsGridRow}>
      <a
        href="https://www.straitstimes.com/singapore/transport/car-not-used-much-rent-it-out-for-cash"
        className={BLandingCss.channelItem}
        target="_blank"
      >
        <img
          className={BLandingCss.channelItemImage}
          src={theStraitsTime}
          alt="THE STRAITS TIMES"
        />
      </a>
      <a
        href="https://www.facebook.com/drivemate20/videos/drive-lah-featured-on-channel-news-asia/991990264536564/"
        target="_blank"
        className={BLandingCss.channelItem}
      >
        <img className={BLandingCss.channelItemImage} src={cna} alt="CNA" />
      </a>
      <a
        href="https://vulcanpost.com/679151/p2p-car-sharing-drive-lah-singapore/"
        target="_blank"
        className={BLandingCss.channelItem}
      >
        <img className={BLandingCss.channelItemImage} src={vulcanPost} alt="Vulcan Post" />
      </a>
      <a
        href="https://www.zaobao.com.sg/news/singapore/story20191116-1005643"
        className={BLandingCss.channelItem}
        target="_blank"
      >
        <img className={BLandingCss.channelItemImage} src={zaobao} alt="ZAOBAO" />
      </a>
    </div>
  </div>
);

const ChannelsListings = memo(({ isMobileLayout }) => {
  return (
    <>
      {isMobileLayout && (
        <div className={BLandingCss.insuranceListingWrapper}>
          <div style={{ flex: 1, maxWidth: 174 }}>
            <div className={BLandingCss.insuranceListingText}>Insurance provided by</div>
            <img className={BLandingCss.insuranceListingImage} src={insurance} alt="Insurance Marine" />
          </div>
          <div style={{ flex: '0 0 40%', maxWidth: '40%', padding: '0 0 0 15px' }}>
            <div style={{ marginBottom: 10 }}>
              <div className={BLandingCss.insuranceListingText}>Insurance provided by</div>
              <img
                className={BLandingCss.insuranceListingImage}
                src={insurance}
                alt="Tokio Marine"
              />
            </div>
            <div>
              <div className={BLandingCss.insuranceListingText}>Approved by</div>
              <img
                className={BLandingCss.approvedByCompanyLogo}
                src={landTransport}
                alt="Land Transport"
              />
            </div>
          </div>
        </div>
      )}

      <div className={BLandingCss.channelsListings}>
        <Channels />
      </div>
    </>
  );
});

export default ChannelsListings;
