import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { formatMoney } from '../../../util/currency';
import { LINE_ITEM_UNITS } from '../../../util/types';
import css from '../BookingBreakdown.css';
import Explanation from '../Explaination';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemLongTermBasePrice = ({ transaction, isCustomer, isProvider, intl }) => {
  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_UNITS && !item.reversal
  );

  if (!customerCommissionLineItem) {
    return null;
  }

  let commission = customerCommissionLineItem.lineTotal;
  if (isProvider) {
    const baseTripPrice = get(
      transaction,
      'attributes.protectedData.entireLongTermTripCommission.note.baseTripPrice',
      0
    );
    commission = new Money(baseTripPrice, commission.currency);
  }

  const formattedCommission = commission ? formatMoney(intl, commission) : null;
  return formattedCommission ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdownLongTerm.longTermRentalPrice" />
        <Explanation text="Special long term monthly price multiplied by the number of months the booking dates are selected." />
      </span>
      <span className={css.itemValue}>{formattedCommission}</span>
    </div>
  ) : null;
};

export default LineItemLongTermBasePrice;
