import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SearchMap,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';

// import SectionImages from './SectionImages';
import css from './SeoLandingPage.css';
import SectionRealBenefits from './SectionRealBenefits';
import SectionHelp from './SectionHelp';
import SeoLandingBanner from './SeoLandingBanner';
import SeoLandingWhyRentCar from './SeoLandingWhyRentCar';
import SeoLandingRentOtherBrands from './SeoLandingRentOtherBrands';
import SeoLandingRentVideo from './SeoLandingRentVideo';
import SectionCarsOnLocations from './SectionCarsOnLocations';
import SeoLandingBannerLocation from './SeoLandingBannerLocation';
import SectionCars from './SectionCars';
import SeoLandingRentOtherBrandsLocation from './SeoLandingRentOtherBrandsLocation';
import SeoLandingRentOtherSegments from './SeoLandingRentOtherSegments';
import SectionCarsTopBrands from './SectionCarsTopBrands';
import DownloadAppSection from '../AntiCompetitionLandingPage/DownloadAppSection';
import { additionalLocations } from './additionalLocation';
import { TOP_BRANDS, TOP_CATEGORIES, TOP_LOCATIONS } from './seoCategoriesArrays';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;
const defaultLocationIdentifiers = [
  'tampines',
  'bukit-batok',
  'punggol',
  'choa-chu-kang',
  'serangoon',
];
const MAX_MOBILE_SCREEN_WIDTH = 768;
export const SEO_LANDING_CONSTANTS = {
  SEO_PAGE_RENTAL_TOP_BRANDS: 'top-car-rental-brands',
  SEO_PAGE_RENTAL_LOCATION: 'australia-locations',
  SEO_PAGE_RENTAL_SEGMENT: 'car-rental-by-segment',
};

const getMetadata = (category, subCategory) => {
  let metaData = {
    pageTitle: '',
    pageDescription: '',
    imageURL: null,
    pageKeywords: ''
  };
  switch (category) {
    case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS:
      const getBrandName = subCategory.replace('-cars-for-rent', '');
      const currentBrand =
        TOP_BRANDS &&
        TOP_BRANDS.values &&
        TOP_BRANDS.values.length > 0 &&
        TOP_BRANDS.values.find(item => {
          if (item.key === getBrandName) {
            return item.key;
          }
        });
      let title = '';
      let description = '';
      let keywords = '';
      switch (currentBrand.label) {


        case 'BMW':
          title = "BMW cars for rent in Australia with Drive mate";
          description = "Find the best BMW Fully Insured short term and long term car rental deals in Australia with DriveLa's wide range of  pre-owned & fully insured Mitsunishi cars.";
          keywords = "BMW cars for rent in Australia, BMW cars"
          break;

        case 'Honda':
          title = "Honda Cars in Australia with Drive mate";
          description = "With Drive mate, you can rent from a huge variety of nearby Fully insured Honda at great value in Australia";
          keywords = "Honda rental cars in Australia, Honda cars for rent in Australia, honda car rental, honda rental"
          break;

        case 'Hyundai':
          title = "Hyundai Cars Rental in Australia – Drive mate";
          description = "Enjoy a seamless rental experience with excellent customer service with DriveLa's wide range of Hyundai cars that are pre-owned & fully insured.";
          keywords = "Hyundai cars for rent in Australia, Hyundai  rental cars in Australia, Hyundai Cars"
          break;

        case 'Mercedes Benz':
          title = "Mercedes-Benz Rental in Australia – Drive mate";
          description = "Enjoy a seamless rental experience with excellent customer service with DriveLa's wide range of Mercedes-Benz cars that are pre-owned & fully insured.";
          keywords = "Mercedes Benz cars for rent in Australia, Mercedes-Benz rental cars in Australia"
          break;

        case 'Mini':
          title = "Mini Cars for Rental in Australia – Drive mate";
          keywords = "BMW cars for rent in Australia, BMW cars"
          description = "Enjoy a seamless rental experience with excellent customer service with DriveLa's wide range of MIni Cars that are pre-owned & fully insured.";
          keywords = "Mini Cars for rental in Australia, Mini Cars"
          break;

        case 'Mitsubishi':
          title = "Mitsubishi cars for rent in Australia with Drive mate";
          description = "Find the best Mitsubishi Fully Insured short term and long term car rental deals in Australia with DriveLa's wide range of  pre-owned & fully insured Mitsunishi cars.";
          keywords = "Mitsubishi cars for rent in Australia, Mitsubishi cars "
          break;

        case 'Porsche':
          title = "Porsche Cars Rental in Australia – Drive mate";
          description = "Enjoy a seamless rental experience with excellent customer service with DriveLa's wide range of Porsche cars that are pre-owned & fully insured.";
          keywords = "Porsche Cars Rental in Australia"
          break;

        case 'Toyota':
          title = "Toyota Rental Cars in Australia with Drive mate";
          description = "Browse the wide range of Toyota rental cars in Australia. Book the Toyota car of your choice now & enjoy!";
          keywords = "Toyota cars, Toyota Rental Cars"
          break;

        case 'Volvo':
          title = "Volvo Rental Cars in Australia with Drive mate";
          description = "Browse the wide range of Volvo rental cars in Australia. Book the Volvo car of your choice now & enjoy!";
          keywords = "Volvo Cars for rental in Australia, Volvo Cars"
          break;

        case 'Mazda':
          title = "Mazda cars for rent in Australia with Drive mate";
          description = "Browse the wide range of Mazda rental cars in Australia. Book the Mazda car of your choice now & enjoy!";
          keywords = "Mazda cars, Mazda Rental Cars"
          break;

        case 'Audi':
          title = "Audi Rental Cars in Australia with Drive mate";
          description = "With Drive mate, you can rent from a huge variety of nearby Fully insured Audis at great value in Australia";
          keywords = "audi rental cars in Australia,audi cars for rent in Australia, audi rental"
          break;
      }
      metaData = {
        pageTitle: title,
        pageDescription: description,
        imageURL: currentBrand.image,
        pageKeywords: keywords,
      };
      return metaData;
    case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT:
      const getSegmentName = subCategory.replace('-cars-for-rent', '');
      const currentSegment =
        TOP_CATEGORIES &&
        TOP_CATEGORIES.values &&
        TOP_CATEGORIES.values.length > 0 &&
        TOP_CATEGORIES.values.find(item => {
          if (item.key === getSegmentName) {
            return item.key;
          }
        });

      let Segmenttitle = '';
      let Segmentdescription = '';
      let Segmentkeywords = '';

      switch (currentSegment.label) {

        case 'Compact':
          Segmenttitle = "Compact Cars for Rent | Drive mate";
          Segmentdescription = "Looking for Compact Cars for rent in Australia? Choose from the top deals for Compact Car Rental, Book & Save Today with Drive mate";
          Segmentkeywords = "compact cars for rent";
          break;

        case 'Standard Sedan':
          Segmenttitle = "Standard Sedan Cars for Rent | Drive mate";
          Segmentdescription = "Looking for Compact Cars for rent in Australia? Choose from the top deals for Compact Car Rental. Book & Save Today with Drive mate";
          Segmentkeywords = "Standard Sedan Cars for rent";
          break;

        case 'Standard SUV':
          Segmenttitle = "Standard SUV Car Rental | Drive mate";
          Segmentdescription = "Looking for Sedan Car Rental in Australia? Choose from the top rented Standard Sedan Cars. Book & Save Today with Drive mate";
          Segmentkeywords = "sedan car rental, standard sedan rental";
          break;

        case 'Luxury Sedans':
          Segmenttitle = "Luxury Sedan Car Rental | Drive mate";
          Segmentdescription = "Looking for Luxury Sedan Car Rental in Australia? Choose from the top rented Luxury Sedan Cars. Book & Save Today with Drive mate";
          Segmentkeywords = "sedan car rental";
          break;

        case 'Luxury SUV':
          Segmenttitle = "Luxury SUV Car Rental | Drive mate";
          Segmentdescription = "Looking for Luxury SUV Car Rental in Australia? Choose from the top rented Luxury SUV Cars. Book & Save Today with Drive mate";
          Segmentkeywords = "luxury suv car rental, luxury suv rental";
          break;

      }
      metaData = {
        pageTitle: Segmenttitle,
        pageDescription: Segmentdescription,
        imageURL: currentSegment.image,
        pageKeywords: Segmentkeywords,
      };
      return metaData;

    case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION:
      const regexText = 'in-';
      const extractLocationName = subCategory.slice(
        subCategory.indexOf(regexText) + regexText.length
      );
      const currentLocation =
        TOP_LOCATIONS &&
        TOP_LOCATIONS.values &&
        TOP_LOCATIONS.values.length > 0 &&
        TOP_LOCATIONS.values.find(item => {
          if (item.key === extractLocationName) {
            return item.key;
          }
        });


      let Locationtitle = '';
      let LocationDescription = '';
      let LocationKeywords = '';
      if(currentLocation){
        switch (currentLocation.label) {

          case 'Bukit Batok':
            Locationtitle = "Cars for rent in Bukit Batok | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Bukit Batok at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental in bukit batok, cars for rent in Bukit Batok";
            break;

          case 'Choa Chu Kang':
            Locationtitle = "Car Rental in Choa Chu Kang | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Choa Chu Kang at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "Car Rental Choa Chu Kang";
            break;

          case 'Punggol':
            Locationtitle = "Car Rental in Punggol | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Punggol at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental punggol";
            break;

          case 'Serangoon':
            Locationtitle = "Car Rental in Serangon | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Serangon at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "Car Rental Serangon";
            break;

          case 'Tampines':
            Locationtitle = "Cars for rent in Tampines | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Tampines at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental in tampines";
            break;

          case 'Woodlands':
            Locationtitle = "Car Rental in Woodlands| Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Woodlands at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental in Woodlands";
            break;

          case 'Jurong':
            Locationtitle = "Car Rental in Jurong Australia | Drive mate";
            LocationDescription = "Rent a car in East and West Jurong at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental in jurong, car rental in jurong Australia, Car rental in East Jurong,  Car rental in West Jurongs";
            break;

          case 'Ang Mo Kio':
            Locationtitle = "Car Rental in Ang Mo Kio | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Ang Mo Kio at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "Car Rental Ang Mo Kio";
            break;

          case 'Bukit Panjang':
            Locationtitle = "Car Rental in Bukit Panjang | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Bukit Panjang at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental bukit panjang";
            break;

          case 'Sengkang':
            Locationtitle = "Car Rental in Sengkang | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Sengkang at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "car rental in Sengkang";
            break;

          case 'Sembawang':
            Locationtitle = "Car Rental in Sembawang| Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Sembawang at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "Car Rental in Sembawang";
            break;

          case 'Hougang':
            Locationtitle = "Car Rental in Hougang | Drive mate";
            LocationDescription = "Rent a car from a huge variety of fully insured cars in Hougang at great value with Drive mate. Call us at +65 64328307 to get your ride now!";
            LocationKeywords = "Car Rental Hougang";
            break;

          case 'Yishun':
            Locationtitle = "Car Rental in Yishun| Drive mate";
            LocationDescription = "Looking for Luxury Sedan Car Rental in Australia? Choose from the top rented Luxury Sedan Cars. Book & Save Today with Drive mate";
            LocationKeywords = "car rental in yishun";
            break;

        }
        metaData = {
          pageTitle: Locationtitle,
          pageDescription: LocationDescription,
          imageURL: currentLocation.image,
          pageKeywords: LocationKeywords,
        };
      }


      return metaData;
    default:
      break;
  }
};

export const SeoLocationLandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    currentPage = 'SeoLocationLandingPage',
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  let { cat, subcat } = useParams();
  let [pageCategory, setPageCategory] = useState(null);
  let [brandCategory, setBrandCategory] = useState(null);
  let [locationName, setLocationName] = useState(null);
  let [selectedValues, setSelectedValues] = useState(null);
  let [mapBounds, setMapBounds] = useState(null);
  let [mapAddress, setMapAddress] = useState(null);
  let [mapOrigin, setMapOrigin] = useState(null);
  let [onLocationChange, setOnLocationChange] = useState(null);
  let [mapListingArray, setMapListingArray] = useState([]);
  let [searchFilters, setSearchFilters] = useState(null);
  const history = useHistory();
  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  const getLocationName = prediction => {
    let getSelectedArray = null;
    if (
      defaultPredictionsExcludingCurrentLocation &&
      defaultPredictionsExcludingCurrentLocation.length > 0
    ) {
      getSelectedArray = defaultPredictionsExcludingCurrentLocation.filter(item => {
        return item.id.toLocaleLowerCase() === prediction;
      });
    }
    return getSelectedArray;
  };

  const getLocationSearchPrediction = prediction => {
    let getSelectedArray = null;
    if (additionalLocations && additionalLocations.length > 0) {
      getSelectedArray = additionalLocations.filter(item => {
        return item.identifierId === prediction;
      });
    }
    return getSelectedArray;
  };

  const checkPageCategory = (c, sb) => {
    let isValidPage;
    setPageCategory(c);
    switch (c) {
      case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION:
        // const isValidPage = TOP_LOCATIONS
        const regexText = 'in-';
        const extractLocationName = sb.slice(sb.indexOf(regexText) + regexText.length);
        isValidPage =
          TOP_LOCATIONS &&
          TOP_LOCATIONS.values &&
          TOP_LOCATIONS.values.length > 0 &&
          TOP_LOCATIONS.values.findIndex(i => i.key === extractLocationName);
        if (isValidPage < 0) {
          const path = pathByRouteName('SeoLocationRentalLandingPage', routeConfiguration());
          history.push(path);
        } else {
          const prediction = 'default-' + extractLocationName;
          const locationData = defaultLocationIdentifiers.includes(extractLocationName)
            ? getLocationName(prediction)
            : getLocationSearchPrediction(extractLocationName);
          if (locationData[0]) {
            setMapAddress(locationData[0].predictionPlace.address);
            setMapBounds(locationData[0].predictionPlace.bounds);
            setMapOrigin(locationData[0].predictionPlace.origin);
          }
          setLocationName(extractLocationName);
        }

        break;
      case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS:
        const getBrandName = sb.replace('-cars-for-rent', '');
        isValidPage =
          TOP_BRANDS &&
          TOP_BRANDS.values &&
          TOP_BRANDS.values.length > 0 &&
          TOP_BRANDS.values.findIndex(i => i.key === getBrandName);
        if (isValidPage < 0) {
          const path = pathByRouteName('SeoBrandRentalLandingPage', routeConfiguration());
          history.push(path);
        }
        if (getBrandName === 'audi') {
          setBrandCategory('Audi');
        } else if (getBrandName === 'toyota') {
          setBrandCategory('Toyota');
        } else if (getBrandName === 'mercedes-benz') {
          setBrandCategory('Mercedes Benz');
        } else if (getBrandName === 'hyundai') {
          setBrandCategory('Hyundai');
        } else if (getBrandName === 'porsche') {
          setBrandCategory('Porsche');
        } else if (getBrandName === 'bmw') {
          setBrandCategory('BMW');
        } else if (getBrandName === 'honda') {
          setBrandCategory('Honda');
        } else if (getBrandName === 'mini') {
          setBrandCategory('Mini');
        } else if (getBrandName === 'mitsubishi') {
          setBrandCategory('Mitsubishi');
        } else if (getBrandName === 'volvo') {
          setBrandCategory('Volvo');
        } else if (getBrandName === 'mazda') {
          setBrandCategory('Mazda');
        }
        break;
      case SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT:
        const getSegmentName = sb.replace('-cars-for-rent', '');
        isValidPage =
          TOP_CATEGORIES &&
          TOP_CATEGORIES.values &&
          TOP_CATEGORIES.values.length > 0 &&
          TOP_CATEGORIES.values.findIndex(i => i.key === getSegmentName);
        if (isValidPage < 0) {
          const path = pathByRouteName('SeoSegmentRentalLandingPage', routeConfiguration());
          history.push(path);
        }
        if (getSegmentName === 'compact') {
          setBrandCategory('Compact');
        } else if (getSegmentName === 'standard-sedan') {
          setBrandCategory('Standard Sedan');
        } else if (getSegmentName === 'standard-suv') {
          setBrandCategory('Standard SUV');
        } else if (getSegmentName === 'luxury-sedan') {
          setBrandCategory('Luxury Sedans');
        } else if (getSegmentName === 'luxury-suv') {
          setBrandCategory('Luxury SUV');
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
  }, [pageCategory, onLocationChange]);

  useEffect(() => {
    checkPageCategory(cat, subcat);
    if (pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION) {
      const searchParams = {
        per_page: 8,
        bounds: mapBounds,
        address: mapAddress,
        sort: 'pub_isSuperHostSorting,pub_instantBookingSorting,meta_isDrivelahGoSorting',
      };
    }
  }, [cat, subcat]);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {

  }

  const onMapMoveEnd = () => { };
  //
  //
  //


  const metaData = getMetadata(cat, subcat);
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: metaData.pageTitle }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: metaData.pageDescription });
  // const schemaKeywords = metaData.pageKeywords;
  const schemaKeywords = intl.formatMessage({ id: metaData.pageKeywords });
  let pageImage = metaData && metaData.imageURL;
  const schemaImage = `${config.canonicalRootURL}${pageImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      facebookImages={[{ url: schemaImage, width: 1200, height: 630 }]}
      twitterImages={[{ url: `${config.canonicalRootURL}${schemaImage}`, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={currentPage} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapper}>
          <div style={{ zIndex: 2 }}>
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT && (
              <SeoLandingBanner
                isMobileLayout={isMobileLayout}
                brandCategory={brandCategory}
                subCategory={subcat}
                searchFilters={searchFilters}
                pageCategory={pageCategory}
                onManageDisableScrolling={onManageDisableScrolling}
                selectedValues={selectedValues}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS && (
              <SeoLandingBanner
                isMobileLayout={isMobileLayout}
                brandCategory={brandCategory}
                subCategory={subcat}
                searchFilters={searchFilters}
                pageCategory={pageCategory}
                selectedValues={selectedValues}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION && (
              <SeoLandingBannerLocation
                isMobileLayout={isMobileLayout}
                subCategory={subcat}
                address={mapAddress}
                bounds={mapBounds}
                center={mapOrigin}
                location={mapAddress}
                selectedValues={selectedValues}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            )}

            {!isMobileLayout && (
              <SectionRealBenefits
                subCategory={subcat}
                onManageDisableScrolling={onManageDisableScrolling}
                onPushGTMSeeVideoEvent={onPushGTMEvent}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS && (
              <SectionCarsTopBrands
                setSearchFilters={setSearchFilters}
                isMobileLayout={isMobileLayout}
                brandCategory={brandCategory}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT && (
              <SectionCars
                setSearchFilters={setSearchFilters}
                isMobileLayout={isMobileLayout}
                brandCategory={brandCategory}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION && (
              <SectionCarsOnLocations
                isMobileLayout={isMobileLayout}
                subCategory={subcat}
                address={mapAddress}
                bounds={mapBounds}
                center={mapOrigin}
                location={mapAddress}
                setMapListingArray={setMapListingArray}
              />
            )}
            <SeoLandingWhyRentCar />
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_TOP_BRANDS && (
              <SeoLandingRentOtherBrands
                selectedValues={selectedValues}
                brandCategory={brandCategory}
                setSelectedValues={val => setSelectedValues(val)}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_SEGMENT && (
              <SeoLandingRentOtherSegments
                selectedValues={selectedValues}
                brandCategory={brandCategory}
                setSelectedValues={val => setSelectedValues(val)}
              />
            )}
            {pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION && (
              <SeoLandingRentOtherBrandsLocation
                selectedValues={selectedValues}
                locationName={locationName}
                setSelectedValues={val => setSelectedValues(val)}
                setOnLocationChange={setOnLocationChange}
              />
            )}
            <SeoLandingRentVideo
              onManageDisableScrolling={onManageDisableScrolling}
              onPushGTMSeeVideoEvent={onPushGTMEvent}
            />
            {/* <TrustBoxSlider
              sectionTitle="Our users loves us"
              sectionSubTitle="People love renting and sharing cars on Drive mate"
            /> */}
            <DownloadAppSection />
            <SectionHelp />
            {/* <SeoLandingRentalCategories /> */}
          </div>
          {!isMobileLayout && pageCategory === SEO_LANDING_CONSTANTS.SEO_PAGE_RENTAL_LOCATION && (
            <SearchMap
              className={css.locationLandingMap}
              rootClassName={css.locationLandingMapRoot}
              mapRootClassName={css.locationLandingMapRootRoot}
              reusableContainerClassName={css.locationLandingMapLayout}
              onMapMoveEnd={onMapMoveEnd}
              bounds={mapBounds}
              center={mapOrigin}
              location={mapAddress}
              listings={mapListingArray || []}
            />
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, number, array, shape, func } = PropTypes;

SeoLocationLandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  mapListings: array,
  onSearchMapListings: func.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SeoLocationLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SeoLocationLandingPageComponent);
export default SeoLocationLandingPage;
