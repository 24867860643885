import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROVIDER_COMMISSION,
  propTypes,
} from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const LineItemProviderCommissionRefundMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );

  const initialPaymentLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
  );

  if (initialPaymentLineItem) {
    return null;
  }

  return isProvider && refund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refundProviderFee" />
        <Explanation text="Refund amount" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, refund.lineTotal, 1)}</span>
    </div>
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
