import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LoginModal,
  NamedLink,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import SectionHero from './SectionHero';
import SectionIntroducing from './SectionIntroducing';
import SectionVision from './SectionVision';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import css from './LandingPageGo.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { login } from '../../ducks/Auth.duck';
import classNames from 'classnames';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    scrollingDisabled,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onLogin,
    loginError,
    loginInProgress,
    onLoginOrSignupClick,
  } = props;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isGoToHost, setIsGoToHost] = useState(false);

  useEffect(() => {
    return () => {
      setShowLoginModal(false);
    };
  }, []);
  useEffect(() => {
    if (!!(currentUser && currentUser.id && isAuthenticated) && isGoToHost) {
      const routes = routeConfiguration();
      const url = createResourceLocatorString('LandingPageGoHost', routes);
      history.push(url);
    }
  });

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = 'Smarter, safer car sharing with 24 hour access | Drive Mate Goᵀᴹ';
  const schemaDescription = 'Remotely unlock & access booked cars with keyless entry technology. Remote tracking & immobilisation functionality. Innovative Australian industry first concept | Drive Mate Goᵀᴹ';
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isLogined = !!(currentUser && currentUser.id && isAuthenticated);

  const handleLearnMore = () => {
    if (isLogined) {
      const routes = routeConfiguration();
      const url = createResourceLocatorString('LandingPageGoHost', routes);
      history.push(url);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleLogin = values => {
    const { email, password } = values;
    setIsGoToHost(true);
    return onLogin(email, password);
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero />
          <SectionIntroducing />
          <SectionVision handleLearnMore={handleLearnMore} />
          <section className={css.whyOptDriveMate}>
            <div className={css.textBox}>
              <h4 className={css.contentTitle}>Why opt for Drive mate Go™?</h4>
              <div className={css.contentDescription}>
                <ul>
                  <li>
                    <p>No more arranging inconvenient pick-up locations to meet drivers.</p>
                  </li>
                  <li>
                    <p>No more waiting for guests to show up.​</p>
                  </li>
                  <li>
                    <p>
                      <strong>Restrictions on accessible hours? Forget ‘em!</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        Safety as car is always tracked with remote immobilisation feature
                      </strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Higher ROI - more demand for Drive mate Go cars​</strong>
                    </p>
                  </li>
                </ul>
              </div>
              {!isAuthenticated && (
                <h5>
                  <NamedLink
                    name="SignupPage"
                    className={classNames(css.goToSearchButton, css.center)}
                    onClick={onLoginOrSignupClick}
                  >
                    Register for Drive mate Go
                  </NamedLink>
                </h5>
              )}
            </div>
          </section>
          <section className={css.bestHostBizSection}>
            <div className={css.textBox}>
              <h6 className={css.contentTitle}>Featuring the best hosts in the biz!</h6>
              <div className={css.contentDescription}>
                <p>
                  At Drive mate, we want you to have nothing but the most positive car sharing
                  experiences to tell fellow guests about.
                </p>
                <p>
                  As a Drive mate guest, you’ll benefit from having instant access to hosts with
                  highly-established reputations for providing remarkable services within our
                  communities.
                </p>
              </div>
            </div>
          </section>
          {/* <SectionDirectAccess /> */}
          <LoginModal
            onManageDisableScrolling={onManageDisableScrolling}
            isOpen={showLoginModal}
            onClose={() => setShowLoginModal(false)}
            handleSubmit={handleLogin}
            loginError={loginError}
            loginInProgress={loginInProgress}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated, loginError, loginInProgress } = state.Auth;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    loginError,
    loginInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onLogin: (email, password) => dispatch(login(email, password)),
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
