import React from 'react';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import css from '../LongTermRentalListingPage.css';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemMileageMaybe = props => {
  const { intl, additionalCost } = props;
  const flexPlusBasePrice = new Money(additionalCost * 100, config.currency);


  return (
    <div>
      <span className={css.priceTitle}>Mileage</span>
      <span className={css.priceTitle}>{formatMoney(intl, flexPlusBasePrice, 1)}</span>
    </div>
  );
};

LineItemMileageMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemMileageMaybe;
