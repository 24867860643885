import React from 'react';
import css from './GrabHostYourCarLandingPage.css';
import Slider from 'react-slick';

import storyIcon from '../../assets/landingpage/storyIcon.png';
import userIcon from '../../assets/grabBanner/userIcon.svg';

const datas = [
  {
    content:
      "I spend my time with family on weekends so I don't need my car. I list it on Drive mate and get paid for weekends.",
    name: 'Supu Xu',
    avatar: userIcon,
  },
  {
    content:
      'Because Grab has such high demand, my car is instantly booked in my breaks. I never thought I could make extra money with my car like this.',
    name: 'John',
    avatar: userIcon,
  },
  {
    content:
      'The demand is less because of COVID 19. The car was not being used so I tried Drive mate. To my surprise, it was booked almost immediately and I got the payment. Now I make extra money when not using the car.',
    name: 'Shimei He',
    avatar: userIcon,
  },
  {
    content: 'Convenient way to Rent out your vehicle with insurance covered. Hassle free!',
    name: 'Jonathan Lai',
    avatar: userIcon,
  },
  {
    content:
      'Drive mate is a safe, easy and convenient platform for car owners to lease their unused car 24-7 for extra income to offset their car maintenance in Australia. Thanks Drive mate!',
    name: 'Ben Chan',
    avatar: userIcon,
  },
];

const Story = ({ content, name, avatar }) => {
  return (
    <div className={css.story}>
      <div className={css.storyContentWrapper}>
        <img src={storyIcon} height={100} />
        <div className={css.storyContent}>{content}</div>
      </div>
      <div className={css.avatarWrapper}>
        <img src={avatar} className={css.avatar} />
        <div className={css.storyName}>{name}</div>
      </div>
    </div>
  );
};

const SectionStories = () => {
  const sliderConfig = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2.25,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.25,
          initialSlide: 0,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionStories}>
      <div className={css.sectionStoriesTitleWrapper}>
        <div className={css.sectionTitle}>Stories from our happy hosts</div>
        <div className={css.sectionStoriesDescription}>They love sharing cars on Drive mate!</div>
      </div>
      <div className={css.sliderWrapper}>
        <Slider {...sliderConfig}>
          {datas.map(s => (
            <div className={css.storyWrapper}>
              <Story {...s} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionStories;
