import React from 'react';

const StarPromotionIcon = ({ width = 32, height = 32, color = '#1AA2AC', className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            className={className}
            fill="none"
            id={'StarPromotionIcon'}
        >
            <path d="M14.9105 1.54415C15.4417 0.791259 16.5583 0.79126 17.0895 1.54415L19.69 5.23002C19.9867 5.65061 20.4991 5.86282 21.0063 5.77525L25.4515 5.0078C26.3595 4.85104 27.149 5.64055 26.9922 6.54854L26.2248 10.9937C26.1372 11.5009 26.3494 12.0133 26.77 12.31L30.4558 14.9105C31.2087 15.4417 31.2087 16.5583 30.4558 17.0895L26.77 19.69C26.3494 19.9867 26.1372 20.4991 26.2248 21.0063L26.9922 25.4515C27.149 26.3594 26.3594 27.149 25.4515 26.9922L21.0063 26.2248C20.4991 26.1372 19.9867 26.3494 19.69 26.77L17.0895 30.4558C16.5583 31.2087 15.4417 31.2087 14.9105 30.4558L12.31 26.77C12.0133 26.3494 11.5009 26.1372 10.9937 26.2248L6.54854 26.9922C5.64055 27.149 4.85104 26.3594 5.0078 25.4515L5.77525 21.0063C5.86282 20.4991 5.65061 19.9867 5.23002 19.69L1.54415 17.0895C0.791259 16.5583 0.79126 15.4417 1.54415 14.9105L5.23002 12.31C5.65061 12.0133 5.86282 11.5009 5.77525 10.9937L5.0078 6.54854C4.85104 5.64055 5.64055 4.85104 6.54854 5.0078L10.9937 5.77525C11.5009 5.86282 12.0133 5.65061 12.31 5.23002L14.9105 1.54415Z" fill={color} />
            <path d="M20.6666 11.3333L11.3333 20.6667" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.3334 14C13.2539 14 14.0001 13.2538 14.0001 12.3333C14.0001 11.4129 13.2539 10.6667 12.3334 10.6667C11.4129 10.6667 10.6667 11.4129 10.6667 12.3333C10.6667 13.2538 11.4129 14 12.3334 14Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.6667 21.3333C20.5871 21.3333 21.3333 20.5871 21.3333 19.6667C21.3333 18.7462 20.5871 18 19.6667 18C18.7462 18 18 18.7462 18 19.6667C18 20.5871 18.7462 21.3333 19.6667 21.3333Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default StarPromotionIcon;