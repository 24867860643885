import React, { useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Modal,
  Page,
  UserNav,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './RefundableDepositPage.css';
import {
  fetchCurrentUserDepositStatusPage,
  fetchNextTransitionsRefundableDepositPage,
} from './RefundableDepositPage.duck.js';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import moment from 'moment';
import { fetchCurrentUser } from '../../ducks/user.duck';
import AlertBox from '../../components/AlertBox/AlertBox';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const RefundableDepositPageComponent = props => {
  const [showModal, setShowModal] = useState(false);

  const {
    currentUser,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    fetchCurrentUserDepositStatus,
    fetchNextTransitionsRefundableDeposit,
    connectCalendarTabVisibility,
    isDepositCharged,
    canRefundRequest,
    depositChargeStatus,
    depositChildTx,
    activeRefundRequest,
    depositedAt,
    isLoading,
    isError,
    depositDataArchive,
    showModalState,
    isTransitioned,
    isProcessing,
    isTransitionError,
    willChargeInNextTx
  } = props;

  console.log("Props in refundable deposit page.", props);

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const activeTripErrorMessage = intl.formatMessage({ id: 'RefundableDeposit.activeTripErrorMessage' });

  const errorAlert = showErrorAlert ? <AlertBox
    title='You have an active trip'
    message={activeTripErrorMessage}
    type='error'
  /> : '';

  const title = intl.formatMessage({ id: 'AccountSettingPage.refundableDepositTitle' });
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  //Reset State onClick once button is pressed.
  useEffect(() => {
    setShowErrorAlert(false);
  }, [showErrorAlert]);

  useEffect(() => {
    if (currentUser) {
      console.log('CurrentUser in useEffect', currentUser && currentUser.id && currentUser.id.uuid);
      const userId = currentUser && currentUser.id && currentUser.id.uuid;
      fetchCurrentUserDepositStatus(userId);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      console.log('CurrentUser in isTransitioned', currentUser && currentUser.id && currentUser.id.uuid);
      const userId = currentUser && currentUser.id && currentUser.id.uuid;
      fetchCurrentUserDepositStatus(userId);
    }
  }, [isTransitioned]);

  let tabs = [...config.custom.tabs];
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (!(ensuredCurrentUser && ensureHostVerified(ensuredCurrentUser))) {
      if (tab.linkProps.name === 'MonthlyStatementPage') {
        return false;
      }
    }
    if (tab.linkProps.name === 'SyncCalenderPage') {
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  });
  const currentTab = tabs.find((tab) => tab.linkProps.name === 'RefundableDepositPage');
  if (currentTab) {
    currentTab.selected = true;
  }

  const refundRequestedHeading = intl.formatMessage({ id: 'RefundableDepositPage.refundRequestedHeading' });
  const refundRequestedContent = intl.formatMessage({ id: 'RefundableDepositPage.refundRequestedContent' });
  const refundQueuedHeading = intl.formatMessage({ id: 'RefundableDepositPage.refundQueuedHeading' });
  const refundQueuedContent = intl.formatMessage({ id: 'RefundableDepositPage.refundQueuedContent' });
  const refundSuccess = intl.formatMessage({ id: 'RefundableDepositPage.refundSuccess' });
  const refundSuccessContent = intl.formatMessage({ id: 'RefundableDepositPage.refundSuccessContent' });
  const refundOnHoldHeading = intl.formatMessage({ id: 'RefundableDepositPage.refundOnHoldHeading' });
  const refundOnHoldContent = intl.formatMessage({ id: 'RefundableDepositPage.refundOnHoldContent' });
  const modalTitle = intl.formatMessage({ id: 'RefundableDeposit.modalTitle' });
  const modalContent = intl.formatMessage({ id: 'RefundableDeposit.modalContent' });
  const modalContent2 = intl.formatMessage({ id: 'RefundableDeposit.modalContent2' });
  const modalButton = intl.formatMessage({ id: 'RefundableDeposit.modalButton' });


  //Available states to observe
  // const states = ["pending", "refund-requested", "admin-refund-requested", "confirmed", "expired", "auto-refund-initiated", "on-hold", "released", "request-denied"]

  let refundHeading, refundContent, handleShowDesc = true;

  //Apply the heading as per logic refundRequestedHeading and refundQueuedHeading
  if (depositChargeStatus === 'refund-requested' || depositChargeStatus === 'admin-refund-requested' && activeRefundRequest) {
    refundHeading = refundRequestedHeading;
    refundContent = refundRequestedContent;
  } else if (depositChargeStatus === 'auto-refund-initiated') {
    refundHeading = refundQueuedHeading;
    refundContent = refundQueuedContent;
  } else if(depositChargeStatus === 'on-hold') {
    refundHeading = refundOnHoldHeading
    refundContent = refundOnHoldContent
  } else if (depositChargeStatus === 'released' || depositDataArchive.length > 0) {
    //Check for deposit Archive data
    handleShowDesc = false;
    refundHeading = refundSuccess;
    refundContent = refundSuccessContent;
  }
  const formattedDate = moment(depositedAt).format('DD.MM.YYYY');
  let depositDateContent = (canRefundRequest ||
    depositChargeStatus === 'confirmed' ||
    depositChargeStatus === 'refund-requested' ||
    depositChargeStatus === 'admin-refund-requested' ||
    depositChargeStatus === 'on-hold') ? `Deposited on: ${formattedDate}` : 'You will be charged when you make your next booking.';
  depositDateContent = !willChargeInNextTx ? 'You are not required to hold deposit on Drive mate.' : depositDateContent;

  const updateTransition = () => {
    //Change the transition state of the childTx
    fetchNextTransitionsRefundableDeposit(depositChildTx);
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      {errorAlert}
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="RefundableDepositPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="RefundableDepositPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          {isLoading && <div className={css.loader}></div>}
          {!isLoading &&
            <div className={css.pageContent}>
              {/*Title of Deposit Container*/}
              <div className={css.titleSection}>
                <h1 className={css.title}>Refundable Deposit</h1>
              </div>

              {/*Amount to be shown section with deposited date*/}
              <div className={css.depositAmountContainer}>
                <div className={css.depositContainer}>
                <span className={css.holdHeading}>
                  Deposit on hold
                </span>
                  <span className={css.holdContent}>
                  {depositDateContent}
                </span>
                </div>
                <div className={css.amountCss}>
                  {(canRefundRequest ||
                    depositChargeStatus === "confirmed" ||
                    depositChargeStatus === "refund-requested" ||
                    depositChargeStatus === "admin-refund-requested" ||
                    depositChargeStatus === "on-hold")
                    ? "$50 ": "$0"
                  }
                </div>
              </div>

              {/* What is deposit section */}
              {
                handleShowDesc && <div className={css.depositDescription}>
                  <div className={css.refundContainer}>
                    <div className={css.refundHeading}>
                      <span>What is a refundable deposit?</span>
                    </div>
                    <div>
                      <p className={css.refundContent}>
                        To ensure safety of our hosts we collect a deposit of $50 from guests at the time of their first booking.
                        You can request for this to be refunded anytime (apart from when you are on an active trip).
                      </p>
                    </div>
                    <div className={css.depositLink}>
                  <span>
                    <a href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/how-the-250-deposit-is-charged-and-returned-a67bLaTYnJ/Steps/2620357`} target="_blank">
                    Learn more
                    </a>
                  </span>
                    </div>
                  </div>
                </div>
              }

              {/*Status section based on the logics*/}
              {
                (((depositChargeStatus === "auto-refund-initiated" ||
                    depositChargeStatus === "released" ||
                    depositChargeStatus === "refund-requested" ||
                    depositChargeStatus === "admin-refund-requested" ||
                    depositChargeStatus === "on-hold")) ||
                  (depositDataArchive.length > 0)) &&
                <div className={css.refundRequested}>
                  <div className={css.refundContainer}>
                    <div>
                      <span className={css.refundHeading}>{refundHeading}</span>
                    </div>
                    <div>
                      <p className={css.refundContent}>
                        {refundContent}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {/*Show button section*/}
              {
                (canRefundRequest || (depositChargeStatus === "confirmed")) ? <div style={{
                  margin: "20px",
                }}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    onClick={() => {
                      if(!canRefundRequest) {
                        setShowErrorAlert(true)
                      } else {
                        setShowModal(true);
                      }
                    }}
                  >
                    {modalButton}
                  </Button>
                </div> : ""
              }
            </div>
          }
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <Modal
        // className={css.modalClass}
        isOpen={showModal && showModalState}
        onManageDisableScrolling={onManageDisableScrolling}
        closeTextClassName={css.closeTextModal}
        disableCloseBtn={false}
        containerClassName={css.modalContainer}
        forceClose={false}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <div>
          <div className={css.modalHeadingTitle}>{modalTitle}</div>
          <p className={css.modalContent}>{modalContent}</p>
          <p className={css.modalContent}>{modalContent2}</p>
          <div>
            <Button
              inProgress={isProcessing}
              onClick={()=> {
              updateTransition()}}
            >
              {modalButton}
            </Button>
          </div>
        </div>
      </Modal>
    </Page>
  );
};

RefundableDepositPageComponent.defaultProps = {
  currentUser: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

RefundableDepositPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  addPaymentMethodError: object,
  deletePaymentMethodError: object,
  createStripeCustomerError: object,
  handleCardSetupError: object,
  onCreateSetupIntent: func.isRequired,
  onHandleCardSetup: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onDeletePaymentMethod: func.isRequired,
  fetchStripeCustomer: func.isRequired,
  fetchCurrentUserDepositStatusPage: func.isRequired,
  fetchNextTransitionsRefundableDepositPage: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    isDepositCharged,
    canRefundRequest,
    depositChargeStatus,
    depositChildTx,
    activeRefundRequest,
    depositedAt,
    isLoading,
    isError,
    depositDataArchive,
    showModalState,
    isTransitioned,
    isProcessing,
    isTransitionError,
    willChargeInNextTx
  } = state.RefundableDepositPage;

  console.log("MapStateToprops", state.RefundableDepositPage);
  return {
    currentUser,
    isDepositCharged,
    canRefundRequest,
    depositChargeStatus,
    depositChildTx,
    activeRefundRequest,
    depositedAt,
    isLoading,
    isError,
    depositDataArchive,
    showModalState,
    isTransitioned,
    isProcessing,
    isTransitionError,
    willChargeInNextTx,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
  fetchCurrentUserDepositStatus: (userId) => dispatch(fetchCurrentUserDepositStatusPage(userId)),
  fetchNextTransitionsRefundableDeposit: (depositChildTx) => dispatch(fetchNextTransitionsRefundableDepositPage(depositChildTx))
});



const RefundableDepositPageNew =  compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(RefundableDepositPageComponent);


RefundableDepositPageNew.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default RefundableDepositPageNew;
