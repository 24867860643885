import React from 'react';
import css from './LandingPageGoHost.css';

import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../components';

const SectionInstallation = () => {

  const openIntercomPannel = () => {
    if (typeof window !== 'undefined' || window) {
      window.Intercom('show');
    }
  }

  return (
    <div className={css.sectionInstallation}>
      <div className={css.sectionInstallationContentWrapper}>
        <div className={css.titleWrapper}>
          <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
          <div className={css.title}>
            <FormattedMessage id='LandingPageGoHost.SectionInstallation.title' />
          </div>
        </div>

        <div className={css.description}>
          <FormattedMessage id='LandingPageGoHost.SectionInstallation.description1' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LandingPageGoHost.SectionInstallation.description2' />
        </div>

        <div className={css.items}>
          <div className={css.item}>
            <div className={css.index}>1</div>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionInstallation.item1' />
            </div>
          </div>
          <div className={css.item}>
            <div className={css.index}>2</div>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionInstallation.item2' />
            </div>
          </div>
          <div className={css.item}>
            <div className={css.index}>3</div>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionInstallation.item3' />
            </div>
          </div>
          <div className={css.item}>
            <div className={css.index}>4</div>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionInstallation.item4' />
            </div>
          </div>
        </div>

        <Button className={css.button} onClick={openIntercomPannel}>
          <FormattedMessage id='LandingPageGoHost.registerForDrivelahGo' />
        </Button>
      </div>
    </div>
  )
};

export default SectionInstallation;
