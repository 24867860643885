import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  txIsAccepted,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIWaitingConfirmPickup,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
} from '../../util/transaction';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton, SecondaryButton } from '../../components';
import { TRIP_PRIMARY_BUTTON_TRANSIT_NAME } from '../../util/gtm/gtmConstants';
import css from './TransactionPanel.css';
import { checkIsOldCar, displayDateForUser } from '../../util/dates';
import moment from 'moment';
import {
  TRANSITION_LTF_REQUEST_PICK_UP,
  txIsAcceptedLTF,
  txIsBeingPickedUpLTF,
  txIWaitingRequestPickupLTF,
} from '../../util/transactionLongTermFirst';
import { txIWaitingRequestDropoffLTL } from '../../util/transactionLongTermLast';
import { getTransitButtonLabelId, getTransitErrorLabelId } from './transitionHelper';

const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
  userId,
  listingId,
  guestName,
  pickUpDate,
  dropOffDate,
  plateNumber,
  chasisNumber,
  isOldCar,
  transactionId,
}) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

// Functional component as a helper to build ActionButtons for
// provider/customer
export const TransitActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    canShowButtons,
    transaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    ownListing,
    onPushEventGTM,
  } = props;
  const buttonsDisabled = transitInProgress;
  const validTransaction = transaction && transaction.customer;
  const transitAllow =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

  const guestIdentityIsNotVerify = !transitAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionNames[0])} />
    </p>
  ) : null;

  const { displayStart, displayEnd } = transaction.booking.attributes;

  const dislayStartDateForUser = new Date(displayDateForUser(displayStart));

  const displayStartTimeAtBeginOfTheDay = new Date(
    dislayStartDateForUser.setHours(dislayStartDateForUser.getHours() - 2)
  );
  // const displayEndTimeAtBeginOfTheDay = new Date(new Date(displayDateForUser(displayEnd)).setHours(0, 0, 0, 0));

  const isPickup =
    transitionNames.includes(TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE) ||
    transitionNames.includes(TRANSITION_LTF_REQUEST_PICK_UP);


  const isDropoff = transitionNames.includes(TRANSITION_REQUEST_DROP_OFF);
  const isDrivelahGo = (transaction.listing
    && transaction.listing.attributes.metadata &&
      transaction.listing.attributes.metadata.isDrivelahGo) && (transitionNames.includes(TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE) || transitionNames.includes(TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED));
  const lastTransitionAt =
    transaction.attributes.lastTransitionedAt && isDropoff
      ? new Date(transaction.attributes.lastTransitionedAt)
      : null;

  const droppoffAvailableTime = lastTransitionAt
    ? moment(lastTransitionAt)
        .add(45, 'minutes')
        .toDate()
    : null;

  const isNotTimeToShowTransitionPickup = !isPickup
    ? false
    : displayStartTimeAtBeginOfTheDay.getTime() > new Date().getTime()
    ? true
    : false;

  const isNotTimeToShowTransitionDropoff =
    !isDropoff && !droppoffAvailableTime
      ? false
      : droppoffAvailableTime > new Date().getTime()
      ? true
      : false;

  const isNotTimeToShowTransition =
    isNotTimeToShowTransitionPickup || isNotTimeToShowTransitionDropoff;
  const classes = classNames(rootClassName || css.actionButtons, className);

  const providerTripPhoto = txIsBeingPickedUp(transaction) || txIsBeingDropOff(transaction);
  const allowTripPhotos =
    txIsAccepted(transaction) ||
    txIsAcceptedLTF(transaction) ||
    txIWaitingRequestPickup(transaction) ||
    txIWaitingRequestPickupLTF(transaction) ||
    txIWaitingRequestDropoff(transaction) ||
    txIWaitingRequestDropoffLTL(transaction);
  const disabledActions = allowTripPhotos && props.tripPhotos && props.tripPhotos.length === 0;

  const handlePrimaryButtonClick = (transitAllow, transaction, ownListing) => {

    console.log("PICK UP BUTTON TRANSIT");
    onPushEventGTM(transaction, transitionNames[0]);
    if (
      txIWaitingConfirmPickup(transaction) &&
      transitionNames[0] === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE &&
      transitAllow &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;
      fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => {
          onTransit(
            transaction.id,
            transitionNames[0],
            null,
            allowTripPhotos || providerTripPhoto
              ? [...props.tripPhotos, ...props.allTripPhotos]
              : null,
            data,
            isDrivelahGo
          );
        });
    } else {
      if (transitAllow) {
        onTransit(
          transaction.id,
          transitionNames[0],
          null,
          allowTripPhotos || providerTripPhoto
            ? [...props.tripPhotos, ...props.allTripPhotos]
            : null,
          null,
          isDrivelahGo
        );
      }
    }
  };
  const handleSecondaryButtonClick = (transaction, ownListing) => {

    if (
      txIWaitingConfirmPickup(transaction) &&
      transitionNames[1] === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;

      fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => {
          onTransit(
            transaction.id,
            transitionNames[1],
            null,
            allowTripPhotos || providerTripPhoto
              ? [...props.tripPhotos, ...props.allTripPhotos]
              : null,
            data
          );
        });
    } else {
      if (transitAllow) {
        onTransit(
          transaction.id,
          transitionNames[1],
          null,
          allowTripPhotos || providerTripPhoto
            ? [...props.tripPhotos, ...props.allTripPhotos]
            : null,
          null
        );
      }
    }
  };

  return canShowButtons && !isNotTimeToShowTransition ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {transitErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        {transitionNames[1] ? (
          <SecondaryButton
            className={css.secondButton}
            inProgress={transitInProgress}
            disabled={buttonsDisabled || !transitAllow || disabledActions}
            onClick={() => {
              if (transitAllow) {
                txIsBeingDropOff(transaction)
                  ? props.onOpenDisputeModal()
                  : handleSecondaryButtonClick(transaction, ownListing);
              }
            }}
          >
            <FormattedMessage id={getTransitButtonLabelId(transitionNames[1])} />
          </SecondaryButton>
        ) : null}
        <PrimaryButton
          id={
            txIWaitingRequestPickup(transaction) ||
            txIWaitingRequestPickupLTF(transaction) ||
            txIsBeingPickedUp(transaction) ||
            txIsBeingPickedUpLTF(transaction) ||
            txIWaitingRequestDropoff(transaction) ||
            txIWaitingRequestDropoffLTL(transaction) ||
            txIsBeingDropOff(transaction)
              ? 'actionTransitButton'
              : null
          }
          inProgress={transitInProgress}
          disabled={buttonsDisabled || !transitAllow || disabledActions}
          name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
          onClick={() => {
            handlePrimaryButtonClick(transaction, transaction, ownListing);
          }}
        >
          <FormattedMessage id={getTransitButtonLabelId(transitionNames[0])} />
        </PrimaryButton>
      </div>
      {(txIWaitingRequestPickup(transaction) || txIWaitingRequestPickupLTF(transaction)) && (
        <div className={css.noCarStatusPhotoUpload}>
          <div
            onClick={() => {
              props.handleOpenStatusModal('openCarStatusModal');
            }}
          >
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload" />
          </div>
        </div>
      )}
      {(txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) && (
        <div className={css.noCarStatusPhotoUpload}>
          <div
            onClick={() => {
              props.handleOpenStatusModal('openCarStatusModal');
            }}
          >
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload2" />
          </div>
        </div>
      )}
      {txIWaitingRequestDropoff(transaction) && (
        <div className={css.noCarStatusPhotoUpload}>
          <div
            onClick={() => {
              props.handleOpenStatusModal('openCarStatusModal');
            }}
          >
            <FormattedMessage id="TransactionPanel.noCarDropOffStatusPhotoUpload" />
          </div>
        </div>
      )}
      {txIsBeingDropOff(transaction) && (
        <div className={css.noCarStatusPhotoUpload}>
          <div
            onClick={() => {
              props.handleOpenStatusModal('openCarStatusModal');
            }}
          >
            <FormattedMessage id="TransactionPanel.noCarDropOffStatusPhotoUpload2" />
          </div>
        </div>
      )}
    </div>
  ) : null;
};
