import React from 'react';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import css from '../LongTermRentalListingPage.css';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemInsuranceMaybe = props => {
  const { insuranceCost, intl } = props;
  const insuranceCostPrice = new Money(insuranceCost * 100, config.currency);

  return (
    <div>
      <span className={css.priceTitle}>Insurance & maintenance</span>
      <span className={css.priceTitle}>{insuranceCost <= 0 ? 'Included' : formatMoney(intl, insuranceCostPrice, 1)}</span>
    </div>
  );
};

LineItemInsuranceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemInsuranceMaybe;
