import React, { useState } from 'react';
import * as validators from '../../util/validators';
import { FieldTextInput } from '../../components';
import classNames from 'classnames';

import css from './SignupForm.css';
import EyeIcon from '../../assets/eye-solid.svg';

const SignUpField = props => {
  const [showPassword, setShowPassword] = useState(false);
  const showHidePassword = () => setShowPassword(!showPassword);
  const { intl, formId, values, touched, errors } = props;

  const invalidPassword = touched.password && errors.password;
  let codeClasses = invalidPassword ? classNames(css.code, css.invalidPassword) : css.code;
  // password
  const passwordLabel = intl.formatMessage({
    id: 'SignupForm.passwordLabel',
  });
  const passwordPlaceholder = intl.formatMessage({
    id: 'SignupForm.passwordPlaceholder',
  });
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  // firstName
  const firstNameLabel = intl.formatMessage({
    id: 'SignupForm.firstNameLabel',
  });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.firstNamePlaceholder',
  });
  const firstNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.firstNameRequired',
  });
  const firstNameRequired = validators.required(firstNameRequiredMessage);

  // lastName
  const lastNameLabel = intl.formatMessage({
    id: 'SignupForm.lastNameLabel',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'SignupForm.lastNamePlaceholder',
  });
  const lastNameRequiredMessage = intl.formatMessage({
    id: 'SignupForm.lastNameRequired',
  });
  // const genderRequiredMessage = intl.formatMessage({
  //   id: 'SignupForm.genderNameRequired',
  // });

  //Code

  const codeLabel = intl.formatMessage({
    id: 'SignupForm.codeLabel',
  });
  const codePlaceholder = intl.formatMessage({
    id: 'SignupForm.codePlaceholder',
  });

  const lastNameRequired = validators.required(lastNameRequiredMessage);

  // const genderRequired = validators.required(genderRequiredMessage);

  const required = validators.required(intl.formatMessage({ id: 'SignupForm.dobRequired' }));
  const minAge = 21;
  const minAgeMessage = intl.formatMessage({ id: 'SignupForm.minAgeRequired' }, { minAge });
  const minAgeRequired = validators.ageAtLeast(minAgeMessage, minAge);

  return (
    <div>
      <div className={css.name}>
        <FieldTextInput
          className={css.formInput}
          type="text"
          id={formId ? `${formId}.fname` : 'fname'}
          name="fname"
          autoComplete="given-name"
          label={firstNameLabel}
          placeholder={firstNamePlaceholder}
          validate={firstNameRequired}
          errorClassName={css.errorRoot}
        />
        <div className={css.centerBlock}></div>
        <FieldTextInput
          className={css.formInput}
          type="text"
          id={formId ? `${formId}.lname` : 'lname'}
          name="lname"
          autoComplete="family-name"
          label={lastNameLabel}
          placeholder={lastNamePlaceholder}
          validate={lastNameRequired}
          errorClassName={css.errorRoot}
        />
      </div>

      <div className={css.inputContainer}>
        <span className={css.eyeIcon} onClick={showHidePassword}>
          <img src={EyeIcon} alt="Alt text" />
        </span>
        <FieldTextInput
          className={css.formInput}
          type={showPassword ? 'text' : 'password'}
          id={formId ? `${formId}.password` : 'password'}
          name="password"
          autoComplete="new-password"
          label={passwordLabel}
          placeholder={passwordPlaceholder}
          validate={passwordValidators}
        />
      </div>
    </div>
  );
};

SignUpField.defaultProps = {};

SignUpField.propTypes = {};

export default SignUpField;
