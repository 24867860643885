import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';
import hostImage from '../../assets/cancelPolicy/host.png';
import guestImage from '../../assets/cancelPolicy/guest.png';
import css from './ModalCancelPolicy.css';

class ModalCancelPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
    };
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      showPolicyModal,
      onClose,
      isLongTerm,
      transactionRole,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const content =
      transactionRole === 'customer' ? (
        <div className={css.container}>
          <h2 className={css.text}>Cancellation Policy</h2>
          <p>
            We do understand that guests can have a change in plan due to unforeseen circumstances.
            In that case, we want to be fair to all parties involved. There are specific rules for
            cancellation depending upon the type of booking it is (regular or instant) and the time
            of cancellation (vs scheduled trip time). Please note that for all cancellations, the
            processing fees is not refundable.
          </p>
          <p>
            <strong>GUEST CANCELLATION</strong>
          </p>
          <p>
            Guests may cancel their trip through our site or mobile app, and the cancellation is
            effective immediately. The total amount refunded will depend on when the guest cancels
            the trip. See breakdown below:
          </p>
          <img src={guestImage} alt="" />
          <p>80% of the penalty will be given to the host via credits</p>
          <p>
            <strong> CANCELLATION BY Drive mate</strong>
          </p>
          <p>
            In some cases, Drive mate may need to cancel a trip after booking to protect our users
            against fraud or for trust and safety considerations. Drive mate will notify both hosts
            and guests in the event Drive mate must cancel a trip. Where Drive mate must cancel a
            trip, guests will receive a full refund.
          </p>
          <p>
            Please note that for exceptional circumstances, Drive mate reserves the right to allow
            certain cancellations without any penalty to the guest or host. These circumstances are
            purely at the discretion of Drive mate and are meant to protect the interests of guests
            &amp; hosts.
          </p>
          {/* <p>If the guest cancels:</p>

          <ol>
            <li>
              If within 1 hour of the booking request is accepted, then full refund (minus the
              processing fees) is provided to the guest&nbsp;
            </li>
            <li>
              If more than 72 hours from the scheduled pick up time, then full refund (minus the
              processing fees)is provided to the guest&nbsp;
            </li>
            <li>
              If less than 72 hours from the scheduled pick up time, then no refund is provided to
              the guest. 80% of the trip cost is provided to the host.
            </li>
          </ol> */}
        </div>
      ) : transactionRole === 'provider' ? (
        <div className={css.container}>
          <h2 className={css.text}>Cancellation Policy</h2>
          <p>
            We do understand that hosts can have a change in plan due to unforeseen circumstances.
            In that case, we want to be fair to all parties involved. There are specific rules for
            cancellation depending upon the type of booking it is (regular or instant) and the time
            of cancellation (vs scheduled trip time). Please note that for all cancellations, the
            processing fee is not refundable.
          </p>
          <p>
            <strong>HOST CANCELLATION</strong>
          </p>

          <p>
            When hosts rent out their car through Drive mate and have accepted the booking (or have
            instant booking turned on), it is assumed to be available for the guest. For this system
            to work, guests need to have confidence that their booking is firm and that they will
            not be left without transport if the host suddenly changes their plans.
          </p>

          <p>If hosts cancel a booking, then:</p>

          <ul>
            <li>• the guest will be annoyed and unlikely to book your car again</li>
            <li>
              • we may charge the host in order to help compensate the guest for the inconvenience
              and for finding alternative transport (up to $60)
            </li>
          </ul>
          <p>
            The following fees apply to bookings cancelled by hosts, including where the host fails
            to make their car or key available for a booking, because he is e.g. unreachable at the
            time of handover. 80% of this fee is passed on to the guest to make up for the
            inconvenience.
          </p>
          <img src={hostImage} alt="" />
          <ol>
            <li>
              For <strong>instant booking trips</strong>, if a booking is received between 10 pm
              &amp; 9 am, then hosts have up to 9 am to cancel. This also is allowed up to once per
              month
              {/* If host cancels within 12 hours of accepting the booking request, then there
              will be no charge. Pls note that for hosts who have&nbsp;
              <strong>instant booking enabled</strong>, this point is not applicable. They will be
              charged for cancellation ($20) if they do not provide a valid reason even if they
              cancel within 12 hours. 80% of the levy will be paid to the guest&nbsp; */}
            </li>
            <li>
              Full refund will be provided to the guest. Processing fees will also be refunded.
            </li>
            <li>
              In case of <strong>no show</strong> by the host, a penalty of $100 will apply. No show
              is determined if the host is not contactable either by the guest or customer service
              less than 1 hour before trip start time.
            </li>
            <li>
              80% of the penalty will be given to the guest via credits except if the trip start
              time is within 24 hours of the booking accepted.
            </li>
          </ol>
          <p>
            <strong>CANCELLATION BY Drive mate</strong>
          </p>
          <p>
            In some cases, Drive mate may need to cancel a trip after booking to protect our users
            against fraud or for trust and safety considerations. Drive mate will notify both hosts
            and guests in the event Drive mate must cancel a trip. Where Drive mate must cancel a
            trip, hosts are generally not eligible for earnings.
          </p>
          <p>
            Please note that for exceptional circumstances, Drive mate reserves the right to allow
            certain cancellations without any penalty to the guest or host. These circumstances are
            purely at the discretion of Drive mate and are meant to protect the interests of guests &
            hosts.
          </p>
          {/* <p>
            If a host wishes to cancel a booked trip anyhow, they must notify the guest via on-site
            messaging as soon as possible, and process the cancellation through the Drive mate
            website or mobile app. The cancellation is effective immediately, and the guest receives
            a complete refund plus possible charges.&nbsp;
          </p>

          <p>
            All additional payouts to the guest as a result of the host cancellations will be paid
            in the form of credits.
          </p>

          <p>
            <strong>INSTANT BOOKING</strong>
          </p>

          <p>
            For hosts who cancel an instant booking trip, they will be charged cancellation fees.
            However, if they have a valid reason for cancelling the trip (to be decided by Drive
            lah), they need to inform Drive mate within 12 hours of the booking confirmed. Post
            this,&nbsp;
            <strong>Drive mate will take a decision</strong>&nbsp;on whether the cancellation fees
            will apply or not.
          </p>

          <p>
            <strong>CANCELLATION BY Drive mate</strong>
          </p>

          <p>
            In some cases, Drive mate may need to cancel a trip after booking to protect our users
            against fraud or for trust and safety considerations. Drive mate will notify both hosts
            and guests in the event Drive mate must cancel a trip. Where Drive mate must cancel a
            trip, guests will receive a full refund and hosts are generally not eligible for
            earnings.
          </p> */}
        </div>
      ) : (
        ''
      );
    const longTermContent = (
      <div className={css.container}>
        <h2 className={css.text}>Cancellation Policy</h2>
        <p>
          We do understand that hosts &amp; guests can have a change in plan due to unforeseen
          circumstances. In that case, we want to be fair to all parties involved. There are
          specific rules for cancellation depending upon the type of booking it is (regular or
          instant) and the time of cancellation (vs scheduled trip time). Please note that for all
          cancellations, the processing fees is not refundable.&nbsp;
        </p>

        <p>
          <strong>GUEST CANCELLATION</strong>
        </p>

        <p>
          Guests may cancel their trip through our site or mobile app, and the cancellation is
          effective immediately.
        </p>

        <p>
          <strong>Before the trip starts:</strong>
        </p>

        <ol>
          <li>
            If the booking is cancelled 72 hours before the start of the trip, the guest will be
            charged 4% cancellation fee of the payable amount &nbsp;
          </li>
          <li>
            If the booking is cancelled within 72 hours before the start of the trip, the guest will
            be charged rental for 7 days based on the daily rate and 4% cancellation fee of the
            payable amount &nbsp;
          </li>
          <li>The host will get 80% of the amount charged to the guest</li>
        </ol>

        <p>
          <strong>After the trip starts:</strong>
        </p>

        <ol>
          <li>
            If the rental is cancelled withing the first two months, then the Trip price will be
            calculated based on the listings daily price plus the Drive mate fee multiplied by the
            days car was rented. Based on this cost, either the balance amount will be refunded to
            the users card or charged &nbsp;
          </li>
          <li>
            After the two month period, user can cancel the trip anytime with a 7 day notice. The
            due amount will be prorated as per the number of days (not paid for yet) of rental based
            on the monthly price &nbsp;
          </li>
          <li>If the 7 day notice is not given, the user will be charged for the entire month.</li>
        </ol>

        <p>
          <strong>HOST CANCELLATION</strong>
        </p>

        <p>
          <strong>Before the trip starts:</strong>
        </p>

        <ol>
          <li>
            {' '}
            If the host cancels the booking before 72 hours, then the host will pay $150 + 4% of
            payable amount. 80% of the amount will go to the guest &nbsp;
          </li>
          <li>
            {' '}
            If the host cancels 72 hours before the trip start time, then the host will pay $300 +
            4% of payable amount. 80% of the amount will go to the guest &nbsp;
          </li>
        </ol>

        <p>
          <strong>After the trip starts:</strong>
        </p>
        <p>
          Host cancellation for long term rental is not permitted. Talk to our support team if there
          are any questions.
        </p>
      </div>
    );

    const closeButtonMessage = <FormattedMessage id="ModalCancelPolicy.close" />;

    return (
      <Modal
        id="ModalCancelPolicy"
        containerClassName={containerClassName}
        isOpen={showPolicyModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
      >
        {isLongTerm ? longTermContent : content}
      </Modal>
    );
  }
}

ModalCancelPolicy.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
};

ModalCancelPolicy.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,

  onManageDisableScrolling: func.isRequired,
  onClose: func.isRequired,
  showPolicyModal: bool.isRequired,
};

ModalCancelPolicy.displayName = 'ModalCancelPolicy';

export default ModalCancelPolicy;
