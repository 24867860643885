import React from 'react';

const FlexLogo = ({ width = 219.499, height = 78, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 219.499 78"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_154"
            data-name="Rectangle 154"
            width={width}
            height={height}
            fill="none"
          />
        </clipPath>
        <filter
          id="Path_364"
          x="-8.872"
          y="7.83"
          width="99.055"
          height="58.874"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_365"
          x="6.235"
          y="32.711"
          width="64.309"
          height="39.459"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_366"
          x="198.503"
          y="7.83"
          width="23.147"
          height="24.044"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-3" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_367"
          x="203.995"
          y="7.83"
          width="24.504"
          height="24.044"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-4" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_368"
          x="90.99"
          y="31.924"
          width="25.639"
          height="39.566"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-5" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_369"
          x="87.135"
          y="7.83"
          width="38.687"
          height="37.974"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-6" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_370"
          x="112.617"
          y="7.83"
          width="25.638"
          height="63.661"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-7" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-7" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_371"
          x="163.674"
          y="20.029"
          width="49.291"
          height="51.934"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-8" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-8" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_372"
          x="181.74"
          y="39.597"
          width="31.331"
          height="32.269"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-9" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-9" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_373"
          x="163.567"
          y="20.128"
          width="31.333"
          height="32.269"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-10" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-10" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_376"
          x="125.974"
          y="19.651"
          width="53.267"
          height="52.52"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-11" />
          <feFlood flood-opacity="0.102" />
          <feComposite operator="in" in2="blur-11" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_2427" data-name="Group 2427" transform="translate(-133)">
        <g
          id="Group_1709"
          data-name="Group 1709"
          transform="translate(133)"
          clip-path="url(#clip-path)"
        >
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_364)">
            <path
              id="Path_364-2"
              data-name="Path 364"
              d="M77.928,20.764c-3.638-5.346-9.3-8.515-17.316-9.687-1.317-.193-2.023-.343-2.222-.625A38.436,38.436,0,0,0,54.9,6.143,18.23,18.23,0,0,0,43.275.419,57.567,57.567,0,0,0,30.646.287C21.142,1.328,15.6,5.425,13.664,7.138a31.717,31.717,0,0,0-7.7,9.468,33.815,33.815,0,0,0-1.71,3.8c-.214.542-.4,1.021-.571,1.366l-.149.115A7.534,7.534,0,0,0,1.186,24.6c-.9,1.782-1.11,5.434-1.154,6.51-.123,3.033.033,6.069,2,7.735l.142.121.16.1a15.964,15.964,0,0,0,7.648,1.81c.575,0,1.176-.028,1.8-.09l1.834-.183A11.7,11.7,0,0,1,12.5,36.244l-1.162.116a12.636,12.636,0,0,1-6.509-1.006c-.121-.258-.466-1.254-.351-4.06A17.267,17.267,0,0,1,5.157,26.6a3.232,3.232,0,0,1,1.081-1.188,4.174,4.174,0,0,0,1.274-1.374,18.842,18.842,0,0,0,.879-2.012A29.785,29.785,0,0,1,9.878,18.71a27.638,27.638,0,0,1,6.73-8.239C18.23,9.039,22.892,5.61,31.131,4.708a53.144,53.144,0,0,1,11.647.13,13.9,13.9,0,0,1,8.87,4.342,34.22,34.22,0,0,1,3.108,3.835c1.336,1.895,3.4,2.2,5.212,2.462,6.806,1,11.344,3.471,14.283,7.789a12.321,12.321,0,0,1,2.342,7.475,4.467,4.467,0,0,1-.856,2.614c-.746.844-2.111,1.1-3.577,1.348H64.007c.024.3.038.6.038.9a11.924,11.924,0,0,1-.537,3.545h9.023l.183-.031c2.02-.339,4.535-.762,6.354-2.819A8.529,8.529,0,0,0,81.032,31a16.766,16.766,0,0,0-3.1-10.236"
              transform="translate(0.13 15.83)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_365)">
            <path
              id="Path_365-2"
              data-name="Path 365"
              d="M76.263,59.411a11.884,11.884,0,0,0-11.244-.219,19.329,19.329,0,0,0-5.763,4.938l-.046.054.045.055c2.761,3.365,2.761,3.365,2.8,3.368l.043,0,.027-.034c2.269-2.74,4.983-5.664,8.494-5.664a6.532,6.532,0,1,1-2.411,12.6c-3.148-1.251-5.522-3.9-7.5-6.286a47.864,47.864,0,0,0-7.548-7.717c-.342-.263-.7-.506-1.051-.74l-.3-.2A10.7,10.7,0,1,0,40.136,77.488a11.926,11.926,0,0,0,11.124.279,18.627,18.627,0,0,0,5.884-5l.046-.054-.045-.055c-2.761-3.364-2.761-3.364-2.8-3.367l-.042,0-.028.033c-2.269,2.741-4.982,5.665-8.495,5.665a6.532,6.532,0,1,1,2.411-12.6c2.819,1.12,5.032,3.4,6.825,5.478q.572.663,1.139,1.334a58.279,58.279,0,0,0,5.3,5.7l.068.062a17.784,17.784,0,0,0,3.061,2.372A10.7,10.7,0,1,0,76.263,59.411"
              transform="translate(-19.81 -17.01)"
              fill="#ffcd05"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_366)">
            <path
              id="Path_366-2"
              data-name="Path 366"
              d="M383.139,23.085h-1.369a.411.411,0,0,1-.464-.465v-.338a.411.411,0,0,1,.464-.465h4.218a.411.411,0,0,1,.465.465v.338a.411.411,0,0,1-.465.465h-1.369V27.4a.411.411,0,0,1-.465.465H383.6a.412.412,0,0,1-.466-.465Z"
              transform="translate(-173.8 -5.99)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_367)">
            <path
              id="Path_367-2"
              data-name="Path 367"
              d="M389.3,22.265a.436.436,0,0,1,.481-.448h.761a.51.51,0,0,1,.525.38l.752,2.172c.034.1.068.2.105.317s.069.224.1.325c.034.118.068.237.1.355h.017c.035-.118.068-.237.1-.355.028-.1.06-.21.1-.325s.072-.221.106-.317l.752-2.172a.51.51,0,0,1,.525-.38h.76a.436.436,0,0,1,.482.448l.414,5.113a.455.455,0,0,1-.1.356.439.439,0,0,1-.342.127h-.55a.436.436,0,0,1-.481-.448l-.152-2.274c-.012-.112-.019-.229-.022-.35s0-.23.005-.326a3.3,3.3,0,0,1,.017-.337h-.017c-.04.123-.079.245-.119.363-.034.1-.071.211-.11.33s-.077.225-.11.321l-.5,1.378a.52.52,0,0,1-.533.371h-.474a.52.52,0,0,1-.532-.371l-.5-1.378c-.035-.1-.071-.2-.11-.321s-.077-.229-.11-.33q-.061-.177-.12-.363h-.017q0,.169.009.337c0,.1,0,.2,0,.326a2.908,2.908,0,0,1-.013.35l-.152,2.274a.436.436,0,0,1-.482.448h-.558a.436.436,0,0,1-.342-.127.455.455,0,0,1-.1-.356Z"
              transform="translate(-175.88 -5.99)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_368)">
            <path
              id="Path_368-2"
              data-name="Path 368"
              d="M236.947,76.591a3.824,3.824,0,0,1-3.819-3.819V55.025h7.639V72.772a3.824,3.824,0,0,1-3.82,3.819"
              transform="translate(-133.14 -15.1)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_369)">
            <path
              id="Path_369-2"
              data-name="Path 369"
              d="M231.305,41.791a3.492,3.492,0,0,1,0-6.984h.364V29.749a7.941,7.941,0,0,1,7.933-7.932h3.611A5.047,5.047,0,0,1,248.5,26.84v.074a3.334,3.334,0,0,1-.99,2.367,3.222,3.222,0,0,1-2.251.972,2.875,2.875,0,0,1-1.243-.284,5.27,5.27,0,0,0-2.2-.626c-1.686,0-2.506,1.575-2.506,4.814v.649h2.247a3.492,3.492,0,0,1,0,6.984Z"
              transform="translate(-131.68 -5.99)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_370)">
            <path
              id="Path_370-2"
              data-name="Path 370"
              d="M266.754,67.478a3.824,3.824,0,0,1-3.819-3.819V25.636a3.819,3.819,0,0,1,7.638,0V63.658a3.824,3.824,0,0,1-3.819,3.819"
              transform="translate(-141.32 -5.99)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_371)">
            <path
              id="Path_371-2"
              data-name="Path 371"
              d="M337.12,72.565a3.819,3.819,0,0,1-2.837-6.373l23.654-26.3a3.819,3.819,0,0,1,5.679,5.109L339.961,71.3a3.828,3.828,0,0,1-2.84,1.265Z"
              transform="translate(-160.63 -10.6)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_372)">
            <path
              id="Path_372-2"
              data-name="Path 372"
              d="M367.717,79.869a3.824,3.824,0,0,1-2.819-1.242l-6.7-7.322,5.133-5.705,7.2,7.873a3.819,3.819,0,0,1-2.817,6.4"
              transform="translate(-167.46 -18)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_373)">
            <path
              id="Path_373-2"
              data-name="Path 373"
              d="M334.156,45.161a3.819,3.819,0,0,1,5.636-5.154l6.7,7.322-5.132,5.706Z"
              transform="translate(-160.59 -10.64)"
              fill="#fff"
            />
          </g>
          <path
            id="Path_374"
            data-name="Path 374"
            d="M330.343,61.412c0,.118.009.234.009.352v-.172c0-.061-.007-.12-.009-.18"
            transform="translate(-159.817 -16.853)"
            fill="#fff"
          />
          <path
            id="Path_375"
            data-name="Path 375"
            d="M330.348,62.357V61.9c0,.276-.009.55-.021.822a3.5,3.5,0,0,0,.021-.362"
            transform="translate(-159.812 -16.987)"
            fill="#fff"
          />
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_376)">
            <path
              id="Path_376-2"
              data-name="Path 376"
              d="M298.977,72.629A17.264,17.264,0,1,1,316.6,55.022s.009.279.009.348c0,.271-.009.54-.021.807A2.961,2.961,0,0,1,313.64,58.8H300.374a2.973,2.973,0,0,1-2.97-2.969V55.2a2.974,2.974,0,0,1,2.97-2.97h8.208l-.155-.4a9.8,9.8,0,0,0-9.148-6.4,10.149,10.149,0,0,0,0,20.289,13.478,13.478,0,0,0,7.352-2.095,3.925,3.925,0,0,1,2.243-.723,4.585,4.585,0,0,1,4.1,2.9,1.746,1.746,0,0,1-.535,2.058c-4.168,3.092-7.549,4.769-13.466,4.769"
              transform="translate(-146.37 -10.46)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FlexLogo;
