import React from 'react';
import { Button, NamedLink } from '../../components';
import css from './DonationForm.css';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { saveVaccineCertificate } from '../../containers/VaccineCredits/VaccineCredits.duck';
import driveTogetherLogo from '../../assets/donationPage/drive-together-logo.svg';
import { get } from 'lodash';

const DonationForm = ({ setIsDonateModalOpen, currentUser }) => {
  const handleSubmit = e => {
    e.preventDefault();
  };

  const isCurrentUser = get(currentUser, 'id.uuid', '');
  const donateButton = isCurrentUser ? (
    <Button
      className={css.donateSubmit}
      type="submit"
      onClick={() => {
        setIsDonateModalOpen(true);
      }}
    >
      Donate
    </Button>
  ) : (
    <NamedLink name="LoginPage" className={css.donateSubmit}>
      Login
    </NamedLink>
  );

  return (
    <form className={classNames(css.root, css.donationForm)} onSubmit={e => handleSubmit(e)}>
      <div className={css.wrapper}>
        <div className={css.formTitle}>
          <div className={css.logoThumb}>
            <img src={driveTogetherLogo} alt="" />
          </div>
          <h4>Donate to help the people of Ukraine</h4>
        </div>
        {/* <div className={classNames(css.customButtons, css.fields)}>
          <div className={css.customRadio}>
            <input type="radio" name="donationAmount" id="amount1" />
            <label htmlFor="amount1">$5</label>
          </div>
          <div className={css.customRadio}>
            <input type="radio" name="donationAmount" id="amount2" />
            <label htmlFor="amount2">$10</label>
          </div>
          <div className={css.customRadio}>
            <input type="radio" name="donationAmount" id="amount3" />
            <label htmlFor="amount3">$25</label>
          </div>
        </div>
        <div className={css.amountInput}>
          <label className={css.amountLabel}>Other amount: $</label>
          <input />
        </div> */}
      </div>
      <div className={css.buttonWrapper}>{donateButton}</div>
    </form>
  );
};
const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onVaccinationCertificateUpload: data => dispatch(saveVaccineCertificate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
