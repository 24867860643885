import React from 'react';
import css from './PropertyList.css';
import classNames from 'classnames';

const Item = props => {
  const { label, ...rest } = props;

  return (
    <div {...rest} className={css.item}>{label}</div>
  )
}

const PropertyList = props => {
  const { rootClassName, className, id, list, twoColumns } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  return (
    <ul className={listClasses}>
      {list.map(option => (
        <Item key={`${id}.${option.key}`} label={option.label} />
      ))}
    </ul>
  );
};

export default PropertyList;