import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { FieldTextInput, FieldUploadFile, Form } from '../../components';

import css from './NRICSettingForm.css';

class NRICSettingFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser
    } = this.props;

    const initialUrls={
      nricFnFrontUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.residencyNumberFront,
      nricFnBackUrl: !currentUser ? null : currentUser.attributes.profile.protectedData.residencyNumberBack,
    }

    let newNRICFn = !currentUser || !currentUser.attributes.profile.protectedData.residencyNumber ? '' : currentUser.attributes.profile.protectedData.residencyNumber;
    if (newNRICFn && newNRICFn.length > 3) {
      let nricFnTemp = newNRICFn[0];
      for (let i = 1; i < newNRICFn.length - 2; i++) {
        nricFnTemp = nricFnTemp + '*'
      }
      nricFnTemp = nricFnTemp + newNRICFn[newNRICFn.length - 2] + newNRICFn[newNRICFn.length - 1];
      newNRICFn = nricFnTemp
    }

    return (
      <FinalForm
        {...this.props}
        initialValues={{
          nricFn:  newNRICFn,
        }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            handleSubmit,
            formId,
            intl,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(rootClassName || css.root, className);

          const nricFnMessage = intl.formatMessage({ id: 'EditListingVerificationForm.nricFn' });
          const nricFnPlaceholderMessage = intl.formatMessage({
            id: 'EditListingVerificationForm.nricFnPlaceholder',
          });

          return (
            <Form
              className={classes}
              onSubmit={e => {

              }}
            >
              <FieldTextInput
                id="nricFn"
                name="nricFn"
                className={css.inputField}
                type="textarea"
                label={nricFnMessage}
                placeholder={nricFnPlaceholderMessage}
                disabled={true}
              />

              <FieldUploadFile
                id='file-upload-front-nric-fin'
                name='file-upload-front-nric-fin'
                label={() => 'Front copy'}
                typeFile='nric-fin-front'
                setStateUploadData={() => {}}
                currentUser={currentUser}
                setUploadState={() => {}}
                initialValues={[
                  initialUrls.nricFnFrontUrl
                ]}
                hideUpload={true}
              />

              <FieldUploadFile
                id='file-upload-back-nric-fin'
                name='file-upload-back-nric-fin'
                label={() => 'Back copy'}
                typeFile='nric-fin-back'
                setStateUploadData={() => {}}
                currentUser={currentUser}
                className={css.bottomField}
                setUploadState={() => {}}
                initialValues={[
                  initialUrls.nricFnBackUrl
                ]}
                hideUpload={true}
              />
            </Form>
          );
        }}
      />
    );
  }
}

NRICSettingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

const { bool, func, string } = PropTypes;

NRICSettingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  intl: intlShape.isRequired,
};

const NRICSettingForm = compose(injectIntl)(NRICSettingFormComponent);

NRICSettingForm.displayName = 'NRICSettingForm';

export default NRICSettingForm;
