import React, { useState } from 'react';
import classNames from 'classnames';
import css from './GrabVoucherPromoLandingPage.css';
import { GrabVoucherPromoForm } from '../../forms';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import { IconSpinner, Modal } from '../../components';
import { EVENT_BROWSE_CLICKED_SIGNUP_LANDING_PAGE } from '../../util/gtm/gtmConstants';
import { initiateEventViewedFromLandingPage } from '../../util/gtm/gtmHelpers';

const { Money } = types;
const grabCreditCode = 'GRABVOUCHER';
const CheckMark = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.778}
      height={14}
      viewBox="0 0 18.778 14"
      {...props}
    >
      <path
        data-name="Path 332"
        d="M8.5 13.361a2.181 2.181 0 01-3.084 0L.639 8.586a2.18 2.18 0 013.084-3.083L6.57 8.349a.552.552 0 00.779 0L15.057.641a2.18 2.18 0 113.083 3.081z"
        fill="#fecd2a"
      />
    </svg>
  );
};

const Content = ({ className }) => {
  return (
    <div className={classNames(css.heroContent, className)}>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Hundreds of cars near you to choose from</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          Fully flexible - pick up and drop off any time/day
        </div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Lock/unlock cars with your phone</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>30-40% more affordable</div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>Safe, sanitised and fully insured</div>
      </div>
    </div>
  );
};

const SectionHero = ({ isScoot, signUpCodeAmount, loading, onManageDisableScrolling }) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    // console.log("HELLLLLLLLLLLLLLOOOOOOOOOOOO", url);
    initiateEventViewedFromLandingPage({
      event: EVENT_BROWSE_CLICKED_SIGNUP_LANDING_PAGE,
    });
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
            state: {
              email,
              password,
              code,
            },
          }
        : {
            pathname: url,
            state: {
              email,
              password,
            },
          }
    );
  };

  return (
    <div className={css.sectionHero}>
      <h1 className={css.heroTitle}>Rent your neighbour’s car</h1>
      <Content className={css.heroContentMobile} />
      <div className={css.formWrapper}>
        <div>
          <h1 className={classNames(css.heroTitle, css.heroTitleDesk)}>
            Rent your neighbour’s car
          </h1>
          <Content className={css.heroContentDesk} />
        </div>
        <div className={css.formWrapperDesktop}>
          {loading ? (
            <div className={css.loadingWrapper}>
              <IconSpinner className={css.loadingSpinner} />
            </div>
          ) : currentUser && currentUser.id ? (
            <>
              <div className={css.signUpTitle}>
                {signUpCodeAmount && signUpCodeAmount > 0 ? (
                  <FormattedMessage
                    id={
                      isScoot
                        ? 'SignUpPromoLandingPage.scootSchemaTitle'
                        : 'SignUpPromoLandingPage.schemaTitle'
                    }
                    values={{
                      amount: signUpCodeAmount
                        ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                        : '$0',
                    }}
                  />
                ) : code === grabCreditCode ? (
                  <FormattedMessage id={'GrabPromoLandingPage.SignUpGrabTitle'} />
                ) : (
                  <FormattedMessage id={'SignUpPromoLandingPage.signUpNow'} />
                )}
              </div>

              <p className={css.loginDesc}>
                This promo is only available to new users. You will have to logout and create a new
                account.
              </p>
            </>
          ) : (
            <>
              <div className={css.signUpTitle}>
                {signUpCodeAmount && signUpCodeAmount > 0 ? (
                  <FormattedMessage
                    id={
                      isScoot
                        ? 'SignUpPromoLandingPage.scootSchemaTitle'
                        : 'SignUpPromoLandingPage.schemaTitle'
                    }
                    values={{
                      amount: signUpCodeAmount
                        ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                        : '$0',
                    }}
                  />
                ) : code === grabCreditCode ? (
                  <FormattedMessage id={'GrabPromoLandingPage.SignUpGrabTitle'} />
                ) : (
                  <FormattedMessage id={'SignUpPromoLandingPage.signUpNow'} />
                )}
              </div>
              <GrabVoucherPromoForm
                openTermsConditionModal={() => setIsModalOpen(true)}
                onSubmit={handleSubmit}
              />
            </>
          )}
        </div>
      </div>
      <Modal
        id="campainTermsAndCondition"
        isOpen={!!isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={<FormattedMessage id="ModalMissingInformation.closeStaticModal" />}
      >
        <div className={css.insuranceListingWrapper}>
          <h3>T&amp;C for Grab Voucher Campaign</h3>
          <ul className={css.insurancePointsList}>
            <li>
              Only users who registered from 20.08.2021 to 31.08.2021 will be eligible for this
              campaign.
            </li>
            <li>Only the first 1,000 registered users will be eligible for the reward.</li>
            <li>
              The reward will be sent to the registered email address within 30 days from the end of
              the campaign.
            </li>
            <li>
              Drive mate reserves the right to amend these terms and conditions at any time without
              prior notice. In the event that any changes are made, the revised terms and conditions
              shall be posted on this website immediately.
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default SectionHero;
