import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getUserTxRole } from '../../util/transaction';
import { ensureUser } from '../../util/data';
import { BookingInfoSection, Logo, NamedLink, Page, PartnerInfo } from '../../components';
import { loadData } from './PoliciesPage.duck';

import css from './PoliciesPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const PoliciesPageComponent = props => {
  const {
    scrollingDisabled,
    transaction: parentTransaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
  } = props;

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [parentTransaction, dataLoaded]);


  const currentListing = parentTransaction && parentTransaction.listing;
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });

  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  const rentalAgreementLink = parentTransaction && parentTransaction.attributes && parentTransaction.attributes.metadata && parentTransaction.attributes.metadata.rentalAgreementFileLink;
  const certificateFileLink = parentTransaction && parentTransaction.attributes && parentTransaction.attributes.metadata && parentTransaction.attributes.metadata.certificateFileLink;

  console.log('parentTransaction.attributes.metadata')
  console.log(parentTransaction && parentTransaction.attributes && parentTransaction.attributes.metadata)

  const policiesSection = (
    <>

      <div className={css.policiesList}>
        {isCustomerRole &&  rentalAgreementLink && <a target='_blank' href={rentalAgreementLink} className={css.policiesItem}>
          <FormattedMessage id="TripPanel.rentalAgreementLink"/>
          <span className={css.arrowLink} />
        </a>}
        {certificateFileLink && <a target='_blank' href={certificateFileLink} className={css.policiesItem}>
          <FormattedMessage id="TripPanel.insuranceLink" />
          <span className={css.arrowLink} />
        </a>}
        <a target='_blank' href={isProviderRole ? `https://help.${DOMAIN_HELP_URL}/kb/guide/en/cancellation-policy-hosts-5QY0ZZ3WMP/Steps/755773` : `https://help.${DOMAIN_HELP_URL}/kb/guide/en/cancellation-policy-guests-vlJp9xGuCf/Steps/755741`} className={css.policiesItem}>
          <FormattedMessage id="TripPanel.policyLink" />
          <span className={css.arrowLink} />
        </a>
        <a className={css.policiesItem} target='_blank' href={`https://help.${DOMAIN_HELP_URL}/kb/en`}>
          <FormattedMessage id="TripPanel.faqLink" />
          <span className={css.arrowLink} />
        </a>
      </div>
    </>

  )


  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {parentTransaction && <NamedLink
                className={css.arrowBack}
                name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                params={{id: parentTransaction.id.uuid}}
              >
                <span className={css.arrowBackText}>&#8249;</span>
                Back
                {/* {listingTitle} */}
              </NamedLink>}
            </div>
            <h3 className={css.policiesSubTitle}>
              <FormattedMessage id="TripPanel.policiesTitle"/>
            </h3>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>
            {policiesSection}
          </div>

          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {parentTransaction && currentListing && (
                  <BookingInfoSection
                    transaction={parentTransaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={parentTransaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>

      </div>
    </Page>
  );
};

PoliciesPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

PoliciesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TripDetailsPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  return {
    currentUser,
    transaction,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const PoliciesPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PoliciesPageComponent);

PoliciesPage.loadData = loadData;

export default PoliciesPage;
