import React from 'react';
import css from './LandingPageNew.css';
import Modal from '../../components/Modal/Modal';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';

const InsuranceSummaryModal = props => {
  const { isModalOpen, setIsModalOpen, onManageDisableScrolling } = props;

  return (
    <div className={css.modalContainer}>
      <Modal
        isOpen={isModalOpen}
        closeButtonMessage=" "
        onClose={() => {
          setIsModalOpen(false);
        }}
        containerClassName={css.summaryContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.insuranceListWrapper}>
          <h3>Summary of the insurance policy</h3>
          <ul className={css.insurancePointsList}>
            <li>Comprehensive insurance throughout Australia</li>
            <li>Covers vehicle damage due to accident, fire or storm, or malicious damage</li>
            <li>Covers theft of vehicle</li>
            <li>$35 million cover for 3rd party damage</li>
            <li>
              Maximum damage liability of $3000 (additional $500 for drivers &#60; 24 years old)
            </li>
            <li>Policy applicable only for the approved driver during the period of rental</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect(null, mapDispatchToProps)(InsuranceSummaryModal);
