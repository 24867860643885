import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import css from './WarningAlert.css';
import IconClose from '../IconClose/IconClose';

export const WarningAlert = props => {
  const { show, onClose, children } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });

  return (
    <div className={classes}>
      <div className={css.closeBtn} onClick={onClose}>
        <IconClose rootClassName={css.iconRoot} />
      </div>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>{children}</p>
      </div>
    </div>
  );
};

const { bool, func, node } = PropTypes;

WarningAlert.propTypes = {
  show: bool.isRequired,
  onClose: func.isRequired,
  children: node.isRequired,
};
