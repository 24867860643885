import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { getUserTxRole } from '../../util/transaction';
import {
  ensureTransaction,
  ensureUser,
  getBookingConfig,
  restoreTransaction,
} from '../../util/data';
import BookingTimeForm from '../../forms/EditTripForm/BookingTimeForm';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import {
  initiateSpeculativeUpdateBooking,
  loadData,
  tripDatesSet,
  updateBookingClick,
} from './EditTripPage.duck';
import {
  $bookingTx,
  $estimatedTx,
  $estimatedTxError,
  $isEstimatedTxPending,
  $isShowEstimatedBreakdown,
  $isSubmitButtonDisable,
  $isSubmitButtonInProgress,
  $tripBookingDates,
  $tripDates,
  $updateBookingFormError,
} from './EditTripPage.selectors';

import css from './EditTripPage.css';
import { fetchTimeSlots, requestToUpdateBooking } from '../TransactionPage/TransactionPage.duck';
import { estimateBreakdown, resetCode } from '../ListingPage/ListingPage.duck';
import { REQUEST_TO_BOOK_BUTTON_ID } from '../../util/gtm/gtmConstants';
import { createSlug } from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;
const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const EditTripPageComponent = props => {
  const {
    tripDates,
    isSubmitButtonInProgress,
    isSubmitButtonDisable,
    isShowEstimatedBreakdown,
    updateBookingFormError,
    tripBookingDates,
    scrollingDisabled,
    transaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    timeSlots,
    monthlyTimeSlots,
    unitType,
    onSubmit,
    isOwnListing,
    onFetchTimeSlots,
    onResetCode,
    checkedCode,
    checkCodeErorr,
    onCheckingVoucher,
    checkCodeInProgress,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimateError,
    estimatedTx,
    initialDate,
    onRequestToUpdateBooking,
    location,
    onInitiateSpeculativeUpdateBooking,
    onTripDatesSet,
    onUpdateBookingClick,
  } = props;

  const [childTransaction, setChildTransaction] = useState({});

  const currentListing = transaction && transaction.listing;
  const listingTitle = currentListing && currentListing.attributes.title;
  const listingId = currentListing && currentListing.id;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isCustomer = transactionRole === 'customer';
  const isProvider = transactionRole === 'provider';
  const currentTransaction = restoreTransaction(ensureTransaction(transaction));

  const price =
    currentListing && currentListing.attributes ? currentListing.attributes.price : null;
  const transactionTimezone = transaction && transaction.attributes && transaction.attributes.protectedData && transaction.attributes.protectedData.transactionTimezone;
  const localTimeZone = getDefaultTimeZoneOnBrowser(transactionTimezone);
  const timeZone =
    currentListing &&
    currentListing.attributes.availabilityPlan &&
    currentListing.attributes.availabilityPlan.timezone;
  const listingParams = currentListing
    ? {
        slug: createSlug(currentListing && currentListing.attributes.title),
        id: currentListing && currentListing.id.uuid,
      }
    : null;
  const bookingConfig = currentListing ? getBookingConfig(currentListing) : null;
  const isNewCar = currentListing ? get(currentListing, 'attributes.metadata.isNewCar') : false;
  const isLongTerm = currentListing
    ? get(transaction, 'attributes.protectedData.isLongTermRental')
    : false;

  const startTime = tripBookingDates && new Date(tripBookingDates.start).getTime();
  const endTime = tripBookingDates && new Date(tripBookingDates.end).getTime();

  const initialValues = {
    bookingStartDate: { date: tripBookingDates.start },
    bookingEndDate: { date: tripBookingDates.end },
    bookingStartTime: startTime ? startTime.toString() : null,
    bookingEndTime: endTime ? endTime.toString() : null,
  };

  const currentProvider = transaction && ensureUser(transaction.provider);
  const currentCustomer = transaction && ensureUser(transaction.customer);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentUserRole =
    currentUser && currentUser.id && transaction && getUserTxRole(currentUser.id, transaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const getChildTransactionData = value => {
    const mapedLineItems = value.lineItems.map(item => {
      return {
        ...item,
        lineTotal: convertMoneyToNumber(item.lineTotal),
        unitPrice: convertMoneyToNumber(item.unitPrice),
      };
    });

    const childTransaction = {
      lineItems: mapedLineItems,
      bookingStart: moment(value.bookingStart).format('YYYY-MM-DD HH:mm:ss'),
      bookingEnd: moment(value.bookingEnd).format('YYYY-MM-DD HH:mm:ss'),
      payinTotal: convertMoneyToNumber(value.payinTotal),
      transactionId: value.transactionId,
      transaction: value.transaction,
    };

    setChildTransaction(childTransaction);
  };

  const onSubmitUpdateBooking = values => {
    onUpdateBookingClick(props.history);
    // return ;
    //
    // const {
    //   history,
    //   callSetInitialValues,
    //   onInitializeCardPaymentData,
    //   onResetCode,
    //   location,
    // } = props;
    //
    // const currentTransaction = ensureTransaction(transaction);
    // const { bookingStartTime, bookingEndTime, signupCredits, voucherCode, payForFuel } = values;
    // const bookingStart = timestampToDate(parseInt(bookingStartTime));
    // const bookingEnd = timestampToDate(parseInt(bookingEndTime));
    // const parentTransactionStartDate = transaction.booking.attributes.displayStart;
    // const parentTransactionEndDate = location && location.state && location.state.endDate;
    // const currentDiffHours = moment(bookingEnd).diff(moment(bookingStart), 'hours', true);
    // const parentTransactionDiffHours = moment(parentTransactionEndDate).diff(
    //   moment(parentTransactionStartDate),
    //   'hours',
    //   true
    // );
    // const diffHours = currentDiffHours - parentTransactionDiffHours;
    //
    // const currentTransition = txIsAcceptedRefundable(currentTransaction)
    //   ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE
    //   : txIsAcceptedNonRefundable(currentTransaction)
    //   ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE
    //   : txIsAcceptedAfterOneHour(currentTransaction)
    //   ? TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR
    //   : TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE;
    //
    // const updateBookingParams = {
    //   transition: currentTransition,
    //   transactionId: currentTransaction.id,
    //   listing: currentTransaction.listing,
    //   bookingStart,
    //   bookingEnd,
    //   protectedData: {
    //     childTransaction,
    //   },
    // };
    //
    // const routes = routeConfiguration();
    // // Customize checkout page state with current listing and selected bookingDates
    // const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    // const listing = currentTransaction.listing;
    // const initialValues = {
    //   listing,
    //   bookingData: {
    //     signupCredits,
    //     voucherCode,
    //     transactionId: currentTransaction.id,
    //     payForFuel: false,
    //     isPaidAmount: true,
    //     // paidAmount: -(currentTransaction.attributes.payinTotal.amount),
    //     diffHours,
    //     updateBookingParams: updateBookingParams,
    //   },
    //   bookingDates: {
    //     bookingStart: timestampToDate(bookingStartTime),
    //     bookingEnd: timestampToDate(bookingEndTime),
    //   },
    //   confirmPaymentError: null,
    // };
    //
    // callSetInitialValues(setInitialValues, initialValues);
    //
    // // Clear previous Stripe errors from store if there is any
    // onInitializeCardPaymentData();
    //
    // onResetCode();
    //
    // // Redirect to CheckoutPage
    // history.push(
    //   createResourceLocatorString(
    //     'CheckoutPage',
    //     routes,
    //     { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
    //     { searchPageParams: location.search },
    //     {}
    //   ),
    //   {
    //     updateBooking: true,
    //     transactionId: currentTransaction.id,
    //   }
    // );
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  const editTripSection = (
    <>
      <div className={css.titleContainer}>
        <h3 className={css.updateBookingTitle}>
          <FormattedMessage id="BookingPanel.updateBookingTitle" />
        </h3>
        <p className={css.updateBookingSubTitle}>
          <FormattedMessage id="BookingPanel.updateBookingSubTitle" />
        </p>
      </div>
      {transaction && transaction.booking ? (
        <BookingTimeForm
          tripDates={tripDates}
          isSubmitButtonInProgress={isSubmitButtonInProgress}
          isSubmitButtonDisable={isSubmitButtonDisable}
          isShowEstimatedBreakdown={isShowEstimatedBreakdown}
          updateBookingFormError={updateBookingFormError}
          currentUser={currentUser}
          timeSlots={timeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={unitType}
          onSubmit={onSubmitUpdateBooking}
          price={price}
          isOwnListing={isOwnListing}
          listingId={listingId}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          listing={currentListing}
          onResetCode={onResetCode}
          checkedCode={checkedCode}
          checkCodeErorr={checkCodeErorr}
          checkCodeInProgress={checkCodeInProgress}
          onCheckingVoucher={onCheckingVoucher}
          onEstimateBreakdown={onEstimateBreakdown}
          estimateBreakdownInProgress={estimateBreakdownInProgress}
          estimatedTx={estimatedTx}
          requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
          listingParams={listingParams}
          // onManageDisableScrolling={onManageDisableScrolling}
          // onReadInsurance={onReadInsurance}
          // onOpenRentalAgreement={onOpenRentalAgreement}
          bookingConfig={bookingConfig}
          initialDate={initialDate}
          estimateError={estimateError}
          initialValues={initialValues}
          localTimeZone={localTimeZone}
          isNewCar={isNewCar}
          isLongTerm={isLongTerm}
          // isLongTermBooking={isLongTerm || shouldShowPricePerMonth}
          transaction={transaction}
          updateBooking={true}
          onRequestToUpdateBooking={onRequestToUpdateBooking}
          getChildTransactionData={getChildTransactionData}
          location={location}
          onTripDatesSet={onTripDatesSet}
          onInitiateSpeculativeUpdateBooking={onInitiateSpeculativeUpdateBooking}
        />
      ) : (
        <div className={css.loadingTimeSlotWrapper}>
          <IconSpinner />
        </div>
      )}
    </>
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {transaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: transaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {listingTitle}
                </NamedLink>
              )}
            </div>
            <h3 className={css.policiesSubTitle}>
              <FormattedMessage id="TripPanel.editTripTitle" />
            </h3>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>{editTripSection}</div>

          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {transaction && currentListing && (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={transaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

EditTripPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

EditTripPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TripDetailsPage;
  const { currentUser } = state.user;
  const { monthlyTimeSlots } = state.TransactionPage;
  const { timeSlots } = state.ListingPage;

  const transaction = $bookingTx(state);
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  return {
    currentUser,
    transaction,
    monthlyTimeSlots,
    estimatedTx: $estimatedTx(state),
    estimateBreakdownInProgress: $isEstimatedTxPending(state),
    timeSlots,
    estimateError: $estimatedTxError(state),
    tripBookingDates: $tripBookingDates(state),
    updateBookingFormError: $updateBookingFormError(state),
    isShowEstimatedBreakdown: $isShowEstimatedBreakdown(state),
    isSubmitButtonDisable: $isSubmitButtonDisable(state),
    isSubmitButtonInProgress: $isSubmitButtonInProgress(state),
    tripDates: $tripDates(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onResetCode: () => dispatch(resetCode()),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onTripDatesSet: () => dispatch(tripDatesSet()),
    onInitiateSpeculativeUpdateBooking: dates => dispatch(initiateSpeculativeUpdateBooking(dates)),
    onUpdateBookingClick: history => dispatch(updateBookingClick(history)),
  };
};

const EditTripPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditTripPageComponent);

EditTripPage.loadData = loadData;

export default EditTripPage;
