import PropTypes, { object, shape } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '..';
import css from './TopbarSearchModal.css';
import LocationAutocompleteInput from '../LocationAutocompleteInput/LocationAutocompleteInput';
import { Field, Form } from 'react-final-form';
import SearchIcon from './SearchIcon';
import GeocoderGoogleMaps, {
  CURRENT_LOCATION_ID,
} from '../LocationAutocompleteInput/GeocoderGoogleMaps';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// import { setLandingImageType } from '../../ducks/UI.duck';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import { useHistory, useLocation } from 'react-router-dom';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import SingleMonthCalender from '../SingleMonthCalender/SingleMonthCalender';
import SwipeTimeSelector from '../SwipeTimeSelector/SwipeTimeSelector';
import { getSearchedPlaces, saveSearchedPlacesMobile } from '../../util/emailNotify';
import { withViewport } from '../../util/contextHelpers';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import AlertBox from '../AlertBox/AlertBox';
import { DEFAULT_TIMEZONE } from '../../util/dates';

export const defaultPredictions = (config.maps.search.suggestCurrentLocation
  ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
  : []
).concat(config.maps.search.defaults);
let DEFAULT_TIME_AND_DATES = {
  location: {},
  selectedDates: {
    startDate: moment(),
    endDate: moment(),
  },
  selectedTimes: {
    startTime: moment(),
    endTime: moment(),
  },
  isDefault: true,
};
export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;
export const defaultPredictionsWithoutCurrentLocation = [
  { id: CURRENT_LOCATION_ID, predictionPlace: {} },
];

const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap
const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};

const Header = ({ handleClickBack, children, className }) => {
  return (
    <div className={classNames(css.modalHeader, className)}>
      <div className={css.backButton} onClick={handleClickBack}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18.468" height="18" viewBox="0 0 18.468 18">
          <path
            id="arrow-left-solid"
            d="M10.566,54.444l-.915.915a.985.985,0,0,1-1.4,0L.241,47.351a.985.985,0,0,1,0-1.4l8.013-8.013a.985.985,0,0,1,1.4,0l.915.915a.99.99,0,0,1-.016,1.414L5.582,45H17.429a.987.987,0,0,1,.989.989v1.319a.987.987,0,0,1-.989.989H5.582l4.967,4.732A.983.983,0,0,1,10.566,54.444Z"
            transform="translate(0.05 -37.65)"
            fill="#FFFFFF"
          />
        </svg>
      </div>
      {children}
    </div>
  );
};

const TopbarSearchModal = ({ onManageDisableScrolling, viewport, currentPage }) => {
  const location = useLocation();
  const history = useHistory();
  const [selectedDuration, setSelectedDuration] = useState(null);
  const searchType = useSelector(state => state.UI.landingImageType);
  const currentSearchParams = useSelector(state => state.UI.searchParams);
  const { pathname, search } = location;
  const { searchStep, dates, hours, address, ...restSearch } = parse(search);
  const [step, setStep] = useState();
  const [selectedValues, setSelectedValues] = useState();
  const userSearchHistory = getSearchedPlaces();
  const handleClickBack = () => {
    if (searchStep === 'searchedResults') {
      history.push('/');
    } else if (step === 'location') {
      setStep('preview');
    } else if (step === 'preview') {
      setStep('searchedResults');
    } else if (step === 'dateTime') {
      setStep('preview');
    } else if (step === 'searchedResults') {
      history.push('/');
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    return () => {
      setSelectedValues({});
    };
  }, []);
  let content = null;
  if (selectedValues && selectedValues.selectedDates && selectedValues.selectedTimes) {
    //Do nothing
  } else if (dates && hours) {
    const currentLocation = address && address;
    const getDatesFromUrl = dates.split(',');
    const getHoursFromUrl = hours.split(',');
    const selectedValues = {
      selectedDates: {
        startDate: moment(getDatesFromUrl[0]),
        endDate: moment(getDatesFromUrl[1]),
      },
      selectedTimes: {
        startTime: moment(`${getDatesFromUrl[0]} ${getHoursFromUrl[0]}`),
        endTime: moment(`${getDatesFromUrl[1]} ${getHoursFromUrl[1]}`),
      },
    };
    setSelectedValues(prev => ({ ...prev, currentLocation, ...selectedValues }));
  }

  const _handleSearch = (selectedValues, view) => {
    let url = '';
    const { location } = selectedValues;
    const { prediction } = selectedValues;
    const { startDate, endDate } = selectedValues.selectedDates;
    const { startTime, endTime } = selectedValues.selectedTimes;
    switch (view) {
      case 'location':
        setStep(null);
        url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
          bounds: encodeLatLngBounds(
            location && location.bounds ? location.bounds : DEFAULT_BOUNDS
          ),
          dates: `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`,
          hours: `${startTime.format('HH:mm')},${endTime.format('HH:mm')}`,
          searchType: 'daily',
          address: location && location.address,
          origin: location && location.origin,
          searchStep: 'preview',
          mapSearch: false,
          timezone: location && location.timezone,
        });

        initiateEventFromListing({
          props: {
            searchParams: {
              ...currentSearchParams,
              bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
            },
          },
          event: EVENT_SEARCH_PERFORMED,
        });
        gSend(GTAG_ACTIONS.ACTION_SEARCH, {
          // TODO: Assign proper details.
          search_location: location && location.address,
          search_pickupdate: `${startDate.format('YYYY-MM-DD')}`,
          search_pickuptime: `${startTime.format('HH:mm')}`,
          search_dropoffdate: `${endDate.format('YYYY-MM-DD')}`,
          search_dropofftime: `${endTime.format('HH:mm')}`,
        });
        if (prediction && prediction.predictionPlace) {
          saveSearchedPlacesMobile(prediction.predictionPlace);
        } else {
          saveSearchedPlacesMobile(prediction);
        }
        history.push(url);
        break;
      default:
        break;
    }
  };

  switch (step || searchStep) {
    case 'location':
      content = (
        <LocationStep
          setStep={setStep}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          onSubmit={s => _handleSearch(s, 'location')}
          handleClickBack={handleClickBack}
          userSearchHistory={userSearchHistory}
          viewport={viewport}
          saveSearchedPlacesMobile={saveSearchedPlacesMobile}
        />
      );
      break;
    case 'preview':
      content = (
        <SearchPreview
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          handleClickBack={handleClickBack}
          onSearchSubmit={s => _handleSearch(s, 'preview')}
          setStep={setStep}
          saveSearchedPlacesMobile={saveSearchedPlacesMobile}
        />
      );
      break;
    case 'dateTime':
      content = (
        <SelectDateTimeStep
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          handleClickBack={handleClickBack}
          setStep={setStep}
          viewport={viewport}
        />
      );
      break;
    case 'searchedResults':
      content = (
        <SearchedResults
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          handleClickBack={handleClickBack}
          setStep={setStep}
        />
      );
      break;
    case 'searchingType':
    default:
      content = (
        <SearchedResults
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          handleClickBack={handleClickBack}
          setStep={setStep}
        />
      );
  }

  return (
    <div className={css.modalOuter}>
      <Modal
        isOpen={true}
        disableCloseBtn
        currentPage={currentPage}
        customContainerClassName={css.container}
        className={css.topBarSearchModal}
        scrollLayerClassName={css.topBarSearchModalScrollLayer}
        contentClassName={css.content}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {content}
      </Modal>
    </div>
  );
};

TopbarSearchModal.propTypes = {
  isOpen: PropTypes.bool,
};

const SearchTypeStep = ({ onSubmit, handleClickBack }) => {
  const dispatch = useDispatch();
  const handleSelectType = type => () => {
    // dispatch(setLandingImageType(type));
    onSubmit({
      searchType: type,
    });
  };

  return (
    <>
      <Header handleClickBack={handleClickBack} />
      {/* <div className={css.searchTypeStep}>
        <div className={css.title}>What kind of rental would you like to book?</div>
        <div className={css.type} onClick={handleSelectType('daily')}>
          <div className={css.typeTitle}>Daily Rental</div>
          <div className={css.desc}>
            Rent from a huge variety of nearby cars at great value. Anything from a day to week long
            trips.
          </div>
        </div>
        <div className={css.type} onClick={handleSelectType('hourly')}>
          <div className={css.typeTitle}>Hourly Rental</div>
          <div className={css.desc}>
            Need a car for a few hours? We got you covered! Book cars on hourly rental instantly and
            start driving now!{' '}
          </div>
        </div>
        <div className={css.type} onClick={handleSelectType('longTerm')}>
          <div className={css.typeTitle}>Long Term Rental</div>
          <div className={css.desc}>
            Flexible monthly car rentals with ZERO financial commitment with
          </div>
        </div>
      </div> */}
    </>
  );
};

SearchTypeStep.propTypes = {
  setStep: PropTypes.func,
};

const LocationStep = ({
  setStep,
  setSelectedValues,
  selectedValues,
  onSubmit,
  handleClickBack,
  userSearchHistory,
  viewport,
}) => {
  const geocoder = useRef(new GeocoderGoogleMaps()).current;
  const [predictions, setPredictions] = useState([]);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [locationLoader, setLocationLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  let EXPERIMENT_DATA;
  // Touch devices need to be able to distinguish touches for scrolling and touches to tap
  const handleSelectPrediction = prediction => {
    setLocationLoader(true);
    if (prediction && prediction.id && prediction.id === CURRENT_LOCATION_ID) {
      navigator.permissions
        .query({
          name: 'geolocation',
        })
        .then(function(result) {
          if (result.state == 'granted') {
            console.log(result.state);
          } else if (result.state == 'prompt') {
            console.log(result.state);
          } else if (result.state == 'denied') {
            console.log(result.state);
            setLocationLoader(false);
            setAlert(true);
          }
          result.onchange = function() {
            console.log(result.state);
          };
        });
    }
    geocoder
      .getPlaceDetails(
        prediction.predictionPlace
          ? Object.keys(prediction.predictionPlace).length > 0
            ? prediction.predictionPlace
            : prediction
          : prediction,
        true
      )
      .then(p => {
        console.log('result', p);
        if (
          selectedValues &&
          selectedValues.selectedDates &&
          selectedValues.selectedDates.startDate &&
          selectedValues.selectedDates.endDate &&
          selectedValues.selectedDates &&
          selectedValues.selectedTimes.startTime &&
          selectedValues.selectedTimes.endTime
        ) {
          setSelectedValues(prev => ({
            ...prev,
            location: p,
          }));
          setTimeout(() => {
            onSubmit({
              ...selectedValues,
              location: p,
              prediction,
            });
            setLocationLoader(false);
            setStep('preview');
          }, 1000);
        } else {
          DEFAULT_TIME_AND_DATES = {
            location: p,
            selectedDates: {
              startDate: moment()
                .add(1, 'day')
                .set({ hour: 9, minute: 0 }),
              endDate: moment()
                .add(4, 'day')
                .set({ hour: 9, minute: 0 }),
            },
            selectedTimes: {
              startTime: moment()
                .add(1, 'day')
                .set({ hour: 9, minute: 0 }),
              endTime: moment()
                .add(4, 'day')
                .set({ hour: 9, minute: 0 }),
            },
            isDefault: true,
          };
          setSelectedValues({
            ...DEFAULT_TIME_AND_DATES,
            location: p,
          });
          setTimeout(() => {
            onSubmit({
              ...DEFAULT_TIME_AND_DATES,
              location: p,
              prediction,
            });
            setLocationLoader(false);
            setStep('preview');
          }, 1000);
        }
      })
      .catch(err => {
        if (err.code === 1 || err.code === 2) {
          setAlert(true);
          setLocationLoader(false);
        }
      });
  };
  const _handleSearchType = p => {
    if (p && p.value !== '') {
      setIsUserSearching(true);
    } else {
      setIsUserSearching(false);
    }
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const item = (prediction, index) => {
    const isHighlighted = index === highlightedIndex;
    const predictionId = geocoder.getPredictionId(prediction);
    return (
      <li
        className={classNames(isHighlighted ? css.highlighted : null, css.prediction)}
        key={predictionId}
        onClick={() => handleSelectPrediction(prediction)}
      >
        {predictionId === CURRENT_LOCATION_ID ? (
          <span className={css.currentLocation}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <defs></defs>
              <g transform="translate(-0.005 0)">
                <g transform="translate(6.005 0)">
                  <path
                    class="a"
                    fill="#026786"
                    d="M201.9.114a.334.334,0,0,0-.471,0L192.1,9.448a.333.333,0,0,0,.471.471L201.9.586A.334.334,0,0,0,201.9.114Z"
                    transform="translate(-192 -0.017)"
                  />
                </g>
                <g transform="translate(0.005 0)">
                  <g transform="translate(0 0)">
                    <path
                      class="a"
                      d="M15.907.1a.332.332,0,0,0-.379-.065L.195,7.366a.333.333,0,0,0,.039.617L6.075,9.93l1.947,5.842a.333.333,0,0,0,.3.227h.021a.333.333,0,0,0,.3-.189L15.972.477A.334.334,0,0,0,15.907.1ZM8.393,14.777,6.655,9.56a.333.333,0,0,0-.211-.211L1.227,7.611l13.736-6.57Z"
                      transform="translate(-0.005 0)"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
          </span>
        ) : (
          geocoder.getPredictionAddress(prediction)
        )}
      </li>
    );
  };
  const handleExperiment = () => {};
  /* eslint-enable jsx-a11y/no-static-element-interactions */
  return (
    <>
      {alert ? (
        <AlertBox
          title="Location error"
          message="You have not given location access on the browser so we cannot fetch your currecnt location. Either provide location permission or enter your location manually."
          type="error"
        />
      ) : null}
      {locationLoader && (
        <div className={css.locationLoader}>
          <span></span>
        </div>
      )}
      {/* <div className={css.locationLoader}><span></span></div> */}
      <Header className={css.locationStepHeader} handleClickBack={handleClickBack}>
        <Form
          onSubmit={() => {}}
          render={() => {
            return (
              <Field name="location">
                {props => (
                  <LocationAutocompleteInput
                    {...props}
                    autoFocus={false}
                    focusInput={handleExperiment}
                    className={css.fieldLocation}
                    iconClassName={css.hideSearchIcon}
                    inputClassName={css.inputClassName}
                    predictionsClassName={css.searchPredictions}
                    onPredictionChange={setPredictions}
                    placeholder="Enter your location"
                    inputRef={_handleSearchType}
                  >
                    <SearchIcon rootClassName={css.searchIcon} />
                  </LocationAutocompleteInput>
                )}
              </Field>
            );
          }}
        />
      </Header>
      <div className={css.predictions} style={{ height: viewport.height - 80 }}>
        <ul className={css.predictionsCurrentLocations}>
          {defaultPredictionsWithoutCurrentLocation &&
          predictions.length > 0 &&
          predictions[0]['description']
            ? predictions
                .filter(item => {
                  const desc = (item['description'] || '').toLowerCase();
                  return desc.includes('australia');
                })
                .map(item)
            : defaultPredictionsWithoutCurrentLocation.map((value, index) => item(value, index))}
        </ul>
        {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
          <div className={css.searchLabels}>
            <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
          </div>
        ) : null}
        {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
          <ul className={css.predictions}>
            {userSearchHistory &&
            userSearchHistory.length > 0 &&
            userSearchHistory[0]['description']
              ? userSearchHistory.map((value, index) => item(value, index))
              : userSearchHistory.map((value, index) => item(value, index))}
          </ul>
        ) : null}
        {predictions && predictions.length > 0 && predictions[0]['description'] ? null : (
          <div className={css.searchLabels}>
            <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
          </div>
        )}

        {userSearchHistory && userSearchHistory.length ? (
          <ul className={css.predictions}>
            {predictions && predictions.length > 0 && predictions[0]['description']
              ? null
              : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                  item(value, index)
                )}
          </ul>
        ) : (
          <ul className={css.predictions}>
            {predictions && predictions.length > 0 && predictions[0]['description']
              ? null
              : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                  item(value, index)
                )}
          </ul>
        )}
        {/* <GeocoderAttribution className={css.attributionClassName} /> */}
      </div>
    </>
  );
};

LocationStep.propTypes = {
  setStep: PropTypes.func,
};

const DisplayDateAndTime = ({
  selectedDates,
  selectedTimes,
  selectedValues,
  searchType,
  duration,
  address,
  mapSearch,
}) => {
  let pickupDisplay = null;
  let dropOffDisplay = null;
  let location = null;
  if (mapSearch) {
    location = 'Map Location';
  } else if (address || (selectedValues && selectedValues.location)) {
    location =
      (selectedValues && selectedValues.location && selectedValues.location.address) || address;
  } else {
    location = 'Select Location';
  }
  if (selectedDates && selectedTimes) {
    pickupDisplay = !!(selectedDates.startDate && selectedTimes.startTime) ? (
      <span>
        {selectedDates.startDate.format('DD MMM')}, {selectedTimes.startTime.format('hhmm')}
      </span>
    ) : selectedDates.startDate ? (
      <FormattedHTMLMessage
        id="TopbarSearchModal.selectedDateWithoutTime.pickUp"
        values={{ date: selectedDates.startDate.format('DD MMM') }}
      />
    ) : (
      <span>Select Pick-up</span>
    );
    dropOffDisplay =
      selectedDates.endDate && selectedTimes.endTime ? (
        <span>
          {selectedDates.endDate.format('DD MMM')}, {selectedTimes.endTime.format('hhmm')}
        </span>
      ) : selectedDates.endDate ? (
        <FormattedHTMLMessage
          id="TopbarSearchModal.selectedDateWithoutTime.dropOff"
          values={{ date: selectedDates.endDate.format('DD MMM') }}
        />
      ) : (
        <span>Select Drop-off</span>
      );
  }
  if (searchType === 'daily') {
    // dasd
  }
  if (searchType === 'hourly' || searchType === 'longTerm') {
    pickupDisplay =
      selectedDates.startDate && selectedTimes.startTime ? (
        <FormattedHTMLMessage
          id="TopbarSearchModal.selectedDateAndTimes.pickUp"
          values={{
            date: selectedDates.startDate.format('DD MMM'),
            time: selectedTimes.startTime.format('hh:mm a'),
          }}
        />
      ) : selectedDates.startDate ? (
        <FormattedHTMLMessage
          id="TopbarSearchModal.selectedDateWithoutTime.pickUp"
          values={{ date: selectedDates.startDate.format('DD MMM') }}
        />
      ) : (
        <FormattedHTMLMessage id="TopbarSearchModal.noSelectedValues.pickUp" />
      );
    const selectedOp = (searchType === 'hourly'
      ? config.custom.durationOptions
      : config.custom.monthDurationOptions
    ).find(op => op.key === duration);
    dropOffDisplay = selectedOp ? (
      <FormattedHTMLMessage
        id="TopbarSearchModal.duration"
        values={{ duration: selectedOp.title }}
      />
    ) : (
      <FormattedHTMLMessage id="TopbarSearchModal.noSelectedValues.duration" />
    );
  }
  return (
    <div className={css.bookingDateAndTimes}>
      <div className={css.selectedSearchLocation}>{location}</div>
      {pickupDisplay} - {dropOffDisplay}
    </div>
  );
};

DisplayDateAndTime.propTypes = {
  selectedDates: shape({
    startDate: object,
    endDate: object,
  }),
  selectedTimes: shape({
    startTime: object,
    endTime: object,
  }),
};

const SelectDateTimeStep = ({
  setSelectedValues,
  setStep,
  selectedValues,
  handleClickBack,
  viewport,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const calendarWrapper = useRef();
  const [isAnyDateSelected, setIsAnyDateSelected] = useState(false);
  const [isStartTime, setisStartTime] = useState(selectedValues && selectedValues.isPickUp);
  const [selectedDates, setSelectedDates] = useState(
    (selectedValues && selectedValues.selectedDates) ||
      (DEFAULT_TIME_AND_DATES && DEFAULT_TIME_AND_DATES.selectedDates)
  );
  const [selectedTimes, setSelectedTimes] = useState(
    (selectedValues && selectedValues.selectedTimes) ||
      (DEFAULT_TIME_AND_DATES && DEFAULT_TIME_AND_DATES.selectedTimes)
  );
  const [isPickupDate, setIsPickUpDate] = useState(
    selectedValues && selectedValues.isPickUp && true
  );
  const [switcherReload, setSwitcherReload] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const calendarRef = useRef();
  const { searchStep, ...restSearch } = parse(search);
  const searchType = useSelector(state => state.UI.landingImageType);
  const calendarProps = {
    minimumNights: 1,
    numberOfMonths: 12,
    maxDate: moment()
      .add(360, 'days')
      .toDate(),
  };

  const timezone = selectedValues.location.timezone || DEFAULT_TIMEZONE;
  let disabledSubmit;
  if (selectedValues && selectedValues.selectedDates && selectedValues.selectedTimes) {
    disabledSubmit =
      (searchType === 'daily' &&
        !(
          selectedValues.selectedTimes.startTime &&
          selectedValues.selectedTimes.endTime &&
          selectedValues.selectedDates.startDate &&
          selectedValues.selectedDates.endDate
        )) ||
      ((searchType === 'hourly' || searchType === 'longTerm') &&
        !(selectedDates.startDate && selectedTimes.startTime && selectedDuration));
  }
  const handleShowPreview = () => {
    setStep('preview');
    setSelectedValues(prev => {
      return {
        ...prev,
        selectedDates,
        selectedTimes,
      };
    });
  };

  let content = null;

  useEffect(() => {
    if (switcherReload) {
      setSwitcherReload(false);
    }
  }, [switcherReload]);

  const _handleTimeSelect = t => {
    handleShowPreview();
    setisStartTime(false);
    setIsAnyDateSelected(false);
    
    
    setSelectedTimes(prev => ({
        ...prev,
        startTime: t, 
        endTime: moment(t).add(2, 'hours').startOf('hours'), 
    }));
};

  const checkIfDateIsToday = d => {
    const currentTime = new Date();
    const iscurrentDate = d.tz(timezone).isSame(currentTime, 'day');
    return iscurrentDate;
  };

  const _dateSelection = t => {
    const { startDate, endDate } = selectedDates && selectedDates;
    if (isPickupDate) {
      const now = moment(new Date()).tz(timezone);
      if (moment(t).isAfter(endDate)) {
        setSelectedDates(prev => ({ ...prev, startDate: t, endDate: null }));
        const updatedTime = checkIfDateIsToday(t)
          ? now.add(1, 'hour')
          : t.set({ hour: 9, minute: 0 });
        setSelectedTimes(prev => ({ ...prev, startTime: updatedTime, endTime: null }));
        setSelectedValues(prev => ({
          ...prev,
          selectedDates: {
            startDate: t,
            endDate: prev.selectedDates.endDate,
          },
          selectedTimes: {
            startTime: updatedTime,
            endTime: prev.selectedTimes.endTime,
          },
        }));
      } else {
        setSelectedDates(prev => ({ ...prev, startDate: t }));
        checkIfDateIsToday(t)
          ? setSelectedTimes(prev => ({
              ...prev,
              startTime: moment(new Date())
                .tz(timezone)
                .add(1, 'hour')
                .startOf('hours'),
            }))
          : setSelectedTimes(prev => ({ ...prev, startTime: t.set({ hour: 9, minute: 0 }) }));
      }
    } else {
      if (moment(t).isBefore(startDate)) {
        setSelectedDates(prev => ({ ...prev, startDate: null, endDate: t }));
        checkIfDateIsToday(t)
          ? setSelectedTimes(prev => ({
              ...prev,
              startTime: null,
              endTime: t.add(1, 'hour').startOf('hours'),
            }))
          : setSelectedTimes(prev => ({
              ...prev,
              startTime: null,
              endTime: t.set({ hour: 9, minute: 0 }),
            }));
      } else {
        if (moment(t).isSame(startDate, 'day')) {
          setSelectedDates(prev => ({ ...prev, endDate: t }));
          const startTimeValue = selectedTimes && selectedTimes.startTime;
          setSelectedTimes(prev => ({
            ...prev,
            endTime: moment(startTimeValue)
              .add(2, 'hour')
              .startOf('hours'),
          }));
        } else {
          setSelectedDates(prev => ({ ...prev, endDate: t }));
          checkIfDateIsToday(t)
            ? setSelectedTimes(prev => ({ ...prev, endTime: t.add(1, 'hour').startOf('hours') }))
            : setSelectedTimes(prev => ({ ...prev, endTime: t.set({ hour: 9, minute: 0 }) }));
        }
      }
    }
    setIsAnyDateSelected(true);
  };

  const _handleSwitchTabs = type => {
    switch (type) {
      case 'pick-up':
        setIsPickUpDate(true);
        setSwitcherReload(true);
        break;
      case 'drop-off':
        setIsPickUpDate(false);
        setSwitcherReload(true);
        break;
      default:
        setIsPickUpDate(true);
        break;
    }
  };

  content = (
    <>
      <div
        style={{
          display: 'block',
          width: '100%',
          background: '#FFFFFF',
          zIndex: 2,
          position: 'relative',
          overflow: 'auto',
          paddingBottom: '25px',
        }}
        ref={calendarWrapper}
      >
        <div className={css.dateTimeSelectionTabsOuter}>
          <div
            className={classNames(css.dateTimeSelectionTabs, {
              [css.tabActive]: isPickupDate,
            })}
            onClick={() => _handleSwitchTabs('pick-up')}
          >
            <span className={css.tabsDateTimeValues}>
              {selectedDates && selectedDates.startDate
                ? selectedDates.startDate.format('DD MMM')
                : 'Date'}
              ,{' '}
              {selectedTimes && selectedTimes.startTime
                ? selectedTimes.startTime.format('hh:mm a')
                : 'Time'}
            </span>
            <span className={css.tabsDateTimeLabel}>Pickup Date &amp; Time</span>
          </div>
          <div
            className={classNames(css.dateTimeSelectionTabs, {
              [css.tabActive]: !isPickupDate,
            })}
            onClick={() => _handleSwitchTabs('drop-off')}
          >
            <span className={css.tabsDateTimeValues}>
              {selectedDates && selectedDates.endDate
                ? selectedDates.endDate.format('DD MMM')
                : 'Date'}
              ,{' '}
              {selectedTimes && selectedTimes.endTime
                ? selectedTimes.endTime.format('hh:mm a')
                : 'Time'}
            </span>
            <span className={css.tabsDateTimeLabel}>Drop-off Date &amp; Time</span>
          </div>
        </div>
        <div
          className={css.dateTimePickerOuter}
          style={{
            display: 'block',
            height: viewport.height - 140,
            overflow: 'auto',
            paddingBottom: '25px',
          }}
        >
          <div className={css.timeDateSelectorLabel}>
            {isPickupDate ? 'Pickup' : 'Drop-off'} Date
          </div>
          <SingleMonthCalender
            selectedDates={selectedDates && selectedDates}
            isPickupDate={isPickupDate}
            onDatesChange={t => _dateSelection(t)}
            ref={calendarRef}
            {...calendarProps}
          />
          <div className={css.timeDateSelectorLabel}>
            {isPickupDate ? 'Pickup' : 'Drop-off'} Time
          </div>
          <div
            style={{
              overflowY: 'scroll',
              height: 'auto',
            }}
          >
            {isPickupDate ? (
              !switcherReload && selectedDates && selectedDates.startDate ? (
                <SwipeTimeSelector
                  timezone={selectedValues.location.timezone || DEFAULT_TIMEZONE}
                  date={selectedDates && selectedDates.startDate}
                  isPickupDate={isPickupDate}
                  onSelect={t => _handleTimeSelect(t)}
                  selectedTimes={selectedTimes}
                  setIsPickUpDate={setIsPickUpDate}
                  setSelectedTimes={setSelectedTimes}
                  setSelectedDates={setSelectedDates}
                  selectedDates={selectedDates && selectedDates}
                />
              ) : (
                ''
              )
            ) : !switcherReload && selectedDates && selectedDates.endDate ? (
              <SwipeTimeSelector
                timezone={selectedValues.location.timezone || DEFAULT_TIMEZONE}
                date={selectedDates && selectedDates.endDate}
                isPickupDate={isPickupDate}
                onSelect={t => _handleTimeSelect(t)}
                selectedTimes={selectedTimes}
                setIsPickUpDate={setIsPickUpDate}
                setSelectedTimes={setSelectedTimes}
                selectedDates={selectedDates && selectedDates}
                setSelectedDates={setSelectedDates}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Header className={css.selectDateTimeHeader} handleClickBack={handleClickBack}>
        <span className={css.searchPreviewTitle}>Select your trip dates</span>
        <div className={css.clearBtn} onClick={handleShowPreview}></div>
      </Header>
      {content}
    </>
  );
};

const SearchedResults = ({ selectedValues, setStep, handleClickBack }) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { searchStep, address, searchType, mapSearch, ...restSearch } = parse(search);
  const handleShowPreview = () => {
    setStep('preview');
  };

  return (
    <>
      <Header className={css.selectDateTimeHeader} handleClickBack={handleClickBack}>
        <DisplayDateAndTime
          selectedDates={selectedValues && selectedValues.selectedDates}
          selectedTimes={selectedValues && selectedValues.selectedTimes}
          selectedValues={selectedValues}
          duration={selectedDuration}
          searchType={searchType}
          address={address}
          mapSearch={mapSearch}
        />
        <div className={css.clearBtn} onClick={() => handleShowPreview()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.746"
            height="22"
            viewBox="0 0 24.746 22"
          >
            <path
              class="a"
              fill="#FFFFFF"
              d="M17.284,14.746l1.375-1.375a.345.345,0,0,1,.589.245v6.247a2.063,2.063,0,0,1-2.062,2.062H2.062A2.063,2.063,0,0,1,0,19.863V4.74A2.063,2.063,0,0,1,2.062,2.678h11.75a.346.346,0,0,1,.245.589L12.683,4.641a.341.341,0,0,1-.245.1H2.062V19.863H17.185V14.987A.338.338,0,0,1,17.284,14.746Zm6.728-8.67L12.73,17.358l-3.884.43a1.775,1.775,0,0,1-1.959-1.959l.43-3.884L18.6.663a2.507,2.507,0,0,1,3.553,0l1.856,1.856a2.517,2.517,0,0,1,0,3.557ZM19.767,7.4l-2.5-2.5L9.288,12.894,8.975,15.7l2.805-.314ZM22.551,3.98,20.7,2.124a.449.449,0,0,0-.636,0L18.732,3.451l2.5,2.5L22.555,4.62A.458.458,0,0,0,22.551,3.98Z"
              transform="translate(0 0.075)"
            />
          </svg>
        </div>
      </Header>
    </>
  );
};

const SearchPreview = ({
  selectedValues,
  setSelectedValues,
  setStep,
  onSearchSubmit,
  handleClickBack,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [searchUpdateEnable, setSearchUpdateEnable] = useState(false);
  const searchType = useSelector(state => state.UI.landingImageType);
  const currentSearchParams = useSelector(state => state.UI.searchParams);
  const { dates, hours, address, mapSearch, ...restSearch } = parse(search);
  let previewNode = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    if (selectedValues && selectedValues.selectedDates && selectedValues.selectedTimes) {
      const { location } = selectedValues;
      const { startDate, endDate } = selectedValues.selectedDates;
      const { startTime, endTime } = selectedValues.selectedTimes;
      if (startDate && endDate && startTime && endTime) {
        setSearchUpdateEnable(true);
      } else {
        setSearchUpdateEnable(false);
      }
    }
  }, []);
  const redirectTo = (location, isPickUp) => {
    switch (location) {
      case 'location':
        setStep('location');
        setSelectedValues(prev => {
          return {
            ...prev,
            isPickUp,
          };
        });
        break;
      case 'dateTime':
        setStep('dateTime');
        setSelectedValues(prev => {
          return {
            ...prev,
            isPickUp,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleOutsideClick = e => {
    if (previewNode.current && !previewNode.current.contains(e.target)) {
      setStep('searchedResults');
      document.removeEventListener('click', handleOutsideClick, false);
    }
  };

  const _handleSearch = () => {
    const { location } = selectedValues;
    let url = '';
    const { startDate, endDate } = selectedValues.selectedDates;
    const { startTime, endTime } = selectedValues.selectedTimes;
    setStep('searchedResults');
    console.log("Start and end dates",  startDate, endDate, selectedValues)

    const dates =
      startDate && endDate
        ? `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`
        : null;
    const hours =
      startTime && endTime ? `${startTime.format('HH:mm')},${endTime.format('HH:mm')}` : null;
    url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
      bounds: encodeLatLngBounds(location && location.bounds ? location.bounds : DEFAULT_BOUNDS),
      dates,
      hours,
      searchType: 'daily',
      address: location && location.address,
      origin: location && location.origin,
      searchStep: 'searchedResults',
      timezone: location && location.timezone
    });
    initiateEventFromListing({
      props: {
        searchParams: {
          ...currentSearchParams,
          searchType,
          bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
        },
      },
      event: EVENT_SEARCH_PERFORMED,
    });
    gSend(GTAG_ACTIONS.ACTION_SEARCH, {
      // TODO: Assign proper details.
      search_location: location && location.address,
      search_pickupdate: `${startDate.format('YYYY-MM-DD')}`,
      search_pickuptime: `${startTime.format('HH:mm')}`,
      search_dropoffdate: `${endDate.format('YYYY-MM-DD')}`,
      search_dropofftime: `${endTime.format('HH:mm')}`,
    });
    saveSearchedPlacesMobile(location);
    history.push(url);
  };

  let startDateTime, endDateTime;

  startDateTime =
    selectedValues &&
    selectedValues.selectedDates &&
    selectedValues.selectedDates.startDate &&
    selectedValues.selectedTimes &&
    selectedValues.selectedTimes.startTime
      ? `${selectedValues.selectedDates.startDate.format(
          'DD MMM'
        )}, ${selectedValues.selectedTimes.startTime.format('hh:mm a')}`
      : 'Select date/time';
  endDateTime =
    selectedValues &&
    selectedValues.selectedDates &&
    selectedValues.selectedDates.endDate &&
    selectedValues.selectedTimes &&
    selectedValues.selectedTimes.endTime
      ? `${selectedValues.selectedDates.endDate.format(
          'DD MMM'
        )}, ${selectedValues.selectedTimes.endTime.format('hh:mm a')}`
      : 'Select date/time';

  return (
    <>
      <Header className={css.locationStepHeader} handleClickBack={handleClickBack}>
        <span className={css.searchPreviewTitle}>Refine Search</span>
      </Header>
      <Form
        onSubmit={() => {}}
        render={() => {
          return (
            <div className={css.searchPreviewWrapper}>
              <div className={css.searchPreviewInner} ref={previewNode}>
                <div className={css.searchPreviewInput} onClick={() => redirectTo('location')}>
                  <label htmlFor="startDateAndTime">Pickup</label>
                  <Field name="location">
                    {props => (
                      <input
                        type="text"
                        className={css.searchPreviewFormInput}
                        placeholder="Selected Location"
                        disabled
                        value={
                          mapSearch
                            ? 'Map Location'
                            : selectedValues && selectedValues.location
                            ? selectedValues.location.address
                            : address
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={css.searchPreviewTimePickerOuter}>
                  <div className={css.searchPreviewTimePicker}>
                    <div
                      className={css.searchPreviewInput}
                      onClick={() => redirectTo('dateTime', true)}
                    >
                      <label htmlFor="startDateAndTime">Pickup</label>
                      <span className={css.fieldLocation} disabled>
                        {startDateTime}
                      </span>
                    </div>
                  </div>
                  <div className={css.searchPreviewTimePicker}>
                    <div
                      className={css.searchPreviewInput}
                      onClick={() => redirectTo('dateTime', false)}
                    >
                      <label htmlFor="endDateAndTime">Dropoff</label>
                      <span className={css.fieldLocation} disabled>
                        {endDateTime}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={css.searchPreviewSubmit}>
                  <div
                    className={classNames(css.viewAllCar, !searchUpdateEnable && css.isDisabled)}
                    onClick={_handleSearch}
                  >
                    Update Search Results
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default withViewport(TopbarSearchModal);
