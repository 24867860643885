/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';

const SeoLandingCarRentalCategories = memo(
  ({ title, text, categoryName }) => {
    const history = useHistory();
    const redirectToDifferentLocation = (history, locationName) => {
      history.push(locationName);
      history.go(0);
    };
    return (
      <div className={css.carRentBrands}>
        <div className={css.carRentBrandsContainer}>
          <div className={css.carRentBrandsTitle}>
            <span>{title}</span>
            <p>{text}</p>
          </div>
          <div className={css.arrayGrid}>
            {categoryName.values.map(value => {
              return (
                <div className={css.cardGrid}>
                  <div
                    key={value.key}
                    className={css.card}
                    onClick={() =>
                      redirectToDifferentLocation(history, categoryName.generateRoute(value.key))
                    }
                  >
                    <div className={css.imageOverlay}>
                      <img src={value.image} alt="" />
                    </div>
                    <div className={css.overlay}>
                      <span className={css.categoryTitle}>{value.label}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingCarRentalCategories;
