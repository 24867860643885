import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { bool } from 'prop-types';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_YOUNG_CUSTOMER_COMMISSION, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemYoungCustomerCommissionMaybeRefund = memo(
  props => {
    const { transaction, isCustomer, intl, showLines = true } = props;

    const customerCommissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_YOUNG_CUSTOMER_COMMISSION && item.reversal
    );

    if (!customerCommissionLineItem) return null;

    // If commission is passed it will be shown as a fee already reduces from the total price
    let commissionItem = null;

    if (isCustomer && customerCommissionLineItem) {
      if (!isValidCommission(customerCommissionLineItem)) {
        // eslint-disable-next-line no-console
        // console.error('invalid commission line item:', customerCommissionLineItem);
        // throw new Error('Commission should be present and the value should be zero or positive');
        return null;
      }

      const commission = customerCommissionLineItem.lineTotal;

      const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

      commissionItem = showLines ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.youngCommissionRefund" />
            <Explanation text="Refund driver fee fro drivers aged between 22-24" />
          </span>
          <span className={css.itemValue}>{formattedCommission}</span>
        </div>
      ) : null;
    }

    return commissionItem;
  },
  (prev, next) =>
    isEqual(pick(prev, ['transaction', 'isCustomer']), pick(next, ['transaction', 'isCustomer']))
);

LineItemYoungCustomerCommissionMaybeRefund.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemYoungCustomerCommissionMaybeRefund;
