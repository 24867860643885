import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.css';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    transaction,
    currentUser,
    acceptButtonName,
    declineButtonName,
    acceptSaleGTM,
    declineSaleGTM,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const validTransaction = transaction && transaction.customer;
  const acceptAllow =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED);

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const guestIdentityIsNotVerify = !acceptAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
        className={css.secondButton}
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          id={declineButtonName}
          name={declineButtonName}
          onClick={() => {
            onDeclineSale(transaction.id);
            declineSaleGTM();
          }}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled || !acceptAllow}
          className={css.primaryButton}
          id={acceptButtonName}
          name={acceptButtonName}
          onClick={() => {
            if (acceptAllow) {
              onAcceptSale(transaction.id, currentUser);
              acceptSaleGTM();
            }
          }}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
