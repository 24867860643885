import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { fetchFeaturedBCar, selectedPrediction } from '../SearchPage/SearchPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import BLandingCss from '../BLandingPage/BLandingPage.css';
import SectionHeroBranding from './SectionHeroBranding';
import HostSectionHeroBrandingPartners from './HostSectionHeroBrandingPartners';
import Footer from '../../components/Footer/Footer';
import Carsharing from './CarSharing';
import PartnerBrandingCalculator from './PartnerBrandingCalculator.js';
import SectionBrandingHelp from './SectionBrandingHelp';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import { parse } from '../../util/urlHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 768;

export const PartnerBrandingPageComponent = props => {
  const { intl, scrollingDisabled, viewport, location } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'PartnerBrandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'PartnerBrandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  };

  const onListYourCar = () => {
    initiateListACarEventFromTab({
      props,
      eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    });
  };

  useEffect(() => {
    const { code } = parse(location.search);
    if (code) {
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
      });
    }
  }, []);

  return (
    <Page
      className={BLandingCss.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'PartnerBrandingPage1'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={BLandingCss.contentWrapperBWrapper}>
          <HostSectionHeroBrandingPartners signUpCodeAmount={signUpCodeAmount} />
          <SectionHeroBranding />
          <PartnerBrandingCalculator
            onListYourCar={onListYourCar}
            onUseRentalCalculator={onUseRentalCalculator}
          />
          <Carsharing />
          {/* <TrustBoxSlider /> */}
          <SectionBrandingHelp />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

PartnerBrandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onSelectedPrediction: val => dispatch(selectedPrediction(val)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PartnerBrandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PartnerBrandingPageComponent);

PartnerBrandingPage.loadData = () => {
  return fetchFeaturedBCar();
};

export default PartnerBrandingPage;
