import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '..';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { fetchFeaturedBCar, selectedPrediction } from '../SearchPage/SearchPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import css from './BrandingPage.css';
import SectionHeroBranding from './SectionHeroBranding';
import Carsharing from './CarSharing';
import PartnerBrandingCalculator from './PartnerBrandingCalculator.js';
import SectionBrandingHelp from './SectionBrandingHelp';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_BROWSE_VISITED_LANDING_PAGE,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import {
  initiateEventViewedFromLandingPage,
  initiateListACarEventFromTab,
  pushGTMBrowseEvent,
} from '../../util/gtm/gtmHelpers';
import PartnerSectionHero from './PartnerSectionHero';
import Guarantee from './Guarantee';
import { checkSignUpCode } from '../../util/referralProgram';
import { types } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import Footer from '../../components/Footer/Footer';

const { Money } = types;
const MAX_MOBILE_SCREEN_WIDTH = 768;

export const PartnerBrandingPage2Component = props => {
  const { intl, scrollingDisabled, location } = props;
  const siteTitle = config.siteTitle;
  const schemaDescription = intl.formatMessage({ id: 'PartnerBrandingPage2.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const targetRef = useRef(null);
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const isScoot = location.pathname === '/scoot-drivelah-signup';
  const schemaTitle = intl.formatMessage({ id: 'PartnerBrandingPage2.schemaTitle' }, { siteTitle });

  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  };

  const onListYourCar = () => {
    initiateListACarEventFromTab({
      props,
      eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    });
  };

  const handleGetToCalculatorSection = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 72);
  };

  useEffect(() => {
    const { code } = parse(location.search);
    initiateEventViewedFromLandingPage({
      props,
      location,
      event: EVENT_BROWSE_VISITED_LANDING_PAGE,
    });
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'PartnerBrandingPage2'} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.contentWrapperBWrapper}>
          <div className={css.backgroundImage}>
            <PartnerSectionHero
              isScoot={isScoot}
              signUpCodeAmount={signUpCodeAmount}
              loading={loading}
              getToCalculatorSection={handleGetToCalculatorSection}
            />
            <Guarantee />
          </div>
          <SectionHeroBranding />
          <div ref={targetRef}>
            <PartnerBrandingCalculator
              onListYourCar={onListYourCar}
              onUseRentalCalculator={onUseRentalCalculator}
            />
          </div>
          <Carsharing />
          {/* <TrustBoxSlider /> */}
          <SectionBrandingHelp />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

PartnerBrandingPage2Component.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onSelectedPrediction: val => dispatch(selectedPrediction(val)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PartnerBrandingPageVersion2 = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PartnerBrandingPage2Component);

PartnerBrandingPageVersion2.loadData = () => {
  return fetchFeaturedBCar();
};

export default PartnerBrandingPageVersion2;
