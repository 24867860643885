import React from 'react';
import css from './SectionCompare.css';
import FlexPlusIcon from '../../components/Icons/FlexPlus';

const SectionCompare = () => {
  return (
    <div className={css.root}>
      <div className={css.compare}>
        <div className={css.compareTitle}>How does Flex+ compare?</div>
        <table className={css.compareTable}>
          <tr>
            <th></th>
            <th>
              <FlexPlusIcon className={css.flexLogo} />
            </th>
            <th>Lease/ Long term rental</th>
            <th>Other subscriptions</th>
          </tr>
          <tbody>
            <tr>
              <td>Earn money</td>
              <td className={css.total} style={{ color: '#ffcd05' }}>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>Weekly payment</td>
              <td> $ </td>
              <td> $$$ </td>
              <td> $$ </td>
            </tr>
            <tr>
              <td>Rego & Insurance</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <CircleCheck />
              </td>
            </tr>
            <tr>
              <td>Roadside assist</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <CircleCheck />
              </td>
            </tr>
            <tr>
              <td>Maintenance</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <CircleCheck />
              </td>
            </tr>
            <tr>
              <td>Car swap</td>
              <td>
                Unlimited
              </td>
              <td>
                <Close />
              </td>
              <td>
                Limited
              </td>
            </tr>
            <tr>
              <td>Delivery</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>No lock-in contract</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <CircleCheck />
              </td>
            </tr>
            <tr>
              <td>24/7 Assistance</td>
              <td>
                <CircleCheck />
              </td>
              <td>
                <Close />
              </td>
              <td>
                <Close />
              </td>
            </tr>
            <tr>
              <td>Minimum term</td>
              <td>
                8 weeks
              </td>
              <td>
                52 weeks
              </td>
              <td>
                4 - 26 weeks
              </td>
            </tr>
            <tr>
              <td>Establishment fee</td>
              <td>
                $0
              </td>
              <td>
                $0
              </td>
              <td>
                $0 - $1000
              </td>
            </tr>
          </tbody>
        </table>
        <div className={css.smallText}>
          Inclusive of Road tax, maintenance, insurance, wear and tear and GST.
        </div>
      </div>
    </div>
  );
};

export default SectionCompare;

const CircleCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.75" height="16.75" viewBox="0 0 16.75 16.75">
    <path
      id="check-circle-solid"
      d="M24.75,16.375A8.375,8.375,0,1,1,16.375,8,8.375,8.375,0,0,1,24.75,16.375Zm-9.344,4.434L21.62,14.6a.54.54,0,0,0,0-.764l-.764-.764a.54.54,0,0,0-.764,0l-5.068,5.067-2.366-2.366a.54.54,0,0,0-.764,0l-.764.764a.54.54,0,0,0,0,.764l3.512,3.512a.54.54,0,0,0,.764,0Z"
      transform="translate(-8 -8)"
      fill="#ffcd05"
    />
  </svg>
);

const Close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.535"
    height="11.535"
    viewBox="0 0 11.535 11.535"
  >
    <path
      id="close-solid"
      d="M7.954,85.767l3.279-3.279a1.031,1.031,0,0,0,0-1.458L10.5,80.3a1.031,1.031,0,0,0-1.458,0L5.767,83.581,2.488,80.3a1.031,1.031,0,0,0-1.458,0L.3,81.031a1.031,1.031,0,0,0,0,1.458l3.279,3.279L.3,89.046A1.031,1.031,0,0,0,.3,90.5l.729.729a1.031,1.031,0,0,0,1.458,0l3.279-3.279,3.279,3.279a1.031,1.031,0,0,0,1.458,0l.729-.729a1.031,1.031,0,0,0,0-1.458Z"
      transform="translate(0 -80)"
      fill="#cf5d5d"
    />
  </svg>
);
