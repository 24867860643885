import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ExpandingTextarea, ValidationError } from '../../components';

import css from './FieldInteractiveTextInput.css';

const CONTENT_MAX_LENGTH = 5000;

class FieldInteractiveTextInput extends Component {
  state = {
    focus: false,
  };
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputClassName,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      inline = false,
      labelClassName,
      errorClassName,
      floatingLabel,
      countCharacters = null,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const handleFocus = e => {
      this.setState({ focus: true });
      input.onFocus(e);
    };

    const handleBlur = e => {
      this.setState({ focus: false });
      input.onBlur(e);
    };

    const inputClasses =
      inputRootClass ||
      classNames(css.input, inputClassName, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
        [css.inputInline]: inline,
      });
    const maxLength = CONTENT_MAX_LENGTH;
    const inputProps = isTextarea
      ? {
          className: inputClasses,
          id,
          rows: 1,
          maxLength,
          ...refMaybe,
          ...inputWithoutType,
          ...rest,
          onFocus: handleFocus,
          onBlur: handleBlur,
        }
      : isUncontrolled
      ? {
          className: inputClasses,
          id,
          type,
          defaultValue,
          ...refMaybe,
          ...inputWithoutValue,
          ...rest,
          onFocus: handleFocus,
          onBlur: handleBlur,
        }
      : {
          className: inputClasses,
          id,
          type,
          ...refMaybe,
          ...input,
          ...rest,
          onFocus: handleFocus,
          onBlur: handleBlur,
        };

    const classes = classNames(rootClassName || css.root, className, {
      [css.inline]: inline,
    });

    const placeholderMaybe = floatingLabel && this.state.focus ? { placeholder: '' } : {};

    const { currentCount = 0, limit = (rest.maxLength || 0) } = countCharacters || {};
    const focusedClass = currentCount > 0 ? css.inputFocused : ''
    return (
      <div className={classes}>
        <div className={classNames(css.inputWrapper, focusedClass)}>
          {isTextarea ? (
            <ExpandingTextarea {...inputProps} />
          ) : (
            <input {...inputProps} {...placeholderMaybe} />
          )}
          {(label && !floatingLabel) || (floatingLabel && this.state.focus) || input.value ? (
            <>
            <label className={classNames(labelClassName, css.labelContainer)} htmlFor={id}>
              {label}
              {/* {countCharacters ? <span className={css.currentCount}>{currentCount}/{limit}</span> : null} */}
            </label>
            </>
          ) : null}
        </div>
        <ValidationError fieldMeta={fieldMeta} inline={inline} className={classNames(css.errorMessage, errorClassName)} />
      </div>
    );
  }
}

FieldInteractiveTextInput.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  inputClassName: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
  floatingLabel: false,
};

FieldInteractiveTextInput.propTypes = {
  rootClassName: string,
  className: string,
  inputClassName: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldInteractiveTextInput} {...this.props} />;
  }
}

export default FieldTextInput;
