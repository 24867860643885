import { types as sdkTypes } from '../util/sdkLoader';
import get from 'lodash/get';
import { EVENT_SEARCH_EXPERIMENT_A } from './gtm/gtmConstants';
import config from '../config';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

const placeOrigin = place => {
  if (place && place.geometry && place.geometry.location) {
    return new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
  }
  return null;
};

const PLACE_TYPE_BOUNDS_DISTANCES = {
  address: 500,
  country: 2000,
  region: 2000,
  postcode: 2000,
  district: 2000,
  place: 2000,
  locality: 2000,
  neighborhood: 2000,
  poi: 2000,
  'poi.landmark': 2000,
};

const GENERATED_BOUNDS_DEFAULT_DISTANCE = 50000; // meters

const placeBounds = place => {
  if (place && place.geometry && place.geometry.viewport) {
    const ne = place.geometry.viewport.getNorthEast();
    const sw = place.geometry.viewport.getSouthWest();
    return new SDKLatLngBounds(
      new SDKLatLng(ne.lat(), ne.lng()),
      new SDKLatLng(sw.lat(), sw.lng())
    );
  }
  return null;
};

export const locationRadiusBounds = (latlng, distance) => {
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};

const placeRadiusBounds = async (origin, isSearch = false) => {
  if (origin) {
    const result = await getAddressDetailsByLatLong(origin.lat, origin.lng);
    let distance =
      result.state === 'New South Wales' ? 15000 : result.state === 'Victoria' ? 20000 : 25000;
    return locationRadiusBounds(
      origin,
      GENERATED_BOUNDS_DEFAULT_DISTANCE
    );
  }
  return null;
};

/**
 * Get a detailed place object
 *
 * @param {String} placeId - ID for a place received from the
 * autocomplete service
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 *
 * @return {Promise<util.propTypes.place>} Promise that
 * resolves to the detailed place, rejects if the request failed
 */
export const getPlaceDetails = (placeId, sessionToken, isSearch = false) =>
  // console.log('Details',placeId,sessionToken,isSearch);
  new Promise(async (resolve, reject) => {
    const serviceStatus = window.google.maps.places.PlacesServiceStatus;
    const el = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(el);
    const fields = ['address_component', 'formatted_address', 'geometry', 'place_id'];
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    await service.getDetails({ placeId, fields, ...sessionTokenMaybe }, async (place, status) => {
      let origin = placeOrigin(place);
      if (status !== serviceStatus.OK) {
        reject(
          new Error(`Could not get details for place id "${placeId}", error status was "${status}"`)
        );
      } else {
        let bounds = await placeRadiusBounds(origin, isSearch);
        resolve({
          address: place.formatted_address,
          origin: origin,
          bounds: bounds,
          place,
        });
      }
    });
  });

const predictionSuccessful = status => {
  const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
  return status === OK || status === ZERO_RESULTS;
};

/**
 * Get place predictions for the given search
 *
 * @param {String} search - place name or address to search
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 * @param {Object} searchConfigurations - defines the search configurations that can be used with
 * the autocomplete service. Used to restrict search to specific country (or countries).
 *
 * @return {Promise<{ search, predictions[] }>} - Promise of an object
 * with the original search query and an array of
 * `google.maps.places.AutocompletePrediction` objects
 */
export const getPlacePredictions = (search, sessionToken, searchConfigurations) =>
  new Promise((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getPlacePredictions(
      { input: search, ...sessionTokenMaybe, ...searchConfigurations },
      (predictions, status) => {
        if (!predictionSuccessful(status)) {
          reject(new Error(`Prediction service status not OK: ${status}`));
        } else {
          const results = {
            search,
            predictions: predictions || [],
          };
          resolve(results);
        }
      }
    );
  });

/**
 * Deprecation: use function from src/util/maps.js
 * Cut some precision from bounds coordinates to tackle subtle map movements
 * when map is moved manually
 *
 * @param {LatLngBounds} sdkBounds - bounds to be changed to fixed precision
 * @param {Number} fixedPrecision - integer to be used on tofixed() change.
 *
 * @return {SDKLatLngBounds} - bounds cut to given fixed precision
 */
export const sdkBoundsToFixedCoordinates = (sdkBounds, fixedPrecision) => {
  const fixed = n => Number.parseFloat(n.toFixed(fixedPrecision));
  const ne = new SDKLatLng(fixed(sdkBounds.ne.lat), fixed(sdkBounds.ne.lng));
  const sw = new SDKLatLng(fixed(sdkBounds.sw.lat), fixed(sdkBounds.sw.lng));

  return new SDKLatLngBounds(ne, sw);
};

/**
 * Deprecation: use function from src/util/maps.js
 * Check if given bounds object have the same coordinates
 *
 * @param {LatLngBounds} sdkBounds1 - bounds #1 to be compared
 * @param {LatLngBounds} sdkBounds2 - bounds #2 to be compared
 *
 * @return {boolean} - true if bounds are the same
 */
export const hasSameSDKBounds = (sdkBounds1, sdkBounds2) => {
  if (!(sdkBounds1 instanceof SDKLatLngBounds) || !(sdkBounds2 instanceof SDKLatLngBounds)) {
    return false;
  }
  return (
    sdkBounds1.ne.lat === sdkBounds2.ne.lat &&
    sdkBounds1.ne.lng === sdkBounds2.ne.lng &&
    sdkBounds1.sw.lat === sdkBounds2.sw.lat &&
    sdkBounds1.sw.lng === sdkBounds2.sw.lng
  );
};

/*
 * Calculate a bounding box in the given location
 *
 * @param {latlng} center - center of the bounding box
 * @param {distance} distance - distance in meters from the center to
 * the sides of the bounding box
 *
 * @return {LatLngBounds} bounding box around the given location
 *
 */
export const locationBounds = (latlng, distance) => {
  console.log('distanceinbounds',latlng,  distance);
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};

/**
 * Get the formatted address for the given latitude and longitude using the Google Maps Geocoding API.
 *
 * @param {number} latitude - The latitude of the location.
 * @param {number} longitude - The longitude of the location.
 * @param {string} [language=en] - The language code for the language in which to return the results.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the formatted address components.
 */
export const getLatLngWithPlaceId = placeId => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${config.maps.googleMapsAPIKey}`;
  return fetch(url)
    .then(response => response.json())
    .then(res => {
      if (res.status === 'OK') {
        return res.results[0].geometry.location;
      } else {
        return Promise.reject('Unable to retrieve address information.');
      }
    });
};

export const getAddressDetailsByLatLong = (latitude, longitude, language = 'en') => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${language}&key=${config.maps.googleMapsAPIKey}`;
  return fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK' && data.results.length) {
        const addressComponents = get(data.results[0], 'address_components', []);
        const address = get(data.results[0], 'formatted_address');
        if (!address) {
          return null;
        }
        // Build the formatted address object
        const JSONformattedAddress = {
          address,
          streetNumber: '',
          streetName: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
          suburb: '',
        };

        // Iterate through the address components and fill in the relevant fields
        addressComponents.forEach(component => {
          if (component.types.includes('street_number')) {
            JSONformattedAddress.streetNumber = component.long_name;
          } else if (component.types.includes('route')) {
            JSONformattedAddress.streetName = component.long_name;
            // NOTE: To get the city, we can also use locality (Verify it once)
            // } else if (component.types.includes('locality')) {
          } else if (
            component.types.includes('administrative_area_level_2') ||
            component.types.includes('colloquial_area')
          ) {
            JSONformattedAddress.city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            JSONformattedAddress.state = component.long_name;
          } else if (component.types.includes('postal_code')) {
            JSONformattedAddress.postalCode = component.long_name;
          } else if (component.types.includes('country')) {
            JSONformattedAddress.country = component.long_name;
          } else if (component.types.includes('locality')) {
            JSONformattedAddress.suburb = component.long_name;
          }
        });

        JSONformattedAddress.formattedAddress = {
          // search: address
          selectedPlace: {
            address,
            origin: {
              lat: latitude,
              lng: longitude,
            },
            postalCode: JSONformattedAddress.postalCode || '',
            suburb: JSONformattedAddress.suburb || '',
            city: JSONformattedAddress.city || '',
          },
        };

        return Promise.resolve(JSONformattedAddress);
      } else {
        return Promise.reject('Unable to retrieve address information.');
      }
    })
    .catch(error => {
      console.log(error);
      return Promise.reject('An error occurred while retrieving address information.');
    });
};

/**
 * Get the latitude and longitude for the user's current location using the browser's geolocation API.
 * Then get the formatted address for the user's location using the Google Maps Geocoding API and return it as a promise.
 *
 * @returns {Promise<string>} - A promise that resolves with the formatted address for the user's current location.
 */
export const getAddressDetailsByBrowser = () => {
  // Check if navigator is available
  if (typeof navigator === 'undefined' || typeof navigator.geolocation === 'undefined') {
    // If it is not, log an error message to the console and reject the promise
    console.log('Geolocation is not available in this browser.');
    return Promise.reject('Geolocation is not available in this browser.');
  }

  // If navigator is available, return a promise that resolves with the formatted address for the user's location
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        // Call getAddressDetailsByLatLong and resolve with the result
        getAddressDetailsByLatLong(latitude, longitude)
          .then(address => {
            resolve(address);
          })
          .catch(error => {
            reject(error);
          });
      },
      error => {
        // Handle errors from getCurrentPosition
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log('User denied the request for Geolocation.');
            reject('User denied the request for Geolocation.');
            break;
          case error.POSITION_UNAVAILABLE:
            console.log('Location information is unavailable.');
            reject('Location information is unavailable.');
            break;
          case error.TIMEOUT:
            console.log('The request to get user location timed out.');
            reject('The request to get user location timed out.');
            break;
          default:
            console.log('An unknown error occurred while getting user location.');
            reject('An unknown error occurred while getting user location.');
        }
      }
    );
  });
};
