/**
 * Provides a time select for Final Forms
 *
 *
 */

import React, { Component } from 'react';
import { string } from 'prop-types';
// import { Field } from 'react-final-form';
import classNames from 'classnames';
// import { START_TIME, END_TIME } from '../../util/dates';
// import { ValidationError } from '../../components';
import TimeSelect from './TimeSelect';
import css from './FieldTimeSelect.css';

class FieldTimeSelectComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      nameInput,
      label,
      inputClassName,
      selectedDate,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <TimeSelect
          className={inputClassName}
          id={id}
          name={nameInput}
          label={label}
          selectedDate={selectedDate}
          {...rest}
        />
      </div>
    )
  }
}

FieldTimeSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FieldTimeSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
}

export { TimeSelect };
export default FieldTimeSelectComponent;
