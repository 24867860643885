const CLOUDINARY_BASE_URL = 'https://dv0eqz2t0y9gj.cloudfront.net';

const imageSource = {
  bannerImage: {
    bannerImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image.webp`,
    banner2Image: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2.webp`,
    bannerImage2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-image@2x.webp`,
    bannerImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image.webp`,
    bannerImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/08-2024/banner-mobile-image@2x.webp`,
    banner2Image2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2@2x.webp`,
    banner2ImageMobile: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile.webp`,
    banner2ImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/bannerImage2Mobile@2x.webp`,
    rentersImage: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/renters-img.webp`,
    videoThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/video-thumb.webp`,
    flexThumb: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flexDesk.webp`,
  },
  redirectBanner: {
    antiCompCar: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image.webp`,
    antiCompCarMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/redirect-banner-image-mobile.webp`,
    dLbgLogo: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo.webp`,
    dLbgLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/drive-lah-bg-logo-mobile.webp`,
  },
  grabBanner: {
    bannerBackground: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-background.webp`,
    bannerBackgroundRent: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/rent-banner.webp`,
    bannerLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo.png`,
    bannerLogoMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/banner-logo-mobile.png`,
    grabLogo: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-desktop.png`,
    grabLogoWhite: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/grab-rentals-mobile.png`,
    approveBlueIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge-blue@2x.png`,
    saveMoneyIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848@2x.png`,
    easyAccessIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_easy_3428320@2x.png`,
    approveBlueIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badgeM@2x.png`,
    saveMoneyIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_Save Money_776848M@2x.png`,
    easyAccessIconMobile: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/1024px-Dollar_sign_in_circle.svg@2x.png`,
    checkRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge@2x1.png`,
    checkBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/approval-symbol-in-badge11@2x.png`,
    crossRed: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477red@2x.png`,
    crossBlue: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/noun_cancel_1888477@2x.png`,
    image1: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image1.png`,
    image2: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image2.png`,
    image3: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image3.png`,
    image4: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image4.png`,
    image5: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image5.png`,
    image6: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/image6.png`,
    bgPattern: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/light-bg.svg`,
    userIcon: `${CLOUDINARY_BASE_URL}/drivelah/grabBanner/userIcon.svg`,
  },
  backgroundGo: {
    go: `${CLOUDINARY_BASE_URL}/drivelah/background-auth@1440.webp`,
  },
  landingPage: {
    sectionHeroInstant: `${CLOUDINARY_BASE_URL}/drivelah/landingpage/sectionHeroInstant.webp`,
  },
  antiCompetition: {
    arrowRight: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/arrow-down-yellow.svg`,
    carsAvailable: `${CLOUDINARY_BASE_URL}/drivelah/antiCompetition/cars-available.svg`,
    bluesg: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/bluesg.webp`,
    drivelahSVG: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah.png`,
    smallCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/category-small.webp`,
    compactPremiumCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-premium.webp`,
    compactSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/compact-suv.webp`,
    deliveryBanner: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/delivery-banner-image.webp`,
    instagramImage: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/drivelah-anti-competition-meta-image.webp`,
  },
  antiCompetition1: {
    midLargeCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mid-large.png`,
    fullSUVCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/full-suv.png`,
    mpvCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/mpv.png`,
    wagonCategories: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/wagon.png`,
    getGo: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/getgo.png`,
    shariot: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/shariot.png`,
    tribecar: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/tribecar.png`,
    hotSaleIcon: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/hot-sale.png`,
    happyUsers: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/happy-users.svg`,
    successfulTrips: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/successful-trips.svg`,
    slide1Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-1-thumb.png`,
    slide2Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-2-thumb.png`,
    slide3Thumbnail: `${CLOUDINARY_BASE_URL}/drivelah/anti-competition/slide-3-thumb.png`,
  },
  aboutUs: {
    driveMateLogo: `${CLOUDINARY_BASE_URL}/drivelah/aboutus/driveMateLogo.png`,
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/heroImage.webp`,
  },
  bLanding: {
    appStoreBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/appstore-badge.svg`,
    flexColor: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/flex-color.svg`,
    googlePlayBadge: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/google-play-badge.png`,
    lightBg: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/light-bg.svg`,
    point1: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-1.svg`,
    point2: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-2.svg`,
    point3: `${CLOUDINARY_BASE_URL}/drivelah/b-landing/point-3.svg`,
  },
  grabBanner1: {
    arrowBlue: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-blue.svg`,
    arrowYellow: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/arrow-right-yellow.svg`,
    grabIcon: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/grab-icon.svg`,
    donationBG: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/Logo-back-svg.png`,
    driveToMalaysia: `${CLOUDINARY_BASE_URL}/drivelah/grab-banner/malaysia.svg`,
  },
  seoLandingPage: {
    cars: {
      audi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/audi.jpg`,
      bmw: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/bmw.jpg`,
      honda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/honda.jpg`,
      hyundai: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/hyundai.jpg`,
      mazda: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mazda.jpg`,
      mercedez: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mercedez.jpg`,
      mini: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mini.jpg`,
      mitsubishi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/mitsubishi.jpg`,
      porsche: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/porsche.jpg`,
      toyota: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/toyota.jpg`,
      volvo: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cars/volvo.jpg`,
    },
    location: {
      angMoKoi: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/ang-mo-koi.jpg`,
      bukitBatok: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-batok.jpg`,
      bukitPanjang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/bukit-panjang.jpg`,
      choaChuKang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/choa-chu-kang.jpg`,
      hougang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/hougang.jpg`,
      jurong: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/jurong.jpg`,
      punggol: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/punggol.jpg`,
      sembawang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sembawang.jpg`,
      sengkang: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/sengkang.jpg`,
      serangoon: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/serangoon.jpg`,
      tampines: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/tampines.jpg`,
      woodlands: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/woodlands.jpg`,
      yishun: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/location/yishun.jpg`,
    },
    segment: {
      compact: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/compact.jpg`,
      luxurySedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-sedan.jpg`,
      luxurySuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/luxury-suv.jpg`,
      standardSedan: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-sedan.jpg`,
      standardSuv: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/segment/standard-suv.jpg`,
    },
    cna: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/cna.png`,
    vulcanPost: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/vulcan.png`,
    theStraitsTime: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/straits.png`,
    zaobao: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/zao.png`,
    landingBannerImage: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/LandingBanner.png`,
    seoLandingVideoBanner: `${CLOUDINARY_BASE_URL}/drivelah/SeoLandingPage/videoLogo.jpg`,
  },
  becomeAHost: {
    insuranceLogo: `${CLOUDINARY_BASE_URL}/drivelah/becomeAHost/insuranceLogo-min.png`,
  },
  contact: {
    heroImage: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact_background-min.jpg`,
    heroImageMobile1x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@1x.jpg`,
    heroImageMobile2x: `${CLOUDINARY_BASE_URL}/drivelah/contact/contact-background-mobile@2x.jpg`,
  },
};

export default imageSource;
