import { initialState } from './state';
import * as Actions from './actions';
import {
  REQUEST_STATUS__ERROR,
  REQUEST_STATUS__NULL,
  REQUEST_STATUS__PENDING,
  REQUEST_STATUS__SUCCESS,
} from '../../../util/constants';

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case Actions.CANCEL_UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        acceptRequestStatus: REQUEST_STATUS__NULL,
        cancelRequestStatus: REQUEST_STATUS__PENDING,
      };

    case Actions.CANCEL_UPDATE_BOOKING_SUCCESS:
      return { ...state, cancelRequestStatus: REQUEST_STATUS__SUCCESS };

    case Actions.CANCEL_UPDATE_BOOKING_ERROR:
      return { ...state, cancelRequestStatus: REQUEST_STATUS__ERROR };

    case Actions.ACCEPT_UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        cancelRequestStatus: REQUEST_STATUS__NULL,
        acceptRequestStatus: REQUEST_STATUS__PENDING,
      };

    case Actions.ACCEPT_UPDATE_BOOKING_SUCCESS:
      return { ...state, acceptRequestStatus: REQUEST_STATUS__SUCCESS };

    case Actions.ACCEPT_UPDATE_BOOKING_ERROR:
      return { ...state, acceptRequestStatus: REQUEST_STATUS__ERROR };

    default:
      return state;
  }
}
