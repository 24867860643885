import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { Button, ListingLink, ToggleSwitch } from '..';
import {
  Modal,
} from '../../components';
import { BblQuoteComponent } from "./Modal-content/BblQuote"
import { BblKeyComponent } from "./Modal-content/Bblkey"
import { Drivemateprotection } from "./Modal-content/Drivemateprotection"

import css from './EditListingInsurancePanel.css';
import { get } from 'lodash';
import gtImg from '../../assets/insurance-panel/gt-logo.png';
import allianz from '../../assets/insurance-panel/allianz-logo.svg';
import check from '../../assets/insurance-panel/check.png';
import percent from '../../assets/insurance-panel/percent.png';
import AlertBox from '../AlertBox/AlertBox';
class EditListingInsurancePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizationDocumentUrl: null,
      insuranceDocumentUrl: null,
      isOpenBBLQuote: false,
      isOpenReadMore: false,
      isOpenViewPolicy: false,
      bblQuotevalue: '',
      toggleButtonChangeError: false,
      getQuotesError: false,
      submitBtnInProgress: false,

     };
     this.setAuthorizationDocumentUrl = this.setAuthorizationDocumentUrl.bind(this);
     this.setInsuranceDocumentUrl = this.setInsuranceDocumentUrl.bind(this);
     this.setSubmitBtnInProgress = this.setSubmitBtnInProgress.bind(this);
     this.setToggleButtonChange = this.setToggleButtonChange.bind(this);
     this.setGetQuotesError = this.setGetQuotesError.bind(this);
     this.setBblQuotevalue = this.setBblQuotevalue.bind(this);
     this.handleQuoteSubmit = this.handleQuoteSubmit.bind(this);
     
  }

  componentDidMount() {
    this.setState({
      bblQuotevalue: get(this.props.listing, 'attributes.privateData.betweenBookingInsurance.bbiPriceWhenSelected', ''),
    })
  }

  setAuthorizationDocumentUrl(data) {
    this.setState({
      authorizationDocumentUrl: data
    });
  }

  setInsuranceDocumentUrl(data) {
    this.setState({
      insuranceDocumentUrl: data
    });
  }

  setSubmitBtnInProgress() {
    this.setState({
      submitBtnInProgress: true
    })
  }

  setToggleButtonChange() {
    this.setState({
      toggleButtonChangeError: true
    });
  }

  setGetQuotesError() {
    this.setState({
      getQuotesError: true
    });
  }

  setBblQuotevalue(value) {
    this.setState({ bblQuotevalue: value });
  }

  handleQuoteSubmit() {
    const { onSubmit, listing } = this.props;

    // Prepare the update data in the specified format
    if (this.state.bblQuotevalue) {
      const updateValues = {
        privateData: {
          betweenBookingInsurance: {
            bbiSelected: true,
            bbiPriceWhenSelected: +this.state.bblQuotevalue, // Ensure it's a number
            bbiSelectedOn: new Date().toISOString().split('T')[0], // ISO date format, YYYY-MM-DD
          },
        },
        publicData: {
          insurance:'private',
        },
      }
      onSubmit(updateValues, listing.id); // Pass update values and listing ID to the onSubmit function
      this.setSubmitBtnInProgress();
    } else {
      const updateValues = {
        privateData: {
          betweenBookingInsurance: {},
        },
        publicData: {
          insurance:'private',
        },
      }
      onSubmit(updateValues, listing.id); // Pass update values and listing ID to the onSubmit function
      this.setSubmitBtnInProgress();
    }
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      isNewListingFlow,
      submitButtonId,
      onManageDisableScrolling
    } = this.props;

    const subscriptionType = get(listing, 'attributes.publicData.subscriptionPlan', '');    

    const isBBIEnabled = get(listing, 'attributes.privateData.betweenBookingInsurance.bbiEnabled', false);

    // const bbiValue = get(listing, 'attributes.privateData.betweenBookingInsurance.bbiPriceWhenSelected', '');

    const getQuoteButton = () => {
      (subscriptionType === 'best-mates') ? this.setState({ isOpenBBLQuote: true }) : this.setGetQuotesError();
    }

    const onToggleChangeHandler = () => {
      isBBIEnabled ? this.setToggleButtonChange() : this.setBblQuotevalue('');
    }

    const helpCenterBtn = (
      <>
        <button
          onClick={() => {
            this.props.redirectionProp('/contact-us');
          }}>
          Help Center
        </button>
      </>
    );
    const goBackToSubscription = (
      <>
        <button
          onClick={() => {
            this.props.redirectionProp(`subscription`);
          }}>
          Go to the Subscription step
        </button>
      </>
    );

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingInsurancePanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingInsurancePanel.createListingTitle" />
    );

    const insuranceLayout = (
      <div className={classes}>
        <div className={css.titleSection}>
          <h1 className={`${css.title} ${css.mainText}`}>Insurance</h1>
          <p>All trips are comprehensively insured. Add BBI for
          Between-Booking Coverage.</p>  
        </div>

        <div className={`${css.titleSection} ${css.insuredByWrapper}`}>
          <p>
          While on any trip, your vehicle is comprehensively insured by Drive mate.
          </p>
          <a onClick={() => this.setState({ isOpenReadMore: true })} >Read more</a>
          <div className={css.logoWrapper}>
            <span>Insured by</span>
           <div className={css.logoImgWrapper}>
           <img src={gtImg} alt='GT Insurance Logo' />
           <img src={allianz} alt='Allianz Insurance Logo' />
           </div>
          </div>
        </div>

        <div className={`${css.titleSection} ${css.BookingInsurenceWrapper}`}>
          <h3>Opt for Between Booking Insurance (BBI)</h3>
          <p>Get complete peace of mind, with comprehensive insurance while your vehicle is not on a Drive mate trip as well.<br/>Benefits of BBI:</p>
          <ul>
            <div className={css.listWrapper}>
              <img src={percent} alt='Percentage icon'/>
              <li>Drive mate fees reduced to <b className={css.boldText}>15%</b> (from 25%).</li>
            </div>
            <div className={css.listWrapper}>
              <img src={check} alt='Check icon'/>
              <li>Between Booking Insurance</li>
            </div>
          </ul>
          <a onClick={() => this.setState({ isOpenViewPolicy: true })}>View policy summary</a>

          {(this.state.bblQuotevalue) && (
            <>
              <div className={css.resultContainer}>
                <div>
                  <span className={css.priceText}><span>$</span>{this.state.bblQuotevalue}</span>
                  <span className={css.monthText}>/month</span>
                </div>
                <div>
                  <ToggleSwitch status={this.state.bblQuotevalue} onChange={onToggleChangeHandler} />
                </div>
              </div>
              {!isBBIEnabled && (
              <a onClick={() => this.setState({ isOpenBBLQuote: true })}>Update quote value</a>
              )}
            </>
          )}

          {(!this.state.bblQuotevalue) && (
            <button
              className={css.getQuoteBtn}
              onClick={getQuoteButton}
            >
              Starting from $120/month. Click here to proceed.
            </button>
          )
          }
        </div>

        {this.state.toggleButtonChangeError && (
          <AlertBox
            title='Contact support to disable BBI'
            message='BBI can only be disabled via the Support team. Please get in touch and they will be happy to assist.'
            type="info"
            button={helpCenterBtn}
          />
        )}
        {this.state.getQuotesError && (
          <AlertBox
            title='BBI is only available on Best Mates'
            message='If you want to enable BBI for your listing, please switch to the Best mates plan. '
            type="info"
            button={goBackToSubscription}
          />
        )}

        <div className={`${css.titleSection} ${css.instructionsSection}`}>
          {/* <p>
          Learn more about the insurance options here - <a href="https://help.drivemate.au/kb/en/guide/between-booking-insurance-bbi-j9f76VsImC/Steps/3799171" target='_blank'>What is the right insurance for me?</a></p> */}
          <p>You can turn off BBI any time. Contact our host
          experience team for more info.</p>
        </div>
        <div>
          {/* Modal for BBL Quote */}
          <Modal
            id="bblQuoteModal"
            isOpen={this.state.isOpenBBLQuote}
            onClose={() => this.setState({ isOpenBBLQuote: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            customContainerClassName = {css.containerClassName}
            isMobilePopUp = {true}
          >
            <BblQuoteComponent
            // className
            setBblQuotevalue={this.setBblQuotevalue}
            onOptIn={this.handleQuoteSubmit}
            onClose={() => this.setState({ isOpenBBLQuote: false })}/>
          </Modal>
        </div>
        <div>
          {/* Other Modals */}
          <Modal
            id="viewPolicyModal"
            isOpen={this.state.isOpenViewPolicy}
            onClose={() => this.setState({ isOpenViewPolicy: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            customContainerClassName = {css.containerClassName}
            isMobilePopUp = {true}
          >
            <BblKeyComponent className/>
          </Modal>
        </div>
        <div>
          <Modal
            id="readMoreModal"
            isOpen={this.state.isOpenReadMore}
            onClose={() => this.setState({ isOpenReadMore: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            customContainerClassName = {css.containerClassName}
            isMobilePopUp = {true}
          >
            <Drivemateprotection className/>
          </Modal>
        </div>
        <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}></div>
                  <div className={css.stickButton} onClick={this.handleQuoteSubmit}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      // inProgress={this.state.submitBtnInProgress}
                      inProgress={updateInProgress}
                      // disabled={submitDisabled}
                      // ready={submitReady}
                      // id={submitButtonId}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
      </div>
    );

    return insuranceLayout;
  }
}

EditListingInsurancePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingInsurancePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isNewListingFlow: bool.isRequired,
  submitButtonId: string,
  onManageDisableScrolling: func.isRequired,
};

export default EditListingInsurancePanel;
