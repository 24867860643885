import React from 'react';
import css from './BrandingPage.css';
import classNames from 'classnames';
import fullyCovered from '../../assets/fullyCovered.svg';
import keepMore from '../../assets/keepMore.svg';
import technology from '../../assets/technology.svg';
import support from '../../assets/support.svg';
import earn from '../../assets/earn.svg';

const Guarantee = () => {
  return (
    <div className={classNames(css.landingContainer, css.guaranteeContainer)}>
      <div className={css.row}>
        <div className={css.contentTitle}>
          <h2>Here’s what we’ll guarantee when you.</h2>
          <p>list your car with Drive mate as well</p>
        </div>
        <div className={css.cards}>
          <div className={css.cardContent}>
            <div className={css.card}>
              <img src={earn} />
              <h4 className={css.cardTitle}>Better Earnings</h4>
              <p className={css.cardPara}>
                Drive mate hosts make on average 20% higher earnings compared to other car sharing
                apps.
              </p>
            </div>

            <div className={css.card}>
              <img src={fullyCovered} />
              <h4 className={css.cardTitle}>You’re Fully-Covered</h4>
              <p className={css.cardPara}>
              You are protected from vehicle theft or damage due to accident, fire or storm, or malicious damage.
              </p>
            </div>

            <div className={css.card}>
              <img src={keepMore} />
              <h4 className={css.cardTitle}>Hosts Keep More</h4>
              <p className={css.cardPara}>
                We’ll only take about half the cut of similar platforms.
              </p>
            </div>

            <div className={css.card}>
              <img src={technology} />
              <h4 className={css.cardTitle}>Exclusive Keyless In-app Technology</h4>
              <p className={css.cardPara}>
                No more lockboxes and accessibility hassles with remote pick up confirmation,
                immobilisation features and advanced telemetry data monitoring.
              </p>
            </div>

            <div className={css.card}>
              <img src={support} />
              <h4 className={css.cardTitle}>24/7 Support Team</h4>
              <p className={css.cardPara}>
                We understand the unpredictable occurs at any time. Enjoy better peace of mind
                knowing you can count on us to assist when needed, not just during business hours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
