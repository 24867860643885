import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-Alexandria',
    predictionPlace: {
      key: "journeyh",
      address: 'Alexandria, Sydney',
      description: 'Alexandria, Sydney',
      place_id: "ChIJa0nQNrSxEmsRMKsyFmh9AQU",
      origin: new LatLng(-33.8688197, 151.2092955),
      bounds: new LatLngBounds(new LatLng(-33.5781409, 151.3430209), new LatLng(-34.118347, 150.5209286)),
    },
  },
  {
    id: 'default-Surry-Hills',
    predictionPlace: {
      key: "journeyhorizon",
      address: 'Surry Hills, Sydney',
      place_id: "ChIJW7w1-SGuEmsRkMwyFmh9AQU",
      description: 'Surry Hills, Sydney',
      origin: new LatLng(-33.8688197, 151.2092955),
      bounds: new LatLngBounds(new LatLng(-33.5781409, 151.3430209), new LatLng(-34.118347, 150.5209286)),
    },
  },
  {
    id: 'default-Marrickville',
    predictionPlace: {
      key: "journeyhor",
      address: 'Marrickville, Sydney',
      place_id: "ChIJGUHmdmSwEmsREMAyFmh9AQU",
      description: 'Marrickville, Sydney',
      origin: new LatLng(-33.8688197, 151.2092955),
      bounds: new LatLngBounds(new LatLng(-33.5781409, 151.3430209), new LatLng(-34.118347, 150.5209286)),
    },
  },
  {
    id: 'default-Brunswick',
    predictionPlace: {
      key: "journeyhorizo",
      address: 'Brunswick, Melbourne',
      description: 'Brunswick, Melbourne',
      place_id: "ChIJaamxJ7lc1moRsNWMIXVWBAU",
      origin: new LatLng(-37.8136276, 144.9630576),
      bounds: new LatLngBounds(new LatLng(-37.5112737, 145.5125288), new LatLng(-38.4338593, 144.5937418)),
    },
  },
  {
    id: 'default-Carlton',
    predictionPlace: {
      key: 'journey',
      address: 'Carlton, Melbourne',
      place_id: "ChIJ0S2YU9FC1moRgOeMIXVWBAU",
      description: 'Carlton, Melbourne',
      origin: new LatLng(-37.8136276, 144.9630576),
      bounds: new LatLngBounds(new LatLng(-37.5112737, 145.5125288), new LatLng(-38.4338593, 144.5937418)),
    },
  },
];
