import React, { useEffect, useRef, useState } from 'react';
import css from './GrabRentACarLandingPage.css';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import searchIcon from '../../assets/searcIcon.png';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const answerFormat = (
  <div>
    <div>You can be a Grab driver if you:</div>
    <ul className={css.answerInPoints}>
      <li>1. are at least 30 years old</li>
      <li>2. have a valid TDVL or PDVL</li>
      <li>3. have a minimum of 1-year driving experience</li>
      <li>4. are an Australian citizen</li>
      <li>5. completed Vehicle Safety &amp; Maintenance &amp; Customer Service &amp; Quality</li>
      <li>6. achieved a passing grade for Safety &amp; Quality driving assessment</li>
    </ul>
  </div>
);

const datas = [
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.one.question" />,
    answer:
      "It is a peer to peer car sharing platform. This means that people who own a car can rent out their car to fellow Australian when they don't need their car. The trip will be comprehensively insured for the host's and guest's peace of mind.",
  },
  {
    question: 'Am I eligible to drive for Grab?',
    answer: answerFormat,
  },
  {
    question: <FormattedMessage id="LandingPage.SectionHelp.questionAnswer.three.question" />,
    answer:
      'Drive mate is the most convenient access to cars in Australia. We offer the largest variety of cars nearby at a click of a button. Drive mate utilizes unused car hours of individual car owners and that makes rentals on Drive mate typically 30-40% cheaper than traditional rental companies.',
  },
];

const QuestionAnswer = ({ question, answer, onClick, showAnswer, ...rest }) => {
  return (
    <div className={css.questionAnswer} {...rest}>
      <div className={css.question} onClick={onClick}>
        {question}
      </div>
      <div
        className={classNames(css.answer, {
          [css.showAnswer]: showAnswer,
        })}
      >
        {answer}{' '}
      </div>
    </div>
  );
};

const SectionHelp = ({ data }) => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const inputRef = useRef(null);

  const handleClickQuestion = i => {
    if (openQuestion === i) setOpenQuestion(null);
    else setOpenQuestion(i);
  };

  useEffect(() => {
    inputRef.current.addEventListener('keyup', e => {
      if (e.keyCode === 13) {
        window.open(`https://help.${DOMAIN_HELP_URL}/kb/en/?q=${e.target.value}`);
      }
    });
  }, []);

  return (
    <div className={css.sectionHelp}>
      <div className={css.sectionHelpTitle}>Frequently Asked Questions</div>
      <div className={css.searchHelp}>
        <img src={searchIcon} className={css.searchIcon} />
        <input
          className={css.searchHelpInput}
          placeholder="Eg, Who can be a host?"
          ref={inputRef}
        />
      </div>
      <div className={css.helpQuestions}>
        {(data || datas).map((d, index) => (
          <QuestionAnswer
            {...d}
            key={index}
            index={index}
            showAnswer={openQuestion === index}
            onClick={() => handleClickQuestion(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionHelp;
