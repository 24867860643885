import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import moment from 'moment';
import React, { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { daysBetween } from '../../../util/dates';
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../../util/types';
import css from '../BookingBreakdown.css';

const BookingPeriod = props => {
  const { isSingleDay, startDate, endDate, timeZone } = props;
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  if (isSingleDay) {
    return <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />;
  }

  return (
    <FormattedMessage
      id="BookingBreakdown.bookingPeriod"
      values={{
        bookingStart: (
          <span className={css.nowrap}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
        newLine: <br />,
        bookingEnd: (
          <span className={css.nowrap}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </span>
        ),
      }}
    />
  );
};

const LineItemLongTermBookingPeriod = memo(
  props => {
    const { unitType, timezone, transaction } = props;
    if (!transaction) return null;

    const displayStart = get(transaction, 'attributes.protectedData.bookingDisplayStart');
    const displayEnd = get(transaction, 'attributes.protectedData.bookingDisplayEnd');

    const realStartDate = moment(displayStart).toDate();
    const realEndDate = moment(displayEnd).toDate();

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isUnits = unitType === LINE_ITEM_UNITS;

    const dayCount = daysBetween(displayStart, displayEnd);
    const isSingleDay = !isNightly && !isUnits && dayCount === 1;

    return (
      <div className={css.lineItem}>
        <span className={css.bookingPeriodText}>
          <BookingPeriod
            isSingleDay={isSingleDay}
            startDate={realStartDate}
            endDate={realEndDate}
            timeZone={timezone}
          />
        </span>
      </div>
    );
  },
  (prev, next) => isEqual(prev.booking, next.booking)
);

LineItemLongTermBookingPeriod.propTypes = {
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
};

export default LineItemLongTermBookingPeriod;
