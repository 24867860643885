import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Button,
  FieldCheckbox,
  FieldTextInput,
  Form,
  InlineTextButton,
  Modal,
} from '../../components';
import css from './EditListingTrackingDeviceForm.css';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';

export const EditListingTrackingDeviceFormComponent = props => {

  const [isShowTerms, setShowTerms]= useState(false);

  return (<FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        onManageDisableScrolling,
        isAgreeState,
        setAgree,
      } = formRenderProps;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const termsLabel = intl.formatMessage({ id: "EditListingTrackingDeviceForm.termsLabel" });
      const trackingDeviceIdLabel = intl.formatMessage({ id: "EditListingTrackingDeviceForm.trackingDeviceIdLabel" });
      const requiredTrackingDeviceId = intl.formatMessage({ id: "EditListingTrackingDeviceForm.requiredTrackingDeviceId" });

      const handelAgreeChange = e => {
        const checked = e.target.checked;
        setAgree(checked);
      }

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingTrackingDeviceForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingTrackingDeviceForm.showListingFailed" />
            </p>
          ) : null}

          {/*<h2>*/}
          {/*  <FormattedMessage id="EditListingTrackingDevicePanel.whatIsTracking" />*/}
          {/*</h2>*/}
          <InlineTextButton type="button" rootClassName={css.btnTerms} onClick={() => setShowTerms(true)}>
            <FormattedMessage id="EditListingTrackingDevicePanel.termsAndConditions" />
          </InlineTextButton>

          <FieldCheckbox
            id={"agree"}
            name={"agree"}
            label={termsLabel}
            className={css.checkBoxAgree}
            value={"agree"}
            checked={isAgreeState}
            onChange={handelAgreeChange}
          />

          {
            isAgreeState ? (
              <FieldTextInput
                name={"trackingDeviceId"}
                id={"trackingDeviceId"}
                label={trackingDeviceIdLabel}
                className={css.trackingDeviceId}
                validate={required(requiredTrackingDeviceId)}
              />
            ) : null
          }

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

          <Modal
            id="EditListingTrackingDevice.modalTermsAndConditions"
            isOpen={isShowTerms}
            onClose={() => setShowTerms(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <>
              <article dir="ltr"><p>Drive mate aims to set the industry standard when it comes to trust &amp; transparency. That is why it has made it mandatory to have a tracking device plugged in (either Drive mate tracking device or Drive mate GO installed) at all times during a Drive mate trip.<br /><br />Every host can request a tracking device from Drive mate. Based on the eligibility criteria Drive mate can provide you one. For every car that has been rented out at least 1x on the Drive mate platform you can get one tracking device. Drive mate GO cars are not eligible, because they are already tracked at all times during Drive mate trips besides offering keyless to guests.</p>
                <p>Usage and costs:</p>
                <ol>
                  <li>At all times during a trip the tracking device must be plugged into the auxiliary power outlet at all times. Remember - it is a simple measure to drive trust &amp; transparency between host &amp; guest.</li>
                  <li>We strongly encourage guests to check if the device is plugged in correctly before the trip starts. Do check with your host if the device is available &amp; working. If there is an issue, please report it to our customer service team before the trip begins.</li>
                  <li>For the first 6 months this service is free for all private hosts. After 6 months you can either return the device for free or continue using it at $15 per month.</li>
                  <li>Commercial hosts can get a tracking device as well at $15 per month.</li>
                  <li>If the device is damaged or not working due to misuse of the device then the host will be charged $100.&nbsp;</li>
                  <li>At Drive mate's discretion we can take back the tracking device if a car with tracking device has not been rented out for more than 1 month.</li>
                </ol>
                <p>Charges for non-adherence to policy:</p>
                <ol>
                  <li>For every trip day that the device is found not plugged in, we levy a charge of $25 to the guest.&nbsp;</li>
                  <li>If the host has a device and does not provide the same to the guest, then a charge of $25 per day will be levied on the host.</li>
                </ol>
                <p />
              </article>
              <FieldCheckbox
                id={"agree"}
                name={"agree"}
                label={termsLabel}
                className={css.checkBoxAgree}
                value={"agree"}
                checked={isAgreeState}
                onChange={handelAgreeChange}
              />
            </>
          </Modal>
        </Form>
      );
    }}
  />
  );
}


EditListingTrackingDeviceFormComponent.defaultProps = { fetchErrors: null };

EditListingTrackingDeviceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingTrackingDeviceFormComponent);
