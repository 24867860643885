import React from 'react';
import classNames from 'classnames';
import css from './BrandingPage.css';
import { useHistory, useLocation } from 'react-router-dom';
import { types } from '../../util/sdkLoader';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrimaryButton } from '../../components';
import bannerImg from '../../assets/landingpage/banner_rightImg.png';
import bannerImgMobile from '../../assets/landingpage/banner_ImgMobile1.png';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import config from '../../config';

const { Money } = types;

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};

const Content = ({ className }) => {
  return (
    <div className={classNames(css.heroContent, className)}>
      <div className={css.row}>
        <div>
          <CheckMark />
        </div>
        <div className={css.heroContentBrandText}>
          Up to 20% higher than other car sharing platforms
        </div>
      </div>
      <div className={css.row}>
        <div>
          <CheckMark />
        </div>
        <div className={css.heroContentBrandText}>You’re Fully-Covered</div>
      </div>
      <div className={css.row}>
        <div>
          <CheckMark />
        </div>
        <div className={css.heroContentBrandText}>Genuine Renters Only</div>
      </div>
      <div className={css.row}>
        <div>
          <CheckMark />
        </div>
        <div className={css.heroContentBrandText}>Proprietary In-Car Technology</div>
      </div>
    </div>
  );
};

const HostSectionHeroBrandingPartners = ({ signUpCodeAmount }) => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { code } = parse(location.search);
  const redirectToSignup = () => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };

  return (
    <div className={css.BrandHero}>
      <div className={css.landingContainer}>
        <h1 className={classNames(css.heroBrandingTitle, css.heroTitleMobile)}>
          Let your car work <br /> for you!
        </h1>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          <div className={classNames(css.column6, css.flexCol)}>
            <div className={css.bannerContent}>
              <h1 className={classNames(css.heroBrandingTitle, css.heroTitleDesk)}>
                Let your car work <br /> for you!
              </h1>
              <p>
                Don’t use your car on certain days of the week? Share it with your neighbours for
                cash!
              </p>
              <Content />
              <PrimaryButton type="button" onClick={redirectToSignup}>
                {signUpCodeAmount ? (
                  <FormattedMessage
                    id="HostSectionHeroPartners.SignupNowButtonMessage"
                    values={{
                      amount:
                        signUpCodeAmount &&
                        formatMoney(intl, new Money(signUpCodeAmount, config.currency)),
                    }}
                  />
                ) : (
                  <FormattedMessage id="brandingPartnerLandingPage.signUpNow" />
                )}
              </PrimaryButton>
            </div>
          </div>
          <div className={classNames(css.column6, css.rightCol)}>
            <div className={css.banner_dekstop}>
              <img src={bannerImg} alt=" " />
            </div>
            <div className={css.banner_mobile}>
              <img src={bannerImgMobile} alt=" " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostSectionHeroBrandingPartners;
