import React from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../components';
import { sendVerificationEmail } from '../../ducks/user.duck';

import { FormattedMessage } from '../../util/reactIntl';

import css from './EmailVerificationFormAccountSettings.css';

const EmailVerificationFormAccountSettings = ({ userEmail, modifyStep, handleSteps, step }) => {
  const dispatch = useDispatch();
  const guidance = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="EmailVerificationForm.guide" />
      </p>
    </div>
  );

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(sendVerificationEmail());
  };

  React.useEffect(() => {
    modifyStep && handleSteps(step, "Verify email Address");
  }, []);

  return (
    <div>
      {guidance}
      <label className={css.emailLabel}>Email ID</label>
      <form className={''} onSubmit={handleSubmit}>
        <div className={css.emailFormContainer}>
          <div className={css.emailWrapper}>
            <input
              className={css.emailField}
              id="email"
              name="email"
              type="text"
              value={userEmail}
            />
          </div>
          <div className={css.resendButtonWrapper}>
            <PrimaryButton type="submit" disabled={false}>
              <FormattedMessage id="EmailVerificationForm.resendEmailVerificationButtonText" />
            </PrimaryButton>
          </div>
        </div>
      </form>

      <div className={css.continueButtonWrapper}>
        <PrimaryButton onClick={() => window.location.reload()} disabled={false}>
          <FormattedMessage id="EmailVerificationForm.continueButtonText" />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default EmailVerificationFormAccountSettings;
