import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { apiBaseUrl } from '../../util/api';
import { pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { parse } from '../../util/urlHelpers';

import {
  isReferralCodeInvalid,
  isSignUpCodeInvalid,
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  IconClose,
  IconEmailSent,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  TermsOfService,
} from '../../components';
import { ConfirmSingupForm, LoginForm, SignupForm, SignupFormStep2 } from '../../forms';
import {
  authenticationInProgress,
  login,
  loginRequest,
  signup,
  signupStep1Success,
  signupWithIdp,
  update,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { pushGTMBrowseEvent, pushGTMSignupForm } from '../../util/gtm/gtmHelpers';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  EVENT_BROWSE_LOGGEDIN,
  EVENT_SIGNUP_OPEN_FORM,
  EVENT_SIGNUP_SUBMIT_FORM,
  SIGNUP_SUBMIT_BUTTON_ID,
  SIGNUP_UPDATE_SUBMIT_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import LogoImage from '../../assets/logos/drive-mate-long-blue.png';
import LogoImageWhite from '../../assets/logos/drive-mate-long-white.png';
import css from './AuthenticationPage.css';
import { checkIfReferralCode } from '../../util/referralProgram';
import { get } from 'lodash';
import leftImage from '../../assets/newSignUpDesign/newHero.jpg';
import confirmLeftImage from '../../assets/step_2.jpg';
import { gSend, GTAG_ACTIONS, GTAG_METHODS } from '../../util/gtag';
import { SignupPhoneExperiment } from '../../util/constants';

export class AuthenticationPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      isReferralInvalid: false,
      data: null,
      newAuth: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      shouldShowReferralCodeLoadingScrn: false,
      isLoginPage: true,
      referralCode: null,
    };
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    if (queryParams && queryParams.code && typeof window !== 'undefined') {
      window.localStorage.setItem('code', queryParams.code);
    }

    if (typeof window !== 'undefined') {
      const code = window.localStorage.getItem('code');
      const codeType = window.localStorage.getItem('codeType');
      if (code) {
        this.setState({
          referralCode: code,
          codeType: codeType
        });
      }
    }

    const { isAuthenticated, tab } = this.props;
    if (!isAuthenticated && tab === 'signup') {
      const properties = createRawPropertiesForGTM({
        props: this.props,
      });

      pushGTMSignupForm(properties, EVENT_SIGNUP_OPEN_FORM);
    }
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
  }

  render() {
    const {
      authInProgress,
      currentUser,
      intl,
      isAuthenticated,
      location,
      submitSignupWithIdp,
      loginError,
      scrollingDisabled,
      signupError,
      submitLogin,
      history,
      submitSignup,
      signupSuccess,
      submitSignupStep1,
      tab,
      state,
      submitUpdate,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      requestLogin,
      onManageDisableScrolling,
      confirmError,
    } = this.props;
    let error;
    const isConfirm = tab === 'confirm';
    const isLogin = tab === 'login';
    const isSignup = tab === 'signup';
    const locationFrom = location.state && location.state.from ? location.state.from : null;
    const authinfoFrom =
      this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;
    const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;
    const isFromListingPage = location.state && location.state.isFromListingPage;
    const isFromCampaign = !!parse(location.search).campaign;
    const signUpCode = parse(location.search).code;

    const { email: signUpEmail = null, password = null } = get(location, 'state', {}) || {};

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const shouldRedirect = user.id && user.attributes.profile.protectedData.phoneNumber;

    if (isAuthenticated && isFromListingPage && shouldRedirect) {
      return (
        <NamedRedirect name="ListingPage" state={location.state} params={location.state.params} />
      );
    }

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

    // Already authenticated, redirect away from auth page
    if (isAuthenticated && from) {
      return <Redirect to={from} />;
    } else if (isAuthenticated && currentUserLoaded) {
      return <NamedRedirect name="LandingPage" />;
    }

    // if (isAuthenticated && isFromListingPage) {
    //   return (
    //     <NamedRedirect name="ListingPage" state={location.state} params={location.state.params} />
    //   );
    // }

    const handleChange = value => {
      this.setState({ isLoginPage: value });
    };

    const handleSubmitUpdate = values => {
      // const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
      submitUpdate(values);
      // this.setState({initPage: true})
    };

    // if (signupError) {
    //   // error = <p>{signupError?.apiErrors[0].title}</p>
    // }
    // const aaa = signupError ? pathByRouteName('SignupUpdatePage', routeConfiguration()) : null;
    // Already authenticated, redirect away from auth page
    // if (isAuthenticated && from) {
    //   return <Redirect to={from} />;
    // } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    //   if (window && signUpCode && checkIfReferralCode(signUpCode)) {
    //     window.alert('You can only use the referral link when you are not logged in');
    //   }
    //   return <NamedRedirect name="LandingPage" />;
    // }
    // if (isAuthenticated && loginTab) {
    //   return (
    //     <NamedRedirect name="LandingPage" />
    //   );
    //     }
    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignUpCodeInvalid(signupError) && (
          <FormattedMessage id="AuthenticationPage.SignUpCodeInvalid" />
        )}
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );
    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : isReferralCodeInvalid(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.referralError" />
        ) : (
          <FormattedMessage
            id="AuthenticationPage.signupConflictError"
            values={{
              link: (
                <NamedLink name={'LoginPage'}>
                  <FormattedMessage id={'AuthenticationPage.loginLink'} />
                </NamedLink>
              ),
            }}
          />
        )}
      </div>
    ) : null;

    // eslint-disable-next-line no-confusing-arrow
    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const fromState = {
      state: from ? { from } : null,
      search: signUpCode ? `code=${signUpCode}` : null,
    };

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;
      const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
      submitSignup(params);
      localStorage.setItem('step2', true);
      const properties = createRawPropertiesForGTM({
        props: this.props,
        button: {
          buttonId: SIGNUP_SUBMIT_BUTTON_ID,
        },
      });
      pushGTMSignupForm(properties, EVENT_SIGNUP_SUBMIT_FORM);
      gSend(GTAG_ACTIONS.ACTION_SIGNUP, { method: GTAG_METHODS.WEB_SIGNUP });
    };

    const handleSubmitConfirm = values => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryParams = Object.fromEntries(urlSearchParams.entries());
      const { idpToken, idpId } = queryParams;

      const {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        // day,
        // month,
        // year,
        // gender,
        role,
        code,
        phoneCode,
        phoneNumber,
      } = values;

      // const dateOfBirth = {
      //   day: Number(day),
      //   month: Number(month),
      //   year: Number(year)
      // }

      requestLogin();

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      let authParams = {
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        protectedData: {
          // phoneNumber: phoneCode + phoneNumber,
          // phoneObj: {
          //   phoneCode,
          //   rawNumber: phoneNumber
          // },
          // dateOfBirth,
          // gender,
          role,
        },
        code,
      };

      // Handle the phone number if it's given
      if (phoneCode && phoneNumber) {
        authParams.protectedData.phoneNumber = `${phoneCode}${phoneNumber}`;
        authParams.protectedData.phoneObj = {
          phoneCode,
          rawNumber: phoneNumber
        };
      }

      // If the confirm form has any additional values, pass them forward as user's protected data
      // const protectedData = !isEmpty(rest) ? { ...rest } : null;

      submitSignupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        // ...(!!protectedData && { protectedData }),
      });
    };

    const handleSubmitLogin = values => {
      submitLogin(values).then(res => {
        gSend(GTAG_ACTIONS.ACTION_LOGIN, { method: GTAG_METHODS.WEB_LOGIN });
        pushGTMBrowseEvent({
          props: this.props,
          event: EVENT_BROWSE_LOGGEDIN,
        });
      });
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = from ? `from=${from}` : '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };
    const authWithFacebook = (action, method) => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
      gSend(action, { method });
    };

    const authWithGoogle = (action, method) => {
      const defaultRoutes = getDefaultRoutes();
      const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
      window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
      gSend(action, { method });
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null;

    const urlSearchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    const { email: userEmail, firstName, lastName } = queryParams;

    const initialValues = {
      email: userEmail,
      firstName,
      lastName,
      code: this.state.referralCode,
      codeType: this.state.codeType,
      phoneCode: '+61',
    };

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.confirmMobileWrapper}>
        <div className={css.authLogoSectionMobile}>
          <img onClick={() => (window.location.href = '/')} src={LogoImageWhite} alt="" />
        </div>

        <div className={css.mobileWrapper}>
          <h1 className={css.confirmAuthPageTitle}>
            Tell us a bit more about
            <br />
            yourself
          </h1>
          {confirmErrorMessage}
              <div>
                <ConfirmSingupForm
                  isReferralInvalid={this.state.isReferralInvalid}
                  experiment={SignupPhoneExperiment.B}
                  className={css.form}
                  location={location}
                  onSubmit={handleSubmitConfirm}
                  inProgress={authInProgress}
                  onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
                  authInfo={this.state.authInfo}
                  idp={idp}
                  initialValues={initialValues}
                />
              </div>
        </div>
      </div>
    );

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );

    const initEmail = parse(location.search).email;

    const name = user.attributes.profile.firstName;
    const email = <span className={css.email}>{user.attributes.email}</span>;

    const resendEmailLink = (
      <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </InlineTextButton>
    );
    const fixEmailLink = (
      <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </NamedLink>
    );

    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;
    const authenticationForms = (
      <div className={css.content}>
        {/* <LinkTabNavHorizontal className={css.tabs} tabs={tabs} /> */}
        <div className={css.authLogoSection}>
          <img onClick={() => (window.location.href = '/')} src={LogoImage} alt="" />
        </div>
        <div className={css.authLogoSectionMobile}>
          <img onClick={() => (window.location.href = '/')} src={LogoImageWhite} alt="" />
        </div>
        <div className={css.mobileWrapper}>
          <div className={css.authPageTitle}>
            {this.state.isLoginPage && tab === 'login' ? 'Welcome back' : 'Join Drive mate'}
          </div>

          {loginOrSignupError}
          {this.state.isLoginPage && tab === 'login' ? (
            <LoginForm
              className={css.form}
              google={() => authWithGoogle(GTAG_ACTIONS.ACTION_LOGIN, GTAG_METHODS.GOOGLE_LOGIN)}
              facebook={() => authWithFacebook(GTAG_ACTIONS.ACTION_LOGIN, GTAG_METHODS.FACEBOOK_LOGIN)}
              onSubmit={handleSubmitLogin}
              inProgress={authInProgress}
              handleChange={handleChange}
              location={location}
            />
          ) : signupSuccess ? (
            <SignupFormStep2
              className={css.form}
              onSubmit={data => handleSubmitUpdate({ ...data })}
              inProgress={authInProgress}
              submitButtonId={SIGNUP_UPDATE_SUBMIT_BUTTON_ID}
              handleChange={handleChange}
            />
          ) : (
            <div>
              {/* <p className={css.errorMessage}>{error}</p>{' '} */}
              <SignupForm
                className={css.form}
                onSubmit={data => {
                  handleSubmitSignup({ ...data, isFromCampaign: isFromCampaign });
                }}
                inProgress={authInProgress}
                google={() => authWithGoogle(GTAG_ACTIONS.ACTION_SIGNUP, GTAG_METHODS.GOOGLE_SIGNUP)}
                facebook={() => authWithFacebook(GTAG_ACTIONS.ACTION_SIGNUP, GTAG_METHODS.FACEBOOK_SIGNUP)}
                initEmail={initEmail || signUpEmail}
                onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
                submitButtonId={SIGNUP_SUBMIT_BUTTON_ID}
                initialValues={{ email: signUpEmail, password }}
                handleChange={handleChange}
                location={location}
              />
            </div>
          )}
        </div>
      </div>
    );

    // // const name = user.attributes.profile.firstName;
    //   // const email = <span className={css.email}>{user.attributes.email}</span>;

    //   const resendEmailLink = (
    //     <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
    //       <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    //     </InlineTextButton>
    //   );
    //   const fixEmailLink = (
    //     <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
    //       <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    //     </NamedLink>
    //   );

    //       const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    //     sendVerificationEmailError
    //   )
    //     ? 'AuthenticationPage.resendFailedTooManyRequests'
    //     : 'AuthenticationPage.resendFailed';
    //   const resendErrorMessage = sendVerificationEmailError ? (
    //     <p className={css.error}>
    //       <FormattedMessage id={resendErrorTranslationId} />
    //     </p>
    //   ) : null;

    const emailVerificationContent = (
      <div className={css.content}>
        <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
          <span className={css.closeText}>
            <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
          </span>
          <IconClose rootClassName={css.closeIcon} />
        </NamedLink>
        <IconEmailSent className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage
            id="AuthenticationPage.verifyEmailText"
            values={{ email, newline: <br /> }}
          />
        </p>
        {resendErrorMessage}

        <div className={css.bottomWrapper}>
          <p className={css.modalHelperText}>
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </p>
          <p className={css.modalHelperText}>
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </p>
        </div>
      </div>
    );
    const formContent = isConfirm ? confirmForm : authenticationForms;

    const siteTitle = config.siteTitle;
    const hasReferralCode = signUpCode && checkIfReferralCode(signUpCode);
    const schemaTitle = hasReferralCode
      ? intl.formatMessage({ id: 'AuthenticationPage.referralCodeSchemaTitle' })
      : this.state.isLoginPage && tab === 'login'
      ? 'Log in | Drive Mate offers more convenience & value for you to rent or share you car'
      : "Join Australia's largest car sharing community today. Sign up | Drive Mate";

    const schemaDescription = hasReferralCode
      ? intl.formatMessage({ id: 'AuthenticationPage.referralCodeSchemaTitle' })
      : isLogin
      ? 'Skip the typical car rental hassle; borrow cars from neighbours today. Rent hourly, daily or monthly - as per your needs with rentals as low as s $4/hour or $30/day. Log In | Drivemate'
      : 'Be it Sydney, Melbourne or any location you prefer across Australia - find & book cars easily. Join our peer-to-peer car sharing community to rent and share cars in Australia. Sign Up | Drivemate';

    const topbarClasses = classNames({
      [css.hideOnMobile]: showEmailVerification,
    });

    const loadingMsg = intl.formatMessage({ id: 'LoadingScreen.loadingMsg' });

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        rootClassName={css.contentWrapper}
        description={schemaDescription}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
          description: schemaDescription,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            {/* <TopbarContainer className={topbarClasses} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            {isConfirm ? (
              <div className={css.confirmRoot}>
                <div className={css.confirmAuthPageContent}>
                  <div className={css.confirmLeftBannerImage}>
                    <img src={confirmLeftImage} alt="" />
                  </div>
                  <div className={css.confirmRightContent}>
                    {showEmailVerification && !isSignup ? emailVerificationContent : formContent}
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.root}>
                <div className={css.authPageContent}>
                  <div className={css.leftBannerImage}>
                    <img src={leftImage} alt="" />
                  </div>
                  <div className={css.rightContent}>
                    {showEmailVerification && !isSignup ? emailVerificationContent : formContent}
                  </div>
                </div>
              </div>
            )}
            <Modal
              id="AuthenticationPage.tos"
              isOpen={this.state.tosModalOpen}
              onClose={() => this.setState({ tosModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>
                  <FormattedMessage id="AuthenticationPage.termsHeading" />
                </h2>
                <TermsOfService />
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>{/* <Footer /> */}</LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  confirmError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),
  submitSignupStep1: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  requestLogin: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  signupSuccess: bool.isRequired,
  confirmError: propTypes.error,
  // from withRouter
  location: shape({ state: object }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    isAuthenticated,
    loginError,
    signupError,
    signupSuccess,
    confirmError,
    signupStep1,
  } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    signupSuccess,
    signupStep1,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    state,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSignupStep1: params => dispatch(signupStep1Success(params)),
  submitUpdate: params => dispatch(update(params)),
  requestLogin: params => dispatch(loginRequest(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
