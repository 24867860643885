export const EVENT_SIGNUP_OPEN_FORM = 'dl_signup_open_form';
export const EVENT_SIGNUP_SUBMIT_FORM = 'dl_signup_submit_form';
export const EVENT_SIGNUP_VERIFIED_EMAIL = 'dl_signup_verified_email';
export const EVENT_SIGNUP_VERIFIED_PHONE = 'dl_signup_verified_phone';
export const EVENT_SIGNUP_PHONE_CASE_A = 'dl_signup_phone_experiment_case_a';
export const EVENT_SIGNUP_PHONE_CASE_B = 'dl_signup_phone_experiment_case_b';
export const EVENT_SIGNUP_SOCIAL_FACEBOOK = 'dl_signup_social_facebook';
export const EVENT_SIGNUP_SOCIAL_GOOGLE = 'dl_signup_social_google';
export const EVENT_SIGNUP_SOCIAL_APPLE = 'dl_signup_social_apple';
export const EVENT_GUEST_VERIFY_OPEN_DRIVER_FORM = 'dl_guestverify_open_driver_form';
export const EVENT_GUEST_VERIFY_UPLOAD_DRIVING_LICENSE = 'dl_guestverify_upload_driving_license';
export const EVENT_GUEST_VERIFY_UPLOAD_NRIC = 'dl_guestverify_upload_nric';
export const EVENT_GUEST_VERIFY_SUBMIT_DRIVER_FORM = 'dl_guestverify_submit_driver_form';

export const EVENT_HOST_VERIFY_ENTERED_NRIC = 'dl_host_verify_entered_nric';
export const EVENT_HOST_VERIFY_UPLOADED_PIC = 'dl_host_verify_uploaded_pic';
export const EVENT_HOST_VERIFY_PRESSED_SUBMIT = 'dl_host_verify_pressed_submit';
export const EVENT_HOST_VERIFY_STRIPE_SUCCESS = 'dl_host_verify_stripe_success';
export const EVENT_HOST_VERIFY_STRIPE_FAILURE = 'dl_host_verify_stripe_failure';

export const EVENT_BOOK_ENTERED_CARD_DETAILS = 'dl_book_entered_card_details';
export const EVENT_BOOK_SAVED_CARD_DETAILS = 'dl_book_saved_card_details';
export const EVENT_BOOK_ENTERED_ADDRESS = 'dl_book_entered_address';
export const EVENT_DELIVERY_ADDON_ADDED = 'dl_delivery_addon_added';
export const EVENT_DELIVERY_ADDON_REMOVED = 'dl_delivery_addon_removed';
export const EVENT_EXCESS_ADDON_ADDED = 'dl_excess_addon_added';
export const EVENT_EXCESS_ADDON_REMOVED = 'dl_excess_addon_removed';
export const EVENT_BOOKING_VAS_EXCESS_FROM = 'dl_booking_vas_excess_from';
export const EVENT_EXCESS_2_ADDON_ADDED = 'dl_excess_addon_2_added';
export const EVENT_EXCESS_2_ADDON_REMOVED = 'dl_excess_addon_2_removed';
export const EVENT_BOOK_EXCESS_2_SUCCESS_GUEST = 'dl_book_excess_2_success_guest';

export const EVENT_FUEL_ADDON_ADDED = 'dl_fuel_addon_added';
export const EVENT_FUEL_ADDON_REMOVED = 'dl_fuel_addon_removed';
export const EVENT_BOOK_FUEL_SUCCESS_GUEST = 'dl_book_fuel_success_guest';

export const EVENT_TRIP_EXCESS_2_ADDON_ADDED = 'dl_trip_vas_excess_2';
export const EVENT_TRIP_EXCESS_2_ADDON_REMOVED = 'dl_trip_vas_excess_2_removed';
export const EVENT_TRIP_VAS_EXCESS_2_FROM = 'dl_trip_vas_excess_2_from';

export const EVENT_TRIP_VAS_EXCESS = 'dl_trip_vas_excess';
export const EVENT_TRIP_BOOKING_VAS_EXCESS_REMOVED = 'dl_trip_vas_excess_removed';
export const EVENT_TRIP_VAS_EXCESS_FROM = 'dl_trip_vas_excess_from';

export const EVENT_TRIP_FUEL_INCLUSSION_ADDON_ADDED = 'dl_trip_vas_fuel';
export const EVENT_TRIP_FUEL_INCLUSSION_ADDON_REMOVED = 'dl_trip_vas_fuelremoved';
export const EVENT_TRIP_FUEL_SUCCESS_GUEST = 'dl_trip_vas_fuel_from';
export const EVENT_TRIP_MODIFICATION_REQUEST_ACCEPTED_HOST = 'dl_trip_modification_request_accepted_host';
export const EVENT_TRIP_MODIFICATION_REQUEST_CANCELLED_HOST = 'dl_trip_modification_request_cancelled_host';
export const EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST = 'dl_trip_modification_request_sent_guest';
export const EVENT_BOOK_USED_PRESAVED_DETAILS = 'dl_book_used_presaved_details';
export const EVENT_BOOK_CREATED_REQUEST_GUEST = 'dl_book_created_request_guest';
export const EVENT_BOOK_EXCESS_SUCCESS_GUEST = 'dl_book_excess_success_guest';
export const EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST = 'dl_book_sent_request_success_guest';
export const EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST = 'dl_book_created_request_fail_guest';
export const EVENT_BOOK_REQUEST_ACCEPTED_GUEST = 'dl_book_request_accepted_guest';
export const EVENT_ORDER_COMPLETED = 'dl_order_completed';
export const EVENT_BOOK_REQUEST_ACCEPTED_HOST = 'dl_book_request_accepted_host';
export const EVENT_BOOK_REQUEST_WITHDRAWN_GUEST = 'dl_book_request_withdrawn_guest';
export const EVENT_BOOK_REQUEST_DECLINED_GUEST = 'dl_book_request_declined_guest';
export const EVENT_BOOK_REQUEST_DECLINED_HOST = 'dl_book_request_declined_host';
export const EVENT_BOOK_REQUEST_EXPIRED_GUEST = 'dl_book_request_expired_guest';
export const EVENT_BOOK_REQUEST_EXPIRED_HOST = 'dl_book_request_expired_host';

export const EVENT_SEARCH_PERFORMED = 'dl_search_performed';
export const EVENT_SEARCH_PAGE_EXPERIMENT = 'dl_experiemnt_mounted';
export const EVENT_SEARCH_USED_MAP = 'dl_search_used_map';
export const EVENT_SEARCH_VIEWED_LISTING_GUEST = 'dl_search_viewed_listing_guest';
export const EVENT_VIEWED_PRODUCT = 'dl_viewed_product';
export const EVENT_BROWSE_VISITED_LANDING_PAGE = 'dl_browser_visited_landing_page';
export const EVENT_BROWSE_CLICKED_SIGNUP_LANDING_PAGE = 'dl_browser_clicked_signup_landing_page';
export const EVENT_SEARCH_USED_MAP_LISTING = 'dl_search_used_map_listing';
export const EVENT_SEARCH_CLICKED_PIC_LISTING = 'dl_search_clicked_pic_listing';
export const EVENT_SEARCH_EXPERT_HELP_BUTTON_CLICKED = 'dl_search_expert_help_button_clicked';
export const EVENT_SEARCH_EXPERIMENT_A = 'Search Experiment Case A';
export const EVENT_SEARCH_EXPERIMENT_B = 'Search Experiment Case B';
export const EVENT_SEARCH_EXPERT_HELP_SUBMITTED = 'dl_search_expert_help_submitted';
export const EVENT_SEARCH_SENT_ENQUIRY_GUEST = 'dl_search_sent_enquiry_guest';
export const EVENT_SEARCH_RECV_ENQUIRY_HOST = 'dl_search_recv_enquiry_host';
export const EVENT_SEARCH_VIEWED_PROFILE_GUEST = 'dl_search_viewed_profile_guest';
export const EVENT_SEARCH_VIEWED_PROFILE_HOST = 'dl_search_viewed_profile_host';
export const EVENT_SEARCH_READ_INSURANCE = 'dl_search_read_insurance';
export const EVENT_SEARCH_PRESSED_BOOK_GUEST = 'dl_search_pressed_book_guest';
export const EVENT_SEARCH_PRESSED_BOOK_GUEST_ERROR = 'dl_search_pressed_book_guest_error';
export const EVENT_BOOK_PRESSED_CONTINUE_ADDONS = 'dl_book_pressed_continue_addons';

export const EVENT_TRIP_GUEST_CANCEL_GUEST = 'dl_trip_guest_cancel_guest';
export const EVENT_TRIP_HOST_CANCEL_HOST = 'dl_trip_host_cancel_host';
export const EVENT_TRIP_PICKUP_REQUEST_SENT = 'dl_trip_pickup_request_sent';
export const EVENT_TRIP_PICKUP_REQUEST_RECEIVED = 'dl_trip_pickup_request_received';
export const EVENT_TRIP_PICKUP_REQUEST_CONFIRM = 'dl_trip_pickup_request_confirm';
export const EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST = 'dl_trip_pickup_request_confirm_guest';
export const EVENT_TRIP_DROPOFF_REQUEST_SENT = 'dl_trip_dropoff_request_sent';
export const EVENT_TRIP_DROPOFF_REQUEST_RECEIVED = 'dl_trip_dropoff_request_received';
export const EVENT_TRIP_DROPOFF_REQUEST_CONFIRM = 'dl_trip_dropoff_request_confirm';
export const EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST = 'dl_trip_dropoff_request_confirm_guest';
export const EVENT_TRIP_DISPUTED_HOST = 'dl_trip_disputed_host';
export const EVENT_TRIP_DISPUTED_GUEST = 'dl_trip_disputed_guest';
export const EVENT_TRIP_HOST_REVIEW_HOST = 'dl_trip_host_review_host';
export const EVENT_TRIP_HOST_REVIEW_GUEST = 'dl_trip_host_review_guest';
export const EVENT_TRIP_GUEST_REVIEW_GUEST = 'dl_trip_guest_review_guest';
export const EVENT_TRIP_GUEST_REVIEW_HOST = 'dl_trip_guest_review_host';
export const EVENT_TRIP_MESSAGE_SENT_HOST = 'dl_trip_messagesent_host';
export const EVENT_TRIP_MESSAGE_SENT_GUEST = 'dl_trip_messagesent_guest';

export const EVENT_LISTACAR_CLICKED_LIST_A_CAR = 'dl_listacar_clicked_list_a_car';
export const EVENT_LISTACAR_CREATED_DRAFT = 'dl_listacar_created_draft';
export const EVENT_LISTACAR_COMPLETED_LOCATION = 'dl_listacar_completed_location';
export const EVENT_LISTACAR_COMPLETED_CARDETAILS = 'dl_listacar_completed_cardetails';
export const EVENT_LISTACAR_COMPLETED_CARFEATURES = 'dl_listacar_completed_carfeatures';
export const EVENT_LISTACAR_COMPLETED_CARRULES = 'dl_listacar_completed_carrules';
export const EVENT_LISTACAR_COMPLETED_PRICING = 'dl_listacar_completed_pricing';
export const EVENT_LISTACAR_COMPLETED_PICTURES = 'dl_listacar_completed_pictures';
export const EVENT_LISTACAR_COMPLETED_INSURANCE = 'dl_listacar_completed_insurance';
export const EVENT_LISTACAR_COMPLETED_AVAILABILITY = 'dl_listacar_completed_availability';
export const EVENT_LISTACAR_UPDATED_AVAILABILITY = 'dl_listacar_updated_availability';
export const EVENT_LISTACAR_COMPLETED_ONBOARDING = 'dl_listacar_completed_ONBOARDING';
export const EVENT_LISTACAR_SELECTED_INSTANTBOOK = 'dl_listacar_selected_instantbook';
export const EVENT_LISTACAR_COMPLETED_SUBSCRIPTION = 'dl_listacar_completed_subscription';
export const EVENT_LISTACAR_COMPLETED_NEXT_LEVEL = 'dl_listacar_completed_nextlevelsharing';

export const EVENT_BROWSE_WHATSAPPICON = 'dl_browse_whatsappicon';
export const EVENT_BROWSE_PHONEICON = 'dl_browse_phoneicon';
export const EVENT_BROWSE_CHATICON = 'dl_browse_chaticon';
export const EVENT_BROWSE_SAWHTD = 'dl_browse_sawHTD';
export const EVENT_BROWSE_USEDRENTALCAL = 'dl_browse_usedrentalcal';
export const EVENT_BROWSE_HOMEPAGE = 'dl_browse_homepage';
export const EVENT_BROWSE_HOWITWORKS = 'dl_browse_howitworks';
export const EVENT_BROWSE_SHAREACAR = 'dl_browse_shareacar';
export const EVENT_BROWSE_RENTACAR = 'dl_browse_rentacar';
export const EVENT_BROWSE_TRUSTANDSAFE = 'dl_browse_trustandsafe';
export const EVENT_BROWSE_FLEXPLUS = 'dl_browse_flexplus';
export const EVENT_BROWSE_DRIVELAHGO = 'dl_browse_drivelahgo';
export const EVENT_BROWSE_LOGGEDIN = 'dl_browse_loggedin';
export const EVENT_BROWSE_LOGGEDOUT = 'dl_browse_loggedout';
export const EVENT_BROWSE_POLICIES = 'dl_browse_policies';
export const EVENT_BROWSE_FAQ = 'dl_browse_faq';
export const EVENT_BROWSE_ABOUTUS = 'dl_browse_aboutus';
export const EVENT_BROWSE_DASHBOARD = 'dl_browse_dashboard';
export const EVENT_BROWSE_OWNPROFILE = 'dl_browse_ownprofile';
export const EVENT_BROWSE_MYLISTINGS = 'dl_browse_mylistings';
export const EVENT_BROWSE_ACCOUNTSETTING = 'dl_browse_accountsetting';
export const EVENT_BROWSE_PRESSED_BACK_BUTTON = 'dl_browse_pressed_back_button';
export const EVENT_BROWSE_PRESSED_FORWARD_BUTTON = 'dl_browse_pressed_forward_button';

export const SIGNUP_SUBMIT_BUTTON_ID = 'signup-button-id';
export const SIGNUP_UPDATE_SUBMIT_BUTTON_ID = 'signup-update-button-id';
export const SEND_REQUEST_BOOKING_BUTTON_ID = 'send-request-booking-button-id';
export const PROVIDER_ACCEPT_BOOKING_BUTTON_NAME = 'provider-accept-booking-button-name';
export const PROVIDER_DECLINE_BOOKING_BUTTON_NAME = 'provider-decline-booking-button-name';
export const CANCEL_BOOKING_BUTTON_ID = 'cancel-booking-button-id';
export const ENQUIRY_BUTTON_ID = 'enquiry-button-id';
export const VIEW_PHOTO_BUTTON_ID = 'view-photo-button-id';
export const VIEW_EXPERT_BUTTON_ID = 'view-expert-button-id';
export const VIEW_EXPERT_FIND_ME_CAR = 'view-expert-find-car-id';
export const REQUEST_TO_BOOK_BUTTON_ID = 'request-to-book-id';
export const LISTACAR_CREATE_NEW_BUTTON_ID = 'listacar-create-new-button-id';
export const LISTACAR_CREATE_NEW_BUTTON_ID_2 = 'listacar-create-new-button-id-2';
export const LISTACAR_LOCATION_BUTTON_ID = 'listacar-location-button-id';
export const LISTACAR_ABOUT_BUTTON_ID = 'listacar-about-button-id';
export const LISTACAR_FEATURES_BUTTON_ID = 'listacar-features-button-id';
export const LISTACAR_CAR_RULES_BUTTON_ID = 'listacar-car-rules-button-id';
export const LISTACAR_PRICING_BUTTON_ID = 'listacar-pricing-button-id';
export const LISTACAR_SUBSCRIPTION_BUTTON_ID = 'listacar-subscription-button-id';
export const LISTACAR_PICTURES_BUTTON_ID = 'listacar-pictures-button-id';
export const LISTACAR_INSURANCE_BUTTON_ID = 'listacar-insurance-button-id';
export const LISTACAR_AVAILABILITY_BUTTON_ID = 'listacar-availability-button-id';
export const LISTACAR_ONBOARDING_CALL_BUTTON_ID = 'listacar-ONBOARDING-button-id';
export const LISTACAR_NEXT_LEVEL_BUTTON_ID = 'listacar-next-level-button-id';
export const LISTACAR_PAYOUTS_BUTTON_ID = 'listcar-payouts-button-id';
export const UPLOAD_FRONT_NRIC_BUTTON_ID = 'upload-front-nric-button-id';
export const UPLOAD_BACK_NRIC_BUTTON_ID = 'upload-back-nric-button-id';
export const UPLOAD_FRONT_SG_DRIVING_LICENSE_BUTTON_ID = 'upload-front-sg-driving-license-button-id';
export const UPLOAD_BACK_SG_DRIVING_LICENSE_BUTTON_ID = 'upload-back-sg-driving-license-button-id';
export const UPLOAD_FRONT_INTL_DRIVING_LICENSE_BUTTON_ID = 'upload-front-intl-driving-license-button-id';
export const UPLOAD_BACK_INTL_DRIVING_LICENSE_BUTTON_ID = 'upload-back-intl-driving-license-button-id';
export const UPLOAD_PROFILE_PHOTO_BUTTON_ID = 'upload-profile-photo-button-id';
export const GUEST_VERIFY_DRIVER_SUBMIT_BUTTON_ID = 'guest-verify-driver-submit-button-id';
export const HOST_VERIFY_PUBLISH_BUTTON_ID = 'host-verify-publish-button-id';
export const TRIP_PUBLISH_REVIEW_BUTTON_ID = 'trip-publish-review-button-id';
export const TRIP_SUBMIT_DISPUTE_BUTTON_ID = 'trip-submit-dispute-button-id';
export const TRIP_PRIMARY_BUTTON_TRANSIT_NAME = 'actionTransitButton';
export const TRIP_SEND_MESSAGE_BUTTON_ID = 'trip-send-message-button-id';
export const BROWSE_BUTTON_PLAY_VIDEO_ID = 'browse-button-play-video-id';

// we can not import from EditWizardTab because webpack'll build css file result in css bug.
export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const INSURANCE = 'insurance';
export const NEXT_LEVEL= 'nextLevel';
export const ONBOARDING_CALL = "onboarding-call";
export const SUBSCRIPTION = "subscription";

export const SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST = 'Book Received Request Success Host';
export const SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST = 'Book Created Request Fail Host';
export const SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST = 'Book Request Withdrawn Host';
export const SERVER_EVENT_TRIP_GUEST_CANCEL_HOST = 'Trip Guest Cancel Host';
export const SERVER_EVENT_TRIP_HOST_CANCEL_GUEST = 'Trip Host Cancel Guest';
export const SERVER_EVENT_SEARCH_VIEWED_LISTING_HOST = 'Search Viewed Listing Host';
export const SERVER_EVENT_SEARCH_PRESSED_BOOK_HOST = 'Search Pressed Book Host';
