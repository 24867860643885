import React, { memo } from 'react';
import Slider from 'react-slick';
import { NamedLink } from '../../../components';
import classNames from 'classnames';
import css from './LongTermCarCard.css';
import { array, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import star from '../../../assets/star-solid.svg';
import NoImageIcon from '../../../components/ResponsiveImage/NoImageIcon';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';
import { getListingMonthlyFlexPrice } from '../../../util/data';

const SMALL_MOBILE_SCREEN_WIDTH = 550;
const MEDIUM_SCREEN_WIDTH = 768;
const LARGE_SCREEN_WIDTH = 1025;

const LongTermCarCard = memo(
  props => {
    const {
      listings,
      className,
      rootClassName,
      intl,
      viewport,
      currentListingId,
      renderSizes,
    } = props;

    if (listings.length === 1 && listings[0].id.uuid === currentListingId) return null;

    const sortedListings = listings.sort((a, b) => {
      if (!a.attributes.publicData.flexPlusEnabled && !b.attributes.publicData.flexPlusEnabled)
        return 1;
      return -1;
    });

    const noImageMessageText = <FormattedMessage id="ResponsiveImage.noImage" />;
    const isMobileLayout = viewport.width <= MEDIUM_SCREEN_WIDTH;

    const getSlidesToShow = () => {
      if (viewport.width <= SMALL_MOBILE_SCREEN_WIDTH) return 1.25;
      if (viewport.width <= MEDIUM_SCREEN_WIDTH) return 2;
      return 3;
    };

    const getMaximumCharsPerLine = () => {
      if (viewport.width <= SMALL_MOBILE_SCREEN_WIDTH) {
        return 10;
      }
      if (viewport.width >= MEDIUM_SCREEN_WIDTH && viewport.width <= LARGE_SCREEN_WIDTH) {
        return 16;
      }
      return 13;
    };

    const title = intl.formatMessage({ id: 'LongTermCarCard.title' });
    const Star = star;

    const prevArrow = (
      <img
        width="30px"
        height="100px"
        style="background-color: black"
        src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23000" fill="%23000" fill-rule="evenodd"/></svg>'
      />
    );
    const nextArrow = (
      <img
        width="30px"
        height="100px"
        style="background-color: black"
        src='data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23000" fill="%23000" fill-rule="evenodd"/></svg>'
      />
    );

    const settings = {
      infinite: false,
      speed: 500,
      dots: false,
      slidesToShow: getSlidesToShow(),
      slidesToScroll: 1,
      arrows: true,
      nextArrow,
      prevArrow,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3.5,
            initialSlide: 0,
            infinite: false,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3.5,
            initialSlide: 0,
            infinite: false,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.5,
            initialSlide: 0,
            infinite: false,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            dots: false,
            arrows: true,
          },
        },
      ],
    };

    const classes = classNames(rootClassName || css.root, className, css.sliderContainer);

    return (
      <div className={classes}>
        <div>
          <h3 className={css.titlename}>{title}</h3>
        </div>
        <div className={css.sliderWrapper}>
          <Slider {...settings} className={css.slider}>
            {sortedListings.map(listing => {
              const id = listing.id.uuid;
              const { titleForSlug = '', price } = listing.attributes;
              const slug = createSlug(titleForSlug);
              return (
                <NamedLink
                  className={classes}
                  name={'LongTermRentalListingPage'}
                  params={{ id, slug }}
                  key={listing.id.uuid}
                >
                  <div className={css.imgWrapper}>
                    <div className={css.imgContainer}>
                      {listing.images[0] && listing.images[0].attributes ? (
                        <img
                          src={listing.images[0].attributes.variants['landscape-crop'].url}
                          className={css.rootImage}
                        />
                      ) : (
                        <div className={css.noImageDisplayContainer}>
                          <div className={css.noImageContainer}>
                            <div className={css.noImageWrapper}>
                              <NoImageIcon />
                              <div className={css.noImageText}>{noImageMessageText}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className={css.heart}>
                      <img src={heartImage} />
                    </div> */}
                    {/* <div className={css.displaykm}>
                      <span>{`${listing.attributes.publicData.millagePerDay} km`}</span>
                    </div> */}
                  </div>
                  <div className={css.contentDetails}>
                    <div className={css.features}>
                      <p className={css.carname}>{listing.attributes.title}</p>
                      <div className={css.carDetail}>
                        {/* {listing.attributes.publicData.transmissions && (
                          <span>{listing.attributes.publicData.transmissions}</span>
                        )} */}
                        {listing.attributes.publicData.fuelType && (
                          <span>
                            <span className={css.circle}></span>
                            {listing.attributes.publicData.fuelType}
                          </span>
                        )}
                        {/* {listing.attributes.publicData.peopleNumberMin &&
                          listing.attributes.publicData.peopleNumberMax && (
                            <span>
                              <span className={css.circle}></span>
                              {`${listing.attributes.publicData.peopleNumberMin} - ${listing.attributes.publicData.peopleNumberMax} Seats`}
                            </span>
                          )} */}
                      </div>
                    </div>

                    <div className={css.border}></div>
                    <div className={css.carinfo}>
                      <div className={css.pricetag}>
                       <span className={css.rent}>
                          {formatMoney(intl, getListingMonthlyFlexPrice(listing))}
                          <span className={css.timeSpan}>/week</span>
                        </span>
                      </div>

                    </div>
                  </div>
                </NamedLink>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev.listings, next.listings)
);

LongTermCarCard.defaultProps = {
  allListings: [],
  currentListingId: null,
  className: null,
  rootClassName: null,
  intl: null,
};

LongTermCarCard.propTypes = {
  intl: intlShape.isRequired,
  allListings: array.isRequired,
  currentListingId: string.isRequired,
};

export default LongTermCarCard;
