import React from 'react';
import classNames from 'classnames';
import css from './BrandingPage.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrimaryButton } from '../../components';
import arrowRight from '../../assets/hostLanding/next.png';
import bannerImg from '../../assets/landingpage/banner_rightImg.png';
import bannerImgMobile from '../../assets/landingpage/banner_ImgMobile1.png';
import { useHistory, useLocation } from 'react-router';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types } from '../../util/sdkLoader';

const { Money } = types;

const HostSectionHeroBrandingPartners = ({ getToCalculatorSection, signUpCodeAmount }) => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { code } = parse(location.search);
  const redirectToSignup = () => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
          }
        : {
            pathname: url,
          }
    );
  };
  return (
    <div className={css.BrandHero}>
      <div className={css.landingContainer}>
        <h1 className={classNames(css.heroBrandingTitle, css.heroTitleMobile)}>
          Bring your car over to us!
        </h1>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          <div className={classNames(css.column6, css.flexCol)}>
            <div className={css.bannerContent}>
              <h1 className={classNames(css.heroBrandingTitle, css.heroTitleDesk)}>
                Bring your car over to us!
              </h1>
              <p>
                Already familiar with car sharing? Why not have your car earning cash on multiple
                platforms?
              </p>
              <p>
                Make sharing your car really worth its while earning limitless passive income across
                all of your favourite networks – Drive mate offers a range of incredibly dynamic and
                industry-first features providing the most safe and comprehensive car sharing app in
                Australia!
              </p>
              <PrimaryButton type="button" onClick={redirectToSignup}>
                {signUpCodeAmount ? (
                  <FormattedMessage
                    id="HostSectionHeroPartners.SignupNowButtonMessage"
                    values={{
                      amount:
                        signUpCodeAmount &&
                        formatMoney(intl, new Money(signUpCodeAmount, config.currency)),
                    }}
                  />
                ) : (
                  <FormattedMessage id="brandingPartnerLandingPage.signUpNow" />
                )}
              </PrimaryButton>
              <span className={css.calcTargetButton} onClick={getToCalculatorSection}>
                Calculate your potential earnings{' '}
                <span>
                  <img src={arrowRight} alt="" />
                </span>
              </span>
            </div>
          </div>
          <div className={classNames(css.column6, css.rightCol)}>
            <div className={css.banner_dekstop}>
              <img src={bannerImg} alt=" " />
            </div>
            <div className={css.banner_mobile}>
              <img src={bannerImgMobile} alt=" " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostSectionHeroBrandingPartners;
