import React from 'react';
import classNames from 'classnames';
import css from './HourlyRentalBanner.css';
import IconClose from '../IconClose/IconClose';

function HourlyRentalBanner({ rootClassName, className, closeHourleyBanner, showBanner }) {
  if (!showBanner || typeof window === 'undefined') return null;

  const classes = classNames(rootClassName || css.root, className);

  return showBanner ? (
    <div className={classes} id="hourlyBanner">
      <div className={css.contentWrapper}>
        <div className={css.title}>Introducing hourly rentals</div>
        <div className={css.description}>
          Your instant solution to run errands or go on a short trip
        </div>
        <div className={css.closeIcon} onClick={closeHourleyBanner}>
          <IconClose />
        </div>
      </div>
    </div>
  ) : null;
}

export default HourlyRentalBanner;
