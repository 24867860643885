import axios from 'axios';

const encodeInFormData = data => {
  let str = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
  }
  return str.join('&');
};

export const configGoogle = config => {
  const { clientId, clientSecret, scopes } = config;

  const getAuthCodeUri = ({ authCodeUri, redirectUri, stateParams = 'oAuthCode' }) => {
    const parts = [
      'access_type=offline',
      'response_type=code',
      `scope=${scopes.join(' ')}`,
      `redirect_uri=${redirectUri}`,
      `client_id=${clientId}`,
      `state=${stateParams}`,
      'include_granted_scopes=true',
      'prompt=consent'
    ];

    return `${authCodeUri}?${parts.join('&')}`;
  };

  const getRefreshToken = async ({ tokenUri, redirectUri, code }) => {
    try {
      const response = await axios.post(
        tokenUri,
        encodeInFormData(
          {
            client_id: clientId,
            redirect_uri: redirectUri,
            client_secret: clientSecret,
            grant_type: 'authorization_code',
            access_type: 'offline',
            code: decodeURIComponent(code),
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
      );
      return response.data;
    } catch (error) {
      return undefined;
    }
  };


  const getUserDetails = async ({
      userUri,
      accessToken,
  }) => {
    const qs = [`access_token=${accessToken}`]
    const newUserUri = `${userUri}?${qs.join("&")}`
    try{
        const response = await axios.get(newUserUri)
        return response.data;
    }catch(error){
        return undefined;
    }
  }

  return {
    getAuthCodeUri,
    getRefreshToken,
    getUserDetails,
  };
};

export const getTimeZoneFromGoogle = async (location) => {
  if (!(location && location.lat && location.lng)){
    return null
  }

  try{
    let timestamp = new Date().getTime() / 1000;
    const response = await axios.get("https://maps.googleapis.com/maps/api/timezone/json", {
        params: {
          timestamp,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          location: `${location.lat} ${location.lng}`
        }
    })
    const data = response.data
    if (data.status === "OK"){
      const timeZoneId = data.timeZoneId
      return timeZoneId;
    }
    return null;
  }catch(error){
    console.log("Error fetching timezone: ", error.toString())
    return null
  }

}

