import React from 'react';
import classNames from 'classnames';

import css from './ToggleSwitch.css';

const ToggleSwitch = props => {
  const {
    rootClassName,
    className,
    onChange,
    disabled = false,
    status
  } = props;

  const classes = classNames(rootClassName || css.root, className)

  return (
    <div className={classes}>
      <label className={css.switch}>
        <input 
          type="checkbox" 
          checked={status} 
          disabled={disabled}
          onChange={onChange}/>
        <span className={classNames(css.slider, css.round)}></span>
      </label>
    </div>
  );
}

ToggleSwitch.defaultProps = {
  rootClassName: null,
  className: null,
  onChange: null,
  status: true,
};

ToggleSwitch.propTypes = {

};

export default ToggleSwitch;