import config from '../config';

const apiUrl = config.apiUrl;

export const triggerStripeTermsVerification = ({ stripeId }) => {
  return fetch(apiUrl + `/api/stripe/${stripeId}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }).then(res => res.json())
    .then(data => {
      return data;
    })
}

const ALFA = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const RANDOM_STRING = [...Array(10)].map(_ => ALFA[~~(Math.random()*ALFA.length)]).join('');
export const STRIPE_T_KEY = 'DRVMAT' + RANDOM_STRING;
