import React, { Component } from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import videoLogo from '../../assets/landingpage/videoLogo.png';
import css from './HIWHowDoesItWork.css';

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/-fg9W0MZYuo"
      ></iframe>
    </div>
  );
};

class HIWHowDoesItWorkSection extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const { onManageDisableScrolling } = this.props;

    return (
      <section className={css.howDoesItWorkSection}>
        <div className={css.howDoestItWorkContainer}>
          <div className={css.textBox}>
            <h2 className={css.textBoxTitle}>
              <FormattedMessage id="HowItWorkPage.howDoesItWorkTitle" />
            </h2>
            <div className={css.textBoxDesc}>
              <FormattedMessage id="HowItWorkPage.howDoesItWorkDesc" />{' '}
              {/* <span onClick={this.handleOpen} className={css.watchNow}>
                <FormattedMessage id="HowItWorkPage.howDoesItWorkWatchNow" />
              </span> */}
            </div>
            <div className={classNames(css.videoWrapper, css.mobile)}>
              <div onClick={this.handleOpen} className={css.video}>
                <img className={css.videoLogo} src={videoLogo} alt="Car rental with more choice and less hassle | Drive Mate" />
              </div>
            </div>
            <div className={css.textBoxDesc}>
              <FormattedMessage id="HowItWorkPage.watchVideoOfHow" />{' '}
            </div>
            {/* <NamedLink name="BecomeAGuestPage" className={css.howToRentACar}>
              <FormattedMessage id="HowItWorkPage.howToRentACar" />
            </NamedLink>
            <NamedLink name="BecomeAHostPage" className={css.howToShareYourCar}>
              <FormattedMessage id="HowItWorkPage.howToShareYourCar" />
            </NamedLink> */}
          </div>
          <div className={classNames(css.videoWrapper, css.desktop)}>
            <div onClick={this.handleOpen} className={css.video}>
              <img className={css.videoLogo} src={videoLogo} alt="Car rental with more choice and less hassle | Drive Mate" />
            </div>
          </div>
          <Modal
            id="videoLandingpage"
            {...this.props}
            containerClassName={css.modalContainer}
            closeButtonClassName={css.modalCloseButton}
            contentClassName={css.contentModal}
            isOpen={this.state.isOpen}
            onClose={() => {
              const videoIframe = document.querySelector('#videoIframe');
              if (videoIframe) {
                const iframeSrc = videoIframe.src;
                videoIframe.src = iframeSrc;
              }
              this.setState({ isOpen: false });
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <ModalVideo />
          </Modal>
        </div>
      </section>
    );
  }
}

export default HIWHowDoesItWorkSection;
