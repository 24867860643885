/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import css from './BLandingPage.css';
import isEqual from 'lodash/isEqual';

const SeoLandingRentalCategories = memo(
  () => {
    return (
      <div className={css.rentalCarCategories}>
        <div className={css.rentalCarCategoriesContainer}>
          <div className={css.rentalCarCategoriesRow}>
            <div className={css.columnEight}>
              <div className={css.columnTitle}>Car Rental by top brands</div>
              <ul className={css.categoriesListing}>
                <li>audi</li>
                <li>honda</li>
                <li>toyota</li>
                <li>bmw</li>
                <li>mercedes benz</li>
                <li>mini</li>
                <li>hyundai</li>
                <li>mitsubishi</li>
                <li>porsche</li>
                <li>volvo</li>
              </ul>
            </div>
            <div className={css.columnEight}>
              <div className={css.columnTitle}>Car Rental by top locations</div>
              <ul className={css.categoriesListing}>
                <li>Punggol</li>
                <li>Tampines</li>
                <li>Serangoon</li>
                <li>Bukit Batok</li>
                <li>Chao Chu Kang</li>
                <li>Punggol</li>
                <li>Tampines</li>
                <li>Serangoon</li>
                <li>Bukit Batok</li>
                <li>Chao Chu Kang</li>
              </ul>
            </div>
            <div className={css.columnTwo}>
              <div className={css.columnTitle}>Car Rental by type</div>
              <ul className={css.categoriesListing}>
                <li>Compact</li>
                <li>Standard Sedan</li>
                <li>Standard SUV</li>
                <li>Luxury Sedan</li>
                <li>Luxury SUV</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingRentalCategories;
