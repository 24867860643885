import React from 'react';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import css from '../LongTermRentalListingPage.css';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineAdditionalDriverMaybe = props => {
  const { intl, additionalDriverCost } = props;

  if (!additionalDriverCost) {
    return null;
  }

  const additionalDriverCostPrice = new Money(additionalDriverCost * 100, config.currency);

  return (
    <div>
      <span className={css.priceTitle}>Additional Driver</span>
      <span className={css.priceTitle}>{formatMoney(intl, additionalDriverCostPrice, 1)}</span>
    </div>
  );
};

LineAdditionalDriverMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineAdditionalDriverMaybe;
