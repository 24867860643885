import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import get from 'lodash/get';
import { withViewport } from '../../../util/contextHelpers';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { ensurePaymentMethodCard, ensureStripeCustomer } from '../../../util/data';
import config from '../../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  PrimaryButton,
} from '../../../components';
import { PaymentCovidForm } from '../../../forms';
import { TopbarContainer } from '../../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../../ducks/UI.duck';
import {
  confirmDeclaration,
  fetchTransactionCovid,
  initiateOrder,
  loadData,
} from '../CovidPage.duck';
import facebookImage from '../../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../../assets/facebook_drivelah_2.jpg';
import checkoutHeroImage from '../../../assets/landingPageCovid/checkoutHero.png';
import { ModalTermAndConditionsCovid } from './ModalTermAndConditionsCovid';
import css from './CovidCheckoutPage.css';
import { useModalState } from '../../../util/useHook/useModalState';

const ConfirmDeclarationPopup = (props) => {
  const { history, location } = props;
  const { search } = location;
  const transactionId = search && search.split('=')[1];

  const [signSuccessfully, setSignSuccessfully] = useState(false);

  const dispatch = useDispatch();
  const onFetchTransactionCovid = transactionId => dispatch(fetchTransactionCovid(transactionId));
  const handleConfirmDeclaration = (params) => dispatch(confirmDeclaration(params));

  useEffect(() => {
    onFetchTransactionCovid(transactionId);
  },[]);

  const inProgress = useSelector(state => state.CovidPage.initiateOrderInProgress);
  const isLoading = useSelector(state => state.CovidPage.fetchInProgress);
  const error = useSelector(state => state.CovidPage.initiateOrderError);
  const currentUser = useSelector(state => state.user.currentUser);
  const transactionCovid = useSelector(state => state.CovidPage.transaction);

  const displayName = get(currentUser, 'attributes.profile.displayName');
  const currentUserId = get(currentUser, 'id.uuid', '');
  const transactionCustomerId = get(transactionCovid, 'customer.id.uuid');

  const isMatchUserAndOrder = currentUserId === transactionCustomerId;
  const isDisinfectedListings = get(transactionCovid, 'attributes.metadata.isDisinfectedListings');
  const isValid = isMatchUserAndOrder;

  const singedDeclarationText = <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.singedDeclarationText' />;

  const confirmDeclarationForm = !error && isValid && <>
    <div className={css.descriptionWrapper}>
      <div className={css.orderNumber}>
        <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.orderNumber' values={{ orderNumber: transactionId }} />
      </div>
      <div className={css.description}>
        <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.description1' />
      </div>
      <div className={css.description}>
        <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.description2' />
      </div>
      <div className={css.description}>
        <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.description3' />
      </div>
    </div>
    <PrimaryButton
      className={css.submit}
      inProgress={inProgress}
      disabled={false}
      onClick={() => {
        return handleConfirmDeclaration(transactionId).then(data => {
          if (data) {
            setSignSuccessfully(true);
          }
        });
      } }
    >
      <FormattedMessage id="CovidCheckoutPage.SectionDeclaration.submitButton" />
    </PrimaryButton>
    <div className={css.nameAndDate}>
      <span> {displayName} </span> <br />
      <span> {moment().format('DD.MM.YYYY')} </span>
    </div>
  </>;

  return (
    isLoading ? 'Loading ...' :
    <div className={css.sectionDeclaration}>
      <div className={css.title}>
        <FormattedMessage id='CovidCheckoutPage.SectionDeclaration.title' />
      </div>
      {error && (
        <div className={css.error}>
          <FormattedMessage id="PaymentDepositForm.error" />
        </div>
      )}

      {!isValid && <div className={css.descriptionWrapper}>
          <div className={css.description}>
            <FormattedMessage id='CovidCheckoutPage.userAndOrderNotMatch' />
          </div>
      </div>}

      {signSuccessfully || isDisinfectedListings ?
        <div className={css.descriptionWrapper}>
            <div className={css.description}>
              {isValid && singedDeclarationText}
            </div>
        </div> : confirmDeclarationForm
      }
    </div>
    );
  }

const ThankYouPopup = ({ orderNumber = 'xxxxxxxxxxxxxx' }) => (
  <div className={css.sectionThankYou}>
    <div className={css.title}>
      <FormattedMessage id='CovidCheckoutPage.SectionThankYou.title' />
    </div>
    <div className={css.orderNumber}>
      <FormattedMessage id='CovidCheckoutPage.SectionThankYou.orderNumber' values={{ orderNumber }} />
    </div>
    <div className={css.description}>
      <FormattedMessage id='CovidCheckoutPage.SectionThankYou.description' />
    </div>
    <div className={css.description}>
      <FormattedMessage id='CovidCheckoutPage.SectionThankYou.instructions' />
    </div>
  </div>
);

export const CovidCheckoutPageComponent = props => {
  const { history, intl, params, location } = props;
  const isSuccess = params && params.tab === 'success';

  const [ showThankYou, setShowThankYou ] = useState(false);
  const [ orderNumber, setOrderNumber ] = useState('xxxxxxxxxxxxxx');
  const [ isOpenModal, openModal, closeModal ] = useModalState(false);

  const dispatch = useDispatch();
  const onHandlePayment = (params) => dispatch(initiateOrder(params));
  const onManageDisableScrolling = (componentId, disableScrolling) => dispatch(
    manageDisableScrolling(componentId, disableScrolling));

  const currentUser = useSelector(state => state.user.currentUser);
  const initiateOrderInProgress = useSelector(state => state.CovidPage.initiateOrderInProgress);
  const initiateOrderError = useSelector(state => state.CovidPage.initiateOrderError);
  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  const hasDefaultPaymentMethod = !!(
    ensureStripeCustomer(currentUser && currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser && currentUser.stripeCustomer.defaultPaymentMethod).id
  );
  const schemaTitle = intl.formatMessage({ id: 'CovidCheckoutPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'CovidCheckoutPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const onSubmit = values => {
    const paramsOrder = {
      deliveryAddress: values.deliveryAddress,
      name: values.name,
      numberBottles: values.numberBottles,
    };
    const selectedPaymentMethod = values.paymentMethod === 'defaultCard' && hasDefaultPaymentMethod
      ? currentUser.stripeCustomer.defaultPaymentMethod.attributes
        .stripePaymentMethodId
      : null;
    return onHandlePayment({
      paramsOrder,
      card: values.card,
      stripe: values.stripe,
      paymentMethod: selectedPaymentMethod,
      replaceCurrentCard: values.replaceCurrentCard
    }).then(transaction => {
      if (transaction) {
        setOrderNumber(transaction && transaction.id && transaction.id.uuid);
        setShowThankYou(true);
      }
    });
  };

  const checkoutForm = showThankYou ?
    <ThankYouPopup orderNumber={orderNumber} /> :
    <PaymentCovidForm
      onOpenTermAndConditions={openModal}
      defaultPaymentMethod={
        hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
      }
      intl={intl}
      currentUser={currentUser}
      initiateOrderError={initiateOrderError}
      inProgress={initiateOrderInProgress}
      onSubmit={onSubmit}
    />

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.background}>
          <img src={checkoutHeroImage} className={css.bannerImage} />
          <div className={css.contentWrapper}>
            {isSuccess ?
              <ConfirmDeclarationPopup history={history} location={location} /> : checkoutForm}
          </div>
          <ModalTermAndConditionsCovid
            onManageDisableScrolling={onManageDisableScrolling}
            showModal={isOpenModal}
            onClose={closeModal}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

CovidCheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const CovidCheckoutPage = compose(
  withViewport,
  withRouter,
  injectIntl
)(CovidCheckoutPageComponent);

CovidCheckoutPage.loadData = loadData;

export default CovidCheckoutPage;
