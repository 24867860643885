// ================ Action types ================ //

export const WAITING_FOR_UPLOAD_AVATAR = 'app/UI/WAITING_FOR_UPLOAD_AVATAR';
export const DISABLE_SCROLLING = 'app/UI/DISABLE_SCROLLING';
export const TURNOFF_PROMOTE_BAR = 'app/UI/TURNOFF_PROMOTE_BAR';
export const VISIBILITY_SEARCH_MAP = 'app/UI/VISIBILITY_SEARCH_MAP';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  turnoffPromoteBar: {
    status: false
  },
  visibilityMap: true,
  waitingForUploadAva: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case WAITING_FOR_UPLOAD_AVATAR:
      return {
        ...state,
        waitingForUploadAva: payload.status
      }
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case TURNOFF_PROMOTE_BAR: {
      const { flag } = payload;
      const turnoffPromoteBar = {
        status: flag
      }
      return {
        ...state,
        turnoffPromoteBar
      }
    }

    case VISIBILITY_SEARCH_MAP: {
      return {
        ...state,
        visibilityMap: !state.visibilityMap
      }
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setupWaitingForUploadAvaThenVerifyGuest = (status) => ({
  type: WAITING_FOR_UPLOAD_AVATAR,
  payload: { status: status || null },
})

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const manageTurnoffPromoteBar = (value) => ({
  type: TURNOFF_PROMOTE_BAR,
  payload: {flag: value}
})

export const manageVisibilitySearchMap = () => ({
  type: VISIBILITY_SEARCH_MAP,
  payload: {}
})

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.UI;
  return disableScrollRequests.some(r => r.disableScrolling);
};
