import React from 'react';
import css from './LandingPageByod.css';
import classNames from 'classnames';

import goLogo from '../../assets/logos/GoLogo.png';
import goLogo2x from '../../assets/logos/GoLogo@2x.png';
import { FormattedMessage } from 'react-intl';

const SectionVision = ({ handleLearnMore }) => {

  return (
    <div className={css.sectionVision}>
      <div className={css.visionBackgroundImage}>
        <div className={css.sectionVisionContentWrapper}>
          <img srcSet={`${goLogo} 1x, ${goLogo2x} 2x`} className={css.goLogo} />
          <div className={css.sectionVisionContent}>
            <h3 className={classNames(css.title, css.alignCenter, css.titleDark)}>
              <FormattedMessage id='LandingPageGo.SectionVision.title'/>
            </h3>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGo.SectionVision.description1'/>
            </div>
            <div className={css.description}>
              <FormattedMessage id='LandingPageGo.SectionVision.description2'/>
            </div>
            {/* <div className={css.description}>
              <FormattedMessage id='LandingPageGo.SectionVision.description3'/>
            </div> */}
            {/* <div className={classNames(css.highlightText, css.alignCenter)}>
              <FormattedMessage id='LandingPageGo.SectionVision.highlightText' />
            </div>
            <div className={css.outlineButton} onClick={handleLearnMore}>
              Learn more
            </div> */}

          </div>

        </div>
      </div>
    </div>
  )
};

export default SectionVision;
