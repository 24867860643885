import React, { useState } from 'react';
import { oneOf } from 'prop-types';
import css from './SectionHero.css';
import { FormattedMessage } from 'react-intl';
import FlexPlusIcon from '../../components/Icons/FlexPlus';
import {
  FieldPhoneNumberInput,
  FieldTextInput,
  Form,
  IconCheckmark,
  IconSpinner,
  SecondaryButton,
} from '../../components';
import classNames from 'classnames';
import { Field, Form as FinalForm } from 'react-final-form';
import { composeValidators, emailFormatValid, required } from '../../util/validators';
import { longTermNoti } from '../../util/slackNotify';
import { useHistory } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import moment from 'moment';
import CitiesModal from './CitiesModal';

const SectionHeroTexts = () => {
  return (
    <div className={css.sectionHeroTexts}>
      <FlexPlusIcon fillLine='#fff' className={css.flexLogo} />

      <div className={css.title}>
        <FormattedMessage id="LandingPageFlex.SectionHero.title" />
      </div>
      <div className={css.description}>
        <FormattedMessage
          id="LandingPageFlex.SectionHero.description"
          values={{ logo: <FlexPlusIcon className={css.flexLogoSmall} fillLine='#fff' /> }}
        />
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <div className={css.features}>
      <div className={css.feature}>
        <FormattedMessage id="LandingPageFlex.SectionHero.features.noCommitments" />
      </div>
      <div className={css.feature}>
        <FormattedMessage id="LandingPageFlex.SectionHero.features.mostAffordable" />
      </div>
      <div className={css.feature}>
        <FormattedMessage id="LandingPageFlex.SectionHero.features.noDeposit" />
      </div>
    </div>
  );
};

const SectionHero = ({ version, currentUser, isFromFlexPage }) => {
  const history = useHistory();
  const getDatesForSearch = () => {
    const todaysDate = new Date();
    const startDate = moment(todaysDate)
      .add(1, 'months')
      .set({ date: 1, hour: 9, minute: 0, second: 0 });
    const endDate = moment(todaysDate)
      .add(3, 'months')
      .set({
        date: moment(todaysDate)
          .add(3, 'months')
          .daysInMonth(),
        hour: 9,
        minute: 0,
        second: 0,
      });

    return `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`;
  };

  const doFindCardsByCity = (city) => {
    const routes = routeConfiguration();
    let query = {};
    if (city) {
      query = { pub_listingTimezone: city };
    }
    const url = createResourceLocatorString(
      'LongTermRentalSearch',
      routes,
      {},
      { ...query },
    );
    history.push(url);
  };
  if (version === 'v1') {
    return <SectionHeroV1 handleFindYourCar={doFindCardsByCity} currentUser={currentUser} isFromFlexPage={isFromFlexPage} />;
  }
  return <SectionHeroV2 handleFindYourCar={doFindCardsByCity} currentUser={currentUser} />;
};

SectionHero.propTypes = {
  version: oneOf(['v1', 'v2']),
};

SectionHero.defaultProps = {
  version: 'v1',
};

const SectionHeroV1 = ({ handleFindYourCar, currentUser, isFromFlexPage }) => {
  const [sending, setSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleSendNoti = ({ email }) => {
    setSending(true);
    longTermNoti({
      email,
      version: 1,
      phoneNumber: '',
    }).then(() => {
      setSubmitted(true);
      setSending(false);
    });
  };

  const onCitySelect = (city) => {
    setIsModalOpen(!isModalOpen);
    handleFindYourCar && handleFindYourCar(city);
  };

  const doModal = ()=>{
    handleFindYourCar && handleFindYourCar();
    // setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.heroContent}>
          <div className={css.heroImage} />
          <div>
            <CitiesModal isModalOpen={isModalOpen} doIsModalOpen={doModal} onCitySelect={onCitySelect} />
            <SectionHeroTexts />
            <SecondaryButton className={css.findYourCarV1} onClick={doModal}>
              Yes, I want a car
            </SecondaryButton>
          </div>
          <div className={classNames(css.heroImage, css.heroImageDesktop)} />
        </div>
      </div>
      <Features />
      <div className={css.content}>
        <div className={css.contactWrapper}>

          <div className={css.contactDescription}>
            {!isFromFlexPage ?
              <FormattedMessage id="LandingPageFlex.SectionHero.contact.description" /> : ''}
          </div>
          {(currentUser && currentUser.id) || isFromFlexPage ? (
            ''
          ) : (
            <FinalForm
              onSubmit={handleSendNoti}
              render={({ pristine, dirtySinceLastSubmit, handleSubmit }) => {
                return (
                  <Field
                    name="email"
                    validate={emailFormatValid('Invalid email address')}
                    render={({ input, meta }) => {
                      return (
                        <div
                          className={classNames(css.emailInputWrapper, {
                            [css.emailInputWrapperActive]: meta.active,
                            [css.emailInputWrapperError]: meta.touched && meta.error,
                          })}
                        >
                          <input
                            placeholder="bruce@wayne.com"
                            className={css.emailInput}
                            {...input}
                          />
                          <div className={css.contactBtn} onClick={handleSubmit}>
                            {sending ? (
                              <IconSpinner />
                            ) : submitted && !dirtySinceLastSubmit ? (
                              <IconCheckmark />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={15.595}
                                viewBox="0 0 16 15.595"
                              >
                                <path
                                  d="M6.803 1.045l.793-.793a.854.854 0 011.211 0l6.943 6.939a.854.854 0 010 1.211l-6.944 6.943a.854.854 0 01-1.211 0l-.793-.793a.858.858 0 01.014-1.225l4.3-4.1H.857A.855.855 0 010 8.37V7.227a.855.855 0 01.857-.857H11.12l-4.3-4.1a.852.852 0 01-.017-1.225z"
                                  fill="#026786"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SectionHeroV2 = ({ handleFindYourCar }) => {
  return (
    <div className={css.root}>
      <div className={classNames(css.content)}>
        <div className={css.heroContentV2}>
          <div>
            <SectionHeroTexts />
            <div className={css.findYourCarV2} onClick={handleFindYourCar}>
              Find your car
            </div>
          </div>
          <ScheduleACall className={css.scheduleDesktop} />
        </div>
      </div>
      <ScheduleACall />
      <Features />
    </div>
  );
};

const ScheduleACall = ({ className }) => {
  const [sending, setSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleSendNoti = ({ email, phone }) => {
    setSending(true);
    longTermNoti({
      email,
      version: 2,
      phoneNumber: phone,
    }).then(() => {
      setSubmitted(true);
      setSending(false);
    });
  };
  return (
    <div className={classNames(css.scheduleACallWrapper, className)}>
      <div className={css.scheduleTitle}>Schedule a Call</div>
      {/* <div className={css.scheduleDescription}>
        Leave us your contact details and our customer success team will get in touch to help find
        you your perfect rental car!
      </div> */}
      <FinalForm
        onSubmit={handleSendNoti}
        render={({ dirtySinceLastSubmit, errors, handleSubmit }) => {
          return (
            <Form>
              <div className={css.contactFieldWrapper}>
                <FieldTextInput
                  name="email"
                  placeholder="Email"
                  inputClassName={css.contactField}
                  validate={composeValidators(
                    required('Email is required.'),
                    emailFormatValid('Invalid email address.')
                  )}
                  errorClassName={css.errorText}
                />
              </div>
              <div className={css.contactFieldWrapper}>
                <FieldPhoneNumberInput
                  name="phone"
                  placeholder="Phone Number"
                  inputClassName={css.contactField}
                  validate={required('Phone number is required.')}
                />
              </div>
              <SecondaryButton
                onClick={handleSubmit}
                className={css.contactButton}
                inProgress={sending}
              >
                {submitted && !dirtySinceLastSubmit
                  ? 'Details successfully submitted'
                  : 'Give me a call'}
              </SecondaryButton>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SectionHero;
