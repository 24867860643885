import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import chplay from '../../assets/landingpage/chplayStore.png';
import appstore from '../../assets/landingpage/appStore.png';
import css from './SectionDownloadApp.css';

const SectionDownloadApp = props => {
  return (
    <div className={css.root}>
      <div className={css.textWrapper}>
        <div className={css.title}>
          <FormattedMessage id="SectionDownloadApp.title" />
        </div>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionDownloadApp.subtitle" />
        </div>
        <div className={css.buttonsWrapper}>
          <img className={css.appButton} src={chplay}/>
          <img className={css.appButton} src={appstore}/>
        </div>
      </div>
    </div>
  )
}

SectionDownloadApp.defaultProps = {

}

SectionDownloadApp.propTypes = {

}

export default SectionDownloadApp;
