import React from 'react';
import css from './LandingPageNew.css';
import Modal from '../../components/Modal/Modal';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import allianzLogo from '../../assets/insurance-panel/allianz-logo.svg';
import gtLogo from '../../assets/insurance-panel/gt-logo.png';

const InsuranceSummaryModal = props => {
  const { isModalOpen, setIsModalOpen, onManageDisableScrolling } = props;

  return (
    <div className={css.modalContainer}>
      <Modal
        isOpen={isModalOpen}
        closeButtonMessage=" "
        onClose={() => {
          setIsModalOpen(false);
        }}
        containerClassName={css.summaryContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.insuranceListWrapper}>
          <h3>Our partners</h3>
          <div className={css.insuranceLogoWrapper}>
            <a href="https://www.allianz.com/en.html" target="_blank">
              <img src={gtLogo} className={css.insuranceLogo} alt="tokio-marine-logo" />
            </a>
            <a href="https://www.gtins.com.au/" target="_blank">
              <img src={allianzLogo} className={css.insuranceLogo} alt="ntuc-income-logo" />
            </a>
          </div>
          <div className={css.insuranceLogoTextWrapper}>
            GT is the underwriting agent and claims manager, Allianz Australia Insurance Limited
          </div>
          <h3>Summary of the insurance policy</h3>
          <ul className={css.insurancePointsList}>
            <li>Comprehensive insurance throughout Australia</li>
            <li>Covers vehicle damage due to accident, fire or storm, or malicious damage</li>
            <li>Covers theft of vehicle</li>
            <li>$35 million cover for 3rd party damage</li>
            <li>Maximum damage liability of $3000 (ex GST) for Section I and II each</li>
            <li>Guests have the option of reducing the excess to $1500 or $500</li>
            <li>Additional excess per section of $1000 for drivers younger than 24 years</li>
            <li>
              Additional excess per section of $1000 for drivers with less than 2 years driving
              experience
            </li>
            <li>Policy applicable only for the approved driver during the period of rental</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect(null, mapDispatchToProps)(InsuranceSummaryModal);
