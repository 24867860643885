import React, { Component } from 'react';
import { HelpLineNumber } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGFAQ.css';

class BAGFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1,
    };
  }

  openTab = index => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1,
    });
  };

  render() {
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <h6 className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: <br /> }} />
          </h6>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                What does peer to peer car sharing mean?
              </div>
              {this.state.tabOpen === 0 && (
                <div className={css.answer}>
                  This means that individuals that own a car can rent out their car to other
                  individuals when they don’t need their car. The trip will be comprehensively
                  insured for the host’s and guest’s peace of mind.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                Am I eligible to drive?
              </div>
              {this.state.tabOpen === 1 && (
                <div className={css.answer}>
                  You are eligible to drive on Drive mate if you meet the following criteria
                  <ul>
                    <li>Must hold a valid driving licence - Australian or International</li>
                    <li>Minimum driving experience of 2 years</li>
                    <li>Minimum age: 21</li>
                    <li>If Australian license - then full licenses (not provisional or learner)</li>
                    <li>
                      Less than 25 years is considered a young driver which will attract a surcharge
                      of 10%
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                Why should I rent with Drive mate?
              </div>
              {this.state.tabOpen === 2 && (
                <div className={css.answer}>
                  Drive mate is the most convenient access to cars in Australia. We offer a huge
                  variety of cars nearby at the click of a button. Drive mate utilizes unused car
                  hours of individual car owners and that makes rentals on Drive mate typically
                  30-40% cheaper than other car rental / sharing companies.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(3)}>
                Can I take pets with me in the car?
              </div>
              {this.state.tabOpen === 3 && (
                <div className={css.answer}>
                  This decision is that of the host. We have a number of cars which are pet
                  friendly. You can filter for pet friendly cars while searching on the platform.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                What protection do I have against car damage?
              </div>
              {this.state.tabOpen === 4 && (
                <div className={css.answer}>
                  All trips are comprehensively insured on Drive mate. That means full cover for you
                  and up to $35 million cover for 3rd party damage.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(4)}>
                Can I cancel a booking?
              </div>
              {this.state.tabOpen === 4 && (
                <div className={css.answer}>
                  Absolutely. You can cancel the booking in line with our cancellation policy. There
                  may be charges depending upon when you cancel the booking.
                </div>
              )}
            </div>
          </div>
          <HelpLineNumber className={css.helpline} isWhiteText={true} />
        </div>
        <div className={css.seperateLine}></div>
      </section>
    );
  }
}

export default BAGFAQ;
