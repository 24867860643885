import get from 'lodash/get';
import config from '../config';
import { types as sdkTypes } from './sdkLoader';

const { Money } = sdkTypes;

export const CANCEL_BEFORE_72_HOURS = 'CANCEL_BEFORE_72_HOURS';
export const CANCEL_WITHIN_72_HOURS = 'CANCEL_WITHIN_72_HOURS';
export const CANCEL_WITHIN_LTF = 'CANCEL_WITHIN_LTF';
export const CANCEL_WITHIN_LTM = 'CANCEL_WITHIN_LTM';
export const CANCEL_WITHIN_LTL = 'CANCEL_WITHIN_LTL';
export const CANCEL_NORMAL_TRIP = 'CANCEL_NORMAL_TRIP';
export const CAR_TYPE_PRIVATE = 'private';
export const CAR_TYPE_COMMERCIAL = 'commercial';

export const calculateLongTermPriceWithFeeOneMonth = transaction => {
  const longTermPrice = get(transaction, 'listing.attributes.publicData.longTermPrice.amount', 0);
  const carType = get(transaction, 'attributes.protectedData.commissionObj.note.carType');
  const fixedCommission = carType === CAR_TYPE_PRIVATE ? 35000 : 25000;
  const processingFee = 0.04*(fixedCommission + longTermPrice);
  const totalPrice = (longTermPrice + fixedCommission + processingFee) || 0;
  return new Money(totalPrice, config.currency);
}
