import React, { memo } from 'react';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { BListingCardMobile, SearchContactForm } from '../../components';
import css from './SearchResultsPanel.css';
import BlandingSearchResultsPanelCSS from './BlandingSearchResultsPanel.css';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

const { Money } = sdkTypes;

const FIELDS = [
  'listings',
  'pagination',
  'search',
  'searchDate',
  'location',
  'searchTimes',
  'visibilityMap',
  'currentUser',
  'speculatedTripPricesInProgress',
  'speculatedTripPrices',
];

const SearchResultsPanel = memo(
  props => {
    const {
      className,
      rootClassName,
      listings,
      center,
      pagination,
      search,
      setActiveListing,
      searchDate,
      location,
      onSelectCard,
      searchTimes,
      isSearchingLongTerm,
      isMobileLayout,
      resultsCount,
      visibilityMap,
      isAuthenticated,
      onManageDisableScrolling,
      currentUser,
      speculatedTripPricesInProgress,
      speculatedTripPrices,
    } = props;
    const classes = classNames(rootClassName || css.root, className);
    // const [showFormModal, setShowFormModal] = useState(false);
    // const paginationLinks =
    //   pagination && pagination.totalPages > 1 ? (
    //     <PaginationLinks
    //       className={css.pagination}
    //       pageName="SearchPage"
    //       pageSearchParams={search}
    //       pagination={pagination}
    //     />
    //   ) : null;


    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    const cardRenderSizes = [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
    const showMonthlyPrice = (search && search.pub_longTermRental) || isSearchingLongTerm;

    const getTipPrice = listingId => {
      const { prices } = speculatedTripPrices || {};
      const currentTripPrice = prices && prices[listingId] || null;
      return currentTripPrice ? new Money(currentTripPrice, config.currency) : null;
    }

    return (
      <div className={classes}>
        <div className={classNames(css.listingCards)}>
          {isMobileLayout && (
            <div className={css.mobileProductCount}>
              <div className={css.numberOfResults}>{resultsCount} cars available</div>
              <div className={css.placeOfResults}>{search && search.address}</div>
            </div>
          )}
          {listings.map(l => (
            <BListingCardMobile
              onSelectCard={onSelectCard}
              className={classNames(BlandingSearchResultsPanelCSS.listingCard, {
                [BlandingSearchResultsPanelCSS.fullWidthListingCard]:
                  !visibilityMap || search.pub_longTermRental,
              })}
              key={l.id.uuid}
              listing={l}
              center={center}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              searchDate={searchDate}
              location={location}
              searchTimes={searchTimes}
              showMonthlyPrice={showMonthlyPrice}
              currentUser={currentUser}
              tripPrice={getTipPrice(l.id.uuid)}
              tripPriceInProgress={speculatedTripPricesInProgress}
            />
          ))}
          {props.children}
          <SearchContactForm
            isAuthenticated={isAuthenticated}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          {props.children}
        </div>
        {/* {paginationLinks} */}
      </div>
    );
  },
  (prev, next) => isEqual(pick(prev, FIELDS), pick(next, FIELDS))
);

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  onSelectCard: func,
};

export default SearchResultsPanel;
