import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BAGHowToRent.css';
import carHowToRent from '../../assets/becomeAGuest/car_how_to_rent.png';

const BAGHowToRent = props => {
  const {
    isAuthenticated,
    currentUser,
    onLoginOrSignupClick,
  } = props;

  return (
    <section className={css.whyDriveLahSection}>
      <div className={css.textBox}>
        <div className={css.title}>
          <FormattedMessage id="BecomeAGuestPage.howToRentTitle" values={{ newline: <br /> }} />
        </div>
        <div className={css.description}>
          <FormattedMessage id="BecomeAGuestPage.howToRentDesc" values={{ newline: <br /> }} />
        </div>
        <div className={css.joinNowRow}>
          {currentUser && currentUser.id ? (
            <h2 className={css.text}>Coming soon to your neighborhood!</h2>
          ) : (
            <NamedLink
              name="SignupPage"
              className={css.joinNow}
              onClick={onLoginOrSignupClick}
            >
              <FormattedMessage id="BecomeAGuestPage.searchForCars" />
            </NamedLink>
          )}
          {/* {!isAuthenticated && (
            <NamedLink name="SignupPage" className={css.joinNow}>
              <FormattedMessage id="BecomeAGuestPage.joinUsNowButton" />
            </NamedLink>
          )} */}
          <img src={carHowToRent} className={css.image} />
        </div>
      </div>
    </section>
  );
};

export default BAGHowToRent;
