import {
  getTransitions,
  getTransitionsToStateFn,
  txLastTransition,
} from './transactionLongTermFirst';
import { ensureTransaction } from './data';

export const PROCESS_NAME_LTR_LAST = 'long-term-rental-last-booking';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_COMMERCIAL =
  'transition/long-term-last-request-payment-normal-commercial';
export const TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_PRIVATE =
  'transition/long-term-last-request-payment-normal-private';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_LTL_CREATE_PAYMENT_INTENT =
  'transition/long-term-last-create-payment-intent';
export const TRANSITION_LTL_CONFIRM_PAYMENT = 'transition/long-term-last-confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_LTL_DECLINE = 'transition/long-term-last-decline';
export const TRANSITION_LTL_EXPIRE_PAYMENT = 'transition/long-term-last-expire-payment';
export const TRANSITION_LTL_TRIP_END = 'transition/long-term-last-trip-end';
export const TRANSITION_LTL_REQUEST_DROP_OFF = 'transition/long-term-last-request-drop-off';
export const TRANSITION_LTL_AUTO_COMPLETE = 'transition/long-term-last-auto-complete';
export const TRANSITION_LTL_CONFIRM_DROP_OFF = 'transition/long-term-last-confirm-drop-off';

// Admin can also cancel the transition.
export const TRANSITION_LTL_CUSTOMER_CANCEL = 'transition/long-term-last-customer-cancel';
export const TRANSITION_LTL_CUSTOMER_CANCEL_AFTER_DROP_OFF_REQUESTED =
  'transition/long-term-last-customer-cancel-after-drop-off-requested';

export const TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END =
  'transition/long-term-last-admin-transit-trip-end';

export const TRANSITION_LTL_ADMIN_NEED_CANCEL = 'transition/long-term-last-admin-need-cancel';
export const TRANSITION_LTL_ADMIN_CANCEL_BEFORE_DROP_OFF_REQUESTED =
  'transition/long-term-last-admin-cancel-before-drop-off-requested';
export const TRANSITION_LTL_ADMIN_CANCEL_AFTER_DROP_OFF_REQUESTED =
  'transition/long-term-last-admin-cancel-after-drop-off-requested';

export const TRANSITION_LTL_ADMIN_CANCEL_REFUND = 'transition/admin-cancel-refund';
export const TRANSITION_LTL_ADMIN_CANCEL_NON_REFUND = 'transition/admin-cancel-non-refund';
export const TRANSITION_LTL_ADMIN_REFUND = 'transition/admin-refund';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_LTL_REVIEW_1_BY_PROVIDER = 'transition/long-term-last-review-1-by-provider';
export const TRANSITION_LTL_REVIEW_2_BY_PROVIDER = 'transition/long-term-last-review-2-by-provider';
export const TRANSITION_LTL_REVIEW_1_BY_CUSTOMER = 'transition/long-term-last-review-1-by-customer';
export const TRANSITION_LTL_REVIEW_2_BY_CUSTOMER = 'transition/long-term-last-review-2-by-customer';
export const TRANSITION_LTL_EXPIRE_CUSTOMER_REVIEW_PERIOD =
  'transition/long-term-last-expire-customer-review-period';
export const TRANSITION_LTL_EXPIRE_PROVIDER_REVIEW_PERIOD =
  'transition/long-term-last-expire-provider-review-period';
export const TRANSITION_LTL_EXPIRE_REVIEW_PERIOD = 'transition/long-term-last-expire-review-period';

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_DECLINE = 'decline';
const STATE_CREATED_PAYMENT = 'created-payment';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_TRIP_ENDED = 'trip-ended';
const STATE_DROP_OFF_REQUESTED = 'drop-off-requested';
const STATE_COMPLETED = 'completed';
const STATE_ADMIN_REVIEW_NEEDED = 'admin-review-needed';
const STATE_WAITING_FOR_REFUND = 'waiting-for-refund';
const STATE_CANCELLED = 'cancelled';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED = 'reviewed';

const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'long-term-rental-last-booking',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_COMMERCIAL]: STATE_PENDING_PAYMENT,
        [TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_PRIVATE]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_LTL_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_LTL_CREATE_PAYMENT_INTENT]: STATE_CREATED_PAYMENT,
        [TRANSITION_LTL_ADMIN_NEED_CANCEL]: STATE_DECLINE,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_CREATED_PAYMENT]: {
      on: {
        [TRANSITION_LTL_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
      },
    },
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_LTL_TRIP_END]: STATE_TRIP_ENDED,
        [TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END]: STATE_TRIP_ENDED,
        [TRANSITION_LTL_CUSTOMER_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_LTL_ADMIN_NEED_CANCEL]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_ADMIN_REVIEW_NEEDED]: {
      on: {
        [TRANSITION_LTL_ADMIN_CANCEL_NON_REFUND]: STATE_CANCELLED,
        [TRANSITION_LTL_ADMIN_CANCEL_REFUND]: STATE_WAITING_FOR_REFUND,
      },
    },
    [STATE_WAITING_FOR_REFUND]: {
      on: {
        [TRANSITION_LTL_ADMIN_REFUND]: STATE_CANCELLED,
      },
    },
    [STATE_CANCELLED]: {},
    [STATE_TRIP_ENDED]: {
      on: {
        [TRANSITION_LTL_REQUEST_DROP_OFF]: STATE_DROP_OFF_REQUESTED,
        [TRANSITION_LTL_ADMIN_CANCEL_BEFORE_DROP_OFF_REQUESTED]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_DROP_OFF_REQUESTED]: {
      on: {
        [TRANSITION_LTL_CONFIRM_DROP_OFF]: STATE_COMPLETED,
        [TRANSITION_LTL_AUTO_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_LTL_ADMIN_CANCEL_AFTER_DROP_OFF_REQUESTED]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_LTL_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_LTL_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_LTL_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_LTL_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_LTL_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_LTL_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_LTL_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS_LTL = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txIsDeclinedLTL = tx =>
  getTransitionsToState(STATE_DECLINE).includes(txLastTransition(tx));

export const txIsPaymentPendingLTL = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpiredLTL = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequestedLTL = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsCreatedPaymentIntentLTL = tx =>
  getTransitionsToState(STATE_CREATED_PAYMENT).includes(txLastTransition(tx));

export const txIsAdminReviewNeededLTL = tx =>
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx));

export const txIsWaitingForRefundLTL = tx =>
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsRefundedLTL = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTL_ADMIN_REFUND;
};

export const txIsCancelledLTL = tx =>
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIWaitingRequestDropoffLTL = tx =>
  getTransitionsToState(STATE_TRIP_ENDED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsBeingDropOffLTL = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_LTL_REQUEST_DROP_OFF;
};

export const txIsCompletedLTL = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedLTL = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

export const txIsAfterConfirmDropoffLTL = tx => {
  return txIsCompletedLTL(tx) || txIsReviewedLTL(tx);
};

export const txIsAfterTripEndLTL = tx => {
  const transition = txLastTransition(tx);
  return [
    TRANSITION_LTL_TRIP_END,
    TRANSITION_LTL_REQUEST_DROP_OFF,
    TRANSITION_LTL_CONFIRM_DROP_OFF,
    TRANSITION_LTL_AUTO_COMPLETE,
  ].includes(transition);
};

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];

export const txHasFirstReviewLTL = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txHasFirstReviewedByProviderLTL = tx =>
  TRANSITION_LTL_REVIEW_1_BY_PROVIDER === txLastTransition(tx);

export const txHasFirstReviewedByCustomerLTL = tx =>
  TRANSITION_LTL_REVIEW_1_BY_CUSTOMER === txLastTransition(tx);

export const txIsInFirstReviewByLTL = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
export const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

export const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenCompletedLTL = hasPassedStateFn(STATE_COMPLETED);

export const getReview1TransitionLTL = isCustomer =>
  isCustomer ? TRANSITION_LTL_REVIEW_1_BY_CUSTOMER : TRANSITION_LTL_REVIEW_1_BY_PROVIDER;

export const getReview2TransitionLTL = isCustomer =>
  isCustomer ? TRANSITION_LTL_REVIEW_2_BY_CUSTOMER : TRANSITION_LTL_REVIEW_2_BY_PROVIDER;

export const isCustomerReviewLTL = transition => {
  return [TRANSITION_LTL_REVIEW_1_BY_CUSTOMER, TRANSITION_LTL_REVIEW_2_BY_CUSTOMER].includes(
    transition
  );
};

export const isProviderReviewLTL = transition => {
  return [TRANSITION_LTL_REVIEW_1_BY_PROVIDER, TRANSITION_LTL_REVIEW_2_BY_PROVIDER].includes(
    transition
  );
};

export const txIsCancelledTransactionLTL = tx =>
  txIsCancelledLTL(tx) || txIsPaymentExpiredLTL(tx) || txIsDeclinedLTL(tx);
// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransitionLTL = transition => {
  return [
    TRANSITION_LTL_EXPIRE_PAYMENT,
    TRANSITION_LTL_TRIP_END,
    TRANSITION_LTL_REQUEST_DROP_OFF,
    TRANSITION_LTL_AUTO_COMPLETE,
    TRANSITION_LTL_CONFIRM_DROP_OFF,
    TRANSITION_LTL_CUSTOMER_CANCEL,
    TRANSITION_LTL_ADMIN_NEED_CANCEL,
    TRANSITION_LTL_ADMIN_CANCEL_BEFORE_DROP_OFF_REQUESTED,
    TRANSITION_LTL_ADMIN_CANCEL_AFTER_DROP_OFF_REQUESTED,
    TRANSITION_LTL_ADMIN_CANCEL_REFUND,
    TRANSITION_LTL_ADMIN_CANCEL_NON_REFUND,
    TRANSITION_LTL_ADMIN_REFUND,
    TRANSITION_LTL_REVIEW_1_BY_PROVIDER,
    TRANSITION_LTL_REVIEW_2_BY_PROVIDER,
    TRANSITION_LTL_REVIEW_1_BY_CUSTOMER,
    TRANSITION_LTL_REVIEW_2_BY_CUSTOMER,
  ].includes(transition);
};

export const txIsNormalCommercial = tx =>
  hasPassedTransition(TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_COMMERCIAL, tx);
export const txIsNormalPrivate = tx =>
  hasPassedTransition(TRANSITION_LTL_REQUEST_PAYMENT_NORMAL_PRIVATE, tx);

export const txCancelByCustomerLTL = tx => hasPassedTransition(TRANSITION_LTL_CUSTOMER_CANCEL, tx);
