import classNames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { array, bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { updateIdentity } from '../../ducks/user.duck';
import { EditListingVerificationForm } from '../../forms';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import { EVENT_HOST_VERIFY_PRESSED_SUBMIT } from '../../util/gtm/gtmConstants';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './EditListingVerificationPanel.css';

const { LatLng } = sdkTypes;

class EditListingVerificationPanelComp extends Component {
  constructor (props) {
    super(props);
    const { currentUser } = props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const { profile = {} } = ensureCurrentUser.attributes || {};
    const { protectedData = {} } = profile || {};
    const {
      passportFirstPage = null,
      passportLastPage = null,
      frontalPhoto = null,
      hostHoldingDrivingLicensePhoto = null,
      drivingLicenseFront = null,
      drivingLicenseBack = null,
    } = protectedData;
    this.currentInitialValues = {
      photo: frontalPhoto,
      passportFirstPage,
      passportLastPage,
      hostHoldingDrivingLicensePhoto,
      drivingLicenseFront,
      drivingLicenseBack,
    };
    this.state = {
      title: null,
    };
  }

  setData = (property, data) => {
    this.currentInitialValues[property] = data;
    console.log(this.currentInitialValues);
  };

  render() {
    const {
      className,
      rootClassName,
      errors,
      fetchInProgress,
      newListingPublished,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      currentUser,
      listing,
      handlePushEventGTM,
      submitButtonId,
      updateIdentityError,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const isVerified = get(currentUser, 'identityStatus.hostVerified', false);
    const underVerified = get(currentUser, 'identityStatus.hostUnderVerify', false);
    console.log("currentUser---->", currentUser)
    const panelTitle = underVerified ? (
      // <span>{currentUser.attributes.profile.displayName}</span>
      <FormattedMessage id="EditListingVerificationPanel.createListingTitleFirstTime" />
    ) : isVerified ? (
      <FormattedMessage
        id="EditListingVerificationPanel.createListingTitle"
        values={{ newline: <br /> }}
      />
    ) : null;

    const userName =
      !!currentUser && !!currentUser.id
        ? currentUser.attributes.profile.displayName
        : this.state.title;
    const title = updateIdentityError ? null : this.state.title ? this.state.title : panelTitle;
    const userLocation = get(currentUser, 'attributes.profile.protectedData.location', null);
    const location = userLocation
      ? {
        ...userLocation,
        selectedPlace: {
          ...userLocation.selectedPlace,
          origin: new LatLng(
            userLocation.selectedPlace.origin.lat,
            userLocation.selectedPlace.origin.lng
          ),
        },
      }
      : {
        search: get(currentListing, 'attributes.publicData.location.address', ''),
        selectedPlace: {
          address: get(currentListing, 'attributes.publicData.location.address', ''),
          origin: currentListing.attributes.geolocation,
        },
      };
    const stripeConnected = get(currentUser, 'attributes.stripeConnected', false);
    const initialValues = {
      postalCode:
        get(currentListing, 'attributes.publicData.location.postalCode', null) ||
        get(currentUser, 'attributes.profile.protectedData.postalCode'),
      location: location
        ? {
          search: location.search,
          selectedPlace: {
            address: location.selectedPlace.address,
            origin: location.selectedPlace.origin,
          },
        }
        : null,
      state:
        get(currentListing, 'attributes.publicData.location.state', null) ||
        get(currentUser, 'attributes.profile.protectedData.state'),
      suburb:
        get(currentListing, 'attributes.publicData.location.suburb', null) ||
        get(currentUser, 'attributes.profile.protectedData.suburb'),
      unitNumber:
        get(currentListing, 'attributes.publicData.location.unitNumber', null) ||
        get(currentUser, 'attributes.profile.protectedData.unitNumber'),
      streetNumber:
        get(currentListing, 'attributes.publicData.location.streetNumber', null) ||
        get(currentUser, 'attributes.profile.protectedData.streetNumber'),
      city:
        get(currentListing, 'attributes.publicData.location.city', null) ||
        get(currentUser, 'attributes.profile.protectedData.city'),
      drivingLicenseFront: get(currentUser, 'attributes.profile.protectedData.drivingLicenseFront'),
      drivingLicenseBack: get(currentUser, 'attributes.profile.protectedData.drivingLicenseBack'),
      hostHoldingDrivingLicensePhoto: get(
        currentUser,
        'attributes.profile.protectedData.hostHoldingDrivingLicensePhoto'
      ),
      passportFirstPage: get(currentUser, 'attributes.profile.protectedData.passportFirstPage'),
      passportLastPage: get(currentUser, 'attributes.profile.protectedData.passportLastPage'),
      typeOfBusiness: get(currentUser, 'attributes.profile.protectedData.typeOfBusiness'),
      abnNumber: get(currentUser, 'attributes.profile.protectedData.abnNumber', null),
    };
    return (
      <div className={classes}>
        <div className={css.titleSection}>
          <h1 className={css.title}>Verification</h1>
          <p>
            As you are hosting for the first time on Drive mate there are a few extra steps you will need to complete to get approved.
          </p>
        </div>

        <EditListingVerificationForm
          className={css.form}
          disabled={fetchInProgress}
          ready={newListingPublished}
          fetchErrors={errors}
          initialValues={initialValues}
          onSubmit={values => {
            const { typeOfBusiness, abnNumber } = values;
            handlePushEventGTM(submitButtonId, EVENT_HOST_VERIFY_PRESSED_SUBMIT);
            const hasProfilePhoto = this.props.currentUser && this.props.currentUser.profileImage;
            const initialPhoto = this.currentInitialValues.photo
              ? this.currentInitialValues.photo
              : hasProfilePhoto
                ? [this.props.currentUser.profileImage.attributes.variants['square-small2x'].url]
                : null;
            if (this.props.imageUpload) {
              this.props.onUpdateProfile({
                profileImageId: this.props.imageUpload.imageId,
              });
            }
            this.props.updateIdentity(
              {
                ...pickBy(values, identity),
                ...pickBy(this.currentInitialValues, identity),
                typeOfBusiness,
                ...(typeOfBusiness === 'company' ? { abnNumber } : {}),
                email: this.props.currentUser.attributes.email,
                photo: initialPhoto,
              },
              'HOST'
            );
            if (userName) {
              this.setState({
                title: userName + ',',
              });
            }
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          currentUser={this.props.currentUser}
          drivingLicenseFrontUrl={get(
            currentUser,
            'attributes.profile.protectedData.drivingLicenseFront',
            ''
          )}
          drivingLicenseBackUrl={get(
            currentUser,
            'attributes.profile.protectedData.drivingLicenseBack',
            ''
          )}
          hostHoldingDrivingLicenseUrl={get(
            currentUser,
            'attributes.profile.protectedData.hostHoldingDrivingLicensePhoto',
            ''
          )}
          passportFirstPageUrl={get(
            currentUser,
            'attributes.profile.protectedData.passportFirstPage',
            ''
          )}
          passportLastPageUrl={get(
            currentUser,
            'attributes.profile.protectedData.passportLastPage',
            ''
          )}
          photo={get(currentUser, 'attributes.profile.protectedData.frontalPhoto', '')}
          setData={this.setData}
          stripeConnected={stripeConnected}
          pushEventGTM={handlePushEventGTM}
          submitButtonId={submitButtonId}
          updateIdentityError={updateIdentityError}
          underVerified={underVerified}
        />
      </div>
    );
  }
}

EditListingVerificationPanelComp.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingVerificationPanelComp.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  handlePushEventGTM: func,
  submitButtonId: string,
};

const mapStateToProps = state => {
  const { image } = state.ProfileSettingsPage;
  const { updateIdentityError } = state.user;
  return {
    imageUpload: image,
    updateIdentityError,
  };
};

const mapDispatchToProps = dispatch => ({
  updateIdentity: (userData, type) => dispatch(updateIdentity(userData, type)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const EditListingVerificationPanel = compose(connect(mapStateToProps, mapDispatchToProps))(
  EditListingVerificationPanelComp
);

export default EditListingVerificationPanel;
