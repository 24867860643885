import React, { memo } from 'react';
import { AvatarLarge, AvatarMedium, NamedLink } from '../../components';

import css from './ListingPage.css';
import isEqual from 'lodash/isEqual';

const SectionAvatar = memo(props => {
  const { user, params } = props;
  return (
    <div className={css.sectionAvatar}>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarLarge user={user} className={css.avatarDesktop} disableProfileLink />
      </NamedLink>
      <NamedLink name="ListingPage" params={params} to={{ hash: '#host' }}>
        <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink />
      </NamedLink>
    </div>
  );
}, (prev, next) => {
    return isEqual(prev, next);
});

export default SectionAvatar;
