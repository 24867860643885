import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.css';
import { txIsEnquired } from '../../util/transaction';
import { FormattedMessage } from '../../util/reactIntl';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    transaction,
    showDetailCardHeadings,
    listingTitle,
    location,
    geolocation,
    showAddress,
    deliveryLineItem,
  } = props;

  const canShowDetailCardHeadings = !txIsEnquired(transaction);

  return canShowDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>
        <FormattedMessage id="TransactionPanel.tripCode" />
      </h2>
      <p className={css.tripCode}>{transaction.id.uuid}</p>
      {!deliveryLineItem && <h2 className={css.detailCardTitle}>
        <FormattedMessage id="TransactionPanel.pickupLocationTitle"/>
      </h2>}
      <p className={css.detailCardSubtitle} />
      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
