import React, { Component, useRef } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldToggleSwitch.css';

const ToogleSwitchComponent = props => {
  const {
    rootClassName,
    className,
    inputClassName,
    inputRootClass,
    customErrorText,
    label,
    input,
    meta,
    id,
    onUnmount,
    rootLabelClass,
    labelClassname,
    customSliderClassName,
    toggleSwitchClassName,
    onChangeInstantBooking,
    ...rest
  } = props;

  const checkboxRef = useRef(null);
  const { invalid, touched, error } = meta;

  const errorText = customErrorText || error;

  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const checkboxProps = {
    id,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const { onChange, value } = input;
  const handleClick = (e) => {
    onChange(checkboxRef.current.checked);
    if (onChangeInstantBooking) {
      onChangeInstantBooking(checkboxRef.current.checked);
    }
  }

  const classes = classNames(rootClassName || css.root, className);
  const labelClasses = classNames(rootLabelClass || css.label, labelClassname);
  return (
    <div className={classes}>
      <label className={labelClasses}>{label}</label>
      <div className={css.toggleMapButton}>
        <label htmlFor={id} className={classNames(toggleSwitchClassName,css.switch)}>
          <input {...checkboxProps} ref={checkboxRef} onClick={handleClick} checked={!!value}/>
          <span className={classNames(customSliderClassName, css.slider, css.round)}></span>
        </label>
      </div>
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
}

// class FieldToggleSwitchComponent extends Component {
//   state = {
//     checked: false
//   }
//   render() {
//     /* eslint-disable no-unused-vars */
//     const {
//       rootClassName,
//       className,
//       inputClassName,
//       inputRootClass,
//       customErrorText,
//       label,
//       input,
//       meta,
//       id,
//       onUnmount,
//       ...rest
//     } = this.props;
//     /* eslint-enable no-unused-vars */

//     const { invalid, touched, error } = meta;

//     const errorText = customErrorText || error;

//     // Error message and input error styles are only shown if the
//     // field has been touched and the validation has failed.
//     const hasError = !!customErrorText || !!(touched && invalid && error);

//     const fieldMeta = { touched: hasError, error: errorText };

//     const checkboxProps = {
//       id,
//       component: 'input',
//       type: 'checkbox',
//       ...rest,
//     };


//   }
// }

// FieldToggleSwitchComponent.defaultProps = {
//   rootClassName: null,
//   className: null,
//   inputRootClass: null,
//   inputClassName: null,
//   onUnmount: null,
//   customErrorText: null,
//   id: null,
//   label: null,
// };

// FieldToggleSwitchComponent.propTypes = {
//   rootClassName: string,
//   className: string,
//   inputClassName: string,
//   inputRootClass: string,

//   // Error message that can be manually passed to input field,
//   // overrides default validation message
//   customErrorText: string,

//   // Label is optional, but if it is given, an id is also required so
//   // the label can reference the input in the `for` attribute
//   id: string,
//   label: string,
//   meta: object.isRequired,
// };

class FieldToggleSwitch extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }
  render() {
    return <Field component={ToogleSwitchComponent} {...this.props} />;
  }
}

export default FieldToggleSwitch;
