import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { formatMoney } from '../../../util/currency';
import css from '../BookingBreakdown.css';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemLongTermRentalTotal = ({ transaction, isCustomer, intl }) => {

  let totalPrice = get(transaction, 'attributes.protectedData.longTermTotalTripPrice', 99900);

  if (!isCustomer || !totalPrice) {
    return null;
  }

  totalPrice = new Money(totalPrice, config.currency);

  const formattedTotalPrice = totalPrice ? formatMoney(intl, totalPrice) : null;

  return formattedTotalPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.longTermRentalTotalPrice" />
      </span>
      <span className={css.itemValue}>{formattedTotalPrice}</span>
    </div>
  ) : null;
};

export default LineItemLongTermRentalTotal;
