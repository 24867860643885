import React from 'react';
import styles from './VerificationStatus.module.css'
import Enable from '../../assets/verificationPage/Enable.svg';
import Disable from '../../assets/verificationPage/Disable.svg';
import IdentityVerification from '../../assets/verificationPage/IdentityVerification.svg';
import Card from '../../assets/verificationPage/Card.svg';
import PhoneNumber from '../../assets/verificationPage/PhoneNumber.svg';

const VerificationStatus = ({ isHost, status, statusID, isPhoneVerified, isSubmittedOnfidoVerification, isCardVerified }) => {

  return (
    <>
      <div className={styles.headingContainer}>
        <p>Verification Status</p>
        <p>{statusID === 'AccountSettingDriverVerificationPage.inProgress' ? 'In Progress' : statusID === 'AccountSettingDriverVerificationPage.pending' ? 'Under Review' : status}</p>
      </div>
      <hr className={styles.separatorHR}/>
      <div className={styles.detailsContainer}>
        {(statusID === 'AccountSettingDriverVerificationPage.notSubmit' || statusID === 'AccountSettingDriverVerificationPage.inProgress') && (
        <p>
          To ensure the safety of our community we need to verify each user who wants to rent a car on Drive mate. The process only takes 5 minutes to complete. Please ensure you have your identity documents handy to provide when asked.
        </p>
        )}
        {statusID === 'AccountSettingDriverVerificationPage.pending' && (
        <p>
          Thank you Verification. Your details have been submitted successfully.
        <br />
        <br />
          We will be reviewing the details you have submitted. It typically takes 2-3 hours for verification. In case of an urgent booking request, you can also contact Drive mate admin +61341602205 or chat with one of our agents below.
        </p>
        )}
        {statusID === 'AccountSettingDriverVerificationPage.declined' && (
        <p>
          According to the documents you have provided, we were unable to verify your account, and as a result, you are not authorized to rent on Drive mate. Please contact our team if you have any questions.
        </p>
        )}
        {statusID === 'AccountSettingDriverVerificationPage.submitted' && (
        <p>
          Your account is verified, Thanks.
        </p>
        )}
        {(statusID === 'AccountSettingDriverVerificationPage.notSubmit' || statusID === 'AccountSettingDriverVerificationPage.inProgress' || statusID === 'AccountSettingDriverVerificationPage.submitted') && (
        <div className={styles.listOfVerifiations}>
          <p>
            <span><img src={PhoneNumber} /></span>
            <span>Pesonal Details</span>
            <span><img src={isPhoneVerified ? Enable : Disable} /></span>
          </p>
          <p>
            <span><img src={IdentityVerification} /></span>
            <span>Identity Verification</span>
            <span><img src={isSubmittedOnfidoVerification ? Enable : Disable} /></span>
          </p>
          {!isHost && (<p>
            <span><img src={Card} /></span>
            <span>Credit Verification</span>
            <span><img src={isCardVerified ? Enable : Disable} /></span>
          </p>)}
        </div>
        )}
        <div className={styles.button}></div>
      </div>
    </>
  );
};

export default VerificationStatus;