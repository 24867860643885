import get from 'lodash/get';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROCESSING_FEE } from './types';
import moment from 'moment';

/**
 * We will use this to send gtag events from where user has logged-in or signup
 */
export const GTAG_METHODS = {
    WEB_LOGIN: 'Email',
    WEB_SIGNUP: 'Email',
    GOOGLE_LOGIN: 'Google',
    APPLE_LOGIN: 'Apple',
    FACEBOOK_LOGIN: 'Facebook',
    GOOGLE_SIGNUP: 'Google',
    APPLE_SIGNUP: 'Apple',
    FACEBOOK_SIGNUP: 'Facebook',
};

/**
 * GTag actions constant listing here
 */
export const GTAG_ACTIONS = {
    ACTION_LOGIN: 'login',
    ACTION_SIGNUP: 'sign_up',
    ACTION_SEARCH: 'search',
    ACTION_VIEW_ITEM: 'view_item',
    ACTION_BEGIN_CHECKOUT: 'begin_checkout',
    ACTION_PURCHASE: 'purchase',
};

/**
 * This method will send a gtag event
 * @param {string} action 
 * @param {Object} data 
 * @param {string} type 
 */
export const gSend = (action, data, type = 'event') => {
    if (!action || !data || !Object.keys(data).length) {
        return;
    }
    const windowEvent = typeof window !== 'undefined' ? window : {};
    if (windowEvent && windowEvent.ga4GTag) {
        windowEvent.ga4GTag(type, action, data);
    }
};

/**
 * This method returns the formatted items for ActionItems
 * @param {Object} transaction 
 * @param {Object} params 
 * @returns {Array<Object>}
 */
export const getActionItems = (transaction, params, type = GTAG_ACTIONS.ACTION_VIEW_ITEM) => {
    let items = [];
    let dates = {};
    let hours = {};
    params = params || {};

    // Date handling
    if (params.dates) {
        dates = getFormattedDateByDates(params.dates);
    } else if (params.bookingStartDate && params.bookingStartDate.date && params.bookingEndDate && params.bookingEndDate.date) {
        dates.start = moment(params.bookingStartDate.date).format('YYYY-MM-DD');
        dates.end = moment(params.bookingEndDate.date).format('YYYY-MM-DD');
    } else if (transaction &&
        transaction.booking &&
        transaction.booking.attributes &&
        transaction.booking.attributes.displayStart &&
        transaction.booking.attributes.displayEnd) {
        dates.start = moment(transaction.booking.attributes.displayStart).format('YYYY-MM-DD');
        dates.end = moment(transaction.booking.attributes.displayEnd).format('YYYY-MM-DD');
    }

    // Time handling
    if (params.hours) {
        hours = getHourlyFormattedTimes(params.hours);
    } else if (params.bookingStartTime && params.bookingEndTime) {
        hours.start = moment(parseInt(params.bookingStartTime)).format('HH:mm');
        hours.end = moment(parseInt(params.bookingEndTime)).format('HH:mm');
    } else if (transaction && transaction.booking && transaction.booking.attributes && transaction.booking.attributes.displayStart && transaction.booking.attributes.displayEnd) {
        hours.start = moment(transaction.booking.attributes.displayStart).format('HH:mm');
        hours.end = moment(transaction.booking.attributes.displayEnd).format('HH:mm');
    }

    switch (type) {
        case GTAG_ACTIONS.ACTION_PURCHASE:
        case GTAG_ACTIONS.ACTION_VIEW_ITEM:
        case GTAG_ACTIONS.ACTION_BEGIN_CHECKOUT:
            // Generate Listing Item
            const item = {
                item_id: get(transaction, 'id.uuid'),
                item_name: get(transaction, 'attributes.title'),
                price: get(transaction, 'attributes.price.amount'),
                pick_up_date: dates.start ? dates.start : '',
                pick_up_time: hours.start ? hours.start : '',
                drop_off_date: dates.end ? dates.end : '',
                drop_off_time: hours.end ? hours.end : '',
            };
            items.push(item);

            // Generate Actual Line Items (if any)
            if (transaction && transaction.attributes && transaction.attributes.lineItems && transaction.attributes.lineItems.length) {
                for (let i = 0; i < transaction.attributes.lineItems.length; i++) {
                    const lineItem = transaction.attributes.lineItems[i] || {};
                    const item = {
                        item_id: get(lineItem, 'code'),
                        item_name: get(lineItem, 'code'),
                        price: get(lineItem, 'unitPrice.amount'),
                        pick_up_date: dates.start ? dates.start : '',
                        pick_up_time: hours.start ? hours.start : '',
                        drop_off_date: dates.end ? dates.end : '',
                        drop_off_time: hours.end ? hours.end : '',
                    };
                    items.push(item);
                }
            }
            break;
        default:
    }

    return items;
};


/**
 * This method will send the event to ga 4 api's
 * @param {Object} transaction 
 * @param {string} type 
 * @param {Object} params 
 */
export const sendG4AEvent = (transaction, type, { searchParams, bookingDates, ...props }) => {
    switch (type) {
        case GTAG_ACTIONS.ACTION_PURCHASE:
            let commission = 0;
            let processing_fees = 0;
            const lineItems = transaction &&
                transaction.attributes &&
                transaction.attributes.lineItems &&
                transaction.attributes.lineItems.length ? transaction.attributes.lineItems : [];

            lineItems.forEach(lineItem => {
                if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION) {
                    commission = lineItem.lineTotal.amount;
                } else if (lineItem.code === LINE_ITEM_PROCESSING_FEE) {
                    processing_fees = lineItem.lineTotal.amount;
                }
            });

            gSend(GTAG_ACTIONS.ACTION_PURCHASE, {
                transaction_id: get(transaction, 'id.uuid'),
                currency: get(transaction, 'attributes.price.currency'),
                value: get(transaction, 'attributes.payinTotal.amount'),
                coupon: get(transaction, 'attributes.protectedData.code'),
                commission: commission,
                processing: processing_fees,
                items: getActionItems(transaction, null, GTAG_ACTIONS.ACTION_PURCHASE),
            });
            break;
        case GTAG_ACTIONS.ACTION_BEGIN_CHECKOUT:
            gSend(GTAG_ACTIONS.ACTION_BEGIN_CHECKOUT, {
                currency: get(transaction, 'attributes.price.currency'),
                value: get(transaction, 'attributes.payinTotal.amount'),
                coupon: get(transaction, 'attributes.protectedData.code'),
                items: getActionItems(transaction, bookingDates, GTAG_ACTIONS.ACTION_BEGIN_CHECKOUT),
            });
            break;
        case GTAG_ACTIONS.ACTION_VIEW_ITEM:
            gSend(GTAG_ACTIONS.ACTION_VIEW_ITEM, {
                currency: get(transaction, 'attributes.price.currency'),
                items: getActionItems(transaction, searchParams, GTAG_ACTIONS.ACTION_VIEW_ITEM),
            });
            break;
        default:
    }
};

/**
 * This method returns the formatted hours
 * @param {Object|string} hours 
 * @returns {Object}
 */
export const getHourlyFormattedTimes = (hours) => {
    if (hours && typeof hours === 'string' && hours.split && hours.split(',').length) {
        return { start: hours.split(',')[0], end: hours.split(',')[1] || '' };
    }
    return { start: '', end: '' };
};

/**
 * This method returns the formatted dates
 * @param {Object|string} dates 
 * @returns {Object}
 */
export const getFormattedDateByDates = (dates) => {
    if (dates && typeof dates === 'string' && dates.split && dates.split(',').length) {
        return { start: dates.split(',')[0], end: dates.split(',')[1] || '' };
    }
    return { start: '', end: '' };
};