/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useState } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';
import insuranceLogo from '../../assets/insurance-logo.svg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { encodeLatLngBounds } from '../../util/urlHelpers';
import { useHistory } from 'react-router';
import InsuranceLandingModal from '../../components/InsuranceLandingModal/InsuranceLandingModal';
import { TrustBox } from '../../components';
import imageSource from '../../util/imageSource';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
const SeoLandingBannerLocation = memo(
  ({ isMobileLayout, address, bounds, selectedValues, onManageDisableScrolling }) => {
    const history = useHistory();
    const addressShortened = address.split(',');
    const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);
    const _handleLocationValues = () => {
      const url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
        bounds: encodeLatLngBounds(bounds ? bounds : DEFAULT_BOUNDS),
        address,
      });
      history.push(url);
    };

    let LocAddress;
    switch (address) {

      case 'Tampines, Singapore':
        LocAddress = "Cars for rent in Tampines, Singapore";
        break;

      case 'Jurong, Singapore':
        LocAddress = "Car Rental in Jurong, Singapore";
        break;

      case 'Bukit Batok, Singapore':
        LocAddress = "Choose from the top rented cars in Bukit Batok";
        break;

      case 'Bukit Panjang, Singapore':
        LocAddress = "Car Rental Bukit Panjang, Singapore";
        break;

      case 'Punggol, Singapore':
        LocAddress = "Car Rental Punggol, Singapore";
        break;

      case 'Sembawang, Singapore':
        LocAddress = "Car Rental Sembawang, Singapore";
        break;

      case 'Yishun, Singapore':
        LocAddress = "Car Rental Yishun, Singapore";
        break;

      case 'Woodlands, Singapore':
        LocAddress = "car rental in Woodlands";
        break;

      case 'Ang Mo Kio, Singapore':
        LocAddress = "car rental in Ang Mo Kio";
        break;

      case 'Choa Chu Kang, Singapore':
        LocAddress = "car rental in Choa Chu Kang";
        break;

      case 'Serangon, Singapore':
        LocAddress = "car rental in Serangon";
        break;

      case 'Sengkang, Singapore':
        LocAddress = "car rental in Sengkang";
        break;

      case 'Hougang, Singapore':
        LocAddress = "car Rental in Hougang";
        break;

    }

    return (
      <div className={css.landingPageBanner}>
        <div className={css.landingContainer}>
          <div className={css.landingRow}>
            <div className={css.bannerSectionLeft}>
              <h1 className={css.landingPageBannerTitle}> {LocAddress}</h1>
              {isMobileLayout ? (
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              ) : (
                ''
              )}
              <p>
                With Drive mate, you can rent from a huge variety of cars in{' '}
                {addressShortened && addressShortened[0]} at great value. Fully insured+.
              </p>
              <div>
                <span onClick={_handleLocationValues} className={css.searchForTheLocation}>
                  <svg width="20.665" height="22" viewBox="0 0 20.665 22">
                    <defs>
                      <style></style>
                      <clipPath id="a">
                        <path class="a" fill="#026786" d="M0,0H20.665V22H0Z" />
                      </clipPath>
                    </defs>
                    <path class="a" d="M20.665,25.5h0Z" transform="translate(0 -3.501)" />
                    <g class="b" clip-path="url(#a)">
                      <path
                        class="a"
                        fill="#026786"
                        d="M19.64,17.263a7.151,7.151,0,0,0-4.989-3.481,5.806,5.806,0,0,0-3.4.792,7.071,7.071,0,0,1-1.269.5,6.921,6.921,0,1,1,3.839-11c.5.638,1.975,3.043.616,7.645a10.868,10.868,0,0,1,1.43.191c.515-1.888,1.247-5.945-.925-8.715A8.362,8.362,0,0,0,.062,7.36a8.331,8.331,0,0,0,10.232,9.109,10.065,10.065,0,0,0,2.555-1.015c1.441-.664,3.809-.137,5.6,2.59.89,1.356.885,2.288.686,2.464-.164.146-.738.051-1.477-.407A6.512,6.512,0,0,1,15,17.008a1.887,1.887,0,0,0-1.491-.147c.26,1.694,1.963,3.56,3.4,4.451a3.889,3.889,0,0,0,2,.688,1.717,1.717,0,0,0,1.175-.424c.921-.813.75-2.506-.437-4.314"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>{' '}
                  Search for available cars in {addressShortened && addressShortened[0]}
                </span>
                {/* <NamedLink
                  name="SeoLocationLandingPage"
                  className={css.listingsItemContent}
                  params={{ cat: 'australia-locations', subcat: 'car-rental-in-tampines' }}
                >
                  Tampines
                </NamedLink> */}
                {/* <div className={css.insuranceWrapper}>
                  <div style={{ flex: 1, padding: '0 20px' }}>
                    <div className={css.insuranceText}>Insurance provided by</div>
                    <img className={css.insuranceImage} src={insurance} alt="Tokio Marine" />
                  </div>
                  <div style={{ flex: 1, padding: '0 20px' }}>
                    <div className={css.insuranceText}>Approved by</div>
                    <img className={css.approvedByLogo} src={landTransport} alt="Land Transport" />
                  </div>
                </div> */}
                <div className={css.insuranceListingWrapper}>
                  <div
                    className={css.comprehensiveInsuranceBlock}
                    onClick={() => setInsuranceModalOpen(true)}
                  >
                    {/* <div className={css.trustPilotLogo}>
                      <TrustBox />
                    </div> */}
                    <div className={css.comprehensiveInsuranceBlockWrapper}>
                      <div className={css.cILogo}>
                        <img src={insuranceLogo} alt="" />
                      </div>
                      <div className={css.cIContent}>
                        <span>
                          Comprehensively insured with Drive mate protection<sup>TM</sup>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isMobileLayout ? (
              <div className={css.bannerSectionRight}>
                <div className={css.landingPageBannerImage}>
                  <img
                    className={css.featureImage}
                    src={(selectedValues && selectedValues.image) || imageSource.seoLandingPage.landingBannerImage}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <InsuranceLandingModal
          onManageDisableScrolling={onManageDisableScrolling}
          isModalOpen={isInsuranceModalOpen}
          setModalVisibility={setInsuranceModalOpen}
        />
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingBannerLocation;
