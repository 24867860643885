import React, { useEffect, useState } from 'react';
import css from './UserPictureIDVerification.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { compose } from 'redux';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { saveBackgroundConsent } from './BackgroundCheckConsent.duck';
import { FieldRadioButton, Form, SecondaryButton } from '../../components';

const BackgroundCheckConsentComponent = props => {
  const [timerValue, setTimerValue] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const {
    modifyStep,
    handleSteps,
    step,
    title,
    onSubmitBackgroundConsentForm,
    handleContinueVerification,
    saveBackgroundConsentSuccess,
  } = props;

  // Timer function that updates the timer value every second
  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimerValue((prevValue) => prevValue + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning]);

  React.useEffect(() => {
    modifyStep && handleSteps(step, title);
  }, []);

  React.useEffect(() => {
    console.log('saveBackgroundConsentSuccess:', saveBackgroundConsentSuccess, handleContinueVerification);
  }, [saveBackgroundConsentSuccess]);

  const onSubmit = (e) => {
    if (!e) { e = {}; }
    if (e && !e.hasOwnProperty('backgroundCheckConsentAccepted')) {
      return;
    }
    // change data type based on value `true` or `false`
    if (e.backgroundCheckConsentAccepted === 'true') {
      e.backgroundCheckConsentAccepted = true;
    } else if (e.backgroundCheckConsentAccepted === 'false') {
      e.backgroundCheckConsentAccepted = false;
    }
    e.timeTakenInSeconds = timerValue;
    setIsTimerRunning(false);
    onSubmitBackgroundConsentForm(e).then(() => {
      if (handleContinueVerification && typeof handleContinueVerification === 'function'){
        handleContinueVerification(true);
      }
    });
  }

  const getForm = () => {
    return (
      <FinalForm
        {...props}
        onSubmit={onSubmit}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveBackgroundConsentError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
          } = fieldRenderProps;
          const { backgroundCheckConsentAccepted } = values;
          let isBackgroundCheckConsentValid = false;
          if (backgroundCheckConsentAccepted === 'true' ||
            backgroundCheckConsentAccepted === 'false' ||
            backgroundCheckConsentAccepted === true ||
            backgroundCheckConsentAccepted === false) {
            isBackgroundCheckConsentValid = true;
          }
          const user = ensureCurrentUser(currentUser);
          if (!user.id) {
            return null;
          }
          const { profile } = user.attributes;
          // value set
          const protectedData = profile.protectedData || {};
          const currentBackgroundCheckConsentAccepted = protectedData.backgroundCheckConsentAccepted || '';
          // value changed
          const isValueChanged = currentBackgroundCheckConsentAccepted.toString() !== (backgroundCheckConsentAccepted || '').toString();
          let genericError = saveBackgroundConsentError;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress || !isBackgroundCheckConsentValid;

          const optionLabel1 = <FormattedHTMLMessage id={'VerificationStatusIndication.backgroundConsentOption1'} />;
          const optionLabel2 = <FormattedHTMLMessage id={'VerificationStatusIndication.backgroundConsentOption2'} />;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={''}>
                <div className={''}>
                  <FieldRadioButton
                    id="backgroundCheckConsentAccepted1"
                    name="backgroundCheckConsentAccepted"
                    label={optionLabel1}
                    value={'true'}
                    showAsRequired={true}
                    inputWrapperClass={css.selectInputWrapper}
                    checked={backgroundCheckConsentAccepted === 'true'}
                  />
                </div>
                <div className={''}>
                  <FieldRadioButton
                    id="backgroundCheckConsentAccepted2"
                    name="backgroundCheckConsentAccepted"
                    label={optionLabel2}
                    value={'false'}
                    showAsRequired={true}
                    inputWrapperClass={css.selectInputWrapper}
                    checked={backgroundCheckConsentAccepted === 'false'}
                  />
                </div>
              </div>
              <div className={css.bottomWrapper}>
                {genericError}
                <SecondaryButton
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled}
                  className={css.backgroundConsentSubmitClass}
                >
                  <FormattedMessage id="VerificationStatusIndication.backgroundConsentSubmit" />
                </SecondaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }

  return (
    <>
      <div className={css.backgroundCheckConsentContainer} style={{ zIndex: 999 }}>
        <div className={css.backgroundCheckConsentDetails}>
          <p>
            For everyone's safety, we occasionally run background checks on our members. It helps keep our community trusted and secure.
          </p>
          <p>
            By providing your consent, you actively contribute to the safety of our community and enable us to create a reliable car-sharing experience. Your personal information will be handled with the utmost care and in compliance with applicable privacy laws and regulations.
          </p>
          <p>
            Please note that providing consent for the background check is optional. However, without your consent, we may not be able to approve your car rental request. We are dedicated to your safety and look forward to providing you with a seamless and secure car rental experience.
          </p>
        </div>
        <div>
          {
            getForm()
          }
        </div>
      </div>
    </>
  );
};


BackgroundCheckConsentComponent.defaultProps = {
  currentUser: null,
  saveBackgroundConsentSuccess: false,
  saveBackgroundConsentError: false,
};

const { bool, func } = PropTypes;

BackgroundCheckConsentComponent.propTypes = {
  onSubmitBackgroundConsentForm: func.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  console.log('mapStateToProps:', state);
  const { currentUser } = state.user;
  const {
    saveBackgroundConsentSuccess,
    saveBackgroundConsentError,
    saveBackgroundConsentInProgress,
  } = state.AddressDetailsPage;

  return {
    currentUser,
    saveBackgroundConsentSuccess,
    saveBackgroundConsentError,
    saveBackgroundConsentInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitBackgroundConsentForm: values => dispatch(saveBackgroundConsent(values)),
  };
};

const BackgroundCheckConsent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(BackgroundCheckConsentComponent);

export default BackgroundCheckConsent;
