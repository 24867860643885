import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { Modal } from '../../../components';

import css from './CovidCheckoutPage.css';

export const ModalTermAndConditionsCovid = (props) => {
  const {
    containerClassName,
    onManageDisableScrolling,
    showModal,
    onClose
  } = props;

  const content = (
    <div className={css.container}>
      <h2 className={css.text}>Terms and Conditions</h2>
      <p>1. Quantum-Ion® distributed to Drive mate by Fix It Pte. Ltd., is owned, manufactured and marketed by an independent party over which Drive mate has no control.</p>

      <p>2. Quantum-Ion® is sold only with the warranties provided by the manufacturer of product, if any. Drive mate MAKES NO OTHER WARRANTY WITH RESPECT TO THE PRODUCTS, AND DISCLAIMS ANY AND ALL WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Drive mate personnel are not authorized to alter this disclaimer of warranty. </p>

      <p>3. Unless otherwise expressly agreed in writing, Drive mate and its affiliates (collectively &quot;Drivelah&quot;) are not in any way associated with the owner or manufacturer of the Quantum-Ion® or
          responsible or liable for the goods and services offered by them or for anything in connection with Quantum-Ion®.</p>
      
      <p>4. Limitation of Liability.</p>

      <ol type='a'>
        <li>IN NO EVENT SHALL Drive mate BE LIABLE TO BUYER OR ANY THIRD PARTY FOR ANY LOSS OF USE, REVENUE OR PROFIT OR DIMINUTION IN VALUE, OR FOR ANY
            CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT Drive mate HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</li>
        <li>IN NO EVENT SHALL Drive mate&#39;s AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS CONTRACT, WHETHER ARISING OUT OF OR RELATED TO BREACH
            OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO Drive mate FOR THE PRODUCTS SOLD HEREUNDER.</li>
        <li>This limitation of liability is a material basis for the parties’ bargain and reflects the bargained-for allocation of risks between Drive mate and Buyer, without which Drive
            lah would not have agreed to provide the Product at the price charged.</li>
      </ol>

      <p>5. Cancellation of Purchase Order</p>

      <ol type='a'>
        <li>Buyer may cancel its order only with the prior written consent of Drive mate, which Drive mate may withhold in its sole discretion. All cancelations will be subject to
            payment to Drive mate of reasonable and proper cancelation charges. Buyer may return Products only at its sole cost and only with the prior written authorization of
            Seller. No returns will be permitted more than sixty (60) days after delivery.</li>
      </ol>

      <p>6. All matters arising out of or relating to this Contract are governed by and construed in accordance with the internal laws of the Australia.</p>

    </div>
  );


  const closeButtonMessage = <FormattedMessage id="ModalCancelPolicy.close" />;

  return (
    <Modal
      id="ModalTermAndConditionsCovid"
      containerClassName={containerClassName}
      isOpen={showModal}
      disableCloseBtn={false}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
      forceClose={false}
    >
      {content}
    </Modal>
  );
}

ModalTermAndConditionsCovid.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,

};

ModalTermAndConditionsCovid.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,

  onManageDisableScrolling: func.isRequired,
  onClose: func.isRequired,
  showModal: bool.isRequired
};

ModalTermAndConditionsCovid.displayName = 'ModalTermAndConditionsCovid';
