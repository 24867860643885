import React from 'react';
import classNames from 'classnames';
import css from './MobileAppBanner.css';
import IconClose from '../IconClose/IconClose';
import { ExternalLink } from '..';

function MobileAppBanner({ rootClassName, className, showBanner, closeHourleyBanner }) {
  if (!showBanner || typeof window === 'undefined') return null;

  const classes = classNames(rootClassName || css.root, className);

  return showBanner ? (
    <div className={classes} id="hourlyBanner">
      <div className={css.contentWrapper}>
        <AppIcon className={css.icon} />
        <div>
          <div className={css.title}>Hurray! The app is here.</div>
          <div className={css.title}>Download now</div>
          <ExternalLink href="https://apps.apple.com/us/app/id1500770324">
            <DownLoadInAppStoreIcon className={css.appleStoreLink} />
          </ExternalLink>
        </div>
        <div className={css.closeIcon} onClick={closeHourleyBanner}>
          <IconClose />
        </div>
      </div>
    </div>
  ) : null;
}

const AppIcon = props => (
  <svg viewBox="0 0 110 110" {...props}>
    <defs>
      <clipPath id="prefix__a">
        <path data-name="Clip 2" d="M0 0h84.75v42.624H0z" fill="none" />
      </clipPath>
    </defs>
    <g data-name="Group 1718">
      <g data-name="Group 1709" transform="translate(-905 -20)">
        <rect
          data-name="Rectangle 140"
          width={110}
          height={110}
          rx={20}
          transform="translate(905 20)"
          fill="#0aa3ad"
        />
        <g data-name="Group 3">
          <path data-name="Clip 2" d="M917.623 50.929h84.75v42.624h-84.75z" fill="none" />
          <g data-name="Group 3" clipPath="url(#prefix__a)" transform="translate(917.623 50.929)">
            <path
              data-name="Fill 1"
              d="M81.481 21.653c-3.8-5.575-9.726-8.879-18.1-10.1-1.378-.2-2.115-.357-2.324-.652A40.1 40.1 0 0057.4 6.406 19.082 19.082 0 0045.248.436 60.355 60.355 0 0032.044.3c-9.938 1.085-15.729 5.357-17.757 7.144a33.083 33.083 0 00-8.056 9.874 35.238 35.238 0 00-1.788 3.965c-.224.565-.421 1.065-.6 1.424l-.156.12a7.859 7.859 0 00-2.451 2.83C.3 27.515.079 31.322.033 32.445c-.133 3.163.035 6.329 2.087 8.066l.148.126.168.1a16.729 16.729 0 008 1.888c.6 0 1.23-.029 1.881-.094l1.917-.191a12.177 12.177 0 01-1.164-4.544l-1.215.121a13.234 13.234 0 01-6.806-1.049c-.126-.269-.486-1.307-.367-4.234a17.957 17.957 0 01.713-4.89A3.378 3.378 0 016.523 26.5a4.361 4.361 0 001.331-1.433 19.527 19.527 0 00.918-2.1 31.187 31.187 0 011.555-3.462 28.838 28.838 0 017.037-8.592c1.7-1.493 6.57-5.069 15.185-6.01a55.727 55.727 0 0112.178.136A14.548 14.548 0 0154 9.572a35.675 35.675 0 013.25 4c1.4 1.976 3.55 2.29 5.45 2.568 7.117 1.039 11.862 3.619 14.934 8.122a12.826 12.826 0 012.448 7.795 4.647 4.647 0 01-.9 2.726c-.78.88-2.207 1.148-3.739 1.406h-8.517c.025.312.039.626.039.941a12.39 12.39 0 01-.561 3.7h9.435l.191-.032c2.112-.354 4.742-.794 6.644-2.939a8.879 8.879 0 002.054-5.527 17.45 17.45 0 00-3.246-10.674"
              fill="#fff"
            />
          </g>
        </g>
        <path
          data-name="Fill 4"
          d="M976.517 78.639a12.454 12.454 0 00-11.757-.228 20.193 20.193 0 00-6.026 5.149l-.048.057.047.058c2.887 3.509 2.887 3.509 2.927 3.511h.045l.028-.034c2.372-2.858 5.21-5.907 8.881-5.907a6.812 6.812 0 11-2.521 13.144c-3.292-1.3-5.774-4.068-7.842-6.555a49.968 49.968 0 00-7.894-8.045c-.358-.275-.735-.527-1.1-.771l-.312-.21a11.163 11.163 0 10-12.204 18.682 12.5 12.5 0 0011.631.291 19.461 19.461 0 006.152-5.213l.048-.057-.047-.057c-2.888-3.508-2.888-3.508-2.928-3.51h-.045l-.028.034c-2.372 2.858-5.21 5.907-8.882 5.907a6.812 6.812 0 112.521-13.146c2.948 1.168 5.262 3.543 7.137 5.712q.6.691 1.191 1.391a60.776 60.776 0 005.544 5.943l.071.064a18.566 18.566 0 003.2 2.473 11.163 11.163 0 1012.211-18.683"
          fill="#ffcd05"
        />
      </g>
    </g>
  </svg>
);

const DownLoadInAppStoreIcon = props => (
  <svg width={119.664} height={40} viewBox="0 0 119.664 40" fill="none" {...props}>
    <g data-name="Group 1719">
      <g data-name="Group 1708">
        <g data-name="Group 1705">
          <g data-name="Group 1700">
            <g data-name="Group 1699">
              <path
                data-name="Path 306"
                d="M110.135 0H8.435c-.306 0-.61.008-.919.013a13.155 13.155 0 00-2 .176 6.72 6.72 0 00-1.9.627A6.444 6.444 0 002 2 6.285 6.285 0 00.819 3.618a6.625 6.625 0 00-.625 1.9 13.078 13.078 0 00-.179 2C.006 7.83 0 8.138 0 8.444v23.115c0 .31.006.611.015.921a13.056 13.056 0 00.179 2 6.607 6.607 0 00.625 1.905A6.233 6.233 0 002 38a6.3 6.3 0 001.616 1.18 6.7 6.7 0 001.9.63 13.285 13.285 0 002 .177c.309.007.613.011.919.011H111.219c.3 0 .617 0 .922-.011a13.12 13.12 0 002-.177 6.781 6.781 0 001.908-.63 6.312 6.312 0 001.617-1.18 6.41 6.41 0 001.182-1.614 6.626 6.626 0 00.619-1.905 13.452 13.452 0 00.185-2v-.921c.008-.364.008-.725.008-1.094V9.536c0-.366 0-.729-.008-1.092v-.921a13.477 13.477 0 00-.185-2 6.643 6.643 0 00-.619-1.9 6.474 6.474 0 00-2.8-2.8 6.807 6.807 0 00-1.908-.627 12.993 12.993 0 00-2-.176c-.305 0-.618-.011-.922-.013h-1.084z"
                fill="#a2a2a2"
              />
              <path
                data-name="Path 307"
                d="M8.445 39.125c-.3 0-.6 0-.9-.011a12.619 12.619 0 01-1.87-.163 5.865 5.865 0 01-1.66-.551 5.374 5.374 0 01-1.4-1.016A5.329 5.329 0 011.6 35.99a5.752 5.752 0 01-.543-1.657 12.388 12.388 0 01-.166-1.875 76.305 76.305 0 01-.015-.913V8.444s.009-.691.015-.894a12.35 12.35 0 01.165-1.872A5.725 5.725 0 011.6 4.016a5.338 5.338 0 011.015-1.4A5.568 5.568 0 014.014 1.6a5.818 5.818 0 011.653-.544A12.65 12.65 0 017.543.887l.9-.012h102.771l.913.013a12.359 12.359 0 011.858.162 5.952 5.952 0 011.671.548 5.593 5.593 0 012.415 2.42 5.738 5.738 0 01.535 1.649 12.82 12.82 0 01.174 1.887v.89c.008.375.008.732.008 1.092v20.929c0 .363 0 .718-.008 1.075v.93a12.558 12.558 0 01-.171 1.853 5.721 5.721 0 01-.54 1.67 5.475 5.475 0 01-1.015 1.386 5.388 5.388 0 01-1.4 1.022 5.832 5.832 0 01-1.668.55 12.46 12.46 0 01-1.869.163c-.293.007-.6.011-.9.011h-1.084z"
              />
            </g>
          </g>
          <g data-name="Group 1704">
            <g data-name="Group 1702">
              <g data-name="Group 1701" fill="#fff">
                <path
                  data-name="Path 308"
                  d="M24.769 20.3a4.948 4.948 0 012.356-4.152 5.063 5.063 0 00-3.991-2.158c-1.679-.175-3.307 1.01-4.163 1.01-.872 0-2.189-.988-3.608-.958a5.314 5.314 0 00-4.473 2.727c-1.934 3.349-.491 8.27 1.361 10.977.927 1.325 2.01 2.805 3.428 2.753 1.387-.058 1.9-.885 3.579-.885s2.145.885 3.591.852c1.489-.025 2.426-1.332 3.321-2.67a10.956 10.956 0 001.518-3.096 4.781 4.781 0 01-2.919-4.4z"
                />
                <path
                  data-name="Path 309"
                  d="M22.037 12.211a4.874 4.874 0 001.115-3.491 4.959 4.959 0 00-3.208 1.66 4.638 4.638 0 00-1.144 3.361 4.1 4.1 0 003.237-1.53z"
                />
              </g>
            </g>
            <g data-name="Group 1703" fill="#fff">
              <path
                data-name="Path 310"
                d="M42.3 27.14h-4.731l-1.137 3.36h-2.005l4.484-12.418h2.083L45.477 30.5h-2.039zm-4.243-1.549h3.752l-1.85-5.447h-.049z"
              />
              <path
                data-name="Path 311"
                d="M55.16 25.97c0 2.813-1.506 4.621-3.779 4.621a3.068 3.068 0 01-2.848-1.584h-.043v4.484h-1.859V21.442h1.8v1.506h.034a3.214 3.214 0 012.883-1.6c2.297 0 3.812 1.816 3.812 4.622zm-1.91 0c0-1.833-.948-3.038-2.393-3.038-1.42 0-2.375 1.23-2.375 3.038 0 1.824.955 3.046 2.375 3.046 1.443 0 2.393-1.197 2.393-3.046z"
              />
              <path
                data-name="Path 312"
                d="M65.125 25.97c0 2.813-1.506 4.621-3.779 4.621a3.068 3.068 0 01-2.846-1.584h-.043v4.484H56.6V21.442h1.8v1.506h.034a3.213 3.213 0 012.883-1.6c2.293 0 3.808 1.816 3.808 4.622zm-1.911 0c0-1.833-.947-3.038-2.392-3.038-1.42 0-2.375 1.23-2.375 3.038 0 1.824.955 3.046 2.375 3.046 1.445 0 2.392-1.197 2.392-3.046z"
              />
              <path
                data-name="Path 313"
                d="M71.71 27.036c.138 1.232 1.334 2.04 2.969 2.04 1.567 0 2.694-.808 2.694-1.919 0-.964-.68-1.541-2.289-1.936l-1.61-.388c-2.28-.551-3.339-1.617-3.339-3.348 0-2.142 1.867-3.614 4.519-3.614 2.624 0 4.423 1.472 4.483 3.614h-1.876c-.112-1.239-1.136-1.987-2.634-1.987s-2.521.757-2.521 1.858c0 .878.654 1.395 2.255 1.79l1.368.336c2.548.6 3.606 1.626 3.606 3.443 0 2.323-1.85 3.778-4.793 3.778-2.754 0-4.614-1.421-4.734-3.667z"
              />
              <path
                data-name="Path 314"
                d="M83.346 19.3v2.142h1.722v1.472h-1.722v4.991c0 .776.345 1.137 1.1 1.137a5.7 5.7 0 00.613-.042v1.463a5.083 5.083 0 01-1.032.086c-1.833 0-2.548-.689-2.548-2.444v-5.19h-1.316v-1.473h1.316V19.3z"
              />
              <path
                data-name="Path 315"
                d="M86.065 25.97c0-2.849 1.678-4.639 4.294-4.639s4.295 1.79 4.295 4.639-1.661 4.638-4.295 4.638-4.294-1.782-4.294-4.638zm6.695 0c0-1.954-.9-3.108-2.4-3.108s-2.4 1.162-2.4 3.108c0 1.962.894 3.106 2.4 3.106s2.4-1.144 2.4-3.106z"
              />
              <path
                data-name="Path 316"
                d="M96.186 21.442h1.773v1.541H98a2.16 2.16 0 012.178-1.635 2.884 2.884 0 01.637.069v1.738a2.62 2.62 0 00-.835-.112 1.873 1.873 0 00-1.937 2.083V30.5h-1.857z"
              />
              <path
                data-name="Path 317"
                d="M109.384 27.837c-.25 1.643-1.85 2.771-3.9 2.771-2.634 0-4.269-1.764-4.269-4.595s1.644-4.682 4.191-4.682c2.505 0 4.08 1.721 4.08 4.466v.637h-6.395v.112a2.358 2.358 0 002.436 2.564 2.049 2.049 0 002.091-1.273zm-6.282-2.7h4.526a2.177 2.177 0 00-2.22-2.3 2.293 2.293 0 00-2.308 2.298z"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 1707">
          <g data-name="Group 1706" fill="#fff">
            <path
              data-name="Path 318"
              d="M37.826 8.731a2.64 2.64 0 012.808 2.969c0 1.906-1.03 3-2.808 3h-2.155V8.731zM36.6 13.854h1.125a1.875 1.875 0 001.967-2.146 1.881 1.881 0 00-1.967-2.134H36.6z"
            />
            <path
              data-name="Path 319"
              d="M41.681 12.444a2.133 2.133 0 114.247 0 2.134 2.134 0 11-4.247 0zm3.333 0c0-.976-.439-1.547-1.208-1.547s-1.207.571-1.207 1.547.434 1.551 1.207 1.551 1.208-.571 1.208-1.551z"
            />
            <path
              data-name="Path 320"
              d="M51.573 14.7h-.922l-.93-3.317h-.071l-.926 3.317h-.913l-1.242-4.5h.9l.806 3.436h.067l.926-3.436h.852l.926 3.436h.07l.8-3.436h.889z"
            />
            <path
              data-name="Path 321"
              d="M53.854 10.195h.855v.715h.066a1.349 1.349 0 011.344-.8 1.465 1.465 0 011.559 1.675V14.7h-.889v-2.694c0-.724-.314-1.084-.972-1.084a1.033 1.033 0 00-1.075 1.141V14.7h-.888z"
            />
            <path data-name="Path 322" d="M59.094 8.437h.888V14.7h-.888z" />
            <path
              data-name="Path 323"
              d="M61.218 12.444a2.133 2.133 0 114.247 0 2.134 2.134 0 11-4.247 0zm3.333 0c0-.976-.439-1.547-1.208-1.547s-1.207.571-1.207 1.547.434 1.551 1.207 1.551 1.208-.571 1.208-1.551z"
            />
            <path
              data-name="Path 324"
              d="M66.4 13.424c0-.81.6-1.278 1.675-1.344l1.219-.07v-.389c0-.475-.314-.744-.921-.744-.5 0-.84.182-.939.5h-.86c.091-.773.818-1.269 1.84-1.269 1.128 0 1.765.562 1.765 1.513V14.7h-.855v-.633h-.071a1.513 1.513 0 01-1.352.707 1.36 1.36 0 01-1.501-1.35zm2.894-.384v-.377l-1.1.07c-.62.042-.9.253-.9.65s.352.641.835.641a1.061 1.061 0 001.166-.984z"
            />
            <path
              data-name="Path 325"
              d="M71.348 12.444c0-1.423.732-2.324 1.869-2.324a1.484 1.484 0 011.381.79h.067V8.437h.888V14.7H74.7v-.712h-.071a1.561 1.561 0 01-1.414.786c-1.143-.002-1.867-.903-1.867-2.33zm.918 0c0 .955.45 1.53 1.2 1.53s1.212-.583 1.212-1.526-.468-1.53-1.212-1.53-1.2.582-1.2 1.526z"
            />
            <path
              data-name="Path 326"
              d="M79.23 12.444a2.133 2.133 0 114.247 0 2.134 2.134 0 11-4.247 0zm3.333 0c0-.976-.438-1.547-1.208-1.547s-1.207.571-1.207 1.547.435 1.551 1.207 1.551 1.208-.571 1.208-1.551z"
            />
            <path
              data-name="Path 327"
              d="M84.669 10.195h.856v.715h.066a1.349 1.349 0 011.344-.8 1.465 1.465 0 011.559 1.675V14.7h-.889v-2.694c0-.724-.314-1.084-.972-1.084a1.033 1.033 0 00-1.075 1.141V14.7h-.889z"
            />
            <path
              data-name="Path 328"
              d="M93.515 9.074v1.141h.976v.749h-.976v2.315c0 .472.194.679.637.679a3.009 3.009 0 00.339-.021v.74a2.862 2.862 0 01-.484.046c-.988 0-1.381-.348-1.381-1.216v-2.543h-.715v-.749h.715V9.074z"
            />
            <path
              data-name="Path 329"
              d="M95.705 8.437h.88v2.481h.071a1.386 1.386 0 011.373-.806 1.484 1.484 0 011.551 1.679V14.7h-.89v-2.69c0-.719-.335-1.084-.963-1.084a1.053 1.053 0 00-1.134 1.142V14.7h-.888z"
            />
            <path
              data-name="Path 330"
              d="M104.761 13.482a1.828 1.828 0 01-1.951 1.3 2.046 2.046 0 01-2.08-2.325 2.077 2.077 0 012.076-2.352c1.253 0 2.009.856 2.009 2.27v.31h-3.18v.05a1.191 1.191 0 001.2 1.29 1.081 1.081 0 001.072-.546zm-3.126-1.451h2.275a1.088 1.088 0 00-1.109-1.167 1.152 1.152 0 00-1.166 1.167z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MobileAppBanner;
