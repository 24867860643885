import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink, IconReviewStar } from '../../components';

import css from './SectionLocations.css';

import dummy1 from '../../assets/landingpage/dummy1.jpeg';
import dummy2 from '../../assets/landingpage/dummy2.jpeg';
import dummy3 from '../../assets/landingpage/dummy3.jpeg';
import authorAvatar1 from '../../assets/landingpage/authorAvatar1.jpeg';
import authorAvatar2 from '../../assets/landingpage/authorAvatar2.jpeg';
import authorAvatar3 from '../../assets/landingpage/authorAvatar3.jpeg';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, authorAvatar, slug, id, authorName, price) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <ExternalLink href={`https://${DOMAIN_URL}/l/${slug}/${id}`} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
        <div className={css.nameLogo}>
          <img className={css.nameLogoImg} src={authorAvatar} />
        </div>
        <div className={css.ownerUsername}>{authorName}</div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
      <div className={css.extraInfo}>
        ${price}/day
      </div>
      <div className={css.ratingBox}>
        <IconReviewStar isFilled={true} className={css.star}/>
        <IconReviewStar isFilled={true} className={css.star}/>
        <IconReviewStar isFilled={true} className={css.star}/>
        <IconReviewStar isFilled={true} className={css.star}/>
        <IconReviewStar isFilled={true} className={css.star}/>
        {/*<div className={css.numberRatings}>132</div>*/}
      </div>
    </ExternalLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className, viewport } = props;

  const classes = classNames(rootClassName || css.root, className);
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id={isMobileLayout ? "SectionLocations.titleMobile" : "SectionLocations.title"} values={{newline: (<br />)}}/>
      </div>
      <div className={css.locations}>
        {locationLink(
          'LC Toyota Axio 2015',
          dummy3,
          authorAvatar3,
          'lc-toyota-axio-2015',
          '5cab00de-7f3a-471a-8ea0-bd87d800c656',
          'Leong Chye',
          60
        )}
        {locationLink(
          'BMW 5-Series 520i F10 Midnight Grey For Rent',
          dummy1,
          authorAvatar1,
          'bmw-5-series-520i-f10-midnight-grey-for-rent',
          '5c9c861e-babf-46c1-9d7c-30023581eae4',
          'Francois',
          150
        )}
        {locationLink(
          'Honda shuttle',
          dummy2,
          authorAvatar2,
          'honda-shuttle',
          '5c8db144-f234-4b9a-85a4-f287a4bb199f',
          'Arvin',
          80
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
