import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import HIWHeroSection from './HIWHero';
import HIWHowDoesItWorkSection from './HIWHowDoesItWork';
import HIWInsuranceSection from './HIWInsurance';
import HIWReadyToGoSection from './ReadyToGo';
import HIWListSection from './HIWList';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { initiateListACarEventFromTab } from '../../util/gtm/gtmHelpers';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import {
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
  LISTACAR_CREATE_NEW_BUTTON_ID_2,
} from '../../util/gtm/gtmConstants';
import css from './HowItWorkPage.css';

const HowItWorkPage = props => {
  const {
    isAuthenticated,
    onManageDisableScrolling,
    currentUser,
    onLoginOrSignupClick,
  } = props;
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const onListYourCar = (buttonId) => {
    initiateListACarEventFromTab({
      props,
      eventButtonParam:
        [EVENT_LISTACAR_CLICKED_LIST_A_CAR, buttonId],
    });
  }

  // prettier-ignore
  return (
    <StaticPage
      title="Find out how Drive Mate car sharing Australia works"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HowItWorkPage',
        description: 'Car sharing made accessible anytime, anywhere. Explore hundreds of cars nearby or offer up your car & watch it make bank with Drive Mate car sharing Australia',
        name: 'How Drive mate works',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <HIWHeroSection
            isAuthenticated={isAuthenticated}
            onLoginOrSignupClick={onLoginOrSignupClick}
          />
          <HIWHowDoesItWorkSection onManageDisableScrolling={onManageDisableScrolling} />
          <HIWListSection
            onListYourCar={onListYourCar}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
            currentUser={currentUser}
            onLoginOrSignupClick={onLoginOrSignupClick}
          />

          <HIWInsuranceSection />
          <HIWReadyToGoSection
            isAuthenticated={isAuthenticated}
            onListYourCar={onListYourCar}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID_2}
            currentUser={currentUser}
            onLoginOrSignupClick={onLoginOrSignupClick}
            />
          {/* <InsuranceDisclaimer/> */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser
  };
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) => dispatch(manageDisableScrolling(componentId, disabled)),
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorkPage);
