import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_MASTERCARD_PROMO,
  LINE_ITEM_UNITS,
} from '../../util/types';

import { TRANSITION_REQUEST_PAYMENT } from '../../util/transaction';
import { calculateTotalPrice, ensurePricing } from '../../util/data';
import { calculateDayOfBooking } from '../../util/dates';
import config from '../../config';
import { get } from 'lodash';

const { Money, UUID } = sdkTypes;
const { guestApplicableCredits } = config.custom;
export const createBookingLineItem = (listing, bookingData) => {
  const {
    credits,
    quantity,
    voucherCode,
    shouldUseMastercardPromoLineItem,
    discount,
  } = bookingData;
  const { price: unitPrice, publicData } = listing.attributes;
  const { pricing } = publicData;
  const ensuredPricing = ensurePricing(pricing);
  const {
    regularDays,
    peakDays,
    peakPrice: rawPeakPrice,
    discount: discountPercentageObject,
  } = ensuredPricing;
  const peakPrice = rawPeakPrice
    ? new Money(rawPeakPrice.amount, rawPeakPrice.currency)
    : new Money(0, config.currency);
  //check for not disable drop off day.
  const daysOfBookings = calculateDayOfBooking(bookingData.bookingStart, bookingData.bookingEnd, {
    regularDays,
    peakDays,
  });
  const { days } = daysOfBookings;
  const { week: discountWeek = 0, month: discountMonth = 0 } = discountPercentageObject;
  const newUnitPrice = calculateTotalPrice(days, unitPrice, peakPrice);
  let discountAmountFromCredits =
    credits && credits > guestApplicableCredits / 100 && unitPrice.amount > guestApplicableCredits
      ? guestApplicableCredits
      : credits * 100 > unitPrice.amount
      ? unitPrice.amount
      : credits
      ? credits * 100
      : 0;
  if (credits < guestApplicableCredits / 100) {
    discountAmountFromCredits = credits;
  }
  const discountAmount =
    !!voucherCode && !!voucherCode.discount
      ? voucherCode.discount
      : discount
      ? discount
      : discountAmountFromCredits;
  const totalPrice = unitPrice.amount * quantity;
  const finalDiscountAmount = !discountAmount
    ? discountAmount
    : totalPrice - discountAmount < 1
    ? discountAmount - 100
    : discountAmount;

  const lineItems = [];

  lineItems.push({
    code: LINE_ITEM_UNITS,
    unitPrice: newUnitPrice,
    lineTotal: newUnitPrice,
    quantity: 1,
  });

  const longtermDiscount =
    quantity >= 30
      ? new Money(parseInt((newUnitPrice.amount * 1 * discountMonth) / 100), config.currency)
      : quantity >= 7
      ? new Money(parseInt((newUnitPrice.amount * 1 * discountWeek) / 100), config.currency)
      : new Money(0, config.currency);

  if (longtermDiscount.amount > 0)
    lineItems.push({
      code: LINE_ITEM_LONG_TERM_DISCOUNT,
      unitPrice: longtermDiscount,
      percentage: -100,
    });

  if (finalDiscountAmount && finalDiscountAmount >= 1) {
    lineItems.push({
      code: LINE_ITEM_CUSTOMER_PROMO,
      unitPrice: new Money(finalDiscountAmount, unitPrice.currency),
      percentage: -100,
    });
  } else if (shouldUseMastercardPromoLineItem) {
    lineItems.push({
      code: LINE_ITEM_MASTERCARD_PROMO,
      unitPrice: new Money(
        Math.min(totalPrice - longtermDiscount.amount, 10000),
        unitPrice.currency
      ),
      percentage: -50,
    });
  }

  return lineItems;
};

export const recreateBookingLineItems = tx => {
  const newTransaction = tx;
  const discountLineItem = tx.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_PROMO
  );
  if (!discountLineItem) {
    return tx;
  }
  const unitLineItem = tx.attributes.lineItems.find(item => item.code === LINE_ITEM_UNITS);

  const unitPrice = unitLineItem.lineTotal;
  const newLineItems = tx.attributes.lineItems.filter(item => {
    return item.code !== LINE_ITEM_UNITS;
  });
  const newUnitPrice = {
    ...unitLineItem,
    lineTotal: new Money(unitPrice.amount + discountLineItem.lineTotal.amount, unitPrice.currency),
  };
  newLineItems.push(newUnitPrice);
  newTransaction.attributes.lineItems = newLineItems;
  return newTransaction;
};

export const calculateRemainingCredit = (listing, bookingData) => {
  const { credits } = bookingData;
  if (!credits) return null;
  const unitPrice = listing.attributes.price;
  const discountAmount =
    credits && credits > guestApplicableCredits / 100 && unitPrice.amount > guestApplicableCredits
      ? guestApplicableCredits / 100
      : credits * 100 > unitPrice.amount
      ? unitPrice.amount / 100
      : credits;
  return credits - discountAmount;
};

const getCommissionConfig = () => {
  return [TRANSITION_REQUEST_PAYMENT];
};

const getCommissionConfigIndex = tripPriceAmount => {
  if (tripPriceAmount >= 6800) {
    return 0;
  } else if (tripPriceAmount >= 6000) {
    return 1;
  } else if (tripPriceAmount >= 5500) {
    return 2;
  } else if (tripPriceAmount >= 5000) {
    return 3;
  } else if (tripPriceAmount >= 4000) {
    return 4;
  } else {
    return 5;
  }
};

export const getCommissionTransition = tripPriceAmount => {
  const commissionConfig = getCommissionConfig();
  const chosenCommisisonIndex = getCommissionConfigIndex(tripPriceAmount);
  return commissionConfig[chosenCommisisonIndex];
};

export const checkCanUseMastercardPromo = currentUser => {
  return false;

  const signedFrom = process.env.REACT_APP_ENV === 'production' ? 1589475600000 : 0; // 15/05/2020;

  // console.log({
  //   createdTime: new Date(currentUser.attributes.createdAt) < signedFrom,
  //   signedFrom: process.env.REACT_APP_ENV,
  //   acceptedRequests: get(currentUser, 'attributes.profile.metadata.intercomUserStat.number_of_booking_requests_accepted_as_guest', 0),
  //   usedPromo: get(currentUser, 'attributes.profile.metadata.hasMastercardPromoBeenUsed'),
  // });

  if (new Date(currentUser.attributes.createdAt) < signedFrom) {
    return false;
  }

  if (
    get(
      currentUser,
      'attributes.profile.metadata.intercomUserStat.number_of_booking_requests_accepted_as_guest',
      0
    ) > 0
  ) {
    return false;
  }

  if (
    get(
      currentUser,
      'attributes.profile.metadata.intercomUserStat.number_of_trips_success_as_guest',
      0
    ) > 0
  ) {
    return false;
  }

  if (get(currentUser, 'attributes.profile.metadata.hasMastercardPromoBeenUsed')) {
    return false;
  }

  return true;
};
