import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  FieldDateInput,
  Form,
  InsurancePlusSign,
  LocationAutocompleteInput,
  NamedLink,
} from '../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import { createTimeSlots } from '../../util/test-data';
import moment from 'moment';

import insuranceLogo from '../../assets/general/insuranceLogo-min.png';
import css from './SectionFindACard.css';

const createAvailableTimeSlots = (dayCount) => {
  const slots = createTimeSlots(new Date(), dayCount);
  return slots;
};

class SectionFindACardComponent extends Component {
  constructor(props) {
    super(props);
    this.searchInput = null;
    this.state = {
      hideReturnDate: false,
      address: ''
    };
    this.updatingDateTime = false;
    this.watchTimeOutUpdatingDateTime = null;
    this.searchButtonClicked = false;
    this.setSearchButtonClicked = this.setSearchButtonClicked.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.watchTimeOutUpdatingDateTime);
  }

  onChange(location) {
    if (location.selectedPlace) {
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  setSearchButtonClicked() {
    this.searchButtonClicked = true;
    this.forceUpdate();
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            form,
            formId,
            formName,
            handleSubmit,
            inProgress,
            titleId,
            subtitleId,
            intl,
            values,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const desktopInputRootClass = css.desktopInputRoot;

          const convertBoundsToParams = (bounds) => {
            return `${bounds.ne.lat}%2C${bounds.ne.lng}%2C${bounds.sw.lat}%2C${bounds.sw.lng}`;
          }

          const searchAddress = values.location && values.location.selectedPlace && values.location.selectedPlace.address && values.isCurrentPlace ? `address=${values.location.selectedPlace.address}&` : '';
          const boundsMap = values.location && values.location.selectedPlace && values.location.selectedPlace.bounds
          const boundsAddress = !!boundsMap ? `bounds=${convertBoundsToParams(boundsMap)}` : 'bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798';
          const startDateRaw = values.startDate && values.startDate.date ? values.startDate.date : null;
          const endDate = values.endDate && values.endDate.date ? moment(values.endDate.date).format("YYYY-MM-DD").toString() : (startDateRaw ? moment(startDateRaw).add(1, "day").format("YYYY-MM-DD").toString() : null);
          const searchDates = startDateRaw && endDate ? `${boundsAddress ? '&' : ''}dates=${moment(startDateRaw).format("YYYY-MM-DD").toString()}%2C${endDate}` : '';

          if (!!values.startDate && !values.endDate) {
            form.change("endDate", { date: new Date(moment(values.startDate.date).add(1, "days")) });
          }

          if (!values.startDate && !!values.endDate) {
            if (moment(values.endDate.date).diff(moment(), "days") <= 0) {
              form.change("startDate", { date: new Date(moment()) });
              form.change("endDate", { date: new Date(moment().add(1, "days")) });
            } else {
              form.change("startDate", { date: new Date(moment(values.endDate.date).subtract(1, "days")) });
            }
          }

          if (!this.updatingDateTime && !!values.startDate && !!values.endDate && moment(values.startDate.date).diff(moment(values.endDate.date), "days") >= 0) {
            this.updatingDateTime = true;
            if (moment(values.endDate.date).diff(moment(), "days") <= 0) {
              form.change("startDate", { date: new Date(moment()) });
              form.change("endDate", { date: new Date(moment().add(1, "days")) });
            } else {
              form.change("endDate", { date: new Date(moment(values.startDate.date).add(1, "days")) });
            }
            this.watchTimeOutUpdatingDateTime = setTimeout(() => {
              this.updatingDateTime = false
            }, 500);
          }

          const canApply = !! (values && values.startDate && values.endDate && values.location);
          const showDateReminderText = !! ( values && values.location && !canApply );
          const showLocationReminderText = this.searchButtonClicked && (!values || !values.location);

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.heroSearchBox}>
                <h1 className={css.heroMainTitle}>
                  <FormattedMessage id={titleId}/>
                </h1>
                <div className={css.heroSubtitle}>
                  <FormattedMessage id={subtitleId} values={{ plus: <InsurancePlusSign/> }}/>
                </div>
                <div className={css.fieldsContainerSolid}>
                  <div className={css.fieldsContainer}>
                    <div className={css.fieldWrapper}>
                      <div className={css.labelField}>
                        <FormattedMessage id="SectionFindACard.placeField" />
                      </div>
                      <Field
                        name="location"
                        format={v => v}
                        render={({ input, meta }) => {
                          const { onChange, ...restInput } = input;

                          const searchOnChange = value => {
                            const newValue = { ...value }
                            if (!!newValue && !!newValue.selectedPlace && !newValue.selectedPlace.address) {
                              newValue.search = "Current place";
                              newValue.isCurrentPlace = true;
                              newValue.selectedPlace = {
                                ...(newValue.selectedPlace ? newValue.selectedPlace : {}),
                                address: "Current place"
                              }
                            }
                            onChange(newValue);
                            form.change("location", newValue);
                            this.onChange(newValue);
                            if (document.querySelector("#searchBoxLocationSearch")) {
                              document.querySelector("#searchBoxLocationSearch").focus();
                            }
                          };

                          const searchInput = { ...restInput, onChange: searchOnChange };
                          return (
                            <LocationAutocompleteInput
                              idInput={"searchBoxLocationSearch"}
                              className={desktopInputRootClass}
                              iconClassName={css.desktopIcon}
                              inputClassName={css.desktopInput}
                              predictionsAttributionClassName={null}
                              placeholder={intl.formatMessage({ id: 'SectionFindACard.searchPlaceholder' })}
                              inputRef={node => {
                                this.searchInput = node;
                              }}
                              input={searchInput}
                              meta={meta}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className={css.dateTimeFields}>
                      <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                        <FormattedMessage id="SectionFindACard.startDate" />
                      </div>
                      <FieldDateInput
                        className={classNames(css.fieldDateTimeInput, css.fieldDateStartTimeInput)}
                        name='startDate'
                        useMobileMargins={false}
                        id="startBookingDate"
                        placeholderText={moment().format('DD/MM/YYYY')}
                        format={v => v}
                        timeSlots={createAvailableTimeSlots(90)}
                        // validate={composeValidators(required('Required'), bookingDateRequired('Date is not valid'))}
                      />
                    </div>
                    <div className={css.dateTimeFields}>
                      <div className={classNames(css.fieldDateTimeLabel, css.labelField)}>
                        <FormattedMessage id="SectionFindACard.endDate" />
                      </div>
                      <FieldDateInput
                        className={classNames(css.fieldDateTimeInput, this.state.hideReturnDate ? css.hideDate : css.null)}
                        name='endDate'
                        useMobileMargins={false}
                        id="endBookingDate"
                        // disabled={!startDateRaw}
                        placeholderText={moment().add(1, "day").format('DD/MM/YYYY')}
                        format={v => v}
                        timeSlots={createAvailableTimeSlots(90)}
                        // validate={composeValidators(required('Required'), bookingDateRequired('Date is not valid'))}
                      />
                    </div>
                    { canApply ? (
                        <NamedLink
                          name="SearchPage"
                          to={{
                            search:
                              `?${searchAddress}${boundsAddress}${searchDates}`,
                          }}
                          className={css.heroButton}
                        >
                          <FormattedMessage id="SectionHeroSearchBox.browseButton" />
                        </NamedLink>
                      ) : (
                        <a
                          className={css.heroButton}
                          onClick={this.setSearchButtonClicked}
                        >
                          <FormattedMessage id="SectionHeroSearchBox.browseButton" />
                        </a>
                      )
                    }
                  </div>
                </div>
                {
                  showLocationReminderText && (
                    <div className={css.submitWarning}><FormattedMessage id="enter_location_first" /></div>
                  )
                }
                {
                  showDateReminderText && (
                    <div className={css.submitWarning}><FormattedMessage id="enter_date_first" /></div>
                  )
                }
                {/* <div className={classNames(css.buttonsWrapper, css.buttonsWrapperTemp)}> */}
                <div className={css.insuranceParrner}>
                  <div className={css.insuranceText}>
                    <FormattedMessage id="SectionFindACard.insurancePartner" />
                  </div>
                  <NamedLink name="TrustAndSafetyPage" className={css.insuranceLogo}>
                    <img className={css.insuranceLogoImg} src={insuranceLogo} />
                  </NamedLink>
                </div>
              </div>
            </Form >
          )
        }}
      />
    )
  }
}

SectionFindACardComponent.defaultProps = { inProgress: false }

const { bool } = PropTypes;

SectionFindACardComponent.propTypes = {
  inProgress: bool,
  intl: intlShape.isRequired
}

const  SectionFindACard = compose(injectIntl)(SectionFindACardComponent)
SectionFindACard.displayName = 'SectionFindACard';

export default SectionFindACard;
