import React from 'react';
import css from './ListingPageGo.css';
import { PropertyList } from '../../../components';
import config from '../../../config';
import { FormattedMessage } from 'react-intl';

const SectionRulesMaybe = props => {
  const { listing, field, title, titleId, directValue } = props;

  const fieldValues = (listing.attributes.publicData || {})[field];
  const isArray = Array.isArray(fieldValues) && fieldValues.length > 0;
  const isString = typeof fieldValues === 'string';
  const isNumber = !isNaN(fieldValues);
  const configValues = config.custom[field];

  const list = isArray
    ? configValues.filter(item => fieldValues.find(rule => item.key === rule))
    : [];
  return (
    <div className={css.section}>
      <div className={css.sectionTitle}>{titleId ? <FormattedMessage id={titleId} /> : title}</div>
      {directValue ? <div className={css.rule}>{directValue}</div>
       : isArray ? (
        <PropertyList list={list} />
      ) : isString && isNumber ? (
        <div className={css.rule}>{`${fieldValues} km`}</div>
      ) : isString && !isNumber ? (
        <div className={css.rule}>{`${fieldValues}`}</div>
      ) : (
        <div className={css.rule}>
          <FormattedMessage id="SectionRulesMaybe.noKeyRules" />
        </div>
      )}
    </div>
  );
};

export default SectionRulesMaybe;
