import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import isEqual from 'lodash/isEqual';
import css from './EditListingPricingPanel.css';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const { Money } = sdkTypes;

const EditListingPricingPanel = memo(
  props => {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      submitButtonId,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { price, publicData } = currentListing.attributes;
    const {
      pricing = {},
      longTermRental = false,
      delivery = false,
      isPeakPriceEnabled = false,
      hourlyBooking = false,
      longTermAdminVerified = false,
      longTermPrice,
      isPublicHolidayPeakDays = false,
    } = publicData;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPricingPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
    );
    const ensurePricing = useMemo(() => {
      const { peakPrice, discount = {}, hourlyRegularPrice, hourlyPeakPrice, ...rest } = pricing;
      console.log('I Am PRICING >>', pricing);
      
      return cloneDeep({
        regularDays: ['mon', 'tue', 'wed', 'thu', 'fri'],
        peakDays: ['sun', 'sat'],
        isPeakPriceEnabled,
        ...rest,
        peakPrice:
          peakPrice && peakPrice.amount > 0
            ? new Money(peakPrice.amount, peakPrice.currency)
            : null,
        ...discount,
        hourlyRegularPrice:
        hourlyRegularPrice && hourlyRegularPrice.amount > 0
            ? new Money(hourlyRegularPrice.amount, hourlyRegularPrice.currency)
            : null,
        ...discount,
        hourlyPeakPrice:
        hourlyPeakPrice && hourlyPeakPrice.amount > 0
            ? new Money(hourlyPeakPrice.amount, hourlyPeakPrice.currency)
            : null,
        ...discount,
      });
    }, [pricing]);

    const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
    const longTermPriceMaybe = longTermPrice
      ? { longTermPrice: new Money(longTermPrice.amount, longTermPrice.currency) }
      : {};


    const form = priceCurrencyValid ? (
      <EditListingPricingForm
        className={css.form}
        initialValues={{
          price,
          ...ensurePricing,
          longTermRental,
          delivery,
          isPeakPriceEnabled,
          hourlyBooking,
          longTermAdminVerified,
          isPublicHolidayPeakDays,
          ...longTermPriceMaybe,
        }}
        onSubmit={values => {
          let {
            price,
            regularDays,
            peakDays,
            peakPrice,
            hourlyPeakPrice,
            hourlyRegularPrice,
            week = 0,
            month = 0,
            longTermRental = false,
            longTermPrice = null,
            hourlyBooking,
            isPeakPriceEnabled = values.peakPrice.amount &&
              values.isPublicHolidayPeakDays &&
              values.regularDays.length > 0
                ? true
                : false,
            isPublicHolidayPeakDays = false,
            delivery = false,
          } = values;
          if (week === null) {
            week = 0;
          }
          if (month === null) {
            month = 0;
          }
          peakPrice = peakPrice ? peakPrice : {};
          if (peakPrice.amount === null) {
            peakPrice = {};
          }
          hourlyPeakPrice = hourlyPeakPrice ? hourlyPeakPrice : {};
          if (hourlyPeakPrice.amount === null) {
            hourlyPeakPrice = {};
          }
          hourlyRegularPrice = hourlyRegularPrice ? hourlyRegularPrice : {};
          if (hourlyRegularPrice.amount === null) {
            hourlyRegularPrice = {};
          }

          if (isPublicHolidayPeakDays === null) {
            isPublicHolidayPeakDays = false;
          }

          peakDays = peakDays.filter(day => day !== null);
          regularDays = regularDays.filter(day => day !== null);

          const data = {
            price,
            publicData: {
              isPublicHolidayPeakDays,
              isPeakPriceEnabled,
              hourlyBooking,
              longTermRental,
              delivery,
              isPublicHolidayPeakDays: !isPeakPriceEnabled ? false : isPublicHolidayPeakDays,
              longTermPrice: longTermPrice
                ? pick(longTermPrice, ['amount', 'currency'])
                : { amount: 0, currency: config.currency },
              longTermPriceAmount: longTermPrice ? longTermPrice.amount : 0,
              pricing: {
                regularDays: !isPeakPriceEnabled
                  ? ['tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'mon']
                  : regularDays,
                peakDays: !isPeakPriceEnabled ? [] : peakDays,
                peakPrice: peakPrice && peakPrice.amount && peakPrice.currency
                  ? {
                      amount: peakPrice.amount,
                      currency: peakPrice.currency,
                    }
                  : {
                      amount: 0,
                      currency: config.currency,
                    },
                discount: {
                  week,
                  month,
                },
                hourlyPeakPrice: hourlyBooking && isPeakPriceEnabled && hourlyPeakPrice && hourlyPeakPrice.amount && hourlyPeakPrice.currency ? {
                  amount: hourlyPeakPrice.amount,
                  currency: hourlyPeakPrice.currency,
                } : {},
                hourlyRegularPrice: hourlyBooking && hourlyRegularPrice && hourlyRegularPrice.amount && hourlyRegularPrice.currency ? {
                  amount: hourlyRegularPrice.amount,
                  currency: hourlyRegularPrice.currency,
                } : {}
              },
            },
          };

          onSubmit(data);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        submitButtonId={submitButtonId}
        listing={listing}
      />
    ) : (
      <div className={css.priceCurrencyInvalid}>
        <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
      </div>
    );
    const title = <FormattedMessage id="EditListingPricingPanel.panelTitle" />;
    const panelDescription = <FormattedMessage id="EditListingPricingPanel.panelDescription" />;
    return (
      <div className={classes}>
        <div className={css.titleSection}>
          <h1 className={css.title}>{title}</h1>
          <p>{panelDescription}</p>
        </div>
        {form}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['listing', 'updateInProgress', 'panelUpdated']),
      pick(next, ['listing', 'updateInProgress', 'panelUpdated'])
    )
);

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingPricingPanel;
