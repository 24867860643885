// ================ Action types ================ //

export const TAB_PANEL_LOADING = 'app/tabPanel/TAB_PANEL_LOADING';
export const CONNECT_CALENDAR_TAB_STATUS = 'app/tabPanel/FETCHING_CONNECT_CALENDAR_TAB_STATUS';

// ================ Reducer ================ //

const initialState = {
  tabPanelLoading: false,
  connectCalendarTabVisibility: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case TAB_PANEL_LOADING:
      return {
        ...state,
        tabPanelLoading: payload,
      };
    case CONNECT_CALENDAR_TAB_STATUS:
      return {
        ...state,
        connectCalendarTabVisibility: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getConnectCalendarTabStatus = () => async (dispatch, getState, sdk) => {
  const validStats = ['draft', 'published'];

  let page = 1;
  let limit = 1;
  const perPage = 10;
  let maxTries = 10;

  const perPageFetching = async (page, perPage) => {
    try {
      const response = await sdk.ownListings.query({
        page,
        perPage,
      });
      const data = response.data.data;
      const pageLimit = response.data.meta.totalPages;
      return { data, pageLimit };
    } catch (error) {
      return { data: [], pageLimit: 0 };
    }
  };

  const findRecord = data => {
    return data.find(listing => {
      return validStats.includes(listing.attributes.state);
    });
  };

  try {
    dispatch({
        type: TAB_PANEL_LOADING,
        payload: true,
    });
    while (page <= limit && maxTries > 0) {
      const { data, pageLimit } = await perPageFetching(page, perPage);
      page++;
      limit = pageLimit;
      if (findRecord(data)) {
        dispatch({
          type: TAB_PANEL_LOADING,
          payload: false,
        });
        dispatch({
          type: CONNECT_CALENDAR_TAB_STATUS,
          payload: true,
        });
        return true;
      }
      maxTries--;
    }
    dispatch({
      type: TAB_PANEL_LOADING,
      payload: false,
    });
    return false;
  } catch (err) {
    return false;
  }
};
