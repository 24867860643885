import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCamera.css';

const IconCamera = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px"
      width="512px" height="512px"
      viewBox="0 0 512 512"
    >
      <g>
        <circle cx="255.811" cy="285.309" r="75.217"/>
        <path d="M477,137H352.718L349,108c0-16.568-13.432-30-30-30H191c-16.568,0-30,13.432-30,30l-3.718,29H34
        c-11.046,0-20,8.454-20,19.5v258c0,11.046,8.954,20.5,20,20.5h443c11.046,0,20-9.454,20-20.5v-258C497,145.454,488.046,137,477,137
        z M255.595,408.562c-67.928,0-122.994-55.066-122.994-122.993c0-67.928,55.066-122.994,122.994-122.994
        c67.928,0,122.994,55.066,122.994,122.994C378.589,353.495,323.523,408.562,255.595,408.562z M474,190H369v-31h105V190z"/>
      </g>
    </svg>

  );
};

const { string } = PropTypes;

IconCamera.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCamera.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCamera;
