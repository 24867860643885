import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './../../components/TripPanel/TripPanel.css';
import { initiateOrder } from './TripDonatePage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import TripDonationPanelForm from '../../forms/TripDonationPanelForm/TripDonationPanelForm';
import { useModalState } from '../../util/useHook/useModalState';


export const TripDonatePageComponent = props => {

   const  {
    history,
    location,
    transaction,
    transactionId,
    currentUser,
    intl,
    transactInModal = false,
    handleGoBack
   } = props;

   const [ isOpenModal, openModal, closeModal ] = useModalState(false);
   const [ isDonationPaymentSuccessful, setIsDonationPaymentSuccessful ] = useState(false);


   const dispatch = useDispatch();
   const onHandlePayment = (params) => dispatch(initiateOrder(params));
   const onManageDisableScrolling = (componentId, disableScrolling) => dispatch(
     manageDisableScrolling(componentId, disableScrolling));


   const initiateOrderInProgress = useSelector(state => state.TripDonatePage.initiateOrderInProgress);
   const initiateOrderError = useSelector(state => state.TripDonatePage.initiateOrderError);
   const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

   const hasDefaultPaymentMethod = !!(
     ensureStripeCustomer(currentUser && currentUser.stripeCustomer).attributes.stripeCustomerId &&
     ensurePaymentMethodCard(currentUser && currentUser.stripeCustomer.defaultPaymentMethod).id
   );

   const onSubmit = values => {
    const paramsOrder = {
      deliveryAddress: values.deliveryAddress,
      name: values.name,
      totalAmount: values.totalAmount,
    };
    const selectedPaymentMethod = values.paymentMethod === 'defaultCard' && hasDefaultPaymentMethod
      ? currentUser.stripeCustomer.defaultPaymentMethod.attributes
        .stripePaymentMethodId
      : null;
    return onHandlePayment({
      paramsOrder,
      card: values.card,
      stripe: values.stripe,
      paymentMethod: selectedPaymentMethod,
      replaceCurrentCard: values.replaceCurrentCard
    }).then(transaction => {
      if (transaction) {
        // setOrderNumber(transaction && transaction.id && transaction.id.uuid);
        setIsDonationPaymentSuccessful(true);
      } else {
        setIsDonationPaymentSuccessful(false);
      }
    });
  };


  return (
    <div className={css.donationTransaction}>
      <TripDonationPanelForm
        onOpenTermAndConditions={openModal}
        defaultPaymentMethod={
          hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
        }
        intl={intl}
        currentUser={currentUser}
        initiateOrderError={initiateOrderError}
        inProgress={initiateOrderInProgress}
        onSubmit={onSubmit}
        handleGoBack={handleGoBack}
        isDonationPaymentSuccessful={isDonationPaymentSuccessful}
        transactInModal={transactInModal}
      />
    </div>
  );
};

const { bool, object } = PropTypes;

TripDonatePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const TripDonatePage = compose(
  withViewport,
  withRouter,
  injectIntl
)(TripDonatePageComponent);


export default TripDonatePage;
