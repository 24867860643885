import React from 'react';

const ArrowDown = ({ width = 14, height = 8.25, color = '#fff', className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 8.25"
      className={className}
    >
      <path
        id="round-keyboard_arrow_down-24px"
        d="M8.553,9.364l4.861,4.861,4.861-4.861a1.249,1.249,0,1,1,1.766,1.766l-5.75,5.75a1.248,1.248,0,0,1-1.766,0l-5.75-5.75a1.248,1.248,0,0,1,0-1.766A1.274,1.274,0,0,1,8.553,9.364Z"
        transform="translate(-6.407 -8.997)"
        fill={color}
      />
    </svg>
  );
};

export default ArrowDown;
