/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { memo } from 'react';
import { bool, oneOf, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { LINE_ITEM_CUSTOM_REFUND, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import LineItemLongTermCurrentPayment from './LongTermLineItems/LineItemLongTermCurrentPayment';
import LineItemLongTermNextMonthly from './LongTermLineItems/LineItemLongTermNextMonthly';
import LineItemLongTermMiddleNextMonthly
  from './LongTermLineItems/LineItemLongTermMiddleNextMonthly';
import LineItemLongTermRentalTotal from './LongTermLineItems/LineItemLongTermRentalTotal';
import LineItemLongTermBookingPeriod from './LongTermLineItems/LineItemLongTermBookingPeriod';
import LineItemLongTermPaymentMade from './LongTermLineItems/LineItemLongTermPaymentMade';
import LineItemLongTermBasePrice from './LongTermLineItems/LineItemLongTermBasePrice';
import LineItemLongTermProviderMake from './LongTermLineItems/LineItemLongTermProviderMake';
import LineItemLongTermCancellationFineHost
  from './LongTermLineItems/LineItemLongTermCancellationFineHost';
import LineItemLongTermProviderMakeAfterCancellation
  from './LongTermLineItems/LineItemLongTermProviderMakeAfterCancellation';
import LineItemLongTermRefundCustomer from './LongTermLineItems/LineItemLongTermRefundCustomer';

export const CheckoutBreakdownLongTermComponent = memo(
  props => {
    const {
      rootClassName,
      className,
      intl,
      userRole,
      transaction,
      currentUser,
      unitType,
      timeZone,
      hideBookingPeriod,
      hideTobePaid,
      hideCurrentPayment,
      hidePaymentMade,
      parentTransaction,
      isCheckoutLTM,
    } = props;

    const isCustomer = userRole === 'customer';
    const isProvider = userRole === 'provider';
    const classes = classNames(rootClassName || css.root, className);
    const isCancellationTransaction = get(
      transaction,
      'attributes.metadata.cancelData.cancellationType'
    );
    // const isCancelByProvider = txCancelByProviderLTF(transaction);
    // const isCancelByCustomer = txCancelByCustomerLTF(transaction) ||
    //   txCancelByCustomerLTM(transaction) || txCancelByCustomerLTL(transaction);
    // const LTM_MONTHLY_PRICE = 300000;
    // if (isLongTermRental) {
    //   transaction = changeLTMPrice(transaction, LTM_MONTHLY_PRICE);
    // }
    return (
      <div className={classes}>
        <LineItemLongTermBookingPeriod
          transaction={transaction}
          unitType={unitType}
          timezone={timeZone}
        />

        <LineItemLongTermRentalTotal
          transaction={transaction}
          isCustomer={isCustomer}
          intl={intl}
        />
        {isProvider && (
          <LineItemLongTermBasePrice
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
        )}
        {/* <LineItemUnknownItemsRefundMaybe transaction={transaction} intl={intl} /> */}

        {!hideCurrentPayment && (
          <LineItemLongTermCurrentPayment
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
          />
        )}

        {!hidePaymentMade && (
          <LineItemLongTermPaymentMade
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
            isCancelled={!!isCancellationTransaction}
          />
        )}
        {isCheckoutLTM ? (
          <LineItemLongTermMiddleNextMonthly
            transaction={transaction}
            parentTransaction={parentTransaction}
            isCustomer={isCustomer}
            intl={intl}
          />
        ) : (
          !isCancellationTransaction && (
            <LineItemLongTermNextMonthly
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
          )
        )}
        {isProvider && <LineItemLongTermProviderMake transaction={transaction} intl={intl} />}
        {isCancellationTransaction && (
          <LineItemLongTermCancellationFineHost
            transaction={transaction}
            intl={intl}
            isProvider={isProvider}
          />
        )}
        <LineItemLongTermProviderMakeAfterCancellation
          transaction={transaction}
          intl={intl}
          isProvider={isProvider}
          isCancelled={isCancellationTransaction}
        />
        {isCancellationTransaction && (
          <LineItemLongTermRefundCustomer
            transaction={transaction}
            intl={intl}
            isCustomer={isCustomer}
          />
        )}
        {/* {!hideTobePaid && (
          <LineItemLongTermTobePaid
            currentUser={currentUser}
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
            parentTransaction={parentTransaction}
          />
        )} */}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['userRole', 'transaction', 'currentUser']),
      pick(next, ['userRole', 'transaction', 'currentUser'])
    )
);

CheckoutBreakdownLongTermComponent.defaultProps = {
  rootClassName: null,
  className: null,
  doNotShowTotal: false,
  customRefundType: LINE_ITEM_CUSTOM_REFUND,
  hideUnitPrice: false,
};

CheckoutBreakdownLongTermComponent.propTypes = {
  rootClassName: string,
  className: string,
  transactionMode: bool,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  doNotShowTotal: bool,
  hideUnitPrice: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckoutBreakdownLongTerm = injectIntl(CheckoutBreakdownLongTermComponent);

CheckoutBreakdownLongTerm.displayName = 'CheckoutBreakdownLongTerm';

export default CheckoutBreakdownLongTerm;
