import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import LogoImage from '../../assets/searchPage/icon-logo.svg';
import ArrowDown from '../../assets/searchPage/arrow-down-white.svg';
import {
  Avatar,
  ExternalLink,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  StripeOnboardingAlert,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_ACCOUNTSETTING,
  EVENT_BROWSE_DASHBOARD,
  EVENT_BROWSE_DRIVELAHGO,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
  EVENT_BROWSE_TRUSTANDSAFE,
} from '../../util/gtm/gtmConstants';

import css from './SearchPageTopbarDesktop.css';
import { saveSearchedPlacesMobile } from '../../util/emailNotify';
import { createResourceLocatorString } from '../../util/routes';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { DEFAULT_TIMEZONE } from '../../util/dates';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_PERFORMED_SEARCH } from '../../util/conversions/conversionsConstants';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: 1.4708809,
    lng: 104.04157989999999,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: 1.216611,
    lng: 103.60650989999999,
  },
};
const MIN_TABLET_LAYOUT = 768;
const MAX_TABLET_LAYOUT = 1024;

let setPickUp = null,
  setDropOff = null;

const createFormValues = (searchParams, searchSelectedPrediction) => {
  const { dates, hours, address, locationName, timezone, mapSearch, bounds: boundParams } = parse(
    searchParams
  );
  let SEARCH_BOUNDS = {};
  if (boundParams && boundParams.length > 0) {
    const boundsArray = boundParams && boundParams.split(',');
    SEARCH_BOUNDS = {
      _sdkType: 'LatLngBounds',
      ne: {
        _sdkType: 'LatLng',
        lat: boundsArray[0],
        lng: boundsArray[1],
      },
      sw: {
        _sdkType: 'LatLng',
        lat: boundsArray[2],
        lng: boundsArray[3],
      },
    };
  }
  const datesArray = dates && dates.split(',');
  const hoursArray = hours && hours.split(',');
  if (datesArray && datesArray.length > 0 && hoursArray && hoursArray.length > 0) {
    setPickUp =
      moment(datesArray[0] + ' ' + hoursArray[0])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
    setDropOff =
      moment(datesArray[1] + ' ' + hoursArray[1])
        .tz(DEFAULT_TIMEZONE)
        .toDate() || null;
  }
  const bounds =
    searchSelectedPrediction && searchSelectedPrediction.bounds
      ? searchSelectedPrediction.bounds
      : SEARCH_BOUNDS;
  const searchDateTime = {
    searchLocation: {
      search: mapSearch ? 'Map location' : locationName || address,
      selectedPlace: {
        address:
          searchSelectedPrediction && searchSelectedPrediction.address
            ? searchSelectedPrediction.address
            : address,
        bounds,
        timezone: searchSelectedPrediction && searchSelectedPrediction.timezone
      },
      origin: searchSelectedPrediction && searchSelectedPrediction.origin,
      mapSearch: mapSearch && mapSearch,
    },
    pickUp: { date: setPickUp },
    pickupTime: setPickUp ? moment(setPickUp).format('hh:mm a') : null,
    dropOff: { date: setDropOff },
    dropOffTime: setDropOff ? moment(setDropOff).format('hh:mm a') : null,
  };
  return searchDateTime;
};

let searchDateTimeData;
const SearchPageTopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    isAuthenticated,
    onLogout,
    viewport,
    searchSelectedPrediction,
    onLoginOrSignupClick
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const { search } = location;
  const [searchDateTime, setSearchDateTime] = useState();
  useEffect(() => {
    if (search === searchParams) {
      return;
    }
    setSearchParams(search);
    searchDateTimeData = createFormValues(search, searchSelectedPrediction);
    setSearchDateTime(searchDateTimeData);
  }, [location]);

  useEffect(() => {
    setMounted(true);
  }, [mounted]);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const isTabletSmall = viewport.width >= MIN_TABLET_LAYOUT && viewport.width < MAX_TABLET_LAYOUT;

  const classes = classNames(rootClassName || css.root, className);

  const Logo = () => {
    return <img className={css.searchPageLogo} src={LogoImage} />;
  };

  const pushGTMBrowse = event => {
    pushGTMBrowseEvent({
      props,
      event,
    });
  };

  const noSearchBox = <div className={css.noSearchBox}></div>;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <span>
          <img src={ArrowDown} alt="" />
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          {isTabletSmall && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
              onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.inbox" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_ACCOUNTSETTING)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="referralPage">
          <NamedLink
            className={classNames(css.referralPageLink, currentPageClass('ReferralPage'))}
            name="ReferralPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.referralPageLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={css.signupLink}
      onClick={onLoginOrSignupClick}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={css.loginLink}
      onClick={onLoginOrSignupClick}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const learnMoreMenu = (
    <Menu>
      <MenuLabel className={css.learnMoreLink}>
        <FormattedMessage id="TopbarDesktop.learnMoreMenu" />
      </MenuLabel>
      <MenuContent className={css.learnMoreMenuContent}>
        <MenuItem key="LMHowItWork">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="HowItWorkPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkHowItWorks" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMRentingACar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMRentingACar)}
            name="BecomeAGuestPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkRentingACar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSharingYourCar">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSharingYourCar)}
            name="BecomeAHostPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSharingYourCar" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMTrustAndSafety">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="TrustAndSafetyPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkTrustAndSafety" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="DriveLahGo">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="LandingPageGo"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_DRIVELAHGO)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.drivelahGo" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InstantBookingLandingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="InstantBookingLandingPage"
          >
            <span className={css.menuItemBorder} />
            Instant booking
          </NamedLink>
        </MenuItem>
        <MenuItem key="BlogPage">
          <ExternalLink className={classNames(css.yourListingsLink, css.LMTrustAndSafety)} href="https://drivemate.au/blog">
             <span className={css.menuItemBorder} />
              Blog
            </ExternalLink>
            {/* <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="InstantBookingLandingPage"
          >
            <span className={css.menuItemBorder} />
            Instant booking
          </NamedLink> */}
        </MenuItem>

        {/* <MenuItem key="LandingPageByod">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="LandingPageByod"
          >
            <span className={css.menuItemBorder} />
            Bring your own device
          </NamedLink>
        </MenuItem> */}

        {/* <MenuItem key="LMAntiVirusProgram">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="CovidLandingPage"
            onClick={() => null}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="MasterCardPromos">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMTrustAndSafety)}
            name="MasterCardLandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.promotions" />
          </NamedLink>
        </MenuItem> */}

        {/* <MenuItem key="LMInsurance">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMInsurance)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkInsurance" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMSmartTechnology">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMSmartTechnology)}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkSmartTechnology" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  );

  const handleSubmitSearch = value => {
    const { searchLocation, pickUp, dropOff, pickupTime, dropOffTime } = value;
    if (searchLocation) {
      const routes = routeConfiguration();
      const { selectedPlace } = searchLocation;
      if (selectedPlace) {
        saveSearchedPlacesMobile(selectedPlace);
      }
      const { search } = searchLocation;
      const dates =
        pickUp && dropOff
          ? `${moment(pickUp.date).format('YYYY-MM-DD')},${moment(dropOff.date).format(
              'YYYY-MM-DD'
            )}`
          : null;
      const hours =
        pickupTime && dropOffTime
          ? `${moment(pickupTime, 'hh:mm a').format('HH:mm')},${moment(
              dropOffTime,
              'hh:mm a'
            ).format('HH:mm')}`
          : null;
      const properties = createRawPropertiesForGTM({
        props,
      });
      createConversionEvents(properties, EVENT_CONVERSION_PERFORMED_SEARCH, 'click');
      history.push(
        createResourceLocatorString('SearchPage', routes, null, {
          bounds: encodeLatLngBounds(
            selectedPlace && selectedPlace.bounds ? selectedPlace.bounds : DEFAULT_BOUNDS
          ),
          dates,
          hours,
          address: search,
          locationName: search,
          timezone: selectedPlace.timezone
        })
      );
    }
  };

  return (
    <nav className={classes}>
      
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo />
      </NamedLink>
      {/* {!notApplySearch ? search : noSearchBox} */}
      {searchDateTime && (
        <div className={css.noSearchBox}>
          <TopbarSearchForm
            className={css.searchLink}
            desktopInputRoot={css.topbarSearchWithLeftPadding}
            onSubmit={handleSubmitSearch}
            history={history}
            location={location}
            rawInitialValues={searchDateTime}
            setSearchDateTime={setSearchDateTime}
          />
        </div>
      )}
      {/* {learnMoreMenu} */}
      {/* <NamedLink
        className={css.createListingLink}
        name="NewListingPage"
        onClick={() =>
          initiateListACarEventFromTab({
            props,
            eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR],
          })
        }
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink> */}
      {/* {!isTabletSmall && inboxLink} */}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

SearchPageTopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

SearchPageTopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  location: object,
  onLoginOrSignupClick: func.isRequired,
};

export default withViewport(SearchPageTopbarDesktop);