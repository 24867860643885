import React from 'react';
import classNames from 'classnames';
import css from './SectionHowItWorks.css';

const SectionHowItWorks = () => {
  return (
    <div className={css.root}>
      <div className={css.content}>
        {/* <div className={css.title}>How it works</div> */}
        {/* <img src={howItWorks} className={css.image} /> */}
        <div className={css.steps}>
          <div className={classNames(css.title)}>How it works</div>
          <div className={css.step}>
            <div className={css.circleIndex}>1</div>
            <div className={css.info}>
              <div className={css.stepTitle}>Choose a car to suit your lifestyle</div>
              <div className={css.stepDesc}>
                Choose your ideal car from a variety of economy, SUV, sports & luxury cars.
              </div>
            </div>
          </div>
          <div className={css.step}>
            <div className={css.circleIndex}>2</div>
            <div className={css.info}>
              <div className={css.stepTitle}>Complete the booking</div>
              <div className={css.stepDesc}>
                Our team will set up your all inclusive subscription which will be charged weekly. A refundable deposit amount of 4 weeks shall apply.
              </div>
            </div>
          </div>
          <div className={css.step}>
            <div className={css.circleIndex}>3</div>
            <div className={css.info}>
              <div className={css.stepTitle}>Share your car and earn</div>
              <div className={css.stepDesc}>
                Feel free to Rent out your vehicle on Drive mate when you are not using it and make additional income.
              </div>
            </div>
          </div>
          <div className={css.step}>
            <div className={css.circleIndex}>4</div>
            <div className={css.info}>
              <div className={css.stepTitle}>Cancel / Change anytime</div>
              <div className={css.stepDesc}>
                Want to cancel / change? Just let us know 4 weeks in advance and we will take care of it without any additional charges.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHowItWorks;
