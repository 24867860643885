import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { login } from '../../ducks/Auth.duck';
import { parse } from '../../util/urlHelpers';
import { get } from 'lodash';
import classNames from 'classnames';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './GenerateUserPage.css';

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/

let sentIntercomEvent = false;

class GenerateUserPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      location,
      onLogin,
    } = this.props;

    const urlParams = parse(location.search);

    onLogin(urlParams.e.replace(" ", "+"), `${urlParams.p}`);
  }

  render() {
    const {
      currentUser,
      intl,
      scrollingDisabled,
      intercomUserId,
      history,
      location,
      loginError,
    } = this.props;
    const title = intl.formatMessage({
      id: 'GenerateUserPage.title',
    });

    if (currentUser && currentUser.id && currentUser.id.uuid) {
      const urlParams = parse(location.search);
      history.push(createResourceLocatorString('EmailVerificationPage', routeConfiguration(), {}, {t: urlParams.t || null, p: urlParams.p || null}));
    }

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled} referrer="origin">
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <div className={classNames(css.content, loginError ? css.contentError : css.null)}>
                <div className={css.loadingContent}>
                  {loginError ?
                    <h3 className={css.error}><FormattedMessage id="GenerateUserPage.loadingError"/></h3>
                    : <h3><FormattedMessage id="GenerateUserPage.loadingContent"/></h3>
                  }
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

GenerateUserPageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
};

const { bool, func, shape, string } = PropTypes;

GenerateUserPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { loginError } = state.Auth
  return {
    currentUser,
    intercomUserId: get(currentUser, 'attributes.profile.privateData.intercomUserId'),
    scrollingDisabled: isScrollingDisabled(state),
    loginError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogin: (usn, pwd) => dispatch(login(usn, pwd))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const GenerateUserPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(GenerateUserPageComponent);

export default GenerateUserPage;
