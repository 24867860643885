import React from 'react';
import css from './LandingPage.css';
import Slider from 'react-slick';
import { ListingCardV2, NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const SectionCars = () => {
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = useSelector(
    state => state.SearchPage
  );
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = useSelector(state => getMarketplaceEntities(state, featuredCarRefs));
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    infinite: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.sectionCars}>
      <div className={css.sectionCarsTitleWrapper}>
        <div className={css.sectionTitle}>New cars are added to the community every day</div>
        <p>
          New reliable, fully-insured cars are joining Drive mate daily! Check out the latest
          vehicles and rates your neighbours are currently offering below.
        </p>
      </div>
      {fetchFeaturedCarInProgress ? (
        <div className={css.sectionCarsLoading}>Loading car...</div>
      ) : (
        <div className={css.sliderWrapper}>
          <Slider {...sliderConfig}>
            {listings.map(l => (
              <div className={css.listingCardWrapper}>
                <ListingCardV2 listing={l} />
              </div>
            ))}
          </Slider>
        </div>
      )}
      <NamedLink
        name="SearchPage"
        to={{
          search:
            '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798',
        }}
        className={css.viewAllCar}
      >
        <FormattedMessage id="LandingPage.viewAllCar" />
      </NamedLink>
    </div>
  );
};

export default SectionCars;
