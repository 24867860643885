import React, { useEffect, useState } from 'react';
import styles from './NewVerificationFlow.module.css'
import config from '../../config';
import VerificationStatus from '../VerificationStatus/VerificationStatus';
import VerificationSteps from '../VerificationSteps/VerificationSteps';

const NewVerificationFlow = ({ statusPageProps, stepsPageProps, isVerificationStatus= false}) => {

  const { status, statusID, phoneNumberVerified, isSubmittedOnfidoVerification, isCardVerified } = statusPageProps
  
  const {currentUser, dateOfBirth, transactionForNewVerification, transactionIDForNewVerification, nextInteractableTransitionsNew, haveNextTransitNew, onTransitNew, isHostNew, onUpdateUserDataNew} = stepsPageProps

  
  const stepSetter = () => {
    return isHostNew
      ? phoneNumberVerified ? 1 : 0
      : phoneNumberVerified
        ? isSubmittedOnfidoVerification ? 2 : 1
        : 0;
  };
  
  const btnNameSetter = () => {
    if (statusID === 'AccountSettingDriverVerificationPage.notSubmit') return 'Start Verification';
    if (statusID === 'AccountSettingDriverVerificationPage.inProgress') {
      return 'Continue Verification'
    };
  }

  console.log('STATUS  >>', status);
        
  const [verificationInProgress, setVerificationInProgress] = useState(isVerificationStatus  );
  const [currentStep, setCurrentStep] = useState(stepSetter());
  const [btnName, setBtnName] = useState(btnNameSetter());

  useEffect(() => {
    setCurrentStep(stepSetter());
    setBtnName(btnNameSetter);
  }, [statusPageProps, stepsPageProps]);

  const onBtnClickhandler = () => {
    setVerificationInProgress(true);
  }

  const tabs = [...config.custom.tabs];
  const currentTab = tabs.find((tab) => tab.linkProps.name === "AccountSettingDriverVerificationPage-2")
  if (currentTab) {
    currentTab.selected = true;
  }

  return (
    <div className={styles.outerContainer}>
      {!verificationInProgress && 
      <VerificationStatus
      isHost={isHostNew}
      status={status}
      statusID={statusID}
      isPhoneVerified={phoneNumberVerified}
      isSubmittedOnfidoVerification={isSubmittedOnfidoVerification}
      isCardVerified={isCardVerified}/>}

      {verificationInProgress && 
      <VerificationSteps
      currentStep={currentStep}
      currentUser={currentUser}
      dateOfBirth={dateOfBirth}
      transaction={transactionForNewVerification}
      transactionId={transactionIDForNewVerification}
      nextInteractableTransitions={nextInteractableTransitionsNew}
      haveNextTransit={haveNextTransitNew}
      onTransit={onTransitNew}
      isHost={isHostNew}
      onUpdateUserData={onUpdateUserDataNew}
      isCardVerified={isCardVerified}
      settingSteps={setCurrentStep}
      />}

      {(statusID === 'AccountSettingDriverVerificationPage.notSubmit' || statusID === 'AccountSettingDriverVerificationPage.inProgress') && !verificationInProgress && (        
      <div className={styles.buttonContainer}>
        <button onClick={onBtnClickhandler}>{btnName}</button>
      </div>
      )}
    </div>
  );
};

export default NewVerificationFlow;