import React, {useState } from 'react';
import css from "../EditListingInsurancePanel.css";
import { Button, ToggleSwitch } from '../..';
const options = [
    {key:"0", value : 0},
    {key:"1", value : 1},
    {key:"2", value : 2},
    {key:"More than 2", value : 8}

]

const redbookOptions = [
  {key:"$8k - $20k", value : 8000},
  {key:"$20K - $40K", value : 20000},
  {key:"$40k - $70k", value : 40000},
]
export const BblQuoteComponent = ({ setBblQuotevalue, onOptIn, onClose }) => {
  const [value, setValue] = useState('');
  const [selectedClaims, setSelectedClaims] = useState(options[0].value);
  const [selectedRedbookValue, setSelectedRedbookValue] = useState(redbookOptions[0].value);
  const [isDashcamInstalled, setIsDashcamInstalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  // Loading state 

  const fetchQuotation = async () => {
    try {
      setIsLoading(true);  // Start loading
      console.log("BBI VALUE", {
        claims: selectedClaims,
        carValue: selectedRedbookValue,
        dashcams: isDashcamInstalled,
      });
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/bbi-prices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          claims: selectedClaims,
          carValue: selectedRedbookValue,
          dashcams: isDashcamInstalled,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.price; // Assuming the API returns the price value in the 'price' field.
      } else {
        console.error('Failed to fetch quotation');
        return null;
      }
    } catch (error) {
      console.error('Error during fetching quotation:', error);
      return null;
    } finally {
      setIsLoading(false);  // Stop loading
    }
  };

  const handleSubmit = async () => {
    const price = await fetchQuotation();
    if (price) {
      setValue(price); // Update the value with the price from the API
      // Do not close the modal here, let the user see the price
    }
  };

  const handleOptIn = () => {
    setBblQuotevalue(value); // Pass the value to the parent
    // onOptIn(value); // Trigger the parent submit function
    onClose();  // Close the modal when the user opts in
    setValue('');
  };

    return(
        <>
            <h1 className={`${css.title} ${css.quoteHeading} ${css.boderBottom}`}>BBI Quote</h1>
            <div className={css.contentWrapper}>
              {!value ? 
                <div className={css.quoteListingWrapper}>           
                  <p>Provide the following details to get a quote for your listing.</p>
                  <p>{`Claims >$1000 done in the last year`}</p>
                  <div>
                  <GroupOfFieldRadios
                    options={options}
                    selectedOption={selectedClaims}
                    onOptionSelect={(option) => setSelectedClaims(option)}
                  />
                  </div>
                  <div className={css.toggleWrapper}>
                  <p>Dashcam installed?</p>
                  <ToggleSwitch
                  status={isDashcamInstalled}
                  onChange={()=>setIsDashcamInstalled(!isDashcamInstalled)}
                  />
                  </div>
                  <div>
                    <p>Current Redbook value of the car</p>
                <GroupOfFieldRadios
                  options={redbookOptions}
                  selectedOption={selectedRedbookValue}
                  onOptionSelect={(option) => setSelectedRedbookValue(option)}
                />
                </div>
              <Button
                className={css.insurenceBtn}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Fetching...' : 'Get your personalised quote'} {/* Loader in the button */}
              </Button>
                </div>
              : 
                <div className={css.bookingInsurenceValue}>
                  <p>Your personalised Between booking insurance quote</p>
                  <h1 className={css.priceText}>{`$${value}`}</h1> {/* Show the price value */}
                  <span className={css.monthText}>/month</span>
                  <Button className={css.insurenceBtn} onClick={handleOptIn}>Opt in for BBI</Button>
                </div>
              }
            </div>
        </>
    )
}

const GroupOfFieldRadios = ({ options, selectedOption, onOptionSelect }) => {
    return (
      <fieldset className={css.optionWrapper}>
        <ul>
          {options.map((option, index) => {
            const fieldId = `${option.key}`;
            return (
              <li key={fieldId}>  
                <label className={css.label}>
                  <span className={`${css.radioButtonWrapper}`}>
                    <input
                      style={{ accentColor: '#00A3AD' }}
                      type='radio'
                      checked={selectedOption === option.value}  // Ensure only one is selected
                      onChange={() => onOptionSelect(option.value)}  // Select only one input
                    />
                  </span>
                  <span className={`${css.text} ${css.labelValue}`}>{option.key}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </fieldset>
    );
  };
