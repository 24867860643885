import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import {
  Footer,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LoginModal,
  NamedLink,
  Page,
  UCSDeclarationModal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import SectionHero from './SectionHero';
import SectionIntroducing from './SectionIntroducing';
import SectionVision from './SectionVision';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import css from './LandingPageByod.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { login } from '../../ducks/Auth.duck';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ByodFaq from './ByodFaq';
import { saveUCSDeclaration } from '../UCSDeclarationPage/UCSDeclarationPage.duck';
import { $hasPublishedOrPendingApprovalListings, checkUserListingsStatus } from '../../ducks/byod.duck';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    scrollingDisabled,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onLogin,
    loginError,
    loginInProgress,
    onLoginOrSignupClick,
    saveUCSDeclarationSuccess,
    saveUCSDeclarationError,
    saveUCSDeclarationInProgress,
    onSubmitUCSDeclarationForm,
    checkUserListingsStatus,
    hasPublishedOrPendingApprovalListings,
  } = props;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showUCSModal, setShowUCSModal] = useState(false);
  const [isGoToHost, setIsGoToHost] = useState(false);

  const ucsHostDeclaration = currentUser?.attributes?.profile?.protectedData?.ucsHostDeclaration;
  const ucsHostDeclarationSigned = ucsHostDeclaration?.signedAt || false;

  useEffect(() => {
    return () => {
      setShowLoginModal(false);
      setShowUCSModal(false);
    };
  }, []);

  console.log('USER HAS LISTINGS ?? >>>', hasPublishedOrPendingApprovalListings);
  
  useEffect(() => {
    if(isAuthenticated) {
      checkUserListingsStatus(isAuthenticated);
      setShowLoginModal(false);
    }
    if (currentUser && currentUser.id && isAuthenticated && isGoToHost) {
      // const routes = routeConfiguration();
      // const url = createResourceLocatorString('LandingPageGoHost', routes);
      // history.push(url);
    }
  }, [currentUser, isAuthenticated, isGoToHost, history]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = 'BYOD | Drive Mate';
  const schemaDescription = 'Drive mate’s BYOD program';
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isLogined = !!(currentUser && currentUser.id && isAuthenticated);


  const handleLearnMore = () => {
    if (isLogined) {
      const routes = routeConfiguration();
      const url = createResourceLocatorString('LandingPageByodHost', routes);
      history.push(url);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleLogin = values => {
    const { email, password } = values;
    setIsGoToHost(true);
    return onLogin(email, password);
  };

  const nameOfLink = hasPublishedOrPendingApprovalListings ? "ManageListingsPage" : "NewListingPage";

  const btnText = hasPublishedOrPendingApprovalListings ? "Go to your listings" : "List your car";

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero  
           ucsModalOpen={() => setShowUCSModal(true)}
           loingModalOpen={() => setShowLoginModal(true)}
           isAuthenticated={isAuthenticated}
           ucsHostDeclarationSigned={ucsHostDeclarationSigned}
           hasPublishedOrPendingApprovalListings={hasPublishedOrPendingApprovalListings}
           />
          <section className={css.heroSection}>
            <div className={css.heroImage}
                 title="Best car sharing for all your car rental needs | Drive Mate">
            </div>
          </section>
          <SectionIntroducing  
            ucsModalOpen={() => setShowUCSModal(true)}
           loingModalOpen={() => setShowLoginModal(true)}
           isAuthenticated={isAuthenticated}
           ucsHostDeclarationSigned={ucsHostDeclarationSigned}
           hasPublishedOrPendingApprovalListings={hasPublishedOrPendingApprovalListings}
          />
          <section className={css.whyOptDriveMate}>
            <div className={css.textBox}>
              <h4 className={css.contentTitle}>How BYOD integrates into our host plans:</h4>
              <div className={css.contentDescription}>
                <ul>
                  <li>
                    <h2>Just mates</h2>
                    <ul>
                     <li>
                        <p className={css.pClass}>
                          Your car must have your own working GPS installed otherwise theft cannot
                          be covered - Airtags or similar are not secure enough.
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Excess mileage reporting by hosts based on odometer photos
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Key handover
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Free
                        </p>
                      </li>
                     
                    </ul>
                  </li>
                  <li>
                    <h2>Good mates</h2>
                    <ul>
                      <li>
                        <p className={css.pClass}>Primary GPS installed via OBD</p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Key handover
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Automated mileage calculations
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          $10/month
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h2>Best mates</h2>
                    <ul>
                      <li>
                        <p className={css.pClass}>Keyless access tech</p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Remote handover
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          Automated mileage calculations
                        </p>
                      </li>
                      <li>
                        <p className={css.pClass}>
                          $30/month
                        </p>
                      </li>
                    </ul>
                  </li>
                  
                    
                  
                </ul>
                <p className={css.pClass}>A $2 one-time BYOD fee per device will apply</p>

              </div>
              <div className={css.contentDescription}>
                <h4 className={css.contentTitle}>Add-ons available</h4>
                <ul>
                  <li>
                    <p className={css.pClass}>
                      BYO lockbox - $10/month
                    </p>
                  </li>
                  <li>
                    <p className={css.pClass}>
                      BYO secondary GPS for enhanced security - $5/month
                      <ul>
                        <li>
                          <p className={css.pClass}>
                            This will be mandatory in certain postcodes
                          </p>
                        </li>
                        <li>
                          <p className={css.pClass}>
                            We can also provide a secondary GPS for just the $5/month
                          </p>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    <p className={css.pClass}>
                      Between booking insurance - cost and criteria advised soon
                    </p>
                  </li>
                </ul>
              </div>
              <h5>

              {!isAuthenticated ? (
              <InlineTextButton
                  rootClassName={classNames(css.goToSearchButton)}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowLoginModal(true);
                  }}
                >
                  <FormattedMessage id="LandingPageByod.loginForEnrol" />
               </InlineTextButton>
                 
                ) : ucsHostDeclarationSigned ? (
                  <NamedLink
                      name={nameOfLink}
                      className={classNames(css.goToSearchButton)}
                    >
                      {btnText}
                  </NamedLink>
                ) : (
                  <InlineTextButton
                  rootClassName={classNames(css.goToSearchButton)}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowUCSModal(true)
                  }}
                >
                 <FormattedMessage id="LandingPageByod.learnMore" />
            </InlineTextButton>
                )}
              </h5>
            </div>
          </section>
          <ByodFaq />
          <LoginModal
            onManageDisableScrolling={onManageDisableScrolling}
            isOpen={showLoginModal}
            onClose={() => setShowLoginModal(false)}
            handleSubmit={handleLogin}
            loginError={loginError}
            loginInProgress={loginInProgress}
            isByod={true}
          />
          {isAuthenticated && (
            <UCSDeclarationModal
              onManageDisableScrolling={onManageDisableScrolling}
              isOpen={showUCSModal}
              onClose={() => setShowUCSModal(false)}
              handleSubmit={onSubmitUCSDeclarationForm}
              saveUCSDeclarationSuccess={saveUCSDeclarationSuccess}
              saveUCSDeclarationError={saveUCSDeclarationError}
              saveUCSDeclarationInProgress={saveUCSDeclarationInProgress}
              ucsHostDeclarationSigned={ucsHostDeclarationSigned}
              hasPublishedOrPendingApprovalListings={hasPublishedOrPendingApprovalListings}

            />
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const {
  bool, object,
} = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: PropTypes.bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  onManageDisableScrolling: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  loginError: PropTypes.object,
  loginInProgress: PropTypes.bool.isRequired,
  onLoginOrSignupClick: PropTypes.func.isRequired,
  saveUCSDeclarationSuccess: PropTypes.bool,
  saveUCSDeclarationError: PropTypes.object,
  saveUCSDeclarationInProgress: PropTypes.bool.isRequired,
  onSubmitUCSDeclarationForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated, loginError, loginInProgress } = state.Auth;

  const {
    saveUCSDeclarationSuccess,
    saveUCSDeclarationError,
    saveUCSDeclarationInProgress,
  } = state.UCSDeclarationPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    loginError,
    loginInProgress,
    saveUCSDeclarationSuccess,
    saveUCSDeclarationError,
    saveUCSDeclarationInProgress,
    hasPublishedOrPendingApprovalListings:$hasPublishedOrPendingApprovalListings(state),
  };
};

const mapDispatchToProps = dispatch => ({
  checkUserListingsStatus: (isAuthenticated) => dispatch(checkUserListingsStatus(isAuthenticated)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onLogin: (email, password) => dispatch(login(email, password)),
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
  onSubmitUCSDeclarationForm: () => dispatch(saveUCSDeclaration()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(LandingPageComponent);

export default LandingPage;
