import chunk from 'lodash/chunk';

// query all page
const calculateRemainPages = meta => {
  const { totalPages = 1 } = meta;
  if (totalPages <= 1) return [];
  return new Array(totalPages - 1).fill(0).map((_v, i) => i + 2);
};

export const queryAllPage = async ({ sdk, query, include, perPage = 20 }) => {
  let result = [];
  const queryPage = async ({ page }) => {
    const response = await sdk.query({
      include,
      page,
      perPage,
      ...query,
    });
    result = [...result, response];
    return response;
  };
  const firstResponse = await queryPage({ page: 1 });
  const { meta } = firstResponse.data;
  const remainPages = calculateRemainPages(meta);
  if (remainPages.length) {
    await Promise.all(remainPages.map(page => queryPage({ page })));
    return result;
  }
  return result;
};

export const deleteAllExceptions = (exceptions = [], sdk) => {
  const pages = chunk(exceptions, 4);

  const deleteFunc = async () => {
    while (pages.length > 0) {
      const currentPage = pages.pop();
      try {
        return await Promise.all(
          currentPage.map(exp => sdk.availabilityExceptions.delete({ id: exp.id }))
        ).then(() => deleteFunc());
      } catch (error) {
        return Promise.resolve();
      }
    }
  };

  return deleteFunc();
};
