import React, { Fragment, memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  checkCode,
  estimateBreakdown,
  fetchTimeSlots,
  getDistanceToUser,
  loadData,
  resetCode,
  sendEnquiry,
  setInitialValues,
} from './ListingPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  categoryLabel,
  getListingFromState,
  getOwnListingFromState,
  listingImages,
  priceData,
} from './ListingPage.helper';
import { types as sdkTypes } from '../../util/sdkLoader';
import { NotFoundPage, TopbarContainer } from '..';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';
import {
  BookingPanel,
  Footer,
  InsurancePanelNew,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  ModalSharingListing,
  NamedLink,
  Page,
  RentalAgreement,
  YouMayAlsoLikeCarousel,
  NamedRedirect
} from '../../components';
import css from './ListingPage.css';
import config from '../../config';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionReviews from './SectionReviews';
import SectionReviewsGo from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import {
  getDatesFromUrlParams,
  getDefaultTimeZoneOnBrowser,
  momentTimeOfDayFromLocalToTimeZone,
  timestampToDate,
} from '../../util/dates';
import {
  ENQUIRY_BUTTON_ID,
  EVENT_SEARCH_CLICKED_PIC_LISTING,
  EVENT_SEARCH_PRESSED_BOOK_GUEST,
  EVENT_SEARCH_READ_INSURANCE,
  EVENT_SEARCH_RECV_ENQUIRY_HOST,
  EVENT_SEARCH_SENT_ENQUIRY_GUEST,
  EVENT_SEARCH_USED_MAP_LISTING,
  EVENT_SEARCH_VIEWED_PROFILE_GUEST,
  EVENT_SEARCH_VIEWED_PROFILE_HOST,
  REQUEST_TO_BOOK_BUTTON_ID,
  SERVER_EVENT_SEARCH_PRESSED_BOOK_HOST,
  VIEW_PHOTO_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import {
  checkOwnListing,
  ensureUser,
  getBookingConfig,
  listingIsDrivelahGo,
  listingIsInstantBooking,
  listingIsLongTermRental,
  userDisplayNameAsString,
} from '../../util/data';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { richText } from '../../util/richText';
import { STRIPE_T_KEY } from '../../util/stripeTerms';
import get from 'lodash/get';
import { sendGAEvent } from '../../util/googleAnalytics';
import { openMissingInfomationModal } from '../../ducks/user.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import SectionImagesGo from './Go/SectionImages';
import SectionHeadingGo from './Go/SectionHeading';
import SectionDescriptionMaybeGo from './Go/SectionDescriptionMaybe';
import SectionInnovative from './Go/SectionInnovative';
import SectionFeaturesMaybeGo from './Go/SectionFeaturesMaybe';
import SectionRulesMaybeGo from './Go/SectionRulesMaybe';
import SectionMapMaybeGo from './Go/SectionMapMaybe';
import SectionHostGo from './Go/SectionHost';
import { withViewport } from '../../util/contextHelpers';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const { UUID, Money } = sdkTypes;

const keyFeaturesConfig = config.custom.keyFeatures.filter(k => k.key !== 'pet_friendly');
const keyRulesConfig = config.custom.keyRules;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const noIndexListings = ["5fd0746b-5c34-49a8-adad-d719d39f5670", "6030e480-54db-45b7-af65-57ea8f8b0848"]

export const ListingPageComponent = memo(props => {
  const {
    params: rawParams,
    getListing,
    getOwnListing,
    showListingError,
    scrollingDisabled,
    intl,
    currentUser,
    onManageDisableScrolling,
    history,
    callSetInitialValues,
    location,
    distanceToUser,
    reviews,
    fetchReviewsError,
    onSendEnquiry,
    isAuthenticated,
    sendEnquiryError,
    sendEnquiryInProgress,
    unitType,
    timeSlots,
    fetchTimeSlotsError,
    onFetchTimeSlots,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    onCheckingVoucher,
    onResetCode,
    onEstimateBreakdown,
    estimateBreakdownInProgress,
    estimatedTx,
    sameCategoryListings,
    viewport,
    estimateError,
    fetchTimeSlotsInProgress,
    monthlyTimeSlots,
  } = props;


  const queryMaybe = parse(location.search);
  let newStart = null;
  let newEnd = null;
  let pickupTime = null;
  let dropoffTime = null;

  if (location && location.state) {
    newStart = location.state.startDate;
    newEnd = location.state.endDate;
    pickupTime = location.state.pickupTime;
    dropoffTime = location.state.dropoffTime;
  }
  if (
    queryMaybe.dates &&
    queryMaybe.dates.split(',').length === 2
  ) {
    const resp = getDatesFromUrlParams(queryMaybe.dates, queryMaybe.hours);
    if (resp.start) {
      pickupTime = resp.start.format('hh:mm a');
      newStart = resp.start.toDate();
    }
    if (resp.end) {
      dropoffTime = resp.end.format('hh:mm a');
      newEnd = resp.end.toDate();
    }
  }
  const isSearchingLongTerm = useSelector(state => state.SearchPage.isSearchingLongTerm);
  const shouldShowPricePerMonth = queryMaybe.pub_longTermRental || isSearchingLongTerm;

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [isOpenRentalAgreement, setIsOpenRentalAgreement] = useState(false);

  const handleOpenViewPhotos = () => setImageCarouselOpen(() => true);
  const handleCloseViewPhotos = () => setImageCarouselOpen(() => false);

  const [showShareModal, setShowShareModal] = useState(false);
  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [isLongTerm, setIsLongTerm] = useState(false);

  const [differenceInHours, setDifferenceInHours] = useState(0);

  console.log('I am differenceInHours >>', differenceInHours);

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant ? getOwnListing(listingId) : getListing(listingId);

  const localTimeZone = queryMaybe.timezone || getDefaultTimeZoneOnBrowser(currentListing && currentListing.attributes && currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone);
    const  initialDate={
    startDate: newStart,
    endDate: newEnd,
    pickupTime: pickupTime,
    dropoffTime: dropoffTime,
  }
  const { startDate, endDate } = initialDate;
  const bookingStartDateMaybe = startDate ? startDate : null;
  const bookingEndDateMaybe = endDate ? endDate : null;
  const pickupTimeToDate = pickupTime ? moment(pickupTime, 'hh:mm a').toDate() : null;


  const dropoffTimeToDate = dropoffTime ? moment(dropoffTime, 'hh:mm a').toDate() : null;

  let bookingStartTimeMaybe = null;
  let bookingEndTimeMaybe = null;
  if (pickupTimeToDate && bookingStartDateMaybe) {
    bookingStartTimeMaybe = bookingStartDateMaybe.setHours(
      pickupTimeToDate.getHours(),
      pickupTimeToDate.getMinutes()
    );
  }

  if (dropoffTimeToDate && bookingEndDateMaybe) {
    bookingEndTimeMaybe = bookingEndDateMaybe.setHours(
      dropoffTimeToDate.getHours(),
      dropoffTimeToDate.getMinutes()
    );
  }

  const initialValues = {
    bookingStartDate: bookingStartDateMaybe ? { date: bookingStartDateMaybe } : null,
    bookingEndDate: bookingEndDateMaybe ? { date: bookingEndDateMaybe } : null,
    bookingStartTime:   bookingStartTimeMaybe ? momentTimeOfDayFromLocalToTimeZone(bookingStartTimeMaybe, localTimeZone).format('x') : null,
    bookingEndTime: bookingEndTimeMaybe ? momentTimeOfDayFromLocalToTimeZone(bookingEndTimeMaybe, localTimeZone).format('x') : null,
  };


  useEffect(() => {
    sendGAEvent({
      eventCategory: 'Transaction',
      eventAction: 'View A Listing',
    });
    loadFbSdk();
    setTimeout(() => {
      calculateDistanceToUser();
    }, 3000);
  }, [currentListing.id]);

  useEffect(() => {
    const isLongTermTransaction = get(estimatedTx, 'attributes.protectedData.isLongTermRental');
    if (isLongTermTransaction !== isLongTerm) setIsLongTerm(isLongTermTransaction);
  }, [estimatedTx]);

  const generateListingLink = () => {
    const routes = routeConfiguration();

    const link =
      currentListing && currentListing.id
        ? createResourceLocatorString(
            'ListingPage',
            routes,
            { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
            {}
          )
        : '/';
    return `${config.canonicalRootURL}${link}`;
  };

  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const calculateDistanceToUser = () => {
    const { currentUser, params: rawParams, getDistanceToUser, location } = props;

    const listingId = new UUID(rawParams.id);
    const searchParams = parse(location.search);
    const { origin } = searchParams || {};

    if (currentListing.id) {
      const userLocation = get(
        currentUser || {},
        'attributes.profile.protectedData.location.selectedPlace.origin'
      );
      const [lat, lng] = origin ? origin.split(',') : [];

      getDistanceToUser({
        userLocation:
          lat & lng
            ? { lat, lng }
            : userLocation
            ? { lat: userLocation.lat, lng: userLocation.lng }
            : null,
        listingLocation: {
          lat: currentListing.attributes.geolocation && currentListing.attributes.geolocation.lat || null,
          lng: currentListing.attributes.geolocation && currentListing.attributes.geolocation.lng || null,
        },
        listingId: currentListing.id.uuid,
      });
    }
  };

  const handleViewPhotosClick = (e, isButtonClicked) => {
    e.stopPropagation();
    handleOpenViewPhotos();
    if (isButtonClicked) {
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        buttonId: VIEW_PHOTO_BUTTON_ID,
        event: EVENT_SEARCH_CLICKED_PIC_LISTING,
        isHost: isOwnListing,
      });
    } else {
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        event: EVENT_SEARCH_CLICKED_PIC_LISTING,
        isHost: isOwnListing,
      });
    }
  };

  if (showListingError && showListingError.status === 404) {
    return <NotFoundPage />;
  }

  if (showListingError) {
    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.errorWrapper}>
            <p className={css.errorText}>
              <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  if (!currentListing.id) {
    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });
    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const onContactUser = () => {
    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: rawParams.id });

      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      setEnquiryModalOpen(() => true);
    }
  };

  const onOpenSharingModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(() => true);
  };

  const onCloseSharingModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(() => false);
  };

  const onShareToFacebookTimeline = message => {
    if (!window) return;
    const link = generateListingLink();

    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: link,
      },
      function(response) {}
    );
  };

  const onShareToMessenger = () => {
    if (!window) return;
    const link = generateListingLink();
    window.FB.ui({
      method: 'send',
      link: link,
    });
  };

  const {
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    description = '',
  } = currentListing.attributes;
  const { longTermRental } = publicData;

  let formattedPrice;
  let priceTitle;

  const data = priceData(
    price,
    intl,
    longTermRental && shouldShowPricePerMonth,
    currentListing
  );

  formattedPrice = data.formattedPrice;
  priceTitle = data.priceTitle;

  let longTermPrice = get(currentListing, 'attributes.publicData.longTermPrice.amount', 99900);
  longTermPrice = new Money(longTermPrice, config.currency);
  longTermPrice = longTermPrice ? formatMoney(intl, longTermPrice) : null;

  const ensuredDescription = description === config.custom.defaultDescription ? '' : description;
  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle }
  );
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'description';
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

  const {
    category,
    brandName,
    transmissions,
    fuelType,
    drivenKm,
    peopleNumber,
    keyFeatures: selectedFeatures,
    keyRules: selectedKeyRules,
    millagePerDay
  } = publicData || {};

  // const milleagePerDay = config.custom.DEFAULT_MILEAGE_ALLOWED_PER_DAY;

  const mileageToShow = () => {
    if (differenceInHours > 12 || !differenceInHours) {
      return millagePerDay + " kms/day";
    }
    if (differenceInHours <= 2) {
      return 50 + " kms/day";
    } else if (differenceInHours > 2 && differenceInHours <= 3) {
      return 75 + " kms/day";
    } else if (differenceInHours > 3 && differenceInHours <= 4) {
      return 100 + " kms/day";
    } else if (differenceInHours > 4 && differenceInHours <= 5) {
      return 125 + " kms/day";
    } else if (differenceInHours > 5) {
      return 150 + " kms/day";
    }
  }

  // const milleagePerDay = millagePerDay + " kms/day" + 'I AM HERE NOW ';
  // const milleagePerDay = mileageToShow();

  console.log('I AM mileageToSHow >>', mileageToShow());

  

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const modifiedCategory = category => {
    if (category) {
      const tempCategory = String(category);
      const splitedValue = tempCategory
        .split('_')
        .filter(Boolean)
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
      return splitedValue;
    }
    return category;
  };

  const categoryVaues =
    category && !category.includes('ADMIN') ? (
      <>
        {modifiedCategory(categoryLabel(config.custom.categories, category))}
        <span className={css.separator}>•</span>
      </>
    ) : null;

  const hostLink = (
    <NamedLink
      className={css.authorNameLink}
      name="ListingPage"
      params={params}
      to={{ hash: '#host' }}
    >
      {authorDisplayName}
    </NamedLink>
  );

  const isInstantBooking = listingIsInstantBooking(currentListing);
  const isDrivelahGo = listingIsDrivelahGo(currentListing);
  const isDisinfected = get(currentListing, 'attributes.metadata.isDisinfected');
  const shareableLink = generateListingLink();
  const isAdminDepositListing = currentListing.attributes.publicData.isDeposit;

  const onSubmitEnquiry = values => {
    const routes = routeConfiguration();
    console.log("CURRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR", estimatedTx)
    const listingId = new UUID(params.id);
    const { message,bookingStartDate,bookingEndDate,bookingStartTime,bookingEndTime } = values;
    const buttonText = intl.formatMessage({ id: 'EnquiryForm.submitButtonText' });
    initiateEventFromListing({
      props: props,
      listing: currentListing,
      buttonId: ENQUIRY_BUTTON_ID,
      buttonText,
      event: EVENT_SEARCH_SENT_ENQUIRY_GUEST,
    });
    onSendEnquiry(listingId, message.trim(),bookingStartDate,bookingEndDate,bookingStartTime,bookingEndTime, queryMaybe,localTimeZone)
      .then(txId => {
        setEnquiryModalOpen(false);
        initiateEventFromListing({
          props: props,
          listing: currentListing,
          buttonId: ENQUIRY_BUTTON_ID,
          buttonText,
          event: EVENT_SEARCH_RECV_ENQUIRY_HOST,
          isHost: true,
          userDiffActionTaker: true,
        });
        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const onViewProfile = () => {
    const isOwnListing = checkOwnListing(currentUser, currentListing);
    initiateEventFromListing({
      props: props,
      listing: currentListing,
      pageName: 'ListingPage',
      event: isOwnListing ? EVENT_SEARCH_VIEWED_PROFILE_HOST : EVENT_SEARCH_VIEWED_PROFILE_GUEST,
      isHost: isOwnListing,
    });
  };
  const isLiveListing = currentListing.id && currentListing.attributes.metadata.live;

  const notBookableLabel = (
    <FormattedMessage id="ListingPage.notBookable" values={{ title: richTitle }} />
  );

  const checkValidateBooking = () => {
    const emailVerificationNeeded = !!currentUser.id && !currentUser.attributes.emailVerified;

    const mobileInputNeeded = !currentUser.id
      ? false
      : !!currentUser.attributes.profile.protectedData &&
        !currentUser.attributes.profile.protectedData.phoneNumber
      ? true
      : false;
    const mobileUnverified = !currentUser.id
      ? false
      : !!currentUser.attributes.profile.protectedData &&
        !currentUser.attributes.profile.protectedData.phoneNumberVerified
      ? true
      : false;
    const mobileVerificationNeeded = mobileUnverified; //Show that mobile need verified or not

    // if (emailVerificationNeeded || mobileVerificationNeeded || mobileInputNeeded) {
    //   props.onOpenMissingInfomationModal(true);
    //   return false;
    // }
    return true;
  };

  const onReadInsurance = () => {
    initiateEventFromListing({
      props: props,
      listing: currentListing,
      event: EVENT_SEARCH_READ_INSURANCE,
      isHost: checkOwnListing(currentUser, currentListing),
    });
  };

  const bookingTitle = (
    <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
  );
  const handleSubmit = values => {
    const {
      history,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onResetCode,
      location,
    } = props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    console.log("Final come to handle listing", values);

    const { bookingStartTime, bookingEndTime, signupCredits, voucherCode, payForFuel } = values;

    const initialValues = {
      listing,
      bookingData: {
        signupCredits,
        voucherCode,
        payForFuel: false,
        stripeTKey: STRIPE_T_KEY,
        // payForFuel: !!payForFuel,
      },
      bookingDates: {
        bookingStart: timestampToDate(bookingStartTime),
        bookingEnd: timestampToDate(bookingEndTime),
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    onResetCode();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        { searchPageParams: location.search }
      )
    );
  };

  const handleBookingSubmit = values => {
    console.log("Hellllllllllllllllllllllllllllllllllll", values);
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    const userVerificationStatus = get(currentUser,'attributes.profile.publicData.guestIdentityVerificationStatus', 'not_submitted');
    console.log("Hello world", isCurrentlyClosed, estimatedTx && estimatedTx.guestIdentityVerificationStatus && estimatedTx.guestIdentityVerificationStatus !== 'confirmed', userVerificationStatus)
    if(estimatedTx && estimatedTx.guestIdentityVerificationStatus && estimatedTx.guestIdentityVerificationStatus !== 'confirmed') {
      history.push('/user-verification');
    }
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } 
    else if (userVerificationStatus === 'confirmed') {
      handleSubmit(values);
    }
    else  {
      history.push('/user-verification');
    }
    try {
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        pageName: 'ListingPage',
        buttonId: REQUEST_TO_BOOK_BUTTON_ID,
        event: EVENT_SEARCH_PRESSED_BOOK_GUEST,
        isHost: false,
        transaction: estimatedTx,
      });
      sendG4AEvent(estimatedTx, GTAG_ACTIONS.ACTION_BEGIN_CHECKOUT, { bookingDates: values });
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        pageName: 'ListingPage',
        buttonId: REQUEST_TO_BOOK_BUTTON_ID,
        event: SERVER_EVENT_SEARCH_PRESSED_BOOK_HOST,
        isHost: true,
        transaction: estimatedTx,
        userDiffActionTaker: true,
        bookingValues: values,
        isServerSideEvent: true,
      });
    }
    catch (error) {
      console.log("Analytics error", error);
    }
    
  };
  const listingState = currentListing ? currentListing.attributes.state : null;

  const bookingSubTitle =
    listingState === LISTING_STATE_PUBLISHED && isLiveListing ? (
      intl.formatMessage({ id: 'ListingPage.bookingSubTitle' })
    ) : listingState === LISTING_STATE_PENDING_APPROVAL ? (
      <div>
        {intl.formatMessage({ id: 'ListingPage.bookingSubTitlePending' })}
        <div className={css.pendingNewline}>
          {intl.formatMessage({ id: 'ListingPage.bookingSubTitlePendingLine2' })}
        </div>
      </div>
    ) : listingState === LISTING_STATE_CLOSED ? (
      intl.formatMessage({ id: 'ListingPage.bookingSubTitleClose' })
    ) : listingState === LISTING_STATE_PUBLISHED ? (
      intl.formatMessage({ id: 'ListingPage.listingNotLive' })
    ) : (
      intl.formatMessage({ id: 'ListingPage.bookingSubTitleLoading' })
    );

  const bookingConfig = getBookingConfig(currentListing);

  const onEnableTheMap = () => {
    initiateEventFromListing({
      props: props,
      listing: currentListing,
      event: EVENT_SEARCH_USED_MAP_LISTING,
    });
  };

  const getIsKeyRulesVisible = (keyRules, selectedRules) => {
    if (!selectedKeyRules.length) {
      return false;
    }
    const filteredValues = keyRules.filter(rule => {
      return selectedRules.includes(rule.key);
    });
    return Boolean(filteredValues.length);
  };

  const isLongTermListing = listingIsLongTermRental(currentListing);
  const isKeyRulesVisible = getIsKeyRulesVisible(keyRulesConfig, selectedKeyRules);
  const mileageJsx = (
    <>
      {mileageToShow()}
      <p>
        <a
          target="_blank"
          href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/mileage-policy-iyw2AbpxJ8/Steps/755833`}
        >
          Read full mileage policy
        </a>
      </p>
    </>
  );

  return (
    <Page
      title={schemaTitle}
      author={authorDisplayName}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={ensuredDescription}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: ensuredDescription,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {isDrivelahGo ? (
            <SectionImagesGo
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              handleViewPhotosClick={handleViewPhotosClick}
              imageCarouselOpen={imageCarouselOpen}
              onImageCarouselClose={() => setImageCarouselOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenSharingModal={onOpenSharingModal}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
            />
          ) : (
            <SectionImages
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
              imageCarouselOpen={imageCarouselOpen}
              onImageCarouselClose={handleCloseViewPhotos}
              handleViewPhotosClick={handleViewPhotosClick}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenSharingModal={onOpenSharingModal}
              viewPhotoButtonId={VIEW_PHOTO_BUTTON_ID}
            />
          )}
          <div className={css.contentContainer}>
            <SectionAvatar user={ensuredAuthor} params={params} />
            <div className={css.mainContent}>
              {isDrivelahGo ? (
                <Fragment>
                  <SectionHeadingGo
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    authorDisplayName={authorDisplayName}
                    richTitle={richTitle}
                    showContactUser={showContactUser}
                    onContactUser={onContactUser}
                    currentListing={currentListing}
                    shouldShowPricePerMonth={isLongTermListing && shouldShowPricePerMonth}
                    isLongTerm={isLongTerm}
                    isDisinfected={isDisinfected}
                  />
                  <SectionDescriptionMaybeGo
                    authorDisplayName={authorDisplayName}
                    listing={currentListing}
                  />

                  <SectionInnovative />

                  <SectionFeaturesMaybeGo
                    listing={currentListing}
                    keyFeaturesConfig={keyFeaturesConfig}
                  />
                  {isKeyRulesVisible && (
                    <SectionRulesMaybeGo
                      field={'keyRules'}
                      listing={currentListing}
                      titleId="ListingPageGo.SectionRulesMaybe.title"
                    />
                  )}
                  <SectionRulesMaybeGo
                    title={intl.formatMessage({ id: 'ListingPage.mileageAllowedPerDay' })}
                    field={'millagePerDay'}
                    directValue={mileageJsx}
                    listing={currentListing}
                  />
                  {!currentUser || currentUser &&
                    currentUser.attributes &&
                    currentUser.attributes.profile &&
                    currentUser.attributes.profile.publicData ?
                     <SectionMapMaybeGo
                    currentUser={currentUser}
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    distanceToUser={distanceToUser}
                    onEnableTheMap={onEnableTheMap}
                  /> : ''}

                </Fragment>
              ) : (
                <Fragment>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={categoryVaues}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={onContactUser}
                    currentListing={currentListing}
                    isInstantBookingListing={isInstantBooking}
                    shouldShowPricePerMonth={isLongTermListing && shouldShowPricePerMonth}
                    isLongTerm={isLongTerm}
                    isDisinfected={isDisinfected}
                  />
                  <SectionDescriptionMaybe
                    authorDisplayName={authorDisplayName}
                    description={ensuredDescription}
                    brandName={brandName}
                    transmissions={transmissions}
                    fuelType={fuelType}
                    drivenKm={drivenKm}
                    seats={peopleNumber}
                  />
                  <SectionFeaturesMaybe
                    id={'ListingPage.keyFeatures'}
                    title={'ListingPage.keyFeaturesTitle'}
                    options={keyFeaturesConfig}
                    selectedOptions={selectedFeatures}
                  />
                  {isKeyRulesVisible && (
                    <SectionRulesMaybe
                      title={'ListingPage.keyRulesTitle'}
                      keyRules={keyRulesConfig}
                      selectedOptions={selectedKeyRules}
                    />
                  )}
                    <div className={css.dynamicMileageDisplay}>
                      <p><FormattedMessage id={'ListingPage.mileageAllowedPerDay'} /></p>
                      <p>{mileageToShow()}</p>
                      <p><a
                        target="_blank"
                        href={`https://help.drivemate.au/kb/guide/en/mileage-policy-iyw2AbpxJ8/Steps/755833`}
                      >
                        Read full mileage policy
                      </a></p>
                    </div>
                  {/* Commenting out the below code for dynamic mileage display */}
                  {/* <SectionRulesMaybe
                    title={'ListingPage.mileageAllowedPerDay'}
                    keyRule={mileageToShow()}
                    directValue={mileageJsx}
                    /> */}
                  {/* Commenting out the below code for dynamic mileage display */}
                  {!currentUser || currentUser &&
                    currentUser.attributes &&
                    currentUser.attributes.profile &&
                    currentUser.attributes.profile.publicData  ?
                    <SectionMapMaybe
                    currentUser={currentUser}
                    location={location}
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    distanceToUser={distanceToUser}
                    onEnableTheMap={onEnableTheMap}
                  />: '' }
                </Fragment>
              )}

              <div className={css.mobileWrapper}>
                <InsurancePanelNew
                  onReadInsurance={onReadInsurance}
                  listing={currentListing}
                  showInsurance={true}
                  insuranceType={publicData.insurance}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
                <div className={css.rentalAgreementLink}>
                  <span onClick={() => setIsOpenRentalAgreement(true)}>
                    <FormattedMessage id="BookingDatesForm.rentalAgreementLink" />
                  </span>
                </div>
              </div>

              {isDrivelahGo ? (
                <Fragment>
                  <SectionHostGo
                    timeSlots={timeSlots}
                    initialValues={initialValues}
                    title={title}
                    localTimeZone={localTimeZone}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={onContactUser}
                    isEnquiryModalOpen={isAuthenticated && enquiryModalOpen}
                    onCloseEnquiryModal={() => setEnquiryModalOpen(false)}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <SectionReviewsGo
                    reviews={reviews}
                    intl={intl}
                    fetchReviewsError={fetchReviewsError}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <SectionHostMaybe
                    title={title}
                    localTimeZone={localTimeZone}
                    initialValues={initialValues}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    timeSlots={timeSlots}
                    intl={intl}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={onContactUser}
                    isEnquiryModalOpen={isAuthenticated && enquiryModalOpen}
                    onCloseEnquiryModal={() => setEnquiryModalOpen(() => false)}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    buttonId={ENQUIRY_BUTTON_ID}
                    onViewProfile={onViewProfile}
                    checkedCode={checkedCode}
                    checkCodeInProgress={checkCodeInProgress}
                    checkCodeErorr={checkCodeErorr}
                    onCheckingVoucher={onCheckingVoucher}
                    className={css.bookingPanel}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    subTitle={bookingSubTitle}
                    onResetCode={onResetCode}
                    checkValidateBooking={checkValidateBooking}
                    calculateMobileHeight={() => {}}
                    initialDate={{
                      startDate: newStart,
                      endDate: newEnd,
                      pickupTime: pickupTime,
                      dropoffTime: dropoffTime,
                    }}
                    isLiveListing={isLiveListing}
                    onReadInsurance={onReadInsurance}
                    requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
                    onEstimateBreakdown={onEstimateBreakdown}
                    estimateBreakdownInProgress={estimateBreakdownInProgress}
                    estimatedTx={estimatedTx}
                    onOpenRentalAgreement={() => setIsOpenRentalAgreement(true)}
                    bookingConfig={bookingConfig}
                    estimateError={estimateError}
                    fetchTimeSlotsInProgress={fetchTimeSlotsInProgress}
                    isLongTerm={isLongTermListing}
                    isLongTermBooking={isLongTerm}
                    shouldShowPricePerMonth={isLongTermListing && shouldShowPricePerMonth}
                    formattedPrice={formattedPrice}
                    priceTitle={priceTitle}
                    longTermRental={longTermRental}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                </Fragment>
              )}
            </div>
            {!isAdminDepositListing ? (
              <BookingPanel
                timeSlots={timeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                monthlyTimeSlots={monthlyTimeSlots}
                checkedCode={checkedCode}
                checkCodeInProgress={checkCodeInProgress}
                checkCodeErorr={checkCodeErorr}
                onCheckingVoucher={onCheckingVoucher}
                currentUser={currentUser}
                className={css.bookingPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                unitType={unitType}
                onSubmit={handleBookingSubmit}
                title={bookingTitle}
                subTitle={bookingSubTitle}
                authorDisplayName={authorDisplayName}
                onResetCode={onResetCode}
                onManageDisableScrolling={onManageDisableScrolling}
                checkValidateBooking={checkValidateBooking}
                calculateMobileHeight={() => {}}
                initialDate={{
                  startDate: newStart,
                  endDate: newEnd,
                  pickupTime: pickupTime,
                  dropoffTime: dropoffTime,
                }}
                isLiveListing={isLiveListing}
                onReadInsurance={onReadInsurance}
                requestButtonId={REQUEST_TO_BOOK_BUTTON_ID}
                onEstimateBreakdown={onEstimateBreakdown}
                estimateBreakdownInProgress={estimateBreakdownInProgress}
                estimatedTx={estimatedTx}
                onOpenRentalAgreement={() => setIsOpenRentalAgreement(true)}
                bookingConfig={bookingConfig}
                estimateError={estimateError}
                fetchTimeSlotsInProgress={fetchTimeSlotsInProgress}
                localTimeZone={localTimeZone}
                isLongTerm={isLongTermListing}
                isLongTermBooking={isLongTerm}
                shouldShowPricePerMonth={isLongTermListing && shouldShowPricePerMonth}
                formattedPrice={formattedPrice}
                priceTitle={priceTitle}
                longTermRental={longTermRental}
                setDifferenceInHours={setDifferenceInHours}
              />
            ) : (
              notBookableLabel
            )}
          </div>
          {sameCategoryListings.length > 0 ? (
            <div className={css.contentContainer}>
              <YouMayAlsoLikeCarousel
                listings={sameCategoryListings}
                currentListingId={currentListing.id.uuid}
                intl={intl}
                viewport={viewport}
              />
            </div>
          ) : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <ModalSharingListing
        containerClassName={css.missingInformationModal}
        onManageDisableScrolling={onManageDisableScrolling}
        showShareModal={showShareModal}
        onShareFacebookTimeline={onShareToFacebookTimeline}
        onShareFacebookMessenger={onShareToMessenger}
        onClose={onCloseSharingModal}
        shareableLink={shareableLink}
      />
      <Modal
        id="rentalAgreementModal"
        isOpen={isOpenRentalAgreement}
        onClose={() => setIsOpenRentalAgreement(() => false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <RentalAgreement />
      </Modal>
    </Page>
  );
}, isEqual);

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  timeSlots: [],
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
  keyFeaturesConfig: config.custom.keyFeatures,
  keyRulesConfig: config.custom.keyRules,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    distanceToUser,
    timeSlots,
    estimateBreakdownInProgress,
    estimatedTx,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    sameCategoryListings,
    estimateError,
    monthlyTimeSlots,
    fetchTimeSlotsInProgress,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const { pagination, searchParams } = state.SearchPage;
  const getListing = getListingFromState(state);
  const getOwnListing = getOwnListingFromState(state);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    distanceToUser,
    timeSlots,
    monthlyTimeSlots,
    estimateBreakdownInProgress,
    estimatedTx,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    sameCategoryListings,
    estimateError,
    fetchTimeSlotsInProgress,
    pagination,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  getDistanceToUser: (listingLocation, userLocation) =>
    dispatch(getDistanceToUser(listingLocation, userLocation)),
  onSendEnquiry: (listingId, message,bookingStartDate,bookingEndDate,bookingStartTime,bookingEndTime, query,localTimeZone) => dispatch(sendEnquiry(listingId, message, bookingStartDate,bookingEndDate,bookingStartTime,bookingEndTime,query,localTimeZone)),
  onOpenMissingInfomationModal: status => dispatch(openMissingInfomationModal(status)),
  onCheckingVoucher: ({ code, valueId, data }) => dispatch(checkCode({ code, valueId, data })),
  onResetCode: () => dispatch(resetCode()),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
});

const ListingPage = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(ListingPageComponent);

ListingPage.loadData = loadData;

export default ListingPage;