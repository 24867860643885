import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_ENQUIRE,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsDroppedOff,
  txIsCanceled,
  txIsDelivered,
  txIsCompleted,
  txIsReviewed,
  txWasPickedUp,
  txIsDeclined,
  txIsExpired
} from '../../util/transaction';
import { createResourceLocatorString } from '../../util/routes';
import { txLastTransition } from '../../util/transactionLongTermFirst';
import routeConfiguration from '../../routeConfiguration';
import css from './TripPanel.css';
import editIcon from '../../assets/tripPage/edit-regular.svg';
import dollarIcon from '../../assets/tripPage/trip-cost.svg';
import contractIcon from '../../assets/tripPage/policies.svg';
import helpIcon from '../../assets/tripPage/help.svg';
import beginnersIcon from '../../assets/tripPage/beginner.svg';
import cancelIcon from '../../assets/tripPage/cancel-trip.svg';

const ImportantLinksSection = ({
  history,
  isCustomer,
  isAccepted,
  onOpenCancelModal,
  onOpenCancelHelpModal,
  isShowCancelLinkCustomer,
  isShowAddonsLinkCustomer,
  transactionId,
  transaction,
  onEditTrip,
  onPoliciesClick,
  isBookingCompleted
}) => {
  console.log(
    "Data coming for unverfied guest", {
      history,
      isCustomer,
      isAccepted,
      onOpenCancelModal,
      onOpenCancelHelpModal,
      isShowCancelLinkCustomer,
      isShowAddonsLinkCustomer,
      transactionId,
      transaction,
      onEditTrip,
     onPoliciesClick,

    }
  )
  const lastTransition = txLastTransition(transaction);
  const tripWasAccepted = transaction &&
    transaction.attributes &&
    transaction.attributes.transitions
      .map(t =>
        t.transition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
        t.transition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR ||
        t.transition === TRANSITION_ACCEPT ||
        t.transition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
        t.transition === TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED ||
        t.transition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT ||
        t.transition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED
      )
      .includes(true)
  ;
  const isEditLinkVisible = tripWasAccepted &&
    !txIsAwaitUpdateBookingBeforePickUp(transaction) &&
    !txIsAwaitUpdateBookingBeforeDropOff(transaction) &&
    !txIsBeingPickedUp(transaction) &&
    !txIsBeingDropOff(transaction) &&
    !(lastTransition === TRANSITION_ENQUIRE) &&
    !txIsCanceled(transaction) &&
    !txIsDelivered(transaction) &&
    !txIsCompleted(transaction) &&
    !txIsDeclined(transaction) &&
    !txIsExpired(transaction) &&
    !txIsReviewed(transaction) &&
    !txIsDroppedOff(transaction) &&
    !isBookingCompleted
  ;

  const isPickedUpTx = txWasPickedUp(transaction);

  const listOfLinks = isCustomer
    ? [
        // Hid addons functional
        // {
        //   id: 1,
        //   img: addOnsIcon,
        //   title: <FormattedMessage id="TransactionPanel.addOnsLink" />,
        //   path: 'AddOnsPage',
        //   show: isShowAddonsLinkCustomer,
        //   redirect: true,
        // },
        ...(isPickedUpTx ? [] : [{
          id: 2,
          img: editIcon,
          title: isPickedUpTx
            ? <FormattedMessage id="TransactionPanel.editLinkPickedUp" />
            : <FormattedMessage id="TransactionPanel.editLink" />,
          path: 'EditTripPage',
          show: isEditLinkVisible,
          redirect: true,
        }]),
        {
          id: 3,
          img: dollarIcon,
          title: <FormattedMessage id="TransactionPanel.tripCostLink" />,
          path: 'TripDetailsPage',
          show:  lastTransition === TRANSITION_ENQUIRE  ? false : true,
          redirect: true,
        },
        {
          id: 4,
          img: contractIcon,
          title: <FormattedMessage id="TransactionPanel.policiesLink" />,
          path: 'PoliciesPage',
          show: lastTransition === TRANSITION_ENQUIRE  ? false : true,
          redirect: true,
        },
        {
          id: 5,
          img: helpIcon,
          title: <FormattedMessage id="TransactionPanel.helpLink" />,
          path: '/',
          isModal: true,
          isCancel: false,
          show: true,
        },
        {
          id: 6,
          img: beginnersIcon,
          title: <FormattedMessage id="TransactionPanel.beginLink" />,
          path: 'BeginnerResourcesPage',
          show:  lastTransition === TRANSITION_ENQUIRE  ? false : true,
          redirect: true,
        },
        {
          id: 7,
          img: cancelIcon,
          title: <FormattedMessage id="TransactionPanel.cancelTrip" />,
          path: '/',
          isCancel: true,
          show: lastTransition === TRANSITION_ENQUIRE  ? false : isShowCancelLinkCustomer,
        },
        // {
        //   id: 7,
        //   img: cancelIcon,
        //   title: <FormattedMessage id="TransactionPanel.withdrawn" />,
        //   path: '/',
        //   isCancel: true,
        //   show:  lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||  lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED ? isShowWithdrawLinkToCustomer : false,
        // },
      ]
    : [
        // {
        //   id: 1,
        //   img: dollarIcon,
        //   title: <FormattedMessage id="TransactionPanel.tripDetailsLink" />,
        //   path: 'TripDetailsPage',
        //   show: true,
        //   redirect: true,
        // },
        {
          id: 2,
          img: contractIcon,
          title: <FormattedMessage id="TransactionPanel.policiesLink" />,
          path: 'PoliciesPage',
          show:  lastTransition === TRANSITION_ENQUIRE  ? false : true,
          redirect: true,
        },
        {
          id: 3,
          img: helpIcon,
          title: <FormattedMessage id="TransactionPanel.helpLink" />,
          path: '#',
          isModal: true,
          isCancel: false,
          show: true,
        },
        {
          id: 4,
          img: beginnersIcon,
          title: <FormattedMessage id="TransactionPanel.beginLink" />,
          path: 'BeginnerResourcesPage',
          show:  lastTransition === TRANSITION_ENQUIRE  ? false : true,
          redirect: true,
        },
        {
          id: 5,
          img: cancelIcon,
          title: <FormattedMessage id="TransactionPanel.cancelTrip" />,
          path: '#',
          isCancel: true,
          show:  lastTransition === TRANSITION_ENQUIRE  ? false : isAccepted,
        },
      ];

  const clickLink = (path, redirect) => {
    const routes = routeConfiguration();
    history.push(createResourceLocatorString(path, routes, { id: transactionId}, {}), {endDate: transaction.booking.attributes.displayEnd});
  };

  console.log("Links of link", listOfLinks);

  return (
    <div className={css.importantLinksContainer}>
      <h3 className={css.importantLinksTitle}>
        <FormattedMessage id="TransactionPanel.importantLinksTitle" />
      </h3>
      {listOfLinks.map(link => {
        return link.show ? (
          <div
            key={link.id}
            className={css.linkContainer}
            onClick={() => (
              link.isModal ? onOpenCancelHelpModal()
                : link.isCancel ? onOpenCancelModal()
                : link.edit ? onEditTrip()
                : link.policies ? onPoliciesClick()
                : clickLink(link.path, link.redirect))}
          >
            <div className={css.imgTextContainer}>
              <div className={css.imgLinkContainer}>
                <img src={link.img} alt="link Icon" className={css.imgLink} />
              </div>
              <p className={css.linkTitle}>{link.title}</p>
            </div>
            <div className={css.arrowLink}></div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default ImportantLinksSection;
