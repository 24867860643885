// ================ Action types ================ //
import { fetchCurrentUser } from '../../ducks/user.duck';

const SAVE_BACKGROUND_CONSENT_REQUEST = 'app/AddressDetailsPage/SAVE_BACKGROUND_CONSENT_REQUEST';
const SAVE_BACKGROUND_CONSENT_SUCCESS = 'app/AddressDetailsPage/SAVE_BACKGROUND_CONSENT_SUCCESS';
const SAVE_BACKGROUND_CONSENT_ERROR = 'app/AddressDetailsPage/SAVE_BACKGROUND_CONSENT_ERROR';

// ================ Reducer ================ //

export const initialState = {
  saveBackgroundConsentSuccess: null,
  saveBackgroundConsentError: null,
  saveBackgroundConsentInProgress: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_BACKGROUND_CONSENT_REQUEST:
      return {
        ...state,
        saveBackgroundConsentSuccess: false,
        saveBackgroundConsentError: false,
        saveBackgroundConsentInProgress: true,
      };
    case SAVE_BACKGROUND_CONSENT_SUCCESS:
      return { ...state, saveBackgroundConsentSuccess: true, saveBackgroundConsentInProgress: false };
    case SAVE_BACKGROUND_CONSENT_ERROR:
      return { ...state, saveBackgroundConsentError: payload, saveBackgroundConsentInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestSaveBackgroundConsent = () => ({
  type: SAVE_BACKGROUND_CONSENT_REQUEST,
});

const saveBackgroundConsentSuccess = () => ({
  type: SAVE_BACKGROUND_CONSENT_SUCCESS,
});

const saveBackgroundConsentError = e => ({
  type: SAVE_BACKGROUND_CONSENT_REQUEST,
  payload: e,
});

export const saveBackgroundConsent = params => (dispatch, getState, sdk) => {
  dispatch(requestSaveBackgroundConsent());
  const { backgroundCheckConsentAccepted, timeTakenInSeconds } = params;
  const protectedData = {};
  protectedData.backgroundCheckConsent = {
    status: backgroundCheckConsentAccepted || false,
    timeTaken: timeTakenInSeconds
  };

  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(response => {
      dispatch(saveBackgroundConsentSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(error => {
      dispatch(saveBackgroundConsentError());
    });
};
