import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import {
  fetchCurrentUser,
  initCurrentUserCalender,
  revokeRefreshToken,
  updateRefreshToken,
} from '../../ducks/user.duck';
//import { savePersonalDetails } from './SyncCalenderPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import googleLogo from '../../assets/google_logo.png';
import {
  Footer,
  IconClose,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { SyncCalenderForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './SyncCalenderPage.css';
import queryString from 'query-string';
//import { apiBaseUrl } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { configGoogle } from '../../util/googleCalendar';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { useHistory } from 'react-router';

const googleCalendar = configGoogle({
  scopes: process.env.REACT_APP_GOOGLE_CALENDER_SCOPES && process.env.REACT_APP_GOOGLE_CALENDER_SCOPES.split(",").filter(Boolean),
  clientId: process.env.REACT_APP_GOOGLE_CALENDER_CLIENT_ID,
  clientSecret: process.env.REACT_APP_GOOGLE_CALENDER_CLIENT_SECRET,
});
const GOOGLE_CALENDER_REDIRECT_PATH = process.env.REACT_APP_GOOGLE_CALENDER_REDIRECT_URI;

const { LatLng } = sdkTypes;

export const SyncCalenderPageComponent = props => {
  const {
    currentUser,
    currentUserCalender,
    scrollingDisabled,
    intl,
    onSubmitAddressDetailsForm,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,

    connectCalendarTabVisibility,
    fetchCalendarVisibility,
  } = props;

  const history = useHistory();

  useEffect(() => {
    const needAsync = async () => {
      const result = await fetchCalendarVisibility();
      const routes = routeConfiguration();
      if(!result){
        history.push(
        createResourceLocatorString(
        'AccountSettingsPage',
        routes));
      }
    }
    needAsync();
  }, [])

  let tabs = [...config.custom.tabs];
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (!(ensuredCurrentUser && ensureHostVerified(ensuredCurrentUser))) {
      if (tab.linkProps.name === "MonthlyStatementPage") {
        return false;
      }
    }
    if (tab.linkProps.name === "SyncCalenderPage" ){
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  })
  const currentTab = tabs.find((tab) => tab.linkProps.name === "SyncCalenderPage")
    if (currentTab){
      currentTab.selected = true;
  }

  const [googleAuthLoader , setGoogleAuthLoader] = useState(false);
  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const { dateOfBirth } = protectedData;
  const initialValues = {
    dateOfBirth,
  };

  const addressInfoForm = ensuredCurrentUser.id ? (
    <SyncCalenderForm
      className={css.form}
      initialValues={initialValues}
      currentUser={currentUser}
      inProgress={saveAddressDetailsInProgress}
      ready={saveAddressDetailsSuccess}
      saveAddressDetailsError={saveAddressDetailsError}
      onSubmit={values => {
        const { dateOfBirth } = values;
        const updatedValues = {
          dateOfBirth,
        };
        onSubmitAddressDetailsForm(updatedValues);
      }}
    />
  ) : null;

  const getDefaultRoutes = () => {
    const baseUrl = process.env.REACT_APP_GOOGLE_CALENDER_OAUTH_URI;
    const redirectUri = GOOGLE_CALENDER_REDIRECT_PATH;
    return googleCalendar.getAuthCodeUri({
      authCodeUri: baseUrl,
      redirectUri: redirectUri,
    });
    // const routes = routeConfiguration();
    // const baseUrl = apiBaseUrl();

    // // Route where the user should be returned after authentication
    // // This is used e.g. with EditListingPage and ListingPage
    // const fromParam = true ? `from=${"/account/sync-details"}` : '';

    // // Default route where user is returned after successfull authentication
    // const defaultReturn = pathByRouteName('LandingPage', routes);
    // const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // // Route for confirming user data before creating a new user
    // const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    // const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    // return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithGoogle = () => {
    const url = getDefaultRoutes();
    //const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    console.log(9555, url);
    window.location.href = url;

    //`${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const disconnectGoogle = async () => {
    const { revokeRefreshToken, refetchUser } = props;
    setGoogleAuthLoader(true)
    await revokeRefreshToken()
    setGoogleAuthLoader(false)
  }

  const setRefreshToken = async (email, refreshToken) => {
    await props.updateRefreshToken(email, refreshToken);
    // const currentUser = await props.getCurrentUserLight()
    // console.log(currentUser.data.id.uuid , 125555)
    // if (currentUser.id){

    //   // save parameters to the flex
    // }
  };

  useEffect(() => {
    const needAsync = async () => {
      const qs = window.location.search;
      setGoogleAuthLoader(true)
      if (qs) {
        const authObj = queryString.parse(qs);
        const refreshTokenData = await googleCalendar.getRefreshToken({
          tokenUri: process.env.REACT_APP_GOOGLE_CALENDER_REFRESH_TOKEN_URI,
          code: authObj.code,
          redirectUri:GOOGLE_CALENDER_REDIRECT_PATH,
        });

        if (refreshTokenData) {
          const { access_token, refresh_token } = refreshTokenData;
          if (refresh_token) {
            const userDetails = await googleCalendar.getUserDetails({
              userUri: process.env.REACT_APP_GOOGLE_CALENDER_USER_INFO,
              accessToken: access_token,
            });

            if (userDetails) {
              await setRefreshToken(userDetails.email ,refresh_token);
              window.location.search = ""
            }
          }
        }
      }else{
        await props.initCurrentUserCalender()
      }
      setGoogleAuthLoader(false)
    };

    needAsync();
  }, []);

  const isGoogleCalenderConnected = calender => {
    return (
      calender && Boolean(calender.email)
    );
  };

  const title = intl.formatMessage({ id: 'SyncCalendarPage.title' });
  const btnText = intl.formatMessage({ id: 'SyncCalendarForm.btnText' });
  const statusText = intl.formatMessage({ id: 'SyncCalendarForm.statusText' });
  const connectedText = intl.formatMessage({ id: 'SyncCalendarForm.connectedText' });

  const googleConnectedStatus = isGoogleCalenderConnected(currentUserCalender);
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} className={css.root}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="SyncCalenderPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="SyncCalenderPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={ensuredCurrentUser} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
               <FormattedMessage id="SyncCalendarPage.title" />
            </h1>
            <div>
            <div className={css.loginUsing}>
              <div>
                <p className={css.infoText}>
                {intl.formatMessage({
                id: "SyncCalendarForm.infoText" })}
                </p>
                <div className={css.calenderSyncContainer}>
                  <div className={css.googleLogoContainer}>
                    <img src={googleLogo} alt={'google logo'} className={css.googleLogo} />
                  </div>
                  {googleAuthLoader ? (
                      <div className={css.loader} />)
                  : (
                    <>
                    {!googleConnectedStatus ? (
                    <a
                      className={css.btnText}
                      onClick={e => {
                        e.preventDefault(); authWithGoogle();
                      }}
                    >
                      {btnText}
                    </a>
                  ) : (
                    <>
                      <div className={css.statusContainer}>
                        <div class={css.emailText}>{currentUserCalender.email}</div>
                        <div>
                          {statusText}: <span class={css.statusText}>{connectedText}</span>
                        </div>
                      </div>
                      <div className={css.disconnect} onClick={disconnectGoogle} title={"Disconnect"}>
                        <IconClose className={css.crossIcon} />
                      </div>
                    </>
                  )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

SyncCalenderPageComponent.defaultProps = {
  currentUser: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,
};

const { bool } = PropTypes;

SyncCalenderPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser, currentUserCalender } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels
  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  } = state.AddressDetailsPage;
  return {
    currentUser,
    currentUserCalender,
    scrollingDisabled: isScrollingDisabled(state),
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
 // onSubmitAddressDetailsForm: values => dispatch(savePersonalDetails(values)),
  updateRefreshToken: (email, refreshToken) => dispatch(updateRefreshToken(email, refreshToken)),
  revokeRefreshToken: () => dispatch(revokeRefreshToken()),
  initCurrentUserCalender : () => dispatch(initCurrentUserCalender()),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus())
});

const SyncCalenderPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SyncCalenderPageComponent);

SyncCalenderPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
    return fetchCurrentUser();
};

export default SyncCalenderPage;
