import React, { Component } from 'react';
import { array, bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '..';

import css from './ModalCancel.css';
import {
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_WITHDRAW,
} from '../../util/transaction';
import { getTransitErrorLabelId } from '../TransactionPanel/transitionHelper';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import config from '../../config';
import SelectForm from './SelectForm';

const getCancelNotice = ({ isLongTerm, isProvider, currentChildTransaction }) => {
  const cancelNoticeLongTermMiddle =
    'If you decide to cancel immediately and not give 7 day notice you will be charged for the entire month. Read the cancellation policy for further clarification.';
  const cancelNoticeLongTermFirst =
    'You will be charged based on the daily price and the balance will be refunded/charged to your card. Read the cancellation policy for further clarification.';
  const cancelNoticeHostLongTerm =
    'You will be charged for the cancellation as per the cancellation policy, please read before proceeding';
  const cancelNoticeNormal =
    'Do note that you may be charged for this cancellation depending upon the time of booking & cancellation';

  if (isLongTerm && isProvider) {
    return cancelNoticeHostLongTerm;
  } else if (currentChildTransaction) {
    return cancelNoticeLongTermMiddle;
  } else if (isLongTerm) {
    return cancelNoticeLongTermFirst;
  } else {
    return cancelNoticeNormal;
  }
};

const CancelButtonsMaybe = props => {
  const {
    canShowButtons,
    transaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    onClose,
    selectedOption,
    onCancelTripGTM,
    cancelButtonId,
    currentChildTransaction,
    onFetchCancelUpdateBooking,
    isCustomer,
    isProvider,
    onCancelAllUpdateBookings,
  } = props;

  const buttonsDisabled = transitInProgress || !selectedOption;
  const validTransaction = transaction && transaction.customer;
  const transitAllow =
    validTransaction ? true: false;
  console.log("Button Disabled", buttonsDisabled);
  // const guestIdentityIsNotVerify = !transitAllow ? (
  //   <p className={css.actionError}>
  //     <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
  //   </p>
  // ) : null;

  // const transitErrorMessage = transitError ? (
  //   <p className={css.actionError}>
  //     <FormattedMessage id={getTransitErrorLabelId(transitionNames[0])} />
  //   </p>
  // ) : null;

  const doNotCancelText = 'Back';
  const cancelText = currentChildTransaction ? 'give notice' : 'Cancel my trip';
  console.log("FINAL BUTTON VERDICT", buttonsDisabled, !transitAllow,buttonsDisabled || !transitAllow);
  return canShowButtons ? (
    <div className={css.buttonContainer}>
      {/* {guestIdentityIsNotVerify ||
        (transitErrorMessage && (
          <div className={css.actionErrors}>
            {guestIdentityIsNotVerify}
            {transitErrorMessage}
          </div>
        ))} */}
      <PrimaryButton
        className={css.doNotCancelButton}
        inProgress={transitInProgress}
        disabled={false}
        onClick={onClose}
      >
        {doNotCancelText}
      </PrimaryButton>
      <SecondaryButton
        className={css.cancelButton}
        id={cancelButtonId}
        inProgress={transitInProgress}
        disabled={buttonsDisabled || !transitAllow}
        onClick={() => {
          const cancelData = {
            cancelReason: selectedOption,
            cancelType: 'cancel',
            currentTransaction: transaction,
          };
          if (transitAllow) {
            onTransit(transaction.id, transitionNames[0], cancelData).then(() => {
              onCancelAllUpdateBookings(transaction.id.uuid)
                .finally(() => {
                  onCancelTripGTM();
                  onClose();
                })
              ;
            });
          }
        }}
      >
        {cancelText}
      </SecondaryButton>
    </div>
  ) : null;
};

class ModalCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false,
      selectedOption: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = value => {
    this.setState({ selectedOption: value.reasonSelected });
  };

  render() {
    const {
      cancelAllUpdateBookingTxsInProgress,
      rootClassName,
      className,
      containerClassName,
      onManageDisableScrolling,
      showCancelModal,
      onClose,
      isCustomer,
      isProvider,
      transitError,
      onTransit,
      openCancelPolicyModal,
      transitionNames,
      transaction,
      transitInProgress,
      isInstantBooking,
      onCancelTripGTM,
      cancelButtonId,
      isAccepted,
      isLongTerm,
      currentChildTransaction,
      onFetchCancelUpdateBooking,
      onCancelAllUpdateBookings,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    // const cancelNotice = getCancelNotice({ isLongTerm, isProvider, currentChildTransaction });
    // const estimateRefund = (
    //   <EstimatedRefundMaybe transaction={transaction} isCustomer={isCustomer} />
    // );

    const validTransaction = transaction && transaction.customer;
    const transitAllow = validTransaction ? true: false;

    console.log("HOST ", transaction)
    console.log("TransiT ALLOW", transitAllow, validTransaction, transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus);
    console.log("Transition Name", transitionNames);
    const guestIdentityIsNotVerify = !transitAllow ? (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
      </p>
    ) : null;

    const transitErrorMessage = transitError ? (
      <p className={css.actionError}>
        <FormattedMessage id={getTransitErrorLabelId(transitionNames[0])} />
      </p>
    ) : null;

    const guestReasons =
      transitionNames[0] === TRANSITION_WITHDRAW || transitionNames[0] === TRANSITION_UNVERIFIED_WITHDRAW ||  transitionNames[0] === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT
        ? config.custom.guestWithdrawnReasons
        : config.custom.guestCancelReasons;
    console.log("Guest Resonse", guestReasons);
    const customerContent = (
      <div>
        <h2 className={css.modalTitle}>Cancel your trip?</h2>
        <p className={css.modalText}>
          We understand that sometimes plans change and you need to cancel your trip. Do note that
          you may be charged for this cancellation depending upon the time of booking &
          cancellation. Read our{' '}
          <span onClick={openCancelPolicyModal} className={css.openPolicy}>
            cancellation policy
          </span>{' '}
          for more information.
        </p>

        <SelectForm
          className={css.form}
          name="reasonsForm"
          onChange={this.onChange}
          reasons={guestReasons}
          onSubmit={() => {}}
        />

        <CancelButtonsMaybe
          rootClassName={classes}
          canShowButtons={true}
          transaction={transaction}
          transitInProgress={transitInProgress || cancelAllUpdateBookingTxsInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={transitionNames}
          openCancelPolicyModal={openCancelPolicyModal}
          onClose={onClose}
          selectedOption={this.state.selectedOption}
          onCancelTripGTM={() => onCancelTripGTM(isAccepted)}
          cancelButtonId={cancelButtonId}
          isCustomer={isCustomer}
          isProvider={isProvider}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
          onCancelAllUpdateBookings={onCancelAllUpdateBookings}
        />

        {guestIdentityIsNotVerify ||
          (transitErrorMessage && (
            <div className={css.actionErrors}>
              {guestIdentityIsNotVerify}
              {transitErrorMessage}
            </div>
          ))}
      </div>
    );

    const providerContent = (
      <div>
        <h2 className={css.modalTitle}>Cancel your trip?</h2>
        <p className={css.modalText}>
          We understand that sometimes plans change and you need to cancel your trip. Do note that
          you may be charged for this cancellation depending upon the time of booking &
          cancellation. Read our{' '}
          <span onClick={openCancelPolicyModal} className={css.openPolicy}>
            cancellation policy
          </span>{' '}
          for more information.
        </p>

        <SelectForm
          className={css.form}
          name="reasonsForm"
          onChange={this.onChange}
          reasons={config.custom.hostCancelReasons}
          onSubmit={() => {}}
        />

        <CancelButtonsMaybe
          rootClassName={classes}
          canShowButtons={true}
          transaction={transaction}
          transitInProgress={transitInProgress || cancelAllUpdateBookingTxsInProgress}
          transitError={transitError}
          onTransit={onTransit}
          selectedOption={this.state.selectedOption}
          transitionNames={transitionNames}
          openCancelPolicyModal={openCancelPolicyModal}
          onClose={onClose}
          onCancelTripGTM={() => onCancelTripGTM(isAccepted)}
          cancelButtonId={cancelButtonId}
          isCustomer={isCustomer}
          isProvider={isProvider}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
          onCancelAllUpdateBookings={onCancelAllUpdateBookings}
        />
      </div>
    );

    const content = isCustomer ? customerContent : providerContent;

    const closeButtonMessage = <FormattedMessage id="ModalCancel.close" />;

    return (
      <Modal
        id="ModalCancel"
        containerClassName={containerClassName}
        isOpen={showCancelModal}
        disableCloseBtn={false}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        forceClose={false}
        closeTextClassName={css.hide}
      >
        {content}
      </Modal>
    );
  }
}

ModalCancel.defaultProps = {
  className: null,
  rootClassName: null,
  forceClose: false,
  isInstantBooking: false,
};

ModalCancel.propTypes = {
  className: string,
  rootClassName: string,
  containerClassName: string,
  isCustomer: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onClose: func.isRequired,
  showCancelModal: bool.isRequired,
  transitInProgress: bool.isRequired,
  onTransit: func.isRequired,
  transitionNames: array.isRequired,
  openCancelPolicyModal: func.isRequired,
  isInstantBooking: bool,
  isAccepted: bool,
  cancelAllUpdateBookingTxsInProgress: bool,
};

ModalCancel.displayName = 'ModalCancel';

export default ModalCancel;
