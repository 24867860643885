import React from 'react';
import classNames from 'classnames';
import css from './HostPromoLandingPage.css';
import arrowRight from '../../assets/hostLanding/next.png';
import { HostSignUpPromoForm } from '../../forms';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import { IconSpinner } from '../../components';

const { Money } = types;

const CheckMark = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M16 8a8 8 0 11-8-8 8 8 0 018 8zm-8.925 4.236L13.01 6.3a.516.516 0 000-.73l-.73-.73a.516.516 0 00-.73 0L6.71 9.681l-2.26-2.26a.516.516 0 00-.73 0l-.73.73a.516.516 0 000 .73l3.355 3.355a.516.516 0 00.73 0z"
        fill="#ffcd05"
      />
    </svg>
  );
};

const Content = ({ className }) => {
  return (
    <div className={classNames(css.heroContent, className)}>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          Better Earnings – Drive mate hosts make on average 20% higher earnings compared to other
          car sharing apps.
        </div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          Hosts Keep More - We’ll only take about half the cut of similar platforms.
        </div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          You’re Fully-Covered – Worry-free renting knowing your car’s comprehensively insured by a
          trusted, Australian-based insurance group. Existing policies and no-claim bonuses are also
          never affected.{' '}
        </div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          Exclusive Keyless In-app Technology - No more lockboxes and accessibility hassles with
          remote pick up confirmation, immobilisation features and advanced telemetry data
          monitoring.
        </div>
      </div>
      <div className={css.row}>
        <CheckMark />
        <div className={css.heroContentText}>
          24/7 Support Team - We understand the unpredictable occurs at any time. Enjoy better peace
          of mind knowing you can count on us to assist when needed, not just during business hours.
        </div>
      </div>
    </div>
  );
};

const HostSectionHero = ({ isScoot, signUpCodeAmount, loading, getToCalculatorSection }) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
            state: {
              email,
              password,
              code,
            },
          }
        : {
            pathname: url,
            state: {
              email,
              password,
            },
          }
    );
  };

  return (
    <div className={css.sectionHero}>
      <div className={css.landingContainer}>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          {/* <div className={css.column12}>
            <h1 className={css.heroTitle}>Rent your neighbour’s car</h1>
            <Content className={css.heroContentMobile} />
          </div> */}
          <div className={css.column6}>
            <div className={css.bannerContent}>
              <h1 className={classNames(css.heroTitle, css.heroTitleDesk)}>
                Bring your car <br />
                over to us!
              </h1>
              <p>
                Already familiar with car sharing? Why not have your car earning cash on multiple
                platforms?
              </p>
              <p>
                Make sharing your car really worth its while earning limitless passive income across
                all of your favourite networks – Drive mate offers a range of incredibly dynamic and
                industry-first features providing the most safe and comprehensive car sharing app in
                Australia!
              </p>
              <Content />
              <span className={css.calcTargetButton} onClick={getToCalculatorSection}>
                Calculate your potential earnings{' '}
                <span>
                  <img src={arrowRight} alt="" />
                </span>
              </span>
            </div>
          </div>
          <div className={css.column6}>
            <div className={css.landingFormWrapper}>
              {loading ? (
                <div className={css.loadingWrapper}>
                  <IconSpinner className={css.loadingSpinner} />
                </div>
              ) : currentUser && currentUser.id ? (
                <>
                  <div className={css.signUpTitle}>
                    {signUpCodeAmount && signUpCodeAmount > 0 ? (
                      <FormattedMessage
                        id={
                          isScoot
                            ? 'HostSignUpPromoLandingPage.scootSchemaTitle'
                            : 'HostSignUpPromoLandingPage.schemaTitle'
                        }
                        values={{
                          amount: signUpCodeAmount
                            ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                            : '$0',
                        }}
                      />
                    ) : (
                      <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
                    )}
                  </div>

                  <p className={css.loginDesc}>
                    This promo is only available to new users. You will have to logout and create a
                    new account.
                  </p>
                </>
              ) : (
                <>
                  <div className={css.signUpTitle}>
                    {signUpCodeAmount && signUpCodeAmount > 0 ? (
                      <FormattedMessage
                        id={
                          isScoot
                            ? 'HostSignUpPromoLandingPage.scootSchemaTitle'
                            : 'HostSignUpPromoLandingPage.schemaTitle'
                        }
                        values={{
                          amount: signUpCodeAmount
                            ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                            : '$0',
                        }}
                      />
                    ) : (
                      <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
                    )}
                  </div>
                  <HostSignUpPromoForm onSubmit={handleSubmit} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostSectionHero;
