import React, { memo } from 'react';
import css from './InstantBookingLandingPage.css';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { injectIntl } from 'react-intl';
import { TopbarContainer } from '..';
import { SectionHero } from './SectionHero';
import { SectionInstantBooking } from './SectionInstantBooking';
import { SectionBrowseCar } from './SectionBrowseCar';
import { loadData } from './InstantBookingLandingPage.duck';
import { compose } from 'redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { SectionInstantBookingPolicies } from './SectionInstantBookingPolicies';

const InstantBookingLandingPageComponent = memo(
  ({ intl, listings, fetchError, fetchInProgress }) => {
    const siteTitle = config.siteTitle;
    const schemaTitle = "Book instantly. Make instant bookings with the largest online car rental in Australia | Drive Mate";
    const schemaDescription = "Lock-in car reservations in seconds with cars that exclusively offer instant booking. Make faster, confirmed bookings with Drive Mate, the largest online car rental in Australia";
    return (
      <Page
        className={css.root}
        scrollingDisabled={false}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {/* <h1 style={{ position: 'absolute', left: -999999, opacity: 0 }}>
              <FormattedMessage id="InstantBookingPage.header1" />
            </h1> */}
            <SectionHero intl={intl} />
            <SectionInstantBooking intl={intl} />
            <SectionBrowseCar intl={intl} listings={listings} />
            <SectionInstantBookingPolicies />
            <InsuranceDisclaimer />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>

      </Page>
    )
  },
  (prev, next) => isEqual(
    pick(prev, ['listings', 'fetchInProgress', 'fetchError']),
    pick(next, ['listings', 'fetchInProgress', 'fetchError'])
  )
)

const mapStateToProps = (state) => {
  const {
    listingRefs,
    fetchInProgress,
    fetchError
  } = state.InstantBookingLandingPage;
  const listings = getMarketplaceEntities(state, listingRefs);
  return {
    fetchInProgress,
    fetchError,
    listings
  }
}

const InstantBookingLandingPage = compose(
  injectIntl,
  connect(mapStateToProps)
)(InstantBookingLandingPageComponent);

InstantBookingLandingPage.loadData = loadData;

export default InstantBookingLandingPage;
