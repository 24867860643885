import React, { useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, FieldToggleSwitch, Form } from '../../components';
import css from './EditListingNextLevelForm.css';

export const EditListingNextLevelFormComponent = props => {
  const submitRef = useRef(null);
  const submittedRef = useRef(false);
  useEffect(() => {
    if (
      props.shouldSubmit &&
      submitRef.current &&
      !props.updateInProgress &&
      !submittedRef.current
    ) {
      setTimeout(() => {
        submitRef.current.click();
        submittedRef.current = true;
      }, 1000);
    }
  }, [props.shouldSubmit, submitRef.current, props.updateInProgress, submittedRef.current]);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          submitButtonId,
          onChangeInstantBooking,
        } = formRenderProps;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12)}>
                {updateListingError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingNextLevelForm.updateFailed" />
                  </p>
                ) : null}
                {showListingsError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EditListingNextLevelForm.showListingFailed" />
                  </p>
                ) : null}
              </div>
              <div className={classNames(css.column, css.column12)}>
                <div className={classNames(css.customToggleInput, css.field)}>
                  <div className={css.toggleLabels}>
                    <label htmlFor="instantBooking">Instant booking</label>
                  </div>
                  <div className={css.toggleInput}>
                    <FieldToggleSwitch
                      id="instantBooking"
                      name="instantBooking"
                      // label="Instant booking"
                      value="allowInstantBooking"
                      labelClassname={css.biggerTextName}
                      onChangeInstantBooking={onChangeInstantBooking}
                    />
                  </div>
                  <div className={css.inlineToggleInputWithText}>
                    <div className={css.inputWithText}>(Recommended ON for higher earnings)</div>
                  </div>
                </div>
              </div>
              <div className={classNames(css.column, css.column12)}>
                <div className={css.moreDesc}>
                  <FormattedMessage id="EditListingNextLevelForm.moreDescriptionNew" />
                </div>
                <div className={classNames(css.moreDescSmall, css.moreDescSmallDarker)}>
                  <FormattedMessage id="EditListingNextLevelForm.moreDescriptionSmallerPrintUpdated" />
                </div>
              </div>
            </div>

            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}></div>
                <div className={css.stickButton}>
                  <Button
                    buttonRef={submitRef}
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                    id={submitButtonId}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingNextLevelFormComponent.defaultProps = { fetchErrors: null };

EditListingNextLevelFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
  onChangeInstantBooking: func,
};

export default compose(injectIntl)(EditListingNextLevelFormComponent);
