import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import { FormattedMessage } from '../../util/reactIntl';
import memoize from 'lodash/memoize';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
  ensureAvailabilityException,
  ensureBooking,
  ensureDayAvailabilityPlan,
} from '../../util/data';
import { DAYS_OF_WEEK, propTypes } from '../../util/types';
import {
  getDefaultTimeZoneOnBrowser,
  isInPartialRange,
  isInRange,
  isSameDate,
  momentTimeOfDayFromLocalToTimeZone,
  monthIdString,
  monthIdStringInTimeZone,
  resetToStartOfDayForPartial,
  sameDay,
  timeOfDayFromLocalToTimeZone,
} from '../../util/dates';
import { FieldSelect, IconArrowHead, IconSpinner } from '../../components';

import css from './ManageAvailabilityCalendar.css';
import {
  getEditListingAvailableStartTimes,
  getTimeSlots,
} from '../BookingTimeForm/FieldDateAndTimeInput';
import EditListingAvailiblityCalenderForm, {
  utcToListingTimeZone,
} from '../EditListingAvailiblityCalenderForm/EditListingAvailiblityCalenderForm';

// Constants

const HORIZONTAL_ORIENTATION = 'horizontal';
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;
const MAX_BOOKINGS_RANGE = 180;
const TODAY_MOMENT = moment().startOf('day');
const END_OF_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1, 'days')
  .startOf('day');
const END_OF_BOOKING_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_BOOKINGS_RANGE - 1, 'days')
  .startOf('day');

// Constants for calculating day width (aka table cell dimensions)
const TABLE_BORDER = 2;
const TABLE_COLUMNS = 7;
const MIN_CONTENT_WIDTH = 272;
const MIN_CELL_WIDTH = Math.floor(MIN_CONTENT_WIDTH / TABLE_COLUMNS); // 38
const MAX_CONTENT_WIDTH_DESKTOP = 603;
const MAX_CELL_WIDTH_DESKTOP = Math.floor(MAX_CONTENT_WIDTH_DESKTOP / TABLE_COLUMNS); // 108
const VIEWPORT_LARGE = 1024;

// Helper functions

// Calculate the width for a calendar day (table cell)
const dayWidth = (wrapperWidth, windowWith) => {
  if (windowWith >= VIEWPORT_LARGE) {
    // NOTE: viewportLarge has a layout with sidebar.
    // In that layout 30% is reserved for paddings and 282 px goes to sidebar and gutter.
    const width = windowWith * 0.7 - 282;
    return width > MAX_CONTENT_WIDTH_DESKTOP
      ? MAX_CELL_WIDTH_DESKTOP
      : Math.floor((width - TABLE_BORDER) / TABLE_COLUMNS);
  } else {
    return wrapperWidth > MIN_CONTENT_WIDTH
      ? Math.floor((wrapperWidth - TABLE_BORDER) / TABLE_COLUMNS)
      : MIN_CELL_WIDTH;
  }
};

// Get a function that returns the start of the previous month
const prevMonthFn = (currentMoment, timeZone = 'Australia/Sydney') =>
  currentMoment
    .clone()
    .tz(timeZone)
    .subtract(1, 'months')
    .startOf('month');

// Get a function that returns the start of the next month
const nextMonthFn = (currentMoment, timeZone = 'Australia/Sydney') =>
  currentMoment
    .clone()
    .tz(timeZone)
    .add(1, 'months')
    .startOf('month');

// Get the start and end Dates in UTC

const dateStartAndEndInTimeZone = (date, timeZone = 'Australia/Sydney') => {
  return {
    start: resetToStartOfDayForPartial(date, timeZone),
    end: resetToStartOfDayForPartial(date, timeZone, 1),
  };
};


// outside range -><- today ... today+MAX_AVAILABILITY_EXCEPTIONS_RANGE -1 -><- outside range
const isDateOutsideRange = (date, timeZone) => {
  const localizedDay = momentTimeOfDayFromLocalToTimeZone(date, timeZone);
  return (
    !isInclusivelyAfterDay(
      localizedDay,
      momentTimeOfDayFromLocalToTimeZone(TODAY_MOMENT, timeZone)
    ) ||
    !isInclusivelyBeforeDay(
      localizedDay,
      momentTimeOfDayFromLocalToTimeZone(END_OF_RANGE_MOMENT, timeZone)
    )
  );
};
const isOutsideRange = memoize(isDateOutsideRange);

const isMonthInRange = (monthMoment, startOfRange, endOfRange) => {
  const isAfterThisMonth = monthMoment.isSameOrAfter(startOfRange, 'month');
  const isBeforeEndOfRange = monthMoment.isSameOrBefore(endOfRange, 'month');
  return isAfterThisMonth && isBeforeEndOfRange;
};

const isPast = date => !isInclusivelyAfterDay(date, TODAY_MOMENT);
const isAfterEndOfRange = date => !isInclusivelyBeforeDay(date, END_OF_RANGE_MOMENT);
const isAfterEndOfBookingRange = date => !isInclusivelyBeforeDay(date, END_OF_BOOKING_RANGE_MOMENT);

const isBooked = (bookings, day, timeZone) => {
  return !!bookings.find(b => {
    const booking = ensureBooking(b);
    const displayStart = booking.attributes.displayStart;
    const displayEnd = booking.attributes.displayEnd;
    const localizedDay = day.toDate();

    return (
      sameDay(displayStart, localizedDay) ||
      sameDay(displayEnd, localizedDay) ||
      isInRange(localizedDay, displayStart, displayEnd, 'day')
    );
  });
};

const findException = (exceptions, day, timeZone = 'Australia/Sydney') => {
  const final =  exceptions.find(exception => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    const isSameTimeZone =
      moment(availabilityException.attributes.start)
        .tz(timeZone)
        .hours() === 0;
    const start = utcToListingTimeZone(availabilityException.attributes.start, timeZone);
    const end = utcToListingTimeZone(availabilityException.attributes.end, timeZone);
      // const start = availabilityException.attributes.start;
      // const end = availabilityException.attributes.end;
    return isInRange(day, start, end, undefined, timeZone, '[)');
  });
  return final;
};


const isBlocked = (availabilityPlan, exception, date, timeZone) => {
  const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
  const localizedDay = timeOfDayFromLocalToTimeZone(date, timeZone);
  const planEntry = planEntries.find(
    weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[moment(localizedDay).isoWeekday() - 1]
  );
  const seatsFromPlan = planEntry ? planEntry.seats : 0;

  const seatsFromException =
    exception && ensureAvailabilityException(exception.availabilityException).attributes.seats;
  const seats = exception ? seatsFromException : seatsFromPlan;
  return seats === 0;
};


const isPartiallyBlocked = (availabilityPlan, exception, date, timeZone) => {
  const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
  const localizedDay = timeOfDayFromLocalToTimeZone(date, timeZone);
  const planEntry = planEntries.find(
    weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[moment(localizedDay).isoWeekday() - 1]
  );
  const seatsFromPlan = planEntry ? planEntry.seats : 0;

  const seatsFromException =
    exception && ensureAvailabilityException(exception.availabilityException).attributes.seats;
  const seats = exception ? seatsFromException : seatsFromPlan;
  return seats === 0;
};


const findPartiallExceptions = (exceptions, day, timeZone = 'Australia/Sydney') => {
  let partialExceptionsList = [];
  exceptions.map(exception => {
    const availabilityException = ensureAvailabilityException(exception.availabilityException);
    if(availabilityException.attributes.seats == 2) {
      return ;
    }
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    const dateRange = dateStartAndEndInTimeZone(day.toDate(), timeZone);
    const dayStart = dateRange.start;
    const dayEnd = dateRange.end;
    const isSameTimeZone =
      moment(availabilityException.attributes.start)
        .tz(timeZone)
        .hours() === 0;
    const startForPartial = utcToListingTimeZone(availabilityException.attributes.start, timeZone);
    const endForPartial = utcToListingTimeZone(availabilityException.attributes.end, timeZone);
    const start = availabilityException.attributes.start;
    const end = availabilityException.attributes.end;


    const res = isInPartialRange(dayStart, dayEnd, start, end, undefined, timeZone, '[]');
    if(res) {
      partialExceptionsList.push({
        exception: availabilityException,
        fullException: exception,
        start: startForPartial,
        end: endForPartial
      });
    }
  });
  if(partialExceptionsList.length) {
  }
  return partialExceptionsList;
};

const getSameDayBookings = (bookings, day, timeZone) => {
  let dayBookings = [];
  bookings.map(b => {
    const booking = ensureBooking(b);
    // console.log("Bookings Same Day", booking);
    const displayStart = booking.attributes.displayStart;
    const displayEnd = booking.attributes.displayEnd;
    const localizedDay = day.toDate();
    if(sameDay(displayStart, localizedDay) ||
    sameDay(displayEnd, localizedDay) ||
    isInRange(localizedDay, displayStart, displayEnd, 'day')) {
      dayBookings.push(b);
    }
  })
  return dayBookings;
};

const dateModifiers = (availabilityPlan, exceptions, bookings, date) => {
  const { timezone } = availabilityPlan;
  const exception = findException(exceptions, date, timezone);
  const partialExceptions = findPartiallExceptions(exceptions, date, timezone);
  if(partialExceptions.length) {
  }
  //  Date modifier
  let sameDayBookings = [];
  const isCarBooked = isBooked(bookings, date, timezone);
  if(isCarBooked) {
    // console.log("Bookings Manage Form", bookings);
    // console.log("Date", moment(date).toDate());
    sameDayBookings = getSameDayBookings(bookings, date, timezone);
    // console.log("Same Day Bookings are", sameDayBookings);
  }
  return {
    isOutsideRange: isOutsideRange(date, timezone),
    isSameDay: isSameDate(
      timeOfDayFromLocalToTimeZone(date, timezone),
      timeOfDayFromLocalToTimeZone(TODAY_MOMENT, timezone)
    ),
    isBlocked: isBlocked(availabilityPlan, exception, date, timezone),
    isPartiallyBlocked: true ?  partialExceptions.length : false,
    isBooked: isBooked(bookings, date, timezone),
    isInProgress: exception && exception.inProgress,
    isFailed: exception && exception.error,
    partialExceptions: partialExceptions,
    exception: exception,
    sameDayBookings
  };
};

const renderDayContents = (calendar, availabilityPlan, timeZone) => date => {
  const { exceptions = [], bookings = [] } =
    calendar[monthIdStringInTimeZone(date, timeZone)] || {};

  const { isOutsideRange, isSameDay, isBlocked, isPartiallyBlocked, partialExceptions, isBooked, isInProgress, isFailed, sameDayBookings } = dateModifiers(
    availabilityPlan,
    exceptions,
    bookings,
    date
  );
    if(partialExceptions.length) {
    }

  // Partially booked
  // Partially blocked

  const dayClasses = classNames(css.default, {
    [css.outsideRange]: isOutsideRange,
    [css.today]: isSameDay,
    [css.paritallyblocked]: isPartiallyBlocked && !(sameDayBookings.length),
    [css.blocked]: isBlocked,
    [css.reserved]: isBooked && !isPartiallyBlocked,
    [css.exceptionError]: isFailed,
    [css.bookedBlocked]: sameDayBookings.length && isPartiallyBlocked,
  });

  return (
    <div className={css.dayWrapper}>
      <span className={dayClasses}>
        {isInProgress ? (
          <IconSpinner rootClassName={css.inProgress} />
        ) : (
          <span className={css.dayNumber}>{date.format('D')}</span>
        )}
      </span>
    </div>
  );
};

const makeDraftException = (exceptions, start, end, seats) => {
  const draft = ensureAvailabilityException({ attributes: { start, end, seats } });
  return { availabilityException: draft };
};

////////////////////////////////
// ManageAvailabilityCalendar //
////////////////////////////////
class ManageAvailabilityCalendar extends Component {
  constructor(props) {
    super(props);
    // DOM refs
    this.dayPickerWrapper = null;
    this.dayPicker = null;

    this.state = {
      currentMonth: moment().startOf('month'),
      focused: true,
      date: null,
      isUpdatedAvailability: false,
      isModalOpen: false,
      modalDate: null,
      availbleCertainHours: false,
      modalFullDayBlocked: false,
      slotIndex: [1],
      selectedSlotList: [],
      partialExceptions:[],
      slotsList: [],
      partialException: [],
      exception: [],
      sameDayBookings: []
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onDayAvailabilityChange = this.onDayAvailabilityChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
  }

  componentDidMount() {
    // Fetch month data if user have navigated to availability tab in EditListingWizard
    this.fetchMonthData(this.state.currentMonth);
    // Fetch next month too.
    this.fetchMonthData(nextMonthFn(this.state.currentMonth));
  }

  fetchMonthData(monthMoment) {
    const { availability, listingId, timeZone } = this.props;

    // Don't fetch exceptions for past months or too far in the future
    if (isMonthInRange(monthMoment, TODAY_MOMENT, END_OF_RANGE_MOMENT)) {
      // Use "today", if the first day of given month is in the past
      const startMoment = isPast(monthMoment) ? TODAY_MOMENT : monthMoment;
      const start = timeOfDayFromLocalToTimeZone(startMoment);

      // Use END_OF_RANGE_MOMENT, if the first day of the next month is too far in the future
      const nextMonthMoment = nextMonthFn(monthMoment);
      const endMoment = isAfterEndOfRange(nextMonthMoment)
        ? END_OF_RANGE_MOMENT.clone().add(1, 'days')
        : nextMonthMoment;
      const end = timeOfDayFromLocalToTimeZone(endMoment);

      // Fetch AvailabilityExceptions for this month
      availability.onFetchAvailabilityExceptions({ listingId, start, end, timeZone });

      // Fetch Bookings if the month is within bookable range (180 days)
      if (isMonthInRange(startMoment, TODAY_MOMENT, END_OF_BOOKING_RANGE_MOMENT)) {
        const endMomentForBookings = isAfterEndOfBookingRange(nextMonthMoment)
          ? END_OF_BOOKING_RANGE_MOMENT.clone().add(1, 'days')
          : nextMonthMoment;
        const endForBookings = timeOfDayFromLocalToTimeZone(endMomentForBookings);

        // Fetch Bookings for this month (if they are in pending or accepted state)
        const state = ['pending', 'accepted'].join(',');
        availability.onFetchBookings({ listingId, start, end: endForBookings, state, timeZone });
      }
    }
  }

  onDayAvailabilityChange(date, seats, exceptions) {
    const { availabilityPlan, listingId } = this.props;
    const { timezone } = availabilityPlan;
    const { start, end } = dateStartAndEndInTimeZone(date.startOf('day').toDate(), timezone);
    const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
    const planEntry = planEntries.find(
      weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[date.isoWeekday() - 1]
    );

    const seatsFromPlan = planEntry ? planEntry.seats : 0;

    const currentException = findException(exceptions, date, timezone, true);
    // Draft Excpetion
    const draftException = makeDraftException(exceptions, start, end, seatsFromPlan);
    const exception = currentException || draftException;
    const hasAvailabilityException = currentException && currentException.availabilityException.id;
    if (hasAvailabilityException) {
      const id = currentException.availabilityException.id;
      const isResetToPlanSeats = seatsFromPlan === seats;

      if (isResetToPlanSeats) {
        // Delete the exception, if the exception is redundant
        // (it has the same content as what user has in the plan).
        this.props.availability.onDeleteAvailabilityException({
          id,
          currentException: exception,
          seats: seatsFromPlan,
        });
      } else {
        // If availability exception exists, delete it first and then create a new one.
        // NOTE: currently, API does not support update (only deleting and creating)
        this.props.availability
          .onDeleteAvailabilityException({ id, currentException: exception, seats: seatsFromPlan })
          .then(() => {
            const params = { listingId, start, end, seats, currentException: exception };
            this.props.availability.onCreateAvailabilityException(params);
          });
      }
    } else {
      // If there is no existing AvailabilityExceptions, just create a new one
      const params = { listingId, start, end, seats, currentException: exception };
      this.props.availability.onCreateAvailabilityException(params);
    }
    if (this.props.onUpdateAvailability && !this.state.isUpdatedAvailability) {
      this.setState({ isUpdatedAvailability: true }, () => this.props.onUpdateAvailability());
    }
  }


  onDateChange(date) {
    if(!this.props.isHourlyUnBlocking) {
        this.setState({ date });
        const { availabilityPlan, availability, disableAllException } = this.props;
        const { timezone } = availabilityPlan;
        const calendar = availability.calendar;
        // This component is for day/night based processes. If time-based process is used,
        // you might want to deal with local dates using monthIdString instead of monthIdStringInUTC.
        const { exceptions = [], bookings = [] } =
          calendar[monthIdStringInTimeZone(date, timezone)] || {};
        const { isPast, isBlocked, isPartiallyBlocked, isBooked, isInProgress, partialExceptions } = dateModifiers(
          availabilityPlan,
          exceptions,
          bookings,
          date
        );
        const deletePartialExceptions = async _ => {
          console.log("Map loop");
          const promises = partialExceptions.map(async exception => {
            console.log("Exception", exception);
            const id = exception.exception.id;
            return this.props.availability
            .onDeleteAvailabilityException({ id, currentException: exception.fullException , seats: 1 })
            // .then(() => {
            //   const params = { listingId, start, end, seats, currentException: exception };
            //   this.props.availability.onCreateAvailabilityException(params);
            // });
            // return numFruit
          })

          const deleteRes = await Promise.all(promises)
          if(deleteRes) {
            this.onDayAvailabilityChange(date, 1, exceptions);
          }
          console.log("Looop ENd")
          return deleteRes;
        }

        if (isBooked || isPast || isInProgress || disableAllException) {
          // Cannot allow or block a reserved or a past date or inProgress
          return;

        } else if( isBooked) {

        }
        else if (isBlocked || partialExceptions.length) {
          if(partialExceptions.length) {
            console.log("Partial Exceptions While Unblocking", partialExceptions);
            console.log("IS BLOCKED", isBlocked);
            deletePartialExceptions();
          }
          else {
            this.onDayAvailabilityChange(date, 1, exceptions);
          }
          // Unblock the date (seats = 1)
        } else {
          // if(partialExceptions.length) {
          //   console.log("Partial Exceptions While blocking", partialExceptions)
          //   const res = deletePartialExceptions();
          // }
          // else {
            this.onDayAvailabilityChange(date, 0, exceptions);
          // }
          // Block the date (seats = 0)
        }
    }
    else {
        const { availabilityPlan, listingId } = this.props;
        const { timeZone } = availabilityPlan;
        const availability = this.props.availability
        const calendar = availability.calendar;
        const { exceptions = [], bookings = [] } = calendar[monthIdStringInTimeZone(date, timeZone)] || {};

        const { isOutsideRange, isSameDay, isBlocked, isPartiallyBlocked, partialExceptions, isBooked, isInProgress, isFailed, exception, sameDayBookings } = dateModifiers(
          availabilityPlan,
          exceptions,
          bookings,
          date
        );

        //  Get Date time Slots
        const localTimeZone = getDefaultTimeZoneOnBrowser() || 'Australia/Sydney';
        const bookingStart = moment(date).startOf("day").toDate() //  || resetToStartOfDay(date.toDate(), localTimeZone, 0);
        const bookingEnd = moment(date).endOf("day").toDate() //  || resetToStartOfDay(date.toDate(), localTimeZone, 1);
        const allHours = getEditListingAvailableStartTimes( this.props.intl, bookingStart, bookingEnd, localTimeZone);
        this.setState({
          date,
          modalFullDayBlocked: !isPartiallyBlocked && isBlocked ? isBlocked: false,
          availbleCertainHours: isPartiallyBlocked ? isPartiallyBlocked: false,
          isBooked: isBooked ? isBooked: false,
          isModalOpen: true,
          modalDate: date,
          slotsList: allHours,
          partialExceptions: partialExceptions,
          exception: exception,
          sameDayBookings: sameDayBookings
        });
    }

  }
  // onDateChange(date) {
  //   const { availabilityPlan, listingId } = this.props;
  //   const { timeZone } = availabilityPlan;
  //   const availability = this.props.availability
  //   const calendar = availability.calendar;
  //   const { exceptions = [], bookings = [] } = calendar[monthIdStringInTimeZone(date, timeZone)] || {};

  //   const { isOutsideRange, isSameDay, isBlocked, isPartiallyBlocked, partialExceptions, isBooked, isInProgress, isFailed, exception, sameDayBookings } = dateModifiers(
  //     availabilityPlan,
  //     exceptions,
  //     bookings,
  //     date
  //   );

  //   //  Get Date time Slots
  //   const localTimeZone = getDefaultTimeZoneOnBrowser() || 'Australia/Sydney';
  //   const bookingStart = moment(date).startOf("day").toDate() //  || resetToStartOfDay(date.toDate(), localTimeZone, 0);
  //   const bookingEnd = moment(date).endOf("day").toDate() //  || resetToStartOfDay(date.toDate(), localTimeZone, 1);
  //   const allHours = getEditListingAvailableStartTimes( this.props.intl, bookingStart, bookingEnd, localTimeZone);
  //   this.setState({
  //     date,
  //     modalFullDayBlocked: !isPartiallyBlocked && isBlocked ? isBlocked: false,
  //     availbleCertainHours: isPartiallyBlocked ? isPartiallyBlocked: false,
  //     isBooked: isBooked ? isBooked: false,
  //     isModalOpen: true,
  //     modalDate: date,
  //     slotsList: allHours,
  //     partialExceptions: partialExceptions,
  //     exception: exception,
  //     sameDayBookings: sameDayBookings
  //   });
  // }



  onFocusChange() {
    // Force the state.focused to always be truthy so that date is always selectable
    this.setState({ focused: true });
  }

  onMonthClick(monthFn) {
    const onMonthChanged = this.props.onMonthChanged;
    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, this.props.timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, this.props.timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, this.props.timeZone);
        const currentMonthData = this.props.availability.calendar[monthId];
        const { fetchExceptionsError, fetchBookingsError } = currentMonthData || {};
        if (currentMonthData && (fetchExceptionsError || fetchBookingsError)) {
          this.fetchMonthData(this.state.currentMonth);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthIdStringInTimeZone(this.state.currentMonth, this.props.timeZone));
        }
      }
    );
  }

  slotSelectionField = id => {
    return (
      <div className={css.fieldWrapper}>
        <div className={classNames(css.newInput, css.field)}>
          <FieldSelect
            labelClassName={css.label}
            name={`availableStartTime${id}`}
            id={`availableStartTime${id}`}
            className={css.fieldSelect}
            selectClassName={css.select}
            label="Available from"
            disabled={false}
            onChange={this.onBookingStartTimeChange}
          >
            {this.state.slotsList.map(slot =><option value={slot.timestamp}>{slot.timeOfDay}</option>)}
          </FieldSelect>
        </div>
        <div className={classNames(css.newInput, css.field)}>
          <FieldSelect
            labelClassName={css.label}
            name="availableEndTime"
            id="availableEndTime"
            className={css.fieldSelect}
            selectClassName={css.select}
            label="To"
            disabled={false}
            onChange={this.onBookingEndTimeChange}
          >
            <option value="">{'00:00'}</option>
          </FieldSelect>
        </div>
      </div>
    );
  };


  blockButtonHalder = (blockFlag, date)  => {
    const { availabilityPlan, availability, disableAllException } = this.props;
    const { timezone } = availabilityPlan;
    const calendar = availability.calendar;
    // This component is for day/night based processes. If time-based process is used,
    // you might want to deal with local dates using monthIdString instead of monthIdStringInUTC.
    const { exceptions = [], bookings = [] } =
      calendar[monthIdStringInTimeZone(date, timezone)] || {};
    // const { isPast, isBlocked, isBooked, isInProgress } = dateModifiers(
    //   availabilityPlan,
    //   exceptions,
    //   bookings,
    //   date
    // );
    if (!blockFlag) {
      // Unblock the date (seats = 1)
      this.onDayAvailabilityChange(date, 1, exceptions);
    } else {
      // Block the date (seats = 0)
      this.onDayAvailabilityChange(date, 0, exceptions);
    }

    // this.onClosePopup();
  }

  changeModalVisiblity = (value) => {
    this.setState({
      isModalOpen: value
    })
  }

  handleSubmit = values => {
    if(this.state.modalFullDayBlocked) {
      this.blockButtonHalder(true, this.state.date);
    }
    else {
      this.blockButtonHalder(false, this.state.date);
    }
    this.setState({
      isModalOpen: false,
      availbleCertainHours: false,
      modalFullDayBlocked: false
    });

  };

  modalCloseAfterUpdate = ( ) => {
    this.setState({
      isModalOpen: false,
      availbleCertainHours: false,
      modalFullDayBlocked: false
    });
  }


  render() {
    const {
      className,
      rootClassName,
      listingId,
      availability,
      availabilityPlan,
      onMonthChanged,
      monthFormat,
      onManageDisableScrolling,
      intl,
      ...rest
    } = this.props;
    const { focused, date, currentMonth, isModalOpen, slotIndex } = this.state;
    const { clientWidth: width } = this.dayPickerWrapper || { clientWidth: 0 };
    const hasWindow = typeof window !== 'undefined';
    const windowWidth = hasWindow ? window.innerWidth : 0;
    const daySize = dayWidth(width, windowWidth);
    const calendarGridWidth = daySize * TABLE_COLUMNS + TABLE_BORDER;
    console.log("availability-------->", availability)
    const calendar = availability.calendar;
    const currentMonthData = calendar[monthIdString(currentMonth)];
    const {
      fetchExceptionsInProgress,
      fetchBookingsInProgress,
      fetchExceptionsError,
      fetchBookingsError,
    } = currentMonthData || {};
    const isMonthDataFetched =
      !isMonthInRange(currentMonth, TODAY_MOMENT, END_OF_RANGE_MOMENT) ||
      (!!currentMonthData && !fetchExceptionsInProgress && !fetchBookingsInProgress);

    const monthName = currentMonth.format('MMMM');
    const classes = classNames(rootClassName || css.root, className);
    const availbleCertainHours = this.state.availbleCertainHours;
    const modalFullDayBlocked = this.state.modalFullDayBlocked;

    const fullDayinput = { value:modalFullDayBlocked, onChange: this.onChangeFullDayBlocking  };
    const certainHourInput = { value:availbleCertainHours, onChange: this.onChangeAvailbleCertainHours };
    // const timeSlotsOnSelectedDate = getTimeSlots(
    //   timeSlotsOnSelectedMonth,
    //   date,
    //   this.props.timeZone
    // );

    return (
      <div
        className={classes}
        ref={c => {
          this.dayPickerWrapper = c;
        }}
      >
        {width > 0 ? (
          <div style={{ width: `${calendarGridWidth}px` }}>
            <DayPickerSingleDateController
              {...rest}
              ref={c => {
                this.dayPicker = c;
              }}
              numberOfMonths={1}
              navPrev={<IconArrowHead direction="left" />}
              navNext={<IconArrowHead direction="right" />}
              weekDayFormat="ddd"
              daySize={daySize}
              renderDayContents={renderDayContents(calendar, availabilityPlan, this.props.timeZone)}
              focused={focused}
              date={date}
              onDateChange={this.onDateChange}
              onFocusChange={this.onFocusChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              hideKeyboardShortcutsPanel
              horizontalMonthPadding={9}
              renderMonthElement={({ month }) => (
                <div className={css.monthElement}>
                  <span className={css.monthString}>{month.format(monthFormat)}</span>
                  {!isMonthDataFetched ? <IconSpinner rootClassName={css.monthInProgress} /> : null}
                </div>
              )}
            />
          </div>
        ) : null}
        <div className={css.legend} style={{ width: `${calendarGridWidth}px` }}>
          <div className={css.legendRow}>
            <span className={css.legendAvailableColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.availableDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendBlockedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.blockedDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendReservedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.bookedDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendHourlyAvailableColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.hourlyAvailableDay" />
            </span>
          </div>
          {/* <div className={css.legendRow}>
            <span className={css.legenbookedBlockedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.bookedBlockedDay" />
            </span>
          </div> */}
        </div>
        {fetchExceptionsError && fetchBookingsError ? (
          <p className={css.error}>
            <FormattedMessage
              id="EditListingAvailabilityForm.fetchMonthDataFailed"
              values={{ month: monthName }}
            />
          </p>
        ) : null}
        {this.state.isModalOpen ? <EditListingAvailiblityCalenderForm
          isModalOpen={this.state.isModalOpen}
          date={this.state.date}
          intl={intl}
          modalCloseAfterUpdate={this.modalCloseAfterUpdate}
          partialExceptions={this.state.partialExceptions}
          exception={this.state.exception}
          modalFullDayBlocked={this.state.modalFullDayBlocked}
          availbleCertainHours={this.state.availbleCertainHours}
          onManageDisableScrolling={onManageDisableScrolling}
          availability={availability}
          slotsList={this.state.slotsList}
          changeModalVisiblity={this.changeModalVisiblity}
          fullDayinput={fullDayinput}
          certainHourInput={certainHourInput}
          availabilityPlan={availabilityPlan}
          listingId={listingId}
          // handleSubmit={this.handleSubmit}
          onUpdateAvailability={this.props.onUpdateAvailability}
          timeZone={availabilityPlan.timeZone || this.props.timeZone}
          disableAllException={this.props.disableAllException}
          sameDayBookings={this.state.sameDayBookings}
        />: ''}

      </div>
    );
  }
}

ManageAvailabilityCalendar.defaultProps = {
  className: null,
  rootClassName: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange,
  isDayHighlighted: () => false,
  enableOutsideDays: true,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  onMonthChanged: null,
};

ManageAvailabilityCalendar.propTypes = {
  className: string,
  rootClassName: string,
  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onFetchBookings: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
  }).isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  onMonthChanged: func,
  onUpdateAvailability: func,
};

export default ManageAvailabilityCalendar;
