import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';
import heroPictureDesktop from '../../assets/landingPageCovid/covidBanner.jpg';
import covidBannerMobile from '../../assets/landingPageCovid/covidBannerMobile.png';
import css from './CovidPage.css';

export const DisinfectedLabel = props => {
  const { rootClass } = props;
  return (
    <div class={`${css.wrapperLabel} ${rootClass}`}>
      <div class={css.numberDays}>
        <div className={css.number}> 60 </div>
        <div className={css.day}> days</div>
      </div>
      <div class={css.textLabel}>
        <span> Disinfected </span>
      </div>
    </div>
  )
}

const SectionHero = () => {
  return (
    <div className={css.sectionHero}>
      <picture>
        <source media="(max-width: 768px)" srcset={covidBannerMobile}/>
        <img src={heroPictureDesktop} className={css.banner} />
      </picture>
      <div className={css.sectionHeroBg}>
        <div className={css.title}>
          <FormattedMessage id='LandingPageCovid.SectionHero.title' />
        </div>
        <div className={css.sectionHeroExcerptDesktop}>
          <FormattedHTMLMessage id='LandingPageCovid.SectionHero.excerptDesktop' />
        </div>
        <div className={css.sectionHeroExcerptMobile}>
          <FormattedHTMLMessage id='LandingPageCovid.SectionHero.excerptMobile' />
        </div>
        <div className={css.descriptionWrapper}>
          <div className={css.sectionHeroDescription}>
            <div className={css.description1}>
              <FormattedHTMLMessage id='LandingPageCovid.SectionHero.description1' /> <br/>
            </div>
            <FormattedHTMLMessage id='LandingPageCovid.SectionHero.description2' />
          </div>
          <div className={css.usefulViroQ}>
            <DisinfectedLabel />
            <div className={css.sectionHeroExcerpt}>
              <FormattedMessage id='LandingPageCovid.SectionHero.usefulViroQ' />
            </div>
            <div className={css.heroSectionEnrolButton}>
              <NamedLink
                className={`${css.enrolButton} ${css.button}`}
                name="CovidCheckoutPage"
              >
                <div className={css.enrolAndBuy}>
                  <FormattedMessage id="LandingPageCovid.SectionHero.enrolAndBuy" />
                  <br />
                </div>
                <div className={css.price}>
                  <FormattedMessage id="LandingPageCovid.SectionHero.price" />
                </div>
              </NamedLink>
              <div className={css.sectionHeroWhyBuy}>
                <FormattedHTMLMessage id='LandingPageCovid.SectionHero.whyBuyIt1' />
                <br />
                <FormattedMessage id='LandingPageCovid.SectionHero.whyBuyIt2' />
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SectionHero;
