/*t
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { BookingBreakdown, NamedLink } from '../../components';

import css from './BookingDatesForm.css';
import { ensureTransaction, restoreTransaction } from '../../util/data';
import { connect } from 'react-redux';
import { fetchEstimateBreakdown } from '../../containers/CheckoutPage/CheckoutPage.duck';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

const EstimatedBreakdownMaybe = props => {
  const {
    unitType,
    startDate,
    endDate,
    credits,
    discount,
    discountChoice,
    listing,
  } = props.bookingData;

  const { checkedCode, setEstimation, currentUser, listingParams } = props;
  const previousRef = useRef({});
  const [estimatedTx, setEstimatedTx] = useState(ensureTransaction(null));
  const [estimating, setEstimating] = useState(false);
  const canEstimatePrice =
    startDate &&
    endDate &&
    (!discountChoice ||
      (discountChoice === 'promos' && checkedCode) ||
      discountChoice === 'credits');

  const handleFetchEstimateBreakdown = useCallback(
    debounce(
      params =>
        props.onFetchEstimateBreakdown(params).then(tx => {
          setEstimatedTx(tx);
          setEstimating(false);
          setEstimation(false);
        }),
      2000,
      {
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    const haveChangeValue = !isEqual(previousRef.current, {
      startDate,
      endDate,
      listing,
      currentUser,
      discount,
      credits,
      checkedCode,
    });
    if (canEstimatePrice && haveChangeValue) {
      setEstimating(true);
      setEstimation(true);
      previousRef.current = {
        startDate: startDate,
        endDate: endDate,
        listing,
        currentUser,
        discount,
        credits,
        checkedCode,
      };
      const estimateParams = {
        bookingStart: startDate,
        bookingEnd: endDate,
        listing,
        currentUser,
        credits,
        discount,
      };
      if (checkedCode) {
        estimateParams.checkedCode = checkedCode;
      }
      handleFetchEstimateBreakdown(estimateParams);
    }
  }, [startDate, endDate, listing, currentUser, discount, credits, discountChoice, checkedCode]);

  if (!canEstimatePrice) {
    return null;
  }

  if (canEstimatePrice && (!currentUser || !currentUser.id)) {
    return (
      <div className={css.warning}>
        Please{' '}
        <NamedLink
          name="LoginPage"
          to={{
            state: {
              isFromListingPage: true,
              startDate: new Date(startDate),
              endDate: new Date(endDate),
              startTime: moment(startDate).format('hh:mm a'),
              endTime: moment(startDate).format('hh:mm a'),
              params: {
                ...listingParams,
              },
            },
          }}
          from="ListingPage"
        >
          login
        </NamedLink>{' '}
        to see the breakdown
      </div>
    );
  }
  return estimating ? (
    <div className={css.estimating}>Estimating...</div>
  ) : estimatedTx && estimatedTx.id ? (
    <BookingBreakdown
      className={css.receipt}
      userRole="customer"
      unitType={unitType}
      transaction={restoreTransaction(estimatedTx)}
      booking={estimatedTx.booking}
      isOnListingPage
    />
  ) : null;
};

const mapDispatchToProps = dispatch => ({
  onFetchEstimateBreakdown: params => dispatch(fetchEstimateBreakdown(params)),
});

export default connect(null, mapDispatchToProps)(EstimatedBreakdownMaybe);
