import React from 'react';
import css from './MasterCardLandingPage.css';
import { FormattedMessage } from 'react-intl';
import masterCardLogo from '../../assets/masterCardLandingPage/masterCardLogo.png';

export const SectionHero = ({ intl }) => {
  const title = intl.formatMessage({
    id: 'MasterCardLandingPage.SectionHero.title'
  });
  const descriptionHighlight = intl.formatMessage({
    id: 'MasterCardLandingPage.SectionHero.affordablyPrice'
  })
  return (
    <div className={css.sectionHero}>
      <img src={masterCardLogo} alt='Master Card' className={css.masterCardLogo} />
      <div className={css.heroTitle}>{title}</div>
      <div className={css.description}>
        <FormattedMessage
          id='MasterCardLandingPage.SectionHero.description'
          values={{ highlight: <span className={css.descriptionHighlight}>{descriptionHighlight}</span> }}
        />
      </div>
    </div>
  )
}
