/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, userDisplayNameAsString } from '../../util/data';
import { NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.css';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_DASHBOARD,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
} from '../../util/gtm/gtmConstants';
import Avatar from '../Avatar/Avatar';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    onClose,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const pushGTMBrowse = (eventGTM, eventAttribute) => {
    let eventCallback;
    if (eventAttribute) {
      eventAttribute.preventDefault();
      const href = eventAttribute.currentTarget.href;
      eventCallback = () => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      };
    }
    pushGTMBrowseEvent({
      props,
      event: eventGTM,
      eventCallback,
    });
  };

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const staticLinks = isAuthenticated ? null : (
    <>
      <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}>
        <NamedLink name="HowItWorkPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.howItWork" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}>
        <NamedLink name="BecomeAGuestPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.rentingACar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}>
        <NamedLink name="BecomeAHostPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.sharingYourCar" />
        </NamedLink>
      </div>
      {/* <div className={css.linkWrapper} onClick={() => null}>
        <NamedLink name="CovidLandingPage" className={css.link}>
          <FormattedMessage id="TopbarDesktop.linkCovidProgram" />
        </NamedLink>
      </div> */}
    </>
  );

  const authenticatedLinks = isAuthenticated ? (
    <>
      <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}>
        <NamedLink name="InboxBasePage" className={css.link}>
          <FormattedMessage id="TopbarMenu.dashBoard" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ManageListingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.yourListings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="AccountSettingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.accountSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ProfileSettingsPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.profileSettings" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="NewListingPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.listYourCar" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper}>
        <NamedLink name="ReferralPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.referralPage" />
        </NamedLink>
      </div>
      <div className={css.linkWrapper} onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}>
        <NamedLink name="HowItWorkPage" className={css.link}>
          <FormattedMessage id="TopbarMenu.howItWork" />
        </NamedLink>
      </div>
    </>
  ) : null;

  const logout = isAuthenticated ? (
    <div
      onClick={() => {
        onLogout();
        onClose();
      }}
      className={css.logout}
    >
      <FormattedMessage id="TopbarMenu.logout" />
    </div>
  ) : null;

  const signUp = isAuthenticated ? null : (
    <div className={classNames(css.linkWrapper, css.signUpLinkWrapper)}>
      <NamedLink name="SignupPage" className={classNames(css.link, css.authLink)}>
        <FormattedMessage id="TopbarMenu.signUp" />
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.space} onClick={onClose} />
      <div className={css.content}>
        <div className={css.avatarWrapper}>
          <Avatar user={currentUser} />
          <div className={css.userName}>
            {isAuthenticated ? (
              userDisplayNameAsString(currentUser)
            ) : (
              <NamedLink name="LoginPage" className={classNames(css.link, css.authLink)}>
                <FormattedMessage id="TopbarMenu.login" />
              </NamedLink>
            )}
          </div>
        </div>
        <div className={css.links}>
          {signUp}
          {authenticatedLinks}
          {staticLinks}
        </div>
        {logout}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string, object } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  location: object,
};

export default TopbarMobileMenu;
