import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes, REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import { ensureCurrentUser, ensureListing, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  AvatarLarge,
  BListingCardMobile,
  ButtonTabNavHorizontal,
  Footer,
  IconReviewStar,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  ListingCard,
  ListingCardV2,
  ListingCardV3,
  NamedLink,
  Page,
  Reviews,
  UserNav,
} from '../../components';
import BlandingSearchResultsPanelCSS from './../../components/SearchResultsPanel/BlandingSearchResultsPanel.css';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { loadData, setInitialState } from './ProfilePage.duck';
import config from '../../config';

import css from './ProfilePage.css';
import { parse } from '../../util/urlHelpers';
import Loader from '../StripePayoutPage/Loader';
import { initiateEventFromListing, initiateEventViewedFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_VIEWED_LISTING_GUEST, EVENT_VIEWED_PRODUCT } from '../../util/gtm/gtmConstants';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';
const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const { Money } = sdkTypes;

const SuperHostLogo = () => {
  return (
    <div className={css.superHost}>
    <FormattedMessage id="ListingCard.superhost" />
  </div>
  );
};

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
      referalCode: '',
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  componentDidMount() {
    
    const signUpCode = parse(this.props.location.search).code;    

    if (signUpCode) {
      this.setState({
        referalCode: signUpCode,
      });
    }
    const { dispatch } = this.props;
    dispatch(setInitialState()); // Reset state including any errors;
  }

  onSelectCard = (selectedListing, isUsedMap) => {
    const currentListing = ensureListing(selectedListing);
    initiateEventFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
      isHost: false,
    });
    initiateEventFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_SEARCH_VIEWED_LISTING_GUEST,
      isHost: false,
    });
    sendG4AEvent(currentListing, GTAG_ACTIONS.ACTION_VIEW_ITEM, this.props);
    initiateEventViewedFromListing({
      props: this.props,
      listing: currentListing,
      event: EVENT_VIEWED_PRODUCT,
      isHost: false,
    });
  };


  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      queryListingsError,
      listings,
      reviews,
      queryReviewsError,
      viewport,
      intl,
      loading,  // Receive loading state as a prop
    } = this.props;

    const referalCode = this.state.referalCode;

    if (referalCode) {
      localStorage.setItem('code', referalCode);
    }

    console.log('REFERAL CODE >>', referalCode);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);

    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const displayName = profileUser.attributes.profile.displayName;
    const bio = profileUser.attributes.profile.bio;
    const superHost = profileUser.attributes.profile && profileUser.attributes.profile.publicData && profileUser.attributes.profile.publicData.superHost;
    const hasBio = !!bio;
    const hasSuperHost = !!superHost;
    const hasListings = listings.length > 0;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

      // If still loading, show a loading spinner or placeholder
 

    const asideContent = (
      <div className={css.asideContent}>
        <AvatarLarge className={css.avatar} user={user} disableProfileLink />
        <h1 className={css.mobileHeading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
          ) : null}
        </h1>
        {profileUser && profileUser.attributes.profile && (
          <div className={css.myNameIs}>
            My name is <br />
            {profileUser.attributes.profile.displayName}
            {/* {profileUser.attributes.profile.firstName} {profileUser.attributes.profile.lastName} */}
          </div>
        )}
        {editLinkMobile}
        {editLinkDesktop}
      </div>
    );

    const listingsContainerClasses = classNames(css.listingsContainer, {
      [css.withBioMissingAbove]: !hasBio,
    });

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const reviewsAverageOfProvider =
      reviewsOfProvider.length > 0
        ? (
            reviewsOfProvider.reduce((total, currentReview) => {
              return total + currentReview.attributes.rating;
            }, 0) / reviewsOfProvider.length
          ).toFixed(2)
        : 'N/A';
    const reviewsAverageOfCustomer =
      reviewsOfCustomer.length > 0
        ? (
            reviewsOfCustomer.reduce((total, currentReview) => {
              return total + currentReview.attributes.rating;
            }, 0) / reviewsOfCustomer.length
          ).toFixed(2)
        : 'N/A';

    const averageReview = averageReviews =>
      averageReviews &&
      averageReviews !== 'N/A' && (
        <div className={css.averageContainer}>
          <IconReviewStar rootClassName={css.star} />
          {averageReviews}
        </div>
      );

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        {averageReview(reviewsAverageOfProvider)}
        <Reviews reviews={reviewsOfProvider} />
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        {averageReview(reviewsAverageOfCustomer)}
        <Reviews reviews={reviewsOfCustomer} />
      </div>
    );
    const getTipPrice = listingId => {
      const { prices } = {};
      const currentTripPrice = prices && prices[listingId] || null;
      return currentTripPrice ? new Money(currentTripPrice, config.currency) : null;
    }

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <React.Fragment>
            {averageReview(reviewsAverageOfProvider)}
            <Reviews reviews={reviewsOfProvider} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {averageReview(reviewsAverageOfCustomer)}
            <Reviews reviews={reviewsOfCustomer} />
          </React.Fragment>
        )}
      </div>
    );

    const getResponseTimeText = averageResponseTime => {
      if (!averageResponseTime || averageResponseTime === 0) return 'N/A';
      const convertedSecond = averageResponseTime / 1000;
      const convertedMinutes = convertedSecond / 60;
      const convertedHours = convertedMinutes / 60;
      const convertedDays = convertedHours / 24;
      const textResult = '';
      if (convertedDays > 30) {
        return `${textResult} months`;
      }
      if (convertedDays > 7) {
        return `${textResult} within weeks`;
      }
      if (convertedDays > 1) {
        return `${textResult} days`;
      }
      if (convertedHours >= 1) {
        return `${textResult} ${Math.ceil(convertedHours)} ${
          convertedHours === 1 ? `hour` : `hours`
        }`;
      }
      if (convertedMinutes >= 1) {
        return `${textResult} ${Math.ceil(convertedMinutes)} ${
          convertedMinutes === 1 ? `minute` : `minutes`
        }`;
      } else
        return `${textResult} ${Math.ceil(convertedSecond)} ${
          convertedSecond === 1 ? `second` : `seconds`
        }`;
    };

    const intercomData = profileUser.id ? (
      <div className={css.respondedTimeWrapper}>
        <p className={css.respondedTime}>
          <strong>Response time:</strong>{' '}
          {getResponseTimeText(
            get(
              user,
              'attributes.profile.metadata.intercomUserStat.average_booking_responded_time_as_host'
            )
          )}
        </p>
        <p className={css.respondedTime}>
          <strong>Response rate:</strong>{' '}
          {get(user, 'attributes.profile.metadata.intercomUserStat.booking_response_rate')
            ? (
                get(user, 'attributes.profile.metadata.intercomUserStat.booking_response_rate') * 1
              ).toFixed(0)
            : 'N/A'}
          %
        </p>
        {isCurrentUser && (
          <p className={css.respondedTime}>
            <strong>Booking acceptance rate:</strong>{' '}
            {get(user, 'attributes.profile.metadata.intercomUserStat.booking_acceptance_rate')
              ? (
                  get(
                    user,
                    'attributes.profile.metadata.intercomUserStat.booking_acceptance_rate'
                  ) * 1
                ).toFixed(0)
              : 'N/A'}
            %
          </p>
        )}
      </div>
    ) : null;

    const mainContent = (
      <div>
        <h1 className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </h1>
        {hasSuperHost ? <SuperHostLogo /> : null}
        {hasBio ? <p className={css.bio}>{bio}</p> : null}
        {intercomData}
        {hasListings ? (
          <div className={listingsContainerClasses}>
            <h2 className={css.listingsTitle}>
              <FormattedMessage
                id="ProfilePage.listingsTitle"
                values={{ count: listings.length }}
              />
            </h2>
            <ul className={css.listings}>
              {/* {listings.map(l => (
                <li className={css.listing} key={l.id.uuid}>
                  <ListingCardV3 listing={l} disableShowReview={false} showDoneTrip={true} />
                </li>

              ))} */}
              {listings.map(l => (
                <BListingCardMobile
                  onSelectCard={this.onSelectCard}
                  className={classNames(BlandingSearchResultsPanelCSS.listingCard, {
                    [BlandingSearchResultsPanelCSS.fullWidthListingCard]: ''
                  })}
                  key={l.id.uuid}
                  listing={l}
                  // center={center}
                  // renderSizes={cardRenderSizes}
                  // setActiveListing={setActiveListing}
                  // searchDate={searchDate}
                  // location={location}
                  // searchTimes={searchTimes}
                  // showMonthlyPrice={showMonthlyPrice}
                  currentUser={currentUser}
                  tripPrice={getTipPrice(l.id.uuid)}
                  // tripPriceInProgress={speculatedTripPricesInProgress}
                />
              ))}
            </ul>
          </div>
        ) : null}
        {isMobileLayout ? mobileReviews : desktopReviews}
      </div>
    );

    let content;

    if (loading) {
      content = (
        <div className={css.loadingContainer}>
          <FormattedMessage id="ProfilePage.loading" />
          <Loader /> 
        </div>
      );
    } else if (userShowError && !loading) {
      // Only show error after loading is complete
      content = (<p className={css.error}><FormattedMessage id="ProfilePage.loadingDataFailed" /></p>);
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
        removePageIndexing={true}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
            {isCurrentUser && <UserNav selectedPageName="ProfilePage" />}
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
          <LayoutWrapperMain>{content}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  loading: false,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    loading
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    loading
  };
};

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
