import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { PayoutPrefForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './PaymentRefPage.css';

export const PayoutPrefPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
  } = props;

  let tabs = [...config.custom.tabs];
  tabs = tabs.map(tab => ({...tab}));
  tabs[4].selected = true;

  const user = ensureCurrentUser(currentUser);
  const protectedData = user.attributes.profile.protectedData || {};
  const {

  } = protectedData;

  const PayoutPrefInfoForm = user.id ? (
    <PayoutPrefForm
      className={css.form}
      initialValues={{  }}
      currentUser={currentUser}
      onSubmit={() => {}}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'PayoutPrefPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PayoutPrefPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="PayoutPrefPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={user}/>
        <LayoutWrapperMain>
          <div className={css.content}>
            <h3 className={css.title}>
              <FormattedMessage id="PayoutPrefPage.heading" />
            </h3>
            {PayoutPrefInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutPrefPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

PayoutPrefPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({

});

const PayoutPrefPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PayoutPrefPageComponent);

PayoutPrefPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default PayoutPrefPage;
