import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import directionsIcon from '../../assets/tripPage/directions-solid.svg';

import css from './TripPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { className, rootClassName, location, geolocation, showAddress, isProvider, customAddress, needDirection = true } = props;
  const { address, building, floorUnit } = location || {};
  const { lat, lng } = geolocation || {};
  const hrefToGoogleMaps = geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  const fullAddress =
    typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;

  const buildingAndFloorUnit = (building && floorUnit) ? (
    <span>{building} - {floorUnit}</span>
  ) : null

  const classes = classNames(rootClassName || css.address, className);
  return showAddress && hrefToGoogleMaps ? (
    <div className={classes}>
      {isProvider ? (
        <p className={css.deliveryText}>
          <FormattedMessage id="TransactionPanel.deliveryText" />
        </p>
      ) : null}
      <div className={css.addressContainer}>

          {customAddress ? customAddress : (
          <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)} href={hrefToGoogleMaps}>
           {buildingAndFloorUnit}
          <span>{address}</span>
          </ExternalLink>)}

        {needDirection && <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
          <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
        </ExternalLink>}
      </div>
    </div>
  ) : (
    <div className={classes}>
      <p className={css.deliveryText}>
        <FormattedMessage id="TransactionPanel.locationText" />
      </p>
    </div>
  );
};

export default AddressLinkMaybe;
