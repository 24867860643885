import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { verify } from '../../ducks/EmailVerification.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { verifyGeneratedUser } from '../../ducks/user.duck';
import { parse } from '../../util/urlHelpers';
import { get } from 'lodash';
import { addIntercomNote } from '../../util/intercomHelper';
import { ensureCurrentUser } from '../../util/data';
import { pushGTMVerifiedEmailPhone } from '../../util/gtm/gtmHelpers';
import { EVENT_SIGNUP_VERIFIED_EMAIL } from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '../../components';
import { EmailVerificationForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import css from './EmailVerificationPage.css';

/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
const parseVerificationToken = search => {
  const urlParams = parse(search);
  const verificationToken = urlParams.t;

  if (verificationToken) {
    return `${verificationToken}`;
  }

  return null;
};

let sentIntercomEvent = false;

export const EmailVerificationPageComponent = props => {
  const {
    currentUser,
    intl,
    scrollingDisabled,
    submitVerification,
    emailVerificationInProgress,
    verificationError,
    location,
    intercomUserId,
    verifyGeneratedUserInprogress,
    verifyGeneratedUserError,
    onVerifyGeneratedUser,
    match,
  } = props;

  useEffect(() => {
    const properties = createRawPropertiesForGTM({
      props,
    })
    pushGTMVerifiedEmailPhone(properties, EVENT_SIGNUP_VERIFIED_EMAIL);
  }, [props.currentUser]);

  const title = intl.formatMessage({
    id: 'EmailVerificationPage.title',
  });

  if (!sentIntercomEvent && intercomUserId) {
    sentIntercomEvent = true;
    addIntercomNote({
      intercomUserId,
      userEmail: currentUser.attributes.email,
      note: 'Logged in and saw the verification form',
    })
      .then(() => {
        console.log('send intercom note successfully')
      });
  }

  const initialValues = {
    verificationToken: parseVerificationToken(location ? location.search : null),
  };

  const user = ensureCurrentUser(currentUser);
  if (user && user.attributes.emailVerified) {
    return <NamedRedirect name="AccountSettingDriverVerificationPage" />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            {currentUser && !(currentUser.attributes.emailVerified && !currentUser.attributes.pendingEmail) ?
              <div className={css.content}>
                {currentUser ? (
                  <EmailVerificationForm
                    initialValues={initialValues}
                    onSubmit={({verificationToken, ...generatedUserData}) => {
                      const mobileInputNeeded = !currentUser.id ? false : !!currentUser.attributes.profile.protectedData && !currentUser.attributes.profile.protectedData.phoneNumber ? true : false;
                      const mobileUnverified =  !currentUser.id ? false : !!currentUser.attributes.profile.protectedData && !currentUser.attributes.profile.protectedData.phoneNumberVerified ? true : false;
                      const mobileVerificationNeeded = mobileUnverified || mobileInputNeeded //Show that mobile need verified or not
                      const isGeneratedUser = currentUser && currentUser.attributes && currentUser.attributes.profile.privateData && currentUser.attributes.profile.privateData.isGenerated;
                      const urlParams = parse(location.search);
                      generatedUserData.currentPassword = `${urlParams.p}`;

                      if(isGeneratedUser) {
                        onVerifyGeneratedUser(verificationToken, mobileVerificationNeeded, generatedUserData)
                      } else {
                        submitVerification({verificationToken, mobileVerificationNeeded})
                      }
                    }}
                    currentUser={currentUser}
                    inProgress={emailVerificationInProgress || verifyGeneratedUserInprogress}
                    verificationError={verificationError || verifyGeneratedUserError}
                  />
                ) : (
                  <FormattedMessage id="EmailVerificationPage.loadingUserInformation" />
                )}
              </div> : null
            }
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

EmailVerificationPageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
  verifyGeneratedUserError: null,
};

const { bool, func, shape, string } = PropTypes;

EmailVerificationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,
  verifyGeneratedUserInprogress: bool.isRequired,
  verifyGeneratedUserError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, verifyGeneratedUserInprogress, verifyGeneratedUserError } = state.user;
  const { verificationError, verificationInProgress, isVerified } = state.EmailVerification;
  return {
    isEmailVerified: isVerified,
    verificationError,
    emailVerificationInProgress: verificationInProgress,
    currentUser,
    intercomUserId: get(currentUser, 'attributes.profile.privateData.intercomUserId'),
    scrollingDisabled: isScrollingDisabled(state),
    verifyGeneratedUserInprogress,
    verifyGeneratedUserError
  };
};

const mapDispatchToProps = dispatch => ({
  submitVerification: ({ verificationToken, mobileVerificationNeeded }) => {
    return dispatch(verify(verificationToken, mobileVerificationNeeded));
  },
  onVerifyGeneratedUser: (verificationToken, mobileVerificationNeeded, generatedUserData) => dispatch(verifyGeneratedUser(verificationToken, mobileVerificationNeeded, generatedUserData)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EmailVerificationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(EmailVerificationPageComponent);

EmailVerificationPage.loadData = (params, search) => {
  console.log('Email Verification Page', params)
  const token = parseVerificationToken(search);
  return verify(token);
};

export default EmailVerificationPage;
