import React, { useEffect, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import config from '../../config';

import css from './GrabVoucherPromoLandingPage.css';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import SectionHero from './SectionHero';
import SectionStories from '../LandingPage/SectionStories';
import SectionHelp from '../LandingPage/SectionHelp';
import SectionChannels from './SectionChannels';
import SectionCars from '../LandingPage/SectionCars';
import { fetchFeaturedCar } from '../SearchPage/SearchPage.duck';
import landTransport from '../../assets/landingpage/landTransport.jpeg';
import insurance from '../../assets/landingpage/insurance.png';
import { parse } from '../../util/urlHelpers';
import { checkSignUpCode } from '../../util/referralProgram';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import { initiateEventViewedFromLandingPage } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_VISITED_LANDING_PAGE } from '../../util/gtm/gtmConstants';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';

const { Money } = types;

const GrabVoucherPromoLandingPageComponent = ({
  scrollingDisabled,
  location,
  onManageDisableScrolling,
  props,
}) => {
  const intl = useIntl();
  const isScoot = location.pathname === '/scoot-drivelah-signup';
  const [signUpCodeAmount, setSignUpCodeAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    {
      id:
        !signUpCodeAmount || signUpCodeAmount === 0
          ? 'GrabPromoLandingPage.signUpNow'
          : isScoot
          ? 'GrabPromoLandingPage.scootSchemaTitle'
          : 'GrabPromoLandingPage.schemaTitle',
    },
    { siteTitle, amount: formatMoney(intl, new Money(signUpCodeAmount, config.currency)) }
  );

  useEffect(() => {
    const { code } = parse(location.search);
    initiateEventViewedFromLandingPage({
      props,
      location,
      event: EVENT_BROWSE_VISITED_LANDING_PAGE,
    });
    if (code) {
      setLoading(true);
      checkSignUpCode(code).then(res => {
        if (res.valid) {
          setSignUpCodeAmount(res.credits);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <Page
      className={css.root}
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero
            onManageDisableScrolling={onManageDisableScrolling}
            isScoot={isScoot}
            signUpCodeAmount={signUpCodeAmount}
            loading={loading}
          />
          <div className={css.sectionProvider}>
            <div>
              <div className={css.providerTitle}>Insurance provided by</div>
              <img src={insurance} className={css.insuranceImage} />
            </div>
            <div>
              <div className={css.providerTitle}>Approved by</div>
              <img src={landTransport} className={css.landTransport} />
            </div>
          </div>
          <div className={css.desktopChannels}>
            <SectionChannels />
          </div>
          <div>
            <SectionCars contentClass={css.sectionCarsContent} />
          </div>
          <div className={css.mobileChannels}>
            <SectionChannels />
          </div>
          <div className={css.sectionStories}>
            <SectionStories />
          </div>
          <SectionHelp />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const GrabVoucherPromoLandingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(GrabVoucherPromoLandingPageComponent);

GrabVoucherPromoLandingPage.loadData = () => {
  return fetchFeaturedCar();
};

export default GrabVoucherPromoLandingPage;
