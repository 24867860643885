import React, { memo } from 'react';
import { array, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionRulesMaybe.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const SectionRulesMaybe = memo(props => {
  let index = 0;
  const {
    className,
    rootClassName,
    titleClassName,
    subTitle,
    keyRules,
    title,
    keyRule,
    selectedOptions,
    directValue
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if(directValue){
    return (
      <div className={classes}>
        <h2 className={classNames(css.title, titleClassName || css.null)}>
          <FormattedMessage id={title} />
        </h2>
         <div className={css.rule}>
        {directValue}
        </div>
      </div>
    )
  }

  return keyRules || keyRule ? (
    <div className={classes}>
      <h2 className={classNames(css.title, titleClassName || css.null)}>
        <FormattedMessage id={title} />
      </h2>
      {subTitle && (
        <p className={css.subTitle}>
          <FormattedMessage id={subTitle} />
        </p>
      )}
      {!!keyRules &&
        Array.isArray(keyRules) &&
        keyRules.length > 0 &&
        keyRules.map(rule => {
          if ((selectedOptions || []).filter(select => select === rule.key).length > 0) {
            index = index + 1;
            return (
              <div className={css.rule} key={rule.key}>
                <span className={css.index}>{index}.</span> {rule.label}
              </div>
            );
          } else {
            return null;
          }
        })}
      {!!keyRules &&
        Array.isArray(keyRules) &&
        keyRules.length > 0 &&
        selectedOptions &&
        selectedOptions.length <= 0 && (
          <div className={css.noRules}>
            <FormattedMessage id="SectionRulesMaybe.noKeyRules" />
          </div>
        )}
      {!!keyRule && typeof keyRule === 'string' && !isNaN(keyRule) && `${keyRule}km`}
      {!!keyRule && typeof keyRule === 'string' && isNaN(keyRule) && `${keyRule}`}
    </div>
  ) : null;
}, (prev, next) => {
    return isEqual(
      pick(prev, ['keyRules', 'selectedOptions']),
      pick(next, ['keyRules', 'selectedOptions']),
    )
});

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
  // publicData: shape({
  //   rules: string,
  // }).isRequired,
  keyRules: array
};

export default SectionRulesMaybe;
