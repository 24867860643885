import React, { memo } from 'react';
import css from './InstantBookingLandingPage.css';
import { ListingCard, NamedLink } from '../../components';
import isEqual from 'lodash/isEqual';
import Slider from 'react-slick';
import classNames from 'classnames';

export const SectionBrowseCar = memo(
  ({ intl, listings = [] }) => {
    const sliderConfig = {
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      centerPadding: '24px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.25,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.25,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    }
    return (
      <div className={css.sectionBrowseCar}>
        <div className={css.sectionBrowseCarDescription}>
          {intl.formatMessage({
            id: 'InstantBookingLandingPage.SectionBrowseCar.description'
          })}
        </div>
        <h3>
          <NamedLink name='SearchPage' className={classNames(css.button, css.browseCarBtn)} to={{
            search:
              `?pub_instantBooking=true&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798&showAlert=true`,
          }}>
            Browse Instant booking cars
          </NamedLink>
        </h3>
        <div className={css.listings}>
          <Slider {...sliderConfig}>
            {listings.map(l => <div>
              <ListingCard listing={l} intl={intl} />
            </div>)}
          </Slider>
        </div>
      </div>
    )
  },
  (prev, next) => isEqual(prev.listings, next.listings)
)
