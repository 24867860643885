import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPTED_PENDING_STATE,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_WITHDRAW,
} from '../../util/transaction';
import {
  TRANSITION_LTF_ACCEPT,
  TRANSITION_LTF_CONFIRM_PAYMENT,
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_CUSTOMER_CANCEL,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED,
  TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_PROVIDER_CANCEL,
  TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  TRANSITION_LTF_WITHDRAW,
} from '../../util/transactionLongTermFirst';
import {
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_CONFIRM_PAYMENT,
  TRANSITION_LTL_CUSTOMER_CANCEL,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
} from '../../util/transactionLongTermLast';
import {
  TRANSITION_LTM_CONFIRM_PAYMENT,
  TRANSITION_LTM_CUSTOMER_CANCEL,
} from '../../util/transactionLongTermMiddle';

export const getNextInteractableTransition = (transaction, isProvider) => {
  if (!transaction || !transaction.attributes || !transaction.attributes.lastTransition) return [];
  const lastTransition = transaction.attributes.lastTransition;
  switch (lastTransition) {
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE];
      }
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE];
      } else {
        return [];
      }
    case TRANSITION_ACCEPT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED];
      }
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE];
      }
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_DROP_OFF];
      }
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_DROP_OFF];
      }
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_DROP_OFF];
      }
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_REQUEST_DROP_OFF];
      }
    case TRANSITION_REQUEST_DROP_OFF:
      if (isProvider) {
        return [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE];
      } else {
        return [];
      }
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      if (isProvider) {
        return [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE];
      } else {
        return [];
      }
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_REQUEST_PICK_UP];
      }
    case TRANSITION_LTF_REQUEST_PICK_UP:
      if (isProvider) {
        return [TRANSITION_LTF_CONFIRM_PICK_UP];
      } else {
        return [];
      }
    case TRANSITION_LTL_TRIP_END:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_REQUEST_DROP_OFF];
      }
    case 'transition/long-term-last-admin-transit-trip-end': // just for test
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_REQUEST_DROP_OFF];
      }
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      if (isProvider) {
        return [TRANSITION_LTL_CONFIRM_DROP_OFF];
      } else {
        return [];
      }
    // case TRANSITION_REQUEST:
    //   if (isProvider) {
    //     return [];
    //   } else {
    //     return [];
    //   }
    default:
      return [];
  }
};

export const getNextCancelTransition = (transaction, isProvider) => {
  if (!transaction || !transaction.attributes || !transaction.attributes.lastTransition) return [];
  const lastTransition = transaction.attributes.lastTransition;
  console.log("Next cancel befroe transition", lastTransition)
  switch (lastTransition) {
    case TRANSITION_REQUEST:
    case TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED:
    case TRANSITION_ACCEPTED_PENDING_STATE:
    case TRANSITION_CONFIRM_PAYMENT:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_WITHDRAW];
      }

    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE:
    case TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE:
      if(isProvider){
        return [TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING]
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING]
      }

    case TRANSITION_CONFIRM_PAYMENT_UNVERIFIED:
      if (isProvider) {
        // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
        return []; //Resue the old accept and decline button
      } else {
        return [TRANSITION_UNVERIFIED_WITHDRAW];
      }
    case TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED:
        if (isProvider) {
          // return [TRANSITION_ACCEPT, TRANSITION_DECLINE];
          return []; //Resue the old accept and decline button
        } else {
          return [TRANSITION_UNVERIFIED_WITHDRAW_INSTANT];
    }

    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_ACCEPT:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    case TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    case TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }
    case TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR];
      }
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE];
      }
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE];
      }
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_DROP_OFF:
      return [];
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
        return [];
    case TRANSITION_LTF_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_WITHDRAW];
      }
    case TRANSITION_LTF_ACCEPT:
      if (isProvider) {
        return [TRANSITION_LTF_PROVIDER_CANCEL];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL];
      }
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      if (isProvider) {
        return [TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE];
      }
    case TRANSITION_LTF_REQUEST_PICK_UP:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED];
      }
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED];
      }
    case TRANSITION_LTM_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTM_CUSTOMER_CANCEL];
      }
    case TRANSITION_LTL_CONFIRM_PAYMENT:
      if (isProvider) {
        return [];
      } else {
        return [TRANSITION_LTL_CUSTOMER_CANCEL];
      }

    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE];
      }

    case TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP:
      if (isProvider) {
        return [TRANSITION_PROVIDER_CANCEL_REFUNDABLE];
      } else {
        return [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE];
      }

    default:
      return [];
  }
};

export const getTransitButtonLabelId = transition => {
  switch (transition) {
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_WITHDRAW:
    case TRANSITION_LTF_WITHDRAW:
      return 'TransactionPanel.withdrawButton';
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancel';
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return 'TransactionPanel.pickup';
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      return 'TransactionPanel.confirmPickup';
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return 'TransactionPanel.dropoff';
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_LTL_CONFIRM_DROP_OFF:
      return 'TransactionPanel.confirmDropoff';
    case TRANSITION_DISPUTE:
      return 'TransactionPanel.dispute';
    default:
      return null;
  }
};

export const getTransitButtonPolicyId = transition => {
  switch (transition) {
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_LTF_WITHDRAW:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_CONFIRMED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancelPolicy';
    default:
      return null;
  }
};

export const getTransitErrorLabelId = transition => {
  switch (transition) {
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    case TRANSITION_LTF_WITHDRAW:
      return 'TransactionPanel.withdrawError';
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_PROVIDER_CANCEL_AFTER_ACCEPTED_NONE_REFUNDABLE:
    case TRANSITION_LTF_CUSTOMER_CANCEL_AFTER_PICK_UP_REQUESTED:
    case TRANSITION_LTF_PROVIDER_CANCEL:
    case TRANSITION_LTL_CUSTOMER_CANCEL:
    case TRANSITION_LTM_CUSTOMER_CANCEL:
      return 'TransactionPanel.cancelError';
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return 'TransactionPanel.pickupError';
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
    case TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE:
    case TRANSITION_LTF_CONFIRM_PICK_UP:
      return 'TransactionPanel.confirmPickupError';
    case TRANSITION_REQUEST_DROP_OFF:
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return 'TransactionPanel.dropoffError';
    case TRANSITION_CONFIRM_DROP_OFF:
    case TRANSITION_REQUEST_DROP_OFF_DLGO:
    case TRANSITION_ADMIN_CONFIRM_DROP_OFF:
    case TRANSITION_LTL_CONFIRM_DROP_OFF:
      return 'TransactionPanel.confirmDropoffError';
    case TRANSITION_DISPUTE:
      return 'TransactionPanel.disputeError';
    default:
      return 'TransactionPanel.pickupError';
  }
};
