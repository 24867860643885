import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../index';
import {
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
  txIsAwaitUpdateBookingBeforePickUp,
} from '../../util/transaction';

import { formatMoney } from '../../util/currency';
import {
  EVENT_TRIP_MODIFICATION_REQUEST_ACCEPTED_HOST,
  EVENT_TRIP_MODIFICATION_REQUEST_CANCELLED_HOST,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromTransaction } from '../../util/gtm/gtmHelpers';
import { ERROR_TYPE__UPDATE_BOOKING__BLOCKED_TIME } from "../../util/api/apiErrorsTypes";

import css from './TripPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const UpdateBookingButtonsMaybe = props => {
  const {
    fetchLastUpdateBookingTxInProgress,
    className,
    rootClassName,
    showButtons,
    transaction,
    acceptInProgress,
    declineInProgress,
    onCancelUpdateBooking,
    onAcceptUpdateBooking,
    bookingUpdateTxs,
    intl,
    history,
    isLimitedUser,
    onCancelUpdateBookingByAdmin,
    onAcceptUpdateBookingByAdmin,
    isCancelUpdateBookingByAdminPending,
    isAcceptUpdateBookingByAdminPending,
  } = props;

  let currentTransition = '';
  if(txIsAwaitUpdateBookingBeforePickUp(transaction)){
    currentTransition = TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP
  }

  const updateBookingParams = {
    transactionId: transaction.id,
    listing: transaction.listing,
    bookingStart: transaction.attributes.protectedData && transaction.attributes.protectedData.childTransaction ? new Date(transaction.attributes.protectedData.childTransaction.bookingStart) : null,
    bookingEnd: transaction.attributes.protectedData && transaction.attributes.protectedData.childTransaction ? new Date(transaction.attributes.protectedData.childTransaction.bookingEnd) : null,
    protectedData: {
      childTransaction: transaction.attributes.protectedData.childTransaction
    }
  }

  const classes = classNames(rootClassName || css.actionButtons, className);

  const lastUpdateBookingTx = bookingUpdateTxs && bookingUpdateTxs[bookingUpdateTxs.length - 1];

  const payoutTotal = get(lastUpdateBookingTx, 'attributes.payoutTotal', null);

  const formattedExtraEarn = payoutTotal && formatMoney(intl, payoutTotal,1)

  return showButtons ? (
    <div className={css.buttonSectionContainer}>
      <div className={css.buttonSectionContainer}>
        <div className={css.buttonSectionContainerMobile}>
          <span>
            {lastUpdateBookingTx &&
            lastUpdateBookingTx.attributes.lastTransition ===
              TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT ? (
              <FormattedMessage
                id="TransactionPanel.extraEarnText"
                values={{ extraEarn: <span className={css.boldText}>{formattedExtraEarn}</span> }}
              />
            ) : (
              ''
            )}
          </span>
        </div>
        <div className={css.buttonSectionInner}>
          <div className={css.buttonSectionText}>
            <span>
              {/*Declining too many bookings can have a negative impact on your positioning on search.*/}
            </span>

            <span>
              {lastUpdateBookingTx &&
              lastUpdateBookingTx.attributes.lastTransition ===
                TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT ? (
                <FormattedMessage
                  id="TransactionPanel.extraEarnText"
                  values={{ extraEarn: <span className={css.boldText}>{formattedExtraEarn}</span> }}
                />
              ) : (
                ''
              )}
            </span>
          </div>

          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              inProgress={declineInProgress || fetchLastUpdateBookingTxInProgress || isCancelUpdateBookingByAdminPending}
              className={css.buttonDisabled}
              onClick={() => {
                if (isLimitedUser) {
                  onCancelUpdateBookingByAdmin(transaction).then(() => history.go(0));
                  return ;
                }

                onCancelUpdateBooking(transaction.id.uuid);
                initiateEventFromTransaction({
                  props: props,
                  transaction: transaction,
                  event: EVENT_TRIP_MODIFICATION_REQUEST_CANCELLED_HOST,
                });
              }}
            >
              <FormattedMessage id="TransactionPanel.declineButtonUpdateBooking" />
              {isLimitedUser && ' as admin'}
            </PrimaryButton>
            <SecondaryButton
              inProgress={acceptInProgress || isAcceptUpdateBookingByAdminPending}
              className={css.buttonAccepted}
              onClick={() => {
                if (isLimitedUser) {
                  onAcceptUpdateBookingByAdmin(transaction).then(() => history.go(0));
                  return ;
                }

                initiateEventFromTransaction({
                  props: props,
                  transaction: transaction,
                  event: EVENT_TRIP_MODIFICATION_REQUEST_ACCEPTED_HOST,
                });
                onAcceptUpdateBooking(transaction.id.uuid)
                  .then((res) => res === ERROR_TYPE__UPDATE_BOOKING__BLOCKED_TIME ? null : history.go(0) )
              }}
            >
              <FormattedMessage id="TransactionPanel.acceptButtonUpdateBooking" />
              {isLimitedUser && ' as admin'}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default UpdateBookingButtonsMaybe;
