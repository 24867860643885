import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_WITHDRAW,
} from '../../util/transaction';

import css from './TransactionPanel.css';
import {
  getTransitButtonLabelId,
  getTransitButtonPolicyId,
  getTransitErrorLabelId,
} from './transitionHelper';

// Functional component as a helper to build ActionButtons for
// provider/customer
export const CancelButtonsMaybe = props => {
  const {
    canShowButtons,
    transaction,
    transitInProgress,
    transitError,
    onTransit,
    onOpenCancelModal,
    transitionNames,
    openCancelPolicyModal,
  } = props;

  const buttonsDisabled = transitInProgress;
  const validTransaction = transaction && transaction.customer;
  const transitAllow = validTransaction;
    // (
    //   transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
    //     VERIFICATION_STATUS_VERIFIED);
  console.log("Cancel Button May be", validTransaction, transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus)
  const isWithDrawn = transitionNames.length > 0 && (transitionNames[0] === TRANSITION_WITHDRAW || transitionNames[0] === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT || transitionNames[0] === TRANSITION_UNVERIFIED_WITHDRAW);

  const guestIdentityIsNotVerify = !transitAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionNames[0])} />
    </p>
  ) : null;

  return canShowButtons ? (
    <div>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {transitErrorMessage}
      </div>
      <div className={css.cancelTextContainer}>
        <div
          onClick={() => {
            if (transitAllow && !buttonsDisabled) {
              onOpenCancelModal();
            }
          }}
          className={css.tooltip}
        >
          <FormattedMessage id={getTransitButtonLabelId(transitionNames[0])} />
          {buttonsDisabled && <span className={css.tooltiptext}>Cancel in progress</span>}
        </div>
        {!isWithDrawn && (
          <div onClick={openCancelPolicyModal} className={css.cancelPolicyText}>
            <FormattedMessage id={getTransitButtonPolicyId(transitionNames[0])} />
          </div>
        )}
      </div>
    </div>
  ) : null;
};
