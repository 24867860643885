import React from 'react';
import { NamedLink } from '../../components';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ReadyToGo.css';

const HIWReadyToGoSection = props => {
  const {
    isAuthenticated,
    listYourCarButtonId,
    onListYourCar,
    currentUser,
    onLoginOrSignupClick,
  } = props;

  return (
    <section
      className={classNames(
        css.readyToGoSection,
        isAuthenticated ? css.readyToGoSectionLogin : css.readyToGoSectionSmall
      )}
    >
      <div className={css.textBox}>
        <h3 className={css.textBoxTitle}>
          <FormattedMessage id="HowItWorkPage.readyToGoTitle" />
        </h3>
        <div className={css.textBoxDesc}>
          <FormattedMessage id="HowItWorkPage.readyToGoDesc" />
        </div>
        <div className={css.buttons}>
          {currentUser && currentUser.id ? (
            <p className={css.text}>Coming soon to your neighborhood!</p>
          ) : (
            <>
              <NamedLink
                name="SignupPage"
                className={css.secondaryButton}
                onClick={onLoginOrSignupClick}
              >
                <FormattedMessage id="HowItWorkPage.findACar" />
              </NamedLink>
              <NamedLink
                name="NewListingPage"
                className={css.defaultButton}
                id={listYourCarButtonId}
                onClick={() => onListYourCar(listYourCarButtonId)}
              >
                <FormattedMessage id="HowItWorkPage.listYourCar" />
              </NamedLink>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default HIWReadyToGoSection;
