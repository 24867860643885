import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingTrackingDeviceForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';

import css from './EditListingTrackingDevicePanel.css';

const EditListingTrackingDevicePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { privateData } = currentListing.attributes;
  const { isAgreeConditionsTrackingDevice, trackingDeviceId } = privateData || {};
  const [isAgreeState, setAgree] = useState(isAgreeConditionsTrackingDevice);

  const initialValues = {
    trackingDeviceId: trackingDeviceId ? trackingDeviceId.toUpperCase().includes("ADMIN") ? null : trackingDeviceId : null,
  }

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingTrackingDevicePanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingTrackingDevicePanel.createListingTitle" />
  );

  const form = <EditListingTrackingDeviceForm 
    className={css.form}
    initialValues={initialValues}
    onSubmit={(values) => {
      const { trackingDeviceId } = values;
      const updatedValues = {
        privateData: {
          trackingDeviceId: isAgreeState ? trackingDeviceId : "ADMIN FILL INFORMATION HERE",
          isAgreeConditionsTrackingDevice: isAgreeState
        }
      } 
      onSubmit(updatedValues);
    }}
    onChange={onChange}
    saveActionMsg={submitButtonText}
    disabled={disabled}
    ready={ready}
    updated={panelUpdated}
    updateInProgress={updateInProgress}
    fetchErrors={errors}
    onManageDisableScrolling={onManageDisableScrolling}
    isAgreeState={isAgreeState}
    setAgree={setAgree}
  />

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingTrackingDevicePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingTrackingDevicePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingTrackingDevicePanel;
