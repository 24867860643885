import React from 'react';
import css from './DonationPage.css';

const DonationCrisis = () => {
  return (
    <div className={css.crisisSection}>
      <div className={css.landingContainer}>
        <div className={css.landingRow}>
          <div className={css.column12}>
            <p>Because of the humanitarian crisis in Ukraine, people urgently need help.</p>
            <p>
              Drive together® contributes earnings &amp; collects donations to help people with
              food, shelter, medical care and clean drinking water.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCrisis;
