import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IntroPanelIcons.css';

const IntroPanelIcons = props => {
  const {
    rootClassName,
    className,
    iconTextStyle,
    iconList
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {iconList.map(icon => (
        <div key={`wrapper-${icon.text}`} className={css.iconWrapper}>
          <div className={css.imgWrapper}>
            <img className={css.iconImg} src={icon.imgSrc} />
          </div>
          <span className={classNames(css.iconText, iconTextStyle)}>{icon.text}</span>
        </div>
      ))}
    </div>
  )
}

IntroPanelIcons.defaultProps = {
  rootClassName: null,
  className: null,
  iconTextStyle: null,
  iconList: []
}

IntroPanelIcons.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  iconTextStyle: PropTypes.string,
  iconList: PropTypes.array
};

export default IntroPanelIcons;