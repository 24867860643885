import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FieldCheckbox, Form } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SelectSingleFilterPlain.css';

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isClear: false
    };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    if (!option) {
      this.setState({
        isClear: true
      });
    } else {
      this.setState({
        isClear: false
      });
    }
    const { urlParam, onSelect, name } = this.props;
    onSelect(urlParam, option);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      initialValue,
      twoColumns,
      useBullets,
      clearButtonClass,
      name,
      labelClassName
    } = this.props;

    const labelClass = labelClassName
      ? labelClassName
      : initialValue ? css.filterLabelSelected : css.filterLabel;

    const hasBullets = useBullets || twoColumns;
    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.hasBullets]: hasBullets,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);
    const clearButtonClasses = classNames(clearButtonClass, css.clearButton);

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={labelClass}>{label}</span>
          </button>
          <button className={clearButtonClasses} onClick={e => this.selectOption(null, e)}>
          <FormattedMessage id={'SelectSingleFilterPlain.clear'} />
          </button>
        </div>
        <div className={optionsContainerClass}>
          <FinalForm
            onSubmit={() => { }}
            initialValues={{[name]: initialValue}}
            render={fieldRenderProps => {
              const {
                form,
                formId,
                handleSubmit,
                values,
              } = fieldRenderProps;

              if (values[`${name}`] !== initialValue) {
                form.change(`${name}`, initialValue);
              }

              return (
                <Form onSubmit={() => handleSubmit}>
                  {options.map(option => {
                    // check if this option is selected
                    const selected = initialValue === option.key;
                    const optionClass = hasBullets && selected ? css.optionSelected : css.option;
                    // menu item selected bullet or border class
                    const optionBorderClass = hasBullets
                      ? classNames({
                        [css.optionBulletSelected]: selected,
                        [css.optionBullet]: !selected,
                      })
                      : classNames({
                        [css.optionBorderSelected]: selected,
                        [css.optionBorder]: !selected,
                      });
                    return (
                      <button
                        type="button"
                        key={option.key}
                        className={optionClass}
                        onClick={() => this.selectOption(option.key)}
                      >
                        {/* <span className={optionBorderClass} /> */}
                        <FieldCheckbox id={`${name}${option.key}`} name={`${name}`} value={option.key} label={option.label} checked={option.key === initialValue && !this.state.isClear ? true: false}/>
                      </button>
                    );
                  })}
                </Form>
              )
            }}
          />
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValue: null,
  twoColumns: false,
  useBullets: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  twoColumns: bool,
  useBullets: bool,
};

export default SelectSingleFilterPlain;
