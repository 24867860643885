/**
 * A text field with number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
import React from 'react';

import { FieldTextInput } from '../../components';
import { floorUnitFormat, parse } from './fiFormatter';

const FieldCustomNumberInput = props => {
  const inputProps = {
    ...props,
    type: 'text',
    format: floorUnitFormat,
    parse: parse,
  };

  return <FieldTextInput {...inputProps} />;
};

export default FieldCustomNumberInput;
