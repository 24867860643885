import { SEND_REFERRAL_INVITATION, sendEventGeneral } from '../../util/emailNotify';

// ======================== Action types ============================== //
export const SEND_INVITATION_SUCCESS = "app/ReferAHostPage/SEND_INVITATION_SUCCESS";
export const SEND_INVITATION_ERROR = "app/ReferAHostPage/SEND_INVITATION_ERROR";
export const SEND_INVITATION_IN_PROGRESS = "app/ReferAHostPage/SEND_INVITATION_IN_PROGRESS";

// ======================== Reducer ============================== //
const initialState = {
  sendInvitationError: null,
  sendInvitationInProgress: false,
}

export default function reducer(state=initialState, action = {}) {
 const { type, payload } = action;

 switch (type) {
   case SEND_INVITATION_SUCCESS: {
    return { ...state, sendInvitationInProgress: false  };
   }
   case SEND_INVITATION_ERROR: {
    return { ...state, sendInvitationError: payload, sendInvitationInProgress: false };
   }
   case SEND_INVITATION_IN_PROGRESS: {
    return { ...state, sendInvitationInProgress: true, sendInvitationError: null };
   }
   default: {
    return state;
   }
 }
}

// ========================Action creators ============================== //
export const sendInvitationSuccess = () => ({
  type: SEND_INVITATION_SUCCESS,
})

export const sendInvitationError = (err) => ({
  type: SEND_INVITATION_ERROR,
  payload: err,
})

export const sendInvitationRequest = () => ({
  type: SEND_INVITATION_IN_PROGRESS,
})

// ================ Thunk ================ //

export const sendInvitation = (data) => (dispatch, getState, sdk) => {

  dispatch(sendInvitationRequest());

  return sendEventGeneral({ ...data, eventType: SEND_REFERRAL_INVITATION })
    .then(response => {
      console.log(response);
      if (!response.ok) {
        return response.json().then(res => {
          throw res;
        });
      }
      return response.json();
    })
    .then(() => {
      dispatch(sendInvitationSuccess());
    })
    .catch(err => {
      console.error(err);
      dispatch(sendInvitationError(`Failed to invite ${data.referredEmail}. Please try again!`));
    })
}
