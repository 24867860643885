import { addMarketplaceEntities } from './marketplaceData.duck';
import config from '../config';

const apiUrl = config.apiUrl;

// ================ Action types ================ //

export const UPDATE_PARENT_TRANSACTION_REQUEST = 'app/AddonsPage/UPDATE_PARENT_TRANSACTION_REQUEST';
export const UPDATE_PARENT_TRANSACTION_SUCCESS = 'app/AddonsPage/UPDATE_PARENT_TRANSACTION_SUCCESS';
export const UPDATE_PARENT_TRANSACTION_ERROR = 'app/AddonsPage/UPDATE_PARENT_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateParentTransactionProgress: false,
  updateParentSuccess: false,
  updateParentError: false,
};

export default function transactionsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PARENT_TRANSACTION_REQUEST:
      return { ...state, updateParentTransactionProgress: true };
    case UPDATE_PARENT_TRANSACTION_SUCCESS:
      return { ...state, updateParentSuccess: true, updateParentTransactionProgress: false };
    case UPDATE_PARENT_TRANSACTION_ERROR:
      return { ...state, updateParentError: true, updateParentTransactionProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const updateParentTransactionRequest = () => ({ type: UPDATE_PARENT_TRANSACTION_REQUEST });
const updateParentTransactionSuccess = () => ({ type: UPDATE_PARENT_TRANSACTION_SUCCESS });
const updateParentTransactionError = () => ({ type: UPDATE_PARENT_TRANSACTION_ERROR });

export const updateParentTransaction = (id, params) => (dispatch, getState, sdk) => {
  dispatch(updateParentTransactionRequest());
  return fetch(apiUrl + '/api/update-parent-transaction', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId: id,
      ...params,
    }),
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(updateParentTransactionSuccess());
      return response;
    })
    .catch(error => {
      console.log(error);
      dispatch(updateParentTransactionError());
    });
};
