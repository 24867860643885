import React from 'react';
import css from './Carousel.css';
import Slider from 'react-slick';
import { ResponsiveImage } from '..';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Carousel = props => {
  const {
    images = [],
    threeToTwoWrapperClassName,
    aspectWrapperClassName,
    title,
    rootForImageClassName,
  } = props;


  return (
    <div className={css.root}>
      <Slider {...settings}>
        {
          images.map((image, index) => {
            return (
              <div key={index} className={threeToTwoWrapperClassName || css.threeToTwoWrapper}>
                <div className={aspectWrapperClassName || css.aspectWrapper}>
                  <ResponsiveImage
                    rootClassName={rootForImageClassName || css.rootForImage}
                    alt={title}
                    image={image}
                    variants={[
                      'landscape-crop',
                      'landscape-crop2x',
                      'landscape-crop4x',
                      'landscape-crop6x',
                    ]}
                  />
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  )
}

export default Carousel;
