import React from 'react';
import css from './MasterCardLandingPage.css';

export const SectionTermOfPromotion = ({ intl }) => {
  return (
    <div className={css.sectionTermOfPromotion}>
      <div className={css.termOfPromotionTitle}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.title'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description1'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description2'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description3'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description4'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description5'
        })}
      </div>
      <div className={css.termOfPromotionDescription}>
        {intl.formatMessage({
          id: 'MasterCardLandingPage.SectionTermOfPromotion.description6'
        })}
      </div>
    </div>
  )
}
