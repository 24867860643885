import React from 'react';
import classNames from 'classnames';
import { AvatarMedium, ResponsiveImage } from '../../components';

import css from './BookingInfoSection.css';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    listingTitle,
    image,
    user,
    isCustomer,
    showAvatar,
    wrapperClassName,
    imageClassName,
  } = props;
  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);
  const wrapperClasses = classNames(wrapperClassName || css.aspectWrapper);
  const imageClasses = classNames(imageClassName || css.rootForImage);
  return (
    <React.Fragment>
      <div className={classes}>
        <div className={wrapperClasses}>
          <ResponsiveImage
            rootClassName={imageClasses}
            alt={listingTitle}
            image={image}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
        </div>
      </div>
      {showAvatar ? (
        <div className={avatarWrapperClassName || css.avatarWrapper}>
          <AvatarMedium user={user} />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DetailCardImage;
