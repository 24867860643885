import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';

import videoLogo from '../../assets/landingpage/videoLogo.png';
import css from './SectionVideo.css';
import { sendGAEvent } from '../../util/googleAnalytics';

const ModalVideo = props => {
  return (
    <div className={css.videoContentWrapper}>
      <iframe
        id="videoIframe"
        className={css.iframeVideo}
        allowFullScreen={true}
        src="https://www.youtube.com/embed/OaOzjZiVaws">
      </iframe>
    </div>
  )
}

class SectionVideo extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOpen = this.handleOpen.bind(this);
    this.videoPlayer = null;
  }

  handleOpen() {
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    this.setState({ isOpen: true })
  }

  render() {
    const {
      onManageDisableScrolling
    } = this.props;

    return (
      <div className={css.root}>
        <div className={css.textWrapper}>
          <div className={css.title}>
            <FormattedMessage id="SectionVideo.titleTemp" />
          </div>
          <div className={css.subtitle}>
            <div className={css.subtitleLine1}>
              <FormattedMessage id="SectionVideo.subtitleTemp1" />
            </div>
            <div className={css.subtitleLine2}>
              <span className={css.subtitle2} onClick={this.handleOpen} id="openHowItWorksVideo">
                <FormattedMessage id="SectionVideo.subtitleTemp2" />
              </span>
              <span>
                <FormattedMessage id="SectionVideo.subtitleTemp3" />
              </span>
            </div>
          </div>
        </div>
        {/* <div className={css.buttonWrapperTemp}>
          <NamedLink
            name="HowItWorkPage"
            className={classNames(css.buttonVideo)}
          >
            <FormattedMessage id="SectionVideo.findOutButtonTemp" />
          </NamedLink>
        </div> */}
        <div className={css.videoWrapper}>
          <div
            onClick={this.handleOpen}
            className={css.video}
          >
            <img className={css.videoLogo} src={videoLogo} alt="Car rental with more choice and less hassle | Drive Mate" />
          </div>
        </div>
        <Modal
          id="videoLandingpage"
          {...this.props}
          containerClassName={css.modalContainer}
          closeButtonClassName={css.modalCloseButton}
          contentClassName={css.contentModal}
          isOpen={this.state.isOpen}
          onClose={() => {
            const videoIframe = document.querySelector("#videoIframe");
            if (videoIframe) {
              const iframeSrc = videoIframe.src;
              videoIframe.src = iframeSrc;
            }
            this.setState({ isOpen: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ModalVideo />
        </Modal>
      </div>
    )
  }
}

SectionVideo.defaultProps = {

}

SectionVideo.propTypes = {

}

export default SectionVideo;
