import React from 'react';
import css from './BrandingPage.css';
import classNames from 'classnames';
import carIcon from '../../assets/carIcon2.svg';
import shareIcon from '../../assets/share1.svg';
import earn from '../../assets/earn.svg';
import notepad from '../../assets/notepad.svg';

const Guarantee = () => {

  return (
    <div className={classNames(css.landingContainer,css.guaranteeContainer)}>
        <div className={css.row}>
            <div className={css.contentTitle}>Here’s what we’ll guarantee when you become a new host</div>
            <div className={css.cardContent}>
                <div className={css.card}>
                    <img src={earn} />
                    <h4 className={css.cardTitle}>Additional income</h4>
                    <p className={css.cardPara}>20% higher earnings than other car sharing platforms.</p>
                </div>

                <div className={css.card}>
                    <img src={notepad} />
                    <h4 className={css.cardTitle}>Genuine Renters Only</h4>
                    <p className={css.cardPara}>Guests are legitimately verified for added security and the ultimate peace of mind.</p>
                </div>

                <div className={css.card}>
                    <img src={carIcon} />
                    <h4 className={css.cardTitle}>You’re Fully-Covered</h4>
                    <p className={css.cardPara}>You are protected from vehicle theft or damage due to accident, fire or storm, or malicious damage.</p>
                </div>

                <div className={css.card}>
                    <img src={shareIcon} />
                    <h4 className={css.cardTitle}>Added Convenience Provided by Proprietary Technology</h4>
                    <p className={css.cardPara}>Keyless access, telemetry data utilisation and remote immobilisation.</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Guarantee;
