import get from 'lodash/get';
import React, { useState } from 'react';
import { obfuscatedCoordinates } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
import Map from '../Map/Map';
import classNames from 'classnames';
import css from './TripPanel.css';

const { LatLng } = sdkTypes;

const CustomerMap = ({ transaction, className, showAddress, geolocation }) => {
  const [isStatic, setStatic] = useState(true);
  geolocation = geolocation || get(transaction, 'attributes.protectedData.customerLocation.selectedPlace.origin', { lat: 0, lng: 0 });

  if (!geolocation) {
    return null;
  }

  const address = get(transaction, 'attributes.protectedData.customerLocation.selectedPlace.address', '');
  const { lat, lng } = geolocation;
  const cacheKey = transaction.id ? `${transaction.id.uuid}_${lat}_${lng}` : null;
  const hideAddress = !showAddress;

  const mapProps = hideAddress
    ? {
        obfuscatedCenter: obfuscatedCoordinates({ lat, lng }, cacheKey),
        hideAddress,
      }
    : {
        address,
        center: new LatLng(lat, lng),
        hideAddress,
      };
  const map = <Map {...mapProps} useStaticMap={isStatic} hideAddress={hideAddress} />;
  return (
    <div>
      {isStatic ? (
        <button
          className={classNames(css.map, className)}
          onClick={() => {
            setStatic(false);
          }}
        >
          {map}
        </button>
      ) : (
        <div className={classNames(css.map, className)}>{map}</div>
      )}
    </div>
  );
};

export default CustomerMap;
