import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import css from '../BookingBreakdown.css';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemMiddleMonthlyLongTerm = ({ transaction, parentTransaction, isCustomer, intl }) => {

  const payinTotal = get(transaction, 'attributes.payinTotal.amount', 0);
  const payinTotalParent = get(parentTransaction, 'attributes.payinTotal.amount', 0);
  const entireMiddleMonths = get(transaction, 'attributes.protectedData.entireMiddleMonths');
  const nextPaymentType = get(parentTransaction, 'attributes.protectedData.longTermNextPaymentType');
  const monthlyPrice = get(parentTransaction, 'listing.attributes.publicData.longTermPrice.amount', 99900);
  const longTermTotalTripPrice = get(parentTransaction, 'attributes.protectedData.longTermTotalTripPrice', 99900);

  let currentPaid = get(parentTransaction, 'attributes.metadata.longTermPaymentMade');
  if (!currentPaid) {
    currentPaid = get(parentTransaction, 'attributes.payinTotal.amount', 0);
  }
  let remainPayment = longTermTotalTripPrice - payinTotal - currentPaid;

  let nextPaymentPrice = monthlyPrice;
  if (!monthlyPrice) {
    return null;
  }

  if (nextPaymentType === 'LTM') {
    if (entireMiddleMonths >= 2){
      nextPaymentPrice = 0.5*payinTotalParent;
    } else {
      nextPaymentPrice = remainPayment;
    }
  } else if (nextPaymentType === 'LTL') {
    nextPaymentPrice = 0;
  } else if (nextPaymentType === 'EMPTY') {
    nextPaymentPrice = 0;
  }

  if (!isCustomer) {
    return null;
  }

  nextPaymentPrice = new Money(nextPaymentPrice, config.currency);
  nextPaymentPrice = nextPaymentPrice ? formatMoney(intl, nextPaymentPrice) : null;

  return nextPaymentPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdownLongTerm.nextMonthlyPayment" />
      </span>
      <span className={css.itemValue}>{nextPaymentPrice}</span>
    </div>
  ) : null;
};

export default LineItemMiddleMonthlyLongTerm;
