import React, { useEffect, useState } from "react";
import styles from './VerificationWhileBooking.module.css'
import TopbarDesktop from "../TopbarDesktop/TopbarDesktop";
import { TopbarContainer } from "../../containers";
import Logo from "../Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import NamedLink from "../NamedLink/NamedLink";
import Enable from '../../assets/verificationPage/Enable.svg';
import Disable from '../../assets/verificationPage/Disable.svg';
import IdentityVerification from '../../assets/verificationPage/IdentityVerification.svg';
import Card from '../../assets/verificationPage/Card.svg';
import PhoneNumber from '../../assets/verificationPage/PhoneNumber.svg';
import { get } from "lodash";
import VerificationSteps from "../VerificationSteps/VerificationSteps";
import Button from "../Button/Button";
import { updateUserData } from "../../ducks/user.duck";
import Page from "../Page/Page";
import LayoutWrapperMain from "../LayoutWrapperMain/LayoutWrapperMain";
import LayoutWrapperTopbar from "../LayoutWrapperTopbar/LayoutWrapperTopbar";
import { verifyIdentifyFromServer } from "../../util/identityVerificationHelpers";

const VerificationWhileBooking = () => {

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state && state.user && state.user.currentUser);

  const guestIdentityVerificationStatus = get(currentUser, 'attributes.profile.publicData.guestIdentityVerificationStatus', '');

  const isPhoneVerified = get(currentUser, 'attributes.profile.protectedData.phoneNumberVerified', false);
  const isSubmittedOnfidoVerification = get(currentUser, 'attributes.profile.publicData.onFidoCheckId', false);
  const isCardVerified = get(currentUser, 'attributes.profile.protectedData.isPaymentMethodVerified', false);

  console.log('All 3 checks >', { isPhoneVerified, isSubmittedOnfidoVerification, isCardVerified });

  const stepSetter = () => {
    if (isPhoneVerified && isSubmittedOnfidoVerification) return 2;
    else if (isPhoneVerified) return 1;
    else return 0;
  }

  const [currentStep, setCurrentStep] = useState(stepSetter());
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentStep(stepSetter());
    if (isPhoneVerified && isSubmittedOnfidoVerification && isCardVerified && !identityStatusPresent) {
      const publicData = { guestIdentityVerificationStatus: 'pending' }
      dispatch(updateUserData({ publicData })).then(() => {
        verifyIdentifyFromServer({
          userId: currentUser.id.uuid,
          submitType: 'guest',
        });
      });
    }

    if (currentUser && guestIdentityVerificationStatus !== '') {
      setIsLoading(false);
    }
    // !verificationInProgress && setIsLoading(!isLoading);
  }, [currentUser]);

  console.log('Is verification in progress ??', verificationInProgress);

  const identityStatusPresent = !!guestIdentityVerificationStatus;

  const headingText = (verificationInProgress, guestIdentityVerificationStatus) => {
    if (verificationInProgress) return 'Complete Verification';
    if (guestIdentityVerificationStatus === 'confirmed') return 'Your account is verified';
    if (guestIdentityVerificationStatus === 'pending') return 'Verification Pending';
    if (guestIdentityVerificationStatus === 'declined') return 'Your verification is declined';
    return 'Just one step away from booking';
  }

  console.log('identityStatusPresent ??', identityStatusPresent);
  console.log('headingtext ??', headingText);

  const btnName = (identityStatusPresent && guestIdentityVerificationStatus) ? 'Return to home' : 'Get verified now';

  const onBottomBtnClickHandler = () => !verificationInProgress && !identityStatusPresent && setVerificationInProgress(true);

  return (
    <>
      <Page title='User Verification'>
        <LayoutWrapperTopbar>
          <TopbarContainer />
          {/* <div className={`${styles.root} ${styles.topNav}`}>
        <NamedLink className={styles.logoLink} name={`LandingPage`}>
          <Logo
            format="desktop"
            className={styles.logo}
            alt='Drive mate logo'
          />
        </NamedLink>
      </div> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={styles.outerContainer}>
            {/* Heading */}
            <p>
              {!isLoading && headingText(verificationInProgress, guestIdentityVerificationStatus)}
            </p>

            <div className={styles.verificationProcessContainer}>

              {isLoading && (
                <p>Loading Data...</p>
              )}

              {/* When verification is not complete and guestIdentityVerificationStatus is not Present on flex */}
              {!isLoading && !verificationInProgress && !identityStatusPresent && (
                <div className={styles.verificationFlowContainer}>
                  <div>
                    <p>
                      We need a few more details before you can rent with us. This step ensures we can create a secure and trusting community for car sharing.
                    </p>
                  </div>

                  <div className={styles.listOfVerifiations}>
                    <p>
                      <span><img src={PhoneNumber} /></span>
                      <span>Pesonal Details</span>
                      <span><img src={isPhoneVerified ? Enable : Disable} /></span>
                    </p>
                    <p>
                      <span><img src={IdentityVerification} /></span>
                      <span>Identity Verification</span>
                      <span><img src={isSubmittedOnfidoVerification ? Enable : Disable} /></span>
                    </p>
                    <p>
                      <span><img src={Card} /></span>
                      <span>Credit Verification</span>
                      <span><img src={isCardVerified ? Enable : Disable} /></span>
                    </p>
                  </div>
                </div>
              )}

              {/* When verification is inProgress and guestIdentityVerificationStatus is not present on flex */}
              {!isLoading && !identityStatusPresent && verificationInProgress && (
                <div className={styles.verificationFlowContainer}>
                  <VerificationSteps
                    currentStep={currentStep}
                    settingSteps={setCurrentStep}
                    currentUser={currentUser}
                    // dateOfBirth={dateOfBirth}
                    // transaction={props.transaction}
                    // transactionId={props.transactionID}
                    // nextInteractableTransitions={props.nextInteractableTransitions}
                    // haveNextTransit={haveNextTransitNew}
                    // onTransit={onTransitNew}
                    onUpdateUserData={data => dispatch(updateUserData(data))}
                    isHost={false}
                    isCardVerified={isCardVerified}
                  />
                </div>
              )}

              {/* When verification is complete and guestIdentityVerificationStatus is Pending on felex */}
              {(!isLoading && identityStatusPresent && guestIdentityVerificationStatus === 'pending') && (
                <div className={styles.verificationFlowContainer}>
                  <div>
                    <p>
                      Your profile verification is in progress. Our team usually completes verification in under 2 hours. We’ll let you know via email and text as soon as we’re finished.
                    </p>
                  </div>
                </div>
              )}

              {/* When verification is complete and guestIdentityVerificationStatus is Confirmed on felex */}
              {(!isLoading && identityStatusPresent && guestIdentityVerificationStatus === 'confirmed') && (
                <div className={styles.verificationFlowContainer}>
                  <div>
                    <p>
                      Your account is verified, thanks.
                    </p>
                  </div>

                  <div className={styles.listOfVerifiations}>
                    <p>
                      <span><img src={PhoneNumber} /></span>
                      <span>Pesonal Details</span>
                      <span><img src={isPhoneVerified ? Enable : Disable} /></span>
                    </p>
                    <p>
                      <span><img src={IdentityVerification} /></span>
                      <span>Identity Verification</span>
                      <span><img src={isSubmittedOnfidoVerification ? Enable : Disable} /></span>
                    </p>
                    <p>
                      <span><img src={Card} /></span>
                      <span>Credit Verification</span>
                      <span><img src={isCardVerified ? Enable : Disable} /></span>
                    </p>
                  </div>
                </div>
              )}

              {/* When verification is complete and guestIdentityVerificationStatus is Pending on felex */}
              {(!isLoading && identityStatusPresent && guestIdentityVerificationStatus === 'declined') && (
                <div className={styles.verificationFlowContainer}>
                  <div>
                    <p>
                      According to the documents you have provided, we were unable to verify your account, and as a result, you are not authorized to rent on Drive mate. Please contact our team if you have any questions.
                    </p>
                  </div>
                </div>
              )}

            </div>
          </div>
          {!isLoading && !verificationInProgress && !identityStatusPresent && (
            <div className={styles.bottomBar}>
              <button type="button" onClick={onBottomBtnClickHandler}>{btnName}</button>
            </div>
          )}

          {!isLoading && !verificationInProgress && identityStatusPresent && (
            <div className={styles.bottomBar}>
              <NamedLink name='LandingPage' className={styles.bottomButtonClass}>
                <button type="button" onClick={onBottomBtnClickHandler} className={styles.reutrnToHomeBtn}>{btnName}</button>
              </NamedLink>
            </div>
          )}
        </LayoutWrapperMain>
      </Page>
    </>
  );
};

export default VerificationWhileBooking;