import axios from 'axios';
import * as log from './log';

export const drivelahApiGet = async (url, options = {}) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/${url}`, options);
    return result;
  } catch (error) {
    log.error(error, 'axios-get-fail', { url });
    throw new Error(error);
  }
};

export const drivelahApiPost = async (url, data = {}, throwIfError = true) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/${url}`, data);
    return result;
  } catch (error) {
    const errorData = data || {};
    log.error(error, 'axios-post-fail', { ...errorData, url });
    if (throwIfError) {
      throw error;
    }
  }
};

export const drivelahApiPut = async (url, data = {}) => {
  try {
    const result = await axios.put(`${process.env.REACT_APP_API_SERVER_URL}/api/${url}`, data);
    return result;
  } catch (error) {
    const errorData = data || {};
    log.error(error, 'axios-put-fail', { ...errorData, url });
    throw new Error(error);
  }
};
