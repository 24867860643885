/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import css from './SeoLandingPage.css';
import isEqual from 'lodash/isEqual';

const SeoLandingWhyRentCar = memo(
  () => {
    return (
      <div className={css.whyRentCarSection}>
        <div className={css.whyRentCarContainer}>
          <div className={css.whyRentCarTitle}>Why rent cars with Drive mate</div>
          <div className={css.whyRentCarListing}>
            <ul className={css.listings}>
              <li className={css.listingsItem}>
                <div className={css.listingsItemContent}>
                  <div className={css.listingsTitle}>Affordable &amp; transparent pricing</div>
                  <p>
                    Registration and verification is free. We only charge a small fee to provide a
                    fully secured &amp; convenient experience to you including insurance. In the
                    end, you save up to 30-40% on your car rentals.
                  </p>
                </div>
              </li>
              <li className={css.listingsItem}>
                <div className={css.listingsItemContent}>
                  <div className={css.listingsTitle}>Cars always nearby</div>
                  <p>
                    With a growing sharing community, you will be sure to find a car in your
                    neighbourhood. Sign up to browse through all nearby cars available and find the
                    perfect fit.
                  </p>
                </div>
              </li>
              <li className={css.listingsItem}>
                <div className={css.listingsItemContent}>
                  <div className={css.listingsTitle}>Comprehensive insurance for all trips</div>
                  <p>
                    In the unlikely case of an accident/damage, the vehicle is comprehensively
                    covered via our insurance. The insurance starts automatically when you pick up
                    the car and is valid till the time you return it.
                  </p>
                </div>
              </li>
              <li className={css.listingsItem}>
                <div className={css.listingsItemContent}>
                  <div className={css.listingsTitle}>24/7 road assistance</div>
                  <p>All Drive mate trips come with full roadside assistance.</p>
                </div>
              </li>
              <li className={css.listingsItem}>
                <div className={css.listingsItemContent}>
                  <div className={css.listingsTitle}>Helpdesk available</div>
                  <p>
                    24/7 support for every step of the way. Just give us a call and we are more than
                    happy to assist you.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SeoLandingWhyRentCar;
