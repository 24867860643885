import React from 'react';
import classNames from 'classnames';
import css from './Grid.css';

export const Container = ({ className, children }) => {
  const classes = classNames(css.container, className);
  return <div className={classes}>{children}</div>;
};

export const Row = ({ className, children, alignItems, justifyContent }) => {
  const classes = classNames(
    css.row,
    className,
    alignItems === 'center' && css[`alignItemsCenter`],
    alignItems === 'end' && css[`alignItemsFlexEnd`],
    alignItems === 'star' && css[`alignItemsFlexStart`],
    justifyContent === 'center' && css[`justifyContentCenter`],
    justifyContent === 'end' && css[`justifyContentFlexEnd`],
    justifyContent === 'star' && css[`justifyContentFlexStart`]
  );
  return <div className={classes}>{children}</div>;
};

export const Column = ({ className, small, medium, large, xLarge, children }) => {
  const classes = classNames(
    css.column,
    className,
    large && css[`columnLarge${large}`],
    small && css[`columnSmall${small}`],
    xLarge && css[`columnXLarge${xLarge}`],
    medium && css[`columnMedium${medium}`]
  );
  return <div className={classes}>{children}</div>;
};
