import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './CreditsDetailsPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';

export const CreditsDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,

    connectCalendarTabVisibility,
    fetchCalendarVisibility,
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
  }, [])

  let tabs = [...config.custom.tabs];
  const user = ensureCurrentUser(currentUser);
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (!(user && ensureHostVerified(user))) {
      if (tab.linkProps.name === "MonthlyStatementPage") {
        return false;
      }
    }
    if (tab.linkProps.name === "SyncCalenderPage" ){
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  })
  const currentTab = tabs.find((tab) => tab.linkProps.name === "CreditsDetailsPage")
  if (currentTab){
    currentTab.selected = true;
  }

  const balanceInformation = user && user.id ? (
    <React.Fragment>
      <FormattedMessage
      id="CreditsDetailsPage.creditsBalance"
      values={{
        values: currentUser.attributes.credits
      }}
       />
    </React.Fragment>
  ) : null;

  const title = intl.formatMessage({ id: 'CreditsDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="CreditsDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="CreditsDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={user} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              {/* <FormattedMessage id="CreditsDetailsPage.heading" /> */}
            </h1>
            {balanceInformation}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

CreditsDetailsPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

CreditsDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    connectCalendarTabVisibility
  };
};

const mapDispatchToProps = dispatch => ({
   fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus())

});

const CreditsDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CreditsDetailsPageComponent);

CreditsDetailsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default CreditsDetailsPage;
