import config from '../config';
import axios from 'axios';

const apiUrl = config.apiUrl;

export const PHONE_VERIFIED = 'phone_number_verified';
export const USED_PHONE = 'phone_number_used_by_other';
export const INVALID_PHONE = 'is not a valid phone number.';
const PHONE_NOT_VERIFIED = 'phone_number_not_verified';

const axiosInstant = axios.create({
  baseURL: apiUrl,
});

export const sendOTP = (userId, phoneNumber) => {
  return fetch(apiUrl + '/api/phone-verification', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: userId, phoneNumber: phoneNumber }),
  });
};

export const verifyOTP = (userId, phoneNumber, code) => {
  return fetch(apiUrl + '/api/phone-verification/verify', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: userId, phoneNumber: phoneNumber, code: code }),
  });
};

export const checkVerifiedPhone = (userId, phoneNumber) => {
  const queryObj = {
    userId: userId,
    phoneNumber: phoneNumber,
  };
  const query = objToQueryString(queryObj);

  return fetch(apiUrl + '/api/phone-verification/check?' + query, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const changePhoneNumber = (userId, newPhone, oldPhone) => {
  return fetch(apiUrl + '/api/phone-verification/change', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: userId, newPhoneNumber: newPhone, oldPhoneNumber: oldPhone }),
  });
};

export const verifyIdentifyFromServer = ({ userId, submitType }) => {
  return axiosInstant.post('/api/user-verification', {
    userId,
    submitType,
  })
};

export const verifyIdentifyOnfidoFromServer = ({ userId }) => {
  return axiosInstant.post('/api/user-verification/onfido', {
    userId
  })
};

const objToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
};

