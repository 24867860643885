import React from 'react';
import css from './LandingPageGoHost.css';
import { FormattedMessage } from 'react-intl';

const Install = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101.509" height="99.236" viewBox="0 0 101.509 99.236">
      <g id="Group_379" data-name="Group 379" transform="translate(0 -0.543)">
        <path id="Fill_1" data-name="Fill 1" d="M43.242,0,39.81,3.805a2.266,2.266,0,0,0-.485,2.164L22.992,24.079a.542.542,0,0,1-.613.139,3.535,3.535,0,0,0-4.172,1.116L1.808,40.74a5.776,5.776,0,1,0,8.552,7.712L24,30.554a3.535,3.535,0,0,0,.682-4.265.542.542,0,0,1,.074-.624L41.083,7.555a2.265,2.265,0,0,0,2.1-.7l3.432-3.806Z" transform="translate(22.559 13.173)" fill="#026786" />
        <path id="Fill_3" data-name="Fill 3" d="M8.176,49.606a8.125,8.125,0,0,1-3.29-.69A8.18,8.18,0,0,1,9.694,33.391a1.008,1.008,0,0,0,.186.017,1.078,1.078,0,0,0,.789-.353L30.174,11.834a1.069,1.069,0,0,0,.234-1.06A8.178,8.178,0,0,1,39.277.077L34.4,5.486l1.636,5.05,5.192,1.108,4.879-5.411a8.179,8.179,0,0,1-9.728,9.923,1.035,1.035,0,0,0-.225-.025,1.078,1.078,0,0,0-.8.367l-19.1,21.587a1.074,1.074,0,0,0-.232,1.007,8.246,8.246,0,0,1,.156,4.073,8.177,8.177,0,0,1-7.995,6.44Zm1.11-13.372L4.236,37.87,3.127,43.061,7.07,46.617l5.05-1.637,1.108-5.191Z" transform="translate(37.102 24.422)" fill="#026786" />
        <g id="Group_7" data-name="Group 7" transform="translate(0 0.323)">
          <path id="Fill_5" data-name="Fill 5" d="M25.029,99.236l3.1-3.306c2.89-3.084,6.589-7.813,7.8-12.824l.1-.413-.415-.1c-9.341-2.183-18.333-8.057-25.321-16.54C3.848,58.229,0,49.052,0,41.505,0,18.62,22.768,0,50.754,0s50.755,18.62,50.755,41.505c0,11.682-3.793,21.7-10.97,28.962l-2.32-2.292c6.561-6.642,10.029-15.865,10.029-26.671,0-21.088-21.305-38.245-47.494-38.245S3.261,20.417,3.261,41.505c0,13.956,14.458,33.558,33.1,37.915l3.615.846L39.1,83.873a29.867,29.867,0,0,1-5.652,10.851c7.745-1.534,17.819-5.091,21.5-13.33l.974-2.179H58.31a50.015,50.015,0,0,0,15.223-2.235l.992,3.107A53.276,53.276,0,0,1,58.31,82.475h-.273l-.111.249c-5.077,11.359-19.974,14.861-28.4,15.938Z" transform="translate(0 0.221)" fill="#026786" />
        </g>
      </g>
    </svg>
  )
}

const Download = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="56.495" height="99.522" viewBox="0 0 56.495 99.522">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_380" data-name="Group 380" transform="translate(0 -0.413)">
        <g id="Group_3" data-name="Group 3" transform="translate(0 0.349)">
          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
          <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
            <path id="Fill_1" data-name="Fill 1" d="M53.6,28.928V91.855c0,1.923-2.526,4.775-6.015,4.775H8.907a6.021,6.021,0,0,1-6.014-6.014V8.907A6.021,6.021,0,0,1,8.907,2.893H47.588c3.541,0,6.015,2.983,6.015,7.254v3.777H56.5V10.147C56.5,4.267,52.749,0,47.588,0H8.907A8.917,8.917,0,0,0,0,8.907V90.615a8.917,8.917,0,0,0,8.907,8.907H47.588c4.119,0,8.907-3.349,8.907-7.667V28.928Z" transform="translate(0 0.064)" fill="#026786" />
          </g>
        </g>
        <path id="Fill_4" data-name="Fill 4" d="M18.161,1.653H.826A.826.826,0,0,1,.826,0H18.161a.826.826,0,1,1,0,1.653" transform="translate(18.754 62.095)" fill="#026786" />
        <path id="Fill_6" data-name="Fill 6" d="M15.329,16.5a1.654,1.654,0,0,0-2.32.288l-3.373,4.33V1.653a1.653,1.653,0,1,0-3.306,0V21.115l-3.373-4.33A1.653,1.653,0,0,0,.349,18.817l7.634,9.8,7.634-9.8a1.653,1.653,0,0,0-.288-2.32" transform="translate(20.264 29.007)" fill="#026786" />
      </g>
    </svg>
  )
}

const Unlock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="56.495" height="99.522" viewBox="0 0 56.495 99.522">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_381" data-name="Group 381" transform="translate(0 -0.413)">
        <g id="Group_3" data-name="Group 3" transform="translate(0 0.349)">
          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H56.495V99.522H0Z" transform="translate(0 0.064)" fill="none" />
          <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
            <path id="Fill_1" data-name="Fill 1" d="M53.6,28.928V91.855c0,1.923-2.526,4.775-6.015,4.775H8.907a6.021,6.021,0,0,1-6.014-6.014V8.907A6.021,6.021,0,0,1,8.907,2.893H47.588c3.541,0,6.015,2.983,6.015,7.254v3.777H56.5V10.147C56.5,4.267,52.749,0,47.588,0H8.907A8.917,8.917,0,0,0,0,8.907V90.615a8.917,8.917,0,0,0,8.907,8.907H47.588c4.119,0,8.907-3.349,8.907-7.667V28.928Z" transform="translate(0 0.064)" fill="#026786" />
          </g>
        </g>
        <path id="Fill_4" data-name="Fill 4" d="M5.01,21.266v0a8.424,8.424,0,0,1-3.9-.908l-.155-.111c-.925-.833-1-2.348-.936-3.86A10.97,10.97,0,0,1,.6,13.032,3.7,3.7,0,0,1,1.765,11.69l.142-.111.035-.072c.1-.2.2-.46.317-.758a18.22,18.22,0,0,1,.9-2.007A16.745,16.745,0,0,1,7.227,3.748,16.109,16.109,0,0,1,16.16.152a30.663,30.663,0,0,1,6.711.071A9.457,9.457,0,0,1,28.9,3.19a20.418,20.418,0,0,1,1.848,2.28c.2.288.677.37,1.431.481,4.205.615,7.171,2.269,9.068,5.057a8.657,8.657,0,0,1,1.608,5.283,4.242,4.242,0,0,1-.967,2.635,5.078,5.078,0,0,1-3.194,1.4l-.137.012-7,0V18.686l6.935,0a3.55,3.55,0,0,0,2.162-.852,2.658,2.658,0,0,0,.556-1.637,7.01,7.01,0,0,0-1.325-4.258c-1.621-2.383-4.219-3.806-7.94-4.35A3.373,3.373,0,0,1,29.4,6.425a18.921,18.921,0,0,0-1.706-2.107,7.849,7.849,0,0,0-5.006-2.453,28.967,28.967,0,0,0-6.347-.07A14.43,14.43,0,0,0,8.321,4.987a15.224,15.224,0,0,0-3.7,4.536A16.75,16.75,0,0,0,3.8,11.355c-.129.33-.242.617-.369.878l-.2.413L2.777,13a2.113,2.113,0,0,0-.7.78,9.246,9.246,0,0,0-.407,2.679A4.889,4.889,0,0,0,2,18.963a6.946,6.946,0,0,0,3,.65c.256,0,.516-.014.773-.039l.348-.035.165,1.644-.349.035c-.312.031-.626.047-.935.047" transform="translate(7.152 36.239)" fill="#026786" />
        <path id="Fill_6" data-name="Fill 6" d="M16.128,10.512h-.143V7.267a7.267,7.267,0,0,0-14.535,0v.349H3.929V7.267a4.788,4.788,0,0,1,9.576,0v3.245H1.855A1.856,1.856,0,0,0,0,12.367V22.852a1.855,1.855,0,0,0,1.855,1.855H16.128a1.856,1.856,0,0,0,1.856-1.855V12.367a1.856,1.856,0,0,0-1.856-1.856" transform="translate(17.781 43.98)" fill="#026786" />
      </g>
    </svg>
  )
}

const Securely = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="81.757" height="99.292" viewBox="0 0 81.757 99.292">
      <g id="Group_382" data-name="Group 382" transform="translate(0 -0.446)">
        <path id="Fill_1" data-name="Fill 1" d="M17.25,63.019H8.844A1.341,1.341,0,0,1,7.505,61.68V59.355h-.36A7.145,7.145,0,0,1,.037,51.483l1.412-13.8a7.8,7.8,0,0,1,7.787-7.715h1.128V1.473A1.475,1.475,0,0,1,11.837,0h2.421a1.475,1.475,0,0,1,1.473,1.472V3.288a.415.415,0,0,1-.414.415H15.2a.414.414,0,0,0-.414.413V7.635a.414.414,0,0,0,.414.414h.113a.414.414,0,0,1,.414.414v2.83a.414.414,0,0,1-.414.414H15.2a.414.414,0,0,0-.414.414v1.14a.414.414,0,0,0,.414.414h.113a.414.414,0,0,1,.414.414v1.786a.414.414,0,0,1-.414.414H15.2a.415.415,0,0,0-.414.415v5.519a.414.414,0,0,0,.414.414h.113a.414.414,0,1,1,0,.828H15.2a.415.415,0,0,0-.414.415V25.02a.414.414,0,0,0,.414.414h.113a.414.414,0,0,1,.414.414v4.119H16.9a7.8,7.8,0,0,1,7.787,7.715l1.412,13.8a7.147,7.147,0,0,1-7.108,7.872H18.59V61.68A1.341,1.341,0,0,1,17.25,63.019Zm-7.96-3.664v1.878H16.8V59.355ZM9.236,32.646a5.114,5.114,0,0,0-5.108,5.108l-.007.136L2.7,51.755a4.466,4.466,0,0,0,4.443,4.922H18.995a4.466,4.466,0,0,0,4.443-4.922l-1.426-14A5.114,5.114,0,0,0,16.9,32.646Z" transform="translate(27.809 19.901)" fill="#026786" />
        <g id="Group_5" data-name="Group 5" transform="translate(0 0.184)">
          <path id="Fill_3" data-name="Fill 3" d="M77.2,42.354C74.05,61.143,66.26,73.673,59.478,81.38A59.859,59.859,0,0,1,40.878,95.9a59.868,59.868,0,0,1-18.6-14.517C13.7,71.627,3.493,54.159,3.135,26.041,23.1,25.267,36.964,9.98,40.978,4.979,45.5,9.823,61.137,25.147,78.622,26.031c0,.038,0,.075,0,.112h3.118c.008-.544.019-1.083.019-1.635V22.946H80.194c-19.529,0-37.946-21.212-38.129-21.426L40.761,0,39.6,1.633c-.151.213-15.367,21.313-38.041,21.313H0v1.563C0,54.593,10.9,73.236,20.05,83.578,30.009,94.838,39.88,98.89,40.3,99.057l.583.234.583-.234c.415-.167,10.286-4.219,20.246-15.479,7.18-8.118,15.441-21.355,18.662-41.224Z" transform="translate(0 0.262)" fill="#026786" />
        </g>
      </g>
    </svg>
  )
}


const SectionHowDrivelahGoWork = () => {
  return (
    <div className={css.sectionHowDrivelahGoWork}>
      <div className={css.sectionHowDrivelahGoWorkContentWrapper}>
        <div className={css.title}>
          <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.title' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.description' />
        </div>
        <div className={css.description}>
          <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.description2' />
        </div>
        <div className={css.steps}>
          <div className={css.step}>
            <Install />
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.installDescription' />
            </div>
          </div>
          <div className={css.step}>
            <Download />
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.downloadDescription' />
            </div>
          </div>
          <div className={css.step}>
            <Unlock />
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.unlockDescription' />
            </div>
          </div>
          <div className={css.step}>
            <Securely />
            <div className={css.description}>
              <FormattedMessage id='LandingPageGoHost.SectionHowDrivelahGoWork.securelyDescription' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SectionHowDrivelahGoWork;
