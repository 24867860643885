import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_LONG_TERM_DISCOUNT, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import { formatMoney } from '../../util/currency';

const LineItemLongTermDiscountMaybe = props => {
  const { transaction, intl } = props;

  const unitPurchase = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_LONG_TERM_DISCOUNT && !item.reversal
  );

  if (!unitPurchase) {
    return null;
  }

  const { discountPercentage = 0 } = transaction.attributes.protectedData;
  return (
    <div className={css.creditsLineItem}>
      <span className={css.totalLabel}>
        <FormattedMessage
          id="BookingBreakdown.longTermDiscount"
          values={{ percent: discountPercentage }}
        />
      </span>
      <span className={css.totalPrice}>{formatMoney(intl, unitPurchase.lineTotal, 1)}</span>
    </div>
  );
};

LineItemLongTermDiscountMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemLongTermDiscountMaybe;
