import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TX_TRANSITION_ACTOR_CUSTOMER,
  txIsBeingPickedUp,
  txIWaitingConfirmPickup,
  txIWaitingRequestPickup,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
} from '../../util/transaction';
import {
  TRANSITION_LTF_CONFIRM_PICK_UP,
  TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
  TX_TRANSITION_ACTOR_PROVIDER,
  txIsBeingPickedUpLTF,
  txIWaitingRequestPickupLTF,
  txLastTransition,
} from '../../util/transactionLongTermFirst';

import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton } from '..';
import { TRIP_PRIMARY_BUTTON_TRANSIT_NAME } from '../../util/gtm/gtmConstants';
import css from './TripPanel.css';
import { checkIsOldCar } from '../../util/dates';
import { getTransitButtonLabelId, getTransitErrorLabelId } from './transitionHelper';
import moment from 'moment';

const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
                             userId,
                             listingId,
                             guestName,
                             pickUpDate,
                             dropOffDate,
                             plateNumber,
                             chasisNumber,
                             isOldCar,
                             transactionId,
                           }) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

export const pickupTransitions = [
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
];
// Functional component as a helper to build ActionButtons for
// provider/customer

export const getPickUpTransition = transaction => {
  const lastTransition = txLastTransition(transaction);
  switch (lastTransition) {
    case TRANSITION_ACCEPT:
    case TRANSITION_CONFIRM_PAYMENT_INSTANT:
    case TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP:
    case TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      };
    case TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED:
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      };
    case TRANSITION_LTF_MARK_ACCEPTED_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: TRANSITION_LTF_REQUEST_PICK_UP,
      };
    case TRANSITION_LTF_REQUEST_PICK_UP:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: TRANSITION_LTF_CONFIRM_PICK_UP,
      };
    default:
      return {};
  }
};

const needUploadPhotoTransitions = [
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_LTF_REQUEST_PICK_UP,
];

export const PickUpButtonMaybe = props => {
  const {
    className,
    rootClassName,
    canShowButtons,
    transaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    ownListing,
    onPushEventGTM,
    inputOdometerInvalid,
    inputNumberOdometer,
    currentUserRole,
    currentUser
  } = props;

  const transitionMaybe = get(getPickUpTransition(transaction), `${currentUserRole}`, null);
  const validTransaction = transaction && transaction.customer;
  const allowUserTransit = validTransaction && userIsVerified(transaction);
  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const guestIdentityIsNotVerify = !allowUserTransit ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionMaybe)} />
    </p>
  ) : null;

  const { displayStart } = get(transaction, 'booking.attributes', {});
  const isTimeToShowTransitionPickup = moment().isSameOrAfter(
    moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
  );

  const classes = classNames(rootClassName || css.actionButtons, className);


  const providerTripPhoto = txIsBeingPickedUp(transaction);
  const allowTripPhotos = needUploadPhotoTransitions.includes(transitionMaybe);

  const emptyPhoto = allowTripPhotos && props.tripPhotos && props.tripPhotos.length === 0;


  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isEligbleForStartCar = (transaction.listing
    && transaction.listing.attributes.metadata &&
    transaction.listing.attributes.metadata.isDrivelahGo && transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE);

  const isStartCar = isHost && isEligbleForStartCar;

  const isEligibleForPushConfig = (transaction.listing
    && transaction.listing.attributes.metadata &&
      transaction.listing.attributes.metadata.isDrivelahGo && ( transitionMaybe == TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE || transitionMaybe ==  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED));

  const isShuCar = !isHost && isEligibleForPushConfig;

  const handlePrimaryButtonClick = (transitAllow, transaction, ownListing) => {
    console.log("PICK UP BUTTON MAY BE");
    if (inputOdometerInvalid) return;
    const dataObject = { pickUpOdometer: inputNumberOdometer };
    onPushEventGTM(transaction, transitionMaybe);
    if (
      txIWaitingConfirmPickup(transaction) &&
      transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE &&
      transitAllow &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;
      fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => {
          onTransit(
            transaction.id,
            transitionMaybe,
            dataObject,
            allowTripPhotos || providerTripPhoto
              ? [...props.tripPhotos, ...props.allTripPhotos]
              : null,
            data,
            isShuCar,
            isStartCar
          );
        });
    } else {
      if (transitAllow) {
        onTransit(
          transaction.id,
          transitionMaybe,
          dataObject,
          allowTripPhotos || providerTripPhoto
            ? [...props.tripPhotos, ...props.allTripPhotos]
            : null,
          null,
          isShuCar,
          isStartCar
        );
      }
    }
  };

  return (needUploadPhotoTransitions.includes(transitionMaybe) && isTimeToShowTransitionPickup) ||
    (transitionMaybe && !needUploadPhotoTransitions.includes(transitionMaybe)) ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {transitErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          id={'actionTransitButton'}
          className={css.submitButton}
          inProgress={transitInProgress}
          disabled={transitInProgress || !allowUserTransit || emptyPhoto || inputOdometerInvalid}
          name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
          onClick={() => {
            handlePrimaryButtonClick(transaction, transaction, ownListing);
          }}
        >
          <FormattedMessage id={getTransitButtonLabelId(transitionMaybe)} />
        </PrimaryButton>
      </div>
      <div className={css.noCarStatusPhotoUpload}>
        <div
          onClick={() => {
            props.handleOpenStatusModal('openCarStatusModal');
          }}
        >
          {(txIWaitingRequestPickup(transaction) || txIWaitingRequestPickupLTF(transaction)) && (
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload" />
          )}
          {(txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) && (
            <FormattedMessage id="TransactionPanel.noCarPickupStatusPhotoUpload2" />
          )}
        </div>
      </div>
    </div>
  ) : null;
};
function userIsVerified(transaction) {
  return (
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
    VERIFICATION_STATUS_VERIFIED ||
    transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
    VERIFICATION_STATUS_VERIFIED
  );
}
