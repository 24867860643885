import React, { memo } from 'react';
import car2 from '../../assets/landingPageNew/weekend-outing/family-road-trip.jpg';
import car3
  from '../../assets/landingPageNew/visit-friends/company-young-guys-welcoming-each-other-car.jpg';
import dc from '../../assets/landingPageNew/daily-chores/two-women-shopping-cart.jpg';
import outing
  from '../../assets/landingPageNew/move-houses/young-family-moving-into-their-new-house.jpg';
import classNames from 'classnames';
import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import Slider from 'react-slick';
import { getRandomNumber } from '../../util/helpers';


/**
 * Data to be rendered
 */
const data = [
  {
    text: 'Weekend Outing',
    image: car2,
    categories: [
      { key: 'large_car', label: 'Large Car' },
      { key: 'suv', label: 'SUV' },
      { key: 'people_mover', label: 'People Mover' },
    ],
  },
  {
    text: 'Daily Chores',
    image: dc,
    categories: [
      { key: 'small_car', label: 'Small Car' },
      { key: 'medium_car', label: 'Medium Car' },
    ],
  },
  {
    text: 'Visit Friends',
    image: car3,
    categories: [
      { key: 'large_car', label: 'Large Car' },
      { key: 'suv', label: 'SUV' },
    ],
  },
  {
    text: 'Move Houses',
    image: outing,
    categories: [
      { key: 'van', label: 'Van' },
      { key: 'ute', label: 'UTE' },
    ],
  },
];

const FindCar = memo(({ openSignUpModal, getSearchDateParameters, doModal, doSelect }) => {
  const sliderConfig = {
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1.25,
  };

  /**
   * This will render the cards for desktop
   * @returns {ReactDOM}
   */
  const onRenderDataForDesktop = () => {
    return (
      <>
        {
          data && data.length && data.map((item) => {
            return (
              <Column large={3} medium={4} small={12} key={getRandomNumber()}>
                <div
                  onClick={() => doSelect(item.categories)}
                  className={css.card}
                >
                  <div className={css.cardThumb}>
                    <img src={item.image} alt="" />
                  </div>
                  <div className={css.cardContent}>
                    <h3>{item.text}</h3>
                  </div>
                </div>
              </Column>
            );
          })
        }
      </>
    );
  };

  /**
   * This will render the cards for mobile
   * @returns {ReactDOM}
   */
  const onRenderDataForMobile = () => {
    return data.map((item, index) => {
      return (
        <Column large={12}
          key={getRandomNumber()}
          className={css.card}
          style={{ marginLeft: '20px' }}
        >
          <div onClick={() => doSelect(item.categories)}>
            <div className={css.cardThumb}>
              <img src={item.image} alt="" />
            </div>
            <div className={css.cardContent}>
              <h3>{item.text}</h3>
            </div>
          </div>
        </Column>
      );
    });
  };

  return (
    <div className={css.findCarWrapper}>
      <Container>
        <Row>
          <Column large={12}>
            {/* <h4 className={css.heading}>A mode for every mood</h4> */}
            <h2 className={css.heading}>Find a car that fits your travel needs
              {/* <span>Drive to Malaysia allowed</span> */}
            </h2>
          </Column>
        </Row>
        <Row className={classNames(css.carGrid, css.showDesktop)}>
          {onRenderDataForDesktop()}
        </Row>
        <Row className={classNames(css.carGrid, css.showMobile)}>
          <Slider {...sliderConfig}>
            {onRenderDataForMobile()}
          </Slider>
        </Row>
        {
          /* <Row className={css.buttonWrapper}>
            <Column alignItems="center" large={12}>
              <button onClick={openSignUpModal} className={classNames(css.btn, css.btnPrimary)}>
                <FormattedMessage id="LandingPageNew.GetStarted" />
              </button>
            </Column>
          </Row>
          */
        }
      </Container>
    </div>
  );
});

export default FindCar;
