import React from 'react';
import css from './GrabHostYourCarLandingPage.css';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import image1 from '../../assets/grabBanner/image1.png';
import image2 from '../../assets/grabBanner/image2.png';
import image3 from '../../assets/grabBanner/image3.png';
import Slider from 'react-slick';

const WhyHostWithDrivelah = ({ isMobileLayout, handleSignupRedirect }) => {
  const gridContent = [
    {
      contentImage: image1,
      contentTitle: 'Passive Income',
      contentDescription:
        'Dont let your car sit idle, maximise your earnings by renting it out on your off days/hours',
    },
    {
      contentImage: image2,
      contentTitle: 'Flexible Hosting',
      contentDescription:
        "With thousands of relief drivers on our platform, you decide when to Rent out your vehicle. Hourly, daily, weekly. It's your choice.",
    },
    {
      contentImage: image3,
      contentTitle: 'Keyless handovers ',
      contentDescription:
        'Stay safe and away from contact by allowing your relief driver to unlock your car securely through the Drive mate',
    },
  ];
  const sliderConfig = {
    // className: css.slider,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={css.whyHostWithUs}>
      <div className={css.container}>
        <div className={classNames(css.row, css.secTitle)}>
          <div className={css.column12}>
            <h2>Why rent out with Drive mate?</h2>
          </div>
        </div>
        <div className={classNames(css.row, css.contentGrid)}>
          {isMobileLayout ? (
            <div className={css.sliderWrapper}>
              <Slider {...sliderConfig}>
                {gridContent &&
                  gridContent.length > 0 &&
                  gridContent.map((content, i) => {
                    return (
                      <div className={css.column4}>
                        <div className={css.contentBlock}>
                          <div className={css.contentBlockThumb}>
                            <img src={content.contentImage} alt="" />
                          </div>
                          <div className={css.contentBlockContent}>
                            <h4>{content.contentTitle}</h4>
                            <p>{content.contentDescription}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          ) : (
            gridContent &&
            gridContent.length > 0 &&
            gridContent.map((content, i) => {
              return (
                <div className={css.column4}>
                  <div className={css.contentBlock}>
                    <div className={css.contentBlockThumb}>
                      <img src={content.contentImage} alt="" />
                    </div>
                    <div className={css.contentBlockContent}>
                      <h4>{content.contentTitle}</h4>
                      <p>{content.contentDescription}</p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className={classNames(css.row, css.btnRow)}>
          <div className={css.column12}>
            <button onClick={e => handleSignupRedirect(e)} className={css.bannerButton}>
              List your car now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHostWithDrivelah;
