export const CANCEL_UPDATE_BOOKING_REQUEST =
  'app/admin-actions/update-booking/CANCEL_UPDATE_BOOKING_REQUEST';
export const CANCEL_UPDATE_BOOKING_SUCCESS =
  'app/admin-actions/update-booking/CANCEL_UPDATE_BOOKING_SUCCESS';
export const CANCEL_UPDATE_BOOKING_ERROR =
  'app/admin-actions/update-booking/CANCEL_UPDATE_BOOKING_ERROR';

export const ACCEPT_UPDATE_BOOKING_REQUEST =
  'app/admin-actions/update-booking/ACCEPT_UPDATE_BOOKING_REQUEST';
export const ACCEPT_UPDATE_BOOKING_SUCCESS =
  'app/admin-actions/update-booking/ACCEPT_UPDATE_BOOKING_SUCCESS';
export const ACCEPT_UPDATE_BOOKING_ERROR =
  'app/admin-actions/update-booking/ACCEPT_UPDATE_BOOKING_ERROR';

export const cancelUpdateBookingRequest = () => ({
  type: CANCEL_UPDATE_BOOKING_REQUEST,
});

export const cancelUpdateBookingSuccess = () => ({
  type: CANCEL_UPDATE_BOOKING_SUCCESS,
});

export const cancelUpdateBookingError = () => ({
  type: CANCEL_UPDATE_BOOKING_ERROR,
});

export const acceptUpdateBookingRequest = () => ({
  type: ACCEPT_UPDATE_BOOKING_REQUEST,
});

export const acceptUpdateBookingSuccess = () => ({
  type: ACCEPT_UPDATE_BOOKING_SUCCESS,
});

export const acceptUpdateBookingError = () => ({
  type: ACCEPT_UPDATE_BOOKING_ERROR,
});
