import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ExpandingTextarea, ValidationError } from '../../components';

import css from './FieldTextAutoFillInput.css';

const CONTENT_MAX_LENGTH = 5000;

const preventEditText = e => e.preventDefault();

class FieldTextAutoFillInputComponent extends Component {
  componentDidMount() {
    const { editable } = this.props;
    if (!editable && this.inputRef) {
      this.inputRef.addEventListener('keypress', preventEditText);
    }
  }

  componentWillUnmount() {
    if (this.inputRef) {
      this.inputRef.removeEventListener('keypress', preventEditText);
    }
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputClassName,
      inputRootClass,
      customErrorText,
      id,
      label,
      type,
      input,
      meta,
      onUnmount,
      optionsList,
      chooseOption,
      isOpenSuggest,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    const inputClasses =
      inputRootClass ||
      classNames(css.input, inputClassName, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      });
    const inputProps = isTextarea
      ? { className: inputClasses, id, rows: 1, maxLength: CONTENT_MAX_LENGTH, ...input, ...rest }
      : { className: inputClasses, id, type, ...input, ...rest };

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        {isTextarea ? (
          <ExpandingTextarea {...inputProps} />
        ) : (
          <input ref={ref => (this.inputRef = ref)} {...inputProps} autoComplete="off" />
        )}
        {isOpenSuggest && optionsList ? (
          <div className={css.suggestField}>
            {optionsList.map(option => (
              <div
                key={option.key}
                className={css.optionField}
                onMouseDown={() => {
                  chooseOption(option);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        ) : null}
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldTextAutoFillInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  inputClassName: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  editable: true,
};

FieldTextAutoFillInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputClassName: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Either 'textarea' or something that is passed to the input element
  type: string.isRequired,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextAutoFillInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextAutoFillInputComponent} {...this.props} />;
  }
}

export default FieldTextAutoFillInput;
