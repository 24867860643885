import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './GOFAQ.css';

class GOFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabOpen: -1,
    };
  }

  openTab = index => {
    this.setState({
      tabOpen: index !== this.state.tabOpen ? index : -1,
    });
  };

  render() {
    return (
      <section className={css.faqSection}>
        <div className={css.innerWrapper}>
          <div className={css.title}>
            <FormattedMessage id="BecomeAHostPage.faqTitle" values={{ newline: <br /> }} />
          </div>
          <div className={css.listTextBox}>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(0)}>
                What is the pricing for Drive mate Go?​
              </div>
              {this.state.tabOpen === 0 && (
                <div className={css.answer}>
                  For Drive mate Go, there is a 2 month trial period with no charges. After that,
                  there is a subscription fee of $30/month.
                  <br />
                  With Drive mate Go, you get guaranteed returns. We guarantee you that you will
                  earn 2x of your monthly subscription minimum. If that doesn't happen, we reimburse
                  the amount to you
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(1)}>
                Does the installation of the device impact the warranty of my car?
              </div>
              {this.state.tabOpen === 1 && (
                <div className={css.answer}>
                  Absolutely not. We don’t make any permanent or physical alteration to your car.
                </div>
              )}
            </div>
            <div className={css.subBox}>
              <div className={css.question} onClick={() => this.openTab(2)}>
                What is the Drive mate Go Guarantee program?
              </div>
              {this.state.tabOpen === 2 && (
                <div className={css.answer}>
                  Drive mate offers a Guarantee Program to all Drive mate Go hosts under which they
                  will earn a minimum of 2X of the monthly subscription fee. If the host earns less
                  than $60 by the end of the month, the balance will be paid by Drive mate.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={css.seperateLine}></div>
      </section>
    );
  }
}

export default GOFAQ;
