import React from 'react';
import { FormattedMessage } from 'react-intl';
import stepClean from '../../assets/landingPageCovid/stepClean.png';
import stepDisinfect from '../../assets/landingPageCovid/stepDisinfect.png';
import stepFinish from '../../assets/landingPageCovid/stepFinish.png';
import iconClean from '../../assets/landingPageCovid/iconClean.png';
import iconSoap from '../../assets/landingPageCovid/iconSoap.png';
import iconLeather from '../../assets/landingPageCovid/iconLeather.png';
import iconNontoxic from '../../assets/landingPageCovid/iconNontoxic.png';
import css from './SectionGuideToDisinfectDesktop.css';

const GuideDisinfect = () => (
  <div className={css.listStepWrapper}>
    <div className={css.listStep}>
      <img src={stepClean} className={css.listStepImage}/>
      <div className={css.listStepTitle}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle1' />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent1' />
      </div>
    </div>
    <div className={css.listStep}>
      <img src={stepDisinfect} className={css.listStepImage}/>
      <div className={css.listStepTitle}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle2' />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent2' />
      </div>
    </div>
    <div className={css.listStep}>
      <img src={stepFinish} className={css.listStepImage}/>
      <div className={css.listStepTitle}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepTitle3' />
      </div>
      <div className={css.listStepContent}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.listStepContent3' />
      </div>
    </div>
  </div>
);

const RecommendAndTouchpoints = () => (
  <div className={css.recommendAndTouchpoints}>
    <div className={css.recommendWrapper}>
      <div className={css.recommendTitle}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.recommendTitle' />
      </div>
      <div className={css.recommendList}>
        <img src={iconClean} className={css.listIcon} />
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription1' />
      </div>
      <div className={css.recommendList}>
        <img src={iconSoap} className={css.listIcon} />
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription2' />
      </div>
      <div className={css.recommendList}>
        <img src={iconLeather} className={css.listIcon} />
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription3' />
      </div>
      <div className={css.recommendList}>
        <img src={iconNontoxic} className={css.listIcon} />
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.recommendDescription4' />
      </div>
    </div>
    <div className={css.touchpointsWrapper}>
      <div className={css.touchpointTitle}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointTitle' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription1' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription2' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription3' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription4' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription5' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription6' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription7' />
      </div>
      <div className={css.touchpointList}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.touchpointDescription8' />
      </div>
    </div>
  </div>
)

const SectionGuideToDisinfectDesktop = () => {
  return (
    <div className={css.SectionGuideToDisinfectDesktop}>
      <div className={css.title}>
        <FormattedMessage id='LandingPageCovid.SectionGuideToDisinfectDesktop.title' />
      </div>
      <GuideDisinfect />
      <RecommendAndTouchpoints />
    </div>
  )
};

export default SectionGuideToDisinfectDesktop;
