/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { memo } from 'react';
import { bool, oneOf, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_CUSTOM_REFUND, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemLongTermTripFees from './LongTermLineItems/LineItemLongTermTripFees';
import LineItemLongTermBasePrice from './LongTermLineItems/LineItemLongTermBasePrice';
import LineItemLongTermRentalDiscountMaybe
  from './LongTermLineItems/LineItemLongTermRentalDiscountMaybe';

export const BookingBreakdownComponent = memo(
  props => {
    const {
      rootClassName,
      className,
      intl,
      userRole,
      transaction: rawTransaction,
      currentUser,
      doNotShowTotal,
    } = props;
    const isCustomer = userRole === 'customer';
    const isProvider = userRole === 'provider';
    const classes = classNames(rootClassName || css.root, className);
    let transaction = rawTransaction;

    // const LTM_MONTHLY_PRICE = 300000;
    // if (isLongTermRental) {
    //   transaction = changeLTMPrice(transaction, LTM_MONTHLY_PRICE);
    // }
    return (
      <div className={classes}>
        <LineItemLongTermBasePrice transaction={transaction} isCustomer={isCustomer} intl={intl} />
        <LineItemLongTermTripFees transaction={transaction} isCustomer={isCustomer} intl={intl} />

        {doNotShowTotal ? null : (
          <div>
            <hr className={css.totalDivider} />
            <LineItemTotalPrice
              currentUser={currentUser}
              transaction={transaction}
              isProvider={isProvider}
              intl={intl}
            />
            <LineItemLongTermRentalDiscountMaybe
              transaction={transaction}
              isCustomer={isCustomer}
              intl={intl}
            />
          </div>
        )}
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      pick(prev, ['userRole', 'transaction', 'currentUser']),
      pick(next, ['userRole', 'transaction', 'currentUser'])
    )
);

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  doNotShowTotal: false,
  customRefundType: LINE_ITEM_CUSTOM_REFUND,
  hideUnitPrice: false,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,
  transactionMode: bool,
  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  doNotShowTotal: bool,
  hideUnitPrice: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdownLongTerm = injectIntl(BookingBreakdownComponent);

BookingBreakdownLongTerm.displayName = 'BookingBreakdownLongTerm';

export default BookingBreakdownLongTerm;
