import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { isMobile, isSafari } from 'react-device-detect';
import {
  LimitedAccessBanner,
  MobileAppBanner,
  NamedLink,
  ThemedTopbarDesktop,
} from '../../components';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_LOGGEDOUT, EVENT_BROWSE_OWNPROFILE } from '../../util/gtm/gtmConstants';
import css from './ThemedTopBar.css';
import ThemedLogo from '../ThemedLogo/ThemedLogo';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class ThemedTopBarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setIntercomSettings = false;
    this.previousLoggedState = false;
    this.topbar = createRef();
    this.topbarContainer = createRef();
    this.contactUs = createRef();

    this.state = {
      showBanner: false,
      showNavbar: false,
      lastScrollY: 0,
      stickyActive: false,
    };
  }

  controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > 250) {
        // if scroll down hide the navbar
        if (window.scrollY > this.state.lastScrollY) {
          this.setState({ showNavbar: false });
        } else {
          // if scroll up show the navbar
          this.setState({ showNavbar: true });
        }
        this.setState({ stickyActive: true });
      } else {
        this.setState({ stickyActive: false });
      }
      // remember current page location to use in the next move
      this.setState({ lastScrollY: window.scrollY });
    }
  };

  handleShowBanner = () => {
    const { isAuthenticated, currentUser, currentPage } = this.props;
    if (currentPage !== 'LandingPage') return;
    this.id = !isAuthenticated
      ? 'showMobileAppBanner'
      : isAuthenticated && currentUser && currentUser.id
      ? `${currentUser.id.uuid}_showMobileAppBanner`
      : null;

    if (this.id && isMobile && isSafari) {
      const showBannerValue = window.localStorage.getItem(this.id);
      if ((this.state.showBanner !== showBannerValue) !== 'hide')
        this.setState({ showBanner: showBannerValue !== 'hide' });
    }
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#FFFFFF';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#FFFFFF';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
    }
  };

  componentDidMount() {
    this.handleShowBanner();
    if (this.props.transparent) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
      document.addEventListener('scroll', this.handleScroll);
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#FFFFFF';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#FFFFFF';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    }
    document.addEventListener('scroll', this.controlNavbar);
  }

  componentWillUnmount() {
    if (this.props.transparent) {
      document.removeEventListener('scroll', this.handleScroll);
      document.removeEventListener('scroll', this.controlNavbar);
    }
  }

  componentWillReceiveProps(props) {
    this.handleShowBanner();
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());
      pushGTMBrowseEvent({
        props: this.props,
        event: EVENT_BROWSE_LOGGEDOUT,
        eventCallback: () => {
          // In production we ensure that data is really lost,
          // but in development mode we use stored values for debugging
          if (config.dev) {
            history.push(path);
          } else if (typeof window !== 'undefined') {
            window.location = path;
          }


        },
      });
    });
  }

  onViewOwnProfile = () => {
    const { currentUser } = this.props;
    if (currentUser) {
      pushGTMBrowseEvent({
        props: this.props,
        event: EVENT_BROWSE_OWNPROFILE,
      });
    }
  };

  closeHourleyBanner = () => {
    this.setState({ showBanner: false });
    if (this.id) {
      window.localStorage.setItem(this.id, 'hide');
    }
  };

  pushGTMBrowse = (eventGTM, eventAttribute) => {
    let eventCallback;
    if (eventAttribute) {
      eventAttribute.preventDefault();
      const href = eventAttribute.currentTarget.href;
      eventCallback = () => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      };
    }
    pushGTMBrowseEvent({
      props: this.props,
      event: eventGTM,
      eventCallback,
    });
  };

  render() {
    const {
      className,
      rootClassName,
      notApplySearch,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      currentUser,
      currentUserHasListings,
      currentPage,
      notificationCount,
      intl,
      location,
      onLoginOrSignupClick,
      openSignUpModal,
    } = this.props;

    const { address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const scrollClass = this.state.stickyActive && !this.state.showNavbar ? css.scroll : '';
    const stickClass = this.state.stickyActive && css.stickTopbar;

    const classes = classNames(rootClassName || css.root, className, scrollClass, stickClass, {
      [css.topbarRootMobileWithBanner]: this.state.showBanner,
    });

    return (
      <div className={classes} id="topbar" ref={this.topbar}>
        <MobileAppBanner
          closeHourleyBanner={this.closeHourleyBanner}
          showBanner={this.state.showBanner}
          className={classNames({ [css.bannerInMobile]: this.state.showBanner })}
        />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div
          ref={this.topbarContainer}
          className={classNames(mobileRootClassName || css.container, mobileClassName, {
            [css.mobileWithBanner]: this.state.showBanner,
          })}
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <NamedLink name="LandingPage" style={{ width: 125 }}>
            <ThemedLogo format="mobile" />
          </NamedLink>

          {currentPage === 'HostLandingPage' ? (
            <button
              onClick={openSignUpModal}
              style={{ width: 125 }}
              className={css.signupButtonTopBar}
            >
              List your car
            </button>
          ) : (
            <button
              onClick={openSignUpModal}
              style={{ width: 125 }}
              className={css.signupButtonTopBar}
            >
              Sign up now
            </button>
          )}
        </div>
        <div className={css.desktop}>
          <ThemedTopbarDesktop
            className={desktopClassName}
            notApplySearch={notApplySearch}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            location={location}
            onLoginOrSignupClick={onLoginOrSignupClick}
            openSignUpModal={openSignUpModal}
          />
        </div>
      </div>
    );
  }
}

ThemedTopBarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  notApplySearch: true,
  transparent: false,
};

const { array, func, number, shape, string } = PropTypes;

ThemedTopBarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  showGenericError: bool.isRequired,
  onLoginOrSignupClick: func.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ThemedTopBar = compose(withViewport, injectIntl)(ThemedTopBarComponent);

ThemedTopBar.displayName = 'ThemedTopBar';

export default ThemedTopBar;
