import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldPasswordInput, FieldTextInput, Form, PrimaryButton } from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  composeValidators,
  emailFormatValid,
  required,
  requiredStringNoTrim,
} from '../../util/validators';
import css from './LoginGoForm.css';
import classNames from 'classnames';

const LoginGoForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          isByod
        } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'LoginForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'LoginForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'LoginForm.emailRequired',
        });
        const emailRequired = required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'LoginForm.emailInvalid',
        });
        const emailValid = emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'LoginForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'LoginForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'LoginForm.passwordRequired',
        });
        const passwordRequired = requiredStringNoTrim(passwordRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldTextInput
              type="email"
              labelClassName={css.labelClassName}
              className={css.email}
              inputClassName={css.inputClassName}
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={composeValidators(emailRequired, emailValid)}
            />
            <FieldPasswordInput
              labelClassName={css.labelClassName}
              className={css.password}
              inputClassName={css.inputClassName}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="current-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordRequired}
            />

            <PrimaryButton className={css.submitButton} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {isByod ? <FormattedMessage id="LoginForm.ByodLogIn" /> : <FormattedMessage id="LoginForm.logIn" /> }
            </PrimaryButton>
          </Form>
        )
      }}
    />
  )
};

export default injectIntl(LoginGoForm);
