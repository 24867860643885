import React from 'react';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import css from '../LongTermRentalListingPage.css';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemBasePriceMaybe = props => {
  const { listing, intl, additionalCost } = props;

  const { flexPlusPrice } = listing.attributes.publicData;

  if (!flexPlusPrice) {
    return null;
  }

  const flexPlusBasePrice = new Money(flexPlusPrice, config.currency);

  return (
    <div>
      <span className={css.priceTitle}>Base price</span>
      <span className={css.priceTitle}>{formatMoney(intl, flexPlusBasePrice, 1)}</span>
    </div>
  );
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemBasePriceMaybe;
