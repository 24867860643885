import config from '../config';

const apiUrl = config.apiUrl;

export const SEND_PROFILE_IMAGE_CHANGE_SLACK_NOTIFICATION =
  'slackNotify/SEND_PROFILE_IMAGE_CHANGE_SLACK_NOTIFICATION';

export const SEND_LISTING_IMAGE_CHANGE_SLACK_NOTIFICATION =
  'slackNotify/SEND_LISTING_IMAGE_CHANGE_SLACK_NOTIFICATION';

export const SEND_MSG_CONTAINING_PHONE_NO_OR_EMAIL_SLACK_NOTIFICATION =
  'slackNotify/SEND_MSG_CONTAINING_PHONE_NO_OR_EMAIL_SLACK_NOTIFICATION';

export const SEND_SMOVE_USER_BOOKING_NOTIFICATION = "slackNotify/SEND_SMOVE_USER_BOOKING_NOTIFICATION";
export const SEND_STRIPE_ERROR_BOOKING_NOTIFICATION = "slackNotify/SEND_STRIPE_ERROR_BOOKING_NOTIFICATION";

export const SEND_MSG_UPDATED_TRACKING_DEVICE_ID = 'USER_UPDATE_LISTING_TRACKING_DEVICE_ID';

export const SEND_CHECKOUT_PAGE_NOTIFICATION = 'SEND_CHECKOUT_PAGE_NOTIFICATION';
export const FUEL_NOTIFICATION_WITHOUT_CHARGE = 'FUEL_NOTIFICATION_WITHOUT_CHARGE';
export const FUEL_NOTIFICATION_FOR_500 = 'FUEL_NOTIFICATION_FOR_500';



export const sendListingImageChangeSlackNoti = ({
  listingId,
  authorName,
  sendImagesChangeSlackNotiMsg,
}) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId,
      authorName,
      sendImagesChangeSlackNotiMsg,
      eventType: SEND_LISTING_IMAGE_CHANGE_SLACK_NOTIFICATION,
    }),
  });
};

export const sendTransactionMsgContainingPhoneNoOrEmailNoti = ({
  transactionId,
  customerName,
  providerName,
  message,
  senderRole,
  senderId,
  fromCheckoutPage,
}) => {
  if (typeof window === 'undefined') {
    return;
  }

  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      senderRole,
      customerName,
      providerName,
      message,
      senderId,
      fromCheckoutPage,
      eventType: SEND_MSG_CONTAINING_PHONE_NO_OR_EMAIL_SLACK_NOTIFICATION,
    }),
  });
};

export const sendProfileImageChangeSlackNoti = ({ userName, userId }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userName,
      userId,
      eventType: SEND_PROFILE_IMAGE_CHANGE_SLACK_NOTIFICATION,
    }),
  });
};


export const sendSmoveUserBookingNotification = ({
  transactionId,
  hostId,
}) => {
  if (typeof window === 'undefined') {
    return;
  }

  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      hostId,
      eventType: SEND_SMOVE_USER_BOOKING_NOTIFICATION,
    }),
  });
};

export const sendStripeErrorSlackNotification = ({
  transactionId,
  error
}) => {
  if (typeof window === 'undefined') {
    return;
  }

  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId,
      error,
      eventType: SEND_STRIPE_ERROR_BOOKING_NOTIFICATION,
    }),
  });
};


export const sendMsgUpdateListingTrackingDeviceId = ({ listingId, listingName }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId,
      listingName,
      eventType: SEND_MSG_UPDATED_TRACKING_DEVICE_ID,
    }),
  });
};

export const sendCheckoutSlackNotification = ({ listingId, userId }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId,
      userId,
      eventType: SEND_CHECKOUT_PAGE_NOTIFICATION,
    }),
  });
};

export const longTermNoti = ({ email, version, phoneNumber }) => {
  if (typeof window === 'undefined') return;
  return fetch(apiUrl + '/api/events/long-term-contact-form', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      version,
      phoneNumber,
    }),
  });
};
