import React from 'react';
import PropTypes from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';
import css from './ThemedTopbarDesktop.css';
import ThemedLogo from '../ThemedLogo/ThemedLogo';

const ThemedTopbarDesktop = props => {
  const { className, currentPage, rootClassName, openSignUpModal } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <ThemedLogo format="mobile" />
      </NamedLink>
      {currentPage === 'HostLandingPage' ? (
        <button onClick={openSignUpModal} style={{ width: 125 }} className={css.signupButtonTopBar}>
          List your car
        </button>
      ) : (
        <button onClick={openSignUpModal} style={{ width: 125 }} className={css.signupButtonTopBar}>
          Sign up now
        </button>
      )}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

ThemedTopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

ThemedTopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  location: object,
  onLoginOrSignupClick: func.isRequired,
};

export default withViewport(ThemedTopbarDesktop);
