import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import css from '../BookingBreakdown.css';
import { formatMoney } from '../../../util/currency';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { Explaination } from '../..';

const { Money } = sdkTypes;

const LineItemLongTermRentalDiscountMaybe = props => {
  const { transaction, intl } = props;

  if (!transaction) return null;

  // const bookingDays = get(transaction, 'attributes.protectedData.days');
  const isLongTermRental = get(transaction, 'attributes.protectedData.isLongTermRental');
  // const totalDays = (bookingDays.regular || 0) + (bookingDays.peak || 0);
  // const dailyPrice = get(transaction, 'listing.attributes.price.amount', 0);
  const dailyFees = get(transaction, 'attributes.protectedData.dailyBookCommission.total', 0);
  const dailyBaseTripPrice = get(
    transaction,
    'attributes.protectedData.dailyBookCommission.note.baseTripPrice',
    0
  );
  const longTermRentalPrice = get(
    transaction,
    'attributes.protectedData.longTermTotalTripPrice',
    0
  );
  // const longTermRentalPrice = transaction.attributes.lineItems.find(
  //   item => item.code === LINE_ITEM_UNITS && !item.reversal
  // );

  if (!longTermRentalPrice || !isLongTermRental) {
    return null;
  }
  let discount = dailyBaseTripPrice * 1 + dailyFees - longTermRentalPrice; // *10 just for test

  if (discount <= 0) return null;

  discount = new Money(discount, config.currency);

  const { discountPercentage = 0 } = transaction.attributes.protectedData;
  return (
    <div className={css.creditsLineItem}>
      <span className={css.totalLabel}>
        <FormattedMessage
          id="BookingBreakdown.longTermRentalDiscountWillBe"
          values={{ percent: discountPercentage }}
        />
        <Explaination text="Amount saved by special monthly price as compared to regular daily price" />
      </span>
      <span className={css.totalPrice}>{formatMoney(intl, discount)}</span>
    </div>
  );
};

LineItemLongTermRentalDiscountMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default LineItemLongTermRentalDiscountMaybe;
