// ================ Action types ================ //
import { fetchCurrentUser } from '../../ducks/user.duck';

const SAVE_ADDRESS_DETAILS_REQUEST = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_REQUEST';
const SAVE_ADDRESS_DETAILS_SUCCESS = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_SUCCESS';
const SAVE_ADDRESS_DETAILS_ERROR = 'app/AddressDetailsPage/SAVE_ADDRESS_DETAILS_ERROR';

// ================ Reducer ================ //

export const initialState = {
  saveAddressDetailsSuccess: null,
  saveAddressDetailsError: null,
  saveAddressDetailsInProgress: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ADDRESS_DETAILS_REQUEST:
      return {
        ...state,
        saveAddressDetailsSuccess: false,
        saveAddressDetailsError: false,
        saveAddressDetailsInProgress: true,
      };
    case SAVE_ADDRESS_DETAILS_SUCCESS:
      return { ...state, saveAddressDetailsSuccess: true, saveAddressDetailsInProgress: false };
    case SAVE_ADDRESS_DETAILS_ERROR:
      return { ...state, saveAddressDetailsError: payload, saveAddressDetailsInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestSaveAddressDetails = () => ({
  type: SAVE_ADDRESS_DETAILS_REQUEST,
});

const saveAddressDetailsSuccess = () => ({
  type: SAVE_ADDRESS_DETAILS_SUCCESS,
});

const saveAddressDetailsError = e => ({
  type: SAVE_ADDRESS_DETAILS_REQUEST,
  payload: e,
});

export const saveAddressDetails = params => (dispatch, getState, sdk) => {
  dispatch(requestSaveAddressDetails());
  const { location, unitNumber, city, postalCode, state, streetNumber, suburb, dateOfBirth } = params;
  const protectedData = {};
  if (location) protectedData.location = location;
  if (dateOfBirth) protectedData.dateOfBirth = dateOfBirth;
  // TODO: Add validation to check if locationProxy is already exists or not (WARN: First check if user want to change the location, we should allow to modify locationProxy?)
  // if (location) protectedData.locationProxy = location;
  if (unitNumber) protectedData.unitNumber = unitNumber;
  if (streetNumber) protectedData.streetNumber = streetNumber;
  if (suburb) protectedData.suburb = suburb;
  if (city) protectedData.city = city;
  if (postalCode) protectedData.postalCode = postalCode;
  if (state) protectedData.state = state;
  return sdk.currentUser
    .updateProfile({ protectedData })
    .then(response => {
      dispatch(saveAddressDetailsSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(error => {
      dispatch(saveAddressDetailsError());
    });
};
