import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
} from '../../components';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { injectIntl } from '../../util/reactIntl';
import DrivelahCreditsIconWhite from './drivelah-credits-icon-white.png';
import drivelahCreditsIcon from './drivelah-credits-icon.png';
import FBMessengerIcon from './FBMessenger.png';
import GroupOfGoogleContacts from './GroupOfGoogleContacts';
import image from './refer-a-host-page-desktop.png';
import css from './ReferAHostPage.css';
import { sendInvitation } from './ReferAHostPage.duck';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useLocation } from 'react-router-dom';
import ShareYourLinkIcon from './share-your-link.png';
import TellFriendsIcon from './tell-friends.png';
import TwitterIcon from './Twitter.png';
import WhatsAppIcon from './WhatsApp.png';
import QRCodeGenerator from '../../components/QRCodeGenerator/QRCodeGenerator';
import DownloadingArrow from '../../assets/DownloadingArrow.svg'
import NewWhatsappIcon from '../../assets/whatsapp/whatsapp@2x.png'
import NewTwitterIcon from '../../assets/twitter/twitter@2x.png'
import NewFBMessengerIcon from '../../assets/fbmessenger/messenger(1)@2x.png'
import axios from 'axios';

const VIEW_PORT_MEDIUM = 768;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;
const API_URL = process.env.REACT_APP_API_SERVER_URL;

const ReferAHostPage = props => {
  const {
    intl,
    currentUser,
    scrollingDisabled,
    onSendInvitation,
    sendInvitationInProgress,
    sendInvitationError,
    onManageDisableScrolling,
  } = props;

  const [copyMsg, setCopyMsg] = useState('Copy');
  const [copyStatus, setCopyStatus] = useState(true);
  const [showGmailContactsModal, setShowGmailContactsModal] = useState(false);
  const [googleContacts, setGoogleContacts] = useState([]);
  const submittedValues = useRef({});
  const qrCodeRef = useRef({ url: '' }); // Ref to hold the QR code URL
  const [referalLink, setReferalLink] = useState(''); // State to manage referal link
  const [userReferalData, setUserReferalData] = useState({}); // State to manage referal historical data
  const [copied, setCopied] = useState(false); // State to manage the visibility of copied notifier

  const history = useHistory();
  const location = useLocation();
  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const onShareToFacebookTimeline = (message, url) => {
    if (!window) return;
    console.log("message, url-------->", message, url)
    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: url,
      },
      function(response) {
        console.log('Success!', response);
      }
    );
  };

  const onShareToMessenger = link => {
    if (!window) return;
    var desc = 'your caption here';
    var title = 'your title here';

    return isMobileView
      ? window.open(`fb-messenger://share/?link=${link}&app_id=${config.facebookAppId}`)
      : window.FB.ui({
          method: 'send',
          link: `${link}`,
          description: 'your_description',
          action_properties: JSON.stringify({
            object: {
              'og:title': title,
              'og:description': desc,
            },
          }),
        });
  };

  useEffect(() => {
    if(typeof window !== 'undefined') {
      loadFbSdk();
    }
  }, []);

  // Generating the referal link on updation of currentUser
  useEffect(() => {
    generateReferalLink();
    referalData();
  }, [currentUser]);

  const loadingTitle = intl.formatMessage({ id: 'ReferAHostPage.loadingReferralCodeTitle' });

  const topbar = <TopbarContainer />;

  // if (!currentUser) {
  //   return (
  //     <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
  //       <LayoutSingleColumn className={css.pageRoot}>
  //         <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
  //         <LayoutWrapperMain>
  //           <p className={css.loadingText}>
  //             <FormattedMessage id="ReferAHostPage.loadingReferralCode" />
  //           </p>
  //         </LayoutWrapperMain>
  //         <LayoutWrapperFooter>
  //           <Footer />
  //         </LayoutWrapperFooter>
  //       </LayoutSingleColumn>
  //     </Page>
  //   );
  // }

  const ensuredUser = ensureCurrentUser(currentUser);
  // prettier-ignore
  const isMobileView = typeof window !== 'undefined' ? window.innerWidth <= VIEW_PORT_MEDIUM : false;
  const mobileLineBreak = isMobileView ? <br /> : null;
  const desktopLineBreak = !isMobileView ? <br /> : null;
  const getReferralCode = get(ensuredUser, 'attributes.profile.metadata.referralCode', '');
  const userId = get(ensuredUser, 'id', '');
  // const { uuid: userId } = ensuredUser && ensuredUser.id && ensuredUser.id;
  const signupReferralLink = `${config.canonicalRootURL}/signup?code=${getReferralCode}`;
  // Old referal Link is generated as above

  const showCopiedText = seconds => {
    setTimeout(() => setCopyMsg('Copy'), seconds * 1000);
  };

  const redirectToSignup = () => {
    const state = { from: `${location.pathname}` };
    history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
  };

  const sharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.sharingMsg' },
    { referalLink, myCode: getReferralCode }
  );

  const whatsappSharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.whatsappSharingMsg' },
    { referalLink, myCode: getReferralCode }
  );

  const twitterLink =
    typeof window !== 'undefined'
      ? `https://twitter.com/intent/tweet?text=${window.encodeURI(sharingMsg)}`
      : '';

  const whatsAppLink = isMobileView
    ? `https://api.whatsapp.com/send?text=${whatsappSharingMsg}`
    : `https://web.whatsapp.com/send?text=${whatsappSharingMsg}`;

  const { clientId, scopes, apiKey } = config.googleContacts;

  // Getting the referal data of user
  const referalData = async()=> {
    const res = axios.get(`${API_URL}/api/events/user-referral?userId=${get(currentUser, 'id.uuid', '')}`)
    .then(response => {
      console.log('I am response >>', response);
      
      setUserReferalData(response.data)})
    .catch(error => console.log('Error in fetching referal data', error));
  };

  // Share button handlers
  const shareOnWhatsapp = () => {window.open(whatsAppLink, '_blank');};
  const shareOnMessenger = () => {onShareToMessenger(referalLink)};
  const shareOnTwitter = () => {window.open(twitterLink, '_blank');};

  // Shortning the reference url for display
  const shortenReferalUrl = (url) => {
    const codeMatch = url.match(/code=([A-Za-z0-9]+)/);
    const shortCode = codeMatch ? codeMatch[1] : 'unknown';
    return `https://drivemate.au /.....code=${shortCode}`;
  };

  // Copying the referal code
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referalLink); // Copy referral code
    setCopied(true); // Show "Copied" message

    // Hide "Copied" message after 1 second
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  // Function for generating the erferal link according to the guest / host
  const generateReferalLink = () => {
    if(get(currentUser, 'attributes.profile.metadata.intercomUserStat.number_of_live_listings', 0) > 0) {
      console.log('I am host >>');
      setReferalLink(`${config.canonicalRootURL}/u/${get(currentUser, 'id.uuid', '')}?code=${getReferralCode}`)
    } else {
      console.log('I am guest >>');
      setReferalLink(`${config.canonicalRootURL}/rent-cars-with-drive-mate?code=${getReferralCode}`)
    }
  }

  // Downloading the QR code
  const handleDownload = () => {
    const canvas = qrCodeRef.current;
    
    if (canvas) {
      // Convert canvas to image URL
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = url;
      link.download = 'DM_InviteQR.png'; // Name of the file
      link.click(); // Simulate click to trigger download
    } else {
      alert('QR Code is not ready yet!');
    }
  };

  // Checking if user is logged in or not.
  const isUserLoggedIn = !(currentUser === null);

  // Setting up referal data
  const signupsWithCode = get(userReferalData, 'totalCodeUsedUsers', 0);
  const completedReferals = get(userReferalData, 'completeReferrals', 0);

  return (
    <StaticPage
      title="Referral program"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Referral Program',
        description: 'About Referral Program',
        name: 'Referral Program',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ReferralPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* Section to show is user is logged in */}
          {isUserLoggedIn && (
            <>
              <p className={css.outerContainerHeading}>Refer and earn</p>
              <div className={css.outerContainer}>
                {/* Details Container Starts */}
                <div className={css.detailsBox}>
                  <div>Track your referrals</div>
                  <div>
                    <div>
                      <p>Signups with your code</p>
                      <p>Users who signed up using your code</p>
                    </div>
                    <div>{signupsWithCode}</div>
                  </div>
                  <div>
                    <div>
                      <p>Completed referrals</p>
                      <p>Referrals you got credits for</p>
                    </div>
                    <div>{completedReferals}</div>
                  </div>
                  <div>
                    <p>Referral program rewards</p>
                    <p><span>Earnings when you refer a guest</span><span>$50</span></p>
                    <p>When you refer a user who uses Drive mate to rent a car you are rewarded with $50 credits.</p>
                    <p>The referred users receive $25 when they signup.</p>
                  </div>
                </div>
                {/* Details Container Ends */}

                {/* QR Code Container Starts */}
                <div className={css.qrCodeBox}>
                  <div>
                    <p>
                      Share the love, invite friends & family and earn credits for you and them!
                    </p>
                    <div>
                      <QRCodeGenerator
                        ref={qrCodeRef}
                        url={referalLink ? referalLink : ''}
                      />
                    </div>
                    <div onClick={handleDownload}>
                      <img src={DownloadingArrow} alt='DOwnloading Arrow' style={{width: '10%', marginRight: '10px'}} />
                      Download QR
                    </div>
                  </div>
                  <hr style={{border: 'solid 1px #EBEBEB'}} />
                  <div>
                    <div>{shortenReferalUrl(referalLink)}</div>
                    <div onClick={copyToClipboard}>
                      <p style={{
                        opacity: copied ? 1 : 0,
                        visibility: copied ? 'visible' : 'hidden',
                        transition: 'opacity 0.25s ease',
                      }}>
                        Copied to clipboard
                      </p>
                      Copy link
                    </div>
                    <div>
                      <img onClick={shareOnWhatsapp} src={NewWhatsappIcon} />
                      <img onClick={shareOnMessenger} src={NewFBMessengerIcon} />
                      <img onClick={shareOnTwitter} src={NewTwitterIcon} />
                    </div>
                  </div>
                </div>
                {/* QR Code Container Ends */}
              </div>
            </>
          )}
          {/* Section to show if user is NOT logged in */}
          {!isUserLoggedIn && (
            <>
              <Modal
                id="ReferAHostPage.gmailContactsModal"
                isOpen={showGmailContactsModal}
                onClose={() => setShowGmailContactsModal(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                customContainerClassName={css.googleContactsContainer}
              >
                {showGmailContactsModal ? (
                  <GroupOfGoogleContacts
                    onManageDisableScrolling={onManageDisableScrolling}
                    emails={googleContacts}
                    submittedValues={submittedValues}
                    error={sendInvitationError}
                    inProgress={sendInvitationInProgress}
                    intl={intl}
                    onSubmit={values => {
                      const emails = values['googleContacts'];
                      submittedValues.current = values;
                      onSendInvitation({
                        profileImage: ensuredUser.profileImage,
                        // referrerName: ensuredUser.attributes.profile.displayName,
                        // referredEmails: emails,
                        // referralCode: userId,
                      });
                    }}
                  />
                ) : null}
              </Modal>

              {/* <h1 className={css.pageTitle}>Experience the unique Finnish home sauna.</h1> */}
              <div className={css.imgWrapper}>
                <img className={css.coverImage} src={image} alt="My first ice cream." />
              </div>

              <div className={css.contentWrapper}>
                <div className={css.contentMain}>
                  <div className={css.title}>Refer and earn</div>
                  <div className={css.content}>
                    <div className={css.mainInfo}>
                      Spread the love to your friends, family &amp; coworkers.
                      <br /> Invite them to join Drive mate. They'll get $25 credits when they join.{' '}
                      <br /> <br /> You’ll get, <br />
                      <br />
                      $50 when they rent a car
                    </div>
                    <br />
                    <a
                      className={css.termsAndConditions}
                      href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/terms-and-conditions-of-the-referral-program-ko8jZVbNNV/Steps/755554`}
                      target="_blank"
                    >
                      Terms & Conditions »
                    </a>
                  </div>
                  {/* <ReferralCodeEmailForm
                onSubmit={values => {
                  const { email } = values;
                  submittedValues.current = values;
                  onSendInvitation({
                    profileImage: ensuredUser.profileImage,
                    // referrerName: ensuredUser.attributes.profile.displayName,
                    // referredEmails: [email],
                    // referralCode: userId,
                  });
                }}
                intl={intl}
                submittedValues={submittedValues}
                // error={sendInvitationError}
                inProgress={sendInvitationInProgress}
              /> */}
                </div>

                {ensuredUser && (
                  <div className={classNames(css.creditsContentSide, css.grey)}>
                    <div className={css.innerContentSide}>
                      <img
                        className={css.drivelahCreditsIcon}
                        src={drivelahCreditsIcon}
                        alt="Drivelah Credits Icon"
                      />
                      <div className={css.creditsTitle}>Your Drive mate credits</div>
                      <div className={css.creditsContent}>
                        {' '}
                        Available: {ensuredUser.attributes.credits || 0}{' '}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={classNames(css.shareYourLink)}>
                <div className={css.shareYourLinkContentWrapper}>
                  <div className={css.shareYourLinkContentMain}>
                    <div className={css.shareYourLinkTitle}>
                      {intl.formatMessage({ id: 'ReferAHostPage.shareYourLink' })}
                    </div>
                    {currentUser ? (
                      <>
                        {' '}
                        <div className={css.copyReferralCodeSection}>
                          <div value={referalLink} className={css.copyReferralCode}>
                            {referalLink}
                          </div>

                          <div className={css.buttonWrapper}>
                            <Button
                              className={css.copyButton}
                              type={'button'}
                              onClick={() => {
                                if (window) {
                                  if (!navigator.clipboard) {
                                    setCopyStatus(false);
                                    setCopyMsg('Copy');
                                    return;
                                  }
                                  navigator.clipboard.writeText(sharingMsg).then(
                                    () => {
                                      setCopyStatus(true);
                                      setCopyMsg('Copied');
                                      showCopiedText(1);
                                      return;
                                    },
                                    err => {
                                      setCopyStatus(false);
                                      setCopyMsg('Copy');
                                      return;
                                    }
                                  );
                                }
                              }}
                            >
                              {copyMsg}
                            </Button>
                          </div>
                        </div>
                        <div className={css.copyError}>
                          {!copyStatus
                            ? intl.formatMessage({
                              id: 'ReferAHostPage.copyError',
                            })
                            : null}
                        </div>
                        <div className={css.shareIcons}>
                          {/* <a
                        className={css.logo}
                        onClick={() => onShareToFacebookTimeline(sharingMsg, signupReferralLink)}
                        target="_blank"
                      >
                        <img src={FacebookIcon} alt="Facebook-logo" />
                      </a> */}

                          <a
                            className={css.logo}
                            onClick={() => onShareToMessenger(referalLink)}
                            target="_blank"
                          >
                            <img src={FBMessengerIcon} alt="Facebook-messenger" />
                          </a>

                          <a className={css.logo} href={whatsAppLink} target="_blank">
                            <img src={WhatsAppIcon} alt="Whatsapp-icon" />
                          </a>

                          <a className={css.logo} href={twitterLink} target="_blank">
                            <img src={TwitterIcon} alt="Twitter-icon" />
                          </a>
                        </div>
                      </>
                    ) : (
                      <Button className={css.signUpButton} onClick={() => redirectToSignup()}>
                        Sign up now
                      </Button>
                    )}
                  </div>
                  {/* <div className={css.shareYourLinkContentSide}>
                <div className={css.content}>
                  <div className={css.suggestionTitle}>
                    {intl.formatMessage({ id: 'ReferAHostPage.suggestionTitle' })}
                  </div>
                  <div className={css.suggestionContent}>
                    Add your contacts to find friends who aren’t on Drive mate.
                  </div>
                </div>
                <a
                  className={css.suggestionsIcon}
                  onClick={() => {
                    new GoogleContacts({ clientId, scopes, apiKey })
                      .loadContacts()
                      .then(contacts => {
                        if (Array.isArray(contacts) && contacts.length > 0) {
                          setGoogleContacts(contacts);
                          setShowGmailContactsModal(true);
                        }
                      });
                  }}
                >
                  <img className={css.gmailIcon} src={EmailIcon} alt="email-icon" />
                  <span className={css.gmail}> Gmail </span>
                </a>
              </div> */}
                </div>
              </div>
              <div className={css.howToSection}>
                <div className={css.howToTitle}>
                  How to get {isMobileView ? <br /> : null} Drive mate credits
                </div>
                <div className={css.howToWrapper}>
                  <div className={css.step}>
                    <div className={css.stepLogo}>
                      {' '}
                      <span className={css.logoHelper}>
                        {' '}
                        <img src={ShareYourLinkIcon} alt="share-your-link-icon" />{' '}
                      </span>{' '}
                    </div>
                    <div className={css.stepTitle}>Share your link</div>
                    <div className={css.stepContent}>
                      {' '}
                      Invite people new to {desktopLineBreak} Drive mate to join by sharing your{' '}
                      {desktopLineBreak} link with them.
                    </div>
                  </div>
                  <div className={css.step}>
                    <div className={css.stepLogo}>
                      {' '}
                      <span className={css.logoHelper}>
                        <img src={TellFriendsIcon} alt="tell-friends-icon" />
                      </span>
                    </div>
                    <div className={css.stepTitle}> Tell friends to rent or host a trip</div>
                    <div className={css.stepContent}>
                      Friends who sign up for {desktopLineBreak} Drive mate with your code will{' '}
                      {desktopLineBreak} get $25 credits
                    </div>
                  </div>
                  <div className={css.step}>
                    <div className={css.stepLogo}>
                      {' '}
                      <span className={css.logoHelper}>
                        <img src={DrivelahCreditsIconWhite} alt="drivelah-credits-icon" />
                      </span>
                    </div>
                    <div className={css.stepTitle}>Earn Drive mate credits</div>
                    <div className={css.stepContent}>
                      When a referred user completes as trip you will get $50.
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const { sendInvitationError, sendInvitationInProgress } = state.ReferAHostPage;

  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    isAuthenticated,
    sendInvitationError,
    sendInvitationInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendInvitation: data => dispatch(sendInvitation(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

ReferAHostPage.loadData = () => {
  return fetchCurrentUser();
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(ReferAHostPage);
