import React, { memo } from 'react';
import { ImageCarousel, Modal } from '../../components';
import css from './LongTermRentalListingPage.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import shareIcon from '../../assets/share-green.svg';
import { get } from 'lodash';

const SectionImagesComponent = props => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    onOpenSharingModal,
  } = props;


  const review = get(listing, 'author.attributes.metadata.reviews.averageRating', '0');
  const trips = get(
    listing,
    'author.attributes.profile.metadata.intercomUserStat.number_of_trips_day_completed_as_host',
    '0'
  );
  const author = listing.author.attributes.profile.displayName;

  const carImage = listing.images[0].attributes.variants['landscape-crop'].url;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const shareButton = listing.id ? (
    <button className={css.sharebtn} onClick={onOpenSharingModal}>
      <img src={shareIcon} alt="" />
    </button>
  ) : null;


  return (
    <div className={css.sectionImages}>
      <div className={css.sectionCarHeading}>
        <div className={css.flexItem}>
          <h2>{title}</h2>
          <div className={css.sharebtns}>
            {/* <div className={css.heartbtn}>
              <img src={heartIcon} />
            </div> */}
            {shareButton}
          </div>
        </div>
        <div className={css.sectionSubheading}>
          <span>
            {/* <img src={star} className={css.starIcon} />
            <span className={css.rating}>{review && review <= 0 ? 'Not rated' : review}</span>
            {`(${trips} trips)`} <span className={css.circle}></span> */}
            {/* Hosted by{' '}
            <a href="" className={css.link}>
              {author}
            </a> */}
          </span>
        </div>
      </div>
      <div className={css.fourToTwoWrapper}>
        <div className={css.flexImageContainer} onClick={handleViewPhotosClick}>
          <div className={css.leftSideImage}>
            <div>
              <img src={carImage} />
            </div>
          </div>
          <div className={css.rightSideImage}>
            <div>
              {listing.images.length > 0 ? (
                [1, 2, 3, 4, 5].slice(1, 5).map((key, index) => {
                  const imageUrl = typeof listing.images[key - 1] !== 'undefined' ? listing.images[key - 1] : false;

                  return imageUrl ? (
                    <div className={css.thumbnail} key={index}>
                      <img src={imageUrl.attributes.variants['landscape-crop'].url} />
                    </div>
                  ) : (
                    <div className={css.thumbnail} key={index}></div>
                  );
                })
              ) : (
                <>
                  <div className={css.thumbnail}></div>
                </>
              )}
            </div>
          </div>
          {listing.images.length > 5 && (
            <div className={css.bottomActions} onClick={handleViewPhotosClick}>
              <div className={css.pattern}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span className={css.text}>Show all Photos</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

const CarGrid = memo(
  props => {
    return <SectionImagesComponent {...props} />;
  },
  (prev, next) => {
    return isEqual(
      pick(prev, ['title', 'listing', 'isOwnListing', 'editParams', 'imageCarouselOpen']),
      pick(next, ['title', 'listing', 'isOwnListing', 'editParams', 'imageCarouselOpen'])
    );
  }
);

export default CarGrid;
