import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldTextInput, Form } from '../../components';
import { emailFormatValid } from '../../util/validators';
import isEqual from 'lodash/isEqual';
import css from './ReferralCodeEmailForm.css';

const ReferralCodeEmailFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        rootClassName,
        intl,
        handleSubmit,
        ready,
        updated,
        invalid,
        values,
        submittedValues,
        inProgress,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className );

      const submittedOnce = Object.keys(submittedValues.current).length > 0;
      const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues.current);
      const submitDisabled = invalid || inProgress || pristineSinceLastSubmit;
      const submitReady = pristineSinceLastSubmit && !props.error;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            id="email"
            name="email"
            placeholder={intl.formatMessage({id: "ReferralCodeEmailForm.emailPlaceholder"})}
            inputRootClass={css.email}
            errorClassName={css.errorMsg}
            validate={emailFormatValid(intl.formatMessage({id: "ReferAHostPage.invalidEmail"}))}
          />
          {props.error && pristineSinceLastSubmit ? <div className={css.errorMsg}>{props.error}</div> : null}
          <Button
            className={css.submitButton}
            type="submit"
            disabled={submitDisabled}
            inProgress={inProgress}
            ready={submitReady}
          >
            {intl.formatMessage({ id: "ReferralCodeEmailForm.submitButton"})}
          </Button>
        </Form>
      )
    }}
  />
)

export default ReferralCodeEmailFormComponent;
