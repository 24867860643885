import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import Star from '../../assets/landingPageNew/star.svg';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import NoImageIcon from '../../components/ResponsiveImage/NoImageIcon';
import classNames from 'classnames';
import { formatHourlyMoney, formatMoney } from '../../util/currency';

import css from './NewGuestLandingPage.css';
import { Column, Container, Row } from '../../components/Grids/Grid';
import Slider from 'react-slick';
import { fetchFeaturedBCar } from '../SearchPage/SearchPage.duck';
import { createSlug } from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { getRandomNumber, getSuburbForListing, getShortStateForListing } from '../../util/helpers';
import { NamedLink } from '../../components';
import { get } from 'lodash';
import { STATE_WITH_SHORT_NAME } from '../../util/constants/general';

const RentCarSection = ({ intl, openSignUpModal, onfetchHourlyFeaturedCars, doModal }) => {
  const { featuredBCarIds, fetchFeaturedBCarInProgress, fetchFeaturedBCarError } = useSelector(
    state => state.SearchPage
  );
  const featuredBCarRefs = featuredBCarIds.map(id => ({ type: 'listing', id }));
  const [carListingType, setCarListingType] = useState('daily');
  const carListings = useSelector(state => getMarketplaceEntities(state, featuredBCarRefs));
  const listings = [...carListings].splice(0, 4) // We need to show only 4 tiles as per ticket, so trimming remaining once
  const noImageMessageText = <FormattedMessage id="ResponsiveImage.noImage" />;
  const imageSliderConfig = {
    autoplay: false,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const showDailyCarList = () => {
    setCarListingType('daily');
    onfetchHourlyFeaturedCars(null);
  };

  const showHourlyCarList = () => {
    setCarListingType('hourly');
    onfetchHourlyFeaturedCars({ isHourlyFeatured: true });
  };

  const getRatingForListing = (l) => {
    return get(l, 'attributes.metadata.reviews.averageRating', 0) ? get(l, 'attributes.metadata.reviews.averageRating', 0) : '0';
  }

  return (
    <div className={classNames(css.sectionWithBackground, css.rentCarWrapper)}>
      <Container>
        <Row className={css.sectionTitle}>
          <Column large={12}>
            <h2 className={css.heading}>
              Rent a car that suits your needs
              {/* <span>Rent by the hour or day</span> */}
              <span>For any adventure by the day, week or months</span>
            </h2>
          </Column>
        </Row>
        {/* <Row>
          <Column large={12}>
            <div className={css.carListingTabs}>
              <div className={css.carListingTabsOuter}>
                <span
                  className={css.activeSwitch}
                  style={{ left: carListingType === 'daily' ? 0 : 90 }}
                ></span>
                <div
                  className={
                    carListingType === 'daily'
                      ? `${css.tabButtons} ${css.active}`
                      : `${css.tabButtons}`
                  }
                  onClick={showDailyCarList}
                >
                  Daily
                </div>
                <div
                  className={
                    carListingType === 'hourly'
                      ? `${css.tabButtons} ${css.active}`
                      : `${css.tabButtons}`
                  }
                  onClick={showHourlyCarList}
                >
                  Hourly
                </div>
              </div>
            </div>
          </Column>
        </Row> */}
        <Row className={css.showDesktop}>
          {listings.map((l) => {
            const ensuredListing = ensureListing(l);
            const { title, price, metadata = {} } = ensuredListing.attributes;
            const id = ensuredListing.id.uuid;
            const slug = createSlug(title);
            const formattedHourlyPrice = formatHourlyMoney(intl, price, 1);
            const { isDrivelahGo } = metadata;
            return (
              <Column large={3} medium={4} small={12} key={`${getRandomNumber()}`}>
                <NamedLink
                  className={css.listingCardWrapper}
                  params={{ id, slug }}
                  name={isDrivelahGo ? 'ListingPageGo' : 'ListingPage'}
                >
                  <div className={css.imgWrapper}>
                    <div className={css.imgContainer}>
                      {/* <Button className={css.favoriteButton}>
                        <img src={heart} alt="" />
                      </Button> */}
                      {l.images[0] && l.images[0].attributes ? (
                        <Slider {...imageSliderConfig} className={css.slider}>
                          {l &&
                            l.images.length > 0 &&
                            l.images.map((image) => {
                              return (
                                <img
                                  key={`${getRandomNumber()}`}
                                  src={image.attributes.variants['landscape-crop'].url}
                                  className={css.rootImage}
                                />
                              );
                            })}
                        </Slider>
                      ) : (
                        <div className={css.noImageDisplayContainer}>
                          <div className={css.noImageContainer}>
                            <div className={css.noImageWrapper}>
                              <NoImageIcon />
                              <div className={css.noImageText}>{noImageMessageText}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={css.cardContent}>
                    <div className={css.heading}>
                      <h3>{l.attributes.title}</h3>
                      <div className={css.rating}>
                        <img src={Star} />
                        <span>{getRatingForListing(l)}</span>
                      </div>
                    </div>
                    <div className={css.features}>
                      {getSuburbForListing(l)}, {getShortStateForListing(l)}
                    </div>
                    <div className={css.price}>
                      <span className={css.rent}>
                        {formatMoney(intl, l.attributes.price)}
                        <strong className={css.timeSpan}>/day</strong>
                      </span>
                      {/*
                      <span className={classNames(css.rent, css.hourlyPrice)}>or</span>
                      <span className={classNames(css.rent, css.hourlyPrice)}>
                        {formattedHourlyPrice}
                        <span className={css.timeSpan}>/hour</span>
                      </span>
                      */}
                    </div>
                  </div>
                </NamedLink>
              </Column>
            );
          })}
        </Row>
        <Row className={css.showMobile}>
          {listings.map((l, index) => {
            const ensuredListing = ensureListing(l);
            const { title, price, metadata = {} } = ensuredListing.attributes;
            const id = ensuredListing.id.uuid;
            const slug = createSlug(title);
            const formattedHourlyPrice = formatHourlyMoney(intl, price, 1);
            const { isDrivelahGo } = metadata;
            if (index < 4) {
              return (
                <Column large={12} key={`${getRandomNumber()}`}>
                  <NamedLink
                    className={css.listingCardWrapper}
                    params={{ id, slug }}
                    name={isDrivelahGo ? 'ListingPageGo' : 'ListingPage'}
                  >
                    <div className={css.imgWrapper}>
                      <div className={css.imgContainer}>
                        {/* <Button className={css.favoriteButton}>
                          <img src={heart} alt="" />
                        </Button> */}
                        {l.images[0] && l.images[0].attributes ? (
                          <Slider {...imageSliderConfig} className={css.slider}>
                            {l &&
                              l.images.length > 0 &&
                              l.images.map((image) => {
                                return (
                                  <img
                                    key={`${getRandomNumber()}`}
                                    src={image.attributes.variants['landscape-crop'].url}
                                    className={css.rootImage}
                                  />
                                );
                              })}
                          </Slider>
                        ) : (
                          <div className={css.noImageDisplayContainer}>
                            <div className={css.noImageContainer}>
                              <div className={css.noImageWrapper}>
                                <NoImageIcon />
                                <div className={css.noImageText}>{noImageMessageText}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={css.cardContent}>
                      <div className={css.heading}>
                        <h3>{l.attributes.title}</h3>
                        <div className={css.rating}>
                          <img src={Star} />
                          <span>{getRatingForListing(l)}</span>
                        </div>
                      </div>
                      <div className={css.features}>
                        {getSuburbForListing(l)}, {getShortStateForListing(l)}
                      </div>
                      <div className={css.price}>
                        <span className={css.rent}>
                          {formatMoney(intl, l.attributes.price)}
                          <strong className={css.timeSpan}>/day</strong>
                        </span>
                        {/*
                         <span className={classNames(css.rent, css.hourlyPrice)}>or</span>
                         <span className={classNames(css.rent, css.hourlyPrice)}>
                          {formattedHourlyPrice}
                          <span className={css.timeSpan}>/hour</span>
                         </span>
                         */}
                      </div>
                    </div>
                  </NamedLink>
                </Column>
              );
            }
            return <></>;
          })}
          {/* <Slider {...sliderConfig}>
            {listings.map(l => (
              <Column large={12}>
                <div className={css.listingCardWrapper}>
                  <div className={css.imgWrapper}>
                    <div className={css.imgContainer}>
                      {l.images[0] && l.images[0].attributes ? (
                        <img
                          src={l.images[0].attributes.variants['landscape-crop'].url}
                          className={css.rootImage}
                        />
                      ) : (
                        <div className={css.noImageDisplayContainer}>
                          <div className={css.noImageContainer}>
                            <div className={css.noImageWrapper}>
                              <NoImageIcon />
                              <div className={css.noImageText}>{noImageMessageText}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={css.cardContent}>
                    <div className={css.heading}>
                      <h3>{l.attributes.title}</h3>
                      <div className={css.rating}>
                        <img src={Star} />
                        <span>4.4</span>
                      </div>
                    </div>
                    <div className={css.features}>
                      {l.attributes.publicData.transmissions}, {l.attributes.publicData.fuelType}
                    </div>
                    <div className={css.price}>
                      <span className={css.rent}>
                        {formatMoney(intl, l.attributes.price)}
                        <span className={css.timeSpan}>/day</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Column>
            ))}
          </Slider> */}
        </Row>
        <Row>
          <Column large={12}>
            <div className={classNames(css.btnWrapper, css.showDesktop)}>
              <button onClick={doModal} className={classNames(css.btn, css.btnPrimary)}>
                Ready to Drive?
              </button>
            </div>
            <div className={classNames(css.btnWrapper, css.showMobile)}>
              <button onClick={doModal} className={classNames(css.btn, css.btnPrimary)}>
                Ready to Drive?
              </button>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { searchParams, featuredCarIds } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);

  return {
    searchParams,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onfetchHourlyFeaturedCars: (filterParams) => dispatch(fetchFeaturedBCar(filterParams)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(RentCarSection);
// export default RentCarSection;
