import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.css';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const SectionFeaturesMaybe = memo(props => {
  const { id, title, options, selectedOptions } = props;
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id={title} />
      </h2>
      <PropertyGroup
        id={id}
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  );
}, (prev, next) => {
    return isEqual(
      pick(prev, ['options', 'selectedOptions']),
      pick(next, ['options', 'selectedOptions']),
    );
});

export default SectionFeaturesMaybe;
