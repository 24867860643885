import React, { useEffect, useRef, useState } from 'react';
import css from './FieldPasswordInput.css';
import { Field } from 'react-final-form';
import { ValidationError } from '..';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const FieldPasswordInputComp = props => {
  const [show, setShow] = useState(false);

  const handleClickShow = () => {
    inputRef.current.type = show ? 'password' : 'text';
    setShow(!show)
  };
  const inputRef = useRef(null);

  const {
    rootClassName,
    className,
    inputClassName,
    inputRootClass,
    customErrorText,
    id,
    label,
    input,
    meta,
    isUncontrolled,
    labelClassName,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;
  const errorText = customErrorText || error;

  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const { value: defaultValue, type, ...inputWithoutValue } = input;
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(css.input, inputClassName, {
      [css.inputSuccess]: valid,
      [css.inputError]: hasError,
    });

  const inputProps = isUncontrolled
    ? {
      className: inputClasses,
      id,
      type: show ? 'text' : 'password',
      defaultValue,
      ...refMaybe,
      ...inputWithoutValue,
      ...rest,
    }
    : { className: inputClasses, id, type: show ? 'text' : 'password', ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label className={labelClassName} htmlFor={id}>{label}</label> : null}
      <label className={classNames(css.showLabel, labelClassName)} onClick={handleClickShow}>{show ? <FormattedMessage id='FieldPasswordInput.hide' /> : <FormattedMessage id='FieldPasswordInput.show'/>  }</label>
      <input {...inputProps} />
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

const FieldPasswordInput = ({ onUnmount, ...rest }) => {
  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function')
        onUnmount();
    }
  }, []);

  return <Field component={FieldPasswordInputComp} {...rest} />;
}

export default FieldPasswordInput;
