import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import downloadIcon from '../../assets/tripPage/downloadIcon.svg';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemDownloadReceipt = props => {
  const { transaction, isProvider, intl, currentUser, isAddons, isTripDetailsPage } = props;

  console.log("Current user", currentUser);

  const stripeReceiptUrl = get(transaction, 'attributes.metadata.stripeReceiptUrl', '');

  const paidAt = get(transaction, 'attributes.createdAt', '');


  if(!stripeReceiptUrl || !currentUser || !isTripDetailsPage) {
    return null;
  }

  const handleDownload = () => {
    // Replace "s3-link-to-pdf" with the actual S3 link to the PDF file
    const s3Link = stripeReceiptUrl;
    window.open(s3Link, '_blank');
  };


  return (
    <div className={css.receiptContainer} onClick={handleDownload}>
      <div className={css.receiptContent}>
        <div className={css.receiptContentText}>{'Download payment receipt'}</div>
        <p>{`Paid on: ${moment(paidAt).format('DD.MM.YYYY')}`}</p>
      </div>
      <div className={css.receiptIcon}>
          <img src={downloadIcon} alt="Addon preview"  />
      </div>
    </div>
  );
};

LineItemDownloadReceipt.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDownloadReceipt;
