import React from 'react';
import css from './InsuranceDisclaimer.css';

const InsuranceDisclaimer = (props) => {
  return (
    <div className={css.root}>
      {/* <span> All cars aged less than or equal to 12 years are comprehensively insured. Cars aged higher than 12 years are insured via third party coverage only. </span> */}
    </div>
  );
};

export default InsuranceDisclaimer;
