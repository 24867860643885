import React from 'react';
import css from './EditListingPhotosForm.css';
import Modal from '../../components/Modal/Modal';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { ExternalLink } from '../../components';

const PhotosModal = props => {
  const { isModalOpen, changeModalVisiblity, onManageDisableScrolling } = props;
  // const [submitDisabled, setSubmitDisabled] = useState(true);

  return (
    <div className={css.modalContainer}>
      <Modal
        isOpen={isModalOpen}
        closeButtonMessage=" "
        onClose={() => {
          changeModalVisiblity(false);
        }}
        containerClassName={css.containerContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>
          <h3 className={css.modalTitle}>Sample Photos</h3>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1602-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1602-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1617-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1617-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1623-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1623-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1631-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1631-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1634-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1634-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1639-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1639-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>

          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1642-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1642-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1650-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1650-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1659-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1659-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
          <div className={css.sampleLink}>
            <img
              className={css.sampleImage}
              src={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1666-min-min-min.jpg'
              }
            />
            <ExternalLink
              href={
                'https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/Sample+Images+-+Compressed/522A1666-min-min-min.jpg'
              }
              className={css.link}
            ></ExternalLink>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default connect(null, mapDispatchToProps)(PhotosModal);
