import React from 'react';
import classNames from 'classnames';
import css from './LandingPageSignupSection.css';
import { LandingPagePromoForm } from '../../forms';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { types } from '../../util/sdkLoader';
import config from '../../config';
import { IconSpinner } from '../../components';

const { Money } = types;
const LandingPageSignupSection = ({
  isScoot,
  signUpCodeAmount,
  loading,
  getToCalculatorSection,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const currentUser = useSelector(state => state.user.currentUser);
  const intl = useIntl();
  const handleSubmit = ({ email, password }) => {
    const url = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
    history.push(
      code
        ? {
            pathname: url,
            search: `?code=${code}`,
            state: {
              email,
              password,
              code,
            },
          }
        : {
            pathname: url,
            state: {
              email,
              password,
            },
          }
    );
  };

  return (
    <div className={css.sectionHero}>
      <div className={css.landingContainer}>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          <div className={css.landingFormWrapper}>
            {loading ? (
              <div className={css.loadingWrapper}>
                <IconSpinner className={css.loadingSpinner} />
              </div>
            ) : currentUser && currentUser.id ? (
              <>
                <div className={css.signUpTitle}>
                  {signUpCodeAmount && signUpCodeAmount > 0 ? (
                    <FormattedMessage
                      id={
                        isScoot
                          ? 'HostSignUpPromoLandingPage.scootSchemaTitle'
                          : 'HostSignUpPromoLandingPage.schemaTitle'
                      }
                      values={{
                        amount: signUpCodeAmount
                          ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                          : '$0',
                      }}
                    />
                  ) : (
                    <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
                  )}
                </div>

                <p className={css.loginDesc}>
                  This promo is only available to new users. You will have to logout and create a
                  new account.
                </p>
              </>
            ) : (
              <>
                <div className={css.signUpTitle}>
                  {signUpCodeAmount && signUpCodeAmount > 0 ? (
                    <FormattedMessage
                      id={
                        isScoot
                          ? 'HostSignUpPromoLandingPage.scootSchemaTitle'
                          : 'HostSignUpPromoLandingPage.schemaTitle'
                      }
                      values={{
                        amount: signUpCodeAmount
                          ? formatMoney(intl, new Money(signUpCodeAmount, config.currency))
                          : '$0',
                      }}
                    />
                  ) : (
                    <FormattedMessage id={'HostSignUpPromoLandingPage.signUpNow'} />
                  )}
                </div>
                <LandingPagePromoForm onSubmit={handleSubmit} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageSignupSection;
