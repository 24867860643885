// ================ Action types ================ //

const SET_REDIRECT_URL = 'app/Topbar/SET_REDIRECT_URL';
const CLEAR_REDIRECT_URL = 'app/Topbar/CLEAR_REDIRECT_URL';

// ================ Reducer ================ //

const initialState = {
  redirectUrl: null,
};

export default function topbarReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_REDIRECT_URL:
      return { ...initialState, redirectUrl: payload };
    case CLEAR_REDIRECT_URL:
      return { ...initialState, redirectUrl: null };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const selectLoginOrSugnupRedirectUrl = state =>
  state.Topbar.redirectUrl
;

// ================ Action creators ================ //
const setRedirectUrl = url => ({
  type: SET_REDIRECT_URL,
  payload: url,
});

// ================ Thunks ================ //

export const loginOrSignupClick = () => (dispatch, getState, sdk) => {
  // if user came from login or signup page.
  if (selectLoginOrSugnupRedirectUrl(getState())) {
    return ;
  }
  const pathname = window.location.pathname;
  const search = window.location.search;
  const url = `${pathname}${search}`;
  dispatch(setRedirectUrl(url));
};

export const clearRedirectUrl = () => (dispatch, getState, sdk) => {
  dispatch({ type: CLEAR_REDIRECT_URL });
};
