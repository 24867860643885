import React, { Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { FieldDateRangeController, FilterPlain, FilterPopup } from '../../components';
import css from './BookingDateRangeFilter.css';
import FieldSelect from '../FieldSelect/FieldSelect';
import config from '../../config';
import { required } from '../../util/validators';
import { sameDay } from '../../util/dates';
import { FormSpy } from 'react-final-form';

const isToDay = date => sameDay(date, new Date());
const todayMinutes = () => {
  const now = new Date();
  return now.getHours() * 60 + now.getMinutes();
};

export class BookingDateRangeFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTimeSet: config.custom.timeSetInput,
      endTimeSet: config.custom.timeSetInput,
    };

    this.popupControllerRef = null;
    this.plainControllerRef = null;
    this.form = null;
  }

  handleDateChange = ({ values, active }) => {
    const { startDate, endDate } = values.dates || {};
    if (active && active.includes('dates')) {
      if (startDate) {
        const today = isToDay(startDate);
        const startTimeSet = today
          ? config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > todayMinutes())
          : config.custom.timeSetInput;
        if (today && this.form) {
          this.form.change('times.pickupTime', startTimeSet[0] && startTimeSet[0].key);
        }
        this.setState(prev => ({
          ...prev,
          startTimeSet,
        }));
      }
      if (endDate) {
        const today = isToDay(endDate);
        const endTimeSet = today
          ? config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > todayMinutes())
          : config.custom.timeSetInput;
        if (today && this.form) {
          this.form.change('times.dropOffTime', endTimeSet[0] && endTimeSet[0].key);
        }
        this.setState(prev => ({
          ...prev,
          endTimeSet,
        }));
      }
    }
  };

  handleSetForm = form => {
    if (!this.form) this.form = form;
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.initialValues.dates && !nextProps.initialValues.dates) {
      if (this.form) {
        this.form.change('times', {});
      }
      if (this.plainControllerRef && this.plainControllerRef.onReset) {
        this.plainControllerRef.onReset(null, null);
      }
      if (this.popupControllerRef && this.popupControllerRef.onReset) {
        this.popupControllerRef.onReset(null, null);
      }
    }
  }

  render() {
    const {
      className,
      rootClassName,
      showAsPopup,
      initialValues: initialValuesRaw,
      id,
      contentPlacementOffset,
      onSubmit,
      urlParam,
      intl,
      ...rest
    } = this.props;

    const isSelected = !!initialValuesRaw && !!initialValuesRaw.dates;
    const initialValues = isSelected ? initialValuesRaw : { dates: null };

    const startDate = isSelected ? initialValues.dates.startDate : null;
    const endDate = isSelected ? initialValues.dates.endDate : null;

    const format = {
      month: 'short',
      day: 'numeric',
    };

    const formattedStartDate = isSelected ? intl.formatDate(startDate, format) : null;
    const formattedEndDate = isSelected ? intl.formatDate(endDate, format) : null;

    const labelForPlain = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPlain' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPlain' });

    const labelForPopup = isSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeFilter.labelSelectedPopup' },
          {
            dates: `${formattedStartDate} - ${formattedEndDate}`,
          }
        )
      : intl.formatMessage({ id: 'BookingDateRangeFilter.labelPopup' });

    const onClearPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onClear: () => this.popupControllerRef.onReset(null, null) }
        : {};

    const onCancelPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? { onCancel: () => this.popupControllerRef.onReset(startDate, endDate) }
        : {};

    const onClearPlainMaybe =
      this.plainControllerRef && this.plainControllerRef.onReset
        ? { onClear: () => this.plainControllerRef.onReset(null, null) }
        : {};

    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={labelForPopup}
        isSelected={isSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialValues}
        urlParam={urlParam}
        handleSetForm={this.handleSetForm}
        {...rest}
      >
        <FormSpy subscription={{ values: true, active: true }} onChange={this.handleDateChange} />
        <FieldDateRangeController
          name="dates"
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
        />
        <div className={css.times}>
          <FieldSelect
            useMobileMargins={false}
            className={css.timeSelect}
            name="times.pickupTime"
            label="Pick up"
            id="pickupTime"
            // disabled={!values.pickUp}
            // labelClassName={css.label}
            validate={required('Please select time')}
            placeholderText={'12:00 am'}
          >
            <option disabled value="">
              10:00 am
            </option>
            {this.state.startTimeSet.map(op => (
              <option value={op.key} key={`pick_up_${op.key}`}>
                {op.label}
              </option>
            ))}
          </FieldSelect>
          <FieldSelect
            useMobileMargins={false}
            className={css.timeSelect}
            name="times.dropOffTime"
            label="Drop off"
            id="dropOffTime"
            // disabled={!values.pickUp}
            // labelClassName={css.label}
            validate={required('Please select time')}
            placeholderText={'12:00 am'}
          >
            <option disabled value="">
              10:00 am
            </option>
            {this.state.endTimeSet.map(op => (
              <option value={op.key} key={`pick_up_${op.key}`}>
                {op.label}
              </option>
            ))}
          </FieldSelect>
        </div>
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={isSelected}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={onSubmit}
        {...onClearPlainMaybe}
        initialValues={initialValues}
        urlParam={urlParam}
        handleSetForm={this.handleSetForm}
        {...rest}
      >
        <FormSpy subscription={{ values: true }} onChange={this.handleDateChange} />
        <FieldDateRangeController
          name="dates"
          controllerRef={node => {
            this.plainControllerRef = node;
          }}
        />
        <div className={css.times}>
          <FieldSelect
            useMobileMargins={false}
            className={css.timeSelect}
            name="times.pickupTime"
            label="Pick up"
            id="pickupTime"
            // disabled={!values.pickUp}
            // labelClassName={css.label}
            validate={required('Please select time')}
            placeholderText={'12:00 am'}
          >
            <option disabled value="">
              10:00 am
            </option>
            {this.state.startTimeSet.map(op => (
              <option value={op.key} key={`pick_up_${op.key}`}>
                {op.label}
              </option>
            ))}
          </FieldSelect>
          <FieldSelect
            useMobileMargins={false}
            className={css.timeSelect}
            name="times.dropOffTime"
            label="Drop off"
            id="dropOffTime"
            // disabled={!values.pickUp}
            // labelClassName={css.label}
            validate={required('Please select time')}
            placeholderText={'12:00 am'}
          >
            <option disabled value="">
              10:00 am
            </option>
            {this.state.endTimeSet.map(op => (
              <option value={op.key} key={`pick_up_${op.key}`}>
                {op.label}
              </option>
            ))}
          </FieldSelect>
        </div>
      </FilterPlain>
    );
  }
}

BookingDateRangeFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  showAsPopup: true,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

BookingDateRangeFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  showAsPopup: bool,
  liveEdit: bool,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeFilter = injectIntl(BookingDateRangeFilterComponent);

export default BookingDateRangeFilter;
