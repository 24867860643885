import React from 'react';
import css from './DonationPage.css';
import driveTogetherIcon from '../../assets/donationPage/drive-together-icon.svg';
import classNames from 'classnames';

const DonationContribute = () => {
  return (
    <div className={css.contributeSection}>
      <div className={css.landingContainer}>
        <div className={classNames(css.landingRow, css.alignItemsCenter)}>
          <div className={css.column6}>
            <div className={css.logoThumb}>
              <img src={driveTogetherIcon} alt="" />
            </div>
          </div>
          <div className={css.column6}>
            <h2>
              How Drive <span>together®</span> <br /> contributes
            </h2>
            <p>
              Drive mate (SG) and Drive mate (AU) donate all their March and April earnings to fund
              for food, shelter, medical care and clean water.
            </p>
            <p>
              We also offer our hosts the possibility to donate directly or as part of their
              earnings
            </p>
            <p>
              Our guests are given the possibility to donate directly or as part of their trips.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationContribute;
