import moment from 'moment';

export default class BookingPeriod {
  constructor(start, end) {
    this._start = moment(start);
    this._end = moment(end);
    this._duration = moment.duration(this._end.diff(this._start));
    this._shortDOW = this._shortDaysOfWeek();
  }

  _numberDayOfWeekByDay(day) {
    return this._shortDOW.indexOf(day);
  }

  _countDayOfWeekInPeriod(day) {
    const d = this._start.clone();
    let count = 0;
    while (d.isSameOrBefore(this._end, 'day')) {
      if (d.day() === this._numberDayOfWeekByDay(day)) {
        count++;
      }
      d.add(1, 'd');
    }
    return count;
  }

  _shortDaysOfWeek() {
    const day = moment().startOf('week')
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(day.format('ddd').toLowerCase());
      day.add(1, 'd');
    }
    return days;
  }

  _hoursInPeriodByTypedDays(typedDays) {
    const d = this._start.clone();
    let halfHours = 0;
    while (d.isBefore(this._end)) {
      if (typedDays.includes(this._shortDaysOfWeek()[d.day()])) {
        halfHours++;
      }
      d.add(30, 'm');
    }
    return halfHours / 2;
  }

  getRegularDays(pricing) {
    return pricing ? pricing.regularDays : [];
  }

  getPeakDays(pricing) {
    return pricing ? pricing.peakDays : [];
  }

  asHours() {
    return this._duration.asHours();
  }

  asDays() {
    return this._duration.asDays();
  }

  regularDaysCount(pricing) {
    return this.getRegularDays(pricing).reduce((acc, day) => acc + this._countDayOfWeekInPeriod(day), 0);
  }

  peakDaysCount(pricing) {
    return this.getPeakDays(pricing).reduce((acc, day) => acc + this._countDayOfWeekInPeriod(day), 0);
  }

  regularHoursCount(pricing) {
    return this._hoursInPeriodByTypedDays(this.getRegularDays(pricing));
  }

  peakHoursCount(pricing) {
    return this._hoursInPeriodByTypedDays(this.getPeakDays(pricing));
  }
}