import moment from 'moment-timezone';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureOwnListing, getListingMonthlyPrice } from '../../util/data';
import { sameDay, timeOfDayFromTimeZoneToLocal } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;
const { getMarketplaceEntities } = require('../../ducks/marketplaceData.duck');

export const getListingFromState = state => id => {
  const ref = { id, type: 'listing' };
  const [listing] = getMarketplaceEntities(state, [ref]);
  return ensureListing(listing);
};

export const getOwnListingFromState = state => id => {
  const ref = { id, type: 'ownListing' };
  const [ownListing] = getMarketplaceEntities(state, [ref]);
  return ensureOwnListing(ownListing);
};

export const listingImages = (listing, variantName) =>
  (listing.images || [])
    .map(image => {
      const variants = image.attributes.variants;
      const variant = variants ? variants[variantName] : null;

      // deprecated
      // for backwards combatility only
      const sizes = image.attributes.sizes;
      const size = sizes ? sizes.find(i => i.name === variantName) : null;

      return variant || size;
    })
    .filter(variant => variant != null);

export const priceData = (price, intl, perMonthPrice = false, listing) => {
  if (price && price.currency === config.currency) {
    const priceMaybe = perMonthPrice ? getListingMonthlyPrice(listing) : price;
    const formattedPrice = formatMoney(intl, priceMaybe);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

const createDateRange = (start, end, currentSession, currentIndex) => {
  const array = [];
  const endRange = moment(end);
  for (let i = moment(start); i.isBefore(endRange); ) {
    const endOfDate = i.clone().endOf('date');
    const endSlotMaybe = endOfDate.isBefore(endRange) ? endOfDate.toDate() : endRange.toDate();
    array.push({
      session: currentSession,
      index: currentIndex++,
      attributes: {
        start: i.clone().toDate(),
        end: endSlotMaybe,
        seats: 1,
        type: 'time-slot/time',
      },
    });
    i = i.add(1, 'days').startOf('day');
  }

  return array;
};

export const splitTimeSlotToLocalTime = (slots, timezone) => {
  const slotsInLocalTime = slots.map(slot => {
    const { start, end } = slot.attributes;
    return {
      ...slot,
      attributes: {
        ...slot.attributes,
        start: timeOfDayFromTimeZoneToLocal(start, timezone),
        end: timeOfDayFromTimeZoneToLocal(end, timezone),
      },
    };
  });

  let array = [];
  let session = 0;

  slotsInLocalTime.forEach(slot => {
    const { start, end } = slot.attributes;
    if (sameDay(start, end)) {
      array.push(Object.assign({}, slot, { session: session++ }));
    } else {
      const _array = createDateRange(start, end, session++);
      array = array.concat(_array);
    }
  });

  return array;
};
