import React from 'react';
import { FieldSelect } from '../../components';
import classNames from 'classnames';
import config from '../../config';
import { string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';

import css from './TimeSelect.css';
import moment from 'moment';

const createTimeSelectOptions = (selectedDate, timeSlotsObj) => {
  if (!selectedDate) return [];
  const timeSlots = config.custom.timeSetInput;
  const selectedSlots = timeSlotsObj[moment(selectedDate).format('DD/MM/YYYY')];
  const today = new Date();
  const isToday = selectedDate
    ? selectedDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    : false;
  const todayMinutes = today.getHours() * 60 + today.getMinutes();
  if (!selectedSlots || !Array.isArray(selectedSlots)) return [];
  const result = [];

  selectedSlots.forEach(slot => {
    const { start, end } = slot.attributes;
    const momentStart = moment(start);
    const momentEnd = moment(end);
    const startMinutes = momentStart.hours() * 60 + momentStart.minutes();
    const endMinutes = momentEnd.hours() * 60 + momentEnd.minutes();

    result.push(
      ...timeSlots.filter(slot => {
        const slotMinutes = slot.hour * 60 + slot.minutes;
        return isToday
          ? slotMinutes >= startMinutes && slotMinutes <= endMinutes && todayMinutes <= slotMinutes
          : slotMinutes >= startMinutes && slotMinutes <= endMinutes;
      })
    );
  });
  return result;
};

const TimeSelect = props => {
  const { className, name, id, label, selectedDate, timeSlotsObj, ...rest } = props;

  const timePlaceholder = '00:00';

  const timeSlots = createTimeSelectOptions(selectedDate, timeSlotsObj);

  return timeSlots ? (
    <FieldSelect
      className={classNames(css.timeInput, className)}
      name={name}
      id={id}
      label={label}
      {...rest}
    >
      <option disabled value="">
        {timePlaceholder}
      </option>
      {timeSlots.map(c => {
        return (
          <option key={c.key} value={c.key}>
            {c.label}
          </option>
        );
      })}
    </FieldSelect>
  ) : null;
};

TimeSelect.defaultProps = {
  className: null,
  timeSlotsObj: {},
};

TimeSelect.propTypes = {
  className: string,
  name: string.isRequired,
  label: string,
  id: string,
  intl: intlShape.isRequired,
};

export default injectIntl(TimeSelect);
