import React, { useEffect } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import css from './AmbassadorProgram.css';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const AmbassadorProgram = ({ intl }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      window.location.replace(
        'https://drivelah.notion.site/Drive-lah-Ambassador-Program-fb9a5052c0c44b3ebc64a62372f0cd1d'
      );
    }
  }, []);

  const schemaTitle = intl.formatMessage({ id: 'AmbassadorProgramPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'AmbassadorProgramPage.schemaDescription' });

  return (
    <StaticPage
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AmbassadorProgram',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>Redirecting...</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
export default compose(injectIntl)(AmbassadorProgram);
