export const REQUEST_STATUS__NULL = 'null';
export const REQUEST_STATUS__PENDING = 'pending';
export const REQUEST_STATUS__SUCCESS = 'success';
export const REQUEST_STATUS__ERROR = 'error';

export const SignupPhoneExperiment = {
  name: 'SignupPhoneExperiment',
  A: 'CASE_A',
  B: 'CASE_B',
};

/**
 * Constant keys to hold DOB values
 * @typedef {Object} DOB_MOBILE_KEYS
 * @property {string} day_1 - First key of the day
 * @property {string} day_2 - Second key of the day
 * @property {string} month_1 - First key of the month
 * @property {string} month_2 - Second key of the month
 * @property {string} year_1 - First key of the year
 * @property {string} year_2 - Second key of the year
 * @property {string} year_3 - Third key of the year
 * @property {string} year_4 - Fourth key of the year
 */
export const DOB_MOBILE_KEYS = {
  day_1: 'day-1',
  day_2: 'day-2',
  month_1: 'month-1',
  month_2: 'month-2',
  year_1: 'year-1',
  year_2: 'year-2',
  year_3: 'year-3',
  year_4: 'year-4',
};

/**
 * Date of birth object with keys for individual digits
 * @typedef {Object} INITIAL_DOB
 * @property {string} day_1
 * @property {string} day_2
 * @property {string} month_1
 * @property {string} month_2
 * @property {string} year_1
 * @property {string} year_2
 * @property {string} year_3
 * @property {string} year_4
 */
export const INITIAL_DOB = {
  [DOB_MOBILE_KEYS.day_1]: '',
  [DOB_MOBILE_KEYS.day_2]: '',
  [DOB_MOBILE_KEYS.month_1]: '',
  [DOB_MOBILE_KEYS.month_2]: '',
  [DOB_MOBILE_KEYS.year_1]: '',
  [DOB_MOBILE_KEYS.year_2]: '',
  [DOB_MOBILE_KEYS.year_3]: '',
  [DOB_MOBILE_KEYS.year_4]: '',
};

export const INPUT_TYPE = 'tel';
