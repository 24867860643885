import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { FilterForm } from '../../forms';
import css from './FilterPlain.css';

class FilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true, isClear: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, urlParam } = this.props;
    onSubmit(urlParam, values);
  }

  handleClear() {
    const { onSubmit, onClear, urlParam } = this.props;
    
    if (onClear) {
      onClear();
    }

    onSubmit(urlParam, null);
    this.setState({ isClear: true });
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  setClear = () => {
    this.setState({ isClear: false });
  };

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      label,
      isSelected,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
      clearButtonClass,
      labelClassName,
      labelHelperText,
      shouldHideClearButton,
      handleSetForm,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const clearButtonClasses = classNames(clearButtonClass, css.clearButton);
    const labelClass = isSelected ? css.filterLabelSelected : css.filterLabel;

    const labelHelperTextElement = labelHelperText ? (
      <div className={css.labelHelperText}> {labelHelperText} </div>
    ) : null;

    return (
      <div className={classes}>
        <div className={classNames(labelClassName, labelClass)}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <div className={classNames(labelClassName, labelClass)}>{label}</div>
            {labelHelperTextElement}
          </button>
          {!shouldHideClearButton ? (
            <button type="button" className={clearButtonClasses} onClick={this.handleClear}>
              <FormattedMessage id={'FilterPlain.clear'} />
            </button>
          ) : null}
        </div>
        <div
          id={id}
          className={classNames(plainClassName, css.plain, { [css.isOpen]: this.state.isOpen })}
          ref={node => {
            this.filterContent = node;
          }}
        >
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            handleSetForm={handleSetForm}
            isClear={this.state.isClear}
            setClear={this.setClear}
          >
            {children}
          </FilterForm>
        </div>
      </div>
    );
  }
}

FilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  shouldHideClearButton: false,
};

FilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  label: node.isRequired,
  isSelected: bool.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  shouldHideClearButton: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterPlain = injectIntl(FilterPlainComponent);

export default FilterPlain;
