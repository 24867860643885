import React, { Component } from 'react';
import { func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';

import { PriceFilterForm } from '../../forms';

import css from './PriceFilterPlain.css';
import get from 'lodash/get';

class PriceFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, urlParam } = this.props;
    onSubmit(urlParam, values);
  }

  handleClear() {
    const { onSubmit, urlParam } = this.props;
    onSubmit(urlParam, null);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      initialValues,
      min,
      max,
      step,
      labelProps,
      intl,
      clearButtonClass,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const clearButtonClasses = classNames(clearButtonClass, css.clearButton);
    const price = get(initialValues, 'price', null);
    const selectedValue = config.custom.priceRange.find(p => p.key === price);
    const hasInitialValues = selectedValue;

    const labelText = selectedValue
      ? intl.formatMessage(
          { id: 'PriceFilter.labelSelectedPlain' },
          {
            range: selectedValue.label,
          }
        )
      : labelProps
      ? intl.formatMessage({ id: labelProps })
      : intl.formatMessage({ id: 'PriceFilter.label' });

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={labelClass}>{labelText}</span>
          </button>
          <button type="button" className={clearButtonClasses} onClick={this.handleClear}>
            <FormattedMessage id={'PriceFilter.clear'} />
          </button>
        </div>
        <div className={css.formWrapper}>
          <PriceFilterForm
            id={id}
            initialValues={initialValues ? initialValues : {}}
            onChange={this.handleChange}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            min={min}
            max={max}
            step={step}
            liveEdit
            isOpen={this.state.isOpen}
          />
        </div>
      </div>
    );
  }
}

PriceFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
  currencyConfig: config.currencyConfig,
};

PriceFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    minPrice: number.isRequired,
    maxPrice: number.isRequired,
  }),
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

const PriceFilterPlain = injectIntl(PriceFilterPlainComponent);

export default PriceFilterPlain;
