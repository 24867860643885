import React from 'react';
import config from '../../config';
import classNames from 'classnames';
import { StaticPage, TopbarContainer } from '..';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';

import css from './TrustAndSafetyPage.css';
import infinityImage from './infinity.png';
import theftImage from './thieft.png';
import verifyImage from './verify.png';
import moneyImage from './money.png';
import ncdImage from './ncd.png';
import crashCarImage from './crashcar.png';
import truckCarImage from './truckcar.png';
import liftCarImage from './liftcar.png';
import damage2 from '../../assets/trustAnfSafety/damage2.svg';
import safeFirst from '../../assets/logos/insuret-logo.svg';
import { default as WhenYouDriveItem } from './WhenYouDriveItem';

const TrustAndSafetyPage = () => {
  const { siteInstagramPage, siteFacebookPage } = config;

  // prettier-ignore
  return (
    <StaticPage
      title="Comprehensively insured trips for maximum Trust and Safety | Drivemate"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'TrustAndSafetyPage',
        description: 'Share safely, drive confidently. Up to $35M third party liability coverage for all trips. 24x7 roadside assistance. Embrace complete trust and safety | Drivemate',
        name: 'Trust and safety page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.coverImageContaienr}>
            <img className={css.coverImage} src={infinityImage} alt="Fully insured car rental Australia | Drive Mate" />
          </div>
          <div className={css.backgroundWhite}>
            <div className={css.sloganWrapper}>
              <div className={classNames(css.sloganMain, css.firstSlogan)}>
                <div>
                  <h1 className={css.lightText}>Safety is everything</h1>
                  <p className={css.darkTextMaxWidth}>
                    All trips on Drive mate are comprehensively insured up to $35 million and covers vehicle damage due to accident, fire or storm, or malicious damage and theft of vehicle
                  </p>
                </div>
                {/* <img src={safeFirst} className={css.safeFirstImage}/> */}
              </div>
            </div>
          </div>

          <div className={css.backgroundDark}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h2 className={css.yellowText}>When you host</h2>
                <p className={css.whiteTextMobile}>
                  With round-the-clock access to our customer service team, no other Australian car share concept matches our level of support and dependability.
                </p>
                <p className={css.whiteText}>
                  We’ll ensure you’re always linked up with locals looking for cars, protected by a top-tier policy and most importantly, making sweet moolah!
                </p>
                <p className={css.whiteText}>
                  $35M Third Party Liability Coverage
                </p>
                <div className={css.whenYouHostContainer}>
                  <div className={css.whenYouHostItem}>
                    <div className={css.contentImage}>
                      <img className={css.whenYouHostImage} src={verifyImage} />
                    </div>
                    <div className={css.contentDescription}>
                      <p className={css.whenYouHostItemText}>Worry-free comprehensive cover</p>
                    </div>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <div className={css.contentImage}>
                      <img className={css.whenYouHostImage} src={theftImage} />
                    </div>
                    <div className={css.contentDescription}>
                      <p className={css.whenYouHostItemText}>Security deposit for incidentals and excess</p>
                    </div>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <div className={css.contentImage}>
                      <img className={css.whenYouHostImage} src={moneyImage} />
                    </div>
                    <div className={css.contentDescription}>
                      <p className={css.whenYouHostItemText}>Security of car tracking &amp; remote immobilisation</p>
                    </div>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <div className={css.contentImage}>
                      <img className={css.whenYouHostImage} src={ncdImage} />
                    </div>
                    <div className={css.contentDescription}>
                      <p className={css.whenYouHostItemText}>No impact on your current insurance and no-claim bonus</p>
                    </div>
                  </div>

                  <div className={css.whenYouHostItem}>
                    <div className={css.contentImage}>
                      <img className={css.whenYouHostImage} src={damage2} />
                    </div>
                    <div className={css.contentDescription}>
                      <p className={css.whenYouHostItemText}>Covers vehicle damage, theft & 3<sup>rd</sup> party loss</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.backgroundLight}>
            <div className={css.sloganWrapper}>
              <div className={css.sloganMain}>
                <h3 className={css.yellowText}>When you drive</h3>
                <div className={css.whenYouDriveContainer}>
                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={safeFirst}
                      title={<span>Comprehensively insured</span>}
                      content={<span>Drive mate protection provides a comprehensive insurance cover for all Drive mate trips. <br /></span>}
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={crashCarImage}
                      title={<span>$35 million cover​</span>}
                      content={"Your rental price includes cover up to $35M for third party damage​"}
                    />
                  </div>
                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={truckCarImage}
                      title={<span>We’ll meet you roadside, 24/7!</span>}
                      content={"Help is only a call away. Wherever you are when a breakdown occurs, Drive mate’s support team is always there to assist. ​"}
                    />
                  </div>

                  {/* <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={copyCarImage}
                      title={<span>No-claim bonus protection.</span>}
                      content={"Because Insurance’s insurance works as an additional layer on top of your existing policy, your no-claim bonus will not be impacted in any case."}
                    />
                  </div>

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={loopHandImage}
                      title={<span>A community that cares.</span>}
                      content={"We’ll screen everyone driving your car. Guests are both verified prior to pick-up and reviewed by peers on return. Trust and accountability ultimately strengthens our network!"}
                    />
                  </div> */}

                  <div className={css.whenYouDriveItem}>
                    <WhenYouDriveItem
                      imageSource={liftCarImage}
                      title={<span>Backed by local business.</span>}
                      content={"We work in close collaboration with a variety of mechanics, auto-electricians and a range of other automotive services while proudly supporting local industry."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.backgroundWhite}>
            <div className={css.sloganWrapper}>
              <div className={classNames(css.sloganMain, css.someTips)}>
                <h4 className={css.darkText}>Getting the most out of Drive mate – Handy Hints for the Best Experience</h4>
                <div className={css.tipsAndTricksContainer}>
                  <div className={css.tipsAndTricksItem}>
                    <span>Scope-out your guests</span>
                    <p>Take a few minutes to learn a little about your guest prior to meeting them.​</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>Speak up</span>
                    <p>Feedback from both ends helps our platform (and ultimately you), remain safe and successful. ​</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>Prioritise your privacy</span>
                    <p>We’ll only ever share your personal contact details with guests who’ve confirmed bookings with a strong recommendation to keep things this way. ​</p>
                  </div>

                  <div className={css.tipsAndTricksItem}>
                    <span>Respect travels a long way</span>
                    <p>Treating each other courteously enhances the Drive mate experience for both guests and hosts.​</p>
                  </div>
                  <div className={css.divider}></div>
                </div>
              </div>
            </div>
          </div>

          {/* <InsuranceDisclaimer/> */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default TrustAndSafetyPage;
