import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { SectionHeroSearchBox } from '../../components';
import FormCampaign from '../SectionHeroSearchBox/FormCampaign';

import css from './SectionHero.css';

const CampaignBox = props => {
  const {
    intl,
    isCustomer,
    applyError,
    applyInProgress,
    applySuccess,
    onApplyCampaign,
  } = props;

  const handleSubmit = (data) => {
    const { email } = data;
    const userType = isCustomer ? 'guest' : 'host';
    onApplyCampaign(email, userType);
  };

  return (
    <FormCampaign
      onSubmit={handleSubmit}
      titleId={intl.formatMessage({
        id: isCustomer ? 'FormCampaign.titleCustomer' : 'FormCampaign.titleProvider',
      })}
      subtitleId={isCustomer ? 'FormCampaign.subtitleCustomer' : 'FormCampaign.subtitleProvider'}
      applyError={applyError}
      applyInProgress={applyInProgress}
      applySuccess={applySuccess}
    />
  );
};

const MAX_MOBILE_SCREEN_WIDTH = 768;

class SectionHero extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rootClassName,
      className,
      viewport,
      currentUser,
      isAuthenticated,
      isMain,
      isHost,
      isGuest,
      intl,
      onApplyCampaign,
      applyError,
      applyInProgress,
      applySuccess,
    } = this.props;


    const classes = classNames(rootClassName || css.root, className);
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const desktopLayout = (
      <div className={classes}>
        <div className={css.heroContent}>
          {
            isMain && (
              <SectionHeroSearchBox
                currentUser={currentUser}
                className={css.form}
                onSubmit={() => {
                }}
                inProgress={false}
                titleId="SectionHeroSearchBox.titleLineTemp"
                isAuthenticated={isAuthenticated}
              />
            )
          }
          {
            (isHost || isGuest) && (
              <CampaignBox
                intl={intl}
                isCustomer={isGuest}
                onApplyCampaign={onApplyCampaign}
                applyError={applyError}
                applyInProgress={applyInProgress}
                applySuccess={applySuccess}
              />
            )
          }

        </div>
        <div className={css.moreinfo}>
          <div className={css.questionMark}>
            ?
          </div>
          <div className={css.moreinfoText}>
            <FormattedMessage id="SectionHero.needHelp"/>
          </div>
        </div>
      </div>
    );

    const mobileLayout = (
      <div className={css.rootWrapper}>
        <div className={classes}>
          <div className={css.heroContent}>
          </div>
        </div>
        {
          isMain && (
            <SectionHeroSearchBox
              currentUser={currentUser}
              className={css.form}
              onSubmit={() => {
              }}
              inProgress={false}
              titleId="SectionHeroSearchBox.titleLineMobileTemp"
              isAuthenticated={isAuthenticated}
            />
          )
        }
        {
          isHost && (
            <CampaignBox
              intl={intl}
              isCustomer={false}
              onApplyCampaign={onApplyCampaign}
              applyError={applyError}
              applyInProgress={applyInProgress}
              applySuccess={applySuccess}
            />
          )
        }
        {
          (!isHost && !isMain) && (
            <CampaignBox
              intl={intl}
              isCustomer={true}
              onApplyCampaign={onApplyCampaign}
              applyError={applyError}
              applyInProgress={applyInProgress}
              applySuccess={applySuccess}
            />
          )
        }
      </div>
    );

    return isMobileLayout ? mobileLayout : desktopLayout;
  }
}

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  applyError: string,
  applyInProgress: bool,
  onApplyCampaign: func,
  applySuccess: bool,
};

export default injectIntl(SectionHero);
