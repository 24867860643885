import React from 'react';

function NoAvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path
        id="user-circle-solid"
        d="M16,8A16,16,0,1,0,32,24,16,16,0,0,0,16,8Zm0,6.194a5.677,5.677,0,1,1-5.677,5.677A5.678,5.678,0,0,1,16,14.194Zm0,22.194a12.363,12.363,0,0,1-9.452-4.4A7.193,7.193,0,0,1,12.9,28.129a1.579,1.579,0,0,1,.458.071A8.542,8.542,0,0,0,16,28.645a8.51,8.51,0,0,0,2.639-.445,1.579,1.579,0,0,1,.458-.071,7.193,7.193,0,0,1,6.355,3.858A12.363,12.363,0,0,1,16,36.387Z"
        transform="translate(0 -8)"
        fill="#fff"
      />
    </svg>
  );
}

export default NoAvatarIcon;
