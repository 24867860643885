import { REQUEST_STATUS__NULL } from '../../constants/other';

const initialDates = {
  start: null,
  end: null,
};

export const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextLongTermTransaction: null,
  childLongTermTransactions: [],
  currentChildLongTermTransaction: null,
  transaction: null,
  estimatedTx: null,
  estimatedTxError: null,
  estimatedTxRequestStatus: REQUEST_STATUS__NULL,
  updateBookingInitiateRequestStatus: REQUEST_STATUS__NULL,
  updateBookingInitiateError: null,
  acceptUpdateBookingRequestStatus: REQUEST_STATUS__NULL,
  acceptUpdateBookingError: null,
  cancelUpdateBookingRequestStatus: REQUEST_STATUS__NULL,
  cancelUpdateBookingError: null,
  cancelAllUpdateBookingTxsRequestStatus: REQUEST_STATUS__NULL,
  cancelAllUpdateBookingTxsError: null,
  fetchUpdateBookingTxsRequestStatus: REQUEST_STATUS__NULL,
  fetchLastUpdateBookingTxRequestStatus: REQUEST_STATUS__NULL,
  tripDates: initialDates,
  updateBookingDates: initialDates,
};
