import React from 'react';
import classNames from 'classnames';
import Loading from '../../containers/CheckEligibilityPage/Loading';

import css from './LoadingScreen.css';


const LoadingScreenComponent = (props) => {
  const { className, rootClassName, msg } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.inner}>
        <Loading rootClassName={css.loadingImg}/>
        <div className={css.loadingMsg}>
          {msg}
        </div>
      </div>
    </div>
  )
}

export default LoadingScreenComponent;
