import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

export const FETCH_INSTANT_BOOKING_REQUEST = 'FETCH_INSTANT_BOOKING_REQUEST';
export const FETCH_INSTANT_BOOKING_SUCCESS = 'FETCH_INSTANT_BOOKING_SUCCESS';
export const FETCH_INSTANT_BOOKING_ERROR = 'FETCH_INSTANT_BOOKING_ERROR';

const initialState = {
  listingRefs: [],
  fetchInProgress: false,
  fetchError: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INSTANT_BOOKING_REQUEST:
      return {
        ...state, fetchInProgress: true
      }
    case FETCH_INSTANT_BOOKING_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        listingRefs: payload.data.data.map(({ id, type }) => ({ id, type })),
      }
    case FETCH_INSTANT_BOOKING_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        error: payload
      }
  default:
    return state
  }
}

const fetchInstantBookingRequest = () => ({ type: FETCH_INSTANT_BOOKING_REQUEST });
const fetchInstantBookingSuccess = data => ({ type: FETCH_INSTANT_BOOKING_SUCCESS, payload: data });
const fetchInstantBookingError = error => ({ type: FETCH_INSTANT_BOOKING_ERROR, payload: error });

const fetchPageReviews = (sdk, option) => page => {
  option.page = page;
  return sdk.reviews
    .query(option)
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      return reviews;
    })
};

const fetchReviews = (listingId, sdk) => {
  const option = {
    listing_id: listingId,
    state: 'public',
    include: ['author', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };
  return sdk.reviews
    .query(option)
    .then(response => {
      const { meta } = response.data;
      const { totalPages } = meta;
      const haveMoreReviews = totalPages > 1;
      if (haveMoreReviews) {
        const firstReviews = denormalisedResponseEntities(response);
        const prebuildReviewRequest = fetchPageReviews(sdk, option);
        const requestReviews = [];
        for (let i = 2; i <= totalPages; i++) {
          requestReviews.push(prebuildReviewRequest(i));
        }
        return Promise.all(requestReviews)
          .then(reviewsArr =>
            reviewsArr.reduce((result, currentReviews) =>
              result.concat(currentReviews), [])
          )
          .then(remainingReviews => firstReviews.concat(remainingReviews));
      } else {
        return denormalisedResponseEntities(response);
      }
    })
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(fetchInstantBookingRequest());
  const bounds = {
    _sdkType: "LatLngBounds",
    ne: {
      _sdkType: "LatLng",
      lat: 1.4708809,
      lng: 104.04157989999999,
    },
    sw: {
      _sdkType: "LatLng",
      lat: 1.216611,
      lng: 103.60650989999999
    }
  }

  const center = {
    lat: (bounds.ne.lat + bounds.sw.lat) / 2,
    lng: (bounds.ne.lng + bounds.sw.lng) / 2,
  };
  let response = null;

  return sdk.listings.query({
    origin: `${center.lat},${center.lng}`,
    pub_instantBooking: true,
    page: 1,
    per_page: 4,
    include: ['author', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
    pub_isDeposit: false,
    meta_live: true,
  }).then(res => {
    response = res;
    // dispatch(addMarketplaceEntities(response));
    // dispatch(fetchInstantBookingSuccess(response));
    const listings = denormalisedResponseEntities(res);
    const promiseToFetchReviews = listings.map(l => fetchReviews(l.id, sdk));
    return Promise.all(promiseToFetchReviews)
  }).then(reviews => {
    reviews.forEach((reviewsOfListing, i) => {
      response.data.data[i].attributes.averageReview = reviewsOfListing.length > 0
        ? (reviewsOfListing.reduce((total, currentReview) => {
          return total + currentReview.attributes.rating;
        }, 0) / reviewsOfListing.length).toFixed(2)
        : 'N/A';
    });
    // update the reviews value to the search page
    return response;
  }).then(res => {
    dispatch(addMarketplaceEntities(res));
    dispatch(fetchInstantBookingSuccess(res));
    return res;
  })
    .catch(e => {
    dispatch(fetchInstantBookingError(storableError(e)));
  })
}
