import React, { useEffect, useRef, useState } from 'react';
import styles from './VerificationSteps.module.css'
import Stepper from '../Stepper/Stepper';
import dynamic from 'next/dynamic';
import { types as sdkTypes } from '../../util/sdkLoader';
import { AddressDetailsForm, PhoneNumberForm } from '../../forms';
import { ensureCurrentUser } from '../../util/data';
import axios from 'axios';
import { get } from 'lodash';
import CardVerificationPage from '../../containers/CardVerificationPage/CardVerificationPage';
import { useDispatch, useSelector } from 'react-redux';
import { saveAddressDetails } from '../UserPictureIDVerification/AddressDetailsPage.duck';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS } from '../../util/conversions/conversionsConstants';
import { saveContactDetails } from '../UserPictureIDVerification/PhoneNumber.duck';

const { LatLng } = sdkTypes;

const VerificationSteps = ({ currentStep = 0, currentUser, transaction, transactionId, nextInteractableTransitions, haveNextTransit, onTransit, isHost, onUpdateUserData, isCardVerified, settingSteps }) => {

  const props = { currentStep, currentUser, transaction, transactionId, nextInteractableTransitions, haveNextTransit, onTransit, isHost, onUpdateUserData, isCardVerified, settingSteps }

  const dispatch = useDispatch();
  const {savePhoneNumberError} = useSelector(state => state.ContactDetailsPage);

  const formSubmitRef = useRef(null);
  const phoneFormSubmitRef = useRef(null);
  const cardVerificationRef = useRef(null);
  const contactDetailsFormSubmitRef = useRef(null);

  const transactionData = {
    transaction,
    transactionId,
    nextInteractableTransitions,
    haveNextTransit,
    onTransit,
    isHost
  }

  const modalReference = useRef(null);
  const [onfidoInitToken, setOnfidoInitToken] = useState(null);
  const [selected, setSelected] = useState('');

  const _createOnfidoCheck = async () => {
    const applicant_id = OnfidoUserID;
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/onfidoCheck/${applicant_id}`)
        .then((response) => {

          if (response.data.status === 'in_progress') {
            const publicData = isHost ? {
              hostIdentityVerificationStatus: 'pending',
              hostVerificationSent: new Date().toISOString(),
              onFidoCheckId: response.data.id
            } : {
              onFidoCheckId: response.data.id
            }
            onUpdateUserData({
              protectedData: {
                onfidoDocIds: response.data.report_ids,
              },
              publicData
            }).then(() => {
              // if (isCardVerified) {
              //   window.location.reload();
              // }
              // settingSteps(currentStep => currentStep += 0);
            });
            OnfidoUserID = '';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }


  }

  let initializeOnfido = null
  const DynamicHeader = dynamic(() => import('../UserPictureIDVerification/dynamicOnfido').then(comp => {
    initializeOnfido = comp;
  }), {
  })

  let token;
  let OnfidoUserID;
  const _handleInitiateVerification = async () => {
    const userId = await get(currentUser, 'id.uuid');
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/${userId}`)
        .then(async (response) => {

          if (response && response.data.data.token) {
            setOnfidoInitToken(response.data.data.token);
            token = response.data.data.token;

            OnfidoUserID = response.data.OnfidoUserID
            initializeOnfido.initializeOnfido(token, currentUser, modalReference, _createOnfidoCheck, transactionData, onTransit);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }

  };

  
  const user = ensureCurrentUser(currentUser);
  const protectedData = user.attributes.profile.protectedData || {};
  const { location, unitNumber, city, state, postalCode, streetNumber, suburb, dateOfBirth } = protectedData;
  const { selectedPlace } = location || {};
  const { address, origin } = selectedPlace || {};
  const currentPhoneNumber = protectedData.phoneObj ? protectedData.phoneObj.rawNumber : '';
  const currentPhoneCode = protectedData.phoneObj ? protectedData.phoneObj.phoneCode : (selected || '');

  const phoneInitialValues = {
    phoneNumber: currentPhoneNumber,
    phoneCode: currentPhoneCode
  }

  const initialValues = {
    dateOfBirth,
    location: {
      search: address,
      selectedPlace: {
        address,
        streetNumber,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    unitNumber,
    streetNumber,
    suburb,
    city,
    state,
    postalCode,
  };

  const onSubmitAddress = () => {
    console.log("I am address submmit START");
    
    if (phoneFormSubmitRef.current) {
      console.log('I enter in ifPhone Form SUBMIT REF >>>');
      phoneFormSubmitRef.current();
    }

    if (formSubmitRef.current) {
      console.log('I enter in if formSubmitRef');
      formSubmitRef.current();
    }

    console.log("I am address submmit END");
  }

  const onSubmitOnfido = () => _handleInitiateVerification();

  const onSubmitCreditCard = () => {
    console.log("I am credit card submmit");
    if (cardVerificationRef.current) {
      cardVerificationRef.current.triggerSubmit();
    }
    settingSteps(currentStep => 0);
  }

  const steps = [
    {
      title: 'Personal Details',
      btnName: 'Save & continue',
      onBtnClick: onSubmitAddress,
      content:
        <>
        <div style={{marginBottom: "1%"}}>Please provide the following details.</div>
          <PhoneNumberForm
            // className={css.form}
            initialValues={{ phoneNumber: currentPhoneNumber, phoneCode: currentPhoneCode }}
            setSubmitRef={phoneFormSubmitRef}
            savePhoneNumberError={savePhoneNumberError}
            currentUser={currentUser}
            onSubmit={values => {
              console.log('I am save phone number details >>>');
              console.log('I am VALUES >>', values);
              dispatch(saveContactDetails({ ...values, currentPhoneNumber, currentPhoneCode }));
              // await setverifyIdVisible(true)
              // await setphoneVisible(false)
              // await setaddressVisible(true)
            }}
            // onChange={onChange}
          // inProgress={saveContactDetailsInProgress}
          // ready={contactDetailsChanged}
          // modifyStep={true}
          // handleSteps={handleSteps}
          // step={1}
          />
          {savePhoneNumberError && (
            <p className={styles.phoneNumberError}>Please enter correct phone number.</p>
          )}

          <AddressDetailsForm
            currentUser={currentUser}
            isVerificationPage={true}
            initialValues={initialValues}
            setSubmitRef={formSubmitRef}
            contactDetailsFormSubmitRef={contactDetailsFormSubmitRef}
            dispatchingContactForm={values => dispatch(saveContactDetails(values))}
            onSubmit={values => {
              const { location, unitNumber, city, state, postalCode, streetNumber, suburb, dateOfBirth } = values;
              const {
                selectedPlace: { address, origin },
              } = location;
              const updatedValues = {
                dateOfBirth,
                location: {
                  search: address,
                  selectedPlace: {
                    address,
                    streetNumber,
                    origin: new LatLng(origin.lat, origin.lng),
                  },
                },
                unitNumber,
                streetNumber,
                suburb,
                state,
                city,
                postalCode,
              };
              dispatch(saveAddressDetails(updatedValues));
              const properties = createRawPropertiesForGTM({
                props,
              });
              createConversionEvents(
                properties,
                EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
                'click'
              );
              // settingSteps(currentStep => currentStep + 1);
            }} />
        </>
    },
    {
      title: 'Identity Verification',
      btnName: 'Start Identity Verification',
      onBtnClick: onSubmitOnfido,
      content:
        <>
        <div style={{marginBottom: "1%"}}>You will be asked for your Driver license, so please keep that handy.</div>
          <DynamicHeader />
          <br></br>
          <div ref={modalReference} style={{ width: 200 }}>
            {' '}
          </div>
        </>,
    },
    {
      title: 'Credit Verification',
      btnName: 'Save Credit Details',
      onBtnClick: onSubmitCreditCard,
      content:
      <>
      <div style={{marginBottom: "1%"}}>You need to add your preferred payment method so we can do credit verification.</div>
      <CardVerificationPage
        currentUser={currentUser}
        ref={cardVerificationRef}
      />
      </> 
    },
  ];

  // Removing the last step for time being, in the case of host verification.
  if (isHost) {
    steps.pop();
  }

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={styles.currentStepHeader}>
          <p className={styles.currentStepName}>{steps[currentStep].title}</p>
          <p className={styles.currentStepIndicator}>
            <Stepper steps={steps} currentStep={currentStep} />
          </p>
        </div>
        <hr className={styles.separatorHR} />
        <div className={styles.currentStepContent}>
          {steps[currentStep].content}
        </div>
        {currentStep !== 2 && (          
        <div className={styles.buttonContainer} onClick={steps[currentStep].onBtnClick}>
          <button>{steps[currentStep].btnName}</button>
        </div>
        )}
      </div>
    </>
  );
};

export default VerificationSteps;