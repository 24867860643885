import { get } from 'lodash';

// ================ Action types ================ //

export const SET_USER_LISTINGS_STATUS = 'app/user/SET_USER_LISTINGS_STATUS';

// ================ Initial State ================ //

const initialState = {
  hasPublishedOrPendingApprovalListings: false,
};

// ================ Reducer ================ //

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_LISTINGS_STATUS:
      return { ...state, hasPublishedOrPendingApprovalListings: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const $hasPublishedOrPendingApprovalListings = state => {
  return state.BYODHasExistingListing.hasPublishedOrPendingApprovalListings;
};

// ================ Action creators ================ //

export const setUserListingsStatus = (status) => ({
  type: SET_USER_LISTINGS_STATUS,
  payload: status,
});

// ================ Thunks ================ //

export const checkUserListingsStatus = (isAuthenticated) => async (dispatch, state, sdk) => {
  if (!isAuthenticated) {
    return;
  }
  try {
    const liveListingsRes = await sdk.ownListings.query();

    const liveListings = liveListingsRes.data.data.filter(
      listing => 
        get(listing, 'attributes.state') === 'published' ||
        get(listing, 'attributes.state') === 'pendingApproval'
    );
    
    const hasListings = liveListings.length > 0;
    dispatch(setUserListingsStatus(hasListings));
  } catch (error) {
    console.error('Error checking user listings status:', error);
    // Optionally handle the error
  }
};
