import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
} from '../../util/transaction';

import css from './TripPanel.css';

const VALID_SHOW_KEY_TRANSITIONS = [
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
 TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
TRANSITION_REQUEST_DROP_OFF,
ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE
]

const isShowKeySectionVisible = (lastTransition , isDrivelahGo) => {
  if (isDrivelahGo && lastTransition){
    return VALID_SHOW_KEY_TRANSITIONS.includes(lastTransition)
  }
  return false;
}

const InfoSection = ({
  listingRegistrationNumber,
  fuelType,
  selectedOptions,
  isMobileLayout,
  showAddress,
  listing,
  lastTransition,
  isDrivelahGo,
}) => {
  const [isReadMore, setIsReadMore] = useState(null);
  let index = 0;

  const rules = listing.attributes.publicData && listing.attributes.publicData.rules ? listing.attributes.publicData.rules : false;
  const keyRules = listing.attributes.publicData && listing.attributes.publicData.keyRules ? listing.attributes.publicData.keyRules : false;
  const carBrand = listing.attributes.publicData.brandName || '';
  const carModel = listing.attributes.publicData.modelName || '';
  let millagePerDay = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.millagePerDay;

   const fuelRulesConfig = config.custom.typeOfFuel
    let fuelRecord = fuelRulesConfig.find((fuel) => {
      return fuel.key === fuelType
    })
    const fuelValue = fuelRecord ?fuelRecord .label : fuelType

  const listCardInfo = lastTransition === TRANSITION_ENQUIRE ?  [
    {
      id: 1,
      title: <FormattedMessage id="TransactionPanel.carInfo" />,
      bodyShow: (
        <div className={css.bodyTextContainer}>
          <p className={css.infotext}>
            <FormattedMessage
              id="TransactionPanel.registration"
            />
            {listing.attributes.publicData.license_plate_number}
          </p>
          <p className={css.infotext}>
            <FormattedMessage id="TransactionPanel.fuel"  />
            {fuelValue}
          </p>
        </div>
      ),
      readMore: false,
      show: showAddress,
    },
  ] : [
    {
      id: 1,
      title: <FormattedMessage id="TransactionPanel.carInfo" />,
      bodyShow: (
        <div className={css.bodyTextContainer}>
          <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.about" />
                  {carBrand + ' ' + carModel}
          </p>
          <p className={css.infotext}>
            <FormattedMessage
              id="TransactionPanel.registration"
            />
            {listing.attributes.publicData.license_plate_number}
          </p>
          <p className={css.infotext}>
            <FormattedMessage id="TransactionPanel.fuel"  />
            {fuelValue}
          </p>
          <p className={css.infotext}>
            <FormattedMessage id="TransactionPanel.mileage" />
            {millagePerDay + " kms/day"}
          </p>
        </div>
      ),
      readMore: false,
      show: showAddress,
    },
    {
      id: 2,
      title: <FormattedMessage id="TransactionPanel.pickupInstruction" />,
      bodyShow: (
        <div className={css.bodyTextContainer}>
          <p className={css.infotext}>
            {rules}
          </p>
        </div>
      ),
      bodyHide: (
        <p className={css.infotext}>
          {rules}
        </p>
      ),
      readMore: false,
      show: rules,
    },
    {
      id: 3,
      title: <FormattedMessage id="TransactionPanel.keyInformation" />,
      bodyShow: (
        <div className={css.bodyTextContainer}>
          {keyRules.length > 0 &&
            keyRules.map((rule, index) => {
              return  <div className={css.infotext} key={rule}>
                <span className={css.index}>{index + 1}.&nbsp;</span>
                {rule}
              </div>
            })}
        </div>
      ),
      readMore: false,
      show: keyRules && keyRules.length > 0,
    },
    {
      id: 4,
      title: <FormattedMessage id="TransactionPanel.keyLocationTitle" />,
      bodyShow: (
        <div className={css.bodyTextContainer}>
            <ul className={css.infotext} className={css.keyLocationList}>
                <li key="dlGo"><FormattedMessage id="TransactionPanel.keyLocationDescriptionForDlGo" /></li>
                <li key="nonDlGo"><FormattedMessage id="TransactionPanel.keyLocationDescriptionForNonDlGo" /></li>
              </ul>
        </div>
      ),
      readMore: false,
      show: isShowKeySectionVisible(lastTransition , isDrivelahGo)
    }
  ];

  return (
    <div className={css.infoSectionContainer}>
      {listCardInfo.map(i => {
        return i.show ? (
          <div key={i.id} className={css.infoCardContainer}>
            <h4 className={css.infoTitle}>{i.title}</h4>
            {isReadMore === i.id ? i.bodyHide : i.bodyShow}
            {i.readMore ? (
              <p className={css.readMore} onClick={() => setIsReadMore(isReadMore ? null : i.id)}>
                {isReadMore === i.id ? (
                  <FormattedMessage id="TransactionPanel.readLess" />
                ) : (
                  <FormattedMessage id="TransactionPanel.readMore" />
                )}
              </p>
            ) : null}
          </div>
        ) : null
      })}
    </div>
  );
};

export default InfoSection;
