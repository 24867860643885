import React, { useEffect, useState } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import css from './FieldBirthdayInput.css';
import {
  fromDateOfBirth,
  onFocusNext,
  onValidDateFromSelected,
  toDateOfBirth,
  toNumber,
} from '../../util/helpers';
import { DOB_MOBILE_KEYS, INITIAL_DOB, INPUT_TYPE } from '../../util/constants';

const pad = num => {
  if (num >= 0 && num < 10) {
    return `0${num}`;
  }
  return num.toString();
};

const BLUR_TIMEOUT = 100;

const BirthdayInputMobileComponent = props => {
  const [dob, setDateOfBirth] = useState(INITIAL_DOB);
  const [selected, setSelected] = useState({});
  let blurTimeoutId = null;

  const {
    dateLabel,
    initialBirthDate,
    required,
  } = props;

  useEffect(() => {
    if (selected && Object.keys(selected).length) {
      props.onChange(onValidDateFromSelected(selected));
    }
  }, [selected]);

  useEffect(() => {
    if (dob) {
      setSelected(toDateOfBirth(dob))
    }
  }, [dob]);

  useEffect(() => {
    if (!Object.keys(selected || {}).length && !selected.month && !selected.year && initialBirthDate) {
      setSelected({
        day: initialBirthDate.day,
        month: initialBirthDate.month,
        year: initialBirthDate.year,
      });
      setDateOfBirth(fromDateOfBirth({
        day: initialBirthDate.day,
        month: initialBirthDate.month,
        year: initialBirthDate.year,
      }));
    }
  }, [initialBirthDate]);

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    const isNumber = toNumber(value);
    if (value && !isNumber && isNumber !== 0) {
      return;
    }

    if (name === DOB_MOBILE_KEYS.day_1 && toNumber(value) >= 4) {
      props.onBlur(); // THIS WILL SHOW THE ERROR MESSAGE
      return;
    }

    if (name === DOB_MOBILE_KEYS.month_1 && toNumber(value) >= 2) {
      props.onBlur(); // THIS WILL SHOW THE ERROR MESSAGE
      return;
    }

    onFocusNext(event.target);
    const input = { [name]: value || '' };
    setDateOfBirth({
      ...dob,
      ...input
    });
  };

  const handleSelectFocus = () => {
    window.clearTimeout(blurTimeoutId);
    props.onFocus();
  }

  const handleSelectBlur = () => {
    window.clearTimeout(blurTimeoutId);
    blurTimeoutId = window.setTimeout(() => {
      props.onBlur();
    }, BLUR_TIMEOUT);
  }

  // const required = validators.required('This field is required');

  return (
    <div className={css.inputRoot}>
      <div className={css.dobMobileViewContainer}>
        {dateLabel}
        <div className={css.dobContainer}>
          <div className={css.dobGroupContainer}>
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.day_1}
              id={`dob_id_${DOB_MOBILE_KEYS.day_1}`}
              value={dob[DOB_MOBILE_KEYS.day_1]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder={'D'}
              // required={isRequired}
              validate={required}
              className={css.numbers}
              onFocus={() => handleSelectFocus()}
              onBlur={() => handleSelectBlur()}
            />
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.day_2}
              id={`dob_id_${DOB_MOBILE_KEYS.day_2}`}
              value={dob[DOB_MOBILE_KEYS.day_2]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder={'D'}
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
          </div>
          <div className={css.dobGroupContainer}>
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.month_1}
              id={`dob_id_${DOB_MOBILE_KEYS.month_1}`}
              value={dob[DOB_MOBILE_KEYS.month_1]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder={'M'}
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.month_2}
              id={`dob_id_${DOB_MOBILE_KEYS.month_2}`}
              value={dob[DOB_MOBILE_KEYS.month_2]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder={'M'}
              // required={isRequired}
              validate={required}
              className={css.numbers}
            /></div>
          <div className={css.dobGroupContainer}>
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.year_1}
              id={`dob_id_${DOB_MOBILE_KEYS.year_1}`}
              value={dob[DOB_MOBILE_KEYS.year_1]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder="Y"
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.year_2}
              id={`dob_id_${DOB_MOBILE_KEYS.year_2}`}
              value={dob[DOB_MOBILE_KEYS.year_2]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder="Y"
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.year_3}
              id={`dob_id_${DOB_MOBILE_KEYS.year_3}`}
              value={dob[DOB_MOBILE_KEYS.year_3]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder="Y"
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
            <input
              type={INPUT_TYPE}
              name={DOB_MOBILE_KEYS.year_4}
              id={`dob_id_${DOB_MOBILE_KEYS.year_4}`}
              value={dob[DOB_MOBILE_KEYS.year_4]}
              onChange={e => handleSelectChange(e)}
              maxLength={1}
              placeholder="Y"
              // required={isRequired}
              validate={required}
              className={css.numbers}
            />
          </div>
        </div>
      </div>
    </div>
  );

}

BirthdayInputMobileComponent.defaultProps = {
  selectClassName: null,
  dateLabel: null,
  monthLabel: null,
  disabled: false,
};

BirthdayInputMobileComponent.propTypes = {
  selectClassName: string,
  dateId: string.isRequired,
  monthId: string.isRequired,
  yearId: string.isRequired,
  dateLabel: node,
  monthLabel: node,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  disabled: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BirthdayInputMobile = injectIntl(BirthdayInputMobileComponent);

const FieldBirthdayMobileInputComponent = props => {
  const {
    rootClassName,
    className,
    id,
    disabled,
    input,
    meta,
    initialBirthDate,
    externalCursor,
  } = props;
  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  // const hasError = touched && invalid && error;
  const hasError = touched && error && invalid;

  const dateId = id;
  const monthId = `${id}-month`;
  const yearId = `${id}-year`;

  const selectClassName = classNames({
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });

  const inputProps = {
    selectClassName,
    dateId,
    monthId,
    yearId,
    disabled,
    initialBirthDate,
    externalCursor,
    ...input,
  };
  const classes = classNames(rootClassName || css.fieldRoot, className);
  return (
    <div className={classes}>
      <BirthdayInputMobile {...inputProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldBirthdayMobileInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  disabled: false,
};

FieldBirthdayMobileInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  disabled: bool,
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldBirthdayMobileInput = props => {
  return <Field component={FieldBirthdayMobileInputComponent} {...props} />;
};

export default FieldBirthdayMobileInput;