import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';
import css from './VerificationStatusIndication.css';
import Enable from '../../assets/verificationPage/Enable.svg';
import Disable from '../../assets/verificationPage/Disable.svg';
import Card from '../../assets/verificationPage/Card.svg';
import Email from '../../assets/verificationPage/Email.svg';
import Search from '../../assets/verificationPage/Search.svg';
import CurrentLocation from '../../assets/verificationPage/CurrentLocation.svg';
import PhoneNumber from '../../assets/verificationPage/PhoneNumber.svg';
import IdentityVerification from '../../assets/verificationPage/IdentityVerification.svg';
import Calender from '../../assets/verificationPage/Calender.svg';

const VerificationStatusIndication = ({
  currentUser,
  handleContinueVerification,
  phoneNumberVerified,
  isEmailVerified,
  userLocation,
  dateOfBirth,
  isHost,
  isVerified,
  isDeclined,
  isCardVerified,
  isBackgroundCheckConsentAccepted,
  isSubmittedOnfidoVerification,
  isPendingVerification,
  isVerificationInProgress,
  isNotSubmittedVerification
}) => {
  const guidance = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="VerificationStatusIndication.guide" />
      </p>
    </div>
  );
  const thankYouMessagePending = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage
          id="AccountSettingDriverVerificationPage.thankYouPageTitle"
          values={{ name: currentUser && `${currentUser.attributes.profile.firstName}` }}
        />
      </p>
      <p>
        <FormattedMessage id="AccountSettingDriverVerificationPage.thankYouPageDescription" />
      </p>
    </div>
  );

  const thankYouMessageDeclined = (
    <div className={css.verificationIndication}>
      {/* <p>
        <FormattedMessage
          id="AccountSettingDriverVerificationPage.thankYouPageTitle"
          values={{ name: currentUser && `${currentUser.attributes.profile.firstName}` }}
        />
      </p> */}
      <p>
        <FormattedMessage id="AccountSettingDriverVerificationPage.declinePageDescription" />
      </p>
    </div>
  );

  const thankyouMessageVerified = (
    <div className={css.verificationIndication}>
      <p>
        <FormattedMessage id="VerificationStatusIndication.thankyouMessageVerified" />
      </p>
    </div>
  );
  return (
    <div>
      {isVerified ? thankyouMessageVerified : isDeclined ? thankYouMessageDeclined: isPendingVerification ? thankYouMessagePending : guidance}
      {
        !isPendingVerification && !isDeclined && (
          <div className={css.stepsContainer}>
            <div className={css.singleStepContainer}>
              <div className={css.titleIconContainer}>

                <div className={css.stepIconContainer}>
                  <img src={PhoneNumber} alt='Alt text' />
                </div>

                <span className={css.stepTitle}>
                  <FormattedMessage id="VerificationStatusIndication.phoneNumber" />
                </span>
              </div>
              {phoneNumberVerified ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
            </div>
            {!isHost && (
              <div className={css.singleStepContainer}>
                <div className={css.titleIconContainer}>

                  <div className={css.stepIconContainer}>
                    <img src={Email} alt='Alt text' />
                  </div>

                  <span className={css.stepTitle}>
                    <FormattedMessage id="VerificationStatusIndication.emailId" />
                  </span>
                </div>
                {isEmailVerified ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
              </div>
            )}
            <div className={css.singleStepContainer}>
              <div className={css.titleIconContainer}>

                <div className={css.stepIconContainer}>
                  <img src={CurrentLocation} alt='Alt text' />
                </div>

                <span className={css.stepTitle}>
                  <FormattedMessage id="VerificationStatusIndication.address" />
                </span>
              </div>
              {userLocation ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
            </div>
            {!isHost && (
              <div className={css.singleStepContainer}>
                <div className={css.titleIconContainer}>

                  <div className={css.stepIconContainer}>
                    <img src={Calender} alt='Alt text' />
                  </div>
                  <span className={css.stepTitle}>
                    <FormattedMessage id="VerificationStatusIndication.dob" />
                  </span>
                </div>
                {dateOfBirth ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
              </div>
            )}

            <div className={css.singleStepContainer}>
              <div className={css.titleIconContainer}>

                <div className={css.stepIconContainer}>
                  <img src={IdentityVerification} alt='Alt text' />
                </div>
                <span className={css.stepTitle}>
                  <FormattedMessage id="VerificationStatusIndication.identityVerification" />
                </span>
              </div>

              {(isSubmittedOnfidoVerification || isVerified) ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
            </div>

            {!isHost && (
              <div className={css.singleStepContainer}>
                <div className={css.titleIconContainer}>

                  <div className={css.stepIconContainer}>
                    <img src={Search} alt='Alt text' />
                  </div>
                  <span className={css.stepTitle}>
                    <FormattedMessage id="VerificationStatusIndication.backgroundCheckConsent" />
                  </span>
                </div>

                {isBackgroundCheckConsentAccepted ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
              </div>
            )}


            {!isHost && (
              <div className={css.singleStepContainer}>
                <div className={css.titleIconContainer}>

                  <div className={css.stepIconContainer}>
                    <img src={Card} alt='Alt text' />
                  </div>
                  <span className={css.stepTitle}>
                    <FormattedMessage id="VerificationStatusIndication.creditVerification" />
                  </span>
                </div>

                {isCardVerified ? <img src={Enable} className={css.badgeposition} alt='Alt text' /> : <img src={Disable} className={css.badgeposition} alt='Alt text' />}
              </div>
            )}

            {(isVerificationInProgress && !isNotSubmittedVerification) ? (
              <div className={css.continueVerificationButton}>
                <PrimaryButton type="button" onClick={handleContinueVerification}>
                  <FormattedMessage id="VerificationStatusIndication.continueButton" />
                </PrimaryButton>
              </div>
            ) : null}

            {(isNotSubmittedVerification) ? (
              <div className={css.continueVerificationButton}>
                <PrimaryButton type="button" onClick={handleContinueVerification}>
                  <FormattedMessage id="VerificationStatusIndication.startButton" />
                </PrimaryButton>
              </div>
            ) : null}
          </div>
        )}
    </div>
  );
};

export default VerificationStatusIndication;
