import React from 'react'
import css from "../EditListingInsurancePanel.css"
import gtInsuranceImg from '../../../assets/insurance-panel/gt-logo.png';

const infoItems = [
    { number: '1', description: '2000 kms/year (outside of trips).' },
    { number: '2', description: 'Works similar to traditional insurance policy: $1000 excess for hosts, claiming impacts premium, tiered vehicle specific parameters, outside of trip.' },
    { number: '3', description: 'Reduced DM commission from 25% to 15%.' }
  ];
  
const InfoItem = ({ number, description }) => (

    <div className={css.infoItem}>
        <p className={css.numberText}>{number}.</p>
        <div>
        <p className={css.descriptionText}>{description}</p>
        </div>
    </div>
  );

export const BblKeyComponent = () => {
    return(
        <>
            <h1 className={`${css.title} ${css.boderBottom}`}>BBI Key highlights</h1>
            <div className={css.infoList}>
                {infoItems.map(item => (
                    <InfoItem key={item.number} number={item.number} description={item.description} />
                ))}
            </div>
            
            <div className={css.contentWrapper}>
                <div>
                    <p className={css.headingText}>Designed by</p>
                    <div className={css.modalLogoWrapper}>
                        <img src={gtInsuranceImg} alt='GT Insurance Logo' />
                    </div>           
                </div>
                <div className={css.planWrapper}>
                    <p className={css.planHeading}>Plan limitations</p>
                    <div className={css.infoItem}>
                        <p className={css.numberText}>1.</p>
                        <div>
                        <p className={css.descriptionText}>Minimum availability of 70% on Drive mate, exclusivity on DM platform.</p>
                        </div>
                    </div>
                    <div className={css.infoItem}>
                        <p className={css.numberText}>2.</p>
                        <div>
                        <p className={css.descriptionText}>Won’t be available for lockbox cars.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

