import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingInsurancePanel,
  EditListingLocationPanel,
  EditListingNextLevelPanel,
  EditListingOnboardingCallPanel,
  EditListingPayoutsPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  EditListingSubscriptionPanel,
  EditListingTrackingDevicePanel,
  EditListingVerificationPanel,
} from '../../components';
import {
  EVENT_LISTACAR_CREATED_DRAFT,
  EVENT_LISTACAR_SELECTED_INSTANTBOOK,
  EVENT_LISTACAR_UPDATED_AVAILABILITY,
  HOST_VERIFY_PUBLISH_BUTTON_ID,
  LISTACAR_ABOUT_BUTTON_ID,
  LISTACAR_AVAILABILITY_BUTTON_ID,
  LISTACAR_CAR_RULES_BUTTON_ID,
  LISTACAR_FEATURES_BUTTON_ID,
  LISTACAR_INSURANCE_BUTTON_ID,
  LISTACAR_LOCATION_BUTTON_ID,
  LISTACAR_NEXT_LEVEL_BUTTON_ID,
  LISTACAR_ONBOARDING_CALL_BUTTON_ID,
  LISTACAR_PAYOUTS_BUTTON_ID,
  LISTACAR_PICTURES_BUTTON_ID,
  LISTACAR_PRICING_BUTTON_ID,
  LISTACAR_SUBSCRIPTION_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateListACarEventFromTab, pushGTMHostVerify } from '../../util/gtm/gtmHelpers';
import { LISTING_WAITING_TO_BE_VERIFIED, sendEventGeneral } from '../../util/emailNotify';
import EditListingDevicePanel from "../EditListingDevicePanel/EditListingDevicePanel"
import css from './EditListingWizard.css';
import { get } from 'lodash';
import EditListingInstructionsPanel from '../EditListingInstructionsPanel/EditListingInstructionsPanel';

export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const NEXT_LEVEL = 'nextLevel';
export const ONBOARDING_CALL = "onboarding-call";
export const TRACKING_DEVICE = 'tracking-device';
export const SUBSCRIPTION = 'subscription';
export const DEVICE = 'device';
export const INSTRUCTIONS = 'instructions'


export const INSURANCE = 'insurance';
export const VERIFICATION = 'verification';
export const PAYOUTS = 'payouts'
// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  AVAILABILITY,
  ONBOARDING_CALL,
  SUBSCRIPTION,
  DEVICE,
  PHOTOS,
  INSURANCE,
  NEXT_LEVEL,
  VERIFICATION,
  TRACKING_DEVICE,
  PAYOUTS
];

let haveSentPublish = false;

const pathParamsToNextTab = (params, tab, marketplaceTabs, hideDeviceTab = false) => {

  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];

  console.log("next tab", tab, nextTab, hideDeviceTab)
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history, hideDeviceTab = false) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs, hideDeviceTab);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    availability,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    updatedTab,
    updateInProgress,
    intl,
    initialImageUrls,
    showListingInProgress,
    onManageDisableScrolling,
    timeZone,
    showPayoutDetails,
    stripeProps,
    updateShowDeviceTab,
    currentUser,
    hideDeviceTab
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const pushHostVerifyEventGTM = (buttonId, event) => {
    pushGTMHostVerify({
      props,
      event,
      buttonId,
    });
  };
  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      if (tab === NEXT_LEVEL) {
        try {
          sendEventGeneral({ listingUUID: currentListing.id.uuid, eventType: LISTING_WAITING_TO_BE_VERIFIED });
          console.log('Event Send!');
        } catch (_error_) {
          console.error('Error while sending event:', _error_);
        }
      }

      onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (isNewURI) {
            initiateListACarEventFromTab({
              props,
              eventButtonParam: [EVENT_LISTACAR_CREATED_DRAFT, LISTACAR_LOCATION_BUTTON_ID],
            });
            initiateListACarEventFromTab({
              tab,
              props,
            });
          } else {
            initiateListACarEventFromTab({
              tab,
              props,
            });
          }

          // Handling force publish if tab is nextLevel (easy access) or verification
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);
            // After successful saving of draft data, user should be redirected to next tab
            console.log("hideDeviceTab", hideDeviceTab)
            redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history, hideDeviceTab);
          } else {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          // No need for extra actions
        });
    } else {
      onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const onChangeInstantBooking = value => {
    if (value) {
      initiateListACarEventFromTab({
        props,
        eventButtonParam: [EVENT_LISTACAR_SELECTED_INSTANTBOOK],
      });
    }
  };

  const onUpdateAvailability = () => {
    if (!isNewListingFlow) {
      initiateListACarEventFromTab({
        props,
        eventButtonParam: [EVENT_LISTACAR_UPDATED_AVAILABILITY],
      });
    }
  };
  const onUpdateOnboardingCall = () => {
    if (!isNewListingFlow) {
      initiateListACarEventFromTab({
        props,
        eventButtonParam: [LISTACAR_ONBOARDING_CALL_BUTTON_ID],
      });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      currentUser: props.currentUser,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      showListingInProgress,
    };
  };

  if (tab !== VERIFICATION) {
    haveSentPublish = false;
  }

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonId={LISTACAR_ABOUT_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          userDetails = {currentUser}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonId={LISTACAR_FEATURES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          {...panelProps(POLICY)}
          submitButtonId={LISTACAR_CAR_RULES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonId={LISTACAR_LOCATION_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          userDetails = {currentUser}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          {...panelProps(PRICING)}
          submitButtonId={LISTACAR_PRICING_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case NEXT_LEVEL: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewNextLevel'
        : 'EditListingWizard.saveEditNextLevel';
      return (
        <EditListingNextLevelPanel
          {...panelProps(NEXT_LEVEL)}
          showPayoutDetails={showPayoutDetails}
          onChangeInstantBooking={onChangeInstantBooking}
          submitButtonId={LISTACAR_NEXT_LEVEL_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ONBOARDING_CALL: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewOnboardingCall'
        : 'EditListingWizard.saveNewOnboardingCall';
      return (
        <EditListingOnboardingCallPanel
          {...panelProps(ONBOARDING_CALL)}
          isNewListingFlow={isNewListingFlow}
          submitButtonId={LISTACAR_ONBOARDING_CALL_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          {...panelProps(AVAILABILITY)}
          submitButtonId={LISTACAR_AVAILABILITY_BUTTON_ID}
          onUpdateAvailability={onUpdateAvailability}
          availability={availability}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onManageDisableScrolling={onManageDisableScrolling}
          isNewListingFlow={isNewListingFlow}
          timeZone={timeZone}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case SUBSCRIPTION:
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSubscription'
        : 'EditListingWizard.saveEditSubscription';
      return (
        <EditListingSubscriptionPanel
          {...panelProps(SUBSCRIPTION)}
          submitButtonId={LISTACAR_SUBSCRIPTION_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          currentUser={props.currentUser}
          updateShowDeviceTab={updateShowDeviceTab}
        ></EditListingSubscriptionPanel>
      );

      case DEVICE:
        const submitButtonTranslationKeys = isNewListingFlow
          ? 'EditListingWizard.saveNewSubscription'
          : 'EditListingWizard.saveEditSubscription';
        return (
          <EditListingDevicePanel
            {...panelProps(DEVICE)}
            submitButtonId={LISTACAR_SUBSCRIPTION_BUTTON_ID}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKeys })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
            currentUser={props.currentUser}
          ></EditListingDevicePanel>
        );

    case TRACKING_DEVICE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTrackingDevice'
        : 'EditListingWizard.saveEditTrackingDevice';
      return (
        <EditListingTrackingDevicePanel
          {...panelProps(TRACKING_DEVICE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      );
    }
    case INSURANCE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewInsurance'
        : 'EditListingWizard.saveEditInsurance';
      return (
        <EditListingInsurancePanel
          {...panelProps(INSURANCE)}
          isNewListingFlow={isNewListingFlow}
          submitButtonId={LISTACAR_INSURANCE_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          redirectionProp={(to)=> {
            if (to === 'subscription') {
              const redirectionPath = `/l/${params.slug}/${params.id}/${params.type}/${to}`;
              history.push(redirectionPath);
            } else if (to === '/contact-us') {
              history.push(to);
            }
          }}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case VERIFICATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewVerification'
        : 'EditListingWizard.saveEditVerification';
      const getHostVerificationStatus = !!panelProps().currentUser && panelProps().currentUser && get(panelProps().currentUser, 'attributes.profile.publicData.hostIdentityVerificationStatus', '')
      const isVerified =
        (!!panelProps().currentUser &&
        !!panelProps().currentUser.id &&
        panelProps().currentUser.identityStatus &&
        panelProps().currentUser.identityStatus.hostVerified) || getHostVerificationStatus === 'confirmed';
      const underVerify =
        (!!panelProps().currentUser &&
        !!panelProps().currentUser.id &&
        panelProps().currentUser.identityStatus &&
        panelProps().currentUser.identityStatus.hostUnderVerify) || getHostVerificationStatus === 'pending';
      if ((isVerified || underVerify) && !haveSentPublish) {
        haveSentPublish = true;
        // setTimeout(() => {
        //   onCompleteEditListingWizardTab(tab, {});
        // }, 2000);
        onCompleteEditListingWizardTab(tab, {});
      }
      return (
        <EditListingVerificationPanel
          {...panelProps(VERIFICATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          currentUser={props.currentUser}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, {});
          }}
          handlePushEventGTM={pushHostVerifyEventGTM}
          submitButtonId={HOST_VERIFY_PUBLISH_BUTTON_ID}
        />
      );
    }

    case PAYOUTS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.next'
        : 'EditListingWizard.save';
      // const isVerified =
      //   !!panelProps().currentUser &&
      //   !!panelProps().currentUser.id &&
      //   panelProps().currentUser.identityStatus &&
      //   panelProps().currentUser.identityStatus.hostVerified;
      // const underVerify =
      //   !!panelProps().currentUser &&
      //   !!panelProps().currentUser.id &&
      //   panelProps().currentUser.identityStatus &&
      //   panelProps().currentUser.identityStatus.hostUnderVerify;
      // if ((isVerified || underVerify) && !haveSentPublish) {
      //   haveSentPublish = true;
      //   // setTimeout(() => {
      //   //   onCompleteEditListingWizardTab(tab, {});
      //   // }, 2000);
      //   onCompleteEditListingWizardTab(tab, {});
      // }
      return (
        <EditListingPayoutsPanel
        {...panelProps(PAYOUTS)}
        submitButtonId={LISTACAR_PAYOUTS_BUTTON_ID}
        submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
        stripeProps={stripeProps}
        onSubmit={values => {
          onCompleteEditListingWizardTab(tab, {});
        }}
      />
      );
    }

    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonId={LISTACAR_PICTURES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          initialImageUrls={initialImageUrls}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }

    case INSTRUCTIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingInstructionsPanel
          {...panelProps(PHOTOS)}
          submitButtonId={LISTACAR_PICTURES_BUTTON_ID}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          newListingPublished={newListingPublished}
          fetchInProgress={fetchInProgress}
          initialImageUrls={initialImageUrls}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }

    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
