import classNames from 'classnames';
import { object, oneOfType, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import css from './BookingBreakdown.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const ExplanationComponent = props => {
  const { text, children, textClassName, className, viewport } = props;

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [positionTop, setPositionTop] = useState(0);
  const [arrowPosition, setArrowPosition] = useState(false);
  const tooltipRef = useRef(null);
  const tooltipTextRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (isTooltipOpen) {
        setIsTooltipOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isTooltipOpen]);

  const calculatePosition = (isMobile, element) => {
    if (isMobile) {
      const target = element && element.current;
      const scrollPosition = target.getBoundingClientRect();
      const tooltipTextElement = tooltipTextRef && tooltipTextRef.current;
      const textElementheight = tooltipTextElement.getBoundingClientRect();
      const tooltipExactPosition = scrollPosition.top - textElementheight.height - 30;
      setPositionTop(tooltipExactPosition);
      setArrowPosition(scrollPosition.left - 15);
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  const handleHoverBug = e => {
    if (e.type === 'touchstart') {
      // Don't trigger mouseenter even if they hold
      setIsTooltipOpen(false);
      calculatePosition(isMobileLayout, tooltipRef);
      if (typeof e.stopImmediatePropagation === 'function') {
        e.stopImmediatePropagation();
      }
      // If $item is a link (<a>), don't go to said link on mobile, show menu instead
      e.preventDefault();
    }
  };

  // console.log('tooltipRef top', tooltipRef && tooltipRef.current && tooltipRef.current.scrollTop);
  // console.log('tooltipRef left', tooltipRef && tooltipRef.current && tooltipRef.current.offsetLeft);
  // console.log('tooltipRef left', isMobileLayout);
  const tooltipPositionStyle = {
    top: positionTop,
  };
  const tooltipArrowPositionStyle = {
    left: arrowPosition,
  };
  const showHideClass = !isTooltipOpen && css.tooltipHidden;
  return (
    <span
      className={classNames(css.explaination, className)}
      onTouchStart={handleHoverBug}
      onMouseEnter={handleHoverBug}
      onFocus={handleHoverBug}
      ref={tooltipRef}
    >
      {!isMobileLayout ? (
        <span className={classNames(css.explainationText, textClassName)}>
          {text}
          {children}
        </span>
      ) : (
        <div
          className={classNames(css.mobileExplainationText, showHideClass)}
          style={tooltipPositionStyle}
        >
          <span className={classNames(css.explainationText, textClassName)} ref={tooltipTextRef}>
            {text}
            {children}
            <span className={css.bottomArrow} style={tooltipArrowPositionStyle}></span>
          </span>
        </div>
      )}
    </span>
  );
};

ExplanationComponent.defaultProps = {
  text: null,
};

ExplanationComponent.propTypes = {
  text: oneOfType([string, object]),
};

const Explanation = compose(withViewport)(ExplanationComponent);

export default Explanation;
