import React, { useEffect, useState } from 'react';
import { TopbarContainer } from '..';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from '../../components';
import modalLeftImage from '../../assets/searchpageContact/vaccine-program-image.png';
import modalLeftImageMobile from '../../assets/searchpageContact/vaccine-program-mobile.png';
import { VaccinationForm } from '../../forms';
import css from './VaccineCredits.css';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_PERFORMED } from '../../util/gtm/gtmConstants';
import { drivelahApiPost } from '../../util/apiHelper';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const VaccineCreditsComponent = ({
  viewport,
  currentUser,
  vaccineCertificateSuccess,
  vaccineCertificateInProgress,
}) => {
  const [formStatus, setFormStatus] = useState({ success: false });

  useEffect(() => {
    if (vaccineCertificateSuccess) {
      addVaccineCreditApi();
    }
  }, [vaccineCertificateSuccess]);

  // prettier-ignore
  const handleSubmitSearch = value => {
    // alert();
  };

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  const addVaccineCreditApi = () => {
    const query = 'add-vaccine-credit';
    const data = {
      userId: currentUser.id.uuid,
    };
    try {
      drivelahApiPost(query, data).then(res => {});
    } catch (error) {
      console.log('Error', error);
    }
  };

  let isVaccineCertificateAdded, isVaccineCreditsAdded;
  if (currentUser) {
    const { publicData } = currentUser.attributes.profile;
    isVaccineCertificateAdded = publicData && publicData.vaccineCertificateadded;
    isVaccineCreditsAdded = publicData && publicData.vaccineCertificateCreditsaddedat;
  }

  const searchButton = (
    <div className={css.buttonWrapper}>
      <NamedLink
        name="SearchPage"
        to={{
          search:
            '?address=sydney&bounds=33.41966205794024%2C151.750246034722%2C-34.31797734205976%2C150.66834496527798',
        }}
        className={css.findYourCar}
        onClick={() => {
          initiateEventFromListing({
            props: {
              searchParams: {
                bounds: {
                  _sdkType: 'LatLngBounds',
                  ne: {
                    _sdkType: 'LatLng',
                    lat: 1.4708809,
                    lng: 104.04157989999999,
                  },
                  sw: {
                    _sdkType: 'LatLng',
                    lat: 1.216611,
                    lng: 103.60650989999999,
                  },
                },
              },
            },
            event: EVENT_SEARCH_PERFORMED,
          });
          gSend(GTAG_ACTIONS.ACTION_SEARCH, {
            // TODO: Assign proper details.
            search_location: `?address=Australia&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999`,
            search_pickupdate: `NA`,
            search_pickuptime: `NA`,
            search_dropoffdate: `NA`,
            search_dropofftime: `NA`,
          });
        }}
      >
        Browse Cars
      </NamedLink>
    </div>
  );

  const loaderView = <div className={css.searchContactRightContent}>Loading...</div>;

  const creditsAddedView = (
    <div className={css.searchContactRightContent}>
      <div className={css.rightContent}>
        <h3>Credits Added</h3>
        <p>
          Your vaccination certificate has been reviewed and $10 have been added to your account.
          Make the most out of them by booking your new rental car today!
        </p>
      </div>
      {/* {searchButton} */}
    </div>
  );

  const addCertificateFormView = (
    <div className={css.searchContactRightContent}>
      <div className={css.rightContent}>
        <h3>Get $10 bonus credits for being vaccinated</h3>
        <p>
          Submit your vaccination certificate, which our team will review and then add credits to
          your account..
        </p>
        <VaccinationForm
          onSubmit={handleSubmitSearch}
          className={css.searchForm}
          formStatus={formStatus}
          setFormStatus={setFormStatus}
          vaccineCertificateInProgress={vaccineCertificateInProgress}
        />
      </div>
    </div>
  );

  const certificateSubmittedFormView = (
    <div className={css.searchContactRightContent}>
      <div className={css.rightContent}>
        <h3>Submitted successfully.</h3>
        <p>
          Your vaccination certificate has been submitted and someone from our team will review it
          and add credits to your account soon.
        </p>
        <p>In the meantime browse cars to find the perfect one you want to rent.</p>
      </div>
      <div className={css.buttonWrapper}>{searchButton}</div>
    </div>
  );

  const vaccineProgramView = !currentUser
    ? loaderView
    : isVaccineCreditsAdded
    ? creditsAddedView
    : vaccineCertificateSuccess || isVaccineCertificateAdded
    ? certificateSubmittedFormView
    : addCertificateFormView;

  return (
    <Page
      title="Vaccination Program"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'VaccineCredits',
        description: 'Vaccination Program',
        name: 'Vaccination Program page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.vaccineCreditsContainer}>
            <div className={css.searchContactModalContainer}>
              <div className={css.searchContactModalInner}>
                <div className={css.searchContactLeftImage}>
                  {isMobileLayout ? (
                    <img src={modalLeftImageMobile} alt="" />
                  ) : (
                    <img src={modalLeftImage} alt="" />
                  )}
                </div>
                {vaccineProgramView}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};
const mapStateToProps = state => {
  const { vaccineCertificateSuccess, vaccineCertificateInProgress } = state.VaccineCredits;
  const { currentUser } = state.user;
  return {
    currentUser,
    vaccineCertificateSuccess,
    vaccineCertificateInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const VaccineCredits = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(VaccineCreditsComponent);

export default VaccineCredits;
