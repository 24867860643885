/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo } from 'react';
import classNames from 'classnames';
import BLandingCss from './LandingPageNew.css';
import isEqual from 'lodash/isEqual';
import bannerImage from '../../assets/b-landing/2024/banner-image.jpg';
import bannerImage2x from '../../assets/b-landing/2024/banner-image@2x.jpg';
import bannerImageMobile from '../../assets/b-landing/2024/banner-mobile-image.jpg';
import bannerImageMobile2x from '../../assets/b-landing/2024/banner-mobile-image@2x.jpg';
import insuranceLogo from '../../assets/insurance-logo.svg';
import Typewriter from 'typewriter-effect';
import { TrustBox } from '../../components';
import SectionSearchBanner from './SectionSearchBanner';
import GTInsurenceIcon from '../../assets/landingPageNew/gtInsurence.svg';
import GTInsurenceIcon1x from '../../assets/landingPageNew/gt-logo-insurance2x.png';
import AllianzInsurenceIcon from '../../assets/landingPageNew/allianceInsurence.svg';

const ImageComponent = ({ src1x, src2x, children, alt }) => {
  return (
    <div className={BLandingCss.bannerSlideItem}>
      <img
        alt={alt}
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={BLandingCss.imageComponentImageBLanding}
      />
      <div className={BLandingCss.imageComponentContentBLanding}>{children}</div>
    </div>
  );
};
const LandingSectionBanner = memo(
  ({ isMobileLayout, currentUser, openModal, onSelectedPrediction, onSelectionPostalAddress }) => {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const newText = (
      <div className={BLandingCss.typeEffect}>
        <Typewriter
          options={{
            strings: ['<u>by the day.</u>', '<u>by the week.</u>', '<u>by the month.</u>'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    );

    // const openModal = (e) => {
    //   console.log("hhhfvbhvbfh")
    //   setIsModalOpen(true)
    // };

    // const changeModalVisiblity = (value) => {
    //     setIsModalOpen(value)
    // };

    return (
      <>
        <div className={BLandingCss.sectionImagesBLanding}>
          <ImageComponent
            src1x={isMobileLayout ? bannerImageMobile : bannerImage}
            src2x={isMobileLayout ? bannerImageMobile2x : bannerImage2x}
            alt="Car rental made easy with Drive Mate"
          >
            <div className={BLandingCss.bannerContentBlock}>
              <h1
                className={classNames(
                  BLandingCss.sectionImageTitleBLanding,
                  BLandingCss.hourlyTitleBLanding
                )}
              >
                <span>
                  <i>Rent local</i>
                  <br />
                  <i>cars&nbsp;</i>
                  <i>{newText}</i>
                </span>
                {/* <div className={BLandingCss.mobileSearchSection}>
                  {isMobileLayout && <SectionSearchBanner onSelectedPrediction={onSelectedPrediction} isMobileLayout={isMobileLayout} />}
                </div> */}
              </h1>

              <div className={classNames(BLandingCss.searchBar, BLandingCss.searchBarMobile)}>
                <div className={BLandingCss.searchBarContent}>
                  <p>
                  Rent cars daily, weekly or monthly for fairer and more affordable car sharing experiences. Ditch those outrageous premiums from big-brand car rental mobs.
                  </p>
                  {/* {currentUser && currentUser.id ? (
                    <p className={BLandingCss.link}>
                      We are coming soon to your area with a huge variety of affordable cars. We
                      will keep you updated. In the meanwhile you can complete your guest
                      verification or share your car so that once we go live you are good to go
                    </p>
                  ) : ( */}
                  {/* <p className={BLandingCss.link}>

                    </p> */}
                  {/* )} */}
                  <SectionSearchBanner
                    onSelectedPrediction={onSelectedPrediction}
                    onSelectionPostalAddress={onSelectionPostalAddress}
                    isMobileLayout={isMobileLayout}
                  ></SectionSearchBanner>
                </div>
                {/*currentUser && currentUser.id ? (
                  <div className={BLandingCss.flexBtns}>
                    <NamedLink name="NewListingPage" className={currentUser &&
                      currentUser.attributes.profile.publicData
                        .guestIdentityVerificationStatus ? classNames(BLandingCss.signupButton, BLandingCss.listYourCarBtn) : BLandingCss.listCarButton}>
                      <PrimaryButton type="button">
                        <FormattedMessage id="LandingSectionBanner.ListYourCarButtonMessage" />
                      </PrimaryButton>
                    </NamedLink>
                    {currentUser &&
                      !currentUser.attributes.profile.publicData
                        .guestIdentityVerificationStatus && (
                        <NamedLink
                          name="AccountSettingDriverVerificationPage"
                          className={BLandingCss.listCarButton}
                        >
                          <PrimaryButton type="button">
                            <FormattedMessage id="LandingSectionBanner.DriverVerificationMessage" />
                          </PrimaryButton>
                        </NamedLink>
                      )}
                  </div>
                ) : (
                  <NamedLink name="SignupPage" className={BLandingCss.signupButton}>
                    <PrimaryButton type="button">
                      <FormattedMessage id="LandingSectionBanner.SignupNowButtonMessage" />
                    </PrimaryButton>
                  </NamedLink>
                )*/}
                {/* <SectionSearchBanner onSelectedPrediction={onSelectedPrediction} isMobileLayout={isMobileLayout} /> */}
                {/* <NamedLink name="SignupPage" className={BLandingCss.signupButton}>
                <PrimaryButton type="button">
                  <FormattedMessage id="LandingSectionBanner.SignupNowButtonMessage" />
                </PrimaryButton>
              </NamedLink> */}
                <div
                  className={BLandingCss.insuranceListingWrapperMOD}
                  onClick={() => openModal(true)}
                >
                  <div>
                    Comprehensive insurance up to <strong>$35m</strong> cover
                  </div>
                  <div class={BLandingCss.insuranceListingWrapperMODLogo}>
                    <p>
                    <img srcSet={`${GTInsurenceIcon} 1x, ${GTInsurenceIcon1x} 2x`} alt="GT Insurence Icon" />
                      <img src={AllianzInsurenceIcon} alt="Allianz Insurence Icon" />
                    </p>
                    <p>Insurance Partners</p>
                  </div>
                </div>
                {/* <div className={BLandingCss.insuranceListingWrapper}> */}
                {/* <div
                    className={BLandingCss.trustPilotLogo}
                    style={{ flex: '0 0 50%', maxWidth: '30%', padding: '0 15px' }}
                  >
                    <TrustBox />
                  </div> */}
                {/* <div
                    className={BLandingCss.comprehensiveInsuranceBlock}
                    onClick={() => openModal(true)}
                  > */}
                {/* <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}> */}
                {/* <div className={BLandingCss.cILogo}>
                        <img src={insuranceLogo} alt="" />
                      </div> */}
                {/* <div className={BLandingCss.cIContent}>
                        <span>
                          Comprehensively insured by Drive mate Protection<sup>TM</sup>
                        </span>
                      </div> */}
                {/* <div className={BLandingCss.GTinsurenceBlock}><p>Insured by <img src={GTInsurenceIcon} alt="GT Insurence Icon" /> <img src={AllianzInsurenceIcon} alt="Allianz Insurence Icon" /></p></div> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div className={BLandingCss.trustPilotQualifier}>
                    <p>
                      The score relates to Drive mate's parent company in Australia (Drive mate)
                    </p>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </ImageComponent>
          {isMobileLayout ? (
            <div className={BLandingCss.searchBar}>
              <div className={BLandingCss.searchBarContent}>
                <p>
                Rent cars daily, weekly or monthly for fairer and more affordable car sharing experiences. Ditch those outrageous premiums from big-brand car rental mobs.
                </p>
                {/* {currentUser && currentUser.id ? (
                  <p className={BLandingCss.link}>
                    We are coming soon to your area with a huge variety of affordable cars. We will
                    keep you updated. In the meanwhile you can complete your guest verification or
                    share your car so that once we go live you are good to go
                  </p>
                ) : ( */}
                {/* <p className={BLandingCss.link}>

                  </p> */}
                {/* )} */}
                <SectionSearchBanner
                  onSelectedPrediction={onSelectedPrediction}
                  isMobileLayout={isMobileLayout}
                />
              </div>

              {/*currentUser && currentUser.id ? (
                <NamedLink name="NewListingPage" className={BLandingCss.signupButton}>
                  <PrimaryButton type="button">
                    <FormattedMessage id="LandingSectionBanner.ListYourCarButtonMessage" />
                  </PrimaryButton>
                </NamedLink>
              ) : (
                <NamedLink name="SignupPage" className={BLandingCss.signupButton}>
                  <PrimaryButton type="button">
                    <FormattedMessage id="LandingSectionBanner.SignupNowButtonMessage" />
                  </PrimaryButton>
                </NamedLink>
              )*/}
              {/*currentUser &&
                !currentUser.attributes.profile.publicData.guestIdentityVerificationStatus && (
                  <NamedLink
                    name="AccountSettingDriverVerificationPage"
                    className={BLandingCss.signupButton}
                  >
                    <PrimaryButton type="button">
                      <FormattedMessage id="LandingSectionBanner.DriverVerificationMessage" />
                    </PrimaryButton>
                  </NamedLink>
                )*/}
              {/* <div className={BLandingCss.insuranceListingWrapper}>
                <div
                  className={BLandingCss.trustPilotLogo}
                  style={{ flex: '1 1 33%', maxWidth: '33%' }}
                >
                  <TrustBox />
                </div>
                <div
                  className={BLandingCss.comprehensiveInsuranceBlock}
                  onClick={() => openModal(true)}
                >
                  <div className={BLandingCss.comprehensiveInsuranceBlockWrapper}>
                    <div className={BLandingCss.cILogo}>
                      <img src={insuranceLogo} alt="" />
                    </div>
                    <div className={BLandingCss.cIContent}>
                      <span>
                        Comprehensive insurance up to <strong>$40m</strong> cover
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className={BLandingCss.trustPilotQualifier}>
                <p>The score relates to Drive mate's parent company in Australia (Drive mate)</p>
              </div> */}
              <div
                className={BLandingCss.insuranceListingWrapperMOD}
                onClick={() => openModal(true)}
              >
                <div>
                  Comprehensive insurance up to <strong>$35m</strong> cover
                </div>
                <div>
                  <p>
                  <img srcSet={`${GTInsurenceIcon} 1x, ${GTInsurenceIcon1x} 2x`} alt="GT Insurence Icon" />
                    <img src={AllianzInsurenceIcon} alt="Allianz Insurence Icon" />
                  </p>
                  <p>Insurance Partners</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {/* {isModalOpen && (
          <InsuranceSummaryModal
            isModalOpen={isModalOpen}
            changeModalVisiblity={changeModalVisiblity}
          />
        )} */}
      </>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default LandingSectionBanner;
