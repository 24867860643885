import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemDepositPrice = props => {
  const { transaction, isProvider, intl, currentUser, isAddons, isTripDetailsPage, isCheckoutPage } = props;

  if(!isCheckoutPage || currentUser === undefined || transaction === undefined) {
    return null;
  }

  const shouldChargeDeposit = get(transaction, 'attributes.protectedData.shouldChargeDeposit', false);

  if (!shouldChargeDeposit) {
    return null;
  }

  return (

    <div className={css.depositContainer}>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{'Refundable deposit'}</div>
        {/*<div className={css.totalPrice}>{`${formattedTotalPrice}${hostCreditMoney ? ` + ${hostCreditMoney} credits` : ''}`}</div>*/}
        <div className={css.totalPrice}>{'$50'}</div>
      </div>
      <div className={css.depositPara}>
          <p>We collect a one-time $50 deposit from our guests, which will be automatically refunded after your first trip</p>
      </div>
    </div>

  );
};

LineItemDepositPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDepositPrice;
